import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnChanges, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Page, Location, Image, Roles } from "src/app/shared/interfaces/model";
import "quill-emoji/dist/quill-emoji.js";
import "quill-mention";
import {BranchesService} from "../../shared/services/branches.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { ImageService } from "src/app/shared/services/image.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { filter, map, shareReplay, take } from "rxjs/operators";
import { PageHelperService } from "src/app/shared/utils/page-helper.service";
import { UserHelperService } from "src/app/shared/utils/user-helper.service";
import { UserService } from "src/app/shared/services/user.service";
import { User } from "src/app/shared/interfaces/user.model";
import { CountryDataService } from "src/app/shared/services/country-data.service";
@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit, OnChanges {
  public hasPhoto = false;
  public hasCoverPhoto: boolean;
  public coverImage;
  public editCoverImage = false;
  public editYourPageInfo = false;
  public image;
  name;

  config = {
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],
        // [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction
        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["image"],                                    // image
        ["video"],
        ["code-block"], // code block
        [{align: []}],
        ["emoji"],
        ["clean"], // remove formatting button
        ["link", "image", "video"],
      ]
    },
  }
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  fruitCtrl = new UntypedFormControl();
  filteredFruits: Observable<string[]>;
  fruits: string[] = [];
  empty="";
  openLanguageDiv = false;
  openLocation = false;
  public model;
  public pages$: Observable<Page[]>;
  public page = new Page();
  public pageRoles = new Roles();
  public showWeb = false;
  public pageID = "";
  public locations = new Location();
  public search = "";
  public pageUrl;
  public pages: Observable<Page[]>;
  public user: User;
  public currentUser: User;

  public dataList$: Observable<any>;


  @ViewChild("fruitInput") fruitInput: ElementRef<HTMLInputElement>;
  public loggedUser: Observable<any>;

  constructor(public router: Router,
              public branchesService: BranchesService,
              public languageService: LanguagesService,
              public http: HttpClient,
              public authService: AuthService,
              public pageService: PagesService,
              public cd: ChangeDetectorRef,
              public imageService: ImageService,
              public shareData: SenderService,
              public recevier: SenderService,
              public pageHelper?: PageHelperService,
              public userHelper?: UserHelperService,
              public userService?: UserService,
              public countryDataService?: CountryDataService,

        ) { }

  ngOnInit(): void {
    this.branchesService.getBranches();


    if(this.authService.isLoggedIn) {
      this.userService.loadPages(this.authService.userID).subscribe();
      this.pages$ = this.userService.pageList;
      this.loggedUser = this.userService.userList;
      this.loggedUser.subscribe(res => {
        this.user = res;
        this.currentUser = res.user
      })

     // this.pageService.getMyPages();
    }

  }

  ngOnChanges() {
    this.cd.detectChanges();
  }
  editPage() {
    this.editYourPageInfo = true;
    this.pageUrl = this.page.pageUrl;
    this.countryDataService.countryData$.pipe().subscribe(res => this.model = res);

  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.fruits.unshift(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.fruitCtrl.setValue(null);
  }
  addBranch(event) {
    this.page.branches = event?.description;
    this.search = "";
   }
   addLanguage() {
     this.openLanguageDiv = true;
   }
   addLocation() {
     this.openLocation = true;
   }



   goToWeb(id) {
     this.showWeb = true;

     this.pageService.getPage(id).subscribe(data => {
       this.page = data;
       if(this.page.pageUrl) {
       this.router.navigate(["", this.page.pageUrl]);
       } else {
        this.router.navigate(["", this.page._id]);
       }

     })
     this.pageID = id;
     this.pageService.changeMessage(id);
   }

   onChange(value) {
   this.locations.c = value.EN;
   }
   deleteLocation(index) {
     this.page.locations.splice(index, 1);
     this.pageService.updatePage(this.pageID, this.page);
   }

   removeBranch(i) {
    return ;
   }

   navigateToPayment() {
    localStorage.setItem("payment", "isUser");
    this.shareData.paymentDataVisibility(this.currentUser);
    this.router.navigateByUrl(this.currentUser?.userUrl + '/payments');
  }

  navigateToUser() {
    const test = [this.currentUser, "outside"];
       this.shareData.send(test);
       this.router.navigate([this.currentUser.userUrl, "start" ]);
    }

    checkPage() {
      this.userService.loadPages(this.authService.userID).subscribe();
      this.pages$ = this.userService.pageList;
      this.cd.detectChanges();
    }


}
