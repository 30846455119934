import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Model } from "src/app/shared/interfaces/model";
import { DataDialogComponent } from '../../modals/data-dialog/data-dialog.component';
import {
  EmptySubCategory,
  EmptyDataDialogComponent,
} from "../../modals/empty-data-dialog/empty-data-dialog.component";

@Component({
  selector: "app-sub-career-dialog",
  templateUrl: "./sub-career-dialog.component.html",
  styleUrls: ["./sub-career-dialog.component.scss"],
})
export class SubCareerDialogComponent implements OnInit {
  public careerSub = [
    {
      id: "yourData",
      name: "New Career",
      description: "New Career",
      icon: "fa fa-id-card",
    },
    {
      id: "newSub",
      name: "New Sub",
      description: "Empty Sub",
      icon: "fa fa-id-card",
    },
    {
      id: "space",
      name: "new Space",
      description: "space",
      icon: "fa fa-id-card",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubDialog,
    private dialogRef: MatDialogRef<SubCareerDialogComponent>,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
  }

  public addCategory(category, data: EmptySubCategory) {
    if (category.id === "newSub") {
      this.dialog.open(EmptyDataDialogComponent, {
        data,
      });
    } else if (category.id === "yourData") {
      this.dialog.open(DataDialogComponent, {
        data,
      });
    }
    return this.dialogRef.close(category);
  }
}
export interface SubDialog {
  model: Model;
  emptyObj?: boolean;
  indexOfLang?: number
}
