import { Component, OnInit,ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Group } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { GroupService } from 'src/app/shared/services/group.service';

@Component({
  selector: 'app-groups-sidebar',
  templateUrl: './groups-sidebar.component.html',
  styleUrls: ['./groups-sidebar.component.scss']
})
export class GroupsSidebarComponent implements OnInit {
  public group = new Group();
  public groups$: Observable<Group[]>;
  public groups: Group[];
  @Input() sidebarDetails;


  empty="";
  public categories = [];
  searchCategory;



  constructor(
    public router: Router,
    public groupService: GroupService,
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    public categoryService: CategoryService) { }

  ngOnInit(): void {
    this.groupService.getGroups();
    this.categoryService.getCategory();
    this.groups$ = this.groupService.groupModel();
    this.groups$.subscribe((data:any) => {
      this.groups = data;
      this.cd.detectChanges();
    });
  }


  goToWeb(id) {
    this.groupService.getGroup(id).subscribe(data => {
      this.group = data;
       this.router.navigate(['groups', this.group._id]);
    })
  }


}
