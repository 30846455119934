import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/assets/environments/environment";
import { saveAs } from "file-saver";

@Injectable({providedIn: "root"})
export class PdfService {
  public baseUrl = environment.backend;


  constructor(private http: HttpClient) {
  }
  public setPDF(data): Promise<any> {
    return this.http.post(`${this.baseUrl}/create-pdf`, data).toPromise();
  }

}
