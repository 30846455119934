import { NgModule } from '@angular/core';
import { NoSanitizePipe } from './noSanitize';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchUserPipe } from './searchUser.pipe';
import { SearchPagePipe } from './searchPage.pipe';



@NgModule({
  declarations: [NoSanitizePipe, SearchUserPipe, SearchPagePipe],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule

  ],
  exports: [
    NoSanitizePipe,
    SearchUserPipe,
    SearchPagePipe

  ]
})
export class PipeModule { }
