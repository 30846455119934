<aside class="pt-4 aside col-md-2">
  <section class="sc-smart">
  <div class="widget-item p-0">
    <div class="">
      <div class="">
          <button type="button" class="button-f button-sc" (click)="navigateToUser()">
            <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="40">
              <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="40">
                <a><span class="span-padding-left10">{{this.currentUser?.firstName}} {{this.currentUser?.lastName}}</span></a>
          </button>
        <div>
          <button type="button" class="button-f button-sc" [routerLink]="userUrl + '/cv'">
            <div >
             <i class="fa fa-file-text width-40"></i>
              <span class="span-padding-left10">{{'header.cv' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc" routerLink="/pages">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-flag" viewBox="0 0 16 16">
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z"/>
              </svg>
             <span class="span-padding-left10">{{'header.pages' | translate}}</span>

            </div>
          </button>
          <button type="button" class="button-f button-sc" routerLink="/groups">
            <div>
             <i class="fa fa-users width-40"></i>
             <span class="span-padding-left10">{{'header.groups' | translate}}</span>
            </div>
          </button>

          <button type="button" class="button-f button-sc" routerLink="/contacts">
            <div>
              <i class="fa fa-user width-40"></i>
              <span class="span-padding-left10">Contacts</span>

            </div>
          </button>
          <button type="button" class="button-f button-sc" [routerLink]="userUrl + '/jobs'">
            <div>
              <i class="bi bi-list-task width-40"></i>
              <span class="span-padding-left10">{{'sidebar.jobs' | translate}}</span>

            </div>
          </button>
          <button type="button" class="button-f button-sc" [routerLink]="userUrl + '/projects'">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-bar-chart-steps width-40" viewBox="0 0 16 16">
              <path
                d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z" />
            </svg>
              <span class="span-padding-left10">{{'sidebar.projects' | translate}}</span>

            </div>
          </button>
          <button type="button" class="button-f button-sc" (click)="navigateToPayment()">
            <div>
              <i class="bi bi-credit-card" style="margin-left: 10px;"></i>
              <span class="span-padding-left10">{{'Payment'}}</span>

            </div>
          </button>

          <div class="sc-lineSeparator" role="separator"></div>
        </div>
         <!--<div class="sc-shortcut" (mouseenter)="showShortcut()" (mouseleave)="hideShortcut()">
        <p class="sc-shortcut p">Your shortcuts
          <span *ngIf="editShortcut">Edit</span>
        </p>
        <div >
          <button type="button" class="button-f button-sc">
            <div>
             <i class="fa fa-file-text-o width-40"></i>
            {{'header.pages' | translate}}
            </div>
          </button>
          <button type="button" class="button-f button-sc">
            <div>
              <share-popup-button [icon]="faCoffee" size="-3"></share-popup-button>
              Share
            </div>
          </button>
          <button type="button" class="button-f button-sc">
            <div>
             <i class="bi bi-plus width-40"></i>
              Pages
            </div>
          </button>
          <button type="button" class="button-f button-sc">
            <div>
             <i class="bi bi-plus width-40"></i>
              Pages
            </div>
          </button>
        </div>
      </div> -->
    </div>
    </div>
</div>
</section>
<!--<footer class="footer footer-sidebar">test</footer> -->
 </aside>
