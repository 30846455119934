import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: "root"
})
export class ToastService {
    constructor(public snackBar: MatSnackBar) { }

    openSnackBar(message: string, action: string, className: string) {
        this.snackBar.open(message, action, {
            duration: 4500,
            verticalPosition: "top",
            horizontalPosition: "end",
            panelClass: [className]
        });
    }
}
