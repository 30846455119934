import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { MaterialModule } from "src/app/material.module";
import { CreateYourProjectComponent } from "./create-your-project.component";

@NgModule({
    declarations: [CreateYourProjectComponent],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        RouterModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule,
        QuillModule.forRoot(),

    ],
    exports: [
        CreateYourProjectComponent
    ]
})

export class CreateProjectModule {

}