import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Model, Page, Project, Qualifications } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import { WarningDialogComponent } from 'src/app/sharedComponents/warning-dialog/warning-dialog.component';
import _ from 'lodash'
import { User, UserUser } from 'src/app/shared/interfaces/user.model';
import { Observable, Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-qualifications-dialog',
  templateUrl: './qualifications-dialog.component.html',
  styleUrls: ['./qualifications-dialog.component.scss']
})
export class QualificationsDialogComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public user: User;
  public pageSelected = false;
  searchForPageTrue = false;
  pageActive = "";
  public searchForPage = "";
  public pages: Page[] = [];
  public website = "";

  public selectedPageID = "";
  public selectedPage: Page;

  public page: Page;
  public dataList$: Observable<any>;


  public uUser: UserUser;

  cv: any
  pages$: Observable<Page[]>;
  private searchText$ = new Subject<string>();
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditQualifications,
    private dialogRef: MatDialogRef<QualificationsDialogComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    public pageService: PagesService,
    public userService: UserService) {
      this.searchText$.pipe(
        debounceTime(400),
        distinctUntilChanged())
        .subscribe(value => {
          this.pageService.search(value).subscribe();
         this.pages$ = this.pageService.loadPages;
         this.searchForPageTrue = true
        });
    }

  ngOnInit(): void {


    if (!this.data.projectQualifications) {
      if (!this.data.edit) {
        this.dataList$ = this.userService.dataList;
        this.dataList$.subscribe((res: any) => {
          this.user = res.user;
          this.uUser = res.userUser;
          this.cv = res.cv

        this.data.qualifications = {} as Qualifications;
        });

      } else {
        this.dataList$ = this.userService.dataList;
        this.dataList$.subscribe((res: any) => {
          this.user = res.user;
          this.uUser = res.userUser;
          this.cv = res.userUser.cv.data[this.data.indexOfLang];
          if (res.cv?.data[this.data.indexOfLang]?.skills[0].qualifications.data.length > 0) {
            this.data.qualifications = res.cv?.data[this.data.indexOfLang]?.skills[0].qualifications.data;
            this.data.qualifications = _.cloneDeep(this.data.qualifications);
            ({ qualifications: this.data.qualifications } = this.data);

            if (this.data.qualifications?.company) {
              this.pageService.getPage(this.data.qualifications?.company).subscribe(res => {
                this.page = res;
                this.searchForPage = this.page?.name;
                this.pageActive = this.page?.name;
                this.pageSelected = true;
                this.website = this.page?.website;
                this.selectedPage = res;
                this.selectedPageID = this.page._id;
              });
            } else {
              this.searchForPage = this.data.qualifications.name;
              this.pageSelected = true;
            }
          } else {
            this.data.qualifications = {} as Qualifications;

          }


        });
      }
      this.pageService.allPages.subscribe(res => {
        this.pages = res;
      });
    } else if (this.data.projectQualifications) {
      this.data.project = new Project();
      this.data.project.qualifications = [];
    }

  }

  saveData() {
    if (!this.data.edit) {
      this.cv.data[this.data.indexOfLang].skills[0].qualifications.data.unshift(this.data.qualifications);


      const data = this.cv.data[this.data.indexOfLang].skills[0].qualifications.data.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name))

      this.user.searchFilterSkills.searchQualifications.data = data;
      this.cv.data[this.data.indexOfLang].skills[0].qualifications.data = data;

      this.userService.updateUser(this.user._id, this.user);
      this.userService.updateCV(this.cv._id, this.cv);
      this.userService.updateuUser(this.uUser._id, this.uUser);

      this.dialogRef.close(this.data.qualifications);

      // this.user.cv[this.data.index].skills[0].subCategories[0].qualifications.unshift(this.data.qualifications);

    }
    this.dialogRef.close(this.data);
  }
  deletePage() {
    this.pageActive = "";
    this.searchForPage = "";
  }
  addCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    if (page.name) {
      this.searchForPage = page.name;
    }
    if (page.website) {
      this.website = page.website;
    }
    this.data.qualifications.company = page._id;
    if (this.pageSelected) {

      this.selectedPageID = page._id;
      this.selectedPage = page;
      this.data.qualifications.company = page._id;
      const removeItem = "name";
    }
    this.searchForPageTrue = false;
  }

  search(packageName: string) {
    this.searchText$.next(packageName);
  }

}

export interface EditQualifications {
  qualifications?: Qualifications;
  index?: number;
  edit?: boolean;
  model?: Model;
  qualificationsObj?: Qualifications;
  project?: Project;
  projectQualifications?: boolean;
  jobEdit?: boolean;
  indexOfLang?: number
}
