import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Duration, EmploymentType, Incomes, Links, Remote } from 'src/app/shared/enums/enums-model';
import { Page, Project } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { EditDriveLicense, DriveLicenseDialogComponent } from '../modals/editors/drive-license-dialog/drive-license-dialog.component';
import { EditLanguage, LanguageDialogComponent } from '../modals/editors/language-dialog/language-dialog.component';
import { EditOffer, OfferDialogComponent } from '../modals/editors/offer-dialog/offer-dialog.component';
import { EditPCKnowledge, PcKnowledgeDialogComponent } from '../modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component';

@Component({
  selector: 'app-create-your-project',
  templateUrl: './create-your-project.component.html',
  styleUrls: ['./create-your-project.component.scss']
})
export class CreateYourProjectComponent implements OnInit {
  public project = new Project();

  @Input() isPage: boolean = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  fourthFormGroup: UntypedFormGroup;

  selectedDate = new Date();
  startDateLimited = new Date();
  currentYear = new Date().getFullYear();
  currentDate = new Date().getDate();
  currentMonth = new Date().getMonth();
  endDateLimited = new Date(this.currentYear, this.currentMonth, this.currentDate + 1);


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  projectDuration: typeof Duration = Duration;
  public projectDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]

  public user: User;
  isLinear = false;
  public page: Page;
  selectedUser = "";
  pcKnowledge = "";
  skillsOffer = "";
  driveLicense = "";
  pcKnowledgeProject = "";
  language = "";
  qualifications = "";
  public modules;
  submitted = false;
  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];

  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite" },
    { id: Remote.hybrid, description: "project.firstPage.hybrid" },
    { id: Remote.remote, description: "project.firstPage.remote" },
  ];
  isFreelancer;
  currency;
  countryJson

  constructor(private _formBuilder: FormBuilder,
    public http: HttpClient,
    public userService: UserService,
    public authService: AuthService,
    public pageService: PagesService,
    public receiverService: SenderService,
    public cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public router: Router,
    public countryDataService: CountryDataService,
    public projectService: ProjectService) {
    this.firstFormGroup = this._formBuilder.group({
      projectTitle: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: [''],
      company: [false, Validators.requiredTrue],
      freelancer: [false, Validators.requiredTrue],
      remote: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      pcKnowledge: this._formBuilder.array([]),
      skillsOffer: this._formBuilder.array([]),
      driveLicense: this._formBuilder.array([]),
      language:  this._formBuilder.array([]),
      qualifications: this._formBuilder.array([]),
      pcKnowledgeProject: this._formBuilder.array([])
    });
    this.thirdFormGroup = this._formBuilder.group({
      editor: ['', Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      salaryBudget: ['', Validators.required],
      salaryStatus: ['', Validators.required],
      currency: ['', Validators.required],
      projectDuration: ['', Validators.required],
      start: new UntypedFormControl,
      end: new UntypedFormControl,
      startDate: [''],
    });

  }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
    this.project.pcKnowledge = [];
    this.project.skillsOffer = [];
    this.project.driveLicense = [];
    this.project.language = [];
    this.project.qualifications = [];
    this.project.incomes = {
      budget: 0,
      month: 0,
      s: '',
      hour:0 ,
      year: 0

    };

    if (this.route.snapshot.paramMap.get("isPage") || this.isPage) {
          this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
            this.page = page.page;
            this.project.incomes = {};

            const findPrimaryLocation = this.page?.locations.find(t => t.headquarters === true);
            this.project.city = findPrimaryLocation.city;
            this.project.postalCode = findPrimaryLocation.postalCode as any;
            this.project.c = findPrimaryLocation.c;
            this.project.street = findPrimaryLocation.street;
            this.project.state = findPrimaryLocation.state;
            this.currency = findPrimaryLocation.c;

        });
    } else {
      this.userService.getUserContactData(this.authService.userID).subscribe(res => {
        this.user = res;
        this.project.incomes = {};
        this.project.postalCode = this.user.address.data[0].postalCode;
        this.project.city = this.user.address.data[0].city;
        this.project.c = this.user.address.data[0].c;
        this.project.street = this.user.address.data[0].street;
        this.project.state =  this.user.address.data[0].state;
        this.currency =  this.user.address.data[0].c;


      });
    }

  }

  addLanguage() {
    this.project.language.unshift({ name: this.language, rate: 1 });
  }

  addQualifications() {
    this.project.qualifications.unshift({ name: this.qualifications });
  }
  addPcKnowledge(data: EditPCKnowledge) {
    this.dialog.open(PcKnowledgeDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.pcKnowledge = res.pcKnowledge;
      }
    });
  }

  addProjectLanguage(data: EditLanguage) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.language = res.language;
      }
    });
  }
  addSkillsOffer(data: EditOffer) {
    this.dialog.open(OfferDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.skillsOffer = res.skillsOffer;
      }
    });
  }

  addProjectQualifications(data: EditLanguage) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.qualifications = res.qualifications;
      }
    });
  }

  addDriveLicense(data: EditDriveLicense) {
    this.dialog.open(DriveLicenseDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.driveLicense = res.driveLicense;
      }
    });
  }

  get f() { return this.firstFormGroup.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.firstFormGroup.get('freelancer').value || this.firstFormGroup.get('company').value || (this.firstFormGroup.get('freelancer').value && this.firstFormGroup.get('company').value)) {
      // for setting validations
      this.firstFormGroup.get('freelancer').clearValidators();
      this.firstFormGroup.get('company').clearValidators();
      this.firstFormGroup.get('freelancer').updateValueAndValidity();
      this.firstFormGroup.get('company').updateValueAndValidity();
      return;
    } else {
      this.firstFormGroup.get('freelancer').setValidators(Validators.requiredTrue);
      this.firstFormGroup.get('freelancer').updateValueAndValidity();
      this.firstFormGroup.get('company').setValidators(Validators.requiredTrue);
      this.firstFormGroup.get('company').updateValueAndValidity();
      return;
    }

  }
  createProject() {
    if (
      this.fourthFormGroup.get("projectDuration").value ===
      this.projectDuration.unlimited
    ) {
      this.project.startDate = this.fourthFormGroup.get("startDate").value;
    } else if (this.fourthFormGroup.get("projectDuration").value ===
    this.projectDuration.temporary) {
      this.project.startDate = this.fourthFormGroup.get("start").value;
    }
    this.project.userID = this.authService.userID;
    this.project.company = this.firstFormGroup.get("company").value;
    this.project.freelancer = this.firstFormGroup.get("freelancer").value;
   this.projectService.createProject(this.project, true).subscribe(t => t);
   this.router.navigateByUrl(this.router.url.replace('/create', ""));

  }

  createPageProject() {
    if (
      this.fourthFormGroup.get("projectDuration").value ===
      this.projectDuration.unlimited
    ) {
      this.project.startDate = this.fourthFormGroup.get("startDate").value;
    } else if (this.fourthFormGroup.get("projectDuration").value ===
    this.projectDuration.temporary) {
      this.project.startDate = this.fourthFormGroup.get("start").value;
    }
    this.project.userID = this.authService.userID;
    this.project.pageID = this.page._id;
    this.project.company = this.firstFormGroup.get("company").value;
    this.project.freelancer = this.firstFormGroup.get("freelancer").value;
    this.project.receivedApplicationBy = {
      selectedApplication: Links.Internal
    };
    this.projectService.createProject(this.project, false, this.page._id).subscribe(t => t);
     this.router.navigateByUrl(this.router.url.replace('/create_project', "/ourprojects"));

    //this.firstFormGroup.reset();
    //this.secondFormGroup.reset();
   // this.thirdFormGroup.reset();
    // this.fourthFormGroup.reset();
  }

  addTasks(data: EditOffer) {
    this.dialog.open(OfferDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.project.tasks = res.tasks;
      }
    });
  }

  backNavigate() {
    this.firstFormGroup.get('freelancer').clearValidators();
    this.firstFormGroup.get('company').clearValidators();
    this.firstFormGroup.get('freelancer').updateValueAndValidity();
    this.firstFormGroup.get('company').updateValueAndValidity();
  }

  changeCompany() {
    console.log(this.firstFormGroup.get('company').value);
    if (!this.firstFormGroup.get('company').value) {
      this.firstFormGroup.get('company').setValidators(Validators.requiredTrue);
      this.firstFormGroup.get('company').updateValueAndValidity({ onlySelf: true });
    }
  }
  changeFreelancer() {
    this.firstFormGroup.get('freelancer').updateValueAndValidity({ onlySelf: true });
  }

  removeTask(index) {
    this.project.tasks.splice(index, 1);
  }
  removeSkills(index) {
    this.project.skillsOffer.splice(index, 1);
  }
  removeSP(index) {
    this.project.pcKnowledge.splice(index, 1);
  }
  removeDriveLicense(index) {
    this.project.driveLicense.splice(index, 1);
  }
  removeLanguage(index) {
    this.project.language.splice(index, 1);
  }
  removeQualifications(index) {
    this.project.qualifications.splice(index, 1);

  }

  get selectedCountry() {
    return this.countryJson.find(r => r.ISO_2 === this.firstFormGroup.get("country").value);
  }
  get getDays() {
    var date1 = new Date(this.fourthFormGroup.get('start').value);
	var date2 = new Date(this.fourthFormGroup.get('end').value);

    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24);
    return Days;
  }

  changeCountry(event) {
    this.currency = event;
    this.project.currency = this.currency
  }

  changeCurrency(event) {
    this.currency = event;
    const object = this.countryJson.find((r) => r.ISO_2 === event);
    this.project.currency = object?.currency.Alphabeticcode;
  }



}
