<app-paginated-view [pageSize]="'A4'" *ngIf="model" class="Grid-grid-column">
  <ng-container>
    <div class="Grid-grid-row" pageContent class="row"
     >
      <div class="Grid-grid-column Grid-grid-column-12">
        <div class="Header-header-header Header-header-minHeight first-template-header">
          <div class="Title-title-titleWrapper first-template-titleWrapper">
            <h4 *ngIf="model?.hideName">{{model.personalData[0].firstName}} {{model.personalData[0].lastName}}</h4>
            <h5>{{model?.newJobTitle}}</h5>
            <div [innerHtml]="model?.description"></div>
          </div>
          <div class="Photo-photo-photoWrapper" *ngIf="model?.showCVPhoto">
            <div class="Photo-photo-photo first-template-photo">
              <img [src]="model?.photo" height="100" style="cursor:  pointer">
            </div>
          </div>
        </div>
        <div>
          <div clickOutside (clickOutside)="removeClick()">
            <ul>
              <li class="bi bi-pencil addIconTop" (click)="editHeaderDialog({edit: true, model: model})"></li>
              <li class="bi bi-plus addIconBottom" (click)="openDialog({model: model})"></li>
              <button class="btn"><i class="fa fa-eye-slash deleteIconRight"></i></button>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container class="Grid-grid-grid">
    <div class="Grid-grid-row">
      <div class="Grid-grid-column Grid-grid-column-6">
        <h1>Abedin Zhuniqi</h1>
      </div>
      <div class="Grid-grid-column Grid-grid-column-6">
      </div>
    </div>

  </ng-container>
</app-paginated-view>

