import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Status, Groups, Privacy, StatusPrivacy } from 'src/app/shared/enums/enums-model';
import { Contact, UserContact, Model } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserHelperService } from 'src/app/shared/utils/user-helper.service';
import { EditPersonalizedData } from '../modals/personaldata-dialog/personalized-data-dialog.component';
import { PrivacyActivity } from 'src/app/shared/services/privacy-activity.service';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.scss']
})
export class PrivacyDialogComponent implements OnInit {

  public friends: Contact[];
  public users: User[];
  public contacts: UserContact[] = [];
  public deletedFriends: Contact[] = [];
  public image;
  public data: Model;
  public term;
  public dialogTitle;
  public user: User;
  groups: typeof Groups = Groups;
  buttonDisabled = false;
  public offerArray = [];


  public privacyArray = [
    {id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone'},
    {id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts'},
    {id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text:'Your Followers'},
    {id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: ''}
  ]

  selectedId;
  constructor(public receiver: SenderService,
    public userHelper: UserHelperService,
    public authService: AuthService,
    public imageService: ImageService,
    public cd: ChangeDetectorRef,
    public userService: UserService,
    public titleService: ArraysService,
    private dialogRef: MatDialogRef<PrivacyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public enums: EditPrivacyDialog,
  ) { }

  ngOnInit(): void {
    this.titleService.getOfferArray();
    this.titleService.getOfferArrayProject();
    if (this.enums.offer) {
      this.selectedId = this.enums.enum;
    } else {
      console.log(this.enums, "enum,s")
      this.selectedId = this.enums.enum;
    }

    if (this.enums.isProject) {
      this.offerArray = this.titleService.offerArrayProject;
    } else {
      this.offerArray = this.titleService.offerArray;
    }


    this.userService.getUsers();
      this.userService.user().subscribe(res => {
        this.user = res;
    });
  }

  public doSomething(date) {

    if (this.enums.enum === Groups.Contacts_except  && !this.enums.personalData) {
      if (!this.user.contactExpect.some((item) => item.id === date.id)) {
        this.user.contactExpect.unshift(date);
      }
    } else if (this.enums.enum === Groups.Contacts_specific && !this.enums.personalData) {
      if (!this.user.specificContacts.some((item) => item.id === date.id)) {
        this.user.specificContacts.unshift(date);
      }
    }

    else if (this.enums.enum === Groups.Contacts_except && this.enums.personalData) {
      if (!this.user.contactExpect.some((item) => item.id === date.id)) {
        this.user.contactExpect.unshift(date);
      }
      this.buttonDisabled = false;
      this.cd.detectChanges();
      this.buttonDisabled = true;
    }
  }
  updatespecificContacts(friend) {
      if (this.enums.enum === Groups.Contacts_specific && this.enums.personalData) {
      if (!this.user.specificContacts.some((item) => item.id == friend.id)) {
        this.user.specificContacts.unshift(friend);
      }
    } else if (this.enums.enum === Groups.Contacts_specific && !this.enums.personalData)  {
      if (!this.user.specificContacts.some((item) => item.id == friend.id)) {
        this.user.specificContacts.unshift(friend);
      }
    }
  }
  deleteFromCV(index) {
    this.user.specificContacts.splice(index, 1);
  //  this.modelDataService.updatePage(this.enums.model._id, this.enums.model);

  }

  deleteFriend(index) {
    this.user.contactExpect.splice(index, 1);
  //  this.modelDataService.updatePage(this.enums.model._id, this.enums.model);
  }
  deleteFromSpecific(index) {
    this.user.specificContacts.splice(index, 1);
   //  this.modelDataService.updatePage(this.enums.model._id, this.enums.model);
  }
  deleteFromCVSpecific(index) {
    this.user.specificContacts.splice(index, 1);
//    this.modelDataService.updatePage(this.enums.model._id, this.enums.model);

  }

  saveData() {
   this.dialogRef.close({enums: this.selectedId, isJob: this.enums.isJob, isProject: this.enums.isProject});
  }


  updateContactExpect(contact: UserContact) {

    const contactData: UserContact = {
      contactId: contact.contactId,
      timestamp: new Date()
    }
    if (this.enums.enum === Groups.Contacts_except  && !this.enums.personalData) {
      if (!this.user?.contactExpect.some((item) => item.contactId === contact.contactId)) {
        this.user.contactExpect.unshift(contactData);
      }
    }
  }
  updateContactSpecific(contact: UserContact) {
    const contactData: UserContact = {
      contactId: contact.contactId,
      timestamp: new Date()
    }
    if (this.enums.enum === Groups.Contacts_specific  && !this.enums.personalData) {
      if (!this.user?.specificContacts.some((item) => item.contactId === contact.contactId)) {
        this.user.specificContacts.unshift(contactData);
      }
    }
  }

}

export interface EditPrivacyDialog {
  model?: Model;
  enum: number;
  personalData?: boolean;
  cv?: true;
  offer?: false;
  isProject?: false;
  isJob?: false;
}
