import {
  AdsManagerArray,
  Categories,
  Model,
  Phone,
  SearchDriveLicense,
  SearchLanguage,
  SearchPCKnowledge,
  SearchQualifications,
  SearchSkillOffer,
  StateAddress,
  UserContact,
  UserJobs,
  UserPage,
  UserProjects,
  UserSearchLocation,
} from "./model";

export class User {
  _id?: string;
  pAccount?: boolean;
  firstName?: string;
  lastName?: string;
  offer?: {
    timestamp: Date,
    s: number
  }
  email?: [
    {
      p?: number;
      name: string
    }
  ];
  password?: string;
  confirmPassword?: string;
  uniqueId?: string;
  userId?: string;
  isFriend?: boolean;
  status?: boolean;
  nationality?: {
    p: number;
    data: string;
  };

  ads: {
    p: number,
    data?: AdsManagerArray[];
  }
  relationship: {
    p: number;
    s: number;
    user?: {
      id: string,
      status: number
    }
    date?: Date
  };

  familyMembers: [{
    _id?: false,
    p: number,
    s: number,
    user?: {
      id: string
      s: Number
    }
  }];
  socialMedia: {
    links: SocialLinks[];
  }
  websites: Websites[];
  religion?: ReligionInterface;
  branches?: string;
  categories?: Categories;
  about?: String;
  phone?:  Phone[];
  birthday?: {
    p?: string;
    date?: string | Date;
  };
  title: number;

  address: {
    p: number;
    data?: StateAddress[];
  }
  gender?: number;
  language?: {
    primary?: string,
    secondary?: string
  };
  birthday_day?: string;
  birthday_month?: string;
  birthday_year?: string;
  places: {
    hometown: {
      p: number;
      name?: string,
      pageID?: string
    },
    current: {
      p: number;
      name?: string,
      pageID?: string
      date?: Date;
    },
    cities:  Cities[]
  };
  following?: {
    p: number;
    data: [{
      userId?: string;
      pageId?: string;
      status?: number;
      timestamp: Date;
      s?: number;
    }]
  };
  followers?: {
    p: boolean;
    counter: number;
  }
  notifications?: {
    followers?: {
      counter?: number,
      seen?: boolean
    }
  };
  contacts?: {
    p: number;
    data: UserContact[];
  }
  contactExpect?: [
    {
      id?: string;
      contactId?: string;
      timestamp?: Date;
    }
  ];
  specificContacts?: [
    {
      id?: string;
      contactId?: string;
      timestamp?: Date;
    }
  ];
  profilePicture?: string;
  coverPicture?: string;
  userUrl?: string;
  userName?: string;
  cv?: {
    p: number;
    data: Model[];
  }
  messages?: [
    {
      _id?: string;
      userID?: string;
      messageID?: string;
      timestamp: Date;
      jobID?: string;
      projectID?: string;
    }
  ];
  premium?: {
    status: number;
    timestamp: Date;
    endDate: Date;
  };
  pages?: UserPage[];
  jobs?: {
    p: number;
    data: UserJobs[];
  };

  projects?: {
    p: number;
    data: UserProjects[];
  }

  searchLocation?: {
    active: {
      s: number,
      from: Date
    };
    remote: number;
    data: UserSearchLocation[];
  };
  searchFilterSkills?: {
    searchPCKnowledge?: {
      p: number;
      data: SearchPCKnowledge[];
    }
    searchLanguages?: {
      p: number;
      data: SearchLanguage[];
    }
    searchSkillsOffer?: {
      p: number;
      data: SearchSkillOffer[];
    }
    searchDriveLicenses?: {
      p: Number,
      data: SearchDriveLicense[];
    }
    searchQualifications?: {
      p: number;
      data: SearchQualifications[];
    }
  };
  incomes?: {
    currency?: string;
    s?: string;
    budget?: number;
    hour: number;
    month: number;
    year: number;
  };
  remote?: number;
  savedJobs?: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  appliedJobs?: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  ignoredJobs: [
    {
      jobId: string;
      timestamp: Date;
    }
  ];
  savedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  appliedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  payment?: {
    counter: number,
    history: CreditsHistory[];
    transactions: Transactions[];
    CV: number;
    Page: number;
    Group: number;
    Job: number;
    Project: number;
    coins: number;
  };
  account?: {
    status?: number,
    startDate?: Date,
    endDate?: Date,
    deleted?: boolean;
  }
}

export interface CreditsHistory {
  date: Date;
  company: string;
  type_: string;
  account_number: string;
  amount: number;
  invoiceID: number
}
export interface Transactions {
  date: Date;
  company: String;
  type: String;
  account_number: String;
  reference: String;
  status: String;
  amount: Number;
  invoiceID: number
}

export class Cities {
    name?: string;
    pageID?: string;
    date?: Date;
    p?: number
}

export class SocialLinks {
  url: string;
  id: string;
  p: number;
}

export class Websites {
  url: string;
  p: Number;
}


export class ReligionInterface {
    name?: string;
    p?: number;
    pageID?: string
}


export interface UserUser {
  _id: string;
  about: string;
  places: Places
  contacts: Contacts
  messages: Messages
  jobs: Jobs
  ads: Ads
  projects: Projects
  cv: Cv
  following: Following
  premium: Premium
  paymentList: PaymentList
  socialMedia: SocialMedia
  websites: Website[]
  groups: Groups
  pages: any[]
  savedJobs: any[]
  savedProjects: any[]
  familyMembers: any[]
  xUserID: string
  offer: Offer
  incomes: Incomes
  religion: Religion
}


export interface Places {
  cities: Cities
}



export interface Contacts {
  data: any[]
}

export interface Messages {
  data: Daum[]
}

export interface Daum {
  messageListID: string
}

export interface Jobs {
  data: Daum2[]
  p: number
}

export interface Daum2 {
  jobListID: string
}

export interface Ads {
  data: Daum3[]
  p: number
}

export interface Daum3 {
  adsListID: string
}

export interface Projects {
  data: Daum4[]
  p: number
}

export interface Daum4 {
  projectListID: string
}

export interface Cv {
  data: Daum5[]
  p: number
}

export interface Daum5 {
  cvID: string
}

export interface Following {
  data: Daum6[]
  p: number
}

export interface Daum6 {
  followingID: string
}

export interface Premium {
  timestamp: Timestamp
}

export interface Timestamp {
  $date: number
}

export interface PaymentList {
  data: Daum7[]
  p: number
}

export interface Daum7 {
  paymentListID: string
}

export interface SocialMedia {
  links: Link[]
}

export interface Link {
  p: number
  id: string
  url: string
}

export interface Website {
  p: number
  url: string
}

export interface Groups {
  data: any[]
}

export interface Offer {
  s: number
}

export interface Incomes {
  s: string
  budget: number
  hour: number
  month: number
  year: number
}

export interface Religion {
  name?: string
  p?: number
  pageID?: string
}

