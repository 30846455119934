
<div class="card">
  <ul
    class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0 ms-3">
    <li class="nav-item">
      <a class="nav-link" [routerLinkActive]="['active']"
        [routerLink]="'/' + userData.user?.userUrl + '/connections/followers'">
        {{ "followers" | translate }}
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
        [routerLink]="'/' + userData.user.userUrl + '/connections/following'">
        {{ "following" | translate }}
      </a>
    </li>
    <!--  <li class="nav-item">
      <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
        [routerLink]="'/' + this.user?.userUrl + '/connections/contacts'">
        {{ "header.contacts" | translate }}
      </a>
    </li> -->
    <!--  <li class="nav-item">
      <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
        [routerLink]="'/' + this.user?.userUrl + '/connections/hashtags'">
        {{ "hashtags" | translate }}
      </a>
    </li> -->

  </ul>

<div class="card-body position-relative pt-0 mt-4">
  <div class="row g-4">
    <ng-container *ngFor="let userFollowing of userData.followings.data">
      <div class="col-sm-6" *ngIf="userFollowing.s !== 9">
        <app-user-connections [userFollowing]="userFollowing" [following]="true"
          [loggedUser]="loggedUser"></app-user-connections>

        <!-- Birthday START -->
        <!-- Birthday END -->
      </div>
    </ng-container>

  </div>



</div>



</div>
