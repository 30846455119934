

<main>

    <!-- Container START -->
    <div class="container">
          <div class="row gx-0">
        <!-- Sidebar START -->
        <div class="col-lg-4 col-xxl-3" id="chatTabs" role="tablist">

          <!-- Divider -->
                  <div class="d-flex align-items-center mb-4 d-lg-none">
                      <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarNotice" aria-controls="offcanvasNavbarNotice">
                          <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">Notice</span>
                      </button>
                  </div>
                  <!-- Advanced filter responsive toggler END -->

          <div class="card card-body border-end-0 border-bottom-0 rounded-bottom-0">
            <div class=" d-flex justify-content-between align-items-center">
              <h1 class="h5 mb-0">Status</h1>
              <!-- Chat new create message item START -->
              <div class="dropend position-relative">
                <div class="nav">
                  <a class="icon-md rounded-circle btn btn-sm btn-primary-soft nav-link toast-btn" [mat-dialog-close]="true" ><i class="bi bi-x-lg"></i></a>
                </div>
              </div>
              <!-- Chat new create message item END -->
            </div>

            <div class="col-sm-12 col-lg-12">
                <select class="form-select js-choice" [ngModel]="issue?.status" (ngModelChange)="remoteCheck($event)" id="selectedBranch" name="selectedBranch" #selectedBranch="ngModel"
                [(ngModel)]="issue.status">
                  <option *ngFor="let level of issueStatus" [ngValue]="level.id" [selected]="level" (change)="remoteCheck(level)">    {{ level.description | translate }}</option>
                </select>
              </div>
          </div>

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbarNotice">
              <!-- Offcanvas header -->
                          <div class="offcanvas-header">
                              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"></button>
                          </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <div class="card card-chat-list rounded-end-lg-0 card-body border-end-lg-0 rounded-top-0">

                  <!-- Search chat START -->
                  <form class="position-relative" [formGroup]="noticeForm">
                    <h1 class="h5 mb-0">Notice</h1>
                    <ng-container *ngIf="isEditingNote; else readonlyModeTmplNote ">
                        <textarea
                        [rows]="3" cdkTextareaAutosize formControlName="notice" (change)="addNewNote($event)" [ngModel]="issue?.note" class="textarea-inline-control textarea-border" [innerHTML]="issue?.note | noSanitize" >
                        </textarea>
                        <div class="pt-3 flex
                        items-center">
                          <button   class="btn btn-sm btn-primary-soft ms-2" (click)="saveNote()">
                            <i class="bi bi-check"></i>
                             </button>

                        </div>
                    </ng-container>

                    <ng-template #readonlyModeTmplNote>
                                  <textarea   [rows]="3" cdkTextareaAutosize class="textarea-inline-control textarea-border" [innerHTML]="issue?.note | noSanitize"  *ngIf="!isEditingNote"
                                  (click)="setNoteEditMode(true)">
                                    </textarea>
                    </ng-template>
                  </form>

                </div>
              </div>
            </div>
          </nav>
        </div>
        <!-- Sidebar START -->

        <!-- Chat conversation START -->
        <div class="col-lg-8 col-xxl-9">
          <div class="card card-chat rounded-start-lg-0 border-start-lg-0">
            <div class="card-body h-100">
              <div class="tab-content py-0 mb-0 h-100" id="chatTabsContent">
                <!-- Conversation item START -->
                <div class="fade tab-pane show active h-100" id="chat-1" role="tabpanel" aria-labelledby="chat-1-tab">
                  <!-- Top avatar and status START -->
                  <div class="d-sm-flex justify-content-between align-items-center">
                    <div class="d-flex mb-2 mb-sm-0">
                        <div class="flex-shrink-0 avatar me-2">
                          <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
                        </div>
                        <div class="d-block flex-grow-1" *ngIf="!page">
                          <h6 class="mb-0 mt-1"> {{appliedUser?.firstName}} {{appliedUser?.lastName}}</h6>
                          <div class="d-flex align-items-center">
                            <button  (click)="goToCV({user: true, data: appliedUser})" class="py-1 btn btn-sm btn-primary-soft col-12 me-1">{{'header.cv' | translate}}</button>
                          </div>
                        </div>
                        <div class="d-block flex-grow-1" *ngIf="page">
                            <h6 class="mb-0 mt-1"> {{page?.name}}</h6>
                            <div class="d-flex align-items-center">
                                <button  (click)="goToPage()" class="py-1 btn btn-sm btn-primary-soft col-12 me-1">{{'header.more' | translate}}</button>
                              </div>
                          </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <!-- Call button -->
                        <!-- Card action START -->
                        <div class="dropdown">
                          <a class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" href="#" id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></a>
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
                            <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
                            <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a></li>
                            <li  *ngIf="page" (click)="goToPage()" ><a class="dropdown-item"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                            <li  *ngIf="!page" (click)="viewProfile()" ><a class="dropdown-item"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
                                  <li><a class="dropdown-item" (click)="deleteChat()" ><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>
                            <li class="dropdown-divider"></li>
                            <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
                          </ul>
                        </div>
                        <!-- Card action END -->
                      </div>
                  </div>
                  <!-- Top avatar and status END -->
                  <hr>
                  <!-- Chat conversation START -->
                  <div class="chat-conversation-content custom-scrollbar">
                    <!-- Chat time -->

                    <ng-container *ngIf="!!checkIDOfUserPage">
                      <div class="text small my-2" >{{"Message"}} {{user?.firstName}} {{user?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                    </ng-container>
                    <ng-container *ngIf="!checkIDOfUserPage">
                      <div class="text small my-2"  *ngIf="!page">{{"Message"}} {{appliedUser?.firstName}} {{appliedUser?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                      <div class="text small my-2" *ngIf="page">{{"Message"}} {{page?.name}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

                    </ng-container>

                   <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHTML]="issue?.message?.description" disabled></div>
                   <div class="row mt-1 mb-1 align-items-center" *ngIf="issue?.cancel.status !== 2">
                     <p *ngIf="isProject" style="width: fit-content;">{{'projectText' | translate}}: </p>
                     <p  *ngIf="!isProject" style="width: fit-content;">{{'jobText' | translate}}: </p>

                      <button class="btn btn-sm py-1 btn-primary me-2 col-lg-3 col-sm-12"  *ngIf="issue?.cancel.status === 0" (click)="apply(data)">{{'job.apply' | translate}}</button>
                      <button class="btn btn-sm py-1 btn-danger-soft col-lg-3 col-sm-12" (click)="cancelProjectData()">{{'cancel' | translate}} </button>
                    </div>
                    <div class="mt-1 mb-1 align-items-center" *ngIf="issue?.cancel.status === 2">
                      <ng-container *ngIf="!issue.cancel.pageId">
                        <p style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledUser?.firstName}} {{cancelledUser?.lastName}} <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
                      </ng-container>
                      <ng-container *ngIf="!!issue.cancel.pageID && !!issue.cancel.userID">
                        <p *ngIf="!cancelledPage" style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledUser?.firstName}} {{cancelledUser?.lastName}} <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
                        <p *ngIf="!!cancelledPage " style="width: fit-content;" class="text small my-2">{{'projectText' | translate}} Cancelled: {{cancelledPage?.name}}  <span> - {{jobManage.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>

                      </ng-container>

                      <div class="bg-light text-secondary p-2 px-3 rounded-2" *ngIf="issue?.cancel.description" [innerHTML]="issue?.cancel.description" disabled></div>
                      <button class="btn btn-sm py-1 btn-primary me-2 col-lg-3 col-sm-12 mt-1" (click)="reApply(data)">{{'job.apply' | translate}}</button>

                  </div>
                  <div *ngIf="cancelProject">
                    <div class="d-sm-flex align-items-end">
                      <quill-editor name="editor" [styles]="editorConfig"  style="width:100%" [modules]="config" [(ngModel)]="cancelMessage" >
                      </quill-editor>
                      <button (click)="cancelProjectButton()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
                    </div>
                  </div>

                    <hr>

                    <ng-container *ngFor="let comment of issue?.comments">
                      <div class="d-flex mb-1" *ngIf="comment.userID === issue.userID">
                          <div class="flex-shrink-0 avatar avatar-xs me-2">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
                          </div>
                          <div class="flex-grow-1">
                            <div class="w-100">
                              <div class="d-flex flex-column align-items-start">
                                <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
                                <div class="small my-2" *ngIf="!comment?.pageID">  {{appliedUser?.firstName}} {{appliedUser?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

                                <div class="small my-2" *ngIf="comment?.pageID"> {{page?.name}}  {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
                                  </div>
                            </div>
                          </div>
                        </div>
                          <!-- Chat message right -->
                          <div class="d-flex justify-content-end text-end mb-1" *ngIf="checkId(comment)">
                              <div class="w-100">
                                <div class="d-flex flex-column align-items-end">
                                  <div class="bg-primary text-white p-2 px-3 rounded-2" style="text-align: left;" [innerHtml]="comment.body"></div>
                                  <ng-container>

                                    <app-user-dialog-name  [isForMessages]="true" [commentId]="comment.userID"  [comment]="comment"></app-user-dialog-name>
                                  </ng-container>
                                    </div>
                              </div>
                            </div>


                  </ng-container>
                  </div>                  <!-- Chat conversation END -->
                </div>

                <!-- Conversation item END -->
                <!-- Conversation item START -->

                <!-- Conversation item END -->
              </div>
            </div>
            <div class="card-footer">
                <div class="d-sm-flex align-items-end">
                    <quill-editor name="editor" [styles]="editorConfig"  style="width:100%" [modules]="config" [(ngModel)]="writeMessage" >
                    </quill-editor>
                    <button class="btn btn-sm btn-danger-soft ms-sm-2"><i class="fa-solid fa-face-smile fs-6"></i></button>
                    <button class="btn btn-sm btn-secondary-soft ms-2"><i class="fa-solid fa-paperclip fs-6"></i></button>
                    <button (click)="addCommentProject()" [disabled]="!writeMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
                  </div>
            </div>
          </div>
        </div>
        <!-- Chat conversation END -->
      </div> <!-- Row END -->
      <!-- =======================
      Chat END -->

      </div>
    <!-- Container END -->

  </main>
