import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Chat, Messages, MessagesResponse } from "../../shared/interfaces/message";
import { MessageHelperService } from "../../shared/utils/message-helper.service";
import { AuthService } from "../../shared/services/auth.service";
import { Image, Job, Model, Page, Project } from "../../shared/interfaces/model";
import { UserHelperService } from "../../shared/utils/user-helper.service";
import { ImageService } from "../../shared/services/image.service";
import { Router } from "@angular/router";
import * as moment from 'moment';

import { UserService } from "src/app/shared/services/user.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { share, shareReplay } from "rxjs/operators";
import { User } from "src/app/shared/interfaces/user.model";
import { JobsService } from "src/app/shared/services/jobs.service";
import { UntypedFormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserJobDialog, UserJobDialogComponent } from "src/app/user-job-dialog/user-job-dialog.component";
import { DataDialogComponent } from "../modals/data-dialog/data-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { ProjectService } from "src/app/shared/services/project.service";
import { ChangeDetectionService } from "src/app/shared/services/change-detection.service";
import { MessageList } from "src/app/shared/interfaces/messageList.model";
@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy {
  public messages: Messages[] = [];
  public getMessages: Messages[] = [];
  contacts: User[] = [];
  public image;
  public data: Model;
  public dataProject: Project;
  public dataJob: Job;
  public user: User;
  public userEmitter: User;
  public outUser: User;
  public authUser: User;
  public myData: Model;
  public userApplied: User;
  showMessages = false;
  selectedIndex;
  userSelected;
  jobSelected;
  public page: Page;
  isEditing: boolean;
  createMode: boolean;
  public authImage;
  public subscription: Subscription;
  public userMessages: MessageList;
  public configSend = {
    toolbar: false
  };

  writeMessage = "";

  config = {
    "emoji-textarea": true,
    toolbar: false,
  }
  editorConfig = {
    border: "none",
    padding: "0",
    height: "inherit !important"
  }
  public message = "";
  public getMessage: Messages;
  public selectedUser = "";
  public selectedJob = "";
  public projectSelected;
  public selectedProject;

  public config_mention;
  public users: User[] = [];
  public userForLanguage: Model;

  public jobManage;
  public jobManageProject;
  public jobmanageJob;
  userJob: Job;
  project: Project;
  job: Job;
  jobUser: User;
  @ViewChild("messagesThread") private messageContainer: ElementRef;

  cancelledUser: User;
  commentControl: UntypedFormControl;

  public loggedUser: Observable<any>;
  public currentUser: any;

  constructor(public msgHelperService: MessageHelperService,
    public authService: AuthService,
    private userHelper: UserHelperService,
    public imageService: ImageService,
    private router: Router,
    private userService: UserService,
    public sendData: SenderService,
    public receiver?: SenderService,
    public jobService?: JobsService,
    public senderService?: SenderService,
    public translateService?: TranslateService,
    public pageService?: PagesService,
    public dialog?: MatDialog,
    private projectService?: ProjectService,
    private changeDetectionService?: ChangeDetectionService,
    private cd?: ChangeDetectorRef
  ) { }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();

    }
  }

  ngOnInit(): void {
    /* this.modelDataService.getUserModel(this.authService.userID).subscribe(res => {
      this.myData = res;
      this.data = res;
      this.sendData.send(this.data);
    }); */



    this.commentControl = new UntypedFormControl('');


   /* this.userHelper.loadRequestingFriends(this.authService.userID).subscribe(finalRequesters => {
      this.contacts = finalRequesters;
    }); */
   /* this.msgHelperService.getYourMessages(this.authService.userID).subscribe((response: Messages[]) => {
      this.messages = response;
      this.messages.map(t => t.timestamp)
      const date = new Date();
      this.messages = this.messages.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
      const getId = this.user.messages.map(res => res.userId).join("");

    }) */
    /*  this.authService.getUserProfile(this.authService.userID).pipe(shareReplay(1)).subscribe(userT => {
        this.authUser = userT;
      }) */
    this.userService.userModel().subscribe(res => {
      this.users = res;
    });

    this.changeDetectionService.emmitChanges.subscribe(t => {
      if (!!t) {
        this.projectSelected = false;
        this.userService.getMessages().subscribe(res => {
          this.userMessages = res[0];
          if (this.userMessages.open[0].jobID) {
            this.selectProject(this.userMessages.open[0], 0)
          } else {
            this.selectProject(this.userMessages.open[0], 0)
          }
        });
      }
    });
    this.userService.getMessageList(this.authService.userID).subscribe(res => {
      this.userMessages = res[0];
      if (this.userMessages.open[0].jobID) {
        this.selectProject(this.userMessages.open[0], 0)
      } else {
        this.selectProject(this.userMessages.open[0], 0)
      }
    });



    this.loggedUser = this.userService.userList;
    this.loggedUser.subscribe(res => {
      this.currentUser = res;
    })



  }
  selectUser(userSelected: Chat) {
    this.receiver.dataUpdate$.subscribe((success: any) => {
      this.userForLanguage = success;
      moment.locale(this.userForLanguage.language.primary);
    });
    this.config_mention = {
      toolbar: false,
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        linkTarget: "_blank",
        onSelect(item, insertItem) {
          insertItem(item)
        },

        source: (searchTerm, renderList, mentionChar) => {

          let values = [{ id: "", value: "", link: null }];

          if (mentionChar === "@") {
            this.users.forEach(t => {
              values.unshift({ id: t._id, value: `${t.firstName} ${t.lastName}`, link: window.location.origin + '/' + t._id + "/cv" });
            });


          } else if (mentionChar === "@") {


          }
          if (searchTerm.length === 0) {

            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.unshift(values[i]);
              }


              renderList(matches, searchTerm);

            }
          }
        },
      },
    }

    if (userSelected.emitterId === this.authService.userID) {
      this.selectedUser = userSelected.emitter;
    } else {
      this.selectedUser = userSelected.emitterId;
    }
    this.userService.getUserContactData(userSelected.emitterId).subscribe(t => {
      this.outUser = t;
    })
    this.userSelected = true;
    this.msgHelperService.getMessages(this.authService.userID, this.selectedUser).subscribe((response: Messages[]) => {
      this.getMessages = response;
      /*this.modelDataService.getUserModel(this.selectedUser).subscribe((res: Model) => {
        this.data = res;
        if (res.profilePicture) {
          this.imageService.getImageByID(res.profilePicture).subscribe((img: Image) => {
            this.image = img.imageUrl;
          })
        }*/
    })
    /*  this.modelDataService.getUserModel(this.authService.userID).subscribe((res: Model) => {
       if (res.profilePicture) {
         this.imageService.getImageByID(res.profilePicture).subscribe((img: Image) => {
           this.authImage = img.imageUrl;
         })
       }
     })
   }) */
    this.userService.getUserContactData(this.selectedUser).subscribe(res => {
      this.userEmitter = res;
    })
    this.scrollMessageContainer();

  }
  selectProject(event, i) {
    this.selectedIndex = i;
    this.projectSelected = event;
    this.userMessages.open = [];
    if (!!event.projectID) {
      this.userMessages.open.unshift({projectID: event.projectID});
      this.projectService.getProject(event.projectID).subscribe((t: Project) => {
        this.project = t;
        this.senderService.sendProjectForMessage({data: this.project, isProject: true});
      });
  //    this.selectedProject = true;
    } else if (event.jobID) {
      this.userMessages.open.unshift({jobID: event.jobID});
      this.jobService.getJob(event.jobID).subscribe((t: Job) => {
        this.job = t;
        this.senderService.sendJobForMessage({data: this.job, isJob: true})
      })
    }

    this.userService.updateMessageList(this.userMessages._id, this.userMessages);


  }

  selectJob(event) {
    console.log(event)
    this.selectedJob = event.jobId;
    this.jobService.getJob(event.jobId).subscribe((t: Job) => {
      this.userJob = t;
     let id =  this.userJob.manage_.find(t => t.userID === this.authService.userID).userID;
     let cancelId =  this.userJob.manage_.find(t => t.userID === this.authService.userID).cancel.userID;
     this.userService.getUserContactData(id).subscribe(tt => {
      this.userApplied = tt;
    })
    this.userService.getUserContactData(cancelId).subscribe(tt => {
      this.cancelledUser = tt;
    })
      if (this.userJob.pageID) {
        this.pageService.getPage(this.userJob.pageID).subscribe(res => {
          this.page = res;
        })
      }
      this.userService.getUserContactData(t.userID).subscribe(tt => {

        this.jobUser = tt;
      })
      this.jobManage = t.manage_.filter(t => t.userID === this.authService.userID);

    })
    this.jobSelected = true;
    this.selectedProject = false;
  }


  apply(userJob: Job) {
    this.userJob.manage_.find(t => t.userID === this.authService.userID).cancel.status = 1;
  //  this.jobService.updateJob(this.userJob._id, this.userJob);

    this.jobManage[0].cancel = 1;
  }
  cancel() {
    localStorage.setItem('userID', this.authService.userID);
    this.senderService.sendDataForApply(this.userJob);
    this.router.navigateByUrl("jobs/public/" + this.userJob._id + "/cancel");
  }

  cancelComment() {
    this.commentControl.reset();
    this.createMode = false;

  }
  alignMessage(userId: string): boolean {
    return this.authService.userID !== userId;
  }
  /* public sendMessage() {
    const content = {
      value: this.message,
      timestamp: new Date(),
      emitter: this.authService.userID,
      emitterId: this.selectedUser,
    }
    this.getMessages.map(res => res.chat.unshift(content));
    const getID = this.getMessages.map(res => res.id).join("");
    this.getMessages.map(res => {
      this.getMessage = res;
      this.getMessage.timestamp = new Date();
    })
    this.msgHelperService.updateChat(getID, this.getMessage).subscribe();
    this.message = "";

  } */

  sendMessage() {

    if (this.projectSelected.projectID) {
      this.subscription = this.receiver.dataMessageForProject.subscribe((success: any) => {
        this.dataProject = success.data;

        this.jobManageProject = this.dataProject?.manage_.filter(t => t.userID === this.authService.userID);
        this.jobManageProject[0].seen = [] as any;
        const now = new Date();
        this.jobManageProject[0]?.comments.unshift({
          createdAt: now.toISOString(),
          body: this.writeMessage,
          userID: this.authService.userID
        });



        const findIndex = this.user?.messages?.findIndex(t => t?.projectID === this.dataProject._id);
        if (!!findIndex) {
          this.arraymove(this.user.messages, findIndex, 0);
          this.userService.updateUser(this.user._id, this.user)

        }
        if (!!this.writeMessage) {
          this.projectService.updateProject(this.dataProject._id, this.dataProject);
          this.writeMessage = "";
        }
      });
    } else if (this.projectSelected?.jobID) {
      this.subscription =   this.receiver.dataMessageForJob.subscribe((success: any) => {
        this.dataJob = success.data;
        this.jobmanageJob = this.dataJob?.manage_.filter(t => t.userID === this.authService.userID);
        const now = new Date();

        this.jobmanageJob[0].comments.unshift({
          createdAt: now.toISOString(),
          body: this.writeMessage,
          userID: this.authService.userID
        });

        const findIndex = this.user?.messages?.findIndex(t => t?.jobID === this.dataJob._id);
        if (!!findIndex) {
          this.arraymove(this.user.messages, findIndex, 0);
          this.userService.updateUser(this.user._id, this.user)
        }

        if (!!this.writeMessage) {
          this.jobService.updateJob(this.dataJob._id, this.dataJob);
          this.writeMessage = "";
        }

      });

    }


  }

  scrollMessageContainer(): void {
    if (this.messageContainer !== undefined) {
      try {
        setTimeout(() => {
          this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        }, 10);
      } catch (error) {
        console.warn(error);
      }
    }
  }
  setCommentEditMode(mode: boolean) {
    this.createMode = mode;
    this.isEditing = mode;
  }

  getDateBetween(date: Date, emitterId) {
    let a = moment(date);
    let b = moment(date);
    let date_day = moment(date);
    let newDateVar = date_day.date();
    if (a.date() !== b.date()) {
      return true;
    } else {
      return false;
    }
  }

  public getDate(date: Date) {
    let date_day;

    this.receiver.dataUpdate$.subscribe((success: any) => {
      this.userForLanguage = success;
      moment.locale(this.userForLanguage.language.primary);

      date = new Date(date);
      const data = this.userForLanguage.language.primary;
      if (data === "de") {
        date_day = new Intl.DateTimeFormat('de-DE', { weekday: 'long' }).format(date);
      } else if (data === "en") {
        date_day = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);
      }
      var Difference_In_Time = new Date().getTime() - date.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days < 7 && Difference_In_Days > 2) {
        date_day;
      } else if (Difference_In_Days < 1) {
        date_day = "Heute";
      } else if (Difference_In_Days > 7) {
        if (data === "de") {
          var month = new Intl.DateTimeFormat('de-DE', { month: 'long' }).format(date);
          var day = date.getUTCDate();
          date_day = day + " " + month;
        } else if (data === "en") {
          var month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
          var day = date.getUTCDate();
          date_day = month + " " + day;
        }

      }
    });
    return date_day;
  }
  public navigateToUser() {
    this.router.navigate([this.data.userUrl + "/start"]);
  }
  navigateToMyAcc() {
    this.router.navigate([this.myData.userUrl + "/start"]);
  }

  public getTimeAndDate(date) {
    return this.getDate(date);
  }

  openJob(data: UserJobDialog) {
    this.dialog.open(UserJobDialogComponent, {
      data,
    });
  }

  addComment() {
    const now = new Date();
    this.jobManage[0].comments.unshift({
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      body: this.commentControl.value,
      userId: this.authService.userID
    });
    this.createMode = false;
    this.isEditing = false;
    this.jobService.updateJob(this.userJob._id, this.userJob);
    this.commentControl.reset()
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
}
