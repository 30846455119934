import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Model, PersonalData, Location, Image, EducationSubCategory, UserSearchLocation } from "../../shared/interfaces/model";
import { Observable } from "rxjs";
import { Category, Status, Groups, MaritualStatus, Remote, SearchActive, Title, StatusTitle, StatusPrivacy } from "src/app/shared/enums/enums-model";
import { MatDialog } from "@angular/material/dialog";
import {
  CategoryDialog,
  CategoryDialogComponent,
} from "../modals/category-dialog/category-dialog.component";
import {
  EditHeaderDialog,
  HeaderDialogComponent,
} from "../modals/header-dialog/header-dialog.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { EditEducation, EducationDialogComponent } from "../modals/education-dialog/education-dialog.component";
import { EmptySubFile, EmptyFileComponent } from "../files/empty-file/empty-file.component";
import { EmptyDataDialogComponent, EmptySubCategory } from "../modals/empty-data-dialog/empty-data-dialog.component";
import { EmptyCategoryDialogComponent } from "../modals/editors/empty-category-dialog/empty-category-dialog.component";
import { SubCareerDialogComponent } from "../career/sub-career-dialog/sub-career-dialog.component";
import { EditCareer, DataDialogComponent } from "../modals/data-dialog/data-dialog.component";
import { Subscription } from "rxjs/internal/Subscription";
import { HttpClient } from "@angular/common/http";
import { NavigationEnd, Router } from "@angular/router";
import { PdfService } from "src/app/shared/services/pdf.service";
import { TranslateService } from "@ngx-translate/core";
import { ImageService } from "src/app/shared/services/image.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { SenderService } from "src/app/shared/services/sender.service";
import { FriendService } from "src/app/shared/services/friend.service";
import _ from "underscore";
import { PrivacyActivity } from "src/app/shared/services/privacy-activity.service";
import { EditPrivacyDialog, PrivacyDialogComponent } from "../privacy-dialog/privacy-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { User } from "src/app/shared/interfaces/user.model";
import jsPDF from "jspdf";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { take } from "rxjs/operators";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { LanguageDialogComponent } from "../modals/editors/language-dialog/language-dialog.component";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ShareDialogComponent } from "../modals/share-dialog/share-dialog.component";

@Component({
  selector: "app-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
  styles: [`
  :host {
    width:100%
  }`]
})
export class OverviewComponent implements OnInit, AfterViewInit, OnChanges {
  fileToUpload: File = null;
  currentUser: User;
  public userID = this.authService.userID;
  public select: boolean;
  public isSelected = false;
  public model$: Observable<Model>;
  public model: Model;
  public modelCV: Model;
  public selectedIndex: number;
  public selectedFileIndex: number;
  public selectedCategory: number;
  public selectedFirstCat = false;
  public category = Category;
  public selectedEmptySubCat: number;
  public selectedCareerIndex: number;
  @ViewChild("content", { static: false }) content: ElementRef;
  private subscriptions: Subscription;
  public color9 = "#278ce2";
  public color8 = "#eb4a3b";
  public color7 = "#567995";
  public color6 = "#caa878";
  public iconsVisible = false;
  selectedThemeVisibleColor: number;
  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;
  public getGender;
  public getTitle;
  public title;
  public getStatus;
  public image;
  public icon;
  public text;
  public iconOffer;

  public textOffer;
  groups: typeof Groups = Groups;
  public userUrl;
  matchingElement = false;
  checkFriendId = false;
  showPublicCV = false;
  showFriends = false;
  friendExpect = false;
  onlyMe = false;
  showPersonalDataFriends = false;
  showPersonaDataPublic = false;
  friendExpectPersonalData = false;
  followers = false;
  cvLanguage;
  public locations = new Location();
  selectSearch: boolean = false;
  selectedSubCategory = "";

  uUser: User;
  indexOfCategory
  selectedIndexLanguage = 0;
  empty;

  public privacyArray = [
    { id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone' },
    { id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts' },
    { id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text: 'Your Followers' },
    { id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '' }
  ]

  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite" },
    { id: Remote.remote, description: "project.firstPage.remote" },
  ];

  public searchActive = [
    { id: SearchActive.Active, description: "searchPrivacy.active" },
    { id: SearchActive.NotActive, description: "searchPrivacy.notActive" },
  ]

  showActiveDate: boolean = false;


  selectedSearchFilter: number;
  public get selectedLanguage() {
    return localStorage.getItem("cvLanguage")
  }

  public themes = [
    { id: 0, name: "firstTemplate", image: "assets/img/themes/firstTheme.png" },
    { id: 1, name: "secondTemplate", image: "assets/img/themes/secondTheme.png" },
  ];
  public housingTypeOptions = [
    { id: StatusTitle.Mr, description: "data.mr" },
    { id: StatusTitle.Mrs, description: "data.mrs" },
  ];
  public statusObject = [
    { id: 1, name: MaritualStatus.Single, description: "status.single" },
    { id: 2, name: MaritualStatus.Married, description: "status.married" },
    { id: 0, name: MaritualStatus.NotKnow, description: "status.nk" }
  ];

  public language = [
    { id: "de", value: "de_DE", lang: "de-de", description: "Deutsch" },
    { id: "en", value: "en_US", lang: "en-us", description: "English" },
    { id: "es", value: "es_ES", lang: "es-es", description: "Español" },
    { id: "fr", value: "fr_FR", lang: "fr-fr", description: "Français" },
    { id: "it", value: "it_IT", lang: "it-it", description: "Italiano" },
    { id: "sq", value: "sq_AL", lang: "sq-al", description: "Shqip" },
    { id: "tr", value: "tr_TR", lang: "tr-tr", description: "Türkçe" },
  ];

  toggleOptions: Array<String> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  public selectLanguage;

  outsideUserId;
  checkFriend: User[] = [];
  public isFriend = false;
  public isRequest = false;
  public user$: Observable<User>;
  public hasFollow = false;

  selectedCountry: any;
  countryJson;
  @Input()
  public value: Readonly<PersonalData>;
  public url: string | ArrayBuffer;
  cvLanguageSecondary;
  userCV: any;

  public dataList$: Observable<any>;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
    public http: HttpClient,
    public router: Router,
    public pdfService: PdfService,
    public translate: TranslateService,
    public imageService: ImageService,
    public receiver?: SenderService,
    public friendService?: FriendService,
    public privacyActivity?: PrivacyActivity,
    public userService?: UserService,
    public ngZone?: NgZone,
    public titleService?: ArraysService,
    public countryDataService?: CountryDataService,
    public languageService?: LanguagesService,
    public categoryService?: CategoryService,
    public branchesService?: BranchesService,



  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.endsWith("/cv")) {
          event.url = event.url.replace("/cv", "");
          event.url = event.url.replace("/", "");
          event.url = event.url.replace("cv", "");
          event.url = event.url.replace("/:id", "");
          this.outsideUserId = event.url;
        } else {
          event.url = event.url.replace("/cvm", "");
          event.url = event.url.replace("/", "");
          event.url = event.url.replace("cvm", "");
          event.url = event.url.replace("cv/search_locations", "")
          event.url = event.url.replace("cv/branch", "")
          event.url = event.url.replace("cv/privacy", "")
          this.outsideUserId = event.url;
          this.receiver.setWeb = false;

        }
      }
    });
  }
  ngOnInit() {
    this.titleService.showTitles();
    this.categoryService.getCategory()
    this.categoryService.getCategories();
    this.branchesService.getBranches();
    this.titleService.getOfferArray();

    this.languageService.getPrimaryLanguages();
    if (!this.authService.isLoggedIn && !this.outsideUserId) {
      this.http.get<any>("assets/muster.json").subscribe(data => {
        this.currentUser = data;
        this.model = data.data.cv[this.selectedIndexLanguage];
        this.modelCV = data.data.cv;
      })
    }
    if (!this.authService.isLoggedIn && this.outsideUserId) {
      this.subscriptions = this.userService.getUserCVwithoutLogin(this.outsideUserId).subscribe(res => {
        this.currentUser = res.data;
        //   this.privacyIcon = this.currentUser;
        //  this.privacyText = this.currentUser;
        this.privacyTextOffer = this.currentUser;
        this.privacyIconOffer = this.currentUser;
        this.model = res?.data.cv[this.selectedIndexLanguage];
        this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.currentUser?.categories?.name);

        const selectedLanguage = localStorage.getItem("cvLanguage");
        if (!!selectedLanguage) {
          this.translate.use(selectedLanguage)
        } else {
          this.translate.use(this.model.language.primary)
        }
        if (this.currentUser?.userUrl) {
          this.userUrl = this.currentUser?.userUrl;
        }
      });
    }
    if (this.authService.isLoggedIn) {
      if (this.outsideUserId) {
        this.privacyActivity.getPrivacyActivity();
        this.dataList$ = this.userService.dataList;


        this.dataList$.subscribe(result => {
          if (Object.keys(result).length !== 0) {
            this.currentUser = result.user;
            this.uUser = result.userUser;
            this.userCV = result.cv;
            this.privacyIcon = result?.cv?.data[this.selectedIndexLanguage];
            this.privacyText = result?.cv?.data[this.selectedIndexLanguage];
            this.privacyTextOffer = this.uUser;
            this.privacyIconOffer = this.uUser;



            this.model = result?.cv?.data[this.selectedIndexLanguage];

            this.modelCV = result.cv?.data;


            this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.currentUser?.categories?.name);
            /* const selectedLanguage = localStorage.getItem("cvLanguage");
             if (!!selectedLanguage) {
               this.translate.use(selectedLanguage)
             } else {
               this.translate.use(this.model.language.primary)
             } */
            if (this.currentUser?.userUrl) {
              this.userUrl = this.currentUser?.userUrl;
            }


            this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
            this.checkIfUserIsAtSpecific(this.outsideUserId);
            this.checkIfUserIsAtExpect(this.outsideUserId);
            this.currentUser = result.user;
            this.privacyIcon = this.model;
            this.privacyText = this.model;
            this.privacyTextOffer = this.uUser;
            this.privacyIconOffer = this.uUser;



            this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.currentUser?.categories?.name);


            this.model = result?.cv?.data[this.selectedIndexLanguage];
            const selectedLanguage = localStorage.getItem("cvLanguage");
            if (!!selectedLanguage) {
              this.translate.use(selectedLanguage)
            } else {
              this.translate.use(this.model?.language?.primary)
            }
            if (this.currentUser?.userUrl) {
              this.userUrl = this.currentUser?.userUrl;
            }
          } else {
            this.dataList$ = this.userService.userList;


            this.dataList$.subscribe(result => {
              this.currentUser = result.user;
              this.uUser = result.userUser;
              this.userCV = result.cv;
              this.privacyIcon = result?.cv?.data[this.selectedIndexLanguage];
              this.privacyText = result?.cv?.data[this.selectedIndexLanguage];
              this.privacyTextOffer = this.uUser;
              this.privacyIconOffer = this.uUser;



              this.model = result?.cv?.data[this.selectedIndexLanguage];
              this.modelCV = result.cv?.data;


              this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.currentUser?.categories?.name);
              /* const selectedLanguage = localStorage.getItem("cvLanguage");
               if (!!selectedLanguage) {
                 this.translate.use(selectedLanguage)
               } else {
                 this.translate.use(this.model.language.primary)
               } */
              if (this.currentUser?.userUrl) {
                this.userUrl = this.currentUser?.userUrl;
              }


              this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
              this.checkIfUserIsAtSpecific(this.outsideUserId);
              this.checkIfUserIsAtExpect(this.outsideUserId);
              this.currentUser = result.user;
              this.privacyIcon = this.model;
              this.privacyText = this.model;
              this.privacyTextOffer = this.uUser;
              this.privacyIconOffer = this.uUser;



              this.indexOfCategory = this.categoryService.categories.findIndex(t => t?.id === this.currentUser?.categories?.name);


              this.model = result?.cv?.data[this.selectedIndexLanguage];
              const selectedLanguage = localStorage.getItem("cvLanguage");
              if (!!selectedLanguage) {
                this.translate.use(selectedLanguage)
              } else {
                this.translate.use(this.model?.language?.primary)
              }
              if (this.currentUser?.userUrl) {
                this.userUrl = this.currentUser?.userUrl;
              }

            });
          }
        });
      }

    }

  }
  ngAfterContentChecked() {
   // this.cd.detectChanges();
  }
  ngOnChanges() {
    this.cd.detectChanges();
  }
  ngAfterViewInit() {

  }

  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      // tslint:disable-next-line:no-shadowed-variable
      reader.onload = (event: ProgressEvent) => {
        this.url = (event.target as FileReader).result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
  setFirstCat() {
    this.selectedFirstCat = true;
    this.selectedCategory = undefined;
  }
  changeTheme(event) {
    if (!this.router.url.startsWith("/user/")) {
      this.model.style.theme = event.name;
      // this.userService.patchUser(this.currentUser._id, this.currentUser);
      //  this.userService.updateUser(this.currentUser._id, this.currentUser);
    }
    return;
  }

  public removeClick() {
    this.selectedCategory = undefined;
    this.selectedFirstCat = false;
    this.selectedIndex = undefined;
    this.selectedFileIndex = undefined;
    this.selectedEmptySubCat = undefined;
    this.selectedCareerIndex = undefined;
  }
  public setCategory(index: Category) {
    this.selectedCategory = index;
    this.selectedFirstCat = false;
  }
  test(event) {
    this.selectedCategory = undefined;
  }
  hidePersonalData(id) {
    this.model.personalData[id].visible = false;
    // this.modelDataService.updatePage(this.model._id, this.model);
  }
  deleteCareerCategory(index) {
    // this.model.careers.subCategories.splice(index, 1);
    // this.modelDataService.updatePage(this.model._id, this.model);
  }
  deleteCareerSubCategory(index) {
    this.userCV.data[this.selectedIndexLanguage].careers.subCategories.splice(index, 1);
    this.userService.updateCV(this.userCV._id, this.userCV);

  //  this.userService.updateUser(this.currentUser._id, this.currentUser);
  }
  deleteEducationCategory(index) {
    this.model.education.subCategories.splice(index, 1);
    // this.modelDataService.updatePage(this.model._id, this.model);
  }
  deleteSkillsCategory(index) {
    this.model.skills.splice(index, 1);
    // this.modelDataService.updatePage(this.model._id, this.model);
  }
  deleteEmptyCategory(index) {
    this.model.emptyCategory.splice(index, 1);
    //  this.modelDataService.updatePage(this.model._id, this.model);
  }
  deleteEducationSubCategory(index) {
    this.userCV.data[this.selectedIndexLanguage].education.subCategories.splice(index, 1);
    this.userService.updateCV(this.userCV._id, this.userCV);


   // this.userService.updateUser(this.currentUser._id, this.currentUser);
  }
  showPageNumbers() {
    this.model.showPageNumbers = true;
    //this.modelDataService.updatePage(this.model._id, this.model);
    this.cd.detectChanges();
  }
  hidePageNumbers() {
    this.model.showPageNumbers = false;
    // this.modelDataService.updatePage(this.model._id, this.model);
    this.cd.detectChanges();
  }
  showIcons(id) {
    this.selectedThemeVisibleColor = undefined;
    this.selectedThemeVisibleColor = id;
  }
  public onChangeColor(color: string): void {
    if (this.authService.isLoggedIn) {
      this.model.style.color = color;
      // this.userService.updateUser(this.currentUser?._id, this.currentUser);
    } else {
      this.dialog.open(WarningDialogComponent);
    }
  }
  getIdOfGender(id) {
    this.getGender = this.housingTypeOptions.find(test => test.id === id)
  }
  getIdOfTitle(id) {
    this.getTitle = this.titleService.titles.find(test => test.id === id).description;
  }
  getIdOfStatus(id) {
    this.getStatus = this.statusObject.find(t => t.id === id).description;
  }

  get checkUserID() {
    return this.authService?.userID === this.currentUser?._id;
  }
  saveSearchFilter() {
    this.userService.updateUser(this.currentUser._id, this.currentUser);

  }



  public setIndex(id) {
    this.selectedIndex = id;
    this.selectedCategory = null;
    this.selectedEmptySubCat = undefined;
    this.selectedFileIndex = undefined;
    this.selectedCareerIndex = undefined;
  }
  public setFileIndex(id) {
    this.selectedFileIndex = id;
    this.selectedCategory = null;
    this.selectedIndex = undefined;
    this.selectedEmptySubCat = undefined;
    return;
  }
  public setCareerIndex(id) {
    this.selectedCareerIndex = id;
    this.selectedIndex = undefined;
    this.selectedCategory = null;
    this.selectedEmptySubCat = undefined;
    this.selectedFileIndex = undefined;
  }

  public setEmptySubCatIndex(id) {
    this.selectedEmptySubCat = id;
    this.selectedFileIndex = undefined;
    this.selectedCategory = null;
    this.selectedIndex = undefined;

  }
  public editEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, { data })
      .afterClosed().subscribe((res: EditEducation) => {
        if (!res) {
          return;
        } else {
          console.log(this.model.education, "eduction")
            if (res) {
              this.model.education.subCategories[data.index].title = res.education?.title;
              this.model.education.subCategories[data.index].description = res.education?.description;
              this.model.education.subCategories[data.index].today = res.education?.today;
              this.model.education.subCategories[data.index].endDate = res.education?.endDate;
              this.model.education.subCategories[data.index].startDate = res.education?.startDate;
              this.model.education.subCategories[data.index].graduation = res.education?.graduation;
              this.model.education.subCategories[data.index].pageID = res.education?.pageID;
              this.model.education.subCategories[data.index].name = res.education?.name;
              this.model.education.subCategories[data.index].url = res.education?.url;
              this.model.education.subCategories[data.index].showCompanyUrl = res.education?.showCompanyUrl;



            }
          this.cd.detectChanges();
          this.userService.updateCV(this.userCV._id, this.userCV);
        }
      });
  }

  selectionChanged(item, index) {
    if (this.currentUser.searchLocation.data[index]?.region.length > 1) {
      this.currentUser.searchLocation.data[index].postalCode = null;
      this.currentUser.searchLocation.data[index].postalCode = "";
    } else if (this.currentUser.searchLocation[index]?.region.length === 0) {
      this.currentUser.searchLocation.data[index].postalCode = null;
      this.currentUser.searchLocation.data[index].postalCode = "";
    } else {
      this.currentUser.searchLocation.data[index]?.region.forEach(i => this.currentUser.searchLocation.data[index].postalCode = i);
    }
  }
  public addEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, {
      data,
    });
  }
  public addFile(data: EmptySubFile) {
    this.dialog.open(EmptyFileComponent, {
      data,
    });
  }
  public addEmptySubCat(data: EmptySubFile) {
    this.dialog.open(EmptyDataDialogComponent, {
      data,
    });
  }

  addNewSearchFilter() {
    let digit = this.model.personalData[0].postalCode.toString()[0];
    const plz = this.model.personalData[0].postalCode.toString().substring(0, 3)
    const searchFilterObj: UserSearchLocation = {
      c: this.model.personalData[0].c,
      region: [`${digit}`],
      postalCode: plz,
    }
    this.currentUser.searchLocation.data.unshift(searchFilterObj);
    this.userService.updateUser(this.currentUser._id, this.currentUser);
  }
  public editEmptyCategory(data) {
    this.dialog.open(EmptyCategoryDialogComponent, {
      data
    });
  }

  public editLanguage(data, index) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.model[index] = res;
        this.translate.use(res.cvData.language.primary);
        //     this.translate.use(this.currentUser.cv.data[index].language.primary);
        //   localStorage.setItem("cvLanguage", this.currentUser.cv.data[index].language.primary)
        //  this.userService.updateUser(this.currentUser._id, this.currentUser)
      }
    })
  }
  addNewLanguage(data, index) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        console.log(res, "res")
        const test = Object.assign({}, this.userCV.data[0]);

        test.language = res.cvData.language;
        this.userCV.data.unshift(test);
        console.log(this.userCV.data, "data");

        // this.currentUser.cv.data.unshift(test);
        this.userService.updateCV(this.userCV._id, this.userCV);
        this.model = this.userCV.data[this.selectedIndexLanguage]
      }
    })
  }

  public editFile(data: EmptySubFile) {
    this.dialog.open(EmptyFileComponent, { data });
  }
  public deleteSubFile(index) {
    this.model.files.map(t => t.subCategories.splice(index, 1));
    this.userService.updateUser(this.currentUser._id, this.currentUser);
  }
  public deleteEmptyCatFile(index) {
    this.model.emptyCategory.map(t => t.emptySubContents.splice(index, 1));
    // this.modelDataService.updatePage(this.model._id, this.model);
  }
  public editCareer(data: EditCareer) {
    this.dialog.open(DataDialogComponent, {
      data,
    }).afterClosed().subscribe((res: EditCareer) => {
      if (!res) {
        return;
      } else {
        this.model.careers.subCategories.some(r => {
          if (res) {
            console.log(res.career, "career")
            r.name = res.career.name;
            r.position = res.career?.position;
            r.employmentType = res.career?.employmentType;
            r.endDate = res.career?.endDate;
            r.startDate = res.career?.startDate;
            r.role = res.career?.role;
            r.organization = res.career?.organization;
            r.description = res.career?.description;
            r.today = res.career?.today;
            r.pageID = res.career?.pageID;
            r.url = res.career?.url;
            r.activityField = res.career.activityField;
            r.level = res.career.level;
            r.companyUrl = res.career.companyUrl;
            r.showCompanyUrl = res.career.showCompanyUrl;
          }
        });

        this.cd.detectChanges();
        this.userService.updateCV(this.userCV._id, this.userCV);
      }
    });;
  }
  public addCareer(data: EditCareer) {
    this.dialog.open(SubCareerDialogComponent, {
      data,
    })
  }
  deleteSubCategory(index) {
    this.model.careers.subCategories.splice(index, 1);
    //   this.modelDataService.updatePage(this.model._id, this.model);
  }


  switchLang(event) {
    const language = event.substring(0, 2);
    if (this.authService.isLoggedIn) {
      this.translate.use(language);
      this.model.language = language;
      //  this.modelDataService.updatePage(this.model._id, this.model);
    } else {
      this.translate.use(language);
    }
  }
  onChange(value, index) {
    this.locations.c = value;
    this.currentUser.searchLocation[index].country = value;
  }
  update_toggle(group, button) {
    if (group.value == "") {
      group.value = button.value;
    }
    else {
      group.value = "";
    }
    button.checked = !button.checked;
  }

  editEmptySubCat(data: EmptySubCategory) {
    this.dialog.open(EmptyDataDialogComponent, {
      data
    });
  }

  public openDialog(data: CategoryDialog) {
    this.dialog
      .open(CategoryDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (result === "PersonalData") {
            this.model.personalData[0].visible = true;
          } else if (result === "Career") {
            this.model.careers.subCategories.unshift({

              name: "Max Muster",
              description: "wdw1d",
              startDate: new Date(),
              endDate: new Date(),
              role: "IT Entwickler",
              position: "Senior IT Consultant",
              privacy: 2,
            });
          } else if (result === "Education") {
            this.model.education.subCategories.unshift(
                {
                  name: "Max Muster",
                  title: "Developer",
                  startDate: new Date(),
                  endDate: new Date(),
                  description: "IT",
                  privacy: 2,
                },
                {
                  name: "Max Muster",
                  title: "Developer",
                  startDate: new Date(),
                  endDate: new Date(),
                  description: "IT",
                  privacy: 2
                },
            );
          } else if (result === "Skills") {
            this.model.skills.unshift({


              languages: {
                privacy: 1,
                data: [
                  { name: "languages.de", rate: 1 },
                  { name: "languages.en", rate: 4 },
                ]
              },
              driveLicenses: {
                privacy: 1,
                data: [
                  { name: "B" }]
              },
              skillsOffer: {
                privacy: 0,
                data: [
                  { name: "Javascript", rate: 0 },
                  { name: "HTML", rate: 0 },
                ]
              },
              pcKnowledge: {
                privacy: 1,
                data: [
                  { name: "Word", rate: 0 },
                  { name: "Excel", rate: 0 },
                  { name: "Adobe Photoshop", rate: 0 }
                ]
              }

              ,
              qualifications: {
                privacy: 1,
                data: []
              },

            });
          } else if (result === "Files") {
            this.model.files.unshift({ subCategories: [{ name: "", link: "" }] });
          }
          // return this.modelDataService.updatePage(this.model._id, this.model);
        } else {
          return null;
        }
      });
  }
  openDialogFirstCat(data: EditHeaderDialog) {
    this.dialog.open(HeaderDialogComponent, {
      data,
    });
  }
  editHeaderDialog(data: EditHeaderDialog) {
    this.dialog.open(HeaderDialogComponent, {
      data,
    }).afterClosed().subscribe(res => {
      if (res === undefined) { }
      else {
       /* if (!!res.model.photo) {
          this.imageService.getImageByID(res.model.photo).subscribe((img: Image) => {
            if (img.imageUrl) {
              this.image = img.imageUrl;
            }
          });
        } */

      }

    });
  }

  public get checkId() {
    return this.authService?.userID === this.currentUser?._id;
  }

  addPrivacy(data: EditPrivacyDialog) {
    if (data.enum === Groups.Contacts_except || data.enum === Groups.Contacts_specific) {
      return this.dialog.open(PrivacyDialogComponent, {
        data,
        panelClass: "my-class"
      })
    } else {
      this.currentUser.cv.p = data.enum;
      // this.userService.updateUser(this.currentUser._id, this.currentUser);
    }
  }

  updatePrivacy() {
    this.userService.updateUser(this.currentUser._id, this.currentUser);
  }

  addSearchActive(event) {
    this.currentUser.searchLocation.active.s = event;
    if (event === SearchActive.Active) {
      this.showActiveDate = true;
    }
  }
  loadActiveUserConnections(userId: string, friendId: string): Observable<any> {
    return new Observable<any>(observer => {
      this.friendService.getFriendById({ userId, friendId }).subscribe(res => {
        observer.next(res);
        const request = res?.[0];
        if ((request?.userId === userId || request?.friendId === userId) && request?.status === "You are friend") {
          this.isFriend = true;
        } else {
          this.isFriend = false;

        }

      })
    });
    /* this.friendService.getFriendById({userId: userId, friendId: friendId}).subscribe(t => {
       console.log(t);
     }) */
    /*  this.friendService.getAllFriendRequests().subscribe((finalRequesters) => {
        this.checkFriend = finalRequesters;
        this.checkFriend.forEach((oneRequest: any) => {
          if ((oneRequest.userId === userId || oneRequest.friendId === userId) && oneRequest.status === "You are friend") {
            oneRequest.isFriend = true;
            this.isFriend = true;
            console.log(oneRequest, "if else");
          }  else {
            console.log(oneRequest, "else");
           this.isFriend = false;
           this.isRequest = false;
          }
        });
      }); */
  }

  checkIfUserIsAtSpecific(userId: string) {
    this.currentUser?.specificContacts?.filter(t => {
      if (t.contactId === userId) {
        this.checkFriendId = true;
      }

    });
  }

  public swapArrays(arr, indexA, indexB) {
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    this.userService.updateUser(this.authService.userID, this.currentUser);
    this.userService.getUserData(this.userID).subscribe(res => {
      // test
      this.currentUser = res;
      this.model = res?.cv[0];
    });

  };
  swapArrayTop(arr, indexA, indexB) {
    this.swapArrays(arr, indexA, indexA - 1);
  }

  swapArray(arr, indexA, indexB) {
    console.log(arr, indexA, indexB)
    this.swapArrays(arr, indexA, indexA + 1);
  }
  checkIfUserIsAtExpect(userId: string) {
    this.currentUser?.contactExpect?.some(t => {
      if (t.contactId === userId) {
        this.friendExpect = false;
      }
    });
  }




  checkIfUserIsAtExpectPersonalData(userId: string) {
    this.currentUser?.contactExpect?.some(t => {
      if (t.id === userId) {
        this.friendExpectPersonalData = false;
      }
    })
  }

  checkPersonalData(userId) {
    switch (this.model.personalData[0].cv_enum) {
      case this.groups.Contacts:
        this.showPersonalDataFriends = true;
        break;
      case this.groups.Public:
        this.showPersonaDataPublic = true;
        break;
      case this.groups.Contacts_specific:
        this.checkIfUserIsAtExpectPersonalData(userId);
        break;
      default:
        break;
    }
  }

  checkID(userId) {
    switch (this.model?.p) {
      case this.groups.Contacts:
        this.showFriends = true;
        break;
      case this.groups.Public:
        this.showPublicCV = true;
        break;
      case this.groups.Contacts_specific:
        this.checkIfUserIsAtSpecific(userId);
        break;
      case this.groups.Contacts_except:
        this.checkIfUserIsAtExpect(userId);
        break;
      case this.groups.OnlyMe:
        this.onlyMe = this.authService.userID === this.currentUser._id;
        break;
      case this.groups.Followers:
        this.followers = true;
        break;
      case 6:
        break;
      default:
        break;
    }
  }


  test21() {
    console.log("test");
  }

  selectSearchFilter(index) {
    this.selectedSearchFilter = index;

  }


  deleteSearchFilter(index) {
    this.currentUser.searchLocation.data.splice(index, 1);
  }
  remoteCheck(event) {
    this.currentUser.searchLocation.remote = event;
    this.userService.updateUser(this.currentUser._id, this.currentUser);
  }
  navigateToUser() {
    const test = [this.currentUser, "outside"];
    this.receiver.send(test);
    this.router.navigateByUrl("/" + this.currentUser.userUrl + "/start");
  }
  printPDF() {
    const doc = new jsPDF({
      unit: 'pt', orientation: 'p', format: 'a4'
    }) // create jsPDF object

    const pdfElement = document.getElementById('printPDFCV');
    // HTML element to be converted to PDF
    doc.setFontSize(9);
    doc.html(pdfElement, {
      callback: (pdf) => {
        pdf.save(`${this.currentUser.firstName}${this.currentUser.lastName}.pdf`);
      },
      x: 0,
      y: -13,
      autoPaging: true,
      windowWidth: 100,
      html2canvas: {
        scale: 0.75,
      },
    });

  }

  public get isPrimary() {
    return this.cvLanguage;
  }
  public get isSecondary() {
    return this.cvLanguageSecondary
  }

  public set isPrimary(index) {
    this.cvLanguage = this.languageService.primaryLanguages.find(t => t.lang === this.currentUser.cv.data[index]?.language?.primary);
  }

  public set isSecondary(index) {
    this.cvLanguageSecondary = this.languageService.primaryLanguages.find(t => t.lang === this.currentUser.cv.data[index]?.language?.primary);
  }

  isPrimaryFnc(index) {
    const data = this.languageService.primaryLanguages.find(t => t.lang === this.userCV.data[index].language.primary);

    if (data?.version) {
      return this.languageService.primaryLanguages.find(t => t.lang === this.userCV.data[index].language?.primary)?.description;
    } else {
      return this.languageService.primaryLanguages.find(t => t.lang === this.userCV.data[index].language?.secondary)?.description;

    }
  }

  deleteCV(index) {

    this.userCV.data.splice(index, 1);
    this.userService.updateCV(this.userCV._id, this.userCV)
  }

  changeCVLanguage(cv: Model, index) {
    const cvLang = cv.language.primary;
    this.selectedIndexLanguage = index;
    const data = this.languageService.primaryLanguages.find(t => t.lang === cvLang);

    if (data?.version) {
      this.translate.use(cv.language.primary);
      localStorage.setItem("cvLanguage", cv.language.primary);
    } else {
      this.translate.use(cv.language.secondary);
      localStorage.setItem("cvLanguage", cv.language.secondary);

    }
    this.model = cv;
  }

  saveData(event) {
    console.log(this.currentUser, "user eeee");


    this.userService.updateUser(this.currentUser._id, this.currentUser);
  }

  selectForChange() {
    this.selectSearch = true;
  }

  saveSearchActive() {
    this.userService.updateUser(this.currentUser._id, this.currentUser);
    this.selectSearch = false;
  }

  showChildren(event) {
    this.selectedCategory = event;
    const dataCat = this.categoryService.categories.findIndex(t => t.id === event);
    this.categoryService.categories[this.indexOfCategory].subCategory = this.categoryService.categories[dataCat].subCategory;
    this.selectedSubCategory = this.categoryService.categories[this.indexOfCategory].subCategory[0].id;
  }

  public set privacyIcon(user: Model) {
    this.icon = this.privacyArray.find(t => t.id === user?.p)?.icon;
  }

  public get privacyIcon() {
    return this.icon;
  }

  public set privacyText(user: Model) {
    this.text = this.privacyArray.find(t => t.id === user?.p)?.description;
  }

  public get privacyText() {
    return this.text;
  }

  public set privacyTextOffer(user: User) {
    this.textOffer = this.titleService.offerArray.find(t => t.id === user?.offer?.s)?.text;
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(user: User) {
    this.iconOffer = this.titleService.offerArray.find(t => t.id === user?.offer?.s)?.icon;
  }

  public get privacyIconOffer() {
    return this.iconOffer;
  }



  openPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        this.model.p = t;
        //  this.privacyIcon = this.currentUser;
        //  this.privacyText = this.currentUser;
        // this.userService.updateUser(this.currentUser._id, this.currentUser);
      }
    })
  }

  openShare(data) {
    this.dialog.open(ShareDialogComponent, {
      data
    });
  }

  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        this.currentUser.offer.s = t;
        this.privacyIconOffer = this.currentUser;
        this.privacyTextOffer = this.currentUser;
        this.currentUser.offer.timestamp = new Date();
        this.userService.updateUser(this.currentUser._id, this.currentUser);
      }
    })
  }
}

