import { Injectable } from "@angular/core";
import {environment} from "../../../assets/environments/environment";
import {Messages, MessagesResponse} from "../interfaces/message";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: "root"
})
export class MessagesService {
  public baseUrl = environment.backend;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    })
  };
  constructor(private http: HttpClient) { }

  createRequest(newRequest: Messages) {
    return this.http.post<Messages>(`${this.baseUrl}/createMessage`, newRequest)
      .pipe(map(res => {
        return res;
      }))
  }

  getAllMsg() {
    return this.http.get<any[]>(`${this.baseUrl}/messages`);
  }

  getMessages(params: Messages): Observable<MessagesResponse> {
    return this.http.post(`${this.baseUrl}/getMessages`, JSON.stringify(params), this.httpOptions).pipe(
      map((response: MessagesResponse) => {
        return response;
      },
        (err) => {
        throw err;
        })
    )
  }
  getYourMessages(params: Messages): Observable<MessagesResponse> {
    return this.http.post(`${this.baseUrl}/getYourMessages`, JSON.stringify(params), this.httpOptions).pipe(
      map((response: MessagesResponse) => {
          return response;
        },
        (err) => {
          throw err;
        })
    )
  }

  updateMessages(params, messages) {
    return this.http.put(`${this.baseUrl}/updateChat/${params}`, messages).pipe(res => {
      return res;
    })
  }
}
