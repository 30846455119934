import { IssueUtil } from "../utils/issue";
import { JComment } from "./comment";

/* eslint-disable no-shadow */
export enum IssueType {
  STORY = 'Story',
  TASK = 'Task',
  BUG = 'Bug'
}

export enum IssueStatus {
  Applied = 'Applied',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IN_PROGRESS = 'InProgress',
  DONE = 'Done'
}

export const IssueStatusDisplay = {
  [IssueStatus.Applied]: 'Applied',
  [IssueStatus.IN_PROGRESS]: 'In progress',
  [IssueStatus.DONE]: 'Done'
};

export enum IssuePriority {
  LOWEST = 5,
  LOW = 4,
  MEDIUM = 3,
  HIGH = 2,
  HIGHEST = 1
}

export const IssuePriorityDisplay = {
  [IssuePriority.LOWEST]: "Lowest",
  [IssuePriority.LOW]: "Low",
  [IssuePriority.MEDIUM]: "Medium",
  [IssuePriority.HIGH]: "High",
  [IssuePriority.HIGHEST]: "Highest",

}

export const IssuePriorityColors = {
  [IssuePriority.HIGHEST]: '#CD1317',
  [IssuePriority.HIGH]: '#E9494A',
  [IssuePriority.MEDIUM]: '#E97F33',
  [IssuePriority.LOW]: '#2D8738',
  [IssuePriority.LOWEST]: '#57A55A'
};
export class JIssue {
  id: string;
  title?: string;
  status: IssueStatus;
  listPosition?: number;
  seen?: [{
    userID?: String,
    pageID?: String,
    timestamp?: Date,
  }]
  message: {
    userID?: string;
    pageID?: string;
    description: string;
  }
  estimate?: number;
  createdAt: Date;
  reporterId?: string;
  userID: string;
  pageID?: string;
  comments?: JComment[];
  projectId?: string;
  note?: string;
  cancel?: {
    status: Number,
    timestamp: Date,
    description?: string,
    userID?: string;
    pageID?: string;
  }
}

export interface FilterState {
  searchTerm: string;
  userId: string;
  onlyMyIssue: boolean;
  ignoreResolved: boolean;
}
export class IssuePriorityIcon {
  icon: string;
  value: string;
  color: string;

  constructor(issuePriority: IssuePriority) {
    const lowerPriorities = [IssuePriority.LOW, IssuePriority.LOWEST];
    this.value = IssuePriorityDisplay[issuePriority];
    this.icon = lowerPriorities.includes(issuePriority) ? 'arrow-down' : 'arrow-up';
    this.color = IssuePriorityColors[issuePriority];
  }
}
export class IssueTypeWithIcon {
  value: string;
  icon: string;

  constructor(issueType: IssueType) {
    this.value = issueType;
    this.icon = IssueUtil.getIssueTypeIcon(issueType);
  }
}
