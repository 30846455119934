import { Injectable } from "@angular/core";
import { Groups } from "../enums/enums-model";

@Injectable({
  providedIn: "root"
})
export class PrivacyActivity {
  public privacyActivity = [];
  constructor() { }

  public getPrivacyActivity() {
      this.privacyActivity = [
        { id: Groups.Public , description: "Public"},
        { id: Groups.Contacts , description: "Contacts"},
        { id: Groups.Contacts_except , description: "Contacts expect"},
        { id: Groups.Contacts_specific , description: "Specific contacts"},
        { id: Groups.User_Premium, description: "User Premium"},
      /*  { id: Groups.Pages_Premium, description: "Pages Premium"}, */
        { id: Groups.Followers, description: "Followers"},
        { id: Groups.OnlyMe , description: "Only me"},

      ]
  }

}

