export enum StatusTitle {
  Mr,
  Mrs,
  All
}

export enum Title {
  Empty,
  Dr,
  Prof,
  ProfDr,
  Ing,
  DipIng,
  Mag,
}

export enum Privacy {
  Private = 0,
  Public = 1
}

export enum Pricing {
  Language = 30,
  CV = 9,
  Page = 30,
  Group = 30,
  Job = 2,
  Projekt = 2,
  Public = 5
}

export enum MaritualStatus {
  Single,
  Married,
  NotKnow,
}

export enum PriceCV {
  price = 40
}

export enum Relationsship {
  Single = 1,
  In_a_relationship,
  Engaged,
  Married,
  In_a_civil_union,
  In_a_domestic_partnership,
  In_a_open_relationship,
  Its_complicated,
  Divorced,
  Windowed,
}

export enum SearchActive {
  NotActive = 0,
  Active = 1,
}

export enum Religion {
  Catholic = "Catholic",
  Orthodox = "Orthodox",
  Protestant = "Protestant",
  Muslim = "Muslim",
  Hindus = "Hindus",
  Jews = "Jews",
  Buddhists = "Buddhists",
  Other = "Other",
}

export enum Languages {
  AL,
  EN,
  DE,
}

export enum Status {
  Sent = 2,
  Accepted = 1,
  Pending = 0,
}

export enum StatusFollow {
  Private = 0,
  Public = 1,
  Contact = 2,
  Block = 9
}



export enum Category {
  PersonalData = 0,
  Education = 1,
  Skills = 2,
  Career = 3,
  Another = 4,
  Files,
}

export enum Groups {
  OnlyMe = 0,
  Contacts = 1,
  Contacts_except = 2,
  Contacts_specific = 3,
  User_Premium = 4,
  Pages_Premium = 5,
  Followers = 6,
  Public = 7,
  Block = 9,
}

export enum StatusPrivacy {
  Only_me = 0,
  Followers = 1,
  Contacts = 2,
  Public = 7
}

export enum OfferPrivacy {
Public = 7,
One = 1,
Two = 2,
Three = 3,
Four = 4,
Five = 5,
Only_me = 0

}

export enum SkillSubCategory {
  Qualifications = 0,
  Language,
  DriveLicense,
  PCKnowledge,
  Offer,
}

export enum EmploymentType {
  EmployedFullTime,
  EmployedPartTime,
  Internship,
  Owner,
  BordMember,
  Volunteer,
  SelfEmployed,
  Shareholder,
  Official,
  Recruiter,
  Freelancer,
  Partner,
  All,
  Search
}

export enum Links {
  Internal = 0,
  External = 1,
}

export enum Remote {
  onSite = 0,
  remote = 1,
  hybrid = 2,
}
export enum Incomes {
  hour = "Hour",
  month = "Month",
  year = "Year",
  total = "Total",
}
export enum Duration {
  unlimited = "Unlimited",
  temporary = "Temporary",
}

export enum Branches {
  All = "All",
  Automotive_Industry = "Automotive_Industry",
  Construction_RealEstate = "Construction_RealEstate",
  Education = "Education",
  Service = "Service",
  Energy_Industry = "Energy_Industry",
  Finance_Marketing_Insurance = "Finance_Marketing_Insurance",
  Trade = "Trade",
  Hotels_Restaurants = "Hotels_Restaurants",
  Industry = "Industry",
  IT_Telecommunications = "IT_Telecommunications",
  Agriculture = "Agriculture",
  Media_Internet = "Media_Internet",
  Medicine_Health = "Medicine_Health",
  Fashion_Beauty = "Fashion_Beauty",
  Food_Beverage = "Food_Beverage",
  Public_Service = "Public_Service",
  Organisation = "Organisation",
  Legal = "Legal",
  Other = "Other",
  Sports_Rekreation = "Sports_Rekreation",
  Transport_Traffic_Logistik = "Transport_Traffic_Logistik",
  Science_Research = "Science_Research",
}

export enum EmploymentSize {
  Employment0,
  Employment1,
  Employment2,
  Employment3,
  Employment4,
  Employment5,
  Employment6,
  Employment7,
  Employment8,
  Employment9,
}

export enum CareerLevel {
  CareerLevel,
  CareerLevel1,
  CareerLevel2,
  CareerLevel3,
  CareerLevel4,
  CareerLevel5,
  CareerLevel6,
}

export enum Organization {
  Organization0,
  Organization1,
  Organization2,
  Organization3,
  Organization4,
  Organization5,
  Organization6,
}

export enum ActivityField {
  EmptyField,
  Administration,
  Analyse,
  Consulting,
  Einkauf,
  Finanzen,
  Forschung,
  Gesundheit,
  Grafik,
  IT,
  Ingenineurwesen,
  Kundenbetreuung,
  Management,
  Marketing,
  PR,
  Personalwessen,
  Produktion,
  Produktmanagment,
  Projektmanagement,
  Prozessplanung,
  Recht,
  Vertrieb,
  Project,
  Sonstige,
  All,
}

export enum ArchitectureArea {
  Empty,
  Architecture,
  Baugenwerbe,
  Bauingenierwesen,
  Baustoffe,
  Garten,
}

export enum CarArea {
  Empty,
  Car,
  Fahrzeug,
  Luft,
  Schienen,
  Schiffbau,
  Verkehrstechnik,
}

export enum BankArea {
  Empty,
  Bankwessen,
  Finanz,
  Invest,
  Risikokapital,
}

export enum WaterArea {
  Empty,
  Recycling,
  Energie,
  EnergieErneuerbare,
  Umweltschutz,
  Wasserversorgung,
}

export enum BildungArea {
  Empty,
  Coaching,
  ELearning,
  Hochschule,
  Forschung,
  Kinderbetreuung,
  School,
}

export enum HealthArea {
  Empty,
  Medizin,
  Doctor,
  Krankenhauser,
  MedizinDienst,
  Pflegeberufe,
  Psygologie,
  Sozialwessen,
  TierMedizin,
}

export enum ImmobilienArea {
  Empty,
  Facility,
  Immobilienvermittlung,
  Immobilienverwaltung,
}

export enum FamilyMembers {
  Mother,
  Father,
  Daughter,
  Son,
  Sister,
  Brother,
  Aunt,
  Uncle,
  Niece,
  Nephew,
  Cousin_female,
  Cousin_male,
  Grandmother,
  Grandfather,
  Granddaughter,
  Grandson,
  Stepsister,
  Stepbrother,
  Stepmother,
  Stepfather,
  Stepdaughter,
  Stepson,
  Sister_in_law,
  Brother_in_law,
  Mother_in_law,
  Father_in_law,
  Daughter_in_law,
  Son_in_law,
  Sibling_gender_neutral,
  Parent_gender_neutral,
  Child_gender_neutral,
  Sibling_of_Parent_gender_neutral,
  Child_of_Sibling_gender_neutral,
  Cousin_gender_neutral,
  Grandparent_gender_neutral,
  Grandchild_gender_neutral,
  Step_Sibling_gender_neutral,
  Step_Parent_gender_neutral,
  Step_Child_gender_neutral,
  Sibling_in_law_gender_neutral,
  Parent_in_law_gender_neutral,
  Child_in_law_gender_neutral,
  Family_member_gender_neutral,
  Pet_gender_neutral,
}

export enum AccountStatus {
  Blocked,
  Deactivated,
  Active
}
