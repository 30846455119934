import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Messages } from "../shared/interfaces/message";
import { AuthService } from "../shared/services/auth.service";
import { MessageHelperService } from "../shared/utils/message-helper.service";
import {ImageService} from "../shared/services/image.service";
import {response} from "express";
import { User } from "../shared/interfaces/user.model";
import { JobsService } from "../shared/services/jobs.service";
import { Job, Page, Project } from "../shared/interfaces/model";
import { ProjectService } from "../shared/services/project.service";
import { PagesService } from "../shared/services/pages.service";
import { UserService } from "../shared/services/user.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-general-chat",
  templateUrl: "./general-chat.component.html",
  styleUrls: ["./general-chat.component.scss"]
})
export class GeneralChatComponent implements OnInit {
  @Input()chat: Messages;
  @Input() selectedIndex: number;
  user: User;
  isUserSelected = false;
  public messages: Messages[] = [];
  public image;
  @Input() job: any;
  @Input() project: any;
  @Input() isJob: boolean;
  @Input() isProject: boolean;
  public userJob: Job;
  public projectData: Project;
  public jobData: Job;
  @Input() newDesign;
  public page: Page;
  pageJobData: Page;
  userJobData: User;

  @Output() selectedUser: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedJob: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedProject: EventEmitter<any> = new EventEmitter<any>();

  jobManage;


  constructor(private authService: AuthService,
    private msgHelperService: MessageHelperService,
              private imageService: ImageService,
              private jobService: JobsService,
              private projectService: ProjectService,
              private userservice: UserService,
              private pageService: PagesService
    ) { }

  ngOnInit(): void {


    if (this.newDesign && this.isProject) {
      if (this.project.projectID) {
        this.projectService.getProject(this.project?.projectID).pipe(take(1)).subscribe(res => {
          this.projectData = res;
          if (this.projectData.pageID) {
            this.pageService.getPage(this.projectData?.pageID).pipe(take(1)).subscribe(resPage => {
              this.page = resPage.page;
            })
          } else {
            this.userservice.getUserContactData(this.projectData?.userID).pipe(take(1)).subscribe(t => {
              this.user = t;
            })
          }

        })
      }
    }
    if (this.newDesign && this.isJob) {
      if (this.project.jobID) {
        this.jobService.getJob(this.project?.jobID).pipe(take(1)).subscribe(res => {
          this.jobData = res;
          if (this.jobData?.pageID) {
            this.pageService.getPage(this.jobData?.pageID).pipe(take(1)).subscribe(resPage => {
              this.pageJobData = resPage.page;
            })
          } else {
            this.userservice.getUserContactData(this.jobData?.userID).pipe(take(1)).subscribe(t => {
              this.userJobData = t;
            })
          }
        })
      }

    }
     /* if(this.chat. === this.authService.userID) {
      this.authService.getUserProfile(this.chat.emitter).subscribe(user => {
        this.user = user;
        this.dataService.getUserModel(this.chat.emitterId).subscribe((model: Model) => {
          if(model.profilePicture) {
            this.imageService.getImageByID(model.profilePicture).subscribe((img: Image) => {
              this.image = img.imageUrl;
            })
          }
        })
      })
      } else {
        this.authService.getUserProfile(this.chat.emitterId).subscribe(res => {
          this.user = res;
          this.dataService.getUserModel(this.chat.emitterId).subscribe((model: Model) => {
            if(model.profilePicture) {
              this.imageService.getImageByID(model.profilePicture).subscribe((img: Image) => {
                this.image = img.imageUrl;
              })
            }
          })
      })
      } */
  }
  selectUser() {
  this.selectedUser.emit();
  }
  selectJob() {
    this.selectedJob.emit();
    }

    selectProject(){
      this.selectedProject.emit();
    }

   public get  userSeen() {
    return this.projectData?.manage_.find(tt => tt.seen.find(t => t.userID)?.userID === this.authService.userID &&
      typeof tt.pageID === 'undefined')?.userID === this.authService.userID;

    }


    public get  userSeenJob() {
      const test = this.jobData?.manage_.find(tt => tt.seen.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID);
      return test?.userID === this.authService.userID &&
        typeof test.pageID === 'undefined';
      }


}
