import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { ReplaySubject } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/assets/environments/environment";
import { Group } from "../interfaces/model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class GroupService {
  public baseUrl = environment.backend;
  private data = new ReplaySubject<Array<Group>>();
  public userID = this.authService.userID;
  public editDataDetails: any = [];
  public lastSetOfData: Array<Group> = [];

  private messageSource = new BehaviorSubject(this.editDataDetails);

  getPageID = this.messageSource.asObservable();


  constructor(private http: HttpClient, private authService: AuthService) { }

  public setGroup(group: Group, id: string) {
    const api = `${this.baseUrl}/group`;
    const user_id = id;
    this.http.post<Group>(api, group, {
      headers: { user_id }
    }).subscribe(res => {
      this.lastSetOfData = [...this.lastSetOfData, res]
      this.data.next(this.lastSetOfData)
    });
  }
  public getGroups() {
    return this.http.get<any>(`${this.baseUrl}/groups/${this.userID}`).subscribe(res => {
      this.lastSetOfData = res;
      this.data.next(this.lastSetOfData);
    });
  }
  public getAllGroups() {
    return this.http.get<any>(`${this.baseUrl}/groups/${this.userID}`);
  }
  public getGroup(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/group/${id}`);
  }

  public getGroupUser(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/user/group/${id}`);
  }
  public groupModel(): Observable<Group[]> {
    return this.data.asObservable();
  }
  public update(id: string, group: Group) {
    const api = `${this.baseUrl}/group/${id}`;
    return this.http.put<any>(api, group).pipe(catchError(this.handleError)).subscribe((res) => {
      const index: number = this.lastSetOfData.findIndex((group: Group) => group._id === group._id);
      this.lastSetOfData[index] = group;
      this.lastSetOfData = [...this.lastSetOfData];
      this.data.next(this.lastSetOfData);

    });

  }
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  handleError(error: HttpErrorResponse) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
