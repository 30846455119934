import { HttpClient } from "@angular/common/http";
import { NgZone } from "@angular/core";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Subscription } from "rxjs";
import { map, shareReplay, take } from "rxjs/operators";
import { Page } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { PageHelperService } from "src/app/shared/utils/page-helper.service";
import { PagesComponent } from "../pages.component";

@Component({
  selector: "app-sidebar-pages",
  templateUrl: "./sidebar-pages.component.html",
  styleUrls: ["./sidebar-pages.component.scss"]
})
export class SidebarPagesComponent implements OnInit {
  public page = new Page();
  search;
  public branch = "";
  public subscriber: Subscription;
  public pages$: Observable<Page[]>;
  public pages: Observable<Page[]>;

  constructor(public router: Router,
    public branchesService: BranchesService,
    public languageService: LanguagesService,
    public http: HttpClient,
    public authService: AuthService,
      public pageService: PagesService,
      public imageService: ImageService,
      public shareData: SenderService,
      public recevier: SenderService,
      private pageHelper?: PageHelperService,
      private ngZone?: NgZone) {
  }

  ngOnInit(): void {
    this.branchesService.getBranches();
    this.pageHelper.getPages().pipe(shareReplay(1)).subscribe(res => {
      this.pages = res.pages;
    })
    this.pageService.getMyPages();
    this.pages$ = this.pageService.pageModel();
  }

createPage() {
  this.page.branches = this.branch;
  this.page.locations = [];
  this.page.roles.Owner.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Admin.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Advertiser.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Analyst.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Editor.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Moderator.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.User.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Jobs.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Projects.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Messages.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Notifications.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Payments.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.roles.Marketing.unshift({userID: this.authService.userID, timestamp: new Date()});
  this.page.phone = this.page.phone;
  this.page.pageUrl = `${this.page.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;
  this.pageService.setPage(this.page);

}
addBranch(event) {
    this.branch = event;
    this.search = "";
}
removeBranch(index) {
    return index
}
}
