<div class="Unit-unit"
  (mouseenter)="selectEmptyObj(iEmpty)"
  (mouseleave)="removeClick()"
  (click)="selectEmptyObj(iEmpty)"
  [ngClass]="{ 'isActive': selectedItem === iEmpty && authService.isLoggedIn && checkId}" pageContent>
  <div class="Line-line-container"  >
    <div class="Line-line-line">
      <div class="Field-field-fieldBase first-template-fieldField">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text">
            {{emptyObj.name}}
          </div>
        </div>
      </div>
      <div class="Field-field-fieldBase first-template-fieldValue">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text-wrapper" [innerHTML]="emptyObj.description | noSanitize">
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedItem === iEmpty && authService.isLoggedIn && checkId">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
              (click)="editEmptyObj({edit: true, empty: emptyObj, model: model, user: user, index: selectedItem})"></li>
            <li class="bi bi-plus addIconBottom" (click)="addEmptyObj()"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deleteEmptyObj(iEmpty)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
