import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-following',
  templateUrl: './user-following.component.html',
  styleUrls: ['./user-following.component.scss']
})
export class UserFollowingComponent implements OnInit {
  public dataList$: Observable<any>;
  public userData;
  loggedUser$:  Observable<any>;
  loggedUser: any;

  constructor(public userService: UserService) {

  }

  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.loggedUser$ = this.userService.userList;
    this.dataList$.subscribe((res: any) => {
      console.log()
      this.userData = res;
    });

    this.loggedUser$.subscribe(res => {
        this.loggedUser = res;
    })


  }

}
