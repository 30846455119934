import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { GravatarModule } from "ngx-gravatar";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { MaterialModule } from "src/app/material.module";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { CompanyNameComponent } from "./company-name/company-name.component";
import { PaymentsComponent } from "./payments.component";
import { SmartPaymentsTableComponent } from './smart-payments-table/smart-payments-table.component';
import { PriceCardModule } from "../price-card/price-card.module";


@NgModule({
    declarations: [
        PaymentsComponent,
        CompanyNameComponent,
        SmartPaymentsTableComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        GravatarModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        TooltipModule,
        NgxIntlTelInputModule,
        MaterialModule,
        DirectivesModule,
        PipeModule,
        RouterModule,
        PriceCardModule
      ],

      exports: [
        SmartPaymentsTableComponent
      ]
})

export class PaymentModule {

}
