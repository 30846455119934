      <div class="Unit-unit">
        <div
           [ngClass]="{ 'isActive': selectedIndex === id}" (click)="setIndex(id)"
           (mouseleave)="removeClick()" (mouseenter)="setIndex(id)" (click)="setIndex(id)"
           >
           <div class="Line-line-container">
            <div class="Line-line-line">
              <div class="Field-field-fieldBase first-template-fieldField">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text">
                    {{educationObj.startDate | date:'MM/yyyy'}} {{educationObj.endDate | date:'MM/yyyy'}}</div>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <b>{{educationObj.title}}</b>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    {{educationObjName}}
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper" aria-multiline="true" [innerHTML]="educationObj.description">
                  </div>
                </div>
              </div>
        <ng-container pageContent *ngIf="selectedIndex === id">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop" (click)="editEducation({edit: true, educationObj: educationObj, model: model})"></li>
            <button (click)="addEducation({edit: false, model: model})" class="btn"><i class="bi bi-plus addIconBottom"></i></button>
            <button (click)="deleteSubCategory(id)" class="btn"><i class="bi bi-trash deleteIconRight"></i></button>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
        </ng-container>
      </div>
      </div>
