import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { $ } from 'protractor';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/assets/environments/environment';
import { Job, Page } from '../interfaces/model';
import { User } from '../interfaces/user.model';
import { AuthService } from './auth.service';
import { PagesService } from './pages.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  api = `${environment.backendXY}/job`;
  userID = this.authService.userID;
  loggedUser$: Observable<any>;
  loggedUser: any;

  constructor(private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private pageService: PagesService) { }

  public createJob(job: Job, isUser: boolean, pageID?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body'
    };
    return this.http.post<any>(this.api, job, httpOptions).pipe(map((response: HttpResponse<any>) => {
      if (isUser) {
        this.loggedUser$ = this.userService.userList;
        this.loggedUser$.subscribe(res => {
          this.loggedUser = res;

          this.userService.getJobList(res.user._id).subscribe(data => {
            const job = {
              jobID: response.body._id,
              timestamp: new Date(),
              c: response.body.c

            };
            data[0].jobs.unshift(job);
            this.userService.updateJobList(data[0]._id, data[0]);
          })
        })
      } else {
        this.pageService.getPage(pageID).pipe(shareReplay(1)).subscribe((res: any) => {


          this.pageService.getJobList(res?.page._id).subscribe(data => {

            const job = {
              jobID: response.body._id,
              timestamp: new Date(),
              c: response.body.c

            };
            data[0].jobs.unshift(job);
            this.pageService.updateJobList(data[0]._id, data[0]);
          })
        })
      }
      localStorage.setItem('jobID', response.body._id);

    }))
  }

  public getUserJobs(id): Observable<any> {
    return this.http.get(`${this.api}/userJobs/${id}`);
  }

  public getJob(id): Observable<any> {
    return this.http.get(`${this.api}/${id}`);
  }
  getItem(id: string): Observable<any> {
    return this.http.get<Job>(`${this.api}/${id}`);
  }

  public getJobMoreInfo(id): Observable<object> {
    return this.http.get(`${this.api}/${id}/getJobMoreInfo`);
  }
  public getJobListPage(id): Observable<object> {
    return this.http.get(`${environment.backendXY}/jobListPage/${id}`);
  }

  public updateJobList(jobListID, jobList) {
    const updateJobList = `${environment.backendXY}/jobListPage/${jobListID}`
    this.http.put<any>(updateJobList, jobList).pipe(take(1)).subscribe(t => t);
  }

  public updateJob(jobID, job) {
    const updateJob = `${this.api}/${jobID}`
    this.http.put<any>(updateJob, job).pipe(take(1)).subscribe(t => t);
  }
  public updateJobID(jobID, job): Observable<any> {
    const updateJob = `${this.api}/${jobID}`
    return this.http.put<Job>(updateJob, job)
  }


  public findJobs(id) {
    const api = `${environment.backendXY}/findJobs`;
    const params = new HttpParams().append('userID', id);
    return this.http.get(`${api}`, { params });
  }

  updateItem(item, jobManage) {

  }
  updateJobItem() {

  }
}
