import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNameComponent } from './user-name.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GravatarModule } from 'ngx-gravatar';
import { QuillModule } from 'ngx-quill';
import { MaterialModule } from '../material.module';
import { PipeModule } from '../shared/pipes/pipe.module';
import { SmartitsChipsModule } from '../smartits-ui/chips/smartits-chips.module';



@NgModule({
  declarations: [UserNameComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MaterialModule,
    DragDropModule,
    GravatarModule,
    TranslateModule,
    PipeModule,
    SmartitsChipsModule
  ],
  exports: [
    UserNameComponent
  ]
})
export class UserNameModule { }
