<div class="container">
  <div class="row hoverUser">
    <div class="d-flex align-items-center" (click)="navigateToUser(user?.userUrl)" *ngIf="user && isBottomSearch">
      <div class="col-2">
        <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'" fallback="mp"
        size="32" />
      <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
      </div>
      <div class="col-10 px-0">
        <span class="pr-2 pl-2">
          {{ user.firstName }} {{ user.lastName }}
          <span> </span>
        </span>
      </div>
      
    </div>
  </div>
</div>
<div class="container">
  <div class="row hoverPage">
    <div class="d-flex align-items-center" (click)="navigateToPage(page?.pageUrl)" *ngIf="page && isPageSearch">
      <div class="col-2">
      <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'" fallback="mp"
        size="32" />
      <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
      </div>
      <div class="col-10 px-0">
      <span class="pr-2 pl-2">
        {{ page.name }}
        <span> </span>
      </span>
    </div>
  </div>
</div>



<div class="container px-0" *ngIf="user && !isBottomSearch">
  <div class="row">
    <div class="d-flex row pr-2 pl-2 align-items-center card_list" (click)="addNewUser(user)">
      <div class="col-md-1">
        <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'" fallback="mp"
        size="32" />
      <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />

      </div>
      <span class="pr-2 pl-2 col-md-10">
        {{ user.firstName }} {{ user.lastName }}
        <span> </span>
      </span>
    </div>
    
  </div>
</div>

