import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { DialogDataDetailsComponent } from "./dialog-data-details.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { DataItemDetailsModule } from "../data-item-details/data-item-details.module";
import { RouterModule } from "@angular/router";
import { GravatarModule } from "ngx-gravatar";
import { QuillModule } from "ngx-quill";
import { MaterialModule } from "src/app/material.module";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { SmartitsChipsModule } from "src/app/smartits-ui/chips/smartits-chips.module";
import { ProjectModule } from "../jira-system/project/project.module";

@NgModule({
  declarations: [
    DialogDataDetailsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    DirectivesModule,
    GravatarModule,
    PipeModule,
    SmartitsChipsModule,
    QuillModule,
    ProjectModule,
    RouterModule,
    DataItemDetailsModule
  ],
  exports: [
    DialogDataDetailsComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class DialogDataDetailsModule {

}
