import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EmptySub, Group, Model, Page, Image } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { GroupService } from "src/app/shared/services/group.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { environment } from "src/assets/environments/environment";
import { ImageService } from "src/app/shared/services/image.service";
import { HttpClient } from "@angular/common/http";
import { Options } from "ngx-quill-upload";
import { User } from "src/app/shared/interfaces/user.model";
Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: "app-empty-data-dialog",
  templateUrl: "./empty-data-dialog.component.html",
  styleUrls: ["./empty-data-dialog.component.scss"]
})
export class EmptyDataDialogComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];
  public modules;
  public user: User;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmptySubCategory,
    private dialogRef: MatDialogRef<EmptyDataDialogComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    private userService: UserService,
    private pageService: PagesService,
    private groupService: GroupService,
    public imageService: ImageService,
    public http: HttpClient,

  ) { }

  ngOnInit(): void {
    if (!this.data.edit) {
      this.data.empty = {} as EmptySub;
    } else {
        this.user = this.data.user;
        this.data.empty = this.data.model.emptyCategory[0].emptySubContents[this.data.index];
    }
    this.userService.userModel().subscribe(res => {
      this.users = res;
      this.pageService.getAllPages().subscribe(t => {
        this.pages = t;
      })
      this.groupService.getAllGroups().subscribe(t => {
        this.groups = t;
      })

      this.modules = {
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          linkTarget:"_blank",
          source: (searchTerm, renderList, mentionChar) => {

            const values = [{id: "", value: "", link: null }];

            if (mentionChar === "@") {
              this.users.forEach(t => {
                values.unshift({id: t._id, value: `${t.firstName} ${t.lastName}`, link:window.location.origin + '/' + t._id + "/cv"});

              })
              this.pages.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/' + t.pageUrl });
              });
              this.groups.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/groups/' + t.url});
              })

            } else if(mentionChar === "@") {


            }
            if (searchTerm.length === 0) {

              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                  this.userService.user().subscribe(res => {
                    this.user = res;
                    values[i].link = res.userUrl;
                    matches.unshift(values[i]);
                  });
                  matches.unshift(values[i]);
                }


              renderList(matches, searchTerm);

              }
            }
          },
        },
        imageResize: true,
        imageDrop: true,
        imageHandler: {
          upload: (file) => {
            return new Promise((resolve, reject) => {

          if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') { // File types supported for image
            if (file.size < 1000000) { // Customize file size as per requirement

            // Sample API Call
              const uploadData = new FormData();
              uploadData.append('file', file, file.name);

              return this.http.post(`${environment.backend}/image/${file}`, uploadData).toPromise()
              .then((result: Image) => {
                this.imageService.getImageByID(result._id).subscribe((t: Image) => {
                  resolve(t.imageUrl); // RETURN IMAGE URL from response
                 });
            })
                .catch(error => {
                  reject('Upload failed');
                  // Handle error control
                  console.error('Error:', error);
                });
            } else {
              reject('Size too large');
             // Handle Image size large logic
            }
          } else {
            reject('Unsupported type');
           // Handle Unsupported type logic
          }
        });
      },
          accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,
    }
    });
  }

  saveData() {
    if(this.authService.isLoggedIn) {
    if (this.data.career === false) {
      this.data.model.careers.emptySubContents.unshift(this.data.empty);
    } else if (this.data.personalData ===  true) {
      this.data.model.personalData.map(t => t.emptySubContents.unshift(this.data.empty));
    }  else if (this.data.emptySubCat === true && !this.data.edit) {
      this.data.model.emptyCategory.map(t => t.emptySubContents.unshift(this.data.empty));
    }
    this.dialogRef.close(this.data);
    return this.userService.updateUser(this.data.user._id, this.data.user);
  } else {
    this.dialogRef.close();
    this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
      disableClose: false
    });

    this.confirmationDialog.afterClosed().subscribe(result => {
      if(result) {
        // do confirmation actions
      }
      this.confirmationDialog = null;
    });
  }
}
}

export interface EmptySubCategory {
  edit?: boolean;
  empty?: EmptySub;
  model?: Model;
  career?: boolean;
  personalData?: boolean;
  skills?: boolean;
  emptySubCat: boolean;
  index: number;
  user?: User;
}
