<footer><div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 light-bg">
    <ul class="list-unstyled list-inline social text-center">
      <li class="list-inline-item"><i class="fa fa-facebook"></i></li>
      <li class="list-inline-item"><i class="fa fa-twitter"></i></li>
      <li class="list-inline-item"><i class="fa fa-instagram"></i></li>
    </ul>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 bg-medium">
    <ul class="list-unstyled list-inline social text-center">
      <li class="list-inline-item"><i class="fa fa-facebook"></i></li>
      <li class="list-inline-item"><i class="fa fa-twitter"></i></li>
      <li class="list-inline-item"><i class="fa fa-instagram"></i></li>
    </ul>
  </div>
</div>
<div class="row dark-bg text-center">
  <div class="col-12 py-3">
    Copyright 2019 | Powered by Smart ITS | Impressum | Datenschutzerklärung
  </div>
</div>
</footer>
