import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-top-nav-buttons-jobs-projects',
  templateUrl: './top-nav-buttons-jobs-projects.component.html',
  styleUrls: ['./top-nav-buttons-jobs-projects.component.scss']
})
export class TopNavButtonsJobsProjectsComponent implements OnInit {

  @Input() textButtonCreate: string;
  @Input() textButtonData: string;
  @Input() textButtonApplied: string;
  @Input() textButtonSaved: string;
  @Input() textButtonIgnored: string;

  @Input() routerLinkForIgnored: string;
  @Input() routerLinkForApplied: string;
  @Input() routerLinkForData: string;
  @Input() routerLinkForSaved: string;
  @Input() routerLinkForCreate: string;
  
  @Output() buttonCreate = new EventEmitter<boolean>();
  @Output() buttonData = new EventEmitter<boolean>();
  @Output() buttonSaved = new EventEmitter<boolean>();

 
  constructor() { }

  ngOnInit(): void {
  }


  create() {
    this.buttonCreate.emit(true);
  }

  goToData() {
    this.buttonData.emit(true);
  }

  goToSaved() {
    this.buttonSaved.emit(true);

  }

}
