import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesDialogPrimaryComponent } from '../components/modals/languages-dialog-primary/languages-dialog-primary.component';

@Component({
  selector: 'app-primary-footer',
  templateUrl: './primary-footer.component.html',
  styleUrls: ['./primary-footer.component.scss']
})
export class PrimaryFooterComponent implements OnInit {
  languages = [
      {id: "de", value: "de_DE", lang: "de-de", description: "Deutsch"},
      {id: "en", value: "en_US", lang: "en-us", description: "English"},
      {id: "es", value: "es_ES", lang: "es-es", description: "Español"},
      {id: "fr", value: "fr_FR", lang: "fr-fr", description: "Français"},
      {id: "it", value: "it_IT", lang: "it-it", description: "Italiano"},
      {id: "al", value: "sq_AL", lang: "sq-al", description: "Shqip"},
      {id: "tr", value: "tr_TR", lang: "tr-tr", description: "Türkçe"},
  ];

  constructor(
    public translateService: TranslateService,
    public dialog: MatDialog) { }

  ngOnInit(): void {


  }

  openLanguages(){
    this.dialog.open(LanguagesDialogPrimaryComponent);
  }
  setLanguage(id) {
    this.translateService.use(id);
  }

}
