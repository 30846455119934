import { timestamp } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';
import { StatusFollow } from 'src/app/shared/enums/enums-model';

@Component({
  selector: 'app-notifications-obj',
  templateUrl: './notifications-obj.component.html',
  styleUrls: ['./notifications-obj.component.scss']
})
export class NotificationsObjComponent implements OnInit{

  @Input() obj;
  public user;
  loggedUser$:  Observable<any>;
  loggedUser: any;
  public dataList$: Observable<any>;
  userData: any;


  constructor(private authService: AuthService,
    private userService: UserService,
  ) {

  }


  ngOnInit(): void {

    this.userService.getUserCV(this.obj.xUserID).subscribe();
    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe((res: any) => {
      this.userData = res;
    });



    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
      this.loggedUser = res;
  })

  }

  acceptFollow(obj) {
    const findUser = this.loggedUser.followings.data.find(t => t.userId === obj._id);
    if (findUser) {
      this.loggedUser.followings.data.find(t => t.userId === obj._id).s = 2;
      this.obj.data.find(t => t.userId === this.loggedUser.user._id).s = 2;
      this.obj.data.find(t => t.userId === this.loggedUser.user._id).timestamp = new Date();
      this.loggedUser.followings.data.find(t => t.userId === obj._id).timestamp = new Date();
      this.loggedUser.userUser.followers.counter =  this.loggedUser.userUser.followers.counter + 1;
      this.userData.userUser.following.counter =  this.userData.userUser.following.counter + 1;

      this.userService.updateFollowing(this.obj._id, this.obj);
      this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);

     this.userService.updateuUser(this.loggedUser.userUser._id, this.loggedUser.userUser);
     this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);

    } else {
      this.obj.data.find(t => t.userId === this.loggedUser.user._id).s = 1;
      this.obj.data.find(t => t.userId === this.loggedUser.user._id).timestamp = new Date();
      this.loggedUser.userUser.followers.counter =  this.loggedUser.userUser.followers.counter + 1;
      this.userData.userUser.following.counter =  this.userData.userUser.following.counter + 1;
      this.userService.updateFollowing(this.obj._id, this.obj);
      this.userService.updateuUser(this.loggedUser.userUser._id, this.loggedUser.userUser);
      this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
    }
  }

  deleteUser(obj) {
    const data = this.obj.data.find(t => t.userId === this.loggedUser.user._id).s = 0;
    this.userData.followings.data.splice(data, 1);
    this.userService.updateFollowing(this.userData.followings._id, this.userData.followings);

    this.ngOnInit();


    console.log(this.userData.followings.data , "this.userData.followings.data")

    /* const data = user.following.data.find(t => t.userId === this.currentUser._id).status = 0;
    user.following.data.splice(data, 1); */



  }

  getDate() {
    return this.obj.data.find(t => t.userId === this.authService.userID).timestamp;
  }
}
