<div class="container justify-content-center px-0">
  <div class="col-md-12 px-0">
    <div class="card shadow-lg overflow-hidden border-0">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-lg-12 justify-content-lg-end">
            <div class="p-3">
              <div class="text-center">
              </div>
              <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="loginUser()">
                <div class="form-group">
                  <input type="email"  [(ngModel)]="email"
                   class="form-control-sm col-md-12"
                    formControlName="email"
                     placeholder="Enter email"
                      autocomplete="email"
                       required>
                </div>
                <div class="form-group">
                  <input type="password" [(ngModel)]="password" class="form-control-sm col-md-12" formControlName="password" placeholder="Password" autocomplete="current-password">
                </div>
                <button [disabled]="loading" type="submit" class="btn btn-block btn-primary">
                  {{'login' | translate}}
              <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span></button>
               <div class="col text-center">
                <button type="button" class="btn btn-link">{{'forgotPassword' | translate}}?</button>
              </div>
              <div class="horizontal-line col-md-12">

              </div>
                <button type="submit" class="btn btn-block btn-success" (click)="register()">{{'register' | translate}}</button>
                 <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}</div>
              </form>
            </div>

        </div>
        <div class="card-footer bg-transparent">
          <a href="#" class="addPage">{{'addPage' | translate}}</a>
          {{'addPageText' | translate}}.
        </div>
        </div>
      </div>
    </div>

  </div>
</div>

