
  <ng-container *ngIf="isPage && !isUser">
    <div *ngFor="let project of page?.savedProjects; let i = index">
        <app-job-item  [pageObject]="page" [search]="'project.search'" [public]="'project.public'" [projectID]="project.projectId"  (unsaveJobUsers)="test(i)" [savedProject]="'savedProject'" [isSavedProject]="true" (removeSavedProjectPage)="deleteSavedProjectPage(i)" [isPage]="true" (removeSavedProject)="deleteSavedProject(i)" [selectedPage]="selectedPage"></app-job-item>
      </div>
    
  </ng-container>
  

  <ng-container *ngIf="!isPage && !isUser">
    <div *ngFor="let project of page?.savedProjects; let i = index">
        <app-job-item [search]="'project.search'" [public]="'project.public'" [projectID]="project.projectId"  (unsaveJobUsers)="test(i)" [savedProject]="'savedProject'" [isSavedProject]="true" (removeSavedProject)="deleteSavedProject(i)"></app-job-item>
      </div>
    
  </ng-container>
  
  <ng-container *ngIf="isUser">
    <div *ngFor="let project of user?.savedProjects; let i = index">
      <app-job-item  [pageObject]="page" [search]="'project.search'" [public]="'project.public'" [projectID]="project.projectId"  (unsaveJobUsers)="test(i)" [savedProject]="'savedProject'" [isSavedProject]="true" (removeSavedProjectPage)="deleteSavedProjectPage(i)" [isPage]="true" (removeSavedProject)="deleteSavedProject(i)" [selectedPage]="selectedPage"></app-job-item>
    </div>
  </ng-container>
  