import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-price-card',
  templateUrl: './price-card.component.html',
  styleUrls: ['./price-card.component.scss']
})
export class PriceCardComponent implements OnInit {
  @Input() data;
  @Input() name;


  constructor() { }

  ngOnInit(): void {
  }

  buyCoins() {

  }



}
