import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class HeaderService {
  requestHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }
    return httpOptions;
  }
}
