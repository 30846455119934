import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Files, Model, SubFiles } from "src/app/shared/interfaces/model";
import { EmptyFileComponent, EmptySubFile } from "./empty-file/empty-file.component";

@Component({
  selector: "app-files",
  templateUrl: "./files.component.html",
  styleUrls: ["./files.component.scss"]
})
export class FilesComponent implements OnInit {
  @Input() id;
  @Input() file: Files;
  @Input() fileObj: SubFiles;
  @Input() model: Model;
  public selectedIndex: number;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  public setIndex(id) {
    this.selectedIndex = id;

  }
  public removeClick() {
    this.selectedIndex = undefined;
  }
  public addFile(data: EmptySubFile) {
    this.dialog.open(EmptyFileComponent, {
      data,
    });
  }
  public editFile(data: EmptySubFile) {
    this.dialog.open(EmptyFileComponent, {data});
  }
  public deleteSubFile(index) {
    this.file.subCategories.splice(index, 1);
  }
}
