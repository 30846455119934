import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CareerComponent} from "./components/career/career.component";
import {KnowledgeComponent} from "./components/knowledge/knowledge.component";
import {RegisterComponent} from "./components/register/register.component";
import {BottomNavComponent} from "./components/bottom-nav/bottom-nav.component";
import {LoginComponent} from "./components/login/login.component";
import {EducationComponent} from "./components/education/education.component";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AuthInterceptor} from "./shared/services/auth-interceptor.service";
import {FooterComponent} from "./components/footer/footer.component";
import {AuthService} from "./shared/services/auth.service";
import {SmartTranslateService} from "./shared/services/smart.translate.service";
import {MaterialModule} from "./material.module";
import {CommonModule, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CategoryDialogComponent} from "./components/modals/category-dialog/category-dialog.component";
import {DialogService} from "./shared/services/dialog.service";
import {ClickDirective} from "./shared/directives/click.directive";
import {StarRatingComponent} from "./smartits-ui/star-rating/star-rating.component";
import {MatChipsModule} from "@angular/material/chips";
import {SubCategoryDialogComponent} from "./components/modals/sub-category-dialog/sub-category-dialog.component";
import {DataDialogComponent} from "./components/modals/data-dialog/data-dialog.component";
import { EducationDialogComponent } from "./components/modals/education-dialog/education-dialog.component";
import { PersonalizedDataDialogComponent } from "./components/modals/personaldata-dialog/personalized-data-dialog.component";
import { LanguageDialogComponent } from "./components/modals/editors/language-dialog/language-dialog.component";
import { OfferDialogComponent } from "./components/modals/editors/offer-dialog/offer-dialog.component";
import { PcKnowledgeDialogComponent } from "./components/modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component";
import { DriveLicenseDialogComponent } from "./components/modals/editors/drive-license-dialog/drive-license-dialog.component";
import { A4Component } from "./smartits-ui/a4/a4.component";
import { PaginatedViewModule } from "./smartits-ui/paginated-view/paginated-view.module";
import { EmptyDataDialogComponent } from "./components/modals/empty-data-dialog/empty-data-dialog.component";
import { EmptyObjectComponent } from "./components/skills/empty-object/empty-object.component";
import { EmptyRowComponent } from "./components/skills/empty-row/empty-row.component";
import { SubCareerDialogComponent } from "./components/career/sub-career-dialog/sub-career-dialog.component";
import { PersonalDataSubDialogComponent } from "./components/personal-data/personal-data-sub-dialog/personal-data-sub-dialog.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { HeaderDialogComponent } from "./components/modals/header-dialog/header-dialog.component";
import { FilesComponent } from "./components/files/files.component";
import { EmptyFileComponent } from "./components/files/empty-file/empty-file.component";
import { EmptyCategoryDialogComponent } from "./components/modals/editors/empty-category-dialog/empty-category-dialog.component";
import { HomeComponent } from "./components/home/home.component";
import { JobsComponent } from "./components/jobs/jobs.component";
import { SecondTemplateComponent } from "./components/templates/second-template/second-template.component";
import { ColorPickerModule } from "ngx-color-picker";
import { FilePickerModule } from  "ngx-awesome-uploader";
import { AngularCropperjsModule } from "angular-cropperjs";
import { SafeurlPipe } from "./shared/pipes/safeurl";
import { PrimaryFooterComponent } from "./primary-footer/primary-footer.component";
import { LanguagesDialogPrimaryComponent } from "./components/modals/languages-dialog-primary/languages-dialog-primary.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ShareButtonsPopupModule } from "ngx-sharebuttons/popup";
import { WarningDialogComponent } from "./sharedComponents/warning-dialog/warning-dialog.component";
import { ShareButtonsModule } from "ngx-sharebuttons/buttons";
import { ShareIconsModule } from "ngx-sharebuttons/icons";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { RightSidebarComponent } from "./components/right-sidebar/right-sidebar.component";
import { BranchesComponent } from "./components/branches/branches.component";
import { UserDialogPictureComponent } from "./components/modals/user-dialogs/user-dialog-picture/user-dialog-picture.component";
import { GravatarModule } from "ngx-gravatar";
import { UserCoverDialogComponent } from "./components/modals/user-dialogs/user-dialog-picture/user-cover-dialog/user-cover-dialog.component";
import { CreatePageComponent } from "./user-components/pages/create-page/create-page.component";
import { PagesSettingsComponent } from "./user-components/pages/pages-settings/pages-settings.component";
import { SearchUserPipe } from "./shared/pipes/searchUser.pipe";
import { BaseComponent } from "./base/base.component";
import { ChatComponent } from "./components/chat/chat.component";
import { HeaderModulesComponent } from "./components/header-modules/header-modules.component";
import { SearchUsersComponent } from "./components/bottom-nav/search-users/search-users.component";
import { PageModule } from "./user-components/pages/page.module";
import { UserModule } from "./user-components/user/user.module";
import { GroupModule } from "./groups/group.module";
import { FriendListComponent } from "./friends/friend-list/friend-list.component";
import { FriendMessagesComponent } from './components/friend-messages/friend-messages.component';
import { GeneralChatComponent } from './general-chat/general-chat.component';
import { MomentModule } from "ngx-moment";
import { PrivacyDialogComponent } from './components/privacy-dialog/privacy-dialog.component';
import { ToastrModule } from "ngx-toastr";
import { MustMatchDirective } from "./components/register/mustMatch.directive";
import { ContactItemComponent } from './friends/contact-item/contact-item.component';
import { ContactsComponent } from "./friends/friends.component";
import { MainSearchComponent } from './components/main-search/main-search.component';
import { SearchSidebarDetailsComponent } from './components/search-sidebar-details/search-sidebar-details.component';
import { QualificationsDialogComponent } from './components/modals/editors/qualifications-dialog/qualifications-dialog.component';
import { QualificationsDialogArrayComponent } from './components/modals/editors/qualifications-dialog-array/qualifications-dialog-array.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { YourJobComponent } from './components/jobs/your-job/your-job.component';
import { YourProjectsComponent } from './your-projects/your-projects.component';
import { JobDetailsComponent } from './components/jobs/job-details/job-details.component';
import { JobsModule } from "./components/jobs/jobs.module";
import { EditJobsDialogComponent } from "./components/jobs/edit-jobs-dialog/edit-jobs-dialog.component";
import { SavedJobsComponent } from "./components/jobs/saved-jobs/saved-jobs.component";
import { PipeModule } from "./shared/pipes/pipe.module";
import { SmartitsChipsModule } from "./smartits-ui/chips/smartits-chips.module";
import { DirectivesModule } from "./shared/directives/directives.module";
import { NgxPayPalModule } from "ngx-paypal";
import { MatNativeDateModule } from "@angular/material/core";
import { PaymentModule } from "./components/payments/payments.module";
import { PrintDialogComponent } from './components/modals/print-dialog/print-dialog.component';
import { ProjectModule } from "./components/jira-system/project/project.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IgnoredJobsComponent } from "./components/jobs/ignored-jobs/ignored-jobs.component";
import { AppliedJobsComponent } from './components/jobs/applied-jobs/applied-jobs.component';
import { UserNameModule } from "./user-name/user-name.module";
import { UserJobDialogComponent } from './user-job-dialog/user-job-dialog.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { JobItemDetailsModule } from "./job-item-details/job-item-details.module";
import { SidebarSearchJobsProjectsComponent } from './sidebar-search-jobs-projects/sidebar-search-jobs-projects.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { SidebarPcKnowledgeComponent } from './sidebar-search-jobs-projects/sidebar-pc-knowledge/sidebar-pc-knowledge.component';
import { SidebarLanguageComponent } from './sidebar-search-jobs-projects/sidebar-language/sidebar-language.component';
import { TopNavButtonsJobsProjectsModule } from "./top-nav-buttons-jobs-projects/top-nav-buttons-jobs-projects.module";
import { MessagesComponent } from './messages/messages.component';
import { PageProjectMessageComponent } from './page-project-message/page-project-message.component';
import { MainHomeModule } from "./main-home/main-home.module";
import { BookmarksModule } from "./components/bookmarks/bookmarks.module";
import { MessageRightSideComponent } from './message-right-side/message-right-side.component';
import { MessageRightSideJobComponent } from './message-right-side-job/message-right-side-job.component';
import { UserDialogModule } from "./project-details/user-dialog-name/user-dialog.module";
import { AppliedProjectsModule } from "./applied-projects/applied-projects.module";
import { CreateProjectModule } from "./components/create-your-project/create-project.module";
import { IgnoredProjectsModule } from "./ignored-projects/ignores-projects.module";
import { JobItemModule } from "./components/jobs/job-item/job-item.module";
import { SavedProjectsModule } from "./saved-projects/saved-projects.module";
import { EducationNameModule } from "./sharedComponents/education-name/education-name.module";
import { DriveLicenseComponent } from "./sidebar-search-jobs-projects/drive-license/drive-license.component";
import { QualificationsComponent } from "./sidebar-search-jobs-projects/qualifications/qualifications.component";
import { ReligionComponent } from './components/modals/religion/religion.component';
import { CreateJobModule } from "./create-your-job/create-your-job.module";
import { MessageNameComponent } from "./message-right-side-job/message-name.component";
import { DialogDataDetailsModule } from "./components/dialog-data-details/dialog-data-details.module";
import { LanguagePageModule } from "./components/language-page/language-page.module";
import { ButtonCoinsModule } from "./components/button-coins/button-coins.module";
import { BlockedUserDialogComponent } from "./components/modals/blocked-user-dialog/blocked-user-dialog.component";
import { ConfirmationDialogBlockedComponent } from "./components/modals/confirmation-dialog-blocked/confirmation-dialog-blocked.component";
import { ShareDialogComponent } from "./components/modals/share-dialog/share-dialog.component";
import { DataItemDetailsModule } from "./components/data-item-details/data-item-details.module";
import { AdvertisingModule } from "./sharedComponents/advertising/advertising.module";
import { AuthGuard } from "./guards";

import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarouselCardDialogComponent } from "./components/modals/carousel-card-dialog/carousel-card-dialog.component";
import { DeactivateDeleteAccountComponent } from './components/deactivate-delete-account/deactivate-delete-account.component';
import { PrivacyButtonModule } from "./components/privacy-button/privacy-button.module";
import { QuillModule } from "ngx-quill";
import { ImageCropperModule } from "ngx-image-cropper";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    CategoryDialogComponent,
    ClickDirective,
    StarRatingComponent,
    SubCategoryDialogComponent,
    PersonalizedDataDialogComponent,
    DataDialogComponent,
    BottomNavComponent,
    EducationDialogComponent,
    PersonalizedDataDialogComponent,
    LanguageDialogComponent,
    OfferDialogComponent,
    PcKnowledgeDialogComponent,
    DriveLicenseDialogComponent,
    A4Component,
    EmptyDataDialogComponent,
    EmptyObjectComponent,
    CarouselCardDialogComponent,
    EmptyRowComponent,
    SubCareerDialogComponent,
    PersonalDataSubDialogComponent,
    HeaderDialogComponent,
    FilesComponent,
    EmptyFileComponent,
    EmptyCategoryDialogComponent,
    HomeComponent,
    JobsComponent,
    SecondTemplateComponent,
    SafeurlPipe,
    PrimaryFooterComponent,
    LanguagesDialogPrimaryComponent,
    WarningDialogComponent,
    ContactsComponent,
    SidebarComponent,
    RightSidebarComponent,
    BranchesComponent,
    UserDialogPictureComponent,
    UserCoverDialogComponent,
    CreatePageComponent,
    PagesSettingsComponent,
    BaseComponent,
    ChatComponent,
    HeaderModulesComponent,
    SearchUsersComponent,
    FriendListComponent,
    FriendMessagesComponent,
    GeneralChatComponent,
    PrivacyDialogComponent,
    ContactItemComponent,
    CareerComponent,
    KnowledgeComponent,
    RegisterComponent,
    EducationComponent,
    MustMatchDirective,
    MainSearchComponent,
    SearchSidebarDetailsComponent,
    QualificationsDialogComponent,
    QualificationsDialogArrayComponent,
    ProjectsComponent,
    YourJobComponent,
    YourProjectsComponent,
    JobDetailsComponent,
    EditJobsDialogComponent,
    SavedJobsComponent,
    PrintDialogComponent,
    IgnoredJobsComponent,
    AppliedJobsComponent,
    UserJobDialogComponent,
    ConfirmationDialogComponent,
    SidebarSearchJobsProjectsComponent,
    ProjectDetailsComponent,
    SidebarPcKnowledgeComponent,
    SidebarLanguageComponent,
    MessagesComponent,
    PageProjectMessageComponent,
    MessageRightSideComponent,
    MessageRightSideJobComponent,
    MessageNameComponent,
    DriveLicenseComponent,
    QualificationsComponent,
    ReligionComponent,
    BlockedUserDialogComponent,
    ConfirmationDialogBlockedComponent,
    ShareDialogComponent,
    DeactivateDeleteAccountComponent,
     ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    FontAwesomeModule,
    CommonModule,
    PageModule,
    PaymentModule,
    UserModule,
    JobsModule,
    AppliedProjectsModule,
    GroupModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    PaginatedViewModule,
    NgxIntlTelInputModule,
    ColorPickerModule,
    QuillModule.forRoot(),
    FilePickerModule,
    AngularCropperjsModule,
    TooltipModule,
    ShareIconsModule,
    GravatarModule,
    MomentModule,
    CommonModule,
    UserNameModule,
    UserDialogModule,
    ReactiveFormsModule,
    CreateProjectModule,
    CreateJobModule,
    ProjectModule,
    JobItemModule,
    LanguagePageModule,
    SavedProjectsModule,
    DialogDataDetailsModule,
    DataItemDetailsModule,
    IgnoredProjectsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true
    }),
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareButtonsPopupModule,
    PaginatedViewModule,
    NgxIntlTelInputModule,
    TooltipModule,
    AppRoutingModule,
    ColorPickerModule,
    MatChipsModule,
    PipeModule,
    SmartitsChipsModule,
    DirectivesModule,
    NgxPayPalModule,
    JobItemDetailsModule,
    MainHomeModule,
    MatNativeDateModule,
    BookmarksModule,
    IgnoredProjectsModule,
    TopNavButtonsJobsProjectsModule,
    EducationNameModule,
    ButtonCoinsModule,
    AdvertisingModule,
    CarouselModule,
    PrivacyButtonModule
  ],
  providers: [
    AuthGuard,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: AuthService},
    {provide: SmartTranslateService},
    {provide: DialogService},
  ],
  exports: [
    TranslateModule,
    GravatarModule,
    FormsModule,
    MomentModule,
    BottomNavComponent,
    MaterialModule,
    StarRatingComponent,
    ProjectModule,
    UserNameModule,
    JobItemDetailsModule,
    BookmarksModule,
    UserDialogModule,
    JobItemDetailsModule,
    IgnoredProjectsModule,
    JobItemModule,
    SearchUsersComponent,
    EducationNameModule,
    CarouselModule,
    ],

  bootstrap: [AppComponent]
})
export class AppModule {
}



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
