<div class="accordion" id="communications">
  <!-- Accordion item -->
  <div class="accordion-item bg-transparent">
    <h2 class="accordion-header" id="communicationOne">
      <button stopPropagation class="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseOne" aria-expanded="true" aria-controls="communicationcollapseOne">
       Privacy
      </button>
    </h2>
<div id="communicationcollapseOne" class="accordion-collapse collapse" aria-labelledby="communicationOne" data-bs-parent="#communications">
  <div class="accordion-body">
     <!-- Notification list item -->
     <div class="form-check">
      <div class="form-group col-md-12 col-12">

<div class="d-flex justify-content-end" *ngIf="data.pageID">
  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
    size="30">
  <div class="d-flex">

    <h6 *ngIf="data?.userID" class="ms-1">{{userCreatedProject?.firstName}}
      {{userCreatedProject?.lastName}}</h6>

    <div class="dropdown">
      <button type="button" stopPropagation class="btn btn-light btn-sm me-2 ms-1  dropdown-toggle"
        data-bs-toggle="dropdown">
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let privacy of dataArrJobs">
          <app-user-dialog-name [privacy]="privacy"
            (changeEventName)="changeUserOfProject($event)"></app-user-dialog-name>
        </li>
      </ul>
    </div>
  </div>


</div>
</div>
</div>
  <div class="form-check px-0">
    <label class="form-check-label" for="ComRadio2">
      Who can see this
    </label>
    <button stopPropagation class="btn btn-outline-light" style="border: none">
      <i [class]="icon"  (click)="openPrivacy({enum: data?.privacy?.s, isJob: isJob, isProject: isProject})" [tooltip]="text"></i>
    </button>
  </div>
  <!-- Notification list item -->
  <div class="form-check px-0">
    <label class="form-check-label" for="ComRadio3">
      Who can Apply
    </label>
    <button stopPropagation class="btn btn-outline-light" style="border: none">
      <i [class]="iconOffer" [tooltip]="textOffer"  (click)="openPrivacyOffer({enum: data?.apply?.s, offer: true, isProject: isProject, isJob: isJob})"></i>
    </button>
  </div>
  </div>
  </div>
  </div>
  </div>
