
  <div class="card-footer mt-3 pt-2 pb-0 mb-3">
    <!-- Nav profile pages -->
    <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
      <li *ngIf="!routerLinkForCreate" class="nav-item ms-2" (click)="create()" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"> <a class="nav-link"
       > <i class="bi bi-pencil-square col-md-1 px-0"></i> {{textButtonCreate | translate}} </a> </li>
       <li *ngIf="routerLinkForCreate" class="nav-item ms-2"[routerLink]="routerLinkForCreate" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"> <a class="nav-link"
        > <i class="bi bi-pencil-square col-md-1 px-0"></i> {{textButtonCreate | translate}} </a> </li>

      <li *ngIf="routerLinkForData" [routerLink]="routerLinkForData" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-item ms-2">   <i class="bi bi-bar-chart-steps col-md-1 px-0"></i> <a class="nav-link"> {{textButtonData | translate}} </a> </li>
      <li *ngIf="!routerLinkForData" (click)="goToData()" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-item ms-2">   <i class="bi bi-bar-chart-steps col-md-1 px-0"></i> <a class="nav-link"> {{textButtonData | translate}} </a> </li>

      <span class="me-5"></span>
      <li *ngIf="routerLinkForApplied" class="nav-item ms-2" [routerLink]="routerLinkForApplied"  [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" >
        <i class="bi me-1 bi-box-arrow-up-right text-success "></i> <a class="nav-link">{{ textButtonApplied | translate }}</a></li>


      <li class="nav-item ms-2" *ngIf="textButtonSaved"  (click)="goToSaved()" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" >
        <i class="bi bi-bookmark-fill text-info"></i><a [routerLinkActive]="['active']"  class="nav-link"><span>{{ textButtonSaved| translate }}</span></a></li>


    </ul>
  </div>
