
<app-groups-sidebar></app-groups-sidebar>
<div class="row">
  <div class="main">
    <div *ngFor="let group of groups" class="card width-900">
      <app-group-list class="d-flex" [group]="group"></app-group-list>
    </div>
    <div>
    </div>
  </div>
</div>
