import { Component, Inject, Input, OnInit } from "@angular/core";
import { SkillSubCategory } from "../../../shared/enums/enums-model";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Model, PCKnowledge, Qualifications, SkillOffer, Skills, SkillsSub } from "../../../shared/interfaces/model";
import {
  EmptyDataDialogComponent,
  EmptySubCategory,
} from "../empty-data-dialog/empty-data-dialog.component";
import { EditQualifications, QualificationsDialogComponent } from "../editors/qualifications-dialog/qualifications-dialog.component";

@Component({
  selector: "app-sub-category-dialog",
  templateUrl: "./sub-category-dialog.component.html",
  styleUrls: ["./sub-category-dialog.component.scss"],
})
export class SubCategoryDialogComponent implements OnInit {
  @Input() model: Model;
  public qualifications;
  public driveLicense;
  public language;
  public pcKnowledge;
  public offer;
  public skillCategory = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubDialog,
    private dialogRef: MatDialogRef<SubCategoryDialogComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.pcKnowledge = this.data.skillObj.pcKnowledge.data.length > 0;
    this.driveLicense = this.data.skillObj.driveLicenses.data.length > 0;
    this.language = this.data.skillObj.languages.data.length > 0;
    this.offer = this.data.skillObj.skillsOffer.data.length > 0;
    this.qualifications = this.data.skillObj.qualifications.data.length > 0;
    this.skillCategory = [
      {
        id: "Offer",
        name: SkillSubCategory.Offer,
        description: "skills.offer",
        icon: "fa fa-bars",
        disabled: this.offer,
      },
      {
        id: "PCKnowledge",
        name: SkillSubCategory.PCKnowledge,
        description: "skills.pcKnowledge",
        icon: "fa fa-desktop",
        disabled: this.pcKnowledge,
      },
      {
        id: "Language",
        name: SkillSubCategory.Language,
        description: "skills.language",
        icon: "fa fa-language",
        disabled: this.language,
      },
      {
        id: "DriveLicense",
        name: SkillSubCategory.DriveLicense,
        description: "skills.driveLicense",
        icon: "fa fa-id-card",
        disabled: this.driveLicense,
      },
      { id: "newSub", name: "New Sub", description: "Empty Sub" },
      { id: "space", name: "new Space", description: "space" },

      {
        id: "Qualifications",
        name: SkillSubCategory.Qualifications,
        description: "skills.qualifications",
        icon: "fa fa-user",
        disabled: this.qualifications,
      },

    ];
  }

  public addCategory(category, qData: EditQualifications, data: EmptySubCategory, ) {
    if (category.id === "newSub") {
      this.dialog.open(EmptyDataDialogComponent, {
        data,
      });
    } else if (category.id === "Qualifications") {
      // tslint:disable-next-line: prefer-const
      this.dialog.open(QualificationsDialogComponent, {
        data
      });
      this.dialogRef.close(category);

    } else if (this.data.language) {
      this.data.skills.subCategories.map(t1 => t1.languages[0].spacesBetween.unshift({ description: "<br>" }));
    }
    return this.dialogRef.close(category);
  }
}

export interface SubDialog {
  model: Model;
  skills: Skills;
  language?: boolean;
  pcKnowledge?: boolean;
  driveLicense?: boolean;
  offer?: boolean;
  emptyObj?: boolean;
  skillObj?: SkillsSub;
  qualifications?: Qualifications;
  indexOfLang?: number;
}
