<main>

  <div class="container">
    <div class="row g-4">
      <!-- Main content START -->
      <div class="col-lg-8 mx-auto">
        <!-- Privacy & terms START -->
        <div class="card">
          <div class="card-header">
            <h1 class="card-title h4 mb-0" *ngIf="page">Deactivating or deleting your Page</h1>
            <h1 class="card-title h4 mb-0" *ngIf="user">Deactivating or deleting your Account</h1>
            <span *ngIf="page">If you want to take a break from us, you can deactivate your Page. If you want to
              permanently delete your Page, let us know.</span>
            <span *ngIf="user">If you want to take a break from us, you can deactivate your Account. If you want to
              permanently delete your Account, let us know.</span>

          </div>
          <div class="card-body">
            <!-- Title -->
            <div class="form-check">
              <input class="form-check-input" type="radio" name="ComRadio" id="ComRadio2" (click)="deactivate()">
              <label class="form-check-label" for="ComRadio2" *ngIf="page">
                Deactivate Page
              </label>
              <label class="form-check-label" for="ComRadio2" *ngIf="user">
                Deactivate Account
              </label>
              <br>
              <b>This is temporary.</b><br>
              <span *ngIf="page">Your Page will be disabled and your name and photos will be removed from most things
                you've shared.</span>
              <span *ngIf="user">Your Account will be disabled and your name and photos will be removed from most things
                you've shared.
                <br>
                If you login your account will be automatically activated.

              </span>
            </div>
            <div class="form-check mb-3 mt-3">
              <input class="form-check-input" type="radio" name="ComRadio" id="ComRadio2" (click)="delete()">
              <label class="form-check-label" for="ComRadio2" *ngIf="page">
                Delete Page
              </label>
              <label class="form-check-label" for="ComRadio2" *ngIf="user">
                Delete Account
              </label>
              <br>
              <b>This is permanent.</b><br>
              <span *ngIf="page">You won't be able to retrieve the content or information you've shared on as this
                Page.</span>
              <span *ngIf="user">You won't be able to retrieve the content or information you've shared on as this
                Account.
                <br>
                If you login your account will be automatically activated.</span>

            </div>
          </div>
          <div class="card-footer d-flex justify-content-end">
            <button class="btn btn-light ms-2 me-2" (click)="cancel()">
              Cancel
            </button>
            <button class="btn btn-primary ms-2" (click)="continue()">Continue</button>

          </div>

        </div>
        <!-- Privacy & terms END -->
      </div>
    </div> <!-- Row END -->
  </div>

</main>
