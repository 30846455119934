<div class="mt-n4 col-lg-1" *ngIf="hasUserAccesOnSidebar">
  <div class="d-flex align-items-center d-lg-none d-lg-none-show">
    <a class="btn text-secondary py-0 me-sm-3 sidebar-start-toggle" type="button" (click)="sidebarClick($event)">
      <i class="bi bi-justify-left fs-3 lh-1"></i>
      {{page?.name}}
    </a>
</div>
  <div class="nav-sidenav p-4 bg-mode h-100 custom-scrollbar" (appOutsideClick)=outsideClick($event)>
    <!-- Card START -->
      <!-- Side Nav START -->
        <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-bs-toggle="dropdown" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'/' + this.page?.pageUrl" [routerLinkActive]="['active']">       <img ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
              <span class="nav-text">{{page?.name}} </span> <span class="caret"></span></a>

          </li>
              <li class="nav-item" (appOutsideClick)="outsideClick($event)">
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"> <i class="bi bi-house-door nav-icon"></i> <span class="nav-text">{{'pagesSettings.home' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isMessaging" (appOutsideClick)="outsideClick($event)">
            <a class="nav-link"  (click)="sendData()"   [routerLinkActiveOptions]="{ exact: true }" [routerLink]="'/' + this.page?.pageUrl + '/messages'"  [routerLinkActive]="['active']"> <i class="bi bi-chat-left-text nav-icon"></i> <span class="nav-text">{{'pagesSettings.sidebar.messaging' | translate}} </span></a>
          </li>

          <li class="nav-item" *ngIf="isUserJobs" (appOutsideClick)="outsideClick($event)">
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/ourjobs'" [routerLinkActiveOptions]="{ exact: true }"  [routerLinkActive]="['active']"> <i class="bi bi-briefcase nav-icon"></i> <span class="nav-text">{{'pagesSettings.jobs' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isUserProjects" (appOutsideClick)=outsideClick($event)>
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/projects/ourprojects'" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
            > <i class="bi bi-bar-chart-steps nav-icon"></i> <span class="nav-text">{{'pagesSettings.projects' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isPayment" (appOutsideClick)=outsideClick($event)>
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/payments'"  [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"> <i class="bi bi-credit-card nav-icon"></i> <span class="nav-text">{{'pagesSettings.payment' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isPayment" (appOutsideClick)=outsideClick($event)>
            <a class="nav-link"[routerLink]="'/' + this.page?.pageUrl + '/payments_management'" [routerLinkActiveOptions]="{ exact: true }"  [routerLinkActive]="['active']" > <i class="bi bi-credit-card nav-icon"></i> <span class="nav-text">{{'pagesSettings.payment_management' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isNotifications" (appOutsideClick)=outsideClick($event)>
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/notifications'" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" > <i class="bi bi-bell nav-icon"></i> <span class="nav-text">{{'pagesSettings.notifications' | translate}} </span></a>
          </li>
          <li class="nav-item" *ngIf="isOwnerOrAdmin" (appOutsideClick)=outsideClick($event)>
            <a class="nav-link" [routerLink]="'/' + this.page?.pageUrl + '/settings'" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" > <i class="bi bi-gear nav-icon"></i> <span class="nav-text">{{'pagesSettings.settings' | translate}} </span></a>
          </li>
        </ul>
        <!-- Side Nav END -->
    <!-- Card body END -->
  </div>
</div>
