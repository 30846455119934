import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Page } from "src/app/shared/interfaces/model";
import { BranchesService } from "src/app/shared/services/branches.service";
import { PagesService } from "src/app/shared/services/pages.service";
import _ from "lodash";

@Component({
  selector: "app-edit-branch-dialog",
  templateUrl: "./edit-branch-dialog.component.html",
  styleUrls: ["./edit-branch-dialog.component.scss"]
})
export class EditBranchDialogComponent implements OnInit {
  page: Page;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditBranchPageDetails,
  public branchService: BranchesService,
  private pageService: PagesService, private dialogRef: MatDialogRef<EditBranchDialogComponent>,
  ) { this.dialogRef.disableClose = true }

  ngOnInit(): void {
    this.branchService.getBranches();
    this.pageService.getPage(this.data.pageID).subscribe((res:Page) => {
      this.page = res;
      this.page = _.cloneDeep(this.page);
    });
  }
  saveData() {
    this.pageService.updatePage(this.page._id, this.page);
    this.dialogRef.close(this.page);
    }
    showTags(event) {
      this.page.branches = event;
    }

}
export interface EditBranchPageDetails {
  edit?: boolean;
  pageID?: string;
  index?: number;
}
