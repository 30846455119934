import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Job, Project } from '../shared/interfaces/model';
import { ArraysService } from '../shared/services/arrays-service/title-service';
import { CountryDataService } from '../shared/services/country-data.service';

@Component({
  selector: 'app-user-job-dialog',
  templateUrl: './user-job-dialog.component.html',
  styleUrls: ['./user-job-dialog.component.scss']
})
export class UserJobDialogComponent implements OnInit {
  public employmentTypeString;
  public remote;
  countryJsonArray;
  countryJsonObject;
  public currencyObject;
  public paymentID;
public jobDurationString;
public dataObject: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: UserJobDialog, private arrayService: ArraysService,     private countryDataService: CountryDataService  ) { }

  ngOnInit(): void {
    this.arrayService.showEmploymentTypes();
    this.arrayService.showSalary();
    this.arrayService.showRemote();
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);

    if (this.data.project) {
      this.dataObject = this.data.project;
      this.getEmploymentTypeID(this.data.project.employmentType);
      this.getCountryID(this.data.project.c);
      this.getCurrencyID(this.data.project.currency);
      this.getPaymentFormID(this.data.project.incomes.s);
      this.getRemoteID(this.data.project.remote);
    } else {
        this.dataObject = this.data.job;
        this.getEmploymentTypeID(this.data.job.employmentType);
        this.getCountryID(this.data.job.c);
        this.getCurrencyID(this.data.job.currency);
        this.getPaymentFormID(this.data.job.incomes.s);
        this.getRemoteID(this.data.job.remote);
    }




  }

  getEmploymentTypeID(id) {
    this.employmentTypeString = this.arrayService.employmentType.find(
      (t) => t.id === id
    ).description;
  }
  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(
      (t) => t.currency.Alphabeticcode === id
    );
    console.log(this.currencyObject);
  }
  getPaymentFormID(id) {
    this.paymentID = this.arrayService.salary.find((t) => t.id === id).description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.arrayService.jobDurationArray.find(
      (t) => t.id === id
    ).description;
  }
  getRemoteID(id) {
    this.remote = this.arrayService.remoteArray.find((test) => test.id === id).description;
  }
}

export class UserJobDialog {
  job: Job;
  project: Project;
}
