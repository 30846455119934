import { Component, OnInit } from '@angular/core';
import { Observable, first } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-followers',
  templateUrl: './user-followers.component.html',
  styleUrls: ['./user-followers.component.scss']
})
export class UserFollowersComponent implements OnInit {
  public dataList$: Observable<any>;
  public userData;
  loggedUser$:  Observable<any>;
  loggedUser: any;
  userFollowers$: any[];

  constructor(public userService: UserService) {

  }


  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.loggedUser$ = this.userService.userList;
    this.dataList$.subscribe((res: any) => {
      console.log()
      this.userData = res;
      this.userService.showFollowers(this.userData.user._id).pipe(first()).subscribe((users: any[]) => {
        this.userFollowers$ = users
      });
    });



    this.loggedUser$.subscribe(res => {
        this.loggedUser = res;
    })


  }

}
