import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Page, Categories, CategoryChildren } from 'src/app/shared/interfaces/model';
import { CategoryService } from 'src/app/shared/services/category.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import _ from "lodash"

@Component({
  selector: 'app-edit-subcategory-dialog',
  templateUrl: './edit-subcategory-dialog.component.html',
  styleUrls: ['./edit-subcategory-dialog.component.scss']
})
export class EditSubcategoryDialogComponent implements OnInit {
  page: Page;
  keyword = "";
  public category:Categories;
  empty = "";
  public subCategories: CategoryChildren[];
  selectedSubCategory: any;
  isSelectedSubCategory: boolean;
  disabledButton: boolean;


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditSubCategoryPageDetails,
  public categoryService: CategoryService,
  private pageService: PagesService,
  private dialogRef: MatDialogRef<EditSubcategoryDialogComponent>,
  ) { this.dialogRef.disableClose = true }

  ngOnInit(): void {
    this.categoryService.getCategories();
    if (!this.data.edit) {
      this.pageService.getPage(this.data.pageID).subscribe((res:Page) => {
      this.page = res;
      this.data.subCategory = {} as CategoryChildren;
      this.subCategories = [];
      const t = this.categoryService.categories.find(t => t.id === this.data.category);
      this.subCategories = t.subCategory;
      this.selectedSubCategory = {name: "pagesInfo.dialogSubCategory.chooseSubCategory"};
      this.isSelectedSubCategory = true;
      if (this.data.disabledCloseButton) {
        this.disabledButton = true;
      }
      });
    } else {
      this.pageService.getPage(this.data.pageID).subscribe((res:Page) => {
        this.page = res;
        res.categories.subCategory[this.data.index] = this.data.subCategory;
        this.data.subCategory = _.cloneDeep(this.data.subCategory);
        const t = this.categoryService.categories.find(t => t.id === this.data.category);
        this.subCategories = t.subCategory;
        const tt = this.data.subCategory.name;
        this.selectedSubCategory = t.subCategory.find(rtt => rtt.id === tt);
        });

    }
  }

  saveData() {
    if (!this.data.edit && !this.data.changedCat) {
      this.page.categories.subCategory.unshift({name: this.data.subCategory.name, keywords: this.data.subCategory.keywords});
      this.data.page.categories.subCategory = this.page.categories.subCategory;
    } else if (this.data.changedCat) {
      this.page.categories.subCategory = [];
      this.page.categories.subCategory.unshift({name: this.data.subCategory.name, keywords: this.data.subCategory.keywords});
    }
    this.page.categories.subCategory[this.data.index].name = this.data.subCategory.name;
    this.page.categories.subCategory[this.data.index].keywords = this.data.subCategory.keywords;
    this.pageService.updatePage(this.page._id, this.page);
    this.dialogRef.close(this.data);
  }
  removeKeyword(index) {
      this.data.subCategory.keywords.splice(index, 1);
  }
  addKeyword() {
    console.log(this.keyword);
    if (this.data.subCategory.keywords?.length > 0) {
    } else {
      this.data.subCategory.keywords = [];
    }
    this.data.subCategory.keywords.unshift({ name: this.keyword });
    this.keyword = "";
  }
  addSubCategory(event) {
    this.data.subCategory.name = event;
  }
  compare(val1, val2) {
    return val1.id === val2;
  }
}

export interface EditSubCategoryPageDetails {
  subCategory?: CategoryChildren;
  edit?: boolean;
  pageID?: string;
  index?: number;
  category?: string;
  page?: Page;
  disabledCloseButton?: boolean;
  changedCat?: boolean;
}


