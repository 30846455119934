import { NgModule } from "@angular/core";
import { CreateYourJobComponent } from "./create-your-job.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../material.module";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";

@NgModule({
  declarations: [CreateYourJobComponent],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    QuillModule.forRoot(),
  ],
  exports: [
    CreateYourJobComponent
  ]
})


export class CreateJobModule {

}
