import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { AdsManager, Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DataPrint, PrintDialogComponent } from '../modals/print-dialog/print-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, AfterViewInit {
    public payPalConfig;
    public user: User;
    public page: Page;
    pageArray: Page[];
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    isUser;
    isPage;
    isPayment: boolean;
    isTransactions: boolean;
    displayedColumns: string[] = ['checkbox','name', 'budgetPlant.active', 'country', 'budgetPlan', 'budgetPlanEndDate', 'adsDetails', 'adsDetailsUrl', 'adsDetailsharedDescription','adsDetailbuttonName', 'budgetPlanStartDate'];
    public payment;
    @ViewChild('htmlData') htmlData!: ElementRef;
    @Input() isFromPage;
    @Input() pageID;
    company;

    dataSource = new MatTableDataSource<AdsManager>(null);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(public userService: UserService,
        public router: Router, private authService: AuthService,
        public activatedRoute: ActivatedRoute,
        public pageService: PagesService,
        private cd: ChangeDetectorRef,
        private receiver: SenderService,
        public dialog: MatDialog,) {
    }

    ngOnInit(): void {
        this.receiver.paymentData$.subscribe((success) => {
            this.payment =  localStorage.getItem("payment");

            if (this.payment === "isPage") {
                this.pageService.getPage(success._id).subscribe(t => {
                    this.user = t;
                    this.user.firstName = t.name;
                });
            } else {
                this.userService.getUserData(this.authService.userID).subscribe(t => {
                    this.user = t;
                });


            }
        });
        this.isPayment = true;
    }
    ngAfterViewInit() {
    }

    ngOnChanges() {
        this.cd.detectChanges();
    }


    setPayment() {
        this.isPayment = true;
        this.isTransactions = false;
    }

    setTransaction() {
        this.isTransactions = true;
        this.isPayment = false;
    }

    public openPDF(): void {
        let DATA: any = document.getElementById('htmlData');
        html2canvas(DATA).then((canvas) => {
          let fileWidth = 208;
          let fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/png');
          let PDF = new jsPDF('p', 'mm', 'a4');
          let position = 0;
          PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
          PDF.save('angular-demo.pdf');
        });
      }

      pageName(name) {
        console.log(name);
       /* this.pageService.getPagesCompany(name).pipe(take(1)).subscribe(t => {


        }); */
      }
      goForPrint(data) {
        console.log(data, "data")

        data.isUser = true;
        data.user = this.user;
        data.isPayment = true;
        data.creditHistory = data;

     //   {user: user, creditHistory: creditHistory, isUser: true,  pageName: pageName, pageDetails: pageDetails, isPayment: true}, creditHistory


        this.pageService.getPagesCompany(data.company).subscribe(t => {
            this.company = t.page[0]?.page;
            console.log(this.company, "company")
            data.pageName = this.company;
            this.pageService.getPage(this.company._id).subscribe(res => {
            data.pageDetails = res;
            this.dialog.open(PrintDialogComponent, { data })
            });
           })
      }

      buyCoins() {
       let newCredit = {
        account_number: this.user.payment.history[0].account_number,
        amount: this.user.payment.history[0].amount,
        company: this.user.payment.history[0].company,
        date:new Date(),
        type_: this.user.payment.history[0].type_,
        invoiceID: 0
       };


       this.user.payment.counter = this.user.payment.counter + 1;
        newCredit.invoiceID =  this.user.payment.counter;
        this.user.payment.history.unshift(newCredit);
        this.userService.updateUser(this.user._id, this.user);
      }


}
