import { SearchSkillOffer } from './../../shared/interfaces/model';
import { ToastService } from './../../shared/services/toast.service';
import { first } from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {Model, Phone, Location, StateAddress, Keywords} from "../../shared/interfaces/model";
import {User} from "../../shared/interfaces/user.model";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import * as moment from "moment";
import { Gender } from "src/app/shared/enums/gender";
import { CountryISO } from "ngx-intl-tel-input";
import { StatusTitle } from "src/app/shared/enums/enums-model";
import { TranslateService } from "@ngx-translate/core";
import { CategoryService } from "src/app/shared/services/category.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})
export class RegisterComponent implements OnInit {
  public model: Model;
  @ViewChild('stepper') private myStepper: MatStepper;


  public days = ["1", "2", "3", "4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31",]
  public months = moment.months();
  public user = new User();
  CountryISO = CountryISO;
  public address = new StateAddress();
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  musterNumber = "12 345678";
  countryCode = "DE"
  public keyword = "";
  editable: boolean = true;
  selectedKeywords: Keywords[];
  firstFormGroup = this._formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', Validators.required],
    telephone: ['', Validators.required],
    password: ['', {
      validators: [Validators.required, Validators.pattern(StrongPasswordRegx)],
    }],
    gender: [, Validators.required],
    birthday: ["", Validators.required],
  });

  secondFormGroup = this._formBuilder.group({
    accountPrivacy: [true, Validators.required],
    branch: ['', Validators.required],
    category: ['', Validators.required],
    subCategory: ['', Validators.required,],
    keyword: ['']
  });

  thirdFormGroup = this._formBuilder.group({
    street:['', Validators.required],
    postalCode: [0, Validators.required],
    city: ['', Validators.required],
    c: ['', Validators.required],
    state: ['']
  })

  fourthFormGroup = this._formBuilder.group({
    codeConfirmation: ['', Validators.required]
  })


  confirmationCode  = "";


  selectedSubCategory = "";
  selectedCategory = "";

  firstPage = true;
  secondPage = false;
  confirmation = false;
  public indexOfCategory;
  public dataCat;

  public locations = new Location();

  confirmationCodeSent = Math.random().toString(36).slice(-8);

   yearMinus3 = new Date().getFullYear() - 3;
   actualMonth = new Date().getMonth();
   actualDate = new Date().getDate();
  maxDate = new Date(this.yearMinus3, this.actualMonth, this.actualDate);


  empty = "";
  empty1 = "";
  empty2 = "";
  empty3 = "";
  public month = "";
  public genders = [
    { id: Gender.Female, description: "gender.female"},
    { id: Gender.Male, description: "gender.male"},
  ];
  languages = [];
  selectedCountry: any;

  constructor(
    public authService: AuthService,
    public router: Router,
    public http: HttpClient,
    private translate: TranslateService,
    public categoryService: CategoryService,
    public branchesService: BranchesService,
    private _formBuilder: FormBuilder,
    private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.user.birthday = {};
    this.categoryService.getCategory()
    this.categoryService.getCategories();
    this.branchesService.getBranches();

    this.user.categories = {};
    this.user.categories.subCategory = [];
    this.secondFormGroup.get('category').valueChanges.subscribe((value) => {
      this.dataCat = this.categoryService.categories.findIndex(t => t.id === value);
    });
    this.user.searchFilterSkills = {} as any;
    this.user.searchFilterSkills.searchSkillsOffer = {} as any;
    this.user.searchFilterSkills.searchSkillsOffer.data = [] as any;
    this.user.searchFilterSkills.searchDriveLicenses = [] as any;
    this.user.searchFilterSkills.searchLanguages = [] as any;
    this.user.searchFilterSkills.searchPCKnowledge = [] as any;
    this.user.searchFilterSkills.searchQualifications = [] as any;
    this.user.searchLocation = {} as any;
    this.user.searchLocation.active = {} as any;
    this.user.searchLocation.data = [] as any;



    const yearMinus18 = new Date().getFullYear() - 18;
    const month = new Date().getMonth();
    const date = new Date().getDate();
    const browserLang = this.translate.getBrowserLang();
    console.log(browserLang, "browserLang")

    this.user.birthday.date = (new Date(yearMinus18, month, date)).toISOString().substring(0,10);
    this.http.get<any>(`assets/languages/${browserLang}/country.json`).subscribe(data => {
      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

    });
  }
  onSubmit2() {

    console.log(this.firstFormGroup.value, "onSubmit2");
    console.log(this.secondFormGroup.value, "onSubmit2");
    console.log(this.thirdFormGroup.value, "onSubmit2");


    console.log(this.fourthFormGroup.value, "onSubmit2");

  }

  addData() {
    let phone = new Phone();

    console.log()
    phone = this.firstFormGroup.get("telephone").value as any;

    const emailLowerCase =this.firstFormGroup.get("email").value.toLowerCase();
    this.user.email = {} as any;
    this.user.email = [] as any;
    this.user.email.push({name: emailLowerCase});
    this.user.gender =this.firstFormGroup.get("gender").value;
    this.user.address = {} as any;
    this.user.address.data = [] as any;
    this.user.firstName = this.firstFormGroup.get("firstName").value;
    this.user.lastName = this.firstFormGroup.get("lastName").value;
    this.user.phone = {} as any;
    this.user.phone = [] as any;
    this.user.phone.push(phone);
    this.user.password = this.firstFormGroup.get("password").value;
    this.user.language = {} as any;
    this.user.language.primary = this.translate.getBrowserLang();
    this.user.language.secondary = "en";
    this.user.searchFilterSkills.searchDriveLicenses = {} as any;
    this.user.searchFilterSkills.searchLanguages = {} as any;
    this.user.searchFilterSkills.searchPCKnowledge = {} as any;
    this.user.searchFilterSkills.searchQualifications = {} as any;

    this.user.searchFilterSkills.searchDriveLicenses.p = 7;
    this.user.searchFilterSkills.searchLanguages.p = 7;
    this.user.searchFilterSkills.searchPCKnowledge.p = 7;
    this.user.searchFilterSkills.searchQualifications.p = 7;

   // this.user.searchFilterSkills.searchSkillsOffer.data.unshift(this.skills as any);

    this.user.address.data.push(this.thirdFormGroup.value as any);
    let digit =  this.user.address.data[0].postalCode.toString()[0];
    this.user.searchLocation.data.unshift({c: this.user.address.data[0].c, postalCode: this.user.address.data[0].postalCode.toString().substring(0, 3), region: [digit] })

    this.user.pAccount = false;
    this.user.categories.name = this.secondFormGroup.get("category").value,
    this.user.branches = this.secondFormGroup.get("branch").value
    this.user.categories.subCategory.unshift({name: this.secondFormGroup.get("subCategory").value});
    this.user.userUrl = `${this.firstFormGroup.get("firstName").value}${this.firstFormGroup.get("lastName").value}${Math.floor((Math.random() * 10000000) + 1)}`;


    this.authService.saveUser(this.user).subscribe(t => console.log(t));



    const number = this.user.phone[0].e164Number.replace("+", "");

   /* this.authService.checkIfUserExist(this.user.email.data[0].name, number).subscribe(t => {
      if (t.message === "true") {
       // this.firstFormGroup['email'].setErrors({'incorrect': true, 'invalid': true});
        this.firstPage = true
      } else {
        this.firstPage = false;
        this.confirmation = true;
      }
    }); */

 /*this.authService.signUp(this.user).subscribe((res: User) => {
     // this.dataService.setData(this.model, res._id);
   //   this.router.navigateByUrl("/login");
    }); */

}

goNext() {
  this.secondPage = true;
  this.confirmation = false;
  this.authService.confirm(this.confirmationCode).subscribe(res => {
    console.log(res, "res")
  })
}

checkEmailorPhone() {
  let returnStepper = false;
  this.authService.checkIfUserEmailExist(this.firstFormGroup.get("email").value).subscribe(res => {
    console.log(res, "res email")
    if (!res.message) {
      this.myStepper.next();
    } else {
      this.firstFormGroup.controls.email.setErrors({'incorrect': true, 'invalid': true});
      this.firstFormGroup.controls.email.markAsDirty();
      this.cd.detectChanges();
    }
  });



  /* let number = this.firstFormGroup.get("telephone").value as any;
  number = number.nationalNumber.replace(" ", "");
  this.authService.checkIfUserPhoneExist(number).subscribe(res => {
    console.log(res, "res number")
    if (!res.message) {
      this.myStepper.next();
      returnStepper = true;

    } else {
      this.firstFormGroup.controls.telephone.setErrors({'incorrect': true, 'invalid': true});
      this.firstFormGroup.controls.telephone.markAsDirty();
      this.cd.detectChanges();
      returnStepper = false;
    }
  }); */

  /* if (returnStepper) {
    this.myStepper.next();
  } else {
    return;
  } */
}

confirmCode() {
  this.authService.confirm(this.fourthFormGroup.get('codeConfirmation').value).subscribe(res => {

    if (res) {
      this.router.navigateByUrl('login')
    } else {
      this.toastService.openSnackBar("Your code is not valid", "", "succes-snackbar" )
    }
  })
}

  showYears() {
      let max = new Date().getFullYear()
      let min = max - 100
      let years = []

      for (let i = max; i >= min; i--) {
        years.unshift(i)
      }
      return years
  }


  addMonth(test) {
    let index = this.months.findIndex(t => t === test);
    this.month = index + 1 as unknown as string;
    console.log(this.month);
  }
  addGender(test) {
    console.log(test);
  }
  onChange(value) {
    this.locations.c = value;
    }
    showKeywords(event) {
      this.selectedSubCategory = event;
      this.selectedKeywords = event.tags;

    }

    addKeyword() {
      if (Object.values(this.user.searchFilterSkills.searchSkillsOffer.data).length > 1) {
        this.user.searchFilterSkills.searchSkillsOffer.data.unshift({ name: this.secondFormGroup.get('keyword')?.value });
      } else {
        this.user.searchFilterSkills.searchSkillsOffer.data.unshift({ name: this.secondFormGroup.get('keyword')?.value });
      }
 //     this.skills.unshift({ name: this.secondFormGroup.get('keyword')?.value });
      this.secondFormGroup.controls.keyword.reset();
    }
    removeSP(index) {
      this.user.searchFilterSkills.searchSkillsOffer.data.splice(index, 1);
    }

    minItems(min: number) {
      return (control: import("@angular/forms").AbstractControl): { [key: string]: any } | null => {
        if (control.value && control.value.length < min) {
          return { 'minItems': true };
        }
        return null;
      };
    }
   /* get skills() {
      return this.secondFormGroup.get('skills') as FormArray;
    } */

    /* addSkill() {
      this.skills.push(this._formBuilder.control(''));
    } */

    // Method to remove a skill control from the form array
  /*  removeSkill(index: number) {
      this.skills.removeAt(index);
    } */
}

export const StrongPasswordRegx: RegExp =
/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
