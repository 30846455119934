<aside class="pt-4 aside">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3">
      <div class="" *ngIf="router.url === '/pages'">
        <h2>Pages</h2>
        <div *ngFor="let userPage of pages$ | async">
          <app-pages-list class="d-flex" [userPage]="userPage" [sidebar]="'sidebar'"></app-pages-list>
        </div>
        <div class="">
          <div>
            <button type="button" class="button-f button-sc px-0 pb-3 pt-3 pl-2" routerLink="pages/create">
              <div>
                <i class="bi bi-plus width-40" aria-hidden="true"></i>
                <span class="span-padding-left10">Add New Page</span>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="
          router.url === '/' + this.page.pageUrl ||
          router.url === '/' + this.page._id
        ">
        <h1>test</h1>
      </div>
    </div>
  </section>
  <footer class="footer footer-sidebar"></footer>
</aside>
