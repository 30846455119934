<div class="chat_outbox show">
  <div class="chat-title">
    <div class="d-flex row chat_title_header align-items-center">
      <div class="d-flex row align-items-center justify-content-start" fxFlex="70">
        <img *ngIf="hasPhoto" borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
        <img *ngIf="!hasPhoto" borderWidth="2" borderColor="#ffffff" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
        <b>{{user?.firstName}} {{user?.lastName}}</b>
      </div>
      <div class="d-flex row justify-content-around" fxFlex="30">
      <b><i class="bi bi-telephone-fill"></i></b>
      <b><i class="bi bi-three-dots"></i></b>
      <b><i class="bi bi-x" (click)="closeChat($event)"></i></b>
    </div>
    </div>

  </div>
  <div style="overflow-y: auto" class="msg-s-message-list-container relative flex mtA ember-view">
    <div class="msg-s-message-list full-width scrollable">
      <ul #messageThread class="message-thread" *ngIf="messages.length !== 0" style="overflow-y: auto; height: xyz;">
        <li class="align-items-in-column" *ngFor="let message of messages; trackBy: trackThings; let i = index">
          <ng-container *ngFor="let content of message.content">
            <ng-container *ngFor="let msg of content.chat; let last = last" >
              <div class="msg-s-event-listitem
              msg-s-event-listitem--m2m-msg-followed-by-date-boundary msg-s-event-listitem--last-in-group msg-s-event-listitem--other" style="background-color: #ebebeb; padding-top: .25rem" *ngIf="!alignMessage(msg.emitterId)">
                <a  href="javascript: void(0);" class="ember-view msg-s-event-listitem__link" (click)="navigateToUser()">
                  <img [src]="this.image" ngx-gravatar
                       [email]="'example@mail.com'" fallback="mp" size="30">
                </a>
                <div class="msg-s-message-group__meta">
                  <a class="msg-s-message-group__profile-link ember-view">
                    <span class="msg-s-message-group__name t-14 t-black t-bold hoverable-link-text">
                      {{this.outUser?.firstName}} {{this.outUser?.lastName}}
                    </span>
                  </a>
                  <time class="msg-s-message-group__timestamp white-space-nowrap t-12 t-black--light t-normal"> {{msg.timestamp | amDateFormat : 'LT'}}</time>
                </div>
                <div class="msg-s-event-listitem__message-bubble msg-s-event-listitem__message-bubble--msg-fwd-enabled">
                    <p class="msg-s-event-listitem__body t-14 t-black--light t-normal" [innerHtml]="msg.value"></p>
                </div>
              </div>
              <time *ngIf="!last" class="msg-s-message-list__time-heading t-12 t-black--light t-boldmsg-s-message-list__time-heading t-12 t-black--light t-bold">{{msg.timestamp | amDateFormat:'ll'}}</time>


              <div class="msg-s-event-listitem
              msg-s-event-listitem--m2m-msg-followed-by-date-boundary msg-s-event-listitem--last-in-group msg-s-event-listitem--other" *ngIf="alignMessage(msg.emitterId)">
                <a href="javascript: void(0);" class="ember-view msg-s-event-listitem__link" (click)="navigateToUser()">
                  <img [src]="this.authImage" ngx-gravatar
                       [email]="'example@mail.com'" fallback="mp" size="30">
                </a>
                <div class="msg-s-message-group__meta">
                  <a class="msg-s-message-group__profile-link ember-view">
                    <span class="msg-s-message-group__name t-14 t-black t-bold hoverable-link-text">
                      {{this.authUser?.firstName}} {{this.authUser?.lastName}}
                    </span>
                  </a>
                  <time class="msg-s-message-group__timestamp white-space-nowrap t-12 t-black--light t-normal"> {{msg.timestamp | amDateFormat : 'LT'}}</time>
                </div>
                <div class="msg-s-event-listitem__message-bubble msg-s-event-listitem__message-bubble--msg-fwd-enabled">
                    <p class="msg-s-event-listitem__body t-14 t-black--light t-normal" [innerHtml]="msg.value"></p>
                </div>
              </div>

            </ng-container>
          </ng-container>
          <ng-container *ngIf="alignMessage(message.emitterId) && selectedMessage  === i">
            <div class="dropdown-icon dropdown-toggle px-0 nav-item" type="button" id="menu1"
            data-toggle="dropdown">
            <i class="bi bi-reply-fill"></i>
              <i class="bi bi-three-dots"></i>
            </div>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">
              <a class="dropdown-item text-align-end" (click)="deleteMessage(i, message)"><span>Delete</span></a>
              <a class="dropdown-item text-align-end" href="#"><span>Another action</span></a>
              <a class="dropdown-item text-align-end" href="#"><span>Something else here</span></a>
            </div>
          </ng-container>
        </li>

      </ul>

    </div>


  </div>
  <div class="type_msg_user">
    <div class="input_msg_write">
      <quill-editor placeholder="" [styles]="editorConfig" [modules]="config"
       [(ngModel)]="message" required
                   > </quill-editor>
    </div>
    <hr>
    <div class="col-md-12 flex justify-content-between flex row align-items-center" style="max-height: 3rem;">
      <div class="flex col-md-4 justify-content-between" style="margin-bottom: 1rem;">
        <i class="bi bi-image-fill"></i>
        <i class="bi bi-link"></i>
        <i class="bi bi-dash"></i>
        <i class="bi bi-camera-video-fill"></i>
      </div>
      <div class="col-md-2" style="margin-bottom: 1rem;">
        <button  (click)="sendMessage(contact)" [ngClass]="message?.length > 0 ?  'btn btn-primary': 'btn btn-link'" [disabled]="!message">
          <svg [ngClass]="message?.length > 0 ?  'crt8y2ji': ''" width="20px" height="20px" viewBox="0 0 24 24">
            <path
              d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592 3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52 C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566 23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088 21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575 C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088 C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905 C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748 16.6915026,12.4744748 16.6915026,12.4744748 Z"
              fill-rule="evenodd" stroke="none" ></path>
          </svg>
        </button>
      </div>

    </div>
  </div>

</div>

