<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title">{{ header }}</h4>
    </div>
    <br>

    <div class="modal-body" *ngIf="isBlock">
      <span>
        {{firstName}}
        will no longer be able to: </span> <br>
        <li>See your posts on your timeline </li>
        <li>Tag you </li>
        <li>Invite you to events or groups</li>
        <li>Message you</li>
        <li>Add you as a contact </li>

<br>
<span>If you're contact, blocking {{firstName}} will also un contact them.</span> <br>
<span>If {{firstName}} moderates your live streams as a community manager, blocking them will remove them as a community manager.</span> <br>
<span>If you just want to limit what you share with {{firstName}} {{lastName}} or see less of them, you can take a break from them instead.</span>

    </div>

    <div class="modal-body" *ngIf="isUnblock">
      <span>
        Are you sure you want to unblock  {{firstName}}?
        </span>
        <li>See your posts on your timeline </li>
        <li>Tag you </li>
        <li>{{firstName}} may be able to see your timeline or contact you, depending on your privacy settings
        </li>
        <li>Tags you and {{firstName}} previously added of each other may be restored</li>
        <li>You can remove tags of yourself on your activity log </li>

<br>
<span>Please remember you'll have to wait 48 hours before you can re-block {{firstName}}</span>

    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="btn btn-light me-2" (click)="onConfirmClick()" >{{confirmButtonText}}</button>
	<button class="btn btn-light" mat-dialog-close >{{cancelButtonText}}</button>
</mat-dialog-actions>
