import { Directive, ElementRef, HostListener } from "@angular/core";


@Directive({
    selector: 'textarea[autosize]'
})
export class Autosize {
    @HostListener('input',['$event.target'])
     onInput(textArea: HTMLTextAreaElement): void {
       this.adjust();
     }
     constructor(public element: ElementRef){
     }
     ngAfterContentChecked(): void{
       this.adjust();
     }
     adjust(): void{
     let offset = this.element.nativeElement.offsetHeight - this.element.nativeElement.clientHeight;
       this.element.nativeElement.style.height = 'auto';
       this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + offset +  "px";
     }
   }