<div class="main-content right-aligned">
  <section class="search-result-container">
    <div>
      <div class="result-list" *ngFor="let userJobs of jobs">

        <app-user-jobs-published  [userJobs]="userJobs" ></app-user-jobs-published>
      </div>
    </div>
  </section>
</div>
