import { HttpClient } from "@angular/common/http";
import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { take } from "rxjs/operators";
import { Role } from "src/app/shared/enums/role";
import { Categories, CategoryChildren, Keywords, Page, Phone, Roles, SearchLocation } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { CategoryService } from "src/app/shared/services/category.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-create-page",
  templateUrl: "./create-page.component.html",
  styleUrls: ["./create-page.component.scss"]
})
export class CreatePageComponent implements OnInit {
  public page = new Page();
  search;
  address;

  searchCategory;
  public branch = "";
  public categories: Categories;
  selectedKeywords: Keywords[];
  selectedChildren: CategoryChildren[];
  keyword = "";
  selectedCategory = "";
  selectedSubCategory = "";
  public selectedChild = null
  public currentUser: User;
  hasPhoto;
  @ViewChild('pageForm', { static: true }) pageForm: NgForm;
  languages = [];


  constructor(public router: Router,
    public branchesService: BranchesService,
    public authService: AuthService,
    public categoryService: CategoryService,
    public pageService: PagesService,
    public userService: UserService,
    public http: HttpClient,
    public shareData: SenderService) { }

  ngOnInit(): void {
    this.categoryService.getCategories();
    this.branchesService.getBranches();
    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
      if (res) {
        this.currentUser = res;
        this.http.get<any>(`assets/languages/${this.currentUser?.language?.primary}/country.json`).subscribe(data => {
          this.languages = data;
          this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
          this.getAddress(this.currentUser.address.data[0].c);

        });
        console.log(this.currentUser.categories.subCategory[0].name, "this.currentUser.categories.name")
        this.pageForm.controls['branches'].setValue(this.currentUser.branches);
        this.pageForm.controls['email'].setValue(this.currentUser.email[0]?.name);
        this.pageForm.controls['categories'].setValue(this.currentUser.categories.name);
        this.pageForm.controls['country'].setValue(this.currentUser.address.data[0].c);
        this.pageForm.controls['phone'].setValue(this.currentUser.phone[0].number);

        this.pageForm.controls['subCategory'].setValue(this.currentUser.categories.subCategory[0].name);
        this.selectedChildren = this.categoryService.categories.find(t => t.id === this.currentUser.categories.name).subCategory;
        this.selectedChild = this.currentUser.categories.subCategory[0].name;
        const keywords = this.currentUser.searchFilterSkills.searchSkillsOffer;
        if (keywords?.data?.length > 0) {
          this.selectedKeywords = this.currentUser.searchFilterSkills.searchSkillsOffer.data;
        }
        // this.page.categories.name = this.currentUser.categories.name;
        //  this.page.categories.subCategory = this.currentUser.categories.subCategory;
        //  this.page.phone = this.currentUser.phone.data[0];
        console.log(this.page.email, "pageEmail")
      }
    });
  }
  addNewPage(myForm: NgForm) {
    this.page.branches = this.branch;
    this.page.categories = this.categories;
    this.page.locations = [];
    this.page.phone = this.page.phone;
    this.page.pageUrl = `${this.page.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;
    this.page.roles = {};
    this.page.roles.Admin = [];
    this.page.roles.Owner = [];
    this.page.roles.Advertiser = [];
    this.page.roles.Analyst = [];
    this.page.roles.Editor = [];
    this.page.roles.Moderator = [];
    this.page.roles.User = [];
    const selectedBranch = myForm.controls[("selectedBranch")].value;
    this.page.branches = selectedBranch;
    this.page.categories = {
      name: this.selectedCategory, subCategory: [
        { name: this.selectedSubCategory, keywords: this.selectedKeywords }]
    };
    // this.page.categories.name = this.selectedCategory;
    //  this.page.categories.subCategory.unshift({name: this.selectedSubCategory, keywords: this.selectedKeywords})
    this.page.roles.Admin.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Owner.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Advertiser.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Analyst.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Editor.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.Moderator.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.page.roles.User.unshift({ userID: this.authService.userID, timestamp: new Date() });
    this.pageService.setPage(this.page).subscribe((r) => {
    }
    );
    this.selectedKeywords = [];
    setTimeout(() => {
      this.router.navigateByUrl("/pages");
    }, 1000);

  }


  createPage(myForm: NgForm) {
    this.page.categories = this.categories;
    this.page.locations = [];
    this.page.locations.unshift({
      city: myForm.form.value.city,
      headquarters: true,
      c: this.address.type,
      postalCode: myForm.form.value.postalCode,
      street: myForm.form.value.street
    });
    this.page.searchLocation = {} as any
    this.page.email = [] as any;
    this.page.email.unshift({ name: myForm.form.value.email })
    this.page.searchLocation.remote = 1;
    this.page.language = this.currentUser.language;
    this.page.userID = this.currentUser._id;
    this.page.searchLocation.data = [] as SearchLocation[];
    this.page.searchLocation.data.unshift({
      c: this.address.type,
      postalCode: myForm.form.value.postalCode,
      remote: true,
      available: new Date(),
    })
    this.page.phone = [] as Phone[];
    this.page.phone.unshift(myForm.form.value.phone);
    this.page.categories = {
      name: this.selectedCategory, subCategory: [
        { name: myForm.form.value.subCategory, keywords: this.selectedKeywords }]
    };
    this.page.pageUrl = `${this.page.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;



    // this.page.categories.name = this.selectedCategory;
    //  this.page.categories.subCategory.unshift({name: this.selectedSubCategory, keywords: this.selectedKeywords})
    this.pageService.setPage(this.page).subscribe((r) => {

    }
    );
     this.selectedKeywords = [];
     setTimeout(() => {
       this.router.navigateByUrl("/pages");
     }, 1000);

  }
  addBranch(event) {
    this.branch = event.id;
    this.search = "";
  }
  addCategory(event) {
    this.categories = event;
    this.searchCategory = "";
  }
  removeBranch(index) {
    return index;
  }
  showChildren(event) {
    this.selectedCategory = event;
    console.log(this.selectedCategory);
    this.selectedChildren = this.categoryService.categories.find(t => t.id === event).subCategory;
    // this.selectedChildren = event.subCategory;
    //  this.selectedChildren.unshift(event.subCategories);
  }

  addKeyword() {
    if (this.selectedKeywords?.length > 0) {
    } else {
      this.selectedKeywords = [];
    }
    this.selectedKeywords.unshift({ name: this.keyword });
    this.keyword = null;
  }
  showKeywords(event) {
    console.log(event);
    this.selectedSubCategory = event.id;
    this.selectedKeywords = event.keywords;
    this.selectedKeywords = [];
  }
  removeKeyword(index) {
    console.log(this.selectedKeywords, "test")
    this.selectedKeywords.splice(index, 1);

  }
  /* removeCategory(index) {
    this.categories.splice(index, 1);
  } */

  navigateToPayment() {
    localStorage.setItem("payment", "isUser");
    this.shareData.paymentDataVisibility(this.currentUser);
    this.router.navigateByUrl(this.currentUser?.userUrl + '/payments');
  }

  navigateToUser() {
    const test = [this.currentUser, "outside"];
    this.shareData.send(test);
    this.router.navigate([this.currentUser.userUrl, "start"]);
  }
  onChange(value) {
    let list = this.languages.filter(x => x.value === value)[0];
    this.currentUser.address.data[0].c = list.type;
  }
  public getAddress(id) {
    this.address = this.languages.find(t => t.type === id);
  }
}
