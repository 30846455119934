import { Component, Input, OnInit } from '@angular/core';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  @Input() data: any;

  constructor(
    ) { }

  ngOnInit(): void {

  }

}
