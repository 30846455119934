<div class="container px-0">

  <h4>Upload your Cover Picture</h4>

   <angular-cropper #imageCoverCropper *ngIf="imgUrl"
  [cropperOptions]="imgConfig"
   [imageUrl]="imgUrl | safeurl"></angular-cropper>
   <div class="col-md-12 docs-buttons px-0 pt-1">
    <!-- <h3>Toolbar:</h3> -->
    <div class="btn-group">
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="zoom" data-option="0.1" title="Zoom In">
        <span class="docs-tooltip" data-toggle="tooltip" title="" (click)="cropperZoom('0.1')" data-original-title="cropper.zoom(0.1)">
          <span class="fa fa-search-plus"></span>
        </span>
      </button>
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="zoom" data-option="-0.1" title="Zoom Out">
        <span class="docs-tooltip" data-toggle="tooltip" title="" (click)="cropperZoom('-0.1')" data-original-title="cropper.zoom(-0.1)">
          <span class="fa fa-search-minus"></span>
        </span>
      </button>
    </div>

    <div class="btn-group">
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="rotate" data-option="-45" (click)="rotateLeft45()" title="Rotate Left">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(-45)">
          <span class="fa fa-undo"></span>
        </span>
      </button>
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="rotate" data-option="45" (click)="rotateRight45()" title="Rotate Right">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(45)">
          <span class="fa fa-repeat"></span>
        </span>
      </button>
    </div>

    <div class="btn-group">
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="scaleX" (click)="flipHorizontal()" data-option="-1" title="Flip Horizontal">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.scaleX(-1)">
          <span class="fa fa-arrows-h"></span>
        </span>
      </button>
      <button [disabled]="!imgUrl" type="button" class="btn btn-primary" data-method="scaleY" (click)="flipVertical()" data-option="-1" title="Flip Vertical">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.scaleY(-1)">
          <span class="fa fa-arrows-v"></span>
        </span>
      </button>
    </div>
    <div class="btn-group">
      <button type="button" [disabled]="!imgUrl" class="btn btn-success" data-method="crop" title="Crop" (click)="saveImage()">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.crop()">
          <span class="fa fa-check"></span>
        </span>
      </button>
    </div>

    <div class="btn-group">
      <label class="btn btn-primary btn-upload" for="inputImage" title="Upload image file" >
        <input type="file" class="sr-only" id="inputImage" name="file" accept="image/*" (change)="fileChangeEvent($event)">
        <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="Import image with Blob URLs">
          <span class="fa fa-upload"></span>
        </span>
      </label>
    </div>
    <br>
    <div class="d-flex justify-content-end">
    <div class="btn-group" style="padding-right: 5px;">
    <button class="btn btn-secondary" mat-dialog-close mat-button>
      {{ "cancel" | translate }}
    </button>
    </div>
    <div class="btn-group">
    <button type="submit" class="btn btn-success me-1" mat-button (click)="saveData()">
      {{ "save" | translate }}
    </button>
    </div>
  </div>
    <br>



    <!-- Show the cropped image in modal -->
    <div class="modal fade docs-cropped" id="getCroppedCanvasModal" aria-labelledby="getCroppedCanvasTitle" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="getCroppedCanvasTitle">Cropped</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body"><canvas width="90" height="90"></canvas></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div><!-- /.modal -->
    <ng-container *ngIf="authService.isLoggedIn">
      <img *ngIf="imgUrl" [src]="image"  height="420" style="cursor:  pointer; overflow: hidden">
      <img *ngIf="!imgUrl" [src]="imagePhoto" height="420" style="cursor:  pointer" (error)="img.src = '../../../../assets/img/User_BG.jpg'" #img>

      </ng-container>
  </div>
  </div>
