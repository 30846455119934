<div class="container">
  <form class="form-grid">
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{'Name of SubCategory'}}</label>
          <input name="school" autocomplete="off" [(ngModel)]="data?.empty.name"  class="form-control-sm col-md-12" >
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <label> {{'education.description' | translate}}
          </label>
          <quill-editor [(ngModel)]="data?.empty.description" name="dialogDescription"
          [modules]="modules"></quill-editor>
        </div>
      </div>
    </li>
  </form>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</div>
