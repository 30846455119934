import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from 'src/app/shared/services/languages.service';

@Component({
  selector: 'app-languages-dialog-primary',
  templateUrl: './languages-dialog-primary.component.html',
  styleUrls: ['./languages-dialog-primary.component.scss']
})
export class LanguagesDialogPrimaryComponent implements OnInit {

  selectedRegion: number= 0;
  languages = [
    {id: "en", value: "en_US", lang: "en-us", description: "English"},
    {id: "de", value: "de_DE", lang: "de-de", description: "Deutsch"},
    {id: "es", value: "es_ES", lang: "es-es", description: "Español"},
    {id: "fr", value: "fr_FR", lang: "fr-fr", description: "Français"},
    {id: "it", value: "it_IT", lang: "it-it", description: "Italiano"},
    {id: "al", value: "sq_AL", lang: "sq-al", description: "Shqip"},
    {id: "tr", value: "tr_TR", lang: "tr-tr", description: "Türkçe"},
];
showLang= [];
  constructor(public languageService: LanguagesService,
    private dialogRef: MatDialogRef<LanguagesDialogPrimaryComponent>,
    public translateService: TranslateService
    ) { }

  ngOnInit(): void {
    this.languageService.getPrimaryLanguages();
    this.showLang = this.languageService.primaryLanguages;
    this.showLang.sort((a,b) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0));
  }

  closeDialog() {
    this.dialogRef.close();
  }
  setLanguage(id) {
    this.translateService.use(id);
  }
  setRegion(event) {
    this.selectedRegion = event;
    if(event  === 0) {
      this.showLang =this.languageService.primaryLanguages[0];
    } else if(event === 1) {
      this.showLang = this.languageService.primaryLanguages[0].africa;
    } else if (event === 2) {
      this.showLang = this.languageService.primaryLanguages[0].america;
      } else if(event === 3) {
        this.showLang = this.languageService.primaryLanguages[0].asia;
      } else if(event === 4) {
        this.showLang = this.languageService.primaryLanguages[0].easternEurope;
      } else if (event === 5) {
        this.showLang = this.languageService.primaryLanguages[0].westernEurope;
      }
  }

}
