<div class="width-900 d-flex">
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>
  <div class="card me-1 ms-1">
    <div class="card-body">
      <div class="d-flex justify-flex-start align-items-center">
        <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
        <div class="">
          <div role="heading" class="font-weight-600">Comments</div>
          <div class="">Push, Email, SMS</div>
        </div>
       </div>
    </div>
  </div>

</div>
