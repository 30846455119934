<div class="fade tab-pane show active h-100" id="chat-1" role="tabpanel" aria-labelledby="chat-1-tab">
    <!-- Top avatar and status START -->
    <div class="d-sm-flex justify-content-between align-items-center">
      <div class="d-flex mb-2 mb-sm-0">
        <div class="flex-shrink-0 avatar me-2">
          <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
        </div>
        <div class="d-block flex-grow-1">
          <h6 class="mb-0 mt-1" *ngIf="!!data?.pageID"> {{page?.page?.name}}</h6>
          <h6 class="mb-0 mt-1" *ngIf="!data?.pageID">    {{user?.firstName}} {{user?.lastName}}</h6>
          <div class="d-flex align-items-center">
            <button (click)="openJob({job: true, data: data})" class="py-1 btn btn-sm btn-primary-soft col-lg-12 col-4 ms-1 me-1">{{"jobText" | translate}}</button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <!-- Call button -->
      <!--  <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Audio call"><i class="bi bi-telephone-fill"></i></a> -->
      <!--  <a href="#!" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Video call"><i class="bi bi-camera-video-fill"></i></a> -->
        <!-- Card action START -->
        <div class="dropdown">
          <a class="icon-md rounded-circle btn btn-primary-soft me-2 px-2" href="#" id="chatcoversationDropdown" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"><i class="bi bi-three-dots-vertical"></i></a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown">
            <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
            <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a></li>
            <li><a class="dropdown-item" href="#"><i class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
            <li><a class="dropdown-item" (click)="deleteChat()"><i class="bi bi-trash me-2 fw-icon"></i>Delete chat</a></li>
            <li class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
          </ul>
        </div>
        <!-- Card action END -->
      </div>
    </div>
    <!-- Top avatar and status END -->
    <hr>
    <!-- Chat conversation START -->
    <div class="chat-conversation-content custom-scrollbar">
      <!-- Chat time -->
      <ng-container *ngIf="checkUserInPage">
        <div class="text-left small my-2">{{"Message"}}:   {{userApplied?.firstName}} {{userApplied?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
      </ng-container>
      <ng-container *ngIf="!checkUserInPage">
        <div *ngIf="pageId" class="text-left small my-2">{{"Message"}}:  {{page?.name}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
        <div *ngIf="!pageId" class="text-left small my-2">{{"Message"}}:  {{userApplied?.firstName}} {{userApplied?.lastName}} {{jobManage?.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

      </ng-container>

      <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHTML]="jobManage?.message?.description" disabled></div>
      <div class="row mt-1 mb-1 align-items-center" *ngIf="jobManage?.cancel.status !== 2">
        <p style="width: fit-content;">{{'jobText' | translate}}: </p>
         <button class="btn btn-sm py-1 btn-primary me-2 col-lg-2 col-4"   *ngIf="jobManage?.cancel.status === 0" (click)="apply(userJob)">{{'job.apply' | translate}}</button>
         <button class="btn btn-sm py-1 btn-danger-soft col-lg-2 col-4" (click)="cancel()">{{'cancel' | translate}} </button>
       </div>
       <div class="row mt-1 mb-1 align-items-center" *ngIf="jobManage?.cancel.status === 2">
        <p style="width: fit-content;" class="text small my-2">{{'jobText' | translate}} Cancelled: {{cancelledUser?.firstName}} {{cancelledUser?.lastName}} <span> - {{jobManage?.cancel.timestamp | date: "dd.MM.yyyy HH:mm"}}</span> </p>
        <div class="bg-light text-secondary p-2 px-3 rounded-2" *ngIf="jobManage?.cancel.description" [innerHTML]="jobManage?.cancel.description" disabled></div>
    </div>
    <div *ngIf="cancelJob">
      <div class="d-sm-flex align-items-end">
        <quill-editor name="editor"   style="width:100%" [modules]="config" [(ngModel)]="cancelMessage" >
        </quill-editor>
        <button (click)="cancelJobButton()" [disabled]="!cancelMessage" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
      </div>
    </div>
       <hr>
       <div class="text-15 font-medium">Messages:</div>
      <!-- Chat message left -->
<ng-container *ngFor="let comment of jobManage?.comments">
    <div class="d-flex mb-1" *ngIf="comment.userID !== authService.userID">
        <div class="flex-shrink-0 avatar avatar-xs me-2">
          <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="">
        </div>
        <div class="flex-grow-1">
          <div class="w-100">
            <div class="bg-light text-secondary p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
            <ng-container *ngIf="checkUserInPage">
              <div class="small my-2" class="text-left small my-2"> <app-message-name [userId]="comment?.userId"></app-message-name> {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
            </ng-container>
            <ng-container *ngIf="!checkUserInPage">
              <div class="small my-2" *ngIf="pageId" class="text-left small my-2">{{page?.name}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
              <div class="small my-2" *ngIf="!pageId" class="text-left small my-2">{{userMsg?.firstName}}  {{userMsg?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>

            </ng-container>
          </div>
        </div>
      </div>
        <!-- Chat message right -->
        <div class="d-flex justify-content-end text-end mb-1" *ngIf="comment.userID === authService.userID">
            <div class="w-100">
              <div class="d-flex flex-column align-items-end">
                <div class="bg-primary text-white p-2 px-3 rounded-2" [innerHtml]="comment.body"></div>
                <div class="small my-2">{{userHasWritten?.firstName}} {{userHasWritten?.lastName}} {{comment.createdAt | date: "dd.MM.yyyy HH:mm"}}</div>
              </div>
            </div>
          </div>


</ng-container>

    </div>
    <!-- Chat conversation END -->
  </div>
