<ng-container *ngIf="educationObject"><a stopPropagation (click)="openPage()">{{educationName}}</a></ng-container>
<ng-container *ngIf="careerObject">
 <a stopPropagation (click)="openPage()"> {{careerName}}</a>
</ng-container>
<ng-container *ngIf="companyURL">
  <a  stopPropagation (click)="openNewTab(careerUrl)">{{careerUrl}}
  </a>
</ng-container>
<ng-container *ngIf="educationCompanyURL">
  <a  stopPropagation (click)="openNewTab(educationURL)">{{educationURL}}
  </a>
</ng-container>
<ng-container *ngIf="!!hometown">
  <a stopPropagation (click)="openPage()"> {{careerName}}</a>
 </ng-container>

 <ng-container *ngIf="!!religion">
  <a stopPropagation (click)="openPage()"> {{careerName}}</a>
 </ng-container>

 <ng-container *ngIf="!!relationshipUser">
  <div class="input-group mb-3">
    <input type="text" class="form-control" [ngModel]="user?.firstName + ' ' +  user?.lastName" aria-label="Recipient's username" aria-describedby="basic-addon2">
    <button class="input-group-text btn btn-light" id="basic-addon2" stopPropagation (click)="removeUser()"><i class="bi bi-trash"></i></button>
  </div>
   </ng-container>

   <ng-container *ngIf="!!isStatusReceive">
    <a stopPropagation > {{user?.firstName}} {{user?.lastName}}</a>
   </ng-container>

   <ng-container *ngIf="!!familyMember">
    <a stopPropagation > {{user?.firstName}} {{user?.lastName}}</a>
   </ng-container>
