<div class="container">
  <div class="body-container">
    <h1>New Skill</h1>
    <div class="content">
    <div class="array" *ngFor="let cat of category; let i = index">
      <ul >
        <button mat-dialog-close [disabled]="cat.disabled" (click)="addCategory(cat.id, {model: data.model})" type="button"><i class="fa-2x" [class]="cat.icon"></i></button>
      </ul>
      <h3> {{cat.description | translate}}</h3>
    </div>
    </div>
  </div>
</div>

