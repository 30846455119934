import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { GravatarModule } from "ngx-gravatar";
import { FilterPipe } from "../shared/pipes/filter.pipe";
import { CreateGroupComponent } from "./create-group/create-group.component";
import { GroupDetailsComponent } from "./group-details/group-details.component";
import { GroupListComponent } from "./group-list/group-list.component";
import { GroupRoutingModule } from "./group-routing.module";
import { GroupsSidebarComponent } from "./groups-sidebar/groups-sidebar.component";
import { GroupsComponent } from "./groups/groups.component";
import { SidebarGroupsDetailsComponent } from "./sidebar-groups-details/sidebar-groups-details.component";

@NgModule({
  declarations: [
    CreateGroupComponent,
    GroupDetailsComponent,
    GroupListComponent,
    GroupsComponent,
    GroupsSidebarComponent,
    SidebarGroupsDetailsComponent,
    FilterPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    TranslateModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    GravatarModule,
    GroupRoutingModule,
    TooltipModule,
  ],
  exports: [
    CreateGroupComponent,
    GroupDetailsComponent,
    GroupListComponent,
    GroupsComponent,
    GroupsSidebarComponent,
    SidebarGroupsDetailsComponent,
    FilterPipe

  ]
})

export class GroupModule {}
