import { Component, OnInit } from "@angular/core";
import {FriendService} from "../../shared/services/friend.service";
import {UserHelperService} from "../../shared/utils/user-helper.service";
import {AuthService} from "../../shared/services/auth.service";
import { Model } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-right-sidebar",
  templateUrl: "./right-sidebar.component.html",
  styleUrls: ["./right-sidebar.component.scss"]
})
export class RightSidebarComponent implements OnInit {
  friends: User[] = [];
  public data: Model;

  constructor(public friendService: FriendService,
              public userHelper: UserHelperService,
              public authService: AuthService,

  ) { }

  ngOnInit(): void {
    this.userHelper.loadRequestingFriends(this.authService.userID).subscribe(finalRequesters => {
      this.friends = finalRequesters;
    });
  }

}
