<div class="Line-line-container">
  <div class="Line-line-line">
      <div pageContent [ngClass]="{ 'isActive': selectedRow}"
           class="Line-line-container"
       [innerHtml]="space.description" (click)="setRow()">
      </div>
      <ng-container *ngIf="selectedRow">
        <div>
          <ul>
        <li class="bi bi-plus addIconBottom" (click)="addNewLine()"></li>
        <li class="bi bi-trash deleteIconRight" (click)="deleteRow(id)"></li>
        </ul>
        </div>
      </ng-container>
  </div>
