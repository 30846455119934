import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Categories, CategoryChildren, Page } from "src/app/shared/interfaces/model";
import { PagesService } from "src/app/shared/services/pages.service";
import _ from "lodash"
import { CategoryService } from "src/app/shared/services/category.service";
import { EditSubcategoryDialogComponent, EditSubCategoryPageDetails } from "../edit-subcategory-dialog/edit-subcategory-dialog.component";

@Component({
  selector: "app-edit-category-dialog",
  templateUrl: "./edit-category-dialog.component.html",
  styleUrls: ["./edit-category-dialog.component.scss"]
})
export class EditCategoryDialogComponent implements OnInit {
  page: Page;
  tagName = "";
  public category: Categories;
  selectedCategory = "";
  public clonedPage: Page;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditCategoryPageDetails,
  public categoryService: CategoryService,
  public dialog: MatDialog,
  private pageService: PagesService, private dialogRef: MatDialogRef<EditCategoryDialogComponent>,
  ) { this.dialogRef.disableClose = true }

  ngOnInit(): void {
    this.categoryService.getCategories();
      this.pageService.getPage(this.data.pageID).subscribe((res:Page) => {
        this.page = res;
        this.page = _.cloneDeep(this.page);
        this.clonedPage = _.cloneDeep(this.page);
      });
  }

  saveData() {
  if (this.clonedPage.categories.name !== this.page.categories.name) {
    let data: EditSubCategoryPageDetails = {
      edit: false,
      category: this.page.categories.name,
      pageID: this.page._id,
      disabledCloseButton: true,
      page: this.page,
      changedCat: true
    };
    this.dialog.open(EditSubcategoryDialogComponent, {data});
    this.pageService.updatePage(this.page._id, this.page);
  } else {
  }
  this.dialogRef.close(this.clonedPage);
  }
  showTags(event) {
    this.selectedCategory = event;
  }

}

export interface EditCategoryPageDetails {
  edit?: boolean;
  pageID?: string;
  index?: number;
  category?: Categories;
}
