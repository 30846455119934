import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
 import { MainHomeComponent } from "./main-home.component";
import { RouteUserBlockedGuard } from "../guards/route-user-blocked.guard";
import { AdsManagerComponent } from "../user-components/pages/ads-manager/ads-manager.component";
import { PaymentsComponent } from "../components/payments/payments.component";

const routes: Routes = [
    {
        path: '',
        canActivate: [RouteUserBlockedGuard],
        children: [
          { path: '', redirectTo: '/', pathMatch: 'full' },
          {
            path: '',
            component: MainHomeComponent
          },
          {path: ":id/adsmanager", component: PaymentsComponent },
          {path: ":id/adsmanager/create", component: AdsManagerComponent },
          {path: ":id/adsmanager/edit", component: AdsManagerComponent },

        ]

        }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })

  export class MainHomeRoutingModule{
  }
