import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Group } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GroupService } from 'src/app/shared/services/group.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  public group = new Group();
  public groups$: Observable<Group[]>;
  public groups: Group[];

  constructor(public router: Router,
    public groupService: GroupService,
    public authService: AuthService,
    public cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.groupService.getGroups();
    this.groups$ = this.groupService.groupModel();
    this.groups$.subscribe((data:any) => {
      this.groups = data;
      this.cd.detectChanges();
    });
  }


}
