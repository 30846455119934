import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { JobItemModule } from "../components/jobs/job-item/job-item.module";
import { JobItemDetailsModule } from "../job-item-details/job-item-details.module";
import { SavedProjectsComponent } from "./saved-projects.component";

@NgModule({
    declarations: [
        SavedProjectsComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        CommonModule,
        JobItemDetailsModule,
        JobItemModule
    ],

    exports: [
        SavedProjectsComponent
    ]
})

export class SavedProjectsModule {

}
