<div [attr.data-bs-theme]="theme">


<app-bottom-nav (emitThemeChange)="changeColors()"></app-bottom-nav>
    <!-- <div class="col-lg-3 left-sidebar" *ngIf="authService.isLoggedIn">
       <app-sidebar></app-sidebar>
      </div> -->

    <router-outlet></router-outlet>
  <!--  <app-friend-messages *ngIf="authService.isLoggedIn && !router.url.endsWith('/cvm')"></app-friend-messages>-->

    <app-primary-footer *ngIf="router.url === '/login' || router.url === '/register'"></app-primary-footer></div>
