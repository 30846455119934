import { Component, Input, OnInit } from "@angular/core";
import { UserService } from "../shared/services/user.service";
import { User } from "../shared/interfaces/user.model";


@Component({
  selector: 'app-message-name',
  templateUrl: './message-name.component.html',
  styleUrls: ['./message-right-side-job.component.scss']
})

export class MessageNameComponent implements OnInit {

  @Input() userId: string;

  user: User;

  constructor(private userService: UserService) {
    console.log(this.userId, "userid")

  }
  ngOnInit(): void {
    this.userService.getUserContactData(this.userId).subscribe(t => {
      this.user = t;
    })

  }

}
