import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { EditDetailsData, EditJobsDialogComponent } from '../components/jobs/edit-jobs-dialog/edit-jobs-dialog.component';
import { EmploymentType, Remote, Incomes, Duration, Status, StatusPrivacy } from '../shared/enums/enums-model';
import { JIssue, IssueStatus, IssueType, IssuePriority } from '../shared/interfaces/jira.model';
import { Job, Page, Project } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { BranchesService } from '../shared/services/branches.service';
import { CategoryService } from '../shared/services/category.service';
import { ChangeDetectionService } from '../shared/services/change-detection.service';
import { CountryDataService } from '../shared/services/country-data.service';
import { PagesService } from '../shared/services/pages.service';
import { ProjectService } from '../shared/services/project.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';
import { HttpClient } from '@angular/common/http';
import { ShareDialogComponent } from '../components/modals/share-dialog/share-dialog.component';
import { Observable } from 'rxjs';
import { WarningDialogComponent } from '../sharedComponents/warning-dialog/warning-dialog.component';
import { ConfirmationDialogComponent } from '../components/modals/confirmation-dialog/confirmation-dialog.component';
import { ArraysService } from '../shared/services/arrays-service/title-service';
import { PrivacyDialogComponent } from '../components/privacy-dialog/privacy-dialog.component';

@Component({
  selector: 'app-job-item-details',
  templateUrl: './job-item-details.component.html',
  styleUrls: ['./job-item-details.component.scss']
})
export class JobItemDetailsComponent implements OnInit, OnChanges {

  @Input() positionTitle: string;
  @Input() name: string;
  @Input() country;
  @Input() data: any;
  @Input() buttons: boolean;
  @Input() more: boolean;
  @Input() createdDate: boolean;
  @Input() search: string;
  @Input() public: string;
  @Input() employmentTypeInput: boolean;
  @Input() project: boolean;
  @Input() isProjectSearch: boolean = false;
  @Input() isJob: boolean = false;
  @Input() isProject: boolean = false;
  @Input() isSavedProject: boolean = false;
  @Input() projectID: string
  @Input() isProjectSearchCompany: boolean = false;
  @Input() isPageApplyProject: boolean = false;
  @Input() projectData: Project;
  @Input() isIgnoredProject: boolean = false;
  @Input() selectedPage: string;
  @Input() isPage: boolean = false;
  @Input() userData: User;
  @Input() isSearchUser: boolean = false;
  @Input() projectSearch: Project;
  @Input() isSearchPage: boolean = false;
  @Input() pageData: Page;
  @Input() pageObject: Page;
  @Input() hasUserProjects: boolean = false;
  @Input() isUser;
  @Input() isUserSelected;
  @Output() unsaveProject: EventEmitter<any> = new EventEmitter<any>();
  @Output() unsaveProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() unignoreProject: EventEmitter<any> = new EventEmitter<any>();

  @Output() ignoreProjectData: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromIgnoreProjectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() showOnlySelected: EventEmitter<any> = new EventEmitter<any>();

  cancelProject;
  cancelMessage;

  description
  selectedProject: boolean = false;

  showDateApplied;
  showDateAppliedPage;

  messageSelected: boolean = false;
  userDataMore: any;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;
  public privacyArray = [
    { id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone' },
    { id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts' },
    { id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text: 'Your Followers' },
    { id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '' }
  ]

  public remote;
  public employmentTypeString;
  public dataDurationString;
  dataDurationStringForApply;
  public currencyObject;
  public currencyUserSearchObject;
  public paymentIDForUser;
  public paymentID;
  public paymentIDForIncomes;
  public countryJsonObject;
  public countryJsonObjectForUser;
  public countryJsonArray;
  public user: User;
  public openMoreInfo = false;
  userCreatedJob: User;
  page: Page;
  selectedPageData: Page;
  public moreJobInfo;
  public isSaved;
  public isSavedProjectPage;
  public isAppliedProjectPage;
  public isIgnoredProjectPage;
  public isIgnored;
  public hasApplied;
  public userApplied: User;
  userForPage: any;


  public pageRemoveProject: Page;

  userRemote;

  contactUserForm;
  contactPageForm;
  public config = {
    toolbar: false
  };
  editorConfig = {
    padding: "0",
  }
  contactMessage = "";

  writeMessage = "";
  selectedFirstGroup: boolean;
  selectedSecondGroup: boolean;
  selectedQualifications: boolean;
  selectedDriveLicense: boolean;
  selectedLanguage: boolean;
  selectedPCKnowledge: boolean;
  selectedTasks: boolean;
  selectedSkillsOffer: boolean;
  selectedDescription: boolean;

  applyForm: boolean = false;
  public authUser: User;


  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];


  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]

  public dataDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]
  branchTitle;
  categoryTitle;
  subCategoryTitle;
  remoteForApply;
  languages = [];
  checkUserInPage: boolean = false;
  loggedUser$: Observable<any>;
  loggedUser: any;
  public userHasWritten: User;
  public dataList$: Observable<any>;
  public icon;
  public text;
  public iconOffer;
  public textOffer;


  salaryStatus;
  constructor(private countryDataService: CountryDataService,
    private senderService: SenderService,
    private userService: UserService,
    private router: Router,
    private pageService: PagesService,
    private projectService: ProjectService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private cdServ: ChangeDetectionService,
    private branchesService: BranchesService,
    private categoryService: CategoryService,
    private dialog: MatDialog,
    public http: HttpClient,
    public sendData: SenderService,
    private titleService: ArraysService

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.selectedPage) {
      this.pageService.getPage(this.selectedPage).subscribe(res => {
        this.selectedPageData = res.page;
        this.savedIDPage = this.data?._id;
        this.hasAppliedPage = this.selectedPageData?._id;

      })
    }

    if (!!this.isPage) {

      if (!!this.selectedPage) {
        this.pageService.getPage(this.selectedPage).subscribe(res => {
          this.selectedPageData = res.page;

          this.savedIDPage = this.data?._id
          this.hasAppliedPage = this.selectedPageData?._id;
          console.log(this.selectedPage, "eejej")

        })
      }

    }

    this.userService.getUserContactData(this.authService.userID).subscribe(tt => {
      this.userHasWritten = tt;
    })

    this.userService.getUserEM(this.authService.userID).subscribe(t => {
      this.userForPage = t.user;
    });


    if (this.data?.pageID) {

      this.pageService.getPage(this.data?.pageID).subscribe(t => {
        this.page = t.page;
        this.checkUserInPage = t.roles.Messages.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID;

      })
    } else if (!this.data?.pageID) {

      if (!!this.data?.userID) {
        this.userService.getUserContactData(this.data?.userID).subscribe(t => {
          this.userCreatedJob = t;
        })
      }

    }


    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.titleService.getOfferArrayProject();
    this.titleService.getPrivacyArray();


    if (!this.isPage) {
      localStorage.setItem("slcPageProjectID", "");
    }
    const selectedPageID = localStorage.getItem("slcPageProjectID");



    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.authUser = res;
      if (!!this.authUser) {

        this.http.get<any>(`assets/languages/${this.authUser?.language.primary}/language.json`).subscribe(data => {

          this.languages = data;
          this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));

        })
      }
    })
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);



    this.categoryService.getCategory()
    this.categoryService.getCategories();
    this.branchesService.getBranches();

    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
      this.user = res.user;
      this.getPaymentFormIDForIncomes(res.userUser?.incomes?.s);
      this.incomes(res.userUser?.incomes.s, this.data)
      this.appliedID = res.user?._id;
      this.savedID = res.user?._id;
      this.loggedUser = res;
      this.getCurrencyForUserSearch(this.user.address.data[0]?.c);

      this.privacyIcon = this.data;
      this.privacyText = this.data;
      this.privacyTextOffer = this.data;
      this.privacyIconOffer = this.data;

    })




    if (selectedPageID) {
      this.pageService.getPage(selectedPageID).subscribe(res => {
        this.selectedPageData = res.page;
        this.savedIDPage = this.data?._id;
        this.hasAppliedPage = this.selectedPageData?._id;

      })
    }

    if (!!this.isPage) {

      if (!!selectedPageID) {
        this.pageService.getPage(selectedPageID).subscribe(res => {
          this.selectedPageData = res.page;

          this.savedIDPage = this.data?._id
          this.hasAppliedPage = this.selectedPageData?._id;

        })
      }

    }

    this.userService.getUserContactData(this.authService.userID).subscribe(tt => {
      this.userHasWritten = tt;
    })


    if (this.data?.pageID) {

      this.pageService.getPage(this.data?.pageID).subscribe(t => {
        this.page = t.page;
        this.checkUserInPage = t.roles.Messages.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID;

      })
    } else if (!this.data?.pageID) {

      if (!!this.data?.userID) {
        this.userService.getUserContactData(this.data?.userID).subscribe(t => {
          this.userCreatedJob = t;
        })
      }

    }



    if (this.isSavedProject) {
    }
    if (!!this.projectData) {

      this.getJobDurationIDForApply();
      this.getRemoteIDForApply();
      this.privacyIcon = this.data;
      this.privacyText = this.data;
      this.privacyTextOffer = this.data;
      this.privacyIconOffer = this.data;

      this.getCountryID(this.projectData.c);

      this.getCurrencyID(this.projectData.currency);
      let id = this.projectData?.manage_?.find(t => t.message.userID)?.message.userID;
      if (id) {
        this.userService.getUserContactData(id).subscribe(tt => {
          this.userApplied = tt;
        })
      }

      if (this.projectData?.pageID) {

        this.pageService.getPage(this.projectData?.pageID).subscribe(t => {

          this.page = t.page;
        })
      } else if (!this.projectData?.pageID) {
        this.userService.getUserContactData(this.projectData?.userID).subscribe(t => {
          this.userCreatedJob = t;
        })
      }
    }



    this.getRemoteID()
    this.getEmploymentTypeID();
    this.getJoDurationID();
    this.getCountryID(this.data?.c);
    this.getCurrencyID(this.data?.currency);
    console.log(this.data, "this.data")
    this.getPaymentFormID(this.data?.incomes.s);


    if (this.isSearchUser) {
      this.userService.getUserCV(this.userData._id).subscribe();
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        //   this.privacyIcon = this.loggedUser;
        //  this.privacyText = this.loggedUser;

        this.userDataMore = res;

      });


      this.getCurrencyForUserSearch(this.userData.address.data[0]?.c);
      this.getUserRemoteID(this.userData?.searchLocation.remote);
      this.getCountryOfUser(this.userData.address.data[0]?.c);
      this.getPaymentFormIDForUser(this.projectSearch.incomes.s);
    }
    if (this.isSearchPage) {
      this.getUserRemoteID(this.pageData.searchLocation.remote);
      this.getPaymentFormIDForUser(this.projectSearch.incomes.s);
      this.getBranch(this.pageData?.branches);
      this.getCategory(this.pageData?.categories.name);
      this.getSubCategoryTitle(this.pageData?.categories.name);
    }

    if (this.isPageApplyProject) {
      this.getPaymentFormID(this.projectData?.incomes.s);
      this.getCurrencyID(this.projectData?.currency);
      this.getCountryID(this.projectData?.c);



    }





    this.cd.detectChanges();

  }

  getBranch(id) {
    this.branchTitle = this.branchesService.branches.find(t => t.id === id).description;
  }
  getCategory(id) {
    this.categoryTitle = this.categoryService.categories.find(t => t.id === id);
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.pageData.categories.subCategory.find(t => t.name);
    this.subCategoryTitle = t.subCategory.find(rtt => rtt.id === tt.name).name;
  }

  searchProject(id) {
    localStorage.setItem("searchProjectID", id);
    localStorage.setItem("jobUrl", this.router.url + `/${id}`);
    this.router.navigateByUrl(this.user.userUrl + "/projects/" + id + "/search");
  }




  getRemoteID() {
    this.remote = this.remoteArray.find(test => test.id === this.data?.remote)?.description;
  }
  getUserRemoteID(id) {
    this.userRemote = this.remoteArray.find(test => test.id === id)?.description;
  }
  getEmploymentTypeID() {
    this.employmentTypeString = this.employmentType.find(t => t.id === this.data?.employmentType)?.description;
  }
  getJoDurationID() {
    this.dataDurationString = this.dataDurationArray.find(t => t.id === this.data?.duration)?.description;
  }

  getJobDurationIDForApply() {
    this.dataDurationStringForApply = this.dataDurationArray.find(t => t.id === this.projectData?.duration)?.description;
  }
  getRemoteIDForApply() {
    this.remoteForApply = this.remoteArray.find(test => test.id === this.projectData?.remote)?.description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }

  countryJsonPage(id) {
    return this.countryJsonArray?.find(t => t.ISO_2 === id);
  }

  getCountryOfUser(id) {
    this.countryJsonObjectForUser = this.countryJsonArray.find(t => t.ISO_2 === id)
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getCurrencyForUserSearch(id) {
    this.currencyUserSearchObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }
  getPaymentFormIDForUser(id) {
    console.log(id, "iddddd")
    this.paymentIDForUser = this.salaryArray.find(t => t.id === id)?.description;
  }

  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id)?.description;
  }

  public incomes(id, job) {
    if (id === Incomes.month) {
      this.salaryStatus = job?.incomes?.month.toFixed(0);
    } else if (id === Incomes.year) {
      this.salaryStatus = job?.incomes?.year.toFixed(0);
    } else if (id === Incomes.hour) {
      this.salaryStatus = Math.trunc(job?.incomes?.hour)
    }
  }

  getPaymentFormIDForIncomes(id) {
    this.paymentIDForIncomes = this.salaryArray.find(t => t.id === id)?.description;
  }


  emmitID() {
    if (this.isProjectSearch) {
      this.senderService.dataJobID(this.data._id);
      localStorage.setItem('projectID', this.data._id);
      localStorage.removeItem('jobID');
      this.router.navigate(["/projects/public", this.data._id]);
    }
    if (this.project && !this.isProjectSearch) {
      this.senderService.dataJobID(this.data._id);
      localStorage.setItem('projectID', this.data._id);
      this.router.navigate([this.user?.userUrl + "/projects/", this.data._id]);
    } else if (!this.project && !this.isProjectSearch) {
      this.senderService.dataJobID(this.data._id);
      localStorage.setItem('jobID', this.data._id);
      localStorage.removeItem('projectID');
      this.router.navigate([this.user?.userUrl + "/jobs/", this.data._id]);
    }
  }
  manageJob() {
    this.senderService.dataManageJobID(this.data._id);
    this.router.navigateByUrl(this.router.url + "/" + this.data._id + "/manage");
  }

  manageProject() {
    this.senderService.dataManageProjectID({ ID: this.data._id, isProject: true });
    this.showOnlySelected.emit();
    this.selectedProject = true;
  }
  closeManageProject() {
    this.selectedProject = false;

  }

  publicJob() {

    this.userService.searchForJobsAndProjects(this.user._id).subscribe(t => {
      if (t.hasPublished === true) {
        alert("You need to pay")
      } else {
        this.router.navigate([this.router.url + '/' + this.data._id + "/public", { my_object: JSON.stringify(this.user) }]);

      }
    });
  }
  publicProject() {
    localStorage.setItem('projectID', this.data._id);
    this.senderService.send({ data: this.authUser, id: "user" })
    this.router.navigate([this.router.url + '/' + this.data._id + "/public"]);
  }
  searchJob() {
    localStorage.setItem("searchJobId", this.data._id);
    localStorage.setItem("jobUrl", this.router.url + `/${this.data._id}`);
    this.router.navigateByUrl(this.router.url + `/${this.data._id}` + "/search");
  }

  moreInfo(jobID) {
    this.openMoreInfo = true;
    console.log(jobID, "job")
    this.projectService.getProject(jobID).subscribe((job) => {
      this.moreJobInfo = job;
    });

  }
  lessInfo() {
    this.openMoreInfo = false;
  }

  saveData(ID: string) {
    this.user.savedProjects.unshift({ projectId: ID, timestamp: new Date() });
    this.userService.updateUser(this.user._id, this.user);
    this.savedID;
  }

  saveProjectPage(id: string) {
    this.selectedPageData.savedProjects.unshift({ projectId: id, timestamp: new Date() });
    this.pageService.updatePage(this.selectedPageData._id, this.selectedPageData)
    this.savedIDPage = id;
  }
  ignoreProjectPage(id: string) {
    // this.ignoreProjectData.emit(id)
    this.selectedPageData.ignoredProjects.unshift({ projectId: id, timestamp: new Date() })
    this.pageService.updatePage(this.selectedPageData._id, this.selectedPageData);

  }

  unsaveProjectpage(index) {
    this.selectedPageData.savedProjects.splice(index, 1);
  }

  ignoreProject(id: string) {
    console.log(this.data, "project")
  }

  goToUserApplied() {
    this.router.navigateByUrl(this.user.userUrl + "/projects/applied")
  }



  applyForProject(ID: string) {
    if (this.data?.receivedApplicationBy?.selectedApplication === 1) {
      window.open(this.data?.receivedApplicationBy?.externalLink, '_blank');
    } else {
      let jIssue: JIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.authService.userID,
          description: this.description,
        },
        seen: [{
          userID: this.authService.userID,
          timestamp: new Date()
        }],
        userID: this.authService.userID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }

      this.data.manage_.unshift(jIssue);

      console.log(this.loggedUser.user._id, "loggedUser")
      //  this.user.messages.unshift({ projectId: this.data._id, timestamp: new Date() });
      this.userService.getMessageList(this.loggedUser.user._id).subscribe(response => {
        response[0].messages.unshift({ projectID: this.data._id, c: this.data.c })

        this.userService.updateMessageList(response[0]._id, response[0]);
      })
      this.projectService.updateProject(this.data._id, this.data);
      //  this.user.appliedProjects.unshift({ projectId: this.data._id, timestamp: new Date() });
      //  this.userService.updateUser(this.user._id, this.user);
      // this.projectService.updateProject(this.data._id, this.data);

      this.applyForm = false;
      this.appliedID = ID;

    }
  }

  goToPage() {
    localStorage.setItem("pageID", this.pageData._id);
    window.open(this.pageData.pageUrl + "/about", "_blank")
  }

  unsaveData(ID) {
    this.unsaveProject.emit(ID)
  }
  unsaveProjectData(id) {
    this.unsaveProjectPage.emit(id);
    let index = this.selectedPageData.savedProjects.findIndex(t => t.projectId === id);
    this.selectedPageData.savedProjects.splice(index, 1)
    this.savedIDPage = id;
  }

  removeFromIgnored(ID) {
    this.unignoreProject.emit(ID);
  }

  removeFromIngoredPageProject(ID) {
    this.removeFromIgnoreProjectPage.emit(ID)
  }

  removeProjectFromPublic(project) {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });

    this.confirmationDialog.componentInstance.message = `Are u sure to cancel publication for ${ project?.positionTitle} ?`;


    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        project.public = {};
        this.userService.getProjectList(this.authService.userID).subscribe(res => {
          const projectData = res[0].projects.find(r => r.projectID === project._id);
          projectData.public = project.public
          this.projectService.updateProjectList(res[0]._id, res[0]);
        })

         this.projectService.updateProject(project._id, project);

      }
      console.log(result, "result")

    });



   // this.projectService.updateProject(project._id, project);
    // this.userService.updateUser(this.user._id, this.user);
  }

  applyForms() {
    this.applyForm = true;
  }
  cancelApplyForm() {
    this.applyForm = false;
  }


  applyForPageProject(ID) {
    const userPageID = localStorage.getItem("userPageID");

    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        pageID: userPageID,
        description: this.description,
      },
      seen: [{
        userID: this.authService.userID,
        pageID: userPageID,
        timestamp: new Date()
      }],
      userID: this.authService.userID,
      pageID: userPageID,
      createdAt: new Date(),
      id: Math.floor(Math.random() * 9).toString() + 1,
      cancel: {
        status: Status.Accepted,
        timestamp: new Date()
      },
    }
    this.data.manage_.unshift(jIssue);

    this.pageService.getMessagePageList(userPageID).subscribe(response => {
      response[0].messages.unshift({ projectID: this.data._id, c: this.data.c, pageID: userPageID, userID: this.authService.userID })
      this.pageService.updateMessagePageList(response[0]._id, response[0]);
      this.projectService.updateProject(this.data._id, this.data);
      this.applyForm = false;
      this.hasAppliedPage = userPageID;

    })


    //  localStorage.setItem('projectID', ID);
    //this.router.navigateByUrl("projects/public/" + ID + "/apply");
  }
  goToAppliedPageProjects() {
    this.router.navigateByUrl(this.selectedPageData.pageUrl + "/projects/applied_projects")

  }


  set savedID(id) {
    this.isSaved = this.data?.savedProjects?.find(t => t.projectId === id);
  }

  get savedID() {
    return this.isSaved;
  }


  set savedIDPage(id) {
    this.isSavedProjectPage = this.data?.savedProjects?.find(t => t.projectId === id);
  }

  get savedIDPage() {
    return this.isSavedProjectPage;
  }

  set ignoredIDPage(id) {
    this.isIgnoredProjectPage = this.selectedPageData?.ignoredProjects?.find(t => t.projectId === id);
  }
  get ignoredIDPage() {
    return this.isIgnoredProjectPage;
  }

  set hasAppliedPage(id) {
    this.isAppliedProjectPage = this.data?.manage_?.find(t => t.pageID === id)?.pageID === this.selectedPageData?._id;
    this.showDateAppliedPage = this.data.manage_.find(t => t.pageID === id)?.createdAt;
  }

  get appliedIDPage() {
    return this.isAppliedProjectPage;
  }


  set appliedID(id: string) {
    console.log(this.selectedPageData, "ejjeej")
    this.hasApplied = this.data?.manage_?.find(t => t.userID === id && !t.pageID)?.userID === this.user?._id;
    this.showDateApplied = this.data?.manage_?.find(t => t.userID === id && !t.pageID)?.createdAt;
  }

  get appliedID() {
    return this.hasApplied;
  }


  public get isMyJob() {
    return this.data?.userID === this.authService.userID;
  }
  public get isMyProject() {
    return this.projectData?.userID === this.authService.userID;
  }
  public get isUserProject() {
    return this.data?.userID === this.authService.userID;

  }

  public get isContactedUser() {
    return this.projectSearch.manage_.find(t => t.userID === this.userData._id);
  }

  public get isContactedPage() {
    return this.projectSearch.manage_.find(t => t.pageID === this.pageData._id);
  }


  projectInfo() {
    this.userService.getUserCV(this.userData._id).subscribe();
    this.openMoreInfo = true;
  }

  contactUser() {
    this.contactUserForm = true;
  }
  cancelContact() {
    this.contactUserForm = false;
  }

  contactPage() {
    this.contactPageForm = true;
  }


  cancelPageContact() {
    this.contactPageForm = false;
  }

  goToManage() {
    this.senderService.dataManageProjectID({ ID: this.projectSearch._id, isProject: true });
    this.router.navigateByUrl("../projects/" + this.projectSearch._id + "/manage");

  }

  sendUserContactForm() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        description: this.contactMessage,
      },
      createdAt: new Date(),
      userID: this.userData._id,
      cancel: {
        status: Status.Pending,
        timestamp: new Date(),
      },
      id: Math.floor(Math.random() * 9).toString() + 1,
    }


    this.userData.messages.unshift({ projectID: this.projectSearch._id, timestamp: new Date });
    this.projectSearch.manage_.unshift(jIssue);
    this.userService.updateUser(this.userData._id, this.userData);
    this.projectService.updateProject(this.projectSearch._id, this.projectSearch)

    this.contactUserForm = false;
  }

  sendPageContactForm() {
    let jIssue: JIssue = {
      status: IssueStatus.Applied,
      message: {
        userID: this.authService.userID,
        pageID: this.pageData._id,
        description: this.contactMessage,
      },
      createdAt: new Date(),
      userID: this.authService.userID,
      pageID: this.pageData._id,
      cancel: {
        status: Status.Pending,
        timestamp: new Date(),
      },
      id: Math.floor(Math.random() * 9).toString() + 1,
    }

    this.pageData.messages.unshift({ projectId: this.projectSearch._id, timestamp: new Date() })
    this.projectSearch.manage_.unshift(jIssue);

    this.pageService.updatePage(this.pageData._id, this.pageData)
    this.projectService.updateProject(this.projectSearch._id, this.projectSearch)

    this.contactPageForm = false;
  }
  message() {
    console.log("ejejejej")
    this.messageSelected = !this.messageSelected;
  }

  cancel() {
    this.cancelProject = true;
  }

  deleteProject() {
    if (this.isUser) {
      const index = this.user.appliedProjects.map(e => e.projectId).indexOf(this.projectData._id);
      this.user.appliedProjects.splice(index, 1);
      this.userService.updateUser(this.user._id, this.user);
    } else {
      const index = this.pageObject.appliedProjects.map(e => e.projectId).indexOf(this.projectData._id);
      this.pageObject.appliedProjects.splice(index, 1);
      this.pageService.updatePage(this.pageObject._id, this.pageObject);
    }


  }
  deleteFromSaved() {
    const index = this.pageObject.savedProjects.map(e => e.projectId).indexOf(this.data._id);
    this.pageObject.savedProjects.splice(index, 1);
    this.pageService.updatePage(this.pageObject._id, this.pageObject);

  }


  cancelProjectButton() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      cancelID: this.authService.userID
    }
    this.projectData.manage_.find(t => t.userID === this.authService.userID).cancel = dataForCancel;
    // this.projectService.updateProject(this.projectSearch._id, this.projectSearch);
    this.cancelProject = false;

    const pageID = localStorage.getItem("pageID");
    if (!!pageID) {
      this.pageService.getPage(pageID).pipe(take(1)).subscribe(res => {
        this.pageRemoveProject = res;
        let index = this.pageRemoveProject.appliedProjects.findIndex(t => t.projectId === this.projectData._id);
        this.pageRemoveProject.appliedProjects.splice(index, 1);
        this.pageService.updatePage(this.pageRemoveProject._id, this.pageRemoveProject);
        this.senderService.send(this.pageRemoveProject);

      })
    }
    this.cdServ.emitChanges(true);
  }

  cancelProjectUser() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      cancelID: this.authService.userID
    }

    this.projectData.manage_.find(t => t.userID === this.authService.userID).cancel = dataForCancel;
    // this.projectService.updateProject(this.projectSearch._id, this.projectSearch);
    this.cancelProject = false;
    this.userService.getMessageList(this.authService.userID).subscribe(res => {
      const projectRemove = res[0];
      let index = projectRemove.messages.findIndex(t => t.projectID === this.projectData._id);
      projectRemove.messages.splice(index, 1);
      this.userService.updateMessageList(projectRemove._id, projectRemove);
      this.projectService.updateProject(this.projectData._id, this.projectData);

    })

  }

  selectFirstGroup() {
    this.selectedFirstGroup = true;
  }
  selectSecondGroup() {
    this.selectedSecondGroup = true;
  }
  selectTasks() {
    this.selectedTasks = true;
  }
  selectPCKnowledge() {
    this.selectedPCKnowledge = true;
  }
  selectSkillsOffer() {
    this.selectedSkillsOffer = true;
  }
  selectQualifications() {
    this.selectedQualifications = true;
  }
  selectDriveLicense() {
    this.selectedDriveLicense = true;
  }
  selectLanguage() {
    this.selectedLanguage = true;
  }
  selectDescription() {
    this.selectedDescription = true;
  }
  deleteProjectFreelancer() {
    this.data.freelancer = false;
    this.projectService.updateProject(this.data._id, this.data)
  }

  deleteProjectTasks() {
    this.data.company = false;
    this.projectService.updateProject(this.data._id, this.data)
  }
  addFreelancer() {
    this.data.freelancer = true;
    this.projectService.updateProject(this.data._id, this.data)
  }

  addCompany() {
    this.data.company = true;
    this.projectService.updateProject(this.data._id, this.data)
  }

  editFirstGroup(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {

          this.data.positionTitle = res.positionTitle;
          this.data.employmentType = res.employmentType;
          this.data.remote = res.remote;
          this.data.street = res.street;
          this.data.postalCode = res.postalCode;
          this.data.city = res.city;
          this.data.country = res.c;
          this.data.state = res.state;
          this.getCountryID(this.data.c);
          this.projectService.updateProject(this.data._id, this.data)
        }
      });
  }

  editSkillsOffer(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.skillsOffer = res.skillsOffer;
          this.projectService.updateProject(this.data._id, this.data);
        }
      });
  }



  editSecondGroup(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.data.incomes.budget = res.job.incomes.budget;
          this.data.incomes.status = res.job.incomes.s;
          this.data.currency = res.job.currency;
          if (res.duration === Duration.unlimited) {
            this.data.duration = Duration.unlimited;
            this.data.startDate = res.job.startDate;
          } else if (res.duration === Duration.temporary) {
            this.data.duration = Duration.temporary;
            this.data.startDate = res.job.startDate;
          }
          this.data.currency = res.currency;
          this.getCurrencyID(this.data.currency);
          this.projectService.updateProject(this.data._id, this.data);

          //this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  editJobDescription(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.description = res.description;
          this.projectService.updateProject(this.data._id, this.data);
          // this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }
  editJobTasks(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.tasks = res.tasks;
          this.projectService.updateProject(this.data._id, this.data);

          //  this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  editPCKnowledge(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.pcKnowledge = res.pcKnowledge;
          this.projectService.updateProject(this.data._id, this.data);
          // this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  editJobLanguage(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.language = res.language;
          this.projectService.updateProject(this.data._id, this.data);
          // this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  editJobDriveLicense(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.driveLicense = res.driveLicense;
          this.projectService.updateProject(this.data._id, this.data);
          //   this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  editQualifications(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.data.qualifications = res.qualifications;
          this.projectService.updateProject(this.data._id, this.data);

          //   this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }

  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }


  openShare(data) {
    this.dialog.open(ShareDialogComponent, {
      data
    });
  }



  removeClick() {
    this.selectedQualifications = false;
    this.selectedDriveLicense = false;
    this.selectedLanguage = false;
    this.selectedPCKnowledge = false;
    this.selectedTasks = false;
    this.selectedSecondGroup = false;
    this.selectedFirstGroup = false;
    this.selectedSkillsOffer = false;
    this.selectedDescription = false;
  }

  preventDefault(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

  }

  goToUser() {
    this.router.navigate([this.userCreatedJob.userUrl, "start"]);
    localStorage.setItem("userProfileID", this.userCreatedJob._id);
  }

  goToPageW() {
    localStorage.setItem("pageID", this.page._id)
    this.pageService.changeMessage(this.page._id);
    this.sendData.send(this.page);
    this.router.navigate([this.page.pageUrl]);

  }


  sendMessage(jobManage) {

    const findIndex = this.user?.messages?.findIndex(t => t?.projectID === this.projectData._id);

    if (!!findIndex) {
      this.arraymove(this.user.messages, findIndex, 0);
      this.userService.updateUser(this.user._id, this.user)

    }

    const findPageIndex = this.pageObject?.messages?.findIndex(t => t?.projectId === this.projectData._id);

    if (!!findPageIndex) {
      this.arraymove(this.pageObject.messages, findPageIndex, 0);
      this.pageService.updatePage(this.pageObject._id, this.pageObject)
    }




    this.projectData.manage_[0].comments.unshift({
      createdAt: new Date().toISOString(),
      body: this.writeMessage,
      userID: this.authService.userID
    })

    this.projectService.updateProject(this.projectData._id, this.projectData);
    this.writeMessage = "";
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public set privacyIcon(project: any | Project | Job) {
    if (project) {
      this.icon = this.privacyArray.find(t => t.id === project.privacy.s).icon;
    }
  }

  public get privacyIcon() {
    return this.icon;
  }

  public set privacyText(project: any | Project | Job) {
    if (project) {
      this.text = this.privacyArray.find(t => t.id === project.privacy.s).description;

    }
  }

  public get privacyText() {
    return this.text;
  }

  public set privacyTextOffer(project: Project | Job | any) {
    if (project) {
      this.textOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).text;

    }
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(project: Project | Job | any) {
    if (project) {
      this.iconOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).icon;

    }
  }

  public get privacyIconOffer() {
    console.log(this.iconOffer, "idon")
    return this.iconOffer;
  }

  openPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.data._id) {
          this.data.privacy.s = t;
          this.privacyIcon = this.data;
          this.privacyText = this.data;
          this.data.privacy.timestamp = new Date();
          this.projectService.updateProject(this.data._id, this.data);

        }
      }
    })
  }

  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.data._id) {
          this.data.apply.s = t;
          this.privacyIconOffer = this.data;
          this.privacyTextOffer = this.data;
          this.data.apply.timestamp = new Date();
          this.projectService.updateProject(this.data._id, this.data);

        }
      }
    })
  }

  goToData(data: any) {

    this.router.navigateByUrl(this.router.url + "/" + this.data._id + "/manage");
  }

}
