import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "../../shared/services/auth.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Model } from "src/app/shared/interfaces/model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  public errorMessage = "";
  public email = "";
  public password = "";
  public loading = false;
  public model: Model;
  public model$: Observable<Model>;


  constructor(
    public fb: UntypedFormBuilder,
    public authService: AuthService,
    public router: Router,
    public translate: TranslateService,
  ) {

    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([]);
    }
  }

  loginUser() {
    this.errorMessage = "";
    this.loading = true;
    this.email = this.loginForm.controls.email.value.toLowerCase();

    this.authService.signIn(this.email, this.password)
        .then(res => {
          localStorage.setItem("access_token", res.token);
          localStorage.setItem("userProfileID", this.authService.userID);
          this.loading = false;
            this.router.navigate([""], {replaceUrl: true});
          this.loginForm.reset();
        })
        .catch((error: HttpErrorResponse) => {
          this.loading = false;
          switch (error.status) {
            case 404:
              this.errorMessage = "Invalid Login";
              break;
            case 401:
              this.errorMessage = "Invalid password";
              break;
            default:
              this.errorMessage = "Login failed: " + error.message;
          }
        });
  }
  register() {
    this.router.navigateByUrl("/register");
  }

}
