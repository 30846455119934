import { Component, Input, OnInit } from '@angular/core';
import { StatusPrivacy } from 'src/app/shared/enums/enums-model';
import { Job, Project } from 'src/app/shared/interfaces/model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { PrivacyDialogComponent } from '../../privacy-dialog/privacy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { ProjectService } from 'src/app/shared/services/project.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {


  public icon;
  public text;
  public iconOffer;
  public textOffer;

  public privacyArray = [
    { id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone' },
    { id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts' },
    { id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text: 'Your Followers' },
    { id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '' }
  ]

  @Input() data: any;
  @Input() dataArrJobs: any;
  @Input() isJob: boolean = false;
  @Input() isProject: boolean = false;




  constructor(private titleService: ArraysService,
    private dialog: MatDialog,
    private jobService: JobsService,
    private projectService: ProjectService
  ) {
    this.titleService.getOfferArrayProject();
    this.titleService.getPrivacyArray();

  }
  ngOnInit(): void {
    this.privacyIcon = this.data;
    this.privacyText = this.data;
    this.privacyTextOffer = this.data;
    this.privacyIconOffer = this.data;

  }





  openPrivacy(obj) {
     this.dialog.open(PrivacyDialogComponent, {
      data: obj
     }).afterClosed().subscribe(t => {
       if (!!t) {
        if (t.isJob) {
          this.data.privacy.s = t.enums;
          this.privacyIcon = this.data;
          this.privacyText = this.data;
          this.data.privacy.timestamp = new Date();
          this.jobService.updateJob(this.data._id, this.data);
        } else {
          this.data.privacy.s = t.enums;
          this.privacyIcon = this.data;
          this.privacyText = this.data;
          this.data.privacy.timestamp = new Date();
          this.projectService.updateProject(this.data._id, this.data);

        }

       }
     })
  }

  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
        data: data
      }).afterClosed().subscribe(res => {
        if (res !== undefined) {
          if (res.isJob) {
            this.data.apply.s = res.enums;
            this.privacyIconOffer = this.data;
            this.privacyTextOffer = this.data;
            this.data.apply.timestamp = new Date();
         //   this.jobService.updateJob(this.data._id, this.data);

          } else {
            this.data.apply.s = res.enums;
            this.privacyIconOffer = this.data;
            this.privacyTextOffer = this.data;
            this.data.apply.timestamp = new Date();
        //    this.projectService.updateProject(this.data._id, this.data);
          }
        } else {
          return;
        }
      })
  }

  public set privacyIcon(project: any | Project | Job) {
    if (project) {
      this.icon = this.privacyArray.find(t => t.id === project.privacy.s).icon;
    }
  }

  public get privacyIcon() {
    return this.icon;
  }

  public set privacyText(project: any | Project | Job) {
    this.text = this.privacyArray.find(t => t.id === project.privacy.s).description;
  }

  public get privacyText() {
    return this.text;
  }

  public set privacyTextOffer(project: Project | Job | any) {
    this.textOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).text;
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(project: Project | Job | any) {
    this.iconOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).icon;
  }

  public get privacyIconOffer() {
    return this.iconOffer;
  }

}
