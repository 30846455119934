<div class="container">
  <share-buttons [theme]="'modern-light'"
  [include]="['facebook','twitter','linkedin','reddit','viber','telegram','messenger','whatsapp','xing','sms','email','print','copy']"
  [show]="13"
  [url]="url"
  [title]="data.data.positionTitle"
  [tags]="tags"
  [autoSetMeta]="false"
  ></share-buttons>

</div>
