import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component, DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {io, Socket} from "socket.io-client";
import {Messages} from "../../../../shared/interfaces/message";
import {environment} from "../../../../../assets/environments/environment";
import {MessageHelperService} from "../../../../shared/utils/message-helper.service";
import {AuthService} from "../../../../shared/services/auth.service";
import {Model, Image, UserContact} from "../../../../shared/interfaces/model";
import { ImageService } from "src/app/shared/services/image.service";
import { Subscription } from "rxjs";
import { SenderService } from "src/app/shared/services/sender.service";
import * as moment from 'moment';
import { UserService } from "src/app/shared/services/user.service";
import { shareReplay } from "rxjs/operators";
import { User } from "src/app/shared/interfaces/user.model";


@Component({
  selector: "app-user-friend-chat",
  templateUrl: "./user-friend-chat.component.html",
  styleUrls: ["./user-friend-chat.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class UserFriendChatComponent implements OnInit, OnChanges, OnDestroy{
  @Input() friendData: Model;
  @Input() contact: UserContact;
  private socket: Socket;
  @Input() public selectedUser;
  @ViewChild("messageThread") private messageContainer: ElementRef;
  public userClicked = false;
  public message = "";
  public messages: Messages[] = [];
  public getMessage: Messages;
  public outUser: User;

  @Output() removeChat: EventEmitter<any> = new EventEmitter<any>();
  config = {
    "emoji-textarea": true,
    toolbar: false,
  }
  editorConfig = {
    border: "none",
    padding: "0",
    height:"inherit !important"
  }
  public authImage;
  public hasPhoto: boolean;
  public selectedMessage;
  public image;
  public authUser: User;
  public user: User;


  public subscriptions: Subscription;
  public imageSubscription: Subscription;
  public modelSubscription: Subscription;
  constructor(
    public msgHelperService: MessageHelperService,
    public authService: AuthService,
    public imageService: ImageService,
    public cd: ChangeDetectorRef,
    public receiver: SenderService,
    public userService: UserService) {
    //  = io(environment.backend);

  }

  ngOnInit(): void {
      this.getMessages();
      this.cd.detectChanges();
      this.scrollMessageContainer();
    /*  this.socket.on("newMessage", () => {
      this.getMessages();
    })*/

    this.userService.getUserContactData(this.contact.contactId).pipe(shareReplay(1)).subscribe(res => {
      this.user = res;
     });



  /*  if(this.friendData.profilePicture) {
      this.imageService.getImageByID(this.friendData.profilePicture).subscribe((res: Image) => {
        if(res.imageUrl) {
          console.log(res.imageUrl);
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    } */

    this.modelSubscription = this.receiver.modelUpdate$.subscribe((success: any) => {
      this.friendData = success;
      moment.locale(this.friendData.language.primary);

      if(this.friendData.profilePicture) {
      this.imageSubscription = this.imageService.getImageByID(this.friendData.profilePicture).subscribe((res: Image) => {
        if(res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    }
    this.userService.getUserContactData(this.authService.userID).subscribe(userT => {
      this.authUser = userT;
    })
    });
    this.userService.getUserContactData(this.selectedUser).subscribe(t => {
      this.outUser = t;
    })


  }
  ngOnChanges(changes: SimpleChanges) {
    this.getMessages();
    this.cd.detectChanges();
  }
  ngOnDestroy(): void {
    this.modelSubscription.unsubscribe();
  }

  trackThings(index, thing) {
    return thing ? index : undefined;
  }

  getMessages() {
   // this.socket.on("newMessage", () => {
      this.msgHelperService.getMessages(this.authService.userID, this.selectedUser).subscribe((response: Messages[]) => {
        this.messages = response;
        this.scrollMessageContainer();
      });
    // });
    this.msgHelperService.getMessages(this.authService.userID, this.selectedUser).subscribe((response: Messages[]) => {
      this.messages = response;
      this.scrollMessageContainer();
    })
  }

  alignMessage(userId: string): boolean {
    return this.authService.userID !== userId;
  }
  scrollMessageContainer(): void {
    if (this.messageContainer !== undefined) {
      try {
        setTimeout(() => {
          this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
        }, 10);
      } catch (error) {
        console.warn(error);
      }
    }
  }
  sendMessage(friend:UserContact) {
    const msgObj: Messages = {
                chat: [{
                  value: this.message,
                  timestamp: new Date(),
                  emitter: this.authService.userID,
                  emitterId: friend.contactId}],
          timestamp: new Date(),
    }
    const content = {
      value: this.message,
      timestamp: new Date(),
      emitter: this.authService.userID,
      emitterId: friend.contactId,
    }

   if(Object.keys(this.messages).length !== 0) {
      this.messages.map(res => res.chat.unshift(content))
      const getID = this.messages.map(res => res.id).join("");
      this.messages.map(res => {
        this.getMessage = res;
      })
      this.msgHelperService.updateChat(getID, this.getMessage).subscribe();
      this.getMessages();
    } else {
      this.msgHelperService.createMessage(msgObj).pipe().subscribe();
    }
    this.message = "";
    this.scrollMessageContainer();
    setTimeout(()=>{
      this.socket.on("newMessage", () =>{
        this.getMessages();
      });
    }, 500);
    this.cd.detectChanges();
    this.scrollMessageContainer();
  }

  public closeChat(close): void {
    this.removeChat.emit(close);
  }

  showMore(index) {
    this.selectedMessage = "";
    this.selectedMessage = index;
  }

  deleteMessage(i, message) {
    this.messages.splice(i, 1);
    this.msgHelperService.update(message.emitterId, message.emitter, this.messages).subscribe();
  }
}
