import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-dialog-data-details',
  templateUrl: './dialog-data-details.component.html',
  styleUrls: ['./dialog-data-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogDataDetailsComponent implements OnInit {
  public user: User;
  public dataList$: Observable<any>;
  userObj: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DataObject,
  private userService: UserService,
  private authService: AuthService,

  ) { }

  ngOnInit(): void {
    this.userService.getUserContactData(this.authService.userID).subscribe(r => {
      this.user = r;
      console.log(this.data.data._id, "this.data")
      this.userService.getUserCV(this.data.data._id).subscribe();
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        this.userObj = res;
        console.log(this.userObj, "RESSS")
      });
    })

  }

}

export interface DataObject {
  user?: false;
  data?: any;
  job?: false;
  project?: false;
}

