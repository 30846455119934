import {Component, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import { Page } from "./shared/interfaces/model";
import { AuthService } from "./shared/services/auth.service";
import { PagesService } from "./shared/services/pages.service";
import { UserService } from "./shared/services/user.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "smart-career-ng";
  loggedIn = false;
  theme: string = "light";

  constructor(public router: Router, private translate: TranslateService, public authService: AuthService,
     public pageService: PagesService, private userService: UserService) {
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang);


    if (localStorage.getItem("theme")) {
       this.theme = localStorage.getItem("theme");
    } else {
      if (this.theme === "light") {
        localStorage.setItem("theme", this.theme)
      } else {
        localStorage.setItem("theme", "dark")
      }
    }

  }

  changeColors() {
    if (localStorage.getItem("theme")) {
      this.theme = localStorage.getItem("theme");
   } else {
     if (this.theme === "light") {
       localStorage.setItem("theme", this.theme)
     } else {
       localStorage.setItem("theme", "dark")
     }
   }
  }

}
