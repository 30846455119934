import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take } from 'rxjs';
import { Project } from 'src/app/shared/interfaces/model';
import { ProjectService } from 'src/app/shared/services/project.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit {

  public dataList$: Observable<any>;
  userData: any;
  projects: Project[];
  projectID = "";
  project: Project;

  constructor(
    public userService: UserService,
    public router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {


  }
  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.dataList$.pipe(take(1)).subscribe((res: any) => {
      //   this.privacyIcon = this.loggedUser;
      //  this.privacyText = this.loggedUser;

      this.userData = res;

      this.userService.getMyProjects(this.userData.userUser.projects.data[0].projectListID).pipe(take(1)).subscribe((res: any) => {
        this.projects = res[0].projects
      })

      const test = this.route.snapshot.paramMap.get('projectID');
      this.route.params.subscribe(params => {
        if (!this.isEmptyObject(params)) {
          this.projectID = params['projectID']; // Access the 'id' parameter from the URL
          this.projectService.getProject(this.projectID).subscribe(resProject => {
            this.project = resProject;
           })
        } else {
          console.log(this.route.parent.snapshot.url[2]);
         // this.projectID = this.router.url.replace()
        }
        console.log(params, "params")

      });



    });
  }
isEmptyObject(obj: any): boolean {
  return JSON.stringify(obj) === '{}';
}
}
