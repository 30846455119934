<div class="main-content right-aligned">
  <section class="search-result-container">
    <div>
      <div class="result-list" *ngFor="let userJobs of jobs">
        <app-job-item [userJobs]="userJobs"></app-job-item>

      </div>
    </div>
  </section>
</div>
