import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-search',
  templateUrl: './main-search.component.html',
  styleUrls: ['./main-search.component.scss']
})
export class MainSearchComponent implements OnInit {
  public search= "";

  constructor(private router: Router) {
    this.search = this.router.getCurrentNavigation().extras.state.example;

  }

  ngOnInit(): void {
  }

}
