<div class="container">
  <form class="form-grid" name="ngForm">
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{'Name of Category'}}:*</label>
          <input
           [(ngModel)]="data.emptyCategory.name"
            class="form-control-sm col-md-12"
            name="emptyCatName"
            type="text"
                     >

        </div>
      </div>
    </li>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="submit" class="btn btn-success" (click)="saveData()" mat-button >{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</form>
</div>
