<ng-container>
  <h2>{{ "settings.notifications.name" | translate }}</h2>
  <span class="d-flex pt-2 pb-2 font-weight-600">We may still send you important notifications about your account and content outside of your preferred notification settings.</span>
  <span class="d-flex pt-2 pb-2 font-weight-600">What Notifications You Receive</span>
  <div class="notification">
    <div class="notification__item d-flex justify-content-between" role="button" data-bs-toggle="collapse" (click)="isCollapsed = !isCollapsed" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
     <div class="d-flex justify-flex-start align-items-center">
      <i class="bi bi-chat-square pl-3 pr-3 notification__icon"></i>
      <div class="">
        <div role="heading" class="font-weight-600">Comments</div>
        <div class="">Push, Email, SMS</div>
      </div>
     </div>
     <i *ngIf="!isCollapsed" class="bi bi-caret-down-fill pl-3 pr-3"></i>
     <i *ngIf="isCollapsed" class="bi bi-caret-up-fill pl-3 pr-3"></i>

    </div>
    <div *ngIf="isCollapsed">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications for comments on your posts and replies to your comments.</span>
          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

           </div>
           <hr>
           <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
            </div>
             <div class="d-flex">
               <span class="pr-3">On</span>

               <label class="switch">
                 <input type="checkbox">
                 <span class="slider round"></span>
               </label>
             </div>
           </div>
           <hr>

           <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
            </div>
             <div class="d-flex">
               <span class="pr-3">On</span>

               <label class="switch">
                 <input type="checkbox">
                 <span class="slider round"></span>
               </label>
             </div>
           </div>
        </div>
      </div>
      </div>
    <hr>
    <!-- Tags -->
    <div class="notification__item d-flex justify-content-between" (click)="isCollapsedTags = !isCollapsedTags">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-tag pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Tags</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedTags" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedTags" class="bi bi-caret-up-fill pl-3 pr-3"></i>
    </div>
    <div *ngIf="isCollapsedTags">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications for when someone tags you in a comment, post or story</span>
          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Get notifications when you're tagged by:</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center pl-3">
                <div class="">Anyone</div>
            </div>
            <div class="d-flex">
              <div class="form-check me-3">
                <input class="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>

              </div>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center pl-3">
                <div class="">Friends of Friends</div>
            </div>
            <div class="d-flex">
              <div class="form-check me-3">
                <input class="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault2">

              </div>
            </div>
          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center pl-3">
              <div class="">
                <div class="">Friends</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="form-check me-3">
                <input class="form-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault3">

              </div>
            </div>
          </div>
          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedReminders = !isCollapsedReminders">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Reminders</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
       <i *ngIf="!isCollapsedReminders" class="bi bi-caret-down-fill pl-3 pr-3"></i>
       <i *ngIf="isCollapsedReminders" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
    <div *ngIf="isCollapsedReminders">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications to remind you of updates you may have missed.</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">{{friendRequestNotificationReminders}}</span>
              <label class="switch">
                <input type="checkbox" name="allowNotifications" [ngModel]="node?.reminders?.all" (change)="clickReminder($event)">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedActivity = !isCollapsedActivity">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-layers pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">More Activity About You</div>
         <div class="">Push, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedActivity" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedActivity" class="bi bi-caret-up-fill pl-3 pr-3"></i>
    </div>
     <div *ngIf="isCollapsedActivity">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications for posts to your timeline, Likes and other Reactions to your posts, and more.</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">Off</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedUpdates = !isCollapsedUpdates">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-people pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Updates From Friends</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedUpdates" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedUpdates" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedUpdates">
     <div class="card card-body">
      <div class="column d-flex">
        <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about your friends' activity, like when they update their status or share a photo.</span>
        <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">Allow Notifications</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">On</span>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

        </div>

        <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
        <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">Push</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">On</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

        </div>
        <hr>
        <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">Email</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">On</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <hr>

        <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">SMS</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedFriends = !isCollapsedFriends">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-person-plus pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Friend Requests</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedFriends" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedFriends" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedFriends">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">Notifications when someone sends you a friend request or accepts your friend request.</span>
         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">{{friendRequestNotificationPush}}</span>

             <label class="switch">
               <input type="checkbox" [(ngModel)]="node.friendRequest.push" (change)="clicked($event)">
               <span class="slider round"></span>
             </label>
           </div>

         </div>
         <hr>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">{{friendRequestNotificationEmail}}</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedPeople = !isCollapsedPeople">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-person-square pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">People You May Know</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedPeople" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedPeople" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedPeople">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about People You May Know suggestions.</span>
         <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">Allow Notifications</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">On</span>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

        </div>
         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>
         <hr>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedBirthday = !isCollapsedBirthday">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-gift pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Birthdays</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedBirthday" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedBirthday" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedBirthday">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about your friends' birthdays.</span>
         <div class="notification__item d-flex justify-content-between">
          <div class="d-flex justify-flex-start align-items-center">
            <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
            <div class="">
              <div class="">Allow Notifications</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">On</span>
            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

        </div>
         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>
         <hr>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedGroup = !isCollapsedGroup">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-people-fill pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Groups</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedGroup" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedGroup" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedGroup">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about activity in Groups you've joined.</span>
         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedVideo = !isCollapsedVideo">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-play-btn pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Video</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedVideo" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedVideo" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedVideo">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications for when people or Pages you follow broadcast a live video.</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Allow Notifications</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>
             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>

         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>
         <hr>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedEvents = !isCollapsedEvents">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-calendar pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Events</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedEvents" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedEvents" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedEvents">
      <div class="card card-body">
       <div class="column d-flex">
         <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about Events.</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Allow Notifications</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>
             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>

         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Push</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>

         </div>
         <hr>
         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">Email</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">On</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>

         <div class="notification__item d-flex justify-content-between">
           <div class="d-flex justify-flex-start align-items-center">
             <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
             <div class="">
               <div class="">SMS</div>
             </div>
           </div>
           <div class="d-flex">
             <span class="pr-3">Off</span>

             <label class="switch">
               <input type="checkbox">
               <span class="slider round"></span>
             </label>
           </div>
         </div>
         <hr>
         <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Receive notifications for</span>
         <div class="notification__item d-flex justify-content-between pt-2 pb-2 pl-3 pr-3">
          <div class="d-flex justify-flex-start align-items-center">
            <div class="">
              <div class="">Activity</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="notification__item d-flex justify-content-between pt-2 pb-2 pl-3 pr-3">
          <div class="d-flex justify-flex-start align-items-center">
            <div class="">
              <div class="">Reminders</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="notification__item d-flex justify-content-between pt-2 pb-2 pl-3 pr-3">
          <div class="d-flex justify-flex-start align-items-center">
            <div class="">
              <div class="">Changes and Updates</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="notification__item d-flex justify-content-between pt-2 pb-2 pl-3 pr-3">
          <div class="d-flex justify-flex-start align-items-center">
            <div class="">
              <div class="">Event Subscriptions</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <hr>
        <div class="notification__item d-flex justify-content-between pt-2 pb-2 pl-3 pr-3">
          <div class="d-flex justify-flex-start align-items-center">
            <div class="">
              <div class="">Suggestions</div>
            </div>
          </div>
          <div class="d-flex">
            <span class="pr-3">Off</span>

            <label class="switch">
              <input type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
       </div>
     </div>
   </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedPages = !isCollapsedPages">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-flag-fill pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Pages You Manage</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedPages" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedPages" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedPagesFollow = !isCollapsedPagesFollow">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-flag pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Pages You Follow</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedPagesFollow" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedPagesFollow" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedPagesFollow">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about activity in Pages that you follow.</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedPagesMarketPlace = !isCollapsedPagesMarketPlace">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-shop pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Marketplace</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedPagesMarketPlace" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedPagesMarketPlace" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedPagesMarketPlace">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">These are notifications about Marketplace listings you're following or might be interested in.</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedFundraisers = !isCollapsedFundraisers">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-suit-heart pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Fundraisers and Crises</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedFundraisers" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedFundraisers" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedFundraisers">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">
            These are notifications for when friends mark themselves safe or ask for help during a crisis, updates if you've signed up to donate blood near you and fundraisers you or your friends create.
          </span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
     <hr>
     <div class="notification__item d-flex justify-content-between" (click)="isCollapsedNotifications = !isCollapsedNotifications">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-three-dots pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Other Notifications</div>
         <div class="">Push, Email, SMS</div>
       </div>
      </div>
      <i *ngIf="!isCollapsedNotifications" class="bi bi-caret-down-fill pl-3 pr-3"></i>
      <i *ngIf="isCollapsedNotifications" class="bi bi-caret-up-fill pl-3 pr-3"></i>
     </div>
     <div *ngIf="isCollapsedNotifications">
      <div class="card card-body">
        <div class="column d-flex">
          <span class="d-flex pt-2 pb-2 pr-3 pl-3">
            These are notifications for app or game requests, restaurants you may be near, expiring offers and more.
          </span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-bell pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Allow Notifications</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>
              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>

          <span class="d-flex pt-2 pb-2 pl-3 pr-3 font-weight-600">Where you receive these notifications</span>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Push</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

          </div>
          <hr>
          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">Email</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
          <hr>

          <div class="notification__item d-flex justify-content-between">
            <div class="d-flex justify-flex-start align-items-center">
              <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
              <div class="">
                <div class="">SMS</div>
              </div>
            </div>
            <div class="d-flex">
              <span class="pr-3">On</span>

              <label class="switch">
                <input type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="d-flex font-weight-600 pt-3 pb-3">How You Get Notifications</span>
  <div class="notification">
    <div class="notification__item d-flex justify-content-between">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-window pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Browser</div>
       </div>
      </div>
      <i class="bi bi-caret-down-fill pl-3 pr-3"></i>

     </div>
     <hr>
     <div class="notification__item d-flex justify-content-between">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-envelope pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">Email</div>
         <div class="">On, Suggested</div>
       </div>
      </div>
      <i class="bi bi-caret-down-fill pl-3 pr-3"></i>

     </div>
     <hr>
     <div class="notification__item d-flex justify-content-between">
      <div class="d-flex justify-flex-start align-items-center">
       <i class="bi bi-chat-square-text pl-3 pr-3 notification__icon"></i>
       <div class="">
         <div role="heading" class="font-weight-600">SMS</div>
         <div class="">On, Suggested</div>
       </div>
      </div>
      <i class="bi bi-caret-down-fill pl-3 pr-3"></i>

     </div>

  </div>
  <hr />
</ng-container>
