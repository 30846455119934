<div pageContent class="Unit-unit">
  <div
  [ngClass]="{ 'isActive': selectedIndex === id}" (click)="setIndex(id)"
  >
  <div class="Line-line-container">
   <div class="Line-line-line">
     <div class="Field-field-fieldBase first-template-fieldField">
       <div class="Text-Text-wrapper">
         <div class="Text-Text-text">
           {{fileObj.name}}</div>
         </div>
       </div>
     </div>
     <div class="Field-field-fieldBase first-template-fieldValue">
      <div class="Text-Text-wrapper">
        <div class="Text-Text-text-wrapper">
          <a target="_blank" [href]="fileObj.link">{{fileObj.link}}
          </a>
        </div>
      </div>
    </div>
     <div class="Field-field-fieldBase first-template-fieldValue">
       <div class="Text-Text-wrapper">
         <div class="Text-Text-text-wrapper" aria-multiline="true" [innerHTML]="fileObj.description">
         </div>
       </div>
     </div>
<ng-container pageContent *ngIf="selectedIndex === id">
<div clickOutside (clickOutside)="removeClick()">
 <ul>
   <li class="bi bi-pencil addIconTop" (click)="editFile({edit: true, file: fileObj, model: model})"></li>
   <button (click)="addFile({edit: false, model: model})" class="btn"><i class="bi bi-plus addIconBottom"></i></button>
   <button [disabled]="file.subCategories.length === 1"(click)="deleteSubFile(id)" class="btn"><i class="bi bi-trash deleteIconRight"></i></button>
   <li class="bi bi-arrow-down moveIconDown"></li>
   <li class="bi bi-arrow-up moveIconTop"></li>
 </ul>
</div>
</ng-container>
</div>
</div>
