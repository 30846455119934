import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FullIssueDetailsComponent } from "./components/full-issue-details/full-issue-details.component";
import { BoardComponent } from "./pages/board/board.component";
import { ProjectComponent } from "./project.component";

const routes: Routes = [
    {
        path: '',
        component: ProjectComponent,
        children: [
            {
                path: 'board',
                component: BoardComponent
              },
              {
                path: `issue/:issueId`,
                component: FullIssueDetailsComponent
              },
          {
            path: '',
            redirectTo: 'board',
            pathMatch: 'full'
          }
        ]
      }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  
  export class ProjectRoutingModule{}