import { UserService } from 'src/app/shared/services/user.service';
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {catchError, delay, distinctUntilChanged, map, shareReplay, take} from "rxjs/operators";
import {Router} from "@angular/router";
import * as JWT from "jwt-decode";
import { environment } from "src/assets/environments/environment";
import { ToastrService } from "ngx-toastr";
import { User } from "../interfaces/user.model";

export const TOKEN_NAME = "access_token";

export interface Token {
  exp: number;
  iat: number;
  sub?: string;
  access_token: string;
  userId: string;
  isAuthenticated: boolean;
  userData: User;
}

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public baseUrl = environment.backend;
  public baseSignUp = environment.backendXY;
  headers = new HttpHeaders().set("Content-Type", "application/json");
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public getErrorIfEmail = "";
  public hasError = false;
  public _userListCache: BehaviorSubject<any> = new BehaviorSubject({});
  userList: Observable<any> = this._userListCache.asObservable().pipe(distinctUntilChanged());


  constructor(private http: HttpClient, private router: Router, public toast?: ToastrService) {
    this.baseUrl = `${this.baseUrl}`;
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

  }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }
  isAuthenticated(): boolean {
    const token = localStorage.getItem(TOKEN_NAME);
    return token && token.length > 0;
  }

  /* signUp(user: User): Observable<object> {
    const api = `${this.baseUrl}/register-user`;
    return this.http.post(api, user)
      .pipe(
        catchError(this.handleError)
      );
  } */
  signUp(user: User): Observable<object> {
    const api = `${this.baseSignUp}/signup`;
    return this.http.post(api, user)
      .pipe(
        catchError(this.handleError)
      );
  }

  saveUser(user: User): Observable<User> {
    const api = `${this.baseSignUp}/addUser`;
    return this.http.post<User>(api, user, {headers: this.headers})

  }

  confirm(code: string) {
    const api = `${this.baseSignUp}/confirm`;
    return this.http.post(api, {"cC":code}).pipe(catchError(this.handleError));
  }

  checkIfUserEmailExist(email: string) {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('email', email)
    return this.http.get<any>(`${this.baseSignUp}/checkUserEmail`, { headers, params,  }).pipe(shareReplay(1));
  }
  checkIfUserPhoneExist(phone: string) {
    const headers = new HttpHeaders();
    const params = new HttpParams().append('phone', phone)
    return this.http.get<any>(`${this.baseSignUp}/checkUserPhone`, { headers, params,  }).pipe(shareReplay(1));
  }



  signIn(email: string, password: string): Promise<any> {
    return this.http.post<any>(`${this.baseSignUp}/login`, {email, password}).pipe(delay(2000), map((user) => {
      let userData: User = user.user;
      if (userData.account.status === 1 || userData.account.deleted === true) {
        userData.account.status = 2;
        userData.account.endDate = new Date();
        userData.account.deleted = false;
        this.updateUser(userData._id, userData);
      }
      user.user = userData;
      this._userListCache.next(user.user);

      return user;
    })).toPromise();
  }

  public get userID() {
    if (this.isAuthenticated()) {
      const authToken = localStorage.getItem(TOKEN_NAME);
      const userID: Token = JWT(authToken);
      return userID.userId;
    }
  }

  public getUserData() {
    const authToken = localStorage.getItem(TOKEN_NAME);
    const user: Token = JWT(authToken);
    return user;
  }

  public get userLoggedIn() {
    if (this.isAuthenticated()) {
      const authToken = localStorage.getItem(TOKEN_NAME);
      const user: Token = JWT(authToken);
      return user;
    }
  }

  public logout() {
    localStorage.removeItem(TOKEN_NAME);
    this.userSubject.next(null);
    this.router.navigate(["/login"]);
  }

  get isLoggedIn(): boolean {
    const authToken = localStorage.getItem(TOKEN_NAME);
    return (authToken !== null);
  }

  handleError(error: HttpErrorResponse) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      this.hasError = true;
      this.getErrorIfEmail = error.error.message;
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }


  updateUser(id: string, user: User) {
      const api = `${this.baseUrl}/user/${id}`
      this.http.put<any>(api, user).pipe(take(1)).subscribe((res) => {
        this.userSubject.next(res);
      })
  }

}
