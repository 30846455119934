
<mat-horizontal-stepper [linear]="true" #stepper style="margin-left: 20px;">
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup">
      <ng-template matStepLabel></ng-template>
      <li class="mat-form-field--inline">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label> {{ "title" | translate }} </mat-label>
          <input matInput name="JobTitle" type="text" [(ngModel)]="job.positionTitle" formControlName="positionTitle"
             />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ "project.firstPage.workplace" | translate }} </mat-label>
          <mat-select id="remote" [(ngModel)]="job.remote" formControlName="remote"
            >
            <mat-option *ngFor="let remote of remoteArray" [value]="remote.id" [selected]="remote">
              {{ remote.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "career.employmentType" | translate }} </mat-label>
          <mat-select id="selectedEmploymentType" [(ngModel)]="job.employmentType" formControlName="employmentType"
            >
            <mat-option *ngFor="let employment of employmentType" [value]="employment.id" [selected]="employment">
              {{ employment.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <span class="firstLabel" style="width: 100%; margin-bottom: .5rem;">{{ "data.address" | translate }}: </span>
        <br>


        <mat-form-field appearance="fill">
          <mat-label> {{ "addressData.street" | translate }} </mat-label>
          <input matInput name="street" type="text" [(ngModel)]="job.street" formControlName="street" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.zip" | translate }} </mat-label>
          <input matInput name="zip" type="text" [(ngModel)]="job.postalCode" formControlName="postalCode" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.city" | translate }} </mat-label>
          <input matInput name="city" type="text" [(ngModel)]="job.city" formControlName="city" />
        </mat-form-field>
        <mat-form-field appearance="fill" >
          <mat-label>{{ "data.country" | translate }} </mat-label>
          <mat-select [(ngModel)]="job.c" formControlName="country" (ngModelChange)="changeCountry($event)">
            <mat-option *ngFor="let country of model" [value]="country.ISO_2" (change)="changeCountry($event)" [selected]="selectedCountry">
              {{ country.EN }} - ({{ country.LokalName }})
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.state" | translate }} </mat-label>
          <input matInput name="state" type="text" [(ngModel)]="job.state" formControlName="state" />
        </mat-form-field>


      </li>
      <div style="display: flex;
      width: 100%;
      justify-content: space-between;">
        <button mat-button matStepperNext class="btn btn-light">Next</button>
        <button mat-button (click)="resetData()" class="btn btn-light">Cancel</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" >
    <form [formGroup]="secondFormGroup">
      <li class="mat-form-field--inline">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label> {{ "job.secondGroup.tasks" | translate }}:* </mat-label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of job.skillsOffer; let i = index" formArrayName="skillsOffer"
                class="d-flex justify-content-between pr-1">
                <div class="card__text">
                  {{ sp.name }}
                  <span class="fa fa-remove" (click)="removeSkills(i)"></span>
                </div>
              </div>
              <input matInput name="zip" type="text" [(ngModel)]="skillsOffer" formControlName="skillsOffer"
                style="display: none !important;">
              <button class="btn btn-light ms-1" (click)="addSkillsOffer({createJob: true})">
                <i class="bi bi-pencil" [ngClass]="{ 'isRequired':job.skillsOffer.length === 0}"></i>
              </button>
            </div>
          </div>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "skills.pcKnowledge" | translate }} </mat-label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of job.pcKnowledge; let i = index" formArrayName="pcKnowledge"
                class="d-flex justify-content-between pr-1">
                <div class="card__text">
                  {{ sp.name }}
                  <span class="fa fa-remove" (click)="removeSP(i)"></span>
                </div>
              </div>
              <input matInput name="zip" type="text" [(ngModel)]="pcKnowledge" formControlName="pcKnowledge"
                style="display: none !important;">
              <button class="btn btn-light ms-1" (click)="addPcKnowledge({jobProject: true})">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
          </div>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "skills.driveLicense" | translate }} </mat-label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of job.driveLicense; let i = index" formArrayName="driveLicense"
                class="d-flex justify-content-between pr-1">
                <div class="card__text">
                  {{ sp.name }}
                  <span class="fa fa-remove" (click)="removeSP(i)"></span>
                </div>
              </div>
              <input matInput name="zip" type="text" [(ngModel)]="driveLicense" formControlName="qualifications"  style="display: none !important;" />
              <button class="btn btn-light ms-1" (click)="addJobDriveLicense({jobDriveLicense: true})">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
          </div>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "skills.language" | translate }} </mat-label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of job.language; let i = index" formArrayName="language"
                class="d-flex justify-content-between pr-1 me-1">
                <div class="card__text">
                  {{getLanguageName(sp.name)?.value}}
                  <span class="fa fa-remove" (click)="removeLanguage(i)"></span>
                </div>
              </div>
              <input matInput name="zip" type="text" [(ngModel)]="language" formControlName="language"  style="display: none !important;" />
              <button class="btn btn-light ms-1" (click)="addJobLanguage({jobLanguage: true, projectLanguage: false, model: user})">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
          </div>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "skills.qualifications" | translate }} </mat-label>
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of job.qualifications; let i = index" formArrayName="qualifications"
                class="d-flex justify-content-between pr-1">
                <div class="card__text">
                  {{ sp.name }}
                  <span class="fa fa-remove" (click)="removeQualifications(i)"></span>
                </div>
              </div>
              <input matInput name="zip" type="text" [(ngModel)]="qualifications" formControlName="qualifications"
                style="display: none !important;" />
              <button class="btn btn-light ms-1" (click)="addQualifications({editJobQualifications: true})">
                <i class="bi bi-pencil"></i>
              </button>
            </div>
          </div>
        </mat-form-field>
      </li>
      <div style="display: flex;
      width: 100%;
      justify-content: space-between;">
              <div>
                <button mat-button class="btn btn-light" matStepperPrevious>Back</button>
                <button mat-button class="btn btn-light"  matStepperNext>Next</button>

      </div>
      <div>
        <button type="submit" (click)="resetData()" mat-button>Cancel</button>

      </div>
      </div>
    </form>
  </mat-step>



  <mat-step [stepControl]="myForm.control">
    <form name="ngForm" #myForm="ngForm"  (ngSubmit)="myForm.form.valid">
      <li class="mat-form-field--inline">
          <mat-label>{{ "career.description" | translate }} </mat-label>
          <!-- <quill-editor name="editor" matInput [(ngModel)]="job.description" [modules]="modules" formControlName="editor">
            </quill-editor> -->
         <quill-editor name="editor" #editor="ngModel" [(ngModel)]="job.description"  [ngClass]="{ 'is-invalid': myForm.submitted && editor.invalid }" required>
          </quill-editor>
          <div *ngIf="myForm.submitted && editor.invalid" class="invalid-feedback">
            <div *ngIf="editor.errors.required">
              {{ "field" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
      </li>

      <div>
        <button mat-button matStepperPrevious >Back</button>
        <button type="submit" mat-button matStepperNext >Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="fourthFormGroup">
    <form [formGroup]="fourthFormGroup">
      <h2>Planned</h2>
      <li class="mat-form-field--inline">
        <mat-form-field appearance="fill"  style="width: 31%">
          <mat-label>{{ "job.fourthPage.paymentForm" | translate }} </mat-label>
          <mat-select [ngModel]="job?.incomes?.s"
          (ngModelChange)="job?.incomes.s=$event" formControlName="salaryStatus" required>
            <mat-option *ngFor="let salary of salaryArray" [value]="salary.id" [selected]="salary">
              {{ salary.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"  style="width: 31%" class="summe">
          <mat-label> {{ "job.fourthPage.summe" | translate }} </mat-label>
          <input matInput name="salaryBudget" type="number" [ngModel]="job?.incomes?.budget"
          (ngModelChange)="job?.incomes.budget=$event" formControlName="salaryBudget"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 31%">
          <mat-label>{{ "job.fourthPage.currency" | translate }} </mat-label>
          <mat-select [(ngModel)]="currency" formControlName="currency" required  (ngModelChange)="changeCurrency($event)">
            <mat-option *ngFor="let country of model" [value]="country?.ISO_2"  [selected]="selectedCountry?.ISO_2">
              {{ country?.currency.Symbol }} - ({{country?.currency.Alphabeticcode}} - {{ country?.currency.Name }}) - {{ country?.LokalName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"  style="width: 31%">
          <mat-label>{{ "job.fourthPage.duration.jobDuration" | translate }} </mat-label>
          <mat-select id="jobDuration" [(ngModel)]="job.duration" formControlName="jobDuration"
            required>
            <mat-option *ngFor="let duration of jobDurationArray" [value]="duration.id" [selected]="duration">
              {{ duration.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill"  style="width: 31%"  *ngIf="fourthFormGroup.get('jobDuration').value === jobDuration.temporary" style="width: 31%;">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input  [min]="minDate" [rangePicker]="picker">
            <input matStartDate formControlName="start" [(ngModel)]="startDateLimited" placeholder="Start date">
            <input matEndDate formControlName="end" [(ngModel)]="endDateLimited"  placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="secondFormGroup.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="secondFormGroup.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill"  style="width: 31%"  *ngIf="fourthFormGroup.get('jobDuration').value === jobDuration.unlimited" style="width: 31%;">
            <mat-label>Start date</mat-label>
            <input matInput [(ngModel)]="selectedDate" [matDatepicker]="picker" [min]="minDate" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span *ngIf="fourthFormGroup.get('jobDuration').value === jobDuration.temporary">
          {{getDays}} days
        </span>
      </li>

      <div style="display: flex;
      width: 100%;
      justify-content: space-between;">
      <div>
        <button mat-button matStepperPrevious class="btn btn-light">
          Back
        </button>
        <button class="btn btn-light" type="submit" (click)="saveData()" mat-button>Save</button>
      </div>
      <div>
        <button type="submit"  class="btn btn-light" (click)="resetData()" mat-button>Cancel</button>
      </div>
      </div>
    </form>
  </mat-step>

</mat-horizontal-stepper>
