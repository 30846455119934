import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { JobItemDetailsModule } from "src/app/job-item-details/job-item-details.module";
import { JobItemComponent } from "./job-item.component";
import { TranslateModule } from "@ngx-translate/core";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { DataItemDetailsModule } from "../../data-item-details/data-item-details.module";

@NgModule({
    declarations:[ JobItemComponent],
    imports: [   BrowserModule,
        FormsModule,
        RouterModule,
        CommonModule,
        JobItemDetailsModule,
        TranslateModule,
        PipeModule,
        DataItemDetailsModule
    ],
    exports: [
        JobItemComponent
    ]

})

export class JobItemModule {

}
