<div class="container px-2 py-2">
    <div class="modal-header">

    </div>
    <div class="modal-body">
        <div class="line">
            <div class="row col-12">
                <div class="container d-flex justify-content-center col-12">
                    <div class="col-12">
                        <label>{{ "career.employmentType" | translate }} </label>
                        <select name="selectedValue" id="selectedValue" [(ngModel)]="data.user?.religion.name"
                          class="col-md-12 form-control" #selectedValue="ngModel"
                         >
                          <option *ngFor="let employment of arrayService.religions" [ngValue]="employment.id" [selected]="employment">
                            {{ employment.name | translate }}
                          </option>
                        </select>
                    </div>
                </div>
            </div>

            <h4>{{ "career.company" | translate }}</h4>
            <li class="line form-row">
              <div class="col row col-md-12">
                <div class="col-md-12">
                  <label>{{ "career.name" | translate }} </label>
                  <ng-container>


            <div class="row no-gutters" *ngIf="pageSelected">
              <div class="col input-group">
                <span class="input-group-prepend" *ngIf="pageActive">
                  <div class="input-group-text bg-transparent border-right-0">
                    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                      fallback="mp" size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                  </div>
                </span>
                <input class="form-control" type="text" [value]="pageActive" [ngModel]="searchForPage"
                  (keyup)="search($event)" name="careerName" #careerName="ngModel" id="example-search-input5"
                  [readonly]="pageActive" autocomplete="off">
              </div>
              <div class="col-auto">
                <button class="btn btn-outline-light" type="button" (click)="deletePage()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
            <ng-container *ngIf="searchForPageTrue">
              <div *ngFor="let page of pages$ | async" class="card__list">

                <div class="hstack gap-2 mb-3" (click)="addNewCompany(page)">
                  <!-- Avatar -->
                  <div class="avatar-xs">
                    <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                  </div>
                  <!-- Title -->
                  <div class="overflow-hidden">
                    <a class="h6 mb-0"> {{ page.name }} </a>
                  </div>
                  <!-- Button -->
                </div>

              </div>
            </ng-container>
                  </ng-container>


                </div>
              </div>
            </li>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
          {{ "cancel" | translate }}
        </button>
        <button type="submit" (click)="save()" class="btn btn-success" mat-button>
          {{ "save" | translate }}
        </button>
      </mat-dialog-actions>
</div>
