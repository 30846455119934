<main>
 <div class="container">
    <div class="row g-4">

        <!-- Sidenav START -->
        <div class="col-lg-3">

            <!-- Advanced filter responsive toggler START -->
            <div class="d-flex align-items-center d-lg-none">
                <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                    <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                    <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'menu' | translate}}</span>
                </button>
            </div>
            <!-- Advanced filter responsive toggler END -->

            <!-- Navbar START-->
            <nav class="navbar navbar-expand-lg mx-0">
                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                    <!-- Offcanvas header -->
                    <div class="offcanvas-header">
                        <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>

                    <!-- Offcanvas body -->
                    <div class="offcanvas-body d-block px-2 px-lg-0">
                        <!-- Card START -->
                        <div class="card overflow-hidden">
                            <!-- Cover image -->

                                <!-- Card body START -->
                                <div class="card-body pt-0">
                                <!-- Side Nav START -->
                                <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
                                        <!-- Avatar -->
                                        <button type="button" class="button-f button-sc px-0" (click)="navigateToUser()">
                                            <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                              <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                               <span class="span-padding-left10">{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
                                          </button>
                                        <!-- Post input -->
                                    <li (click)="cv()" class="nav-item" [routerLink]="currentUser?.userUrl + '/cv'">
                                        <a class="nav-link" > <i class="bi bi-file-text"></i>
                                            <span class="ms-2">{{'header.cv' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" routerLink="/pages">
                                        <a class="nav-link" > <i class="bi bi-flag"></i><span class="ms-2">{{'header.pages' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" routerLink="/groups">
                                        <a class="nav-link" > <i class="bi bi-people"></i>
                                            <span class="ms-2">{{'header.groups' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" routerLink="/contacts">
                                        <a class="nav-link"><i class="bi bi-person"></i>
                                             <span class="ms-2">{{'header.contacts' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" [routerLink]="currentUser?.userUrl + '/jobs'">
                                        <a (click)="removePageSearchJob()" class="nav-link" ><i class="bi bi-list-task"></i>
                                            <span class="ms-2">{{'sidebar.jobs' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" [routerLink]="currentUser?.userUrl + '/projects'">
                                        <a class="nav-link"><i class="bi bi-bar-chart-steps fs-6"></i><span class="ms-2">{{'sidebar.projects' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" (click)="navigateToPayment()">
                                        <a class="nav-link"> <i class="bi bi-credit-card"></i><span class="ms-2">{{'sidebar.payment' | translate}} </span></a>
                                    </li>
                                    <li class="nav-item" (click)="navigateToPayment()">
                                      <a class="nav-link"><i class="bi bi-badge-ad"></i><span class="ms-2">{{'sidebar.advertisement' | translate}} </span></a>
                                  </li>

                                </ul>

                                <!-- Side Nav END -->
                            </div>
                            <!-- Card body END -->
                            <!-- Card footer -->
                            <div class="card-footer text-center py-2">
                                <a class="btn btn-link btn-sm" href="my-profile.html">View Profile </a>
                            </div>
                        </div>
                        <!-- Card END -->

                        <!-- Helper link START -->
                        <ul class="nav small mt-4 justify-content-center lh-1">
                            <li class="nav-item">
                                <a class="nav-link" href="my-profile-about.html">About</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="settings.html">Settings</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="help.html">Help</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                            </li>
                        </ul>
                        <!-- Helper link END -->
                        <!-- Copyright -->
                        <p class="small text-center mt-1">©2023 <a class="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </a></p>
                    </div>
                </div>
            </nav>
            <!-- Navbar END-->
        </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-md-8 col-lg-6 vstack gap-4">


    <!-- Story END -->

            <!-- Share feed START -->
            <div class="card card-body">
                <div class="d-flex mb-3">
                    <!-- Avatar -->
                    <div class="avatar avatar-xs me-2">
                        <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/avatar/03.jpg" alt=""> </a>
                    </div>
                    <!-- Post input -->
                    <form class="w-100">
                        <textarea class="form-control pe-4 border-0" rows="2" data-autoresize placeholder="Share your thoughts..."></textarea>
                    </form>
                </div>
                <!-- Share feed toolbar START -->
                <ul class="nav nav-pills nav-stack small fw-normal">
                    <li class="nav-item">
                        <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionPhoto"> <i class="bi bi-image-fill text-success pe-2"></i>Photo</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#feedActionVideo"> <i class="bi bi-camera-reels-fill text-info pe-2"></i>Video</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="nav-link bg-light py-1 px-2 mb-0" data-bs-toggle="modal" data-bs-target="#modalCreateEvents"> <i class="bi bi-calendar2-event-fill text-danger pe-2"></i>Event </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link bg-light py-1 px-2 mb-0" href="#!" data-bs-toggle="modal" data-bs-target="#modalCreateFeed"> <i class="bi bi-emoji-smile-fill text-warning pe-2"></i>Feeling /Activity</a>
                    </li>
                    <li class="nav-item dropdown ms-lg-auto">
                        <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                        </a>
                        <!-- Dropdown menu -->
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                            <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                            <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question </a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                        </ul>
                    </li>
                </ul>
                <!-- Share feed toolbar END -->
            </div>
            <!-- Share feed END -->


            <!-- Card feed item END -->

            <!-- Card feed item START -->
            <!--<app-advertising></app-advertising> -->

            <!-- Card feed item END -->

            <!-- Card feed item START -->

            <!-- Card feed item END -->


            <!-- Card feed item END -->

            <!-- Story END -->

                <!-- Card feed item END -->

                <!-- Load more button START -->
                <a href="#!" role="button" class="btn btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
                    <span class="load-text"> Load more </span>
                    <div class="load-icon">
                        <div class="spinner-grow spinner-grow-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </a>
                <!-- Load more button END -->

        </div>
        <!-- Main content END -->

        <!-- Right sidebar START -->
        <div class="col-lg-3">
            <div class="row g-4">
                <!-- Card follow START -->
                <div class="col-sm-6 col-lg-12">
                    <div class="card">
                        <!-- Card header START -->
                        <div class="card-header pb-0 border-0">
                            <h5 class="card-title mb-0">Who to follow</h5>
                        </div>
                        <!-- Card header END -->
                        <!-- Card body START -->
                        <div class="card-body">
                            <!-- Connection item START -->
                            <div class="hstack gap-2 mb-3">
                                <!-- Avatar -->
                                <div class="avatar">
                                    <a href="#!"><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                    <a class="h6 mb-0" href="#!">Judy Nguyen </a>
                                    <p class="mb-0 small text-truncate">News anchor</p>
                                </div>
                                <!-- Button -->
                                <a class="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i class="fa-solid fa-plus"> </i></a>
                            </div>
                            <!-- Connection item END -->
                            <!-- Connection item START -->
                                <div class="hstack gap-2 mb-3">
                                    <!-- Avatar -->
                                    <div class="avatar avatar-story">
                                        <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/avatar/05.jpg" alt=""> </a>
                                    </div>
                                    <!-- Title -->
                                    <div class="overflow-hidden">
                                        <a class="h6 mb-0" href="#!">Amanda Reed </a>
                                        <p class="mb-0 small text-truncate">Web Developer</p>
                                    </div>
                                    <!-- Button -->
                                    <a class="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i class="fa-solid fa-plus"> </i></a>
                            </div>
                            <!-- Connection item END -->

                            <!-- Connection item START -->
                            <div class="hstack gap-2 mb-3">
                                <!-- Avatar -->
                                <div class="avatar">
                                    <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt=""> </a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                    <a class="h6 mb-0" href="#!">Billy Vasquez </a>
                                    <p class="mb-0 small text-truncate">News anchor</p>
                                </div>
                                <!-- Button -->
                                <a class="btn btn-primary rounded-circle icon-md ms-auto" href="#"><i class="bi bi-person-check-fill"> </i></a>
                            </div>
                            <!-- Connection item END -->

                            <!-- Connection item START -->
                            <div class="hstack gap-2 mb-3">
                                <!-- Avatar -->
                                <div class="avatar">
                                    <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt=""> </a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                    <a class="h6 mb-0" href="#!">Lori Ferguson </a>
                                    <p class="mb-0 small text-truncate">Web Developer at Webestica</p>
                                </div>
                                <!-- Button -->
                                <a class="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i class="fa-solid fa-plus"> </i></a>
                            </div>
                            <!-- Connection item END -->

                            <!-- Connection item START -->
                            <div class="hstack gap-2 mb-3">
                                <!-- Avatar -->
                                <div class="avatar">
                                    <a href="#"> <img class="avatar-img rounded-circle" src="assets/images/avatar/placeholder.jpg" alt=""> </a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                    <a class="h6 mb-0" href="#!">Carolyn Ortiz </a>
                                    <p class="mb-0 small text-truncate">News anchor</p>
                                </div>
                                <!-- Button -->
                                <a class="btn btn-primary-soft rounded-circle icon-md ms-auto" href="#"><i class="fa-solid fa-plus"> </i></a>
                            </div>
                            <!-- Connection item END -->

                            <!-- View more button -->
                            <div class="d-grid mt-3">
                                <a class="btn btn-sm btn-primary-soft" href="#!">View more</a>
                            </div>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
                <!-- Card follow START -->

                <!-- Card News START -->
                <div class="col-sm-6 col-lg-12">
                    <div class="card">
                        <!-- Card header START -->
                        <div class="card-header pb-0 border-0">
                            <h5 class="card-title mb-0">Today’s news</h5>
                        </div>
                        <!-- Card header END -->
                        <!-- Card body START -->
                        <div class="card-body">
                            <!-- News item -->
                            <div class="mb-3">
                                <h6 class="mb-0"><a href="blog-details.html">Ten questions you should answer truthfully</a></h6>
                                <small>2hr</small>
                            </div>
                            <!-- News item -->
                            <div class="mb-3">
                                <h6 class="mb-0"><a href="blog-details.html">Five unbelievable facts about money</a></h6>
                                <small>3hr</small>
                            </div>
                            <!-- News item -->
                            <div class="mb-3">
                                <h6 class="mb-0"><a href="blog-details.html">Best Pinterest Boards for learning about business</a></h6>
                                <small>4hr</small>
                            </div>
                            <!-- News item -->
                            <div class="mb-3">
                                <h6 class="mb-0"><a href="blog-details.html">Skills that you can learn from business</a></h6>
                                <small>6hr</small>
                            </div>
                            <!-- Load more comments -->
                            <a href="#!" role="button" class="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center" data-bs-toggle="button" aria-pressed="true">
                                <div class="spinner-dots me-2">
                                    <span class="spinner-dot"></span>
                                    <span class="spinner-dot"></span>
                                    <span class="spinner-dot"></span>
                                </div>
                                View all latest news
                            </a>
                        </div>
                        <!-- Card body END -->
                    </div>
                </div>
                <!-- Card News END -->
            </div>
        </div>
        <!-- Right sidebar END -->

    </div> <!-- Row END -->
</div>
