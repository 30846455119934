import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Page } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { ChangeDetectionService } from '../shared/services/change-detection.service';
import { PagesService } from '../shared/services/pages.service';
import { ProjectService } from '../shared/services/project.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-applied-projects',
  templateUrl: './applied-projects.component.html',
  styleUrls: ['./applied-projects.component.scss']
})
export class AppliedProjectsComponent implements OnInit {

  public subscription: Subscription;
  public appliedProjects = [];
  public appliedPageProjects = [];
  public page: Page;
  public user: User;
  @Input() isUser;
  @Input() isPage;
  loggedUser$:  Observable<any>;
  loggedUser: any;

  constructor( public pageService: PagesService,
     projectService: ProjectService,
       public receiverService: SenderService,
       private userService: UserService,
       public router: Router,
       public authService: AuthService,
       public cd: ChangeDetectionService,
    ) { }

  ngOnInit(): void {
    this.receiverService.dataUpdate$.subscribe((res: Page) => {
      this.page = res;
      this.cd.emmitChanges.subscribe(t => {
        if(!!t) {
          this.page = res;
        }
      })

    });

    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
        this.loggedUser = res;

        this.userService.getMessageList(this.authService.userID).subscribe(res => {
          this.appliedProjects = res[0].messages;
        })
        const pageID = localStorage.getItem("pageID");
        this.pageService.getPage(pageID).subscribe(res => {
          this.page = res.page;
          this.pageService.getMessagePageList(this.page._id).subscribe(res => {
            this.appliedPageProjects = res[0].messages;
          });
        });
    });





  }

  ngOnDestroy(): void {
    }
    createProject() {

        this.router.navigateByUrl(this.user?.userUrl+ "/projects");
    }
    goToMyProjects() {
        this.router.navigateByUrl(this.user?.userUrl + "/projects");

  }

  navigateToSaved() {

      this.router.navigateByUrl(this.user?.userUrl + "/projects/saved");

  }

}
