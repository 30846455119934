<div class="container">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Select your language</h5>
    </div>
    <div class="clearfix" style="display: flex; flex-direction: row">

      <div class="suggestionsGrid">
        <table class="localeList">
          <tbody>
            <tr>
              <td *ngFor="let lang of languages | slice: 0:4">
                <ul>
                  <li>
                    <div class="localeLink" (click)="setLanguage(lang.id)">
                      <a class="suggestedLocaleInGrid" [ngClass]="{'active': translateService.currentLang === lang.id}">
                        {{lang.description}}</a>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     <ul class="suggestionsHeader">
        <li>
          {{ "languagesPrimary" | translate }}
        </li>
      </ul>
    </div>
    <div class="divider"></div>

    <div class="clearfix modal-body">
      <div class="selectedRegion">
        <div>
      <table class="table-lang">
        <tbody>
          <tr>
            <td>
              <ul *ngFor="let languages of showLang | slice: 0:29">
                <li>
                  <div class="language-local" (click)="setLanguage(languages.id)">
                    <a role="button" tooltip="Info about the action" placement="right">
                      {{ languages.description }}
                    </a>
                  </div>
                </li>
              </ul>
            </td>

            <td>
              <ul *ngFor="let languages of showLang | slice: 29:58">
                <li>
                  <div class="language-local" (click)="setLanguage(languages.id)">
                    <a role="button" tooltip="Info about the action" placement="right">
                      {{ languages.description }}
                    </a>
                  </div>
                </li>
              </ul>
            </td>
            <td>
              <ul *ngFor="let languages of showLang | slice: 58:87">
                <li>
                  <div class="language-local" (click)="setLanguage(languages.id)">
                    <a role="button" tooltip="Info about the action" placement="right">
                      {{ languages.description }}
                    </a>
                  </div>
                </li>
              </ul>
            </td>
            <td>
              <ul *ngFor="let languages of showLang | slice: 87:120">
                <li>
                  <div class="language-local" (click)="setLanguage(languages.id)">
                    <a role="button" tooltip="Info about the action" placement="right">
                      {{ languages.description }}
                    </a>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
    <div class="float-right">
      <div>
        <div
          class="selected-intl-region-link1"
          id="intl-region-link-container"
        >
          <ul class="uiList mrm regionList">
            <li class="regionLink">
              <a
                class="selected-intl-region-link1"
                role="button"
                (click)="setRegion(0)"
                [ngClass]="{'active': selectedRegion === 0}"

                >All Languages</a
              >
            </li>
            <li class="regionLink">
              <a
                class="selected-intl-region-link2"
                role="button"
                [ngClass]="{'active': selectedRegion === 1}"

                (click)="setRegion(1)"
                >Africa and Middle East</a
              >
            </li>
            <li class="regionLink">
              <a
                class="selected-intl-region-link3"
                (click)="setRegion(2)"
                [ngClass]="{'active': selectedRegion === 2}"

                role="button"
                >Americas</a
              >
            </li>
            <li class="regionLink">
              <a
                class="selected-intl-region-link4"
                (click)="setRegion(3)"
                [ngClass]="{'active': selectedRegion === 3}"

                role="button"
                >Asia-Pacific</a
              >
            </li>
            <li class="regionLink">
              <a
                class="selected-intl-region-link5"
                (click)="setRegion(4)"
                [ngClass]="{'active': selectedRegion === 4}"

                role="button"
                >Eastern Europe</a
              >
            </li>
            <li class="regionLink">
              <a
                class="selected-intl-region-link6"
                (click)="setRegion(5)"
                [ngClass]="{'active': selectedRegion === 5}"
                role="button"
                >Western Europe</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
    <div class="modal-footer">
      <a role="button" class="button-footer" (click)="closeDialog()">{{
        "cancel" | translate
      }}</a>
    </div>
  </div>
</div>
