import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import { Model } from "src/app/shared/interfaces/model";
import { DataDialogComponent } from "../../modals/data-dialog/data-dialog.component";
import {
  EmptySubCategory,
  EmptyDataDialogComponent,
} from "../../modals/empty-data-dialog/empty-data-dialog.component";

@Component({
  selector: "app-personal-data-sub-dialog",
  templateUrl: "./personal-data-sub-dialog.component.html",
  styleUrls: ["./personal-data-sub-dialog.component.scss"],
})
export class PersonalDataSubDialogComponent implements OnInit {
  public careerSub = [
    {
      id: "newSub",
      name: "New Sub",
      description: "Empty Sub",
      icon: "fa fa-id-card",
    },
    {
      id: "space",
      name: "new Space",
      description: "space",
      icon: "fa fa-id-card",
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SubPersonalData,
    private dialogRef: MatDialogRef<PersonalDataSubDialogComponent>,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {}
  public addCategory(category, data: EmptySubCategory) {
    if (category.id === "newSub") {
      this.dialog.open(EmptyDataDialogComponent, {
        data,
      });
    } else if (category.id === "yourData") {
      this.dialog.open(DataDialogComponent, {
        data,
      });
    }
    return this.dialogRef.close(category);
  }
}

export interface SubPersonalData {
  model: Model;
  emptyObj?: boolean;
}
