<div class="container px-4 py-4">
  <form name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData(myForm)" class="form-grid">
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <h2>{{ "category.PersonalData" | translate }}</h2>
        </div>
      </div>
    </li>

    <div class="d-flex column">

    <li class="line form-row">
      <div class="col row col-md-12">

        <div class="col-md-6">
          <label>{{ "Gender:*" }}</label>
          <select class="col-md-12 form-control" id="gender" [(ngModel)]="user.gender" name="gender">
            <option *ngFor="let gender of genders" [ngValue]="gender.id" [selected]="gender">
              {{ gender.description | translate }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label>{{ "Title" }}</label>
          <select class="col-md-12 form-control" id="selectedTitle" [(ngModel)]="user.title" name="title">
            <option *ngFor="let title of titleService.titles" [ngValue]="title.id" [selected]="title ? title : titleService.titles[0]">
              {{ title.description | translate }}
            </option>
          </select>
        </div>
      </div>
    </li>
    <!-- First & Last name -->
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "data.firstName" | translate }}:*</label>
          <input class="form-control col-md-12" [(ngModel)]="user.firstName" name="firstName"
            autocomplete="off" #firstName="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && firstName.invalid }" required />
          <div *ngIf="myForm.submitted && firstName.invalid" class="invalid-feedback">
            <div *ngIf="firstName.errors.required">
              {{ "data.firstName" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label>{{ "data.lastName" | translate }}:*</label>
          <input class="form-control col-md-12" [(ngModel)]="user.lastName" name="lastName"
            autocomplete="off" #lastName="ngModel" #firstName="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && lastName.invalid }" required />
          <div *ngIf="myForm.submitted && lastName.invalid" class="invalid-feedback">
            <div *ngIf="lastName.errors.required">
              {{ "data.lastName" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "data.birthday" | translate }}:*</label>
          <label class="checkbox-inline margin5px">
            <input [(ngModel)]="data.personalData.showBirthday" [checked]="data.personalData.showBirthday"
              name="showBirthday" type="checkbox" value="" />
            {{ "data.show" | translate }}
          </label>
          <input type="date" class="form-control col-md-12" [ngModel]="user.birthday.date | date: 'yyyy-MM-dd'"
            (ngModelChange)="user.birthday.date = $event" name="birthday" #birthday="ngModel"  [ngClass]="{ 'is-invalid': myForm.submitted && birthday.invalid }" required/>
            <div *ngIf="myForm.submitted && birthday.invalid" class="invalid-feedback">
              <div *ngIf="birthday.errors.required">
                {{ "data.birthday" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
        </div>
        <div class="col-md-6">
          <label class="firstLabel">{{ "data.status" | translate }}</label>
          <input [(ngModel)]="data.personalData.showStatus" [checked]="data.personalData.showAddress" name="showStatus"
          type="checkbox" value="" />
        {{ "data.show" | translate }}

          <select class="col-md-12 form-control" id="status" [(ngModel)]="data.user.status" name="status">
            <option *ngFor="let status of statusObject" [ngValue]="status.id" [selected]="status">
              {{ status.description | translate }}
            </option>
          </select>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label class="firstLabel">{{ "data.nationality" | translate }}:*</label>
          <label class="checkbox-inline">
            <input [(ngModel)]="data.personalData.showNationality" [checked]="data.personalData.showNationality"
              name="showNationality" type="checkbox" value="" />
            {{ "data.show" | translate }}
          </label>
          <input class="form-control col-md-12" [(ngModel)]="user.nationality.data" name="nationality"
            autocomplete="off"  #nationality="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && nationality.invalid }" required/>
            <div *ngIf="myForm.submitted && nationality.invalid" class="invalid-feedback">
              <div *ngIf="nationality.errors.required">
                {{ "data.nationality" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
        </div>
      </div>
    </li>
    <!--   <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "data.telephone" | translate }}:*</label>
             <input type="text" pattern="[0-9]+" class="form-control col-md-12" [(ngModel)]="data.personalData.telephone"
            name="telephone" #telephone="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && telephone.invalid }" required/>
          <div *ngIf="myForm.submitted && telephone.invalid" class="invalid-feedback">
            <div *ngIf="telephone.errors">{{ "error.number" | translate }}</div>
          </div>

        </div>
      </div>
    </li>-->
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label class="firstLabel">{{ "data.telephone" | translate }}</label>
          <span class="checkbox-inline">
            <input [(ngModel)]="data.personalData.showTelephone"
             [checked]="data.personalData.showTelephone"
              name="showTelephone" #showTelephone
              type="checkbox" value="" />
            {{ "data.show" | translate }}
          </span>

          <ngx-intl-tel-input
          [enablePlaceholder]="true"
          [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']"
          [separateDialCode]="true"
          #phone="ngModel"
          name="phone"
          required
          [phoneValidation]="true"
          [(ngModel)]="data.user.phone[0].number"
          [selectedCountryISO]="data.user.phone[0].countryCode"
          [ngClass]="{ 'is-invalid': myForm.submitted && phone.invalid }" required
          [cssClass]="'form-control col-md-12'"
         >
        </ngx-intl-tel-input>
        <div *ngIf="myForm.submitted && phone.invalid" class="invalid-feedback">
          <div *ngIf="phone.errors.required">
            {{ "data.telephone" | translate }} {{ "error.isRequired" | translate }}
          </div>
        </div>
        </div>
      </div>

    </li>

    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label class="firstLabel">{{ "data.email" | translate }}:* </label>
          <span class="checkbox-inline">
            <input [(ngModel)]="data.personalData.showEmail" [checked]="data.personalData.showEmail" name="showEmail"
              type="checkbox" value="" />
            {{ "data.show" | translate }}
          </span>
          <input class="form-control col-md-12" autocomplete="off" [(ngModel)]="data.user.email[0].name"
            name="email" #email="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && email.invalid }" required />
          <div *ngIf="myForm.submitted && email.invalid" class="invalid-feedback">
            <div *ngIf="email.errors.required">
              {{ "data.email" | translate }} {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <br/>
    <div class="col row col-md-12">
      <span class="firstLabel">{{ "data.address" | translate }} </span>
      <span class="checkbox-inline secondLabel">
        <input [(ngModel)]="data.personalData.showAddress" [checked]="data.personalData.showAddress" name="showAddress"
          type="checkbox" value="" />
        {{ "data.show" | translate }}
      </span>
    </div>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{ "addressData.street" | translate }}</label>
          <input class="form-control col-md-12" autocomplete="off" [(ngModel)]="data.user.address.data[0].street"
            name="street" />
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-4">
          <label>{{ "data.zip" | translate }}:*</label>
          <input class="form-control col-md-12" [(ngModel)]="data.user.address.data[0].postalCode" #postalCode="ngModel"
            name="postalCode" [ngClass]="{ 'is-invalid': myForm.submitted && postalCode.invalid }"
            autocomplete="postal-code" required />
          <div *ngIf="myForm.submitted && postalCode.invalid" class="invalid-feedback">
            <div *ngIf="postalCode.errors.required">
              {{ "data.zip" | translate }} {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <label>{{ "data.city" | translate }}:*</label>
          <input class="form-control col-md-12" [(ngModel)]="data.user.address.data[0].city" name="city" #city="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && city.invalid }" required />
          <div *ngIf="myForm.submitted && city.invalid" class="invalid-feedback">
            <div *ngIf="city.errors.required">
              {{ "data.city" | translate }} {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "data.country" | translate }}:*</label>
          <select class="col-md-12 form-control" name="country" id="country"
            [(ngModel)]="data.user.address.data[0].c"  (ngModelChange)="onChange($event)" required>
            <option hidden value="" disabled selected>{{selectedCountry?.type}}</option>
            <option *ngFor="let country of languages" [value]="country.type" [selected]="selectedCountry"
              (change)="onChange(country)">
              {{ country.value }}
            </option>
          </select>

        </div>
        <div class="col-md-6">
          <label>{{ "data.state" | translate }}:*</label>
          <input class="form-control col-md-12" [(ngModel)]="data.user.address.data[0].state" name="state" #state="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && state.invalid }" required />
          <div *ngIf="myForm.submitted && state.invalid" class="invalid-feedback">
            <div *ngIf="state.errors.required">
              {{ "data.state" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </div>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">


    <mat-dialog-actions align="end">
      <button class="btn btn-secondary" mat-dialog-close mat-button>
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success me-1" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
  </div>
  </div>
</li>
  </form>
</div>
