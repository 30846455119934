<div class="row justify-content-center">
  <div class="card shadow-lg overflow-hidden border-0 px-0">
    <div class="card-body pt-1 width-500 px-0">
      <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="firstFormGroup" [editable]="editable" (ngSubmit)="onSubmit2()">
          <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel></ng-template>
              <li class="mat-form-field--inline">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label> {{ "register.firstNamePlaceHolder" | translate }} </mat-label>
                  <input matInput type="text" name="firstName" class="form-control col-md-12" formControlName="firstName"
                 required>

                </mat-form-field>
                <br>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label> {{ "data.lastName" | translate }} </mat-label>
                  <input matInput type="text" name="lastName" class="form-control col-md-12" formControlName="lastName" placeholder="{{'register.lastNamePlaceHolder' | translate }}"
                 required>
                 </mat-form-field>
                 <br>
                 <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label> {{ "data.email" | translate }} </mat-label>
                  <input matInput type="text" name="email" class="form-control col-md-12" formControlName="email" placeholder="{{'register.emailAddressPlaceHolder' | translate }}"
                 required>
                 <mat-error *ngIf="firstFormGroup.controls.email.errors?.invalid"><span style="cursor: pointer;" routerLink="/login">Email exist. Login</span></mat-error>
                 </mat-form-field>


                 <div appearance="fill" style="width: 100%;" class="mt-3 mb-3">
                  <mat-label> {{'data.telephone' | translate}} </mat-label>
                  <br>
                    <ngx-intl-tel-input
                    formControlName="telephone"
                    [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']"
                    [separateDialCode]="true"
                    required
                    matInput
                    name="telephone"
                    [enablePlaceholder]="true"
                    [phoneValidation]="true"
                    [cssClass]="'form-control col-md-12   mat-mdc-form-field-type-mat-input mat-form-field-appearance-fill mat-form-field-hide-placeholder mat-primary'"
                    [selectedCountryISO]="countryCode"
                   >
                  </ngx-intl-tel-input>
                </div>
                 <br>
                 <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label> {{ "loginPage.password" | translate }} </mat-label>
                  <input matInput type="password" name="password" class="form-control col-md-12" formControlName="password"
                 required>
                 <span style="font-size: 12px;"
                 class="text-danger"
                 *ngIf="firstFormGroup.get('password').invalid && firstFormGroup.get('password').dirty"
             >   <div *ngIf="firstFormGroup.get('password').required">{{'loginPage.password' | translate}} {{'error.isRequired' | translate}}</div>
             <div *ngIf="firstFormGroup.get('password').errors">{{'register.passMinimumCharactersPlaceHolder' | translate}}"</div></span>
                 </mat-form-field>
                 <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>{{ "Gender"}} </mat-label>
                  <mat-select id="remote" formControlName="gender" required>
                    <mat-option *ngFor="let gender of genders" [value]="gender.id" [selected]="gender">
                      {{ gender.description | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>{{'Birthday'}}</mat-label>
                  <input matInput [matDatepicker]="picker" [max]="maxDate"  formControlName="birthday">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

              </li>
              <div>
                <button mat-button  class="btn btn-light" (click)=" editable != editable; checkEmailorPhone()" >Next</button>
              </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup" [editable]="editable">
          <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel></ng-template>
            <li class="mat-form-field--inline">
                <div class="d-flex align-items-center justify-content-between pt-3 pb-3 " style="width:100%"><b>Private account</b><mat-slide-toggle ></mat-slide-toggle></div>

                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Branch</mat-label>
                  <mat-select formControlName="branch" required>
                    <mat-option *ngFor="let branch of branchesService.branches" [value]="branch.id" [selected]="branch">
                      {{branch.description | translate}}

                    </mat-option>

                  </mat-select>

                </mat-form-field>

                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>{{ "pagesInfo.categories" | translate }}</mat-label>
                  <mat-select formControlName="category" required>
                    <mat-option *ngFor="let category of categoryService.categories" [value]="category.id" [selected]="category">
                      {{category.name | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>{{ "categories.subCategories.name" | translate }}</mat-label>
                  <mat-select formControlName="subCategory" required>
                    <mat-option *ngFor="let subCategory of categoryService.categories[dataCat]?.subCategory" [value]="subCategory.id" [selected]="subCategory">
                      {{subCategory.name | translate}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label> {{ "pagesInfo.keywords" | translate }} </mat-label>
                  <div class="card__body d-flex column">
                    <div class="d-flex align-items-center card__body__list">
                      <div *ngFor="let sp of user.searchFilterSkills.searchSkillsOffer.data; let i = index"
                        class="d-flex justify-content-between pr-1">
                        <div class="card__text me-1">
                          {{ sp.name }}
                          <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeSP(i)"><i
                              class="fa fa-remove"></i></button>
                        </div>
                      </div>
                      <input matInput name="keyword" type="text" formControlName="keyword">
                        <button type="button" class="btn btn-primary btn-xs ms-3 btn-light-keyword" [disabled]="!secondFormGroup.get('keyword')?.value"
                        (click)="addKeyword()">
                        <i class="bi bi-plus fa-lg"></i></button>
                    </div>
                  <!--  <div formArrayName="skills">
                      <div *ngFor="let skillControl of skills.controls; let i = index">
                        <input matInput type="text" [formControlName]="i">
                        <button type="button" (click)="removeSkill(i)">Remove</button>
                      </div>
                    </div>
                    <button type="button" (click)="addSkill()">Add Skill</button>
                    <div *ngIf="secondFormGroup.get('skills').hasError('minItems') && secondFormGroup.get('skills').touched">
                      <span style="color: red;">At least one skill is required.</span>
                    </div> -->
                  </div>
                </mat-form-field>

      </li>
      <div>
        <button mat-button matStepperPrevious class="btn btn-light">Back</button>

        <button mat-button matStepperNext class="btn btn-light">Next</button>
      </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup" [editable]="editable">
          <form [formGroup]="thirdFormGroup">
            <ng-template matStepLabel></ng-template>
            <li class="mat-form-field--inline">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label> {{ "addressData.street" | translate }} </mat-label>
                <input matInput type="text" name="street" class="form-control col-md-12" formControlName="street"
               required>

              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label> {{ "data.zip" | translate }} </mat-label>
                <input matInput type="text" name="postalCode" class="form-control col-md-12" formControlName="postalCode"
               required>

              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label> {{ "data.city" | translate }} </mat-label>
                <input matInput type="text" name="city" class="form-control col-md-12" formControlName="city"
               required>

              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>{{ "data.country" | translate}} </mat-label>
                <mat-select id="remote" formControlName="c" required>
                  <mat-option *ngFor="let country of languages" [value]="country.type" [selected]="country">
                    {{ country.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label> {{ "data.state" | translate }} </mat-label>
                <input matInput type="text" name="state" class="form-control col-md-12" formControlName="state"
               >

              </mat-form-field>
            </li>
            <div>
              <button mat-button matStepperPrevious class="btn btn-light">Back</button>

              <button mat-button matStepperNext (click)="addData()" class="btn btn-light">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="fourthFormGroup">
          <form [formGroup]="fourthFormGroup">
            <ng-template matStepLabel></ng-template>
            <li class="mat-form-field--inline">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label> {{ "Key for Confirmation" }} </mat-label>
                <input matInput type="text" name="codeConfirmation" class="form-control col-md-12" formControlName="codeConfirmation"
               >

              </mat-form-field>

    </li>
    <div>

      <button mat-button [disabled]="!fourthFormGroup.get('codeConfirmation')?.value" (click)="confirmCode()" class="btn btn-light">Save</button>
    </div>

    </form>

        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>

</div>





