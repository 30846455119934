import {Injectable} from "@angular/core";
import {MessagesService} from "../services/messages.service";
import {Messages} from "../interfaces/message";
import {Socket} from "socket.io-client";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})

export class MessageHelperService {
  private socket: Socket;

  constructor(private messageService: MessagesService) {
  }

  createMessage(message): Observable<Messages> {
    return new Observable(observer => {
      this.messageService.createRequest(message).subscribe(() => {
        observer.next();
      })
    })
  }
  updateChat(id: string, chat: Messages) {
    return new Observable(observer => {
      this.messageService.updateMessages(id, chat).subscribe(() => {
        observer.next()
      })
    })
  }
  loadMsg(): Observable<any> {
    return new Observable<any>(observer => {
      this.messageService.getAllMsg().subscribe(result => {
        observer.next(result)
      })
    })
  }

  getMessages(toUserId, authId): Observable<any> {
      return new Observable<any>(observer => {
        this.messageService.getMessages(authId).subscribe(result => {
          observer.next(result);
        })
      })
  }
  getYourMessages(authId): Observable<any> {
    return new Observable<any>(observer => {
      this.messageService.getYourMessages(authId).subscribe(result => {
        observer.next(result);
      })
    })
  }

  update(authId, userId, messages): Observable<any> {
    return new Observable(observer => {
      this.messageService.updateMessages({emitter: authId, emitterId:userId}, messages).subscribe(res => {
        observer.next(res);
      })
    })
  }
}
