import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-friend-list',
  templateUrl: './friend-list.component.html',
  styleUrls: ['./friend-list.component.scss']
})
export class FriendListComponent implements OnInit {
  @Input() user: User;
  public image;
  public hasPhoto: boolean;

  constructor(
    private imageService: ImageService) { }

  ngOnInit(): void {

  }

}
