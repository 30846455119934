import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguagePageComponent } from './language-page.component';
import { ButtonCoinsModule } from '../button-coins/button-coins.module';



@NgModule({
  declarations: [
    LanguagePageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    ButtonCoinsModule
  ],
  exports: [
    LanguagePageComponent
  ]
})
export class LanguagePageModule { }
