<div class="card-item d-flex" *ngIf="!sidebar && !privacy && !contactExpect && !contactSpecific">

  <div class="card d-flex ms-1 me-1 mt-1 mb-1 align-items-center row">
    <div class="img_and_name" (click)="navigateToUser(contact)">

      <img *ngIf="hasPhoto" borderWidth="3" borderColor="#ffffff" [src]="this.image" ngx-gravatar
        [email]="'example@mail.com'" fallback="mp" size="60">
      <img *ngIf="!hasPhoto" borderWidth="3" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'"
        fallback="mp" size="60">
      <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>
      <div class="ms-2 show-friend-header">{{ page?.name }}</div>
    </div>

    <div class="">
      <a class="px-1" *ngIf="this.user">
        <button class="dropdown-toggle px-3 font-weight-600 btn btn-light" data-toggle="dropdown">
          <span class="fa fa-ellipsis-h"></span>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01">

          <a class="dropdown-item text-align-end" href="#"><i class="bi bi-star"></i><span
              class="span-padding-left10">Favorites</span></a>
          <a class="dropdown-item text-align-end" href="#"><i class="bi bi-archive width-40"></i><span
              class="span-padding-left10">Edit friend list</span></a>
          <a class="dropdown-item text-align-end" href="#"><span>Unfollow</span></a>
          <a class="dropdown-item text-align-end" (click)="deleteFriend(contact)"><i class="bi bi-person-x"></i><span
              class="span-padding-left10">Delete contact</span></a>

        </div>
      </a>
    </div>
  </div>
</div>
<div *ngIf="sidebar">
  <div class="img_name_from_sidebar" (click)="openUserChat(contact)">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
</div>
<div *ngIf="privacy && !contactSpecific && !contactExpect" class="privacy" (click)="removeFriend(friend)">

  <div class="img_name_from_sidebar" style="width: 430px">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
  <a class="remove_icon">
    <i class="bi bi-dash-circle"></i>

  </a>
</div>
<div *ngIf="contactExpectAdded" class="privacy" (click)="removeContactExpect(user)">

  <div class="img_name_from_sidebar" style="width: 430px">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
  <a class="remove_icon">
    <i class="bi bi-dash-circle"></i>

  </a>
</div>
<div *ngIf="isPage" class="privacy">

  <div class="img_name_from_sidebar" style="width: 430px">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
  <a class="remove_icon">
    <i class="bi bi-dash-circle"></i>

  </a>
</div>
<div *ngIf="contactExpect" class="privacy" (click)="removeContactExpect(user)">

  <div class="img_name_from_sidebar" style="width: 430px">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
  <a class="remove_icon">
    <i class="bi bi-dash-circle"></i>

  </a>
</div>

<div *ngIf="contactSpecific" class="privacy" (click)="specificFriendDelete(user)">

  <div class="img_name_from_sidebar" style="width: 430px">

    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
    <div class="ms-2 show-friend-header">{{ user?.firstName }} {{ user?.lastName }} </div>

  </div>
  <div class="control-group">
    <label class="control control-checkbox">
      <div class="control_indicator"></div>
    </label>

  </div>
</div>
<app-user-friend-chat *ngIf="userClicked" [friendData]="friendData" [contact]="contact" [selectedUser]="selectedUser"
  (removeChat)="closeChat()">

</app-user-friend-chat>
