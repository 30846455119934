import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";

@Component({
  selector: "app-star-rating",
  templateUrl: "./star-rating.component.html",
  styleUrls: ["./star-rating.component.scss"],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {
  @Input("rating") private rating = 3;
  @Input("starCount") public starCount = 5;
  @Output() private ratingUpdated = new EventEmitter();

  public ratingArr = [];

  constructor() {

  }

  ngOnInit() {
    for (let index = 0; index < this.starCount; index++) {
      this.ratingArr.unshift(index);
    }
  }
  onClick(rating: number) {
    this.ratingUpdated.emit(rating);
    return false;
  }

  showIcon(index: number) {
    if (this.rating >= index + 1) {
      return "star";
    } else {
      return "star_border";
    }
  }
}

