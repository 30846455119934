import { NgModule } from "@angular/core";
import { NotificationsComponent } from "./notifications.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NotificationsListComponent } from "./notifications-list/notifications-list.component";
import { GravatarModule } from "ngx-gravatar";
import { NotificationsObjComponent } from './notifications-obj/notifications-obj.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationsListComponent,
    NotificationsObjComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    GravatarModule
  ],
  exports: [
    NotificationsComponent,
    NotificationsListComponent,
    NotificationsObjComponent
  ]
})

export class NotificationsModule {

}
