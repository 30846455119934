import { Injectable } from '@angular/core';
import { Settings } from 'http2';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SenderService {
  public webView = false;


  constructor() { }

  changeDataSource = new BehaviorSubject<Object>({});
  changeModelSource = new BehaviorSubject<Object>({});
  changeDataForApply = new BehaviorSubject<Object>({});
  changeDataForProject = new BehaviorSubject<Object>({});
  dataMessageForProject = new BehaviorSubject<Object>({})
  dataMessageForJob = new BehaviorSubject<Object>({});
  paymentData = new BehaviorSubject<any>({});
  adsData = new BehaviorSubject<any>({})
  jobID = new BehaviorSubject<string>("");
  manageJobID = new BehaviorSubject<Object>({});
  manageProjectID = new BehaviorSubject<any>(1);
  issueData = new BehaviorSubject<Object>({})

  public changeFriendsSource = new BehaviorSubject<Object>({});
  public changeUserData = new BehaviorSubject<Object>({});

  public pageData = new BehaviorSubject<Object>({});

  public dataUpdate$ = this.changeDataSource.asObservable();
  public modelUpdate$ = this.changeModelSource.asObservable();
  public friendsUpdate$ = this.changeFriendsSource.asObservable();
  public userData$ = this.changeUserData.asObservable();
  public dataForProject$ = this.changeDataForProject.asObservable();
  public dataJobID$ = this.jobID.asObservable();
  public dataManageJobID$ = this.manageJobID.asObservable();
  public dataManageProjectID$ = this.manageProjectID.asObservable()
  public paymentData$ = this.paymentData.asObservable();
  public issueData$ = this.issueData.asObservable();
  public dataForApply$ = this.changeDataForApply.asObservable();
  public adsData$ = this.adsData.asObservable();



  public issueDataVis = (data) => {
    this.issueData.next(data);
  }

  public adsDataAds = (data) => {
this.adsData.next(data)
  }

  public paymentDataVisibility = (data) => {
    this.paymentData.next(data);
  }
  public dataVisibility = (dataInfo: {data: Object, id?: string}) => {
    this.changeDataSource.next(dataInfo);
  }
  public modelVisibility = (modelInfo: Object) => {
    this.changeModelSource.next(modelInfo);
  }
  public dataForProject = (dataInfo: Object) => {
    this.changeDataForProject.next(dataInfo);
  }
  public projectForMessage = (data: Object) => {
    this.dataMessageForProject.next(data);
  }

  public jobForMessage = (data: Object) => {
    return this.dataMessageForJob.next(data);
  }
  dataForApply = (data: Object) => {
    this.changeDataForApply.next(data);
  }

  public dataJobID = (jobID: string) => {
    this.jobID.next(jobID);
  }

  public dataManageJobID = (data: {ID: string, isJob?: boolean}) => {
    this.manageJobID.next(data)
  }

  public dataManageProjectID = (data: {ID: string, isProject: boolean}) => {
    this.manageProjectID.next(data)
  }
  public userDataVisibility = (modelInfo: Object) => {
    this.changeUserData.next(modelInfo);
  }

  public friendsVisibility = (friendsInfo: Object) => {
    this.changeFriendsSource.next(friendsInfo);
  }

  public pageData$ = (data: Object) => {
    this.pageData.next(data);
  }

  public send(data?) {
    return this.dataVisibility(data);
  }
  public sendDataForProject(data) {
  return this.dataForProject(data);
  }

  public sendProjectForMessage(data) {
    return this.projectForMessage(data);
  }

  public sendJobForMessage(data) {
    return this.jobForMessage(data)
  }

  public sendDataForApply(data) {
    return this.dataForApply(data);
  }

  public sendModel(data) {
    return this.modelVisibility(data);
  }

  public sendFriends(data) {
    return this.friendsVisibility(data);
  }

  public sendUserData(data) {
    return this.userDataVisibility(data);
  }

  public sendPageData(data) {
    return this.sendPageData(data);
  }

  public set setWeb(w: boolean) {
    this.webView = w;
  }
  public get web() {
    return this.webView;
  }
}
