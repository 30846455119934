import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CropperComponent } from "angular-cropperjs";
import { Observable, Subscription } from "rxjs";
import { EditCoverImage, UserCoverDialogComponent } from "src/app/components/modals/user-dialogs/user-dialog-picture/user-cover-dialog/user-cover-dialog.component";
import { EditUserDialog, UserDialogPictureComponent } from "src/app/components/modals/user-dialogs/user-dialog-picture/user-dialog-picture.component";
import { Model, Image, Contact, UserContact, Page, Job, Project } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ImageService } from "src/app/shared/services/image.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { debounceTime, distinctUntilChanged, filter, first, shareReplay, take, takeUntil } from "rxjs/operators";
import { UserHelperService } from "src/app/shared/utils/user-helper.service";
import { Subject } from "rxjs";
import { FriendService } from "src/app/shared/services/friend.service";
import _ from "underscore";
import { UserService } from "src/app/shared/services/user.service";
import { Status, Groups, MaritualStatus, Relationsship, StatusTitle, Privacy, StatusFollow, StatusPrivacy } from "src/app/shared/enums/enums-model";
import { environment } from "src/assets/environments/environment";
import { HttpClient } from "@angular/common/http";
import { PrivacyActivity } from "src/app/shared/services/privacy-activity.service";
import { EditPrivacyDialog, PrivacyDialogComponent } from "src/app/components/privacy-dialog/privacy-dialog.component";
import { Cities, SocialLinks, User, Websites } from "src/app/shared/interfaces/user.model";
import { PagesService } from "src/app/shared/services/pages.service";
import * as moment from "moment";
import { SubCareerDialogComponent } from "src/app/components/career/sub-career-dialog/sub-career-dialog.component";
import { DataDialogComponent, EditCareer } from "src/app/components/modals/data-dialog/data-dialog.component";
import { EmptySubCategory } from "src/app/components/modals/empty-data-dialog/empty-data-dialog.component";
import { EditEducation, EducationDialogComponent } from "src/app/components/modals/education-dialog/education-dialog.component";
import { EditOffer, OfferDialogComponent } from "src/app/components/modals/editors/offer-dialog/offer-dialog.component";
import { EditDriveLicense, DriveLicenseDialogComponent } from "src/app/components/modals/editors/drive-license-dialog/drive-license-dialog.component";
import { EditPCKnowledge, PcKnowledgeDialogComponent } from "src/app/components/modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component";
import { EditLanguage, LanguageDialogComponent } from "src/app/components/modals/editors/language-dialog/language-dialog.component";
import { QualificationsDialogArrayComponent } from "src/app/components/modals/editors/qualifications-dialog-array/qualifications-dialog-array.component";
import { EditQualifications, QualificationsDialogComponent } from "src/app/components/modals/editors/qualifications-dialog/qualifications-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { ReligionComponent } from "src/app/components/modals/religion/religion.component";
import { CategoryService } from "src/app/shared/services/category.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ConfirmationDialogComponent } from "src/app/components/modals/confirmation-dialog/confirmation-dialog.component";
import { ProjectService } from "src/app/shared/services/project.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild("angularCropper", { static: false }) public angularCropper: CropperComponent;
  public data: Model;
  public data$: Observable<Model>;
  public subscriptions: Subscription;
  public routed: Subscription;
  public image;
  public user: User;
  public authUser: User;
  public hasPhoto: boolean;
  public hasCoverPhoto: boolean;
  imgUrl;
  imageCrop;
  groups: typeof Groups = Groups;
  public coverImage;
  public editCoverImage = false;
  public userSettings = false;
  public friendRequest = false;
  unsubscribe = new Subject<void>();
  noOfConnections: Number = 0;
  contacts: UserContact[] = [];
  followers: UserContact[] = [];
  checkFriend: User[] = [];
  public userUrl;
  followersLength = 0;
  followingLength = 0;
  showPublic = false;
  showFriends = false;
  checkFriendId = false;
  friendExpect = false;
  onlyMe = false;
  showPersonalDataFriends = false;
  showPersonaDataPublic = false;
  friendExpectPersonalData = true;
  showOnlyFollowers = false;
  showEverything = true;
  followingArray: User;
  followersArray: User;
  contactsArray: User;
  religion;
  public getStatus;
  isEditingSocialLink: "";
  isEditingWebsite: "";

  relationshipStatus;
  cityAdd: false;
  hometownAdd: false;
  addressAdd: false;
  hometown: string;
  currentAddres: string
  pageActive = "";
  pageSelected;
  selectedPageId;
  showMonths;
  showDays;
  isEditingRelationship: boolean = false;
  isEditingFamilyMember: string;
  addSocialLinkUrl = "";
  addWebsiteUrl = "";

  public emailIcon;
  public emailText;

  users;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;

  public months = moment.months();
  public days = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31",]
  languages = [];
  branchTitle;
  categoryTitle
  subCategoryTitle
  searchForUser = "";
  newFamilyMember;
  newSocialLink;
  newWebsite;

  selectedUser: User;
  public getGender;


  searchForPage;
  searchForPageTrue

  fallbacks = ["mp"];



  public cities = new Cities();
  public socialLinks = new SocialLinks;
  public website = new Websites;

  public imgConfig = {
    aspectRatio: 3 / 4,
    dragMode: "move",
    background: true,
    movable: true,
    rotatable: true,
    scalable: true,
    zoomable: true,
    viewMode: 0,
    checkImageOrigin: false,
    checkCrossOrigin: false,
    width: 300,
    height: 400,
    crop: () => {

      this.image = this.angularCropper.cropper.getCroppedCanvas().toDataURL("image/jpeg", 90);
    }
  };
  public pages: Page[] = [];

  public housingTypeOptions = [
    { id: StatusTitle.Mr, description: "data.mr" },
    { id: StatusTitle.Mrs, description: "data.mrs" },
  ];

  outsideUserId;
  matchingElement;
  contactsSize = 0;
  hasFollow = false;
  isPending = false;
  isPendingFollow = false;
  subscription: Subscription;
  years;

  usersArray: User[];
  userData: any;

  loggedUser: any;

  public isFriend = false;
  public isRequest = false;
  public statusObject = [
    { id: 1, name: MaritualStatus.Single, description: "status.single" },
    { id: 2, name: MaritualStatus.Married, description: "status.married" },
    { id: 0, name: MaritualStatus.NotKnow, description: "status.nk" }
  ];
  public projectID;
  public jobID;

  public project = new Project();
  public job = new Job();

  public dataList$: Observable<any>;

  userFollowers$: User[];
  userFollowing$: Observable<User[]>;
  userContacts$: Observable<User[]>;
  private searchText$ = new Subject<string>();
  pages$: Observable<Page[]>;
  loggedUser$:  Observable<any>;

  constructor(
    public router: Router,
    public authService: AuthService,
    public imageService: ImageService,
    public dialog: MatDialog,
    public receiver?: SenderService,
    public userHelper?: UserHelperService,
    public friendService?: FriendService,
    public sendData?: SenderService,
    public cd?: ChangeDetectorRef,
    public userService?: UserService,
    private http?: HttpClient,
    public privacyActivity?: PrivacyActivity,
    public pageService?: PagesService,
    public translateService?: TranslateService,
    public arrayService?: ArraysService,
    public categoryService?: CategoryService,
    public branchService?: BranchesService,
    public projectService?: ProjectService,
    public jobService?: JobsService
  ) {
    this.arrayService.showReligions();
    this.categoryService.getCategory()
    this.categoryService.getCategories();
    this.branchService.getBranches();
    this.arrayService.showRelationsship();
    this.arrayService.showFamilyMembers();
    this.arrayService.showSocialLinks();
    this.arrayService.getPrivacyArray();
    this.searchText$.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.pageService.search(value).subscribe();
        this.pages$ = this.pageService.loadPages;
        this.searchForPageTrue = true
      });



    if (this.router.url.includes('publicated/projects/')) {
      this.projectID = this.router.url.split('/').pop();
      console.log(this.projectID)
    } else if (this.router.url.includes('publicated/jobs/')) {
      this.jobID = this.router.url.split('/').pop()
    }


  }
  ngAfterViewInit(): void {
    console.log(this.projectID, "proj id")
  }

  ngOnInit(): void {

    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
        this.loggedUser = res;
    })





    /*   this.pageService.allPages.subscribe(res => {
         this.pages = res;
       }); */




    if (!!this.projectID) {
      this.projectService.getProject(this.projectID).subscribe(res => {
        this.project = res;
      })

    }

    if (!!this.jobID) {
      this.jobService.getJob(this.jobID).subscribe(res => {
        this.job = res;
      })
    }

    const searchProfile = localStorage.getItem("userSearchID");
    if (searchProfile) {
      this.userService.getUserCV(searchProfile).subscribe();


      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        //   this.privacyIcon = this.loggedUser;
        //  this.privacyText = this.loggedUser;

        this.userData = res;
        if (!!res) {
          if (res.userUser.followers.counter) {
            this.followersLength = res.userUser.followers.counter;
          }
        }

        this.loggedUser.followings.data.filter((r) => {
          this.hasFollow = r.userId === this.userData?.user._id && r.s === 1 || r.s === 2;
          this.isPendingFollow = r.userId === this.userData?.user._id && r.s === 0;
        });

       // this.isPendingFollow = this.loggedUser.followings.data.map(t => t.userId === this.userData.user._id && t.s === 0 );


        this.getBranch(this.userData.user?.branches);

        this.getCategory(this.userData.user?.categories);
        this.privacyIcon = this.userData.user;
        this.privacyText = this.userData.user;
        this.getIdOfGender(this.userData?.user?.gender);
        this.getReligion(this.userData?.userUser?.religion?.name);

        this.getSubCategoryTitle(this.userData.user?.categories.name);
        this.getRelationship(this.userData?.userUser?.relationship?.s);
      });

      this.http.get<any>(`assets/languages/${this.userData.user.language.primary}/language.json`).subscribe(data => {
        this.translateService.use(this.userData.user.language.primary);
        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
      })

    } else {
      this.loggedUser$ = this.userService.userList;
      this.loggedUser$.subscribe((res) =>{
        this.userData = res;
        this.loggedUser.followings.data.filter((r) => {
          this.hasFollow = r.userId === this.loggedUser?._id && r.status === 1 || r.status === 2;
          this.isPendingFollow = r.userId === this.loggedUser?._id && r.status === 0;

        })
      })
    }





    /*  this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        this.loggedUser = res.user;
        this.user = res.user;
     //   this.privacyIcon = this.loggedUser;
      //  this.privacyText = this.loggedUser;

      });


     /* this.userService.getUserContactData(this.authService.userID).subscribe(res => {
        this.loggedUser = res;
        this.privacyIcon = this.loggedUser;
        this.privacyText = this.loggedUser;
        this.users = res.following.data.filter((item, index, self) => self.indexOf(item) === index);
        res?.following?.data?.filter((r) => {

          this.hasFollow = r.userId === this.loggedUser?._id && r.status === 1 || r.status === 2;

        })
      }) */

    /* this.userService.getUserData(localStorage.getItem("userProfileID")).pipe(shareReplay(1)).subscribe((res: User) => {

       this.user = res;
       res?.following?.data?.filter((r) => {
         this.hasFollow = r.userId === this.loggedUser?._id && r.status === 1 || r.status === 2;
         this.isPendingFollow = r.userId === this.loggedUser?._id && r.status === 0;

       })
      /* this.userService.showFollowers(this.user._id).subscribe((users: User[]) => {
         this.userFollowers$ = users
       });
      // this.userFollowing$ = this.userService.showFollowing(this.user._id);
       this.userContacts$ = this.userService.showContacts(this.user._id);



    /*   this.getIdOfGender(this.user?.gender);
       this.getReligion(this.user?.religion?.name);
       this.getBranch(this.user?.branches);

       this.getCategory(this.user?.categories.name);
       this.getSubCategoryTitle(this.user?.categories.name);
       this.getRelationship(this.user?.relationship.status);



       this.http.get<any>(`assets/languages/${this.user.language.primary}/language.json`).subscribe(data => {
         this.translateService.use(this.user.language.primary);

         this.languages = data;
         this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));

       })

       this.contactsSize = res?.contacts?.data?.length;
       this.contacts = this.user?.contacts.data;
       this.followingLength = res.following?.data?.length;
       this.followersLength = res.followers.counter;
       this.userService.getUserContactData(this.authService.userID).subscribe(res => {
         this.loggedUser = res;
         const userData = this.loggedUser.following.data.find(t => t.userId === this.user._id);
         this.hasFollow = userData?.userId === this.loggedUser?._id && userData?.status === 1 || userData?.status === 2;

         this.isPendingFollow = userData?.status === 0;
       })

       res?.contacts?.data?.filter(res => {
         // tslint:disable-next-line: max-line-length
         this.isFriend = res.status === Status.Accepted && res.contactId === this.user?._id;
         // tslint:disable-next-line: max-line-length
         this.isRequest = res.status === Status.Sent && res.contactId === this.user?._id;
         this.isPending = res.status === Status.Pending && res.contactId === this.user?._id;
       });
     }); */

    if (this.authService.isLoggedIn) {
      this.privacyActivity.getPrivacyActivity();

      // this.checkPrivacy(this.user?._id, this.user);
      //this.checkPrivacyFollowing(this.user?._id, this.user);
      // this.checkPrivacyFollowers(this.user?._id, this.user)
      // tslint:disable-next-line: max-line-length
    }
  }

  getUserFollowing() {

  }

  ngOnDestroy() {
    this.userSettings = false;
  }
  refreshPage() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url = event.url.replace("/start", "");
        event.url = event.url.replace("/data/", "");
        event.url = event.url.replace("/contacts", "");
        event.url = event.url.replace("/", "");
        event.url = event.url.replace("/settings/account", "");
        event.url = event.url.replace("/settings/notification", "");
        event.url = event.url.replace("/published/jobs", "");
        this.outsideUserId = event.url;
        /*  this.userHelper.getUserData().pipe(shareReplay(1)).subscribe(res => {
            this.user = res.data;
            this.contactsSize = res?.contactsLength;
            this.contacts = this.user?.contacts.data;
            this.userService.getUserData(this.authService.userID).subscribe((res: User) => {
              res.following.data.filter(r => {
                this.hasFollow = r.userId === this.loggedUser?._id && r.status === 1 || r.status === 2;

                this.isPendingFollow = r.userId === this.user?._id && r.status === 0;


              })
              res?.contacts.data.filter(res => {
                // tslint:disable-next-line: max-line-length
                this.isFriend = res.status === Status.Accepted && res.contactId === this.user?._id;
                // tslint:disable-next-line: max-line-length
                this.isRequest = res.status === Status.Pending && res.contactId === this.user?._id;
              });
            })
            // this.checkPrivacy(this.user._id, this.user);
            // this.checkPrivacyFollowing(this.user._id, this.user);
            //  this.checkPrivacyFollowers(this.user._id, this.user)

            // tslint:disable-next-line: max-line-length
          }); */
      }
    });

  }



  ngOnChanges() {
    if (this.data.profilePicture) {
      this.imageService.getImageByID(this.data.profilePicture).subscribe((res: Image) => {
        if (res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    } else if (this.data.profilePicture === undefined) {
      return;
    }
    if (this.data.coverPicture) {

      this.imageService.getImageByID(this.data.coverPicture).subscribe((res: Image) => {
        if (res.imageUrl) {
          this.coverImage = res.imageUrl;
          this.hasCoverPhoto = true;
        }
      });
    } else if (this.data.coverPicture === undefined) {
      return;
    }

  }
  fileChangeEvent(event: any): void {
    this.imgUrl = URL.createObjectURL(event.target.files[0]);
    this.imageCrop = event.target.files[0];
  }
  openProfileImgDialog(data: EditUserDialog) {
    this.dialog.open(UserDialogPictureComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (res === undefined) { }
      this.imageService.getImageByID(res.model.profilePicture).subscribe((img: Image) => {
        if (img.imageUrl) {
          this.image = img.imageUrl;
        }
      });
    });
  }
  loadActiveUserConnections(userId: string) {
    this.userService.getUserData(userId);
    this.userService.user().subscribe(res => {
      this.noOfConnections = res.contacts.data.filter(t => t.status === Status.Accepted).length;
    });
  }

  sendMessage(event) {
  }

  unfollowUser() {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.componentInstance.message = "You will no longer receive notifications or see relevant updates in your feed";
    this.confirmationDialog.componentInstance.header = "Unfollow";
    this.confirmationDialog.componentInstance.confirmButtonText = "Unfollow";
    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {
          const userFollowing = user.following.data.findIndex(t => t.userId === this.user._id);
          user.following.data.splice(userFollowing, 1);
          user.following.data.filter(t => {
            this.hasFollow = t.userId === this.user._id
          });
          this.userService.updateUser(user._id, user);
        });
        this.user.followers.counter = this.user.followers.counter - 1;
        this.userService.updateUser(this.user._id, this.user);

      }
    });

  }

  followContact() {
    const following = {
      userId: this.userData.user?._id,
      timestamp: new Date(),
      s: StatusFollow.Public
    }
    const followingWithPrivacy = {
      userId: this.userData.user?._id,
      timestamp: new Date(),
      s: StatusFollow.Private
    }
    this.friendRequest = !this.friendRequest;


    if (this.userData.user.pAccount === true) {
      this.loggedUser.followings.data.unshift(followingWithPrivacy);
      this.loggedUser.followings.counter = this.loggedUser.followings.counter + 1;
      this.userData.userUser.followers.counter = this.user.followers.counter + 1;
    } else {
      this.userData.userUser.followers.counter = this.user.followers.counter + 1;
      this.loggedUser.followings.counter = this.loggedUser.followings.counter + 1;
      this.loggedUser.followings.data.unshift(following);
    }



    this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);
    this.userService.updateUser(this.user._id, this.user);
  }



  uploadCoverPhoto(data: EditCoverImage) {
    this.dialog.open(UserCoverDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (res === undefined) { }
      this.imageService.getImageByID(res.model.coverPicture).subscribe((img: Image) => {
        if (img.imageUrl) {
          this.coverImage = img.imageUrl;
        }
      });
    });;
  }
  repositionCoverPhoto() {
    this.editCoverImage = true;
  }
  cancelReposition() {
    this.editCoverImage = false;
  }
  saveReposition() {
    this.editCoverImage = false;
  }

  navigateSettings() {
    this.userSettings = true;
  }

  search(packageName: string) {
    this.searchText$.next(packageName);
  }


  public get checkUser() {
    return this.authService.userID === this.userData.user?._id;
  }

  follow() {

  }

  isLoggedIn() {
    return this.authService.isLoggedIn;
  }

  navigateToCV(id) {

    if (this.userData.user?.userUrl) {
      this.userUrl = this.userData.user?.userUrl;
    } else {
      this.userUrl = this.userData.user?._id;

    }
    this.router.navigateByUrl(this.userUrl + "/cv");
  }

  addContact() {
    const friendRequestObject = {
      contactId: this.user._id,
      status: Status.Sent,
      createdDate: new Date()
    }
    const myFriendObject = {
      contactId: this.authService.userID,
      status: Status.Pending,
      timestamp: new Date()
    }

    const follower = {
      contactId: this.authService?.userID,
      timestamp: new Date()
    }
    const following = {
      contactId: this.user?._id,
      timestamp: new Date()
    }
    this.hasFollow = true;

    this.friendRequest = !this.friendRequest;
    const api = `${environment.backend}/user/${this.authService.userID}`
    this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {
      user.contacts.data.unshift(friendRequestObject);
      if (!user.following.data.some((item) => item.userId === following.contactId)) {
        user.following.data.unshift(following);
      }
      this.http.put<any>(api, user).subscribe((res) => {
      })
    });
    const apiUser = `${environment.backend}/user/${this.user?._id}`
    this.userService.getUserContactData(this.user?._id).subscribe((user: User) => {
      user.contacts.data.unshift(myFriendObject);

      this.http.put<any>(apiUser, user).subscribe((res) => {
      })
    });

    this.isRequest = true;
  }

  addPrivacy(data: EditPrivacyDialog, privacy: string) {
    if (data.enum === Groups.Contacts_except || data.enum === Groups.Contacts_specific) {
      return this.dialog.open(PrivacyDialogComponent, {
        data,
        panelClass: "my-class"
      })
    } else {
      if (privacy === "following") {
        this.user.following.p = data.enum;
      } else if (privacy === "followers") {
        this.user.followers.p = true;
      } else if (privacy === "contacts") {
        this.user.contacts.p = data.enum;
      }
      this.userService.updateUser(this.user._id, this.user);
    }
  }

  get isSameUser() {
    return this.authService.userID === this.user?._id;
  }

  checkPrivacy(userID, user?: User) {
    switch (user?.contacts.p) {
      case this.groups.Contacts:
        this.showFriends = true;
        break;
      case this.groups.Public:
        this.showPublic = true;
        break;
      case this.groups.Contacts_specific:
        this.checkIfUserIsAtSpecific(userID);
        break;
      case this.groups.Contacts_except:
        this.checkIfUserIsAtExpect(userID);
        break;
      case this.groups.OnlyMe:
        this.onlyMe = this.authService.userID === this.user._id;
        break;
      case 6:
        break;
      default:
        this.showEverything = true;
        break;
    }
  }

  checkPrivacyFollowing(userID, user?: User) {
    switch (user?.following.p) {
      case this.groups.Contacts:
        this.showFriends = true;
        break;
      case this.groups.Public:
        this.showPublic = true;
        break;
      case this.groups.Contacts_specific:
        this.checkIfUserIsAtSpecific(userID);
        break;
      case this.groups.Contacts_except:
        this.checkIfUserIsAtExpect(userID);
        break;
      case this.groups.OnlyMe:
        this.onlyMe = this.authService.userID === this.user._id;
        break;
      case 6:
        break;
      default:
        this.showEverything = true;
        break;
    }
  }
  checkPrivacyFollowers(userID, user?: User) {

  }
  checkIfUserIsAtExpect(userId: string) {
    this.user?.contactExpect.some(t => {
      if (t.contactId === userId) {
        this.friendExpect = false;
      }
    });
  }
  checkIfUserIsAtSpecific(userId: string) {
    this.user?.specificContacts.filter(t => {
      if (t.contactId === userId) {
        this.checkFriendId = true;
      }

    });
  }


  navigateToProjects(event) {
    if (this.user?.userUrl) {
      this.userUrl = this.user?.userUrl;
    } else {
      this.userUrl = this.user?._id;

    }
    this.router.navigateByUrl(this.userUrl + "/projects");

  }

  addHomeTown() {
    if (this.selectedPageId) {
      this.userData.userUser.places.hometown = {} as any;
      this.userData.userUser.places.hometown.pageID = this.selectedPageId;
      this.userData.userUser.places.hometown.p = 7;
    } else {
      this.selectedPageId = "";
      this.userData.userUser.places.hometown = {} as any;
      this.userData.userUser.places.hometown.p = 7;
      this.userData.userUser.places.hometown.name = this.searchForPage;
    }

    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
  }


  addCity() {
    if (this.selectedPageId) {
      this.userData.userUser.places.cities.unshift({ pageID: this.selectedPageId, date: new Date(), p: 7 });

    } else {
      this.selectedPageId = "";
      this.userData.userUser.places.cities.unshift({ name: this.searchForPage, date: new Date(), p: 7 })
    }
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
    this.selectedPageId = "";

  }
  addCompanyCity(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    this.selectedPageId = page._id;
    if (page.name) {
      this.searchForPage = page.name;
    }

    this.searchForPageTrue = false;
  }

  addAddres() {
    if (this.selectedPageId) {
      this.userData.userUser.places.current = {} as any;

      this.userData.userUser.places.current.pageID = this.selectedPageId;
      this.userData.userUser.places.current.p = 7;

    } else {
      this.selectedPageId = "";
      this.userData.userUser.places.current = {} as any;
      this.userData.userUser.places.current.name = this.searchForPage;
      this.userData.userUser.places.current.p = 7;
    }

    console.log(this.userData.userUser.places.current)

    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
  }

  deleteNameOfHomeTown() {
    this.searchForPage = "";
    const { name: remove, ...rest } = this.user.places.hometown;
    this.user.places.hometown = rest;

    this.hometownAdd = false;
    this.selectedPageId = false;
    this.userService.updateUser(this.user._id, this.user);
  }



  addNewCompany(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    this.selectedPageId = page._id;
    if (page.name) {
      this.searchForPage = page.name;
    }

    this.searchForPageTrue = false;
  }



  editPage() {

  }



  addCompanyAddress(page: Page) {
    this.pageSelected = true;
    this.pageActive = page.name;
    this.selectedPageId = page._id;
    if (page.name) {
      this.searchForPage = page.name;
    }

    this.searchForPageTrue = false;
  }

  addYear(event) {
    if (event !== "Year") {
      this.showMonths = true;
    }
  }

  addMonth(event) {
    if (event !== "Month") {
      this.showDays = true;
    }
  }

  addDay() {

  }

  addWork(data: EmptySubCategory) {
    this.dialog.open(DataDialogComponent, {
      data,
    });
  }

  public addEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, {
      data,
    });
  }

  addOffer(data: EditOffer) {
    this.dialog.open(OfferDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.userData.cv.data[0].skills.subCategories[0].skillsOffer = res;
        this.cd.detectChanges();
      }
    });
  }

  editPCKnowledge(data: EditPCKnowledge) {
    this.dialog.open(PcKnowledgeDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.user.cv[0].skills[0].subCategories[0].pcKnowledge = res.pcKnowledge;
        this.cd.detectChanges();
      }
    });
  }
  editDriveLicense(data: EditDriveLicense) {
    this.dialog.open(DriveLicenseDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.user.cv[0].skills[0].subCategories[0].driveLicenses = res.driveLicenses;
        this.cd.detectChanges();
      }
    });
  }

  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }
  editLanguages(data: EditLanguage) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.userData.cv[0].skills.subCategories[0].languages = res.languages;
        this.userService.updateUser(this.authService.userID, this.user)
        this.cd.detectChanges();
      }
    });
  }
  editQualifications(data: EditQualifications) {

    if (this.userData.cv.data[0]?.skills[0]
      ?.qualifications.data.length > 0) {

      this.dialog.open(QualificationsDialogArrayComponent, {
        data,
      }).afterClosed().subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.user.cv[0].skills[0].subCategories[0].qualifications = res.qualifications
          this.userService.updateUser(this.authService.userID, this.user)
          this.cd.detectChanges();
        }
      });
    } else {
      this.dialog.open(QualificationsDialogComponent, {
        data,
      }).afterClosed().subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.user.cv[0].skills[0].subCategories[0].qualifications = res.qualifications
          this.userService.updateUser(this.authService.userID, this.user)
          this.cd.detectChanges();
        }
      });
    }

  }






  public getIdOfGender(id) {
    this.getGender = this.housingTypeOptions.find(test => test.id === id)?.description;
  }
  getReligion(id) {
    this.religion = this.arrayService.religions.find(t => t.id === id);
  }

  addReligion(data) {
    this.dialog.open(ReligionComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      console.log(res)
    });
  }

  getBranch(id) {
    this.branchTitle = this.branchService.branches.find(t => t.id === id)?.description;
  }
  getCategory(id) {
    if (!!id) {
      return this.categoryTitle = this.categoryService.categories.find(t => t.id === id)?.name;
    }
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.userData.user.categories.subCategory.find(t => t.name);
    this.subCategoryTitle = t.subCategory.find(rtt => rtt.id === tt.name).name;
  }
  public get checkLoginID() {
    return this.userData.user._id === this.authService.userID;
  }

  getIdOfStatus(id) {
    this.getStatus = this.statusObject.find(t => t.id === id)?.description;
  }

  getRelationship(id) {
    this.relationshipStatus = this.arrayService.relationsship.find(t => t.id === id);
  }

  editRelationship() {
    this.isEditingRelationship = true;
  }

  editFamilyMember(index) {
    this.isEditingFamilyMember = index;
  }

  deleteFamilyMember(index) {
    this.user.familyMembers.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user)
  }

  changeRelationship(event) {
    this.user.relationship.s = event;
  }

  addSearchedUser(user) {
    this.selectedUser = user;
    this.searchForUser = `${user.firstName} ${user.lastName}`;

  }

  saveUserRelationship(form) {
    const selectedDate = form.controls[("relationshipDate")]?.value;
    const relationshipStatus = form.controls[("relationshipStatus")].value;
    const date = new Date(selectedDate);


    if (this.user.relationship.s !== Relationsship.Single) {
      if (!this.selectedUser) {
        this.user.relationship = {
          p: 0,
          s: relationshipStatus,
          date: date
        }

        this.userService.updateUser(this.user._id, this.user)
      } else if (!!this.selectedUser) {
        this.user.relationship = {
          p: 0,
          s: relationshipStatus,
          user: {
            id: this.selectedUser._id,
            status: Status.Sent
          },
          date: date
        }
        this.selectedUser.relationship = {
          p: 0,
          s: relationshipStatus,
          user: {
            id: this.user._id,
            status: Status.Pending
          },
          date: date
        }
        this.userService.updateUser(this.user._id, this.user)
        this.userService.updateUser(this.selectedUser._id, this.selectedUser);


      }

    } else {
      this.user.relationship = {
        p: 0,
        s: relationshipStatus,
      }
      this.userService.updateUser(this.user._id, this.user);
      this.getRelationship(this.user?.relationship.s);
    }


    this.isEditingRelationship = false;
  }



  acceptRelationship() {
    this.user.relationship.user.status = Status.Accepted;
    let data;
    this.userService.getUserContactData(this.user.relationship.user.id).subscribe((res: User) => {
      data = res;
      data.relationship.user.status = Status.Accepted;
      this.userService.updateUser(data._id, data);
    })
    this.userService.updateUser(this.user._id, this.user);
    this.getRelationship(this.user?.relationship.s);
  }

  removeUser() {
    this.userService.getUserContactData(this.user.relationship.user.id).subscribe((t: User) => {
      t.relationship.user = {} as any;
      this.userService.updateUser(t._id, t);

    })
    this.user.relationship.user = {} as any;
    this.userService.updateUser(this.user._id, this.user);
    this.getRelationship(this.user?.relationship.s);
  }
  addFamilymember() {
    this.newFamilyMember = true;
  }
  addSocialLinks() {
    this.newSocialLink = true;
  }

  addWebsites() {
    this.newWebsite = true;
  }

  changeSocialLink(event) {
    this.socialLinks.id = event;
  }
  saveSocialLink() {
    this.socialLinks.url = this.addSocialLinkUrl;
    this.socialLinks.p = 7;
    this.userData.userUser.socialMedia.links.unshift(this.socialLinks);
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
    this.newSocialLink = false;
    this.socialLinks = {} as any;
    this.addSocialLinkUrl = "";
  }

  saveWebsiteUrl() {
    this.website.url = this.addWebsiteUrl;
    this.website.p = 7;
    this.userData.userUser.websites.unshift(this.website);
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);

    this.addWebsiteUrl = "";
    this.newWebsite = false;
  }

  openLink(url) {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      url;
    } else {
      url = "https://" + url;
    }
    window.open(url, "_blank");


  }
  saveNewFamilyMember(form) {
    const selectedStatusMember = form.controls[('familyMemberStatus')]?.value;
    console.log(selectedStatusMember)

    const familyMember = {
      p: 0,
      s: selectedStatusMember,
      user: {
        id: this.selectedUser._id,
        s: Status.Sent
      }
    }
    this.user.familyMembers.unshift(familyMember)


    const sendFamilyMember = {
      p: 0,
      s: selectedStatusMember,
      user: {
        id: this.user._id,
        s: Status.Pending
      },
    }
    this.selectedUser.familyMembers.unshift(sendFamilyMember);
    this.userService.updateUser(this.user._id, this.user)
    this.userService.updateUser(this.selectedUser._id, this.selectedUser);
    this.selectedUser = {} as any;
    this.newFamilyMember = false;
    this.searchForUser = "";
  }

  changeFamilyMemberStatus() {

  }


  public getFamilyMemberStatus(id) {
    return this.arrayService.familyMembers.find(t => t.id === id);
  }

  acceptFamilyMember(index) {

    this.user.familyMembers[index].user.s = Status.Accepted;

    this.userService.getUserContactData(this.user.familyMembers[index].user.id).subscribe((res: User) => {
      res.familyMembers.find(t => t.user.id === this.authService.userID).user.s = Status.Accepted;
      this.userService.updateUser(res._id, res);

    })
    this.userService.updateUser(this.user._id, this.user);
    this.getFamilyMemberStatus(this.user.familyMembers[index].s);
  }
  getSocialLinkIcons(id) {
    return this.arrayService.socialLinks.find(t => t.id === id);
  }

  deleteSocialLink(index) {
    this.userData.userUser.socialMedia.links.splice(index, 1);
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);

  }

  deleteWebsite(index) {
    this.userData.userUser.websites.splice(index, 1);
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
  }

  editSocialLink(index) {
    this.isEditingSocialLink = index;
  }
  editWebsite(index) {
    this.isEditingWebsite = index;
  }

  updateSocialLink(data, index) {
    this.userData.userUser.socialMedia.links[index] = data;
    this.isEditingSocialLink = null;
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
    // this.userService.updateUser(this.user._id, this.user);
  }

  updateWebsite(data, index) {
    this.userData.userUser.websites[index] = data;
    this.isEditingWebsite = null;
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);

  }

  getActualWorkingCompany() {
    return this.userData.cv.data[0].careers.subCategories[0];
  }

  changeAccountStatus() {
    this.user.account.status = 2;
    this.user.account.endDate = new Date();
    this.userService.updateUser(this.user._id, this.user);
  }

  public set privacyIcon(user: User) {
    this.emailIcon = this.arrayService.privacyArray.find(t => t.id === user.email[0].p).icon;
  }

  public get privacyIcon() {
    return this.emailIcon;
  }

  public set privacyText(user: User) {
    this.emailText = this.arrayService.privacyArray.find(t => t.id === user.email[0].p)?.description;

  }
  public get privacyText() {
    return this.emailText;
  }


  updateUser(event) {
    console.log(event);
  }



  openEmailPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t) {
        this.loggedUser.email[0].p = t;
        this.privacyIcon = this.loggedUser;
        this.privacyText = this.loggedUser;
        this.userService.updateUser(this.loggedUser._id, this.loggedUser);
      }
    })
  }

  updatePhone(event) {
    this.loggedUser.phone[0].privacy = event;
    this.user.phone[0].privacy = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }
  updateSearchQualifications(event) {
    this.loggedUser.searchFilterSkills.searchQualifications.p = event;
    this.user.searchFilterSkills.searchQualifications.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateDriveLicense(event) {
    this.loggedUser.searchFilterSkills.searchDriveLicenses.p = event;
    this.user.searchFilterSkills.searchDriveLicenses.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updatePcKnowledge(event) {
    this.loggedUser.searchFilterSkills.searchPCKnowledge.p = event;
    this.user.searchFilterSkills.searchPCKnowledge.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateSearchLanguages(event) {
    this.loggedUser.searchFilterSkills.searchLanguages.p = event;
    this.user.searchFilterSkills.searchLanguages.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateSearchSkillsOffer(event) {
    this.loggedUser.searchFilterSkills.searchSkillsOffer.p = event;
    this.user.searchFilterSkills.searchSkillsOffer.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }

  updateEmail(event) {
    this.loggedUser.email[0].p = event;
    this.user.email[0].p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateRelationship(event) {
    this.userData.userEmea.relationship.p = event;
    this.userData.userEmea.user.relationship.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updatePhonePrivacy(event, index) {
    this.loggedUser.phone[index].privacy = event;
    this.user.phone[index].privacy = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateFamilyMember(event, index) {
    this.loggedUser.familyMembers[index].p = event;
    this.user.familyMembers[index].p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateEmailPrivacy(event, index) {
    this.loggedUser.email[index].p = event;
    this.user.email[index].p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateWebsitePrivacy(event, index) {
    this.userData.userUser.websites[index].p = event;
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
  }

  updateSocialLinksPrivacy(event, index) {
    this.userData.userUser.socialMedia.links[index].p = event;
    this.userData.userUser.socialMedia.links[index].p = event;
    this.userService.updateuUser(this.userData.userUser._id, this.userData.userUser);
  }

  updateCitiesPrivacy(event) {
  /*  this.loggedUser.places.cities.p = event;
    this.user.places.cities.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser); */
  }

  updateCurrentPlacePrivacy(event) {
    this.loggedUser.places.current.p = event;
    this.user.places.current.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateHometown(event) {
    this.loggedUser.places.hometown.p = event;
    this.user.places.hometown.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updatePlaces(event) {
    this.loggedUser.places.current.p = event;
    this.user.places.current.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateCareer(event) {
    this.user.cv.data[0]?.skills[0].skillsOffer.privacy
    this.loggedUser.cv.data[0].skills[0].skillsOffer.privacy = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }

  updateBirthdayPrivacy(event) {
    this.loggedUser.birthday.p = event;
    this.user.birthday.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);
  }

  updateReligionPrivacy(event) {
    this.loggedUser.religion.p = event;
    this.user.religion.p = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }

  updateCareerObj(event, index) {
    this.user.cv.data[0].careers[0].subCategories[index].privacy = event;
    this.loggedUser.cv.data[0].careers[0].subCategories[index].privacy = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }


  updateEducationObj(event, index) {
    this.user.cv.data[0].education[0].subCategories[index].privacy = event;
    this.loggedUser.cv.data[0].education[0].subCategories[index].privacy = event;
    this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }

  openPhonePrivacy(data) {
    console.log(data, "data")
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t) {
        this.loggedUser.phone[0].privacy = t;
        this.privacyIcon = this.loggedUser;
        this.privacyText = this.loggedUser;
        this.userService.updateUser(this.loggedUser._id, this.loggedUser);
      }
    })
  }
}
