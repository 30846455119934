

<main>

  <!-- Container START -->
  <div class="container">
    <div class="row g-4">

      <!-- Sidenav START -->
      <div class="col-lg-3">

        <!-- Advanced filter responsive toggler START -->
        <div class="d-flex align-items-center d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'header.pages' | translate}}</span>
            </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <!-- Navbar START-->
        <nav class="navbar navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                <!-- Offcanvas header -->
                <div class="offcanvas-header">
                    <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <!-- Offcanvas body -->
                <div class="offcanvas-body d-block px-2 px-lg-0">
                    <!-- Card START -->
                    <div class="card overflow-hidden">
                        <!-- Cover image -->

                            <!-- Card body START -->
                            <div class="card-body pt-0">
                            <!-- Side Nav START -->
                            <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
                                    <!-- Avatar -->
                                    <button type="button" class="button-f button-sc px-0" data-bs-dismiss="offcanvas" (click)="navigateToUser()">
                                        <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                          <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                           <span class="span-padding-left10">{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
                                      </button>
                                    <!-- Post input -->
                                <li data-bs-dismiss="offcanvas" class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/cv'">
                                    <a class="nav-link" > <i class="bi bi-file-text"></i>
                                        <span class="ms-2">{{'header.cv' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" routerLink="/pages">
                                    <a class="nav-link" > <i class="bi bi-flag"></i><span class="ms-2">{{'header.pages' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" routerLink="/groups">
                                    <a class="nav-link" > <i class="bi bi-people"></i>
                                        <span class="ms-2">{{'header.groups' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" routerLink="/contacts">
                                    <a class="nav-link"><i class="bi bi-person"></i>
                                         <span class="ms-2">{{'header.contacts' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/jobs'">
                                    <a class="nav-link" ><i class="bi bi-list-task"></i>
                                        <span class="ms-2">{{'sidebar.jobs' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" [routerLink]="'../'+ currentUser?.userUrl + '/projects'">
                                    <a class="nav-link"><i class="bi bi-bar-chart-steps fs-6"></i><span class="ms-2">{{'sidebar.projects' | translate}} </span></a>
                                </li>
                                <li data-bs-dismiss="offcanvas" class="nav-item" (click)="navigateToPayment()">
                                    <a class="nav-link"> <i class="bi bi-credit-card"></i><span class="ms-2">{{'sidebar.payment' | translate}} </span></a>
                                </li>
                            </ul>
                            <!-- Side Nav END -->
                        </div>
                        <!-- Card body END -->
                        <!-- Card footer -->
                        <div class="card-footer text-center py-2">
                            <a class="btn btn-link btn-sm" href="my-profile.html">View Profile </a>
                        </div>
                    </div>
                    <!-- Card END -->

                    <!-- Helper link START -->
                    <ul class="nav small mt-4 justify-content-center lh-1">
                        <li class="nav-item">
                            <a class="nav-link" href="my-profile-about.html">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="settings.html">Settings</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="help.html">Help</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                        </li>
                    </ul>
                    <!-- Helper link END -->
                    <!-- Copyright -->
                    <p class="small text-center mt-1">©2023 <a class="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </a></p>
                </div>
            </div>
        </nav>
        <!-- Navbar END-->
    </div>
      <!-- Sidenav END -->

      <!-- Main content START -->
      <div class="col-md-8 col-lg-6 vstack gap-4">
          <!-- Card START -->
          <div class="card">
            <!-- Card header START -->
            <div class="card-header border-0 pb-0">
              <div class="row g-2">
                <div class="col-lg-2">
                  <!-- Card title -->
                  <h1 class="h4 card-title mb-lg-0">{{'header.pages' | translate}}</h1>
                </div>
                <div class="col-sm-6 col-lg-3 ms-lg-auto">
                  <!-- Select Groups -->
                  <select class="form-select js-choice choice-select-text-none" data-search-enabled="false">
                    <option value="AB">Alphabetical</option>
                    <option value="NG">Newest group</option>
                    <option value="RA">Recently active</option>
                    <option value="SG">Suggested</option>
                  </select>
                </div>
                  <div class="col-sm-6 col-lg-3">
                  <!-- Button modal -->
                  <a class="btn btn-primary-soft ms-auto w-100" routerLink="create" > <i class="fa-solid fa-plus pe-1"></i> {{'pages.create' | translate}}</a>
                </div>
              </div>
            </div>
            <!-- Card header START -->
            <!-- Card body START -->
            <div class="card-body">
              <!-- Tab nav line -->
              <ul class="nav nav-tabs nav-bottom-line justify-content-center justify-content-md-start">
                <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#tab-1"> {{'pages.myPages' | translate}} </a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-2"> Suggested for you </a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-3"> Popular near you </a> </li>
                <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-4"> More suggestions </a> </li>
              </ul>
              <div class="tab-content mb-0 pb-0">

                <!-- Friends groups tab START -->
                <div class="tab-pane fade show active" id="tab-1">
                  <div class="row g-4">
                      <ng-container *ngFor="let userPage of pages$ | async">
                        <app-pages-list  class="col-sm-6 col-lg-4" (rejectPageIndex)="checkPage()" [user]="user" [pageDetails]="userPage" [details]="'details'"></app-pages-list>
                      </ng-container>
                      <!-- Card START -->

                      <!-- Card END -->
                    </div>
                  </div>
                  <!-- Friends' groups tab END -->

                <!-- Suggested for you START -->
                <div class="tab-pane fade" id="tab-2">
                  <div class="row g-4">
                    <div class="col-sm-6 col-lg-4">
                      <!-- Card START -->
                      <div class="card">
                        <div class="h-80px rounded-top" style="background-image:url(assets/images/bg/01.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;"></div>
                          <!-- Card body START -->
                          <div class="card-body text-center pt-0">
                            <!-- Avatar -->
                            <div class="avatar avatar-lg mt-n5 mb-3">
                               <a href="group-details.html"><img class="avatar-img rounded-circle border border-white border-3 bg-white" src="assets/images/logo/01.svg" alt=""></a>
                            </div>
                            <!-- Info -->
                            <h5 class="mb-0"><a href="group-details.html">Real humans</a></h5>
                            <small> <i class="bi bi-globe pe-1"></i> Public Group</small>
                            <!-- Group stat START -->
                            <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">23k</h6>
                                <small>Members</small>
                              </div>
                              <!-- Divider -->
                              <div class="vr"></div>
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">12</h6>
                                <small>Post per day</small>
                              </div>
                            </div>
                            <!-- Group stat END -->
                            <!-- Avatar group START -->
                            <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/06.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/09.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+12</span></div>
                              </li>
                            </ul>
                            <!-- Avatar group END -->
                        </div>
                        <!-- Card body END -->
                        <!-- Card Footer START -->
                        <div class="card-footer text-center">
                          <a class="btn btn-danger-soft btn-sm" href="#!"> Leave group </a>
                        </div>
                        <!-- Card Footer END -->
                      </div>
                      <!-- Card END -->
                    </div>
                    <div class="col-sm-6 col-lg-4">
                      <!-- Card START -->
                      <div class="card">
                        <div class="h-80px rounded-top" style="background-image:url(assets/images/bg/02.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;"></div>
                          <!-- Card body START -->
                          <div class="card-body text-center pt-0">
                            <!-- Avatar -->
                            <div class="avatar avatar-lg mt-n5 mb-3">
                               <a href="group-details.html"><img class="avatar-img rounded-circle border border-white border-3 bg-white" src="assets/images/logo/03.svg" alt=""></a>
                            </div>
                            <!-- Info -->
                            <h5 class="mb-0"><a href="group-details.html">Strong signals</a></h5>
                            <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                            <!-- Group stat START -->
                            <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">45k</h6>
                                <small>Members</small>
                              </div>
                              <!-- Divider -->
                              <div class="vr"></div>
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">16</h6>
                                <small>Post per day</small>
                              </div>
                            </div>
                            <!-- Group stat END -->
                            <!-- Avatar group START -->
                            <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+05</span></div>
                              </li>
                            </ul>
                            <!-- Avatar group END -->
                        </div>
                        <!-- Card body END -->
                        <!-- Card Footer START -->
                        <div class="card-footer text-center">
                          <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                        </div>
                        <!-- Card Footer END -->
                      </div>
                      <!-- Card END -->
                    </div>
                    <div class="col-sm-6 col-lg-4">
                      <!-- Card START -->
                      <div class="card">
                        <div class="h-80px rounded-top" style="background-image:url(assets/images/bg/03.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;"></div>
                          <!-- Card body START -->
                          <div class="card-body text-center pt-0">
                            <!-- Avatar -->
                            <div class="avatar avatar-lg mt-n5 mb-3">
                               <a href="group-details.html"><img class="avatar-img rounded-circle border border-white border-3 bg-white" src="assets/images/logo/05.svg" alt=""></a>
                            </div>
                            <!-- Info -->
                            <h5 class="mb-0"><a href="group-details.html">Team yes, we can</a></h5>
                            <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                            <!-- Group stat START -->
                            <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">32k</h6>
                                <small>Members</small>
                              </div>
                              <!-- Divider -->
                              <div class="vr"></div>
                              <!-- Group stat item -->
                              <div>
                                <h6 class="mb-0">05</h6>
                                <small>Post per day</small>
                              </div>
                            </div>
                            <!-- Group stat END -->
                            <!-- Avatar group START -->
                            <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/14.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/13.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                              </li>
                              <li class="avatar avatar-xs">
                                <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+08</span></div>
                              </li>
                            </ul>
                            <!-- Avatar group END -->
                        </div>
                        <!-- Card body END -->
                        <!-- Card Footer START -->
                        <div class="card-footer text-center">
                          <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                        </div>
                        <!-- Card Footer END -->
                      </div>
                      <!-- Card END -->
                    </div>
                  </div>
                </div>
                <!-- Suggested for you END -->

                <!-- Popular near you START -->
                <div class="tab-pane fade" id="tab-3">
                   <!-- Add group -->
                  <div class="my-sm-5 py-sm-5 text-center">
                    <i class="display-1 text-muted bi bi-people"> </i>
                    <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                    <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup"> Click here to add </button>
                  </div>
                </div>
                <!-- Popular near you END -->

                <!-- More suggestions START -->
                <div class="tab-pane fade" id="tab-4">
                   <!-- Add group -->
                  <div class="my-sm-5 py-sm-5 text-center">
                    <i class="display-1 text-muted bi bi-people"> </i>
                    <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                    <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup"> Click here to add </button>
                  </div>
                </div>
                <!-- More suggestions END -->

            </div>
          </div>
          <!-- Card body END -->
        </div>
        <!-- Card END -->
      </div>
      <!-- Right sidebar END -->

    </div> <!-- Row END -->
  </div>
  <!-- Container END -->

</main>
<!--<div class="main-container">


  <div class="main-contain">
    <div class="row">
      <div class="main">
        <div class="d-flex">
          <button type="button" class="col-3 btn btn-light px-0 pb-1 pt-1 pl-2" routerLink="create">
            <div>
              <i class="bi bi-plus width-40" aria-hidden="true"></i>
              <span class="span-padding-left10 isSidebar">Add New Page</span>
            </div>
          </button>
          <button type="button" class="col-4 btn btn-light px-0 pb-1 pt-1 pl-2 ms-2">
            <div>
              <i class="bi bi-plus width-40" aria-hidden="true"></i>
              <span class="span-padding-left10 isSidebar">Discover</span>
            </div>
          </button>
        </div>

       <div *ngFor="let userPage of pages$ | async">
          <app-pages-list class="d-flex width-900" [userPage]="userPage" [details]="'details'"></app-pages-list>
        </div>
        <div>
        </div>
      </div>
    </div>

  </div>
</div> -->
<router-outlet></router-outlet>
