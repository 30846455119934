import { ChangeDetectorRef, Component, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Group, Image } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { GroupsComponent } from '../groups/groups.component';
import "quill-emoji/dist/quill-emoji.js";
import "quill-mention";
import { EditCoverImage, UserCoverDialogComponent } from 'src/app/components/modals/user-dialogs/user-dialog-picture/user-cover-dialog/user-cover-dialog.component';
import { ImageService } from 'src/app/shared/services/image.service';
import { MatDialog } from '@angular/material/dialog';
import { EditUserDialog, UserDialogPictureComponent } from 'src/app/components/modals/user-dialogs/user-dialog-picture/user-dialog-picture.component';
import { CategoryService } from 'src/app/shared/services/category.service';


@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent extends GroupsComponent implements OnInit {
  public group = new Group();
  public groups$: Observable<Group[]>;
  public groups: Group[];
  public hasCoverPhoto = false;
  public hasPhoto = false;
  public editGroupInfo = false;
  public coverImage;
  public profileImage;
  @Output()
  public privacy = [
    {id: 0, name:"Public"},
    {id: 1, name:"Private"}
  ];
  empty="";
  public webUrl;
  public privacyName;
  public categories = [];
  searchCategory;

  public outsiderGroupId;
  public groupUrl;



  config = {
    "emoji-toolbar": true,
    "emoji-textarea": true,
    "emoji-shortname": true,
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],
        // [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction
        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["image"],                                    // image
        ["video"],
        ["code-block"], // code block
        [{align: []}],
        ["emoji"],
        ["clean"], // remove formatting button
        ["link", "image", "video"],
      ]
    },
  }
  constructor(public router: Router,
    public groupService: GroupService,
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    public imageService: ImageService,
    public dialog: MatDialog,
    public categoryService?: CategoryService) {
    super(router, groupService, authService, cd);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url = event.url.replace("/groups", "");
        event.url = event.url.replace("/", "");
        this.outsiderGroupId = event.url;
      }
  });
  }

  ngOnInit(): void {
    this.groupService.getGroups();
    this.categoryService.getCategory();
    this.groups$ = this.groupService.groupModel();
    this.groupService.getPageID.subscribe(data => {
      if(data.length > 0) {
      this.groupService.getGroup(data).subscribe(group => {
        this.group = group;
        this.privacyName = this.privacy.find(x => x.id === this.group.privacy).name;
        if(this.group.profileImg) {
          this.imageService.getImageByID(this.group.profileImg).subscribe((res: Image) => {
            if(res.imageUrl) {
              this.profileImage = res.imageUrl;
              this.hasPhoto = true;
              this.cd.detectChanges();
            }
          });
        } else {
          return this.hasPhoto = false;
        }

        if(this.group.coverImg) {
          this.imageService.getImageByID(this.group.coverImg).subscribe((res: Image) => {
              if(res.imageUrl) {
                this.coverImage = res.imageUrl;
                this.hasCoverPhoto = true;
                this.cd.detectChanges();

              }
            });
          } else {
            return this.hasCoverPhoto = false;
          }
      })
    } else {
      this.groupService.getGroupUser(this.outsiderGroupId).subscribe(group => {
        this.group = group[0];
        this.privacyName = this.privacy.find(x => x.id === this.group.privacy).name;

        if(this.group.profileImg) {
          this.imageService.getImageByID(this.group.profileImg).subscribe((res: Image) => {
            if(res.imageUrl) {
              this.profileImage = res.imageUrl;
              this.hasPhoto = true;
              this.cd.detectChanges();
            }
          });
        } else if(this.group.profileImg === undefined) {
          return this.hasPhoto = false;
        }
        if(this.group.coverImg) {
          this.imageService.getImageByID(this.group.coverImg).subscribe((res: Image) => {
              if(res.imageUrl) {
                this.coverImage = res.imageUrl;
                this.hasCoverPhoto = true;
                this.cd.detectChanges();

              }
            });
          } else if(this.group.coverImg === undefined) {
            return this.hasCoverPhoto = false;
          }
      })
    }
    })
  }

  update() {

    this.group.url = this.groupUrl;
    this.groupService.update(this.group._id, this.group);
    this.privacyName = this.privacy.find(x => x.id === this.group.privacy).name;
    this.groupUrl = "";
  }
  addPrivacy(event) {
    this.group.privacy = event.id;
  }
  uploadCoverPicture(data: EditCoverImage) {
    this.dialog.open(UserCoverDialogComponent, {
      data
    }).afterClosed().subscribe(res => {
      if(res === undefined) {
        return;
      }
      if (res.group.coverImg) {
      this.imageService.getImageByID(res.group.coverImg).subscribe((img: Image) => {
        if(img.imageUrl) {
          this.coverImage = img.imageUrl;
          this.hasPhoto = true;
        }
      });
    }
    });
  }
  openProfileImgDialog(data: EditUserDialog) {
    this.dialog.open(UserDialogPictureComponent, {
      data
    }).afterClosed().subscribe(res => {
      if(res === undefined) {}
      this.imageService.getImageByID(res.group.profileImg).subscribe((img: Image) => {
        if(img.imageUrl) {
          this.profileImage = img.imageUrl;
          this.hasPhoto = true;
        }
      });
    });
  }
  groupInfo() {
    this.editGroupInfo = true;
    this.groupUrl = this.group.url;
  }
  addCategory(event) {
    this.group.categories.unshift({name: event.description});
    this.searchCategory = "";
   }
  removeCategory(index) {
    this.group.categories.splice(index, 1);
  }
  cancel() {
    this.searchCategory = "";
    this.editGroupInfo = false;
    this.groupUrl = "";
  }
  changeUrl(event) {
    this.groupUrl = event;
  }

}
