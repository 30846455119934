<section class="pricing py-5">
  <div class="container">
      <div class="row">
        <!-- Free Tier -->
        <div class="col-lg-3" *ngFor="let obj of data">

          <div class="card mb-5 mb-lg-0">
            <div class="card-body">
              <h5 class="card-title text-uppercase text-center">{{obj.months}} {{obj.description}}</h5>
              <h6 class="card-price text-center">€ {{obj.price}}<span class="period"></span></h6>
              <hr>

              <div class="d-grid">
                <a href="#" class="btn btn-primary text-uppercase">Upgrade</a>
              </div>
          </div>
        </div>
        <!-- Plus Tier -->


      </div>
    </div>

  </div>
</section>
