import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { GravatarModule } from "ngx-gravatar";
import { AdvertisingComponent } from "./advertising.component";
import { DataItemDetailsModule } from "src/app/components/data-item-details/data-item-details.module";

@NgModule({
  declarations: [AdvertisingComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    GravatarModule,
    DataItemDetailsModule,
  ],
  exports: [
    AdvertisingComponent
  ]
})
export class AdvertisingModule { }
