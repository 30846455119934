import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CropperComponent } from 'angular-cropperjs';
import { Group, Image, Model, Page } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { WarningDialogComponent } from 'src/app/sharedComponents/warning-dialog/warning-dialog.component';
import { EditUserDialog } from '../user-dialog-picture.component';

@Component({
  selector: 'app-user-cover-dialog',
  templateUrl: './user-cover-dialog.component.html',
  styleUrls: ['./user-cover-dialog.component.scss']
})
export class UserCoverDialogComponent implements OnInit {
  imgUrl;
  image;
  imageURL;
  imageCrop;
  @ViewChild("imageCoverCropper", {static: false}) public imageCoverCropper: CropperComponent;


public imgConfig = {
  aspectRatio : 4/3,
  dragMode : "move",
  background : true,
  movable: true,
  rotatable : true,
  scalable: true,
  zoomable: true,
  viewMode: 0,
  checkImageOrigin : false,
  checkCrossOrigin: false,
  width: 300,
  height: 400,
  rounded: true,

  crop: () => {

this.image = this.imageCoverCropper.cropper.getCroppedCanvas().toDataURL("image/jpeg", 90);
  }
};
public imagePhoto;
public confirmationDialog: MatDialogRef<WarningDialogComponent>

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditCoverImage,
    public imageService: ImageService,
    public authService: AuthService,
    public pageService: PagesService,
    public groupService: GroupService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserCoverDialogComponent>

    ) { }

  ngOnInit(): void {
    if (this.data.edit) {
      this.data.model = this.data.model;
      if(this.data.model.coverPicture !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.model.coverPicture).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
        });
      }
    }  else if(this.data.editPage) {
      this.data.page = this.data.page;
      if (this.data.page.coverImage !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.page.coverImage).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
        });
      }
    } else if(this.data.editGroup) {
      this.data.group = this.data.group;
      if (this.data.group.coverImg !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.group.coverImg).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
        });
      }
    }
  }
  fileChangeEvent(event: any): void {
    this.imgUrl = URL.createObjectURL(event.target.files[0]);
    this.imageCrop = event.target.files[0];
    }

    saveImage() {
      if(this.data.edit) {

      this.imageCoverCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

         this.imageService.addImage(blob, "test").subscribe((res: any) => {
           if (res.body) {
             this.data.model.coverPicture = res.body._id;
             this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
              this.imageURL = t.imageUrl;
             });
            }
          }, (err: any) => {
            console.log(err);
         })
       }, "image/jpeg", 100);
      } else if (this.data.editPage) {
        const name = `${this.data.page._id}`

        this.imageCoverCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

          this.imageService.addImage(blob, name).subscribe((res: any) => {
            if (res.body) {
              this.data.page.coverImage = res.body._id;
              this.pageService.updatePage(this.data.page._id, this.data.page);
              this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
               this.imageURL = t.imageUrl;
              });
             }
           }, (err: any) => {
             console.log(err);
          })
        }, "image/jpeg", 100);
      } else if(this.data.editGroup) {
        const name = `${this.data.group._id}`

        this.imageCoverCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

          this.imageService.addImage(blob, name).subscribe((res: any) => {
            if (res.body) {
              this.data.group.coverImg = res.body._id;
              this.groupService.update(this.data.group._id, this.data.group);
              this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
               this.imageURL = t.imageUrl;
              });
             }
           }, (err: any) => {
             console.log(err);
          })
        }, "image/jpeg", 100);
      }
}
saveData() {
  if(this.authService.isLoggedIn) {
    if(this.data.edit) {
    } else if(this.data.editPage) {
      this.pageService.updatePage(this.data.page._id, this.data.page);
      this.dialogRef.close(this.data.page);
    } else if(this.data.editGroup) {
      this.groupService.update(this.data.group._id, this.data.group);
    }
    this.dialogRef.close(this.data);
  } else {
    this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
      disableClose: false
    });

    this.confirmationDialog.afterClosed().subscribe(result => {
      if(result) {
        // do confirmation actions
      }
      this.confirmationDialog = null;
    });
  }
}
flipHorizontal() {
  this.imageCoverCropper.cropper.scaleX(-1);
  }

  flipVertical() {
    this.imageCoverCropper.cropper.scaleY(-1)
  }

rotateLeft45() {
  this.imageCoverCropper.cropper.rotate(-45);
}
rotateRight45() {
  this.imageCoverCropper.cropper.rotate(45);
}
cropperZoom(number) {
  this.imageCoverCropper.cropper.zoom(number);
}
}
export class EditCoverImage {
  edit?: boolean;
  model?: Model;
  editPage?: boolean;
  page?: Page;
  editGroup?: boolean;
  group?: Group;
}

