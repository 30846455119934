import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesService } from 'src/app/shared/services/languages.service';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss']
})
export class LanguagePageComponent implements OnInit {

  @Input() data: any;

  @Input() updatePrice: number;

  @Output() saveObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateBuyForm: EventEmitter<any> = new EventEmitter<any>();

  myControl = new UntypedFormControl('');
  myControlSecond = new UntypedFormControl('');



  languageVersion = "";
  isNotTranslated: boolean = false;
  isNotSameVersion: boolean = false;
  showSecondaryLanguage: boolean = false;
  secondLanguageArray;
  selectedValueLanguage;
  selectedLanguage;
  selectedSecondLanguage;



  languages = [];


  constructor(public languageService: LanguagesService,
    public translateService: TranslateService,
    private http: HttpClient,

  ) { }

  ngOnInit(): void {
    this.languageService.getPrimaryLanguages();

    this.http.get<any>(`assets/i18n/en.json`).subscribe(res => {
      this.languageVersion = res.version;
      localStorage.setItem("languageVersion", res.version)
    });

    this.http.get<any>(`assets/languages/${this.data?.language?.primary}/country.json`).subscribe(data => {
      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
    });
    this.translateService.use(this.data?.language?.primary);

    this.secondLanguageArray = this.languageService.primaryLanguages.filter(t => t.version > '0' );


    const version = this.languageService.primaryLanguages.find(t => t.id === this.data?.language?.primary)?.version;
    this.selectedValueLanguage = this.languageService.primaryLanguages.find(t => t.id === this.data?.language?.primary || t.lang === this.data?.language?.primary );
    if (!!this.data.language.secondary) {
      this.selectedSecondLanguage = this.languageService.primaryLanguages.find(t => t.lang === this.data.language.secondary )

    }

    if (!!this.languageVersion && this.languageVersion !== version) {
      this.isNotSameVersion = true;

    } else if (!version) {
      this.isNotTranslated = true;

    }

  }

  saveLanguage() {
    this.saveObject.emit(this.data);
  }
  updateBuy() {
    this.updateBuyForm.emit()
  }


  changeLanguageSecond(event) {
    this.data.language.secondary = event.lang;
  }

  changeLanguage(event) {
    this.selectedLanguage = event;
    this.translateService.use(this.selectedValueLanguage.lang);
    if (event.version === this.languageVersion) {
      setTimeout(() => {
        this.data.language = {};
        this.data.language.primary = this.selectedLanguage.lang;
        this.selectedValueLanguage = this.selectedLanguage;
        this.isNotSameVersion = false;
        this.isNotTranslated = false;
      }, 100)


    } if (!!event.version && event.version !== this.languageVersion) {
      setTimeout(() => {
        this.isNotSameVersion = true;
        this.data.language.primary = this.selectedLanguage.lang;
        this.selectedValueLanguage = this.selectedLanguage;
        this.isNotTranslated = false;
      }, 100)

    } else if (!event.version) {
      setTimeout(() => {
        this.isNotTranslated = true;
        this.showSecondaryLanguage = true;
        this.isNotSameVersion = false;
        this.data.language.primary = this.selectedLanguage.lang;

        this.selectedValueLanguage = this.selectedLanguage;
      }, 100)

    }
  }

}
