import "quill-emoji/dist/quill-emoji.js";
import "quill-mention";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Dimensions, ImageCroppedEvent, ImageTransform } from "ngx-image-cropper";
import { Model, Image, Page, Group } from "src/app/shared/interfaces/model";
import { CropperComponent } from "angular-cropperjs";
import { ImageService } from "src/app/shared/services/image.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { UserService } from "src/app/shared/services/user.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { GroupService } from "src/app/shared/services/group.service";
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
import { ImageHandler, Options } from "ngx-quill-upload";
import { environment } from "src/assets/environments/environment";
import { EmploymentType, Incomes } from "src/app/shared/enums/enums-model";
import { Observable, Subscription } from "rxjs";
import { User, UserUser } from "src/app/shared/interfaces/user.model";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { take } from "rxjs/operators";
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageHandler', ImageHandler);


@Component({
  selector: "app-header-dialog",
  templateUrl: "./header-dialog.component.html",
  styleUrls: ["./header-dialog.component.scss"]
})
export class HeaderDialogComponent implements OnInit {
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  hasFocus = false;
  imgUrl;
  image;
  imageURL;
  imageCrop;
  public modules;
  public model: Model;
  public user: User;
  public userUser: UserUser;
  countryModel: any;

  cv: any;
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]

  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];


  @ViewChild("angularCropper", {static: false}) public angularCropper: CropperComponent;

  atValues = [
    { id: 1, value: "Fredrik Sundqvist", link: "https://google.com" },
    { id: 2, value: "Patrik Sjölin" }
  ];
  hashValues = [
    { id: 3, value: "Fredrik Sundqvist 2" },
    { id: 4, value: "Patrik Sjölin 2" }
  ]
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];


imagePhoto;
currency;

public imgConfig = {
  aspectRatio : 3/4,
  dragMode : "move",
  background : true,
  movable: true,
  rotatable : true,
  scalable: true,
  zoomable: true,
  viewMode: 0,
  checkImageOrigin : false,
  checkCrossOrigin: false,
  width: 300,
  height: 400,
  crop: () => {

this.image = this.angularCropper.cropper.getCroppedCanvas().toDataURL("image/jpeg", 90);
  }
};
public confirmationDialog: MatDialogRef<WarningDialogComponent>
subscription: Subscription;
public dataList$: Observable<any>;
selectedCV: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditHeaderDialog,
    private dialogRef: MatDialogRef<HeaderDialogComponent>,
    public imageService: ImageService,
    public authService: AuthService,
    public dialog: MatDialog,
    private http: HttpClient,
    private userService: UserService,
    private pageService: PagesService,
    private groupService: GroupService,
    private countryDataService: CountryDataService
    ) {


    }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryModel = res);

   if (this.data.edit) {
    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe((res: any) => {
      this.user = res.user;
      this.userUser = res.userUser;
      if (!this.userUser.incomes) {
        this.userUser.incomes = {} as any;
      }
      this.selectedCV = res.cv;

       this.currency = res.user.address.data[0].c;
      this.data.model = res.cv.data[this.data.index];
      this.cv = res.userUser.cv.data[this.data.index];
     })
     if (this.data.model.photo !== undefined && this.authService.isLoggedIn) {
      this.imageService.getImageByID(this.data.model.photo).subscribe((res: Image) => {
        this.imagePhoto = res.imageUrl;
      });
    }
   }
   this.userService.userModel().subscribe(res => {
    this.users = res;

    this.pageService.getAllPages().subscribe(t => {
      this.pages = t;
    })
    this.groupService.getAllGroups().subscribe(t => {
      this.groups = t;
    })

    this.modules = {
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        linkTarget:"_blank",
        onSelect (item, insertItem) {
          insertItem(item)
  },

        source: (searchTerm, renderList, mentionChar) => {

          let values = [{id: "", value: "", link: null }];

          if (mentionChar === "@") {
            this.users.forEach(t => {
              values.unshift({id: t._id, value: `${t.firstName} ${t.lastName}`, link:window.location.origin + '/' + t._id + "/start"});
          });
            this.pages.forEach(t => {
              values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/' + t.pageUrl});
            });
            this.groups.forEach(t => {
              values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/groups/' + t.url});
            })

          } else if(mentionChar === "@") {


          }
          if (searchTerm.length === 0) {

            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.unshift(values[i]);
              }


            renderList(matches, searchTerm);

            }
          }
        },
      },
      imageResize: true,
      imageDrop: true,
      imageHandler: {
        upload: (file) => {
          return new Promise((resolve, reject) => {

        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') { // File types supported for image
          if (file.size < 1000000) { // Customize file size as per requirement

          // Sample API Call
            const uploadData = new FormData();
            uploadData.append('file', file, file.name);

            return this.http.post(`${environment.backend}/image/${file}`, uploadData).toPromise()
            .then((result: Image) => {
              this.imageService.getImageByID(result._id).subscribe((t: Image) => {
                resolve(t.imageUrl); // RETURN IMAGE URL from response
               });
          })
              .catch(error => {
                reject('Upload failed');
                // Handle error control
                console.error('Error:', error);
              });
          } else {
            reject('Size too large');
           // Handle Image size large logic
          }
        } else {
          reject('Unsupported type');
         // Handle Unsupported type logic
        }
      });
    },
        accepts: ['png', 'jpg', 'jpeg', 'jfif'] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
      } as Options,
  }
  });
  }

  ngOnDestory() {
    this.subscription.unsubscribe();
  }
  onSelectionChanged = (event) =>{
    if(event.oldRange == null){
      this.onFocus();
    }
    if(event.range == null){
      this.onBlur();
    }
  }

  onContentChanged = (event) =>{
    // console.log(event.html);
  }

  onFocus = () =>{
    console.log("On Focus");
  }
  onBlur = () =>{
    console.log("Blurred");
  }
  saveData() {
    if(this.authService.isLoggedIn) {
      this.selectedCV.data[this.data.index] = this.data.model;

    this.userService.updateCV(this.cv.cvID, this.selectedCV);
    this.userService.updateUser(this.user._id, this.user);
    this.userService.updateuUser(this.userUser._id, this.userUser);
    this.dialogRef.close(this.data);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }
  }
  fileChangeEvent(event: any): void {
  this.imgUrl = URL.createObjectURL(event.target.files[0]);
  this.imageCrop = event.target.files[0];
  }

imageCropped(event: ImageCroppedEvent) {
    this.data.model.photo = event.base64;
}

rotateLeft45() {
  this.angularCropper.cropper.rotate(-45);
}
rotateRight45() {
  this.angularCropper.cropper.rotate(45);
}
cropperZoom(number) {
  this.angularCropper.cropper.zoom(number);
}

cropperReady(sourceImageDimensions: Dimensions) {
}
getCropperCanvas320() {
  this.angularCropper.cropper.getCroppedCanvas({width: 180, height: 320});
}

loadImageFailed() {
}
cropMoved(data){
  this.imgUrl = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
}
saveImage() {
 this.data.model.pdfPhoto = this.angularCropper.cropper.getCroppedCanvas({width: 400, height: 500}).toDataURL();
 // let name = `${this.data.model.personalData[0].firstName}.${this.data.model.personalData[0].lastName}`

 this.angularCropper.cropper.getCroppedCanvas({width: 350, height: 450}).toBlob((blob) =>  {

    this.imageService.addImage(blob, "test").subscribe((res: any) => {
      if (res.body) {
        this.data.model.photo = res.body._id;
        this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
         this.imageURL = t.imageUrl;
        });
       }
     }, (err: any) => {
       console.log(err);
    })
  }, 'image/jpeg', 100)
}

rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
}

rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
}

private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
        ...this.transform,
        flipH: flippedV,
        flipV: flippedH
    };
}


flipHorizontal() {
this.angularCropper.cropper.scaleX(-1);
}

flipVertical() {
  this.angularCropper.cropper.scaleY(-1)
}

resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
}

zoomOut() {
    this.scale -= .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

zoomIn() {
    this.scale += .1;
    this.transform = {
        ...this.transform,
        scale: this.scale
    };
}

toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
}

updateRotation() {
    this.transform = {
        ...this.transform,
        rotate: this.rotation
    };
}

}
export interface EditHeaderDialog {
  edit?: boolean;
  model?: Model;
  index?: number
}
