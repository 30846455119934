import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from "@angular/core";
import {fromEvent, Subscription} from "rxjs";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[clickOutside]"
})
export class ClickDirective implements AfterViewInit, OnDestroy {
  // tslint:disable-next-line:no-output-rename
  @Output("clickOutside") clickOutside: EventEmitter<void> = new EventEmitter();
  squareMatrix: {
    x1: number,
    y1: number,
    x2: number,
    y2: number
  };
  sub: Subscription;
  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    /**
     * Populate current square x,y position.
     */
    this.populateMatrix();
    // Timeout is here to ignore first click. Not right way but do the job.
    setTimeout(() => {
      this.sub = fromEvent(window, "click").subscribe((e: MouseEvent) => {
        if (!this.checkIfClickOnSquare(e.clientX, e.clientY)) {
          this.clickOutside.emit();
        }
      });
    }, 100);
  }

  ngOnDestroy() {
    if (this.sub) {
      // Don't forget to unsubscribe.
      this.sub.unsubscribe();
    }
  }

  private populateMatrix() {
    const {x, y, width, height} = this.elementRef.nativeElement.getBoundingClientRect();

    this.squareMatrix = {
      x1 : x,
      y1: y,
      x2: x + width,
      y2: y + height
    };
  }

  private checkIfClickOnSquare(currentX, currentY): boolean {
    return (
      currentX > this.squareMatrix.x1 &&
      currentX < this.squareMatrix.x2 &&
      currentY > this.squareMatrix.y1 &&
      currentY < this.squareMatrix.y2
    );
  }
}
