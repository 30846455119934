<div class="container">
<h3>{{"skills.qualifications" | translate}}</h3>
<ng-container *ngFor="let qualificationsObj of data.qualifications; let id = index">
  <div class="Unit-unit"
  (mouseenter)="setQualifications(id)"
  (mouseleave)="removeClick()"
  (click)="setQualifications(id)"
  [ngClass]="{ 'isActive': selectedQualifications === id}">
  <div class="Line-line-container"  >
    <div class="Line-line-line">
      <div class="Field-field-fieldBase">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text pl-3">
            {{qualificationsObj.name}}
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedQualifications === id">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
            (click)="editQualifications({edit: true, qualifications: qualificationsObj, model: model, indexOfLang: data.index})"></li>
            <li class="bi bi-plus addIconBottom"  (click)="addNewQualifications({edit: false, qualifications: qualificationsObj, model: model, index: data.index, indexOfLang: data.index })"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deleteQualification(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
</ng-container>
<mat-dialog-actions align="end">
  <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
    {{ "cancel" | translate }}
  </button>
  <button type="submit" class="btn btn-success" mat-button (click)="saveData()">
    {{ "save" | translate }}
  </button>
</mat-dialog-actions>
</div>
