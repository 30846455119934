import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionService {

  emmitChanges = new BehaviorSubject<boolean>(false);

  public emmitChanges$ = this.emmitChanges.asObservable();

  constructor() { }

  emitChanges = (boolean) => {
    this.emmitChanges.next(boolean)
  }
}
