import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { JUser } from 'src/app/shared/interfaces/jira-user.model';
import { JIssue, IssuePriorityIcon, IssueType } from 'src/app/shared/interfaces/jira.model';
import { Job, Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import { IssueUtil } from 'src/app/shared/utils/issue';
import { IssueModalComponent } from '../issue-modal/issue-modal.component';

@Component({
  selector: 'app-smart-career-card',
  templateUrl: './smart-career-card.component.html',
  styleUrls: ['./smart-career-card.component.scss']
})
export class SmartCareerCardComponent implements OnInit, OnChanges {
  @Input() issue: JIssue;
  @Input() job: Job;
  @Input() isProject: boolean = false;;
  assignees: JUser[];
  public page: Page;
  issueTypeIcon: string;
  user: User;
  issueTypeIconColor: string;
  issueTypeIconBackground: string;
  priorityIcon: IssuePriorityIcon;
  dialogRef: MatDialogRef<IssueModalComponent>;
  pageSeen: boolean = false
  constructor(public dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private pageService: PagesService) {

     }

  ngOnInit(): void {

    if (this.issue.pageID) {
      this.pageService.getPage(this.issue?.pageID).subscribe(t => {
        this.page = t.page;
        this.pageSeenMsg(this.page._id);
      });
    } else {
      this.userService.getUserContactData(this.issue.userID).subscribe(response => {
        this.user = response;
      })
    }





  }
  ngOnChanges(changes: SimpleChanges): void {
    const issueChange = changes.issue;
    if (issueChange?.currentValue !== issueChange.previousValue) {
    }
  }

  openIssueModal(issueId: JIssue) {
    this.dialog.open(IssueModalComponent, {
      data: issueId
    })
  }
  openProjectModal(issueId: JIssue) {
    this.dialog.open(IssueModalComponent, {
      data: issueId
    })
  }

  public get userSeenMsg() {
    const index = this.job.manage_.findIndex(tt => tt.userID === this.issue.userID);
    return this.job.manage_[index].seen.find(tt => tt.userID === this.authService.userID &&
      typeof tt.pageID === 'undefined');
  }

  public pageSeenMsg(pageID: any) {
    const index = this.job.manage_.findIndex(tt => tt.pageID === pageID);
    this.pageSeen = this.job.manage_[index]?.seen.find(tt => tt.userID === this.authService.userID && typeof tt.pageID !== 'undefined')?.userID === this.authService.userID;
  }

}
