<main class="main">
  <div class="container">
    <div class="row g-4">
      <ng-container>
        <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-badge-ad nav-icon nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'Ads Manager'}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/ads_manager'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-badge-ad nav-icon nav-icon"></i>
                          <span>Ads Manager</span></a>
                      </li>
                      <!--    <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/create_project'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-pencil-square nav-icon"></i> <span>{{'project.create' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0"
                          [routerLink]="'/' + this.page?.pageUrl + '/projects/applied_projects'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-box-arrow-up-right nav-icon"></i>
                          <span>{{'project.applied' | translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/saved'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-bookmark-fill nav-icon"></i> <span>{{'job.savedJobs' |
                            translate}} </span></a>
                      </li>
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0" [routerLink]="'/' + this.page?.pageUrl + '/projects/ignored'"
                          [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']"
                          data-bs-toggle="tab"> <i class="bi bi-eye-slash-fill nav-icon"></i> <span>{{'job.ignoredJobs'
                            | translate}} </span></a>
                      </li> -->

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                </div>
                <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4 card px-0 py-0">
          <div class="tab-content py-0 mb-0">
            <div class="row g-2 m-3">
              <div class="col-lg-3">
                <!-- Card title -->
                <h1 class="h4 card-title mb-lg-0"> {{'sidebar.advertisement' | translate}}</h1>
              </div>
              <div class="col-sm-6 col-lg-3 ms-lg-auto">
                <!-- Select Groups -->
                <select class="form-select js-choice choice-select-text-none" data-search-enabled="false">
                  <option value="AB">Alphabetical</option>
                  <option value="NG">Newest group</option>
                  <option value="RA">Recently active</option>
                  <option value="SG">Suggested</option>
                </select>
              </div>
              <div class="col-sm-6 col-lg-3">
                <!-- Button modal -->
                <a class="btn btn-primary-soft ms-auto w-100" routerLink="create"> <i
                    class="fa-solid fa-plus pe-1"></i> {{'create' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Tab nav line -->
            <ul class="nav nav-tabs nav-bottom-line justify-content-center justify-content-md-start">
              <li class="nav-item"> <a class="nav-link active" data-bs-toggle="tab" href="#tab-1"> {{'Ads'}} </a> </li>
              <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-2"> Ad sets </a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-bs-toggle="tab" href="#tab-3"> Campaings </a>
              </li>
            </ul>
            <div class="tab-content mb-0 pb-0">

              <!-- Friends groups tab START -->
              <div class="tab-pane fade show active" id="tab-1">
                <div class="row g-4">
                  <div class="mat-elevation-z8">
                    <div fxFlex="1 0 100%" fxLayout="column" fxLayoutAlign="start stretch">
                      <mat-sidenav-container fxFlex="0 1 auto">
                        <mat-sidenav-content>
                          <div class="mat-elevation-z8 m-12">
                            <div class="table-container">
                              <!-- <table mat-table [dataSource]="dataSource"> -->
                              <table mat-table [dataSource]="dataSource">
                                <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->



                                <ng-container matColumnDef="checkbox" sticky>

                                  <mat-header-cell *matHeaderCellDef class="table-column-10">
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-10 px-0">
                                    <input type="checkbox">

                                  </mat-cell>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name" sticky>


                                  <mat-header-cell *matHeaderCellDef class="table-column-150">
                                    Name
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-150">
                                    <a href="javascript: void(0);" (click)="openAds(element)">{{element.name}}</a>

                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlant.active">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Off/On
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    <div class="form-check form-switch"><input
                                        (change)="updateActiveStatus(element, $event)" type="checkbox" role="switch"
                                        id="NotiSwitchCheckChecked" checked="" class="form-check-input"></div>
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="country">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100"> Country
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.country}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlan">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Campaign
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.value}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="budgetPlanEndDate">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Budget
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.budget}}
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="adsDetails">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Spent
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="adsDetailsUrl">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Results
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>


                                <ng-container matColumnDef="adsDetailsharedDescription">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Cost per Result
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="adsDetailbuttonName">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Reach
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.adsDetails.url}}
                                  </mat-cell>
                                </ng-container>




                                <ng-container matColumnDef="budgetPlanStartDate">
                                  <mat-header-cell *matHeaderCellDef class="table-column-100">
                                    Impressions
                                  </mat-header-cell>
                                  <mat-cell *matCellDef="let element" class="table-column-100">
                                    {{element.budgetPlan.startDate | date: 'dd/MM/yyyy'}}
                                  </mat-cell>
                                </ng-container>


                                <!-- Weight Column -->


                                <!-- Symbol Column -->


                                <!-- Position Column -->

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row stopPropagation *matRowDef="let row; columns: displayedColumns;"></mat-row>
                              </table>

                              <div>
                                <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]"
                                  [showFirstLastButtons]="true">
                                </mat-paginator>
                              </div>
                            </div>
                            <!-- end table container -->
                          </div>
                        </mat-sidenav-content>
                      </mat-sidenav-container>
                    </div>




                    <!-- <app-smart-payments-table firstTd="Name" secondTd="Status" thirdTd="Type" fourthTD="'Bill'"
                    fifthTD="'Price'" [sixthTD]="'Impression'" [data]="adsData" [reference]="true" [status]="true"
                      (print)="goForPrint($event)"></app-smart-payments-table> -->

                  </div>
                  <!-- Card START -->

                  <!-- Card END -->
                </div>
              </div>
              <!-- Friends' groups tab END -->

              <!-- Suggested for you START -->
              <div class="tab-pane fade" id="tab-2">
                <div class="row g-4">
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/01.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/01.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Real humans</a></h5>
                        <small> <i class="bi bi-globe pe-1"></i> Public Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">23k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">12</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/06.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/07.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/09.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+12</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-danger-soft btn-sm" href="#!"> Leave group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/02.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/03.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Strong signals</a></h5>
                        <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">45k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">16</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+05</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                  <div class="col-sm-6 col-lg-4">
                    <!-- Card START -->
                    <div class="card">
                      <div class="h-80px rounded-top"
                        style="background-image:url(assets/images/bg/03.jpg); background-position: center; background-size: cover; background-repeat: no-repeat;">
                      </div>
                      <!-- Card body START -->
                      <div class="card-body text-center pt-0">
                        <!-- Avatar -->
                        <div class="avatar avatar-lg mt-n5 mb-3">
                          <a href="group-details.html"><img
                              class="avatar-img rounded-circle border border-white border-3 bg-white"
                              src="assets/images/logo/05.svg" alt=""></a>
                        </div>
                        <!-- Info -->
                        <h5 class="mb-0"><a href="group-details.html">Team yes, we can</a></h5>
                        <small> <i class="bi bi-lock pe-1"></i> Private Group</small>
                        <!-- Group stat START -->
                        <div class="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">32k</h6>
                            <small>Members</small>
                          </div>
                          <!-- Divider -->
                          <div class="vr"></div>
                          <!-- Group stat item -->
                          <div>
                            <h6 class="mb-0">05</h6>
                            <small>Post per day</small>
                          </div>
                        </div>
                        <!-- Group stat END -->
                        <!-- Avatar group START -->
                        <ul class="avatar-group list-unstyled align-items-center justify-content-center mb-0 mt-3">
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/10.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/14.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/13.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/12.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/11.jpg" alt="avatar">
                          </li>
                          <li class="avatar avatar-xs">
                            <div class="avatar-img rounded-circle bg-primary"><span
                                class="smaller text-white position-absolute top-50 start-50 translate-middle">+08</span>
                            </div>
                          </li>
                        </ul>
                        <!-- Avatar group END -->
                      </div>
                      <!-- Card body END -->
                      <!-- Card Footer START -->
                      <div class="card-footer text-center">
                        <a class="btn btn-success-soft btn-sm" href="#!"> Join group </a>
                      </div>
                      <!-- Card Footer END -->
                    </div>
                    <!-- Card END -->
                  </div>
                </div>
              </div>
              <!-- Suggested for you END -->

              <!-- Popular near you START -->
              <div class="tab-pane fade" id="tab-3">
                <!-- Add group -->
                <div class="my-sm-5 py-sm-5 text-center">
                  <i class="display-1 text-muted bi bi-people"> </i>
                  <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                  <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup">
                    Click here to add </button>
                </div>
              </div>
              <!-- Popular near you END -->

              <!-- More suggestions START -->
              <div class="tab-pane fade" id="tab-4">
                <!-- Add group -->
                <div class="my-sm-5 py-sm-5 text-center">
                  <i class="display-1 text-muted bi bi-people"> </i>
                  <h4 class="mt-2 mb-3 text-body">No group founds</h4>
                  <button class="btn btn-primary-soft btn-sm" data-bs-toggle="modal" data-bs-target="#modalCreateGroup">
                    Click here to add </button>
                </div>
              </div>
              <!-- More suggestions END -->

            </div>




          </div>
        </div>


      </ng-container>
    </div>
  </div>
</main>
