import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceAreaComponent } from './service-area.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
  declarations: [
    ServiceAreaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule
  ],
  exports: [
    ServiceAreaComponent
  ]
})
export class ServiceAreaModule { }
