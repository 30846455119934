import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchUser"
})
export class SearchUserPipe implements PipeTransform {

  transform(value: any, searchValue): any {

    if (!searchValue) return value;

    // tslint:disable-next-line: max-line-length
    return value.filter((v) => v.firstName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 || v.lastName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 )

  }

}
