import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookmarksComponent } from './bookmarks.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { GravatarModule } from 'ngx-gravatar';



@NgModule({
  declarations: [
    BookmarksComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    GravatarModule
  ],

  exports: [
    BookmarksComponent
  ]
})
export class BookmarksModule { }
