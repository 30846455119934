import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-dialog-name',
  templateUrl: './user-dialog-name.component.html',
  styleUrls: ['./user-dialog-name.component.scss']
})
export class UserDialogNameComponent implements OnInit {

  public user: User;
  @Input() privacy;
  @Input() isForMessages: boolean = false;
  @Input() isUser: boolean = false;
  @Input() commentId;
  @Input() comment;


  public page: Page;

  @Output() changeEventName: EventEmitter<any> = new EventEmitter()

  constructor(private userService: UserService, private pageService: PagesService, public authService: AuthService) { }

  ngOnInit(): void {
    if (this.privacy) {
      this.userService.getUserContactData(this.privacy.userID).subscribe(t => {
        return this.user = t;
      })
    }

    if (this.isForMessages) {
      if(this.commentId) {
        this.userService.getUserContactData(this.commentId).pipe(take(1)).subscribe(t => {
          return this.user = t;
        })
      }

      if (this.comment.pageId && this.comment.userId !== this.authService.userID) {
        this.pageService.getPage(this.comment.pageId).subscribe(t => {
          this.page = t;
        })
      }

    }

  }

  addEventName(id) {
   this.changeEventName.emit(id);
  }



}
