import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PagesComponent } from "./pages.component";
import { PageDetailsComponent } from "./page-details/page-details.component";
import { SidebarPageDetailsComponent } from "./sidebar-pages/sidebar-page-details/sidebar-page-details.component";
import { PagesListComponent } from "./pages-list.component";
import { PagesRoutingModule } from "./pages-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { GravatarModule } from "ngx-gravatar";
import { SidebarPagesComponent } from "./sidebar-pages/sidebar-pages.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { SidebarPageSettingsComponent } from "./sidebar-page-settings/sidebar-page-settings.component";
import { PageContactComponent } from './page-contact/page-contact.component';
import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { EditCategoryDialogComponent } from './dialogs/edit-category-dialog/edit-category-dialog.component';
import { MaterialModule } from "src/app/material.module";
import { EditSubcategoryDialogComponent } from './dialogs/edit-subcategory-dialog/edit-subcategory-dialog.component';
import { EditBranchDialogComponent } from './dialogs/edit-branch-dialog/edit-branch-dialog.component';
import { PageJobsPublishedComponent } from './page-jobs-published/page-jobs-published.component';
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { PipeModule } from "src/app/shared/pipes/pipe.module";
import { SmartitsChipsModule } from "src/app/smartits-ui/chips/smartits-chips.module";
import { PagePaymentsComponent } from './page-payments/page-payments.component';
import { TopNavButtonsJobsProjectsModule } from "src/app/top-nav-buttons-jobs-projects/top-nav-buttons-jobs-projects.module";
import { LocationDialogComponent } from './page-details/location-dialog/location-dialog.component';
import { QuillModule } from "ngx-quill";
import { UserDialogModule } from "src/app/project-details/user-dialog-name/user-dialog.module";
import { AppliedProjectsModule } from "src/app/applied-projects/applied-projects.module";
import { CreateProjectModule } from "src/app/components/create-your-project/create-project.module";
import { JobItemModule } from "src/app/components/jobs/job-item/job-item.module";
import { SavedProjectsModule } from "src/app/saved-projects/saved-projects.module";
import { IgnoredProjectsModule } from "src/app/ignored-projects/ignores-projects.module";
import { ProjectModule } from "src/app/components/jira-system/project/project.module";
import { AppModule } from "src/app/app.module";
import { RolesDetailsComponent } from "./pages-settings/roles-details/roles-details.component";
import { ServiceAreaModule } from "src/app/components/service-area/service-area.module";
import { CreateJobModule } from "src/app/create-your-job/create-your-job.module";
import { LanguagePageModule } from "src/app/components/language-page/language-page.module";
import { SmartPaymentsTableComponent } from "src/app/components/payments/smart-payments-table/smart-payments-table.component";
import { PaymentModule } from "src/app/components/payments/payments.module";
import { SmartCareerCardComponent } from "src/app/components/jira-system/project/components/smart-career-card/smart-career-card.component";
import { PriceCardModule } from "src/app/components/price-card/price-card.module";
import { PricingModule } from "src/app/components/pricing/pricing.module";
import { NotificationsModule } from "src/app/components/notifications/notifications.module";
import { UserModule } from "../user/user.module";
import { AdsManagerComponent } from './ads-manager/ads-manager.component';
import { CarouselModule } from "ngx-owl-carousel-o";




@NgModule({
  declarations: [
    PagesComponent,
    PageDetailsComponent,
    SidebarPageDetailsComponent,
    PagesListComponent,
    SidebarPagesComponent,
    SidebarPageSettingsComponent,
    PageContactComponent,
    EditCategoryDialogComponent,
    EditSubcategoryDialogComponent,
    EditBranchDialogComponent,
    PageJobsPublishedComponent,
    PagePaymentsComponent,
    LocationDialogComponent,
    RolesDetailsComponent,
    AdsManagerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    TranslateModule,
    GravatarModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    TooltipModule,
    NgxIntlTelInputModule,
    MaterialModule,
    DirectivesModule,
    PipeModule,
    SmartitsChipsModule,
    TopNavButtonsJobsProjectsModule,
    UserDialogModule,
    AppliedProjectsModule,
    SavedProjectsModule,
    JobItemModule,
    IgnoredProjectsModule,
    CreateProjectModule,
    CreateJobModule,
    ProjectModule,
    ServiceAreaModule,
    CarouselModule,
    LanguagePageModule,
    PaymentModule,
    PriceCardModule,
    NotificationsModule,
    PricingModule,
    UserModule,
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    PagesComponent,
    PageDetailsComponent,
    SidebarPageDetailsComponent,
    PagesListComponent,
    SidebarPagesComponent,
    SidebarPageSettingsComponent,
    PageJobsPublishedComponent
  ]
})
export class PageModule {
}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

