<div class="">
  <img ngx-gravatar class="me-3" [email]="'example@mail.com'" fallback="mp" size="35">
  <span>{{user?.firstName}} {{user?.lastName}} &nbsp;</span>
  <span>{{notification.description}}</span>
</div>
<span fxFlex="30%" fxLayoutAlign="center center">
  <div >
      <button class="btn btn-primary" (click)="onAcceptButtonClick(notification.contactId)">Confirm</button>
      <button class="btn btn-secondary ms-2">Reject</button>
  </div>
</span>
<div class="d-flex column align-items-center">
  <span></span>
  <span class="radius-50 btn btn-light fa fa-ellipsis-h"></span>

</div>
