
<ng-container *ngIf="!isJob && !isProject ">
    <img [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="40">
    <a href="javascript: void(0);" (click)="selectUser()">
    <h5 >{{user?.firstName}} {{user?.lastName}}</h5>
    </a>

</ng-container>


<ng-container *ngIf="newDesign && isJob">
    <li data-bs-dismiss="offcanvas">
        <!-- Chat user tab item -->
        <a (click)="selectProject()" [ngClass]="{'active': selectedIndex === 0}" class="nav-link text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
          <div class="d-flex">
            <div class="flex-shrink-0 avatar avatar-story me-2 status-online">
              <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
            </div>
            <div class="flex-grow-1 d-block">
              <h6 *ngIf="jobData?.pageID" class="mb-0 mt-1">{{pageJobData?.name}}</h6>
              <h6 *ngIf="!jobData?.pageID" class="mb-0 mt-1"> {{userJobData?.firstName}} {{userJobData?.lastName}}</h6>
              <div class="small text-secondary">{{jobData?.positionTitle}}</div>
            </div>
          </div>
        </a>
      </li>
</ng-container>




<ng-container *ngIf="newDesign && isProject">
    <li data-bs-dismiss="offcanvas">
        <!-- Chat user tab item -->
        <a (click)="selectProject()" [ngClass]="{'active': selectedIndex === 0}" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
          <div class="d-flex">
            <div class="flex-shrink-0 avatar avatar-story me-2 status-online">
              <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
            </div>
            <div class="flex-grow-1 d-block">
              <h6 *ngIf="projectData?.pageID" class="mb-0 mt-1">{{page?.name}}</h6>
              <h6 *ngIf="!projectData?.pageID" class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
              <div class="small text-secondary">{{projectData?.positionTitle}}</div>
            </div>
          </div>
        </a>
      </li>
</ng-container>
