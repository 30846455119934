import { Component, OnInit } from "@angular/core";
import {SkillsComponent} from "../skills.component";
import {DialogService} from "../../../shared/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-skills-sec",
  templateUrl: "./skills-sec.component.html",
  styleUrls: ["./skills-sec.component.scss"]
})
export class SkillsSecComponent extends SkillsComponent implements OnInit {
  languages = [];

  constructor(
    public dialogService: DialogService,
    public authService: AuthService,
    public dialog: MatDialog,
    public http?: HttpClient) {
    super(dialogService,authService, dialog);
  }

  ngOnInit(): void {
    this.http.get<any>(`assets/languages/${this.model.language.primary}/language.json`).subscribe(data => {

      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
     
    })
  }
  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }

}
