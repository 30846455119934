<aside class="pt-4 aside" style="left:0">
  <section class="sc-smart">
    <div *ngIf="router.url === '/groups/create'">
      <div class="form p-3">
        <h2>{{'groupSettings.create' | translate}}</h2>
        <div class="inputbox mt-3">
          <input type="search" placeholder="Name" [placeholder]="'groupSettings.groupName' | translate" [(ngModel)]="group.name" name="" class="form-control" />
        </div>
        <div class="inputbox mt-3">
          <span>Category</span>
          <div class="card__body d-flex column">
            <div class="d-flex card__body__list">
              <div *ngFor="let category of categories; let i = index" class="d-flex justify-content-between pt-2 px-1">
                <div class="card__text">
                  {{ category.description }}
                  <span class="fa fa-remove" (click)="removeCategory(i)"></span>
                </div>
              </div>
              <input type="text" [(ngModel)]="searchCategory" class="form-control" />
            </div>
          </div>
        </div>
        <span *ngIf="categories.length > 2" class="error text-danger">You can choose only between 1 to 3 Categories</span>

        <div *ngIf="searchCategory">
          <div class="card" style="
              position: fixed;
              transform: translate(0px, -15px);
              width: 228px;
              z-index: 1000;
              display: flex;
        flex-direction: column;
            ">
            <div *ngFor="let cat of categoryService.categoryy" class="card__list">
              <button [disabled]="searchCategory.length > 2" class="btn btn-light" (click)="addCategory(cat)">
                <span>
                  {{ cat.description}}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="inputbox mt-3">
          <select #selectedValue name="selectedValue"
          id="selectedValue" [(ngModel)]="empty"
          (ngModelChange)="addPrivacy($event)" class="col-md-12 form-control" placeholder="d.ff">
          <option hidden value="" disabled selected>{{'groupSettings.privacy' | translate}}</option>
  <option *ngFor="let t of privacy"
          [ngValue]="t" (change)="addPrivacy(t)" [selected]="t">
    {{t.name}}
  </option>
</select>

        </div>
        <div class="d-flex justify-content-between align-items-center pt-3" routerLink="/groups">
          <div class="text-right button-width-100" (click)="createGroup()">
            <button [disabled]="!group.name" class="btn btn-success register btn-block">
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>

</aside>



<div class="main" *ngIf="router.url === '/groups/create'">
  <div class="d-flex flex-column border-shadow">
    <div class="jumbotron" style="padding-right: 0;
    padding-left: 0;
    padding-top: 0;">
      <div class="container px-0">

        <div class="cover-photo">
          <div class="cover-image">

            <img  style="width: 100%;" src="../../../assets/img/User_BG.jpg">

          </div>

          <div class="profile-picture">
            <img  [round]="false" ngx-gravatar [email]="'example@mail.com'"
              fallback="mp" size="140" cornerRadius="10">


          </div>
      </div>
      <div class="d-flex column justify-content-center" style="transform: translate(12rem, 2rem);
      height: 97px;
      flex-direction: column;
      align-items: start;">
        <h1 class="mb-0">{{group?.name}}</h1>
        <h4 class="mb-0">{{group?.slogan}}</h4>
      </div>
      <br>
      <hr class="pt-16">
      <div class="d-flex justify-content-between px-0" style="position: sticky">
        <div class="d-flex justify-content-start">
          <a class="btn btn-light" >
            <div class="px-2 font-weight-600 active-route">
              {{'userProfile.startPage' | translate}}
            </div>
          </a>
          <a class="btn btn-light active-route">
            <div class="px-2 font-weight-600">
              {{'userProfile.shop' | translate}}
            </div>
          </a>

        </div>
        <div class="d-flex">
          <a class="px-1">
            <button class="px-3 font-weight-600 btn btn-light" [tooltip]="'Add Post'">
              <span class="bi bi-plus"></span>
            </button>
          </a>
          <a class="px-1" routerLink="info">
            <button  class="px-3 font-weight-600 btn btn-light" tooltip="Edit Page">
              <span class="bi bi-pencil"></span>
            </button>
          </a>

          <a class="px-1">
            <button class="px-3 font-weight-600 btn btn-light" [tooltip]="'userProfileTooltips.viewAs' | translate">
              <span class="fa fa-eye"></span>
            </button>
          </a>
          <a class="px-1">
            <button class="px-3 font-weight-600 btn btn-light"
              [tooltip]="'userProfileTooltips.searchProfile' | translate">
              <span class="fa fa-search"></span>
            </button>
          </a>
          <a class="px-1">
            <button class="px-3 font-weight-600 btn btn-light">
              <span class="fa fa-ellipsis-h"></span>
            </button>
          </a>


        </div>
      </div>

    </div>
  </div>
</div>
