import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserSearchLocation } from 'src/app/shared/interfaces/model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { User } from 'src/app/shared/interfaces/user.model';

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss']
})
export class ServiceAreaComponent implements OnInit {
  @Input() data;
  @Input() dataArray: UserSearchLocation[];
  @Input() activeSearch: boolean = false;
  @Input() user: User;

  countryJson;
  selectedWorkplace: boolean = false;
  selectedIndexPage: boolean = false;
  @Output() buttonSaved = new EventEmitter<boolean>();



  toggleOptions: Array<String> = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];


  constructor(
    public titleService: ArraysService,
    public countryDataService?: CountryDataService,
    ) { }

  ngOnInit(): void {
    console.log(this.dataArray, "keke")
    this.titleService.showRemoteWithoutHybrid();
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);

  }

  remoteCheck(event) {

    this.data = event;
    this.user.searchLocation.remote = this.data;
  }

  addSearchLocation() {
    let digit = this.dataArray[0].postalCode.toString()[0];
    const data: UserSearchLocation = {
      c: this.dataArray[0].c,
      region: [`${digit}`],
      postalCode: this.dataArray[0].postalCode.toString(),

    }
    this.dataArray.unshift(data);
    this.buttonSaved.emit();
  }

  selectWorkplace() {
    this.selectedWorkplace = true;
  }
  saveNotePageProject(item, index) {
    this.selectedWorkplace = false;
  }
  onChange(value, index) {
    this.dataArray[index].c = value;
    }

  selectionChanged(param, index) {
    if (this.dataArray[index]?.region.length > 1) {
      this.dataArray[index].postalCode = null;
      this.dataArray[index].postalCode = "";
    } else if (this.dataArray[index]?.region.length === 0 ) {
      this.dataArray[index].postalCode = null;
      this.dataArray[index].postalCode = "";
    } else {
      this.dataArray[index]?.region.forEach(i =>  this.dataArray[index].postalCode = i);
    }
  }

  selectIndexForButtonsPage(index) {
    this.selectedIndexPage = index;
  }

  saveSearchLocation() {
    this.buttonSaved.emit();
    this.selectedWorkplace = false;
    this.selectedIndexPage = undefined;

  }


  deleteSearchFilter(index) {
    this.dataArray.splice(index, 1);
    this.buttonSaved.emit();
  }


}
