import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipsComponent } from './chips.component';
import { MatChipsModule } from '@angular/material/chips';
import { MaterialModule } from 'src/app/material.module';



@NgModule({
  declarations: [
    ChipsComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MaterialModule
  ],
  exports: [
    ChipsComponent
  ]
})
export class SmartitsChipsModule { }
