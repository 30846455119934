
  <main>

    <!-- Container START -->
    <div class="container">
      <div class="row g-4">

         <!-- Sidenav START -->
         <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <div class="d-flex align-items-center d-lg-none">
              <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                  <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                  <span class="h6 mb-0 fw-bold d-lg-none ms-2">My profile</span>
              </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <!-- Navbar START-->
          <nav class="navbar navbar-expand-lg mx-0">
              <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                  <!-- Offcanvas header -->
                  <div class="offcanvas-header">
                      <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>

                  <!-- Offcanvas body -->
                  <div class="offcanvas-body d-block px-2 px-lg-0">
                      <!-- Card START -->
                      <div class="card overflow-hidden">
                          <!-- Cover image -->

                              <!-- Card body START -->
                              <div class="card-body pt-0">
                              <!-- Side Nav START -->
                              <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
                                      <!-- Avatar -->
                                      <button type="button" class="button-f button-sc px-0" (click)="navigateToUser()">
                                          <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                            <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
                                             <span class="span-padding-left10">{{currentUser?.firstName}} {{currentUser?.lastName}}</span>
                                        </button>
                                      <!-- Post input -->
                                  <li class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/cv'">
                                      <a class="nav-link" > <i class="bi bi-file-text"></i>
                                          <span class="ms-2">{{'header.cv' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/pages">
                                      <a class="nav-link" > <i class="bi bi-flag"></i><span class="ms-2">{{'header.pages' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/groups">
                                      <a class="nav-link" > <i class="bi bi-people"></i>
                                          <span class="ms-2">{{'header.groups' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" routerLink="/contacts">
                                      <a class="nav-link"><i class="bi bi-person"></i>
                                           <span class="ms-2">{{'header.contacts' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" [routerLink]="'../' + currentUser?.userUrl + '/jobs'">
                                      <a class="nav-link" ><i class="bi bi-list-task"></i>
                                          <span class="ms-2">{{'sidebar.jobs' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" [routerLink]="'../'+ currentUser?.userUrl + '/projects'">
                                      <a class="nav-link"><i class="bi bi-bar-chart-steps fs-6"></i><span class="ms-2">{{'sidebar.projects' | translate}} </span></a>
                                  </li>
                                  <li class="nav-item" (click)="navigateToPayment()">
                                      <a class="nav-link"> <i class="bi bi-credit-card"></i><span class="ms-2">{{'sidebar.payment' | translate}} </span></a>
                                  </li>
                              </ul>
                              <!-- Side Nav END -->
                          </div>
                          <!-- Card body END -->
                          <!-- Card footer -->
                          <div class="card-footer text-center py-2">
                              <a class="btn btn-link btn-sm" href="my-profile.html">View Profile </a>
                          </div>
                      </div>
                      <!-- Card END -->

                      <!-- Helper link START -->
                      <ul class="nav small mt-4 justify-content-center lh-1">
                          <li class="nav-item">
                              <a class="nav-link" href="my-profile-about.html">About</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="settings.html">Settings</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="help.html">Help</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                          </li>
                      </ul>
                      <!-- Helper link END -->
                      <!-- Copyright -->
                      <p class="small text-center mt-1">©2023 <a class="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </a></p>
                  </div>
              </div>
          </nav>
          <!-- Navbar END-->
      </div>
        <!-- Sidenav END -->

        <!-- Main content START -->
        <div class="col-md-8 col-lg-6 vstack gap-4">
          <!-- Create a page START -->
          <div class="card">
            <!-- Title START -->
            <div class="card-header border-0 pb-0">
              <h1 class="h4 card-title mb-0">Create a page</h1>
            </div>
            <!-- Title END -->
            <!-- Create a page form START -->
            <div class="card-body">
              <form class="row g-3" name="ngForm" #pageForm="ngForm" (ngSubmit)="pageForm.form.valid && createPage(pageForm)" >
                <!-- Page information -->
                <div class="col-12">
                  <label class="form-label">{{ "pagesInfo.pageName" | translate }}</label>
                  <input type="text" class="form-control"  name="pageName" [(ngModel)]="page.name" #pageName="ngModel"   [ngClass]="{ 'is-invalid': pageForm.submitted && pageName.invalid }" required>
                  <small>Name that describes what the page is about.</small>
                  <div *ngIf="pageForm.submitted && pageName.invalid" class="invalid-feedback">
                    <div *ngIf="pageName.errors.required">
                    {{'Page name is required'}}
                    </div>
                  </div>
                </div>
                <!-- Display name -->
             <!--  <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Display name</label>
                  <input type="text" class="form-control" placeholder="Display name (Required)">
                </div> -->
                 <!-- Phone number -->
                 <div class="col-lg-6">
                  <label class="form-label">Phone number</label>
                  <br>
                  <ngx-intl-tel-input [enablePlaceholder]="true"
                  [preferredCountries]="['de', 'us', 'gb', 'it', 'es', 'fr', 'ch', 'at']"
                  [separateDialCode]="true" #phone="ngModel" name="phone" id="phone" required
                  autoComplete="off" [phoneValidation]="true" [(ngModel)]="phone.number"
                  [selectedCountryISO]="phone.countryCode" [cssClass]="'form-control col-md-12'">
                </ngx-intl-tel-input>
                </div>
                <!-- Email -->
                <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Email</label>
                  <input type="text" class="form-control"  name="email" #email="ngModel" [(ngModel)]="page.email" placeholder="Email (Required)">
                </div>
                <!-- Category -->
                <div class="col-sm-6 col-lg-4">
                  <label class="form-label">Branch (required)</label>
                  <select class="form-select js-choice" data-search-enabled="true" id="branches" name="branches" #branches="ngModel"
                  [(ngModel)]="page.branches">
                    <option *ngFor="let level of branchesService.branches" [ngValue]="level.id" [selected]="level">    {{ level.description | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{ "pagesInfo.categories" | translate }}</label>
                  <select class="form-select js-choice" id="categories" name="categories" #categories="ngModel" [ngModel]="page?.categories?.name" (ngModelChange)="showChildren($event)" data-search-enabled="true">
                    <option *ngFor="let level of categoryService.categories" [ngValue]="level.id" [selected]="level">    {{ level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <label class="form-label">{{ "categories.subCategories.name" | translate }}</label>
                  <select class="form-select js-choice" name="subCategory" #subCategory="ngModel" [ngModel]="selectedChild" (ngModelChange)="showKeywords($event)" data-search-enabled="true">
                    <option *ngFor="let level of selectedChildren" [ngValue]="level.id"
                    (change)="showKeywords($event)">    {{ level.name | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-6 col-lg-6 d-flex align-items-end">
                    <div class="d-flex align-items-end pt-3 pb-3 " style="width:100%"><b class="me-2 ms-2">Private account</b><mat-slide-toggle formControlName="accountPrivacy" ></mat-slide-toggle></div>

                </div>
                <div class="col-sm-6 col-lg-12">
                  <label>{{'pagesInfo.keywords' | translate}}</label>
                  <div class="d-flex align-items-center card__body__list">
                    <div *ngFor="let sp of selectedKeywords; let i=index" class="d-flex justify-content-between pr-1">
                      <div class="card__text">
                        {{sp.name}}
                        <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeKeyword(i)"><i class="fa fa-remove"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex col-lg-12 col-sm-6">
                    <input [(ngModel)]="keyword" #key="ngModel" name="key" [required]="selectedKeywords?.length < 1" class="form-control">

                    <button type="button" class="btn btn-light ms-3 btn-light-keyword" [disabled]="!keyword" (click)="addKeyword()">
                      <i class="bi bi-plus fa-lg"></i></button>
                  </div>

                </div>
                <br>
                <hr/>

                <div class="col row col-md-12">
                  <span class="firstLabel">{{ "data.address" | translate }} </span>

                </div>
                <li class="line form-row">
                  <div class="col row col-md-12">
                    <div class="col-md-12">
                      <label>{{ "addressData.street" | translate }}</label>
                      <input class="form-control col-md-12" autocomplete="off"
                        [ngModel]="currentUser?.address.data[0]?.street" name="street"   #street="ngModel" (ngModelChange)="currentUser.address.data[0].street = $event" />
                    </div>
                  </div>
                </li>
                <li class="line form-row">
                  <div class="col row col-md-12">
                    <div class="col-md-4">
                      <label>{{ "data.zip" | translate }}</label>
                      <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.postalCode"
                        #postalCode="ngModel" name="postalCode" autocomplete="postal-code" required />

                    </div>
                    <div class="col-md-8">
                      <label>{{ "data.city" | translate }}</label>
                      <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.city" name="city"
                        #city="ngModel" required />

                    </div>
                  </div>
                </li>
                <li class="line form-row">
                  <div class="col row col-md-12">
                    <div class="col-md-6">
                      <label>{{ "data.country" | translate }}</label>
                      <div class="col-lg-12">

                        <input name="country" [ngModel]="address?.value" (ngModelChange)="onChange($event)" required
                          list="encodings" class="col-sm-6 form-control custom-select custom-select-sm"
                          autocomplete="off">
                        <datalist id="encodings" >
                          <option *ngFor="let country of languages" [value]="country.value"
                            [selected]="selectedCountry" (change)="onChange(country)">
                            {{ country.value }}
                          </option>
                        </datalist>
                      </div>


                    </div>
                    <div class="col-md-6">
                      <label>{{ "data.state" | translate }}</label>
                      <input class="form-control col-md-12" [ngModel]="currentUser?.address.data[0]?.state" name="state"
                        #state="ngModel" />

                    </div>
                  </div>
                </li>
                <!-- Website URL -->
                <!--<div class="col-sm-6">
                  <label class="form-label">Website URL</label>
                  <input type="text" class="form-control" placeholder="https://www.webestica.com">
                </div> -->

                <!-- Page information -->
               <!-- <div class="col-12">
                  <label class="form-label">About page</label>
                  <textarea class="form-control" rows="3" placeholder="Description (Required)"></textarea>
                  <small>Character limit: 300</small>
                </div> -->

                <!-- Divider -->
                <hr>

                <!-- Social Links START -->
                <!--<div class="col-12">
                  <h5 class="card-title mb-0">Social Links</h5>
                </div> -->
                <!-- Facebook -->
                <!--<div class="col-sm-6">
                  <label  class="form-label">Facebook</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-facebook text-facebook"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.facebook.com">
                  </div>
                </div> -->
                <!-- Twitter -->
                <!--<div class="col-sm-6">
                  <label class="form-label">Twitter</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-twitter text-twitter"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.twitter.com">
                  </div>
                </div> -->
                <!-- Instagram -->
               <!-- <div class="col-sm-6">
                  <label class="form-label">Instagram</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-instagram text-instagram"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.instagram.com">
                  </div>
                </div>-->
                <!-- Pinterest -->
                <!--<div class="col-sm-6">
                  <label class="form-label">Pinterest</label>
                  <div class="input-group">
                    <span class="input-group-text border-0"> <i class="bi bi-pinterest text-pinterest"></i> </span>
                    <input type="text" class="form-control" placeholder="https://www.pinterest.com">
                  </div>
                </div>-->
                <!-- Button  -->
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary mb-0">Create a page</button>
                </div>
              </form>
            </div>
            <!-- Create a page form END -->
          </div>
          <!-- Create a page END -->
        </div>

      </div> <!-- Row END -->
    </div>
    <!-- Container END -->

  </main>
