<app-sidebar-groups-details [group]="group" (changeEdit)="groupInfo()"></app-sidebar-groups-details>
<div class="main">
  <div class="d-flex flex-column border-shadow">
    <div class="jumbotron p-0">
      <div class="container px-0">
        <div class="cover-photo">
          <div class="cover-image">
            <img
              *ngIf="hasCoverPhoto"
              [src]="coverImage"
              style="width: 100%"
              (error)="img.src = '../../../assets/img/User_BG.jpg'"
              #img
            />
            <img
              *ngIf="!hasCoverPhoto"
              style="width: 100%"
              src="../../../assets/img/User_BG.jpg"
            />
          </div>
          <div
            class="btn-group button-cover-upload"
            id="dropdown02"
            data-toggle="dropdown"
          >
            <label for="imageCover" title="Upload image file">
              <input class="sr-only" id="imageCover" name="file" />
              <span
                class="docs-tooltip"
                data-toggle="tooltip"
                title=""
                data-original-title="Import image with Blob URLs"
              >
                <span
                  class="fa fa-camera img-profile-upload-fa"
                  style="transform: translate(0, 0)"
                ></span>
              </span>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown02"
              >
                <a class="fa fa-picture-o dropdown-item"
                  ><span class="span-padding-left10">Select Photo</span></a
                >
                <a class="fa fa-cloud-upload dropdown-item" href="#"
                  ><span
                    class="span-padding-left10"
                    (click)="
                      uploadCoverPicture({ group: group, editGroup: true })
                    "
                    >Upload Photo</span
                  ></a
                >
                <a class="fa fa-arrows dropdown-item" href="#"
                  ><span class="span-padding-left10">Reposition</span></a
                >
                <hr />
                <a class="bi bi-trash-o dropdown-item" href="#"
                  ><span class="span-padding-left10">Remove</span></a
                >
              </div>
            </label>
          </div>
        </div>
        <div class="d-flex row height50">
          <div class="profile-picture">
            <img
              *ngIf="hasPhoto"
              [src]="this.profileImage"
              ngx-gravatar
              [email]="'example@mail.com'"
              fallback="mp"
              size="140"
              cornerRadius="10"
              borderWidth="3" borderColor="#ffffff"
            />
            <img
              *ngIf="!hasPhoto"
              ngx-gravatar
              [email]="'example@mail.com'"
              fallback="mp"
              size="140"
              cornerRadius="10"
              borderWidth="3" borderColor="#ffffff"
            />

            <i
              class="fa fa-camera img-profile-upload-fa"
              (click)="openProfileImgDialog({ group: group, editGroup: true })"
            ></i>
          </div>
          <div
            class="d-flex justify-content-between"
            style="
              flex: 0 0 77%;
              transform: translate(-2rem, -0.9rem);
              height: 97px;
              flex-direction: row;
              align-items: start;
            "
          >
            <div class="d-flex column">
              <h1 class="mb-0">{{ group.name }}</h1>
              <h4>{{privacyName}} {{'group'}}</h4>
            </div>
            <button class="btn btn-light">
              <i class="bi bi-plus font-size1_25rem pr-2"></i>
              <span>Invite</span>
            </button>
          </div>
          <br />
        </div>
        <hr />
        <div
          class="d-flex justify-content-between px-0"
          style="position: sticky"
        >
          <div class="d-flex justify-content-start" style="align-items: center">
            <a class="btn btn-light active-route">
              <div class="px-2 font-weight-600 active-route">
                {{ "userProfile.startPage" | translate }}
              </div>
            </a>
            <a class="btn btn-light active-route">
              <div class="px-2 font-weight-600">
                {{ "userProfile.shop" | translate }}
              </div>
            </a>
          </div>
          <div class="">
           <!-- <a class="px-1" (click)="editGroup()">
              <button
                [disabled]="editGroupInfo"
                routerLink="settings/about"
                class="px-3 font-weight-600 btn btn-light"
                tooltip="Edit Group"
              >
                <span class="bi bi-pencil"></span>
              </button>
            </a> -->
            <a class="px-1">
              <button
                class="px-3 font-weight-600 btn btn-light"
                [tooltip]="'userProfileTooltips.searchProfile' | translate"
              >
                <span class="fa fa-search"></span>
              </button>
            </a>
            <a class="px-1">
              <button class="px-3 font-weight-600 btn btn-light">
                <span class="fa fa-ellipsis-h"></span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between width-900 pt-3">
        <div class="user-sidebar" *ngIf="editGroupInfo">
          <button
            type="button"
            [routerLinkActive]="['active']"
            class="btn-light button-f button-sc"
            routerLink="settings/about">
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.setUp" | translate}}</span>
            </div>
          </button>
          <button type="button" class="btn-light button-f button-sc" routerLink="settings/customize" [routerLinkActive]="['active']" >
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.customize" | translate}}</span>
            </div>
          </button>
          <button type="button" class="btn-light button-f button-sc" routerLink="settings/features" [routerLinkActive]="['active']" >
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.features" | translate}}</span>
            </div>
          </button>
          <button type="button" class="btn-light button-f button-sc" routerLink="settings/membership" [routerLinkActive]="['active']" >
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.manageMembership" | translate}}</span>
            </div>
          </button>
          <button type="button" class="btn-light button-f button-sc" routerLink="settings/discussion" [routerLinkActive]="['active']" >
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.manageDiscussion" | translate}}</span>
            </div>
          </button>
          <button type="button" class="btn-light button-f button-sc" routerLink="settings/advanced" [routerLinkActive]="['active']" >
            <div>
              <span class="span-padding-left10">{{"groupSettings.dataSidebar.manageAdvancedSettings" | translate}}</span>
            </div>
          </button>

        </div>
        <div class="user-sidebar" *ngIf="!editGroupInfo">
          <button
            type="button"
            [routerLinkActive]="['active']"
            class="button-f button-sc"
            routerLinkActive="active"
          >
            <div>
              <span class="span-padding-left10">{{
                "pagesInfo.about" | translate
              }}</span>
            </div>
          </button>

          <button type="button" class="button-f button-sc">
            <div>
              <span class="span-padding-left10">Discussion</span>
            </div>
          </button>
          <button type="button" class="button-f button-sc">
            <div>
              <span class="span-padding-left10">{{
                "pagesInfo.people" | translate
              }}</span>
            </div>
          </button>
          <button type="button" class="button-f button-sc">
            <div>
              <span class="span-padding-left10">{{
                "pagesInfo.events" | translate
              }}</span>
            </div>
          </button>
        </div>
        <div class="user-data edit pt-3 pb-3" *ngIf="editGroupInfo">
          <div class="">
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/about'">
              <div class="col-md-12 pb-2">
                <label>{{ "groupSettings.editName" | translate }}</label>
                <input
                  maxlength="75"
                  name="group.name"
                  [(ngModel)]="group.name"
                  class="form-control"
                />
              </div>
              <div class="col-md-12 inputbox mt-3">
                <span>Category</span>
                <div class="card__body d-flex column">
                  <div class="d-flex card__body__list">
                    <div *ngFor="let category of group.categories; let i = index" class="d-flex justify-content-between pt-2 px-1">
                      <div class="card__text">
                        {{ category.description }}
                        <span class="fa fa-remove" (click)="removeCategory(i)"></span>
                      </div>
                    </div>
                    <input type="text" [(ngModel)]="searchCategory" class="form-control" />
                  </div>
                </div>
              </div>
              <span *ngIf="group.categories.length > 2" class="error text-danger">You can choose only between 1 to 3 Categories</span>

              <div *ngIf="searchCategory">
                <div class="card" style="
                       position: absolute;
    transform: translate(0px, -15px);
    width: 91%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
                  ">
                  <div *ngFor="let cat of categoryService.category" class="card__list">
                    <button [disabled]="group.categories.length > 2" class="btn btn-light" (click)="addCategory(cat)">
                      <span>
                        {{ cat.description}}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-12 pb-2">
                <select
                  #selectedValue
                  name="selectedValue"
                  id="selectedValue"
                  [(ngModel)]="empty"
                  (ngModelChange)="addPrivacy($event)"
                  class="col-md-12 form-control"
                  placeholder="d.ff"
                >
                  <option hidden value="" disabled [selected]="group.privacy">
                    {{ "groupSettings.privacy" | translate }}
                  </option>
                  <option
                    *ngFor="let t of privacy"
                    [ngValue]="t"
                    (change)="addPrivacy(t)"
                    [selected]="group.privacy"
                  >
                    {{ t.name }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 pb-2">
                <label>{{ "groupSettings.description" | translate }}</label>
                <quill-editor
                  [(ngModel)]="group.description"
                  [modules]="config"
                ></quill-editor>
              </div>

            </div>
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/customize'">
              <div class="col-md-12 pb-2">
                  <label>{{ "groupSettings.url" | translate }}</label>
                  <input
                    maxlength="75"
                    [ngModel]="group.url"
                    (ngModelChange)="changeUrl($event)"
                    class="form-control"
                  />
                  <br>
                <label>{{ "groupSettings.groupColor" | translate }}</label>
                <input
                  maxlength="75"
                  [(ngModel)]="group.color"
                  class="form-control"
                />
                <br>
                <label>{{ "groupSettings.badges" | translate }}</label>
                <input
                  maxlength="75"
                  [(ngModel)]="group.badges"
                  class="form-control"
                />
                <br>
                <label>{{ "groupSettings.affiliation" | translate }}</label>
                <input
                  maxlength="75"
                  [(ngModel)]="group.affiliation"
                  class="form-control"
                />

              </div>
            </div>
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/features'">
              <div class="col-md-12 pb-2">
              </div>
            </div>
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/membership'">
              <div class="col-md-12 pb-2">
              </div>
            </div>
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/discussion'">
              <div class="col-md-12 pb-2">
              </div>
            </div>
            <div class="col-md-12" *ngIf="router.url === '/groups/' + this.group.url + '/settings/advanced'">
              <div class="col-md-12 pb-2">
              </div>
            </div>
          </div>
          <div class="page__buttons">
            <a *ngIf="editGroupInfo" class="px-1">
              <button
                routerLink="about"
                class="px-3 font-weight-600 btn btn-secondary"
                (click)="cancel()"
              >
                <span>{{ "cancel" | translate }}</span>
              </button>
            </a>

            <a *ngIf="editGroupInfo" class="px-1">
              <button
                class="px-3 font-weight-600 btn btn-success"
                routerLink="about"
                (click)="update(); editGroupInfo = !editGroupInfo"
              >
                <span>{{ "save" | translate }}</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="jumbotron p-0" *ngIf="router.url === '/groups/' + this.group.url + '/member-requests'">
       <div class="container px-0 column">
        <div class="d-flex justify-content-between p-3">
          <input type="text" class="form-control col-md-4">
          <button class="btn btn-light">Suggested</button>
          </div>
       </div>
       <div class="d-flex align-items-center justify-content-center">
        <h2>No Pending Members</h2>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/auto_approve_requests'">
      <div class="d-flex p-3 justify-content-start column align-items-center">
          <h1>You haven't set up requirements for automatically approving members yet. Once you do, future pending members that have what's required for your group will be instantly added.</h1>
          <button class="btn btn-primary">Set up Criteria</button>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/membership_questions'">
      <div class="d-flex p-3 justify-content-start column align-items-center">
        <h1>{{'groupSettings.sidebar.membershipQuestions' | translate}}</h1>
          <h3 class="d-flex text-align-center">Ask pending members up to three questions when they request to join your group. Only admins and moderators will see the answers.</h3>
          <button class="btn btn-primary">Add Question</button>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/pending_posts'">
      <div class="d-flex align-items-center column width-900">
      <div class="card d-flex justify-content-between width-400 align-items-center">
        <h3 class="m-0">Pending Posts 0</h3>
        <button class="btn btn-light">Newest first</button>
      </div>
      </div>
      <div class="d-flex p-3 justify-content-start column align-items-center">
        <h1>No Pending Posts</h1>
          <h3 class="d-flex text-align-center">There are no pending posts to review</h3>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/post_tags_list'">
      <div class="d-flex align-items-center column width-900">
      <div class="card d-flex justify-content-between width-900 align-items-center">
        <h3 class="m-0">Post Topics</h3>
        <button class="btn btn-link">Create</button>
      </div>
      <div class="card d-flex column justify-content-between width-900 align-items-center">
        <div class="row d-flex justify-content-between width-900 p-2">
        <input type="text" placeholder="search for a topic" class="form-control col-md-4">
        <button class="btn btn-light">Default</button>
      </div>
        <div class="d-flex column pt-5 align-items-center">
          <h2>There are no post topics in your group yet</h2>
          <h3>Create a post topic to get started.</h3>
        </div>
      </div>
      </div>

    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/scheduled_posts'" class="width-900">
      <div class="d-flex p-3 justify-content-start column align-items-center">
        <h1>No Scheduled Posts</h1>
          <h3 class="d-flex text-align-center">You can schedule posts to be shared to the group at the date and time you choose.</h3>
          <button class="btn btn-primary">Create Post</button>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/admin_activities'" class="width-900">
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/manage_rules'" class="width-900">
      <div class="d-flex p-3 justify-content-start column align-items-center">
        <h1>No Rules Set Up</h1>
          <h3 class="d-flex text-align-center">Use rules to help set the tone for your group and help prevent member conflict. Write up to 10 rules about your group.</h3>
          <button class="btn btn-primary">Get Started</button>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/member_reported_content'" class="width-900">
      <div class="d-flex p-3 mt-5 justify-content-start column align-items-center">
        <h1>No Member-Reported Content</h1>
          <h3 class="d-flex text-align-center">If members report content to the group admins, you can review it here.</h3>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/alerted'" class="width-900">
      <div class="d-flex p-3 mt-5 justify-content-start column align-items-center">
        <h1>No Moderation Alerts</h1>
          <h3 class="d-flex text-align-center">No posts or comments have triggered the alerts you've set up.</h3>
          <button class="btn btn-primary">Edit Alerts</button>
      </div>
    </div>
    <div *ngIf="router.url === '/groups/' + this.group.url + '/group_quality'">
      <div class="d-flex align-items-center column width-900">
      <div class="card d-flex column width-900 align-items-start">
        <h2 class="mt-1 mb-1">{{'groupSettings.sidebar.quality' | translate}}</h2>
        <h3>We want to help you keep your group safe. Group Quality lets you know if content in your group goes against our policies, what we've done about it, and what you can do.</h3>
      </div>
      <div class="card d-flex  justify-content-between width-900 align-items-center">
        <div class="row d-flex justify-content-between width-900 p-2">
        <h3>NO UPDATES FOR YOUR GROUP</h3>
      </div>
      </div>
      </div>

    </div>
  </div>
</div>
