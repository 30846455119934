import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss']
})
export class WarningDialogComponent implements OnInit {
  public message:string;

  loginForm: UntypedFormGroup;
  public errorMessage = "";
  public email = "";
  public password = "";
  public loading = false;


  constructor(
    public fb: UntypedFormBuilder,
    public authService: AuthService,
    public router: Router,
    public dialogRef: MatDialogRef<WarningDialogComponent>
  ) {
    this.loginForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([]);
    }
  }

  loginUser() {
    this.errorMessage = "";
    this.loading = true;
    this.email = this.loginForm.controls.email.value.toLowerCase();

    this.authService.signIn(this.email, this.password)
        .then(res => {
          localStorage.setItem("access_token", res.token);
          this.loading = false;
          this.router.navigate(["/cv/" + res._id], {replaceUrl: true});
          this.loginForm.reset();
          this.dialogRef.close();
        })
        .catch((error: HttpErrorResponse) => {
          this.loading = false;
          switch (error.status) {
            case 404:
              this.errorMessage = "Invalid Login";
              break;
            case 401:
              this.errorMessage = "Invalid password";
              break;
            default:
              this.errorMessage = "Login failed: " + error.message;
          }
        });
  }
  register() {
    this.router.navigateByUrl("/register");
    this.dialogRef.close();
  }
}
