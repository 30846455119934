import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IssueStatus } from 'src/app/shared/interfaces/jira.model';

@Component({
  selector: 'app-board-dnd',
  templateUrl: './board-dnd.component.html',
  styleUrls: ['./board-dnd.component.scss']
})
export class BoardDndComponent implements OnInit, OnChanges {
  issueStatuses: IssueStatus[] = [
    IssueStatus.Applied,
    IssueStatus.IN_PROGRESS,
    IssueStatus.DONE
  ];
  constructor(private cd: ChangeDetectorRef) { }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }

}
