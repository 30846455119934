<div class="search-widget">
    <ul class="ui-selection-list">
      <li class="line form-row">
        <div class="col-12 pb-2 pt-2 specialty" id="sp">
          <div class="card__body d-flex column">
            <div class="d-flex align-items-start card__body__list">
              <div *ngFor="let sp of user?.searchFilterSkills?.searchQualifications.data; let i=index"
                class="d-flex justify-content-between pr-1 pt-1">
                <div class="card__text">
                  {{sp.name}}
                  <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeQualifications(i)"><i class="fa fa-remove"></i></button>
                </div>
              </div>
              <button class="btn btn-light ms-1" (click)="replaceQualifications()">
                <i class="bi bi-arrow-repeat"></i></button>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
