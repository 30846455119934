<div class="container" *ngIf="!router.url.endsWith('cancel') && !router.url.endsWith('applied_projects') && !router.url.endsWith('ourprojects')">
  <share-buttons [theme]="'modern-light'" *ngIf="router.url.includes('public')" style="
    display: flex;
    position: absolute;
    justify-content: center;
    position: relative;"
    [include]="['facebook','twitter','linkedin','reddit','telegram','whatsapp','xing','email','copy']" [size]="-4"
    [autoSetMeta]="false"></share-buttons>
  <div class="body pt-4 pb-4">
    <div class="flex row col-md-12 job-buttons"
      *ngIf="router.url !== '/jobs/public/' + job?._id && router.url !== '/projects/public/' + project?._id">
      <button class="btn btn-light col-md-3" (click)="searchJob()" *ngIf="!isProject">
        {{'job.search' | translate}}
      </button>
      <button class="btn btn-light col-md-3" (click)="searchProject()" *ngIf="isProject">
        {{'project.search' | translate}}
      </button>
      <button class="btn btn-light col-md-2" (click)="manageJob(project?._id)">
        Manage
      </button>
      <button *ngIf="!isProject" class="btn btn-light col-md-4" (click)="publicJob()" [disabled]="project?.public">
        {{'job.public' | translate}}
      </button>
      <button *ngIf="isProject" class="btn btn-light col-md-3" (click)="publicProject()" [disabled]="project?.public">
        {{'project.public' | translate}}
      </button>
    </div>


    <div class="col-md-12 px-0">
      <div class="flex row job-buttons buttons-public col-md-12"
        *ngIf="router.url === '/projects/public/' + project?._id">
        <button type="button" (click)="applyJob(job)" class="btn btn-light col-md-2"><i
            class="me-1 bi bi-box-arrow-up-right"></i>
          {{'job.apply' | translate}}</button>
        <button type="button" class="btn btn-light col-md-2" stopPropagation><i class="me-1 bi bi-bookmark"></i><span>
            {{"save" | translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-eye-slash"></i>
          <span>{{'job.ignoredJobs' | translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-share"></i> <span>{{'job.share' |
            translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-three-dots"></i></button>
      </div>
      <div class="flex row job-buttons buttons-public col-md-12" *ngIf="router.url === '/jobs/public/' + job?._id">
        <button type="button" (click)="applyJob(project)" class="btn btn-light col-md-2"><i
            class="me-1 bi bi-box-arrow-up-right"></i>
          {{'job.apply' | translate}}</button>
        <button type="button" class="btn btn-light col-md-2" stopPropagation><i class="me-1 bi bi-bookmark"></i><span>
            {{"save" | translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-eye-slash"></i>
          <span>{{'job.ignoredJobs' | translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-share"></i> <span>{{'job.share' |
            translate}}</span></button>
        <button type="button" class="btn btn-light col-md-2"><i class="me-1 bi bi-three-dots"></i></button>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex pt-4">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-3">
              <div class="flex justify-content-between" *ngIf="!isProject && isUser">
                <div class="flex" *ngIf="!isJob">
                  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                    size="30">
                  <h3 *ngIf="!job?.userID" class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</h3>
                  <h3 *ngIf="job?.pageID" class="ms-1">{{page?.name}}</h3>

                </div>
                <div class="flex" *ngIf="(isUser && !isProject) && !isJob">
                  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                    size="30">
                  <div class="flex">

                    <h3 *ngIf="job?.userID" class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</h3>
                    <div class="dropdown">
                      <button type="button" class="btn btn-light btn-sm me-2 ms-1  dropdown-toggle"
                        data-bs-toggle="dropdown">
                      </button>
                      <ul class="dropdown-menu">
                        <ng-container *ngFor="let privacy of dataArrJobs">
                          <li> <app-user-dialog-name [privacy]="privacy"
                              (changeEventName)="changeUserOfJobs($event)"></app-user-dialog-name>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>

                </div>
                <div class="flex" *ngIf="isJob">
                  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                    size="30">
                  <h3 class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</h3>

                </div>
              </div>
              <div class="flex justify-content-between" *ngIf="isProject">
                <div class="flex">
                  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                    size="30">
                  <h3 *ngIf="!project?.userID" class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}
                  </h3>
                  <h3 *ngIf="project?.pageID" class="ms-1">{{page?.name}}</h3>

                </div>
                <div class="flex" *ngIf="isUser && isProject">
                  <img borderWidth="2" borderColor="#ffffff" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                    size="30">
                  <div class="flex">

                    <h3 *ngIf="project?.userID" class="ms-1">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}
                    </h3>
                    <div class="dropdown">
                      <button type="button" class="btn btn-light btn-sm me-2 ms-1  dropdown-toggle"
                        data-bs-toggle="dropdown">
                      </button>
                      <ul class="dropdown-menu">
                        <ng-container *ngFor="let privacy of dataArr">
                          <li> <app-user-dialog-name [privacy]="privacy"
                              (changeEventName)="changeUserOfProject($event)"></app-user-dialog-name>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              <div [ngClass]="{ 'isActive': selectedFirstGroup && isMyJob}" (mouseenter)="selectFirstGroup()"
                (mouseleave)="removeClick()" (click)="selectFirstGroup()">
                <div>
                  <h1 *ngIf="isProject">{{project?.positionTitle}}</h1>
                  <h1 *ngIf="!isProject">{{job?.positionTitle}}</h1>
                  <p *ngIf="!isProject" style="margin-bottom: 0.5rem !important;"><i
                      class="me-1 bi bi-briefcase"></i>{{'career.employmentType' | translate}}
                    {{employmentTypeString | translate}}</p>
                  <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-person-workspace" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                      <path
                        d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                    </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
                  <div style="display: flex; align-items: center">

                    <div style="display: flex; align-items: center">
                      <p> <i class="bi bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                      <p *ngIf="isProject" style="margin-left: 0.25rem;">{{project?.street}}, {{project?.postalCode}},
                        {{project?.city}},
                        {{countryJsonObject?.EN}}</p>
                      <p *ngIf="!isProject" style="margin-left: 0.25rem;">{{job?.street}}, {{job?.postalCode}},
                        {{job?.city}},
                        {{countryJsonObject?.EN}}</p>
                    </div>
                  </div>
                  <div *ngIf="selectedFirstGroup && isMyJob">
                    <div clickOutside (clickOutside)="removeClick()">
                      <ul>
                        <li class="bi bi-pencil addIconTop"
                          (click)="editFirstGroup({project:project, firstGroup: true})"></li>
                        <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})">
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div [ngClass]="{ 'isActive': selectedSecondGroup && isMyJob}" (mouseenter)="selectSecondGroup()"
                (mouseleave)="removeClick()" (click)="selectSecondGroup()">
                <div>
                  <p><i class="me-1 bi bi-cash"></i>{{"job.fourthPage.paymentForm" | translate }}: {{paymentID |
                    translate}} {{project?.incomes?.budget}}+
                    {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
                  <p *ngIf="!isProject"><i class="me-1 bi bi-calendar-week"></i>{{'job.fourthPage.duration.jobDuration'
                    | translate}}: {{jobDurationString | translate}} - Start date
                    {{job.startDate | date:"dd.MM.yyyy"}}</p>
                  <p *ngIf="isProject"><i class="me-1 bi bi-calendar-week"></i>{{'job.fourthPage.duration.jobDuration' |
                    translate}}: {{jobDurationString | translate}} - Start date
                    {{project.startDate | date:"dd.MM.yyyy"}}</p>


                </div>
                <div *ngIf="selectedSecondGroup && isMyJob">
                  <div clickOutside (clickOutside)="removeClick()">
                    <ul>
                      <li class="bi bi-pencil addIconTop"
                        (click)="editSecondGroup({project:project, secondGroup: true})"></li>
                      <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})">
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card" *ngIf="!isProject">
        <div class="card-header">
          <strong>{{ 'category.Skills' | translate }}</strong>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "project.secondPage.tasks" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="job?.skillsOffer" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.pcKnowledge" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="job?.pcKnowledge" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.driveLicense" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper" style="margin-bottom: .5rem;">
              <app-chips [chips]="job?.driveLicense" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.language" | translate}}
              </strong>
            </div>

            <div *ngFor="let language of job?.language; let iLang = index">
              <div class="Field-field-fieldBase">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <div class="progress">
                      <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                        style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                        [style.font-size.px]="10">
                        <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                          {{language.name | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-1 pb-0">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.qualifications" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="job?.qualifications" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="card" *ngIf="isProject && project?.company">
        <span class="bi bi-trash remove-icon" *ngIf="project?.skillsOffer.length > 0 && isMyJob"
          (click)="deleteProjectTasks(index)"></span>
        <span class="bi bi-plus remove-icon" *ngIf="!project?.freelancer && isMyJob"
          (click)="deleteProjectFreelancer(index)"></span>

        <div class="card-header">
          <strong>{{'project.company' | translate}}</strong>
        </div>
        <div class="card-body">
          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && isMyJob}"
            (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
            <div class="Text-Text-text">
              <strong>
                {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="project?.tasks" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedTasks && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="card" *ngIf="isProject && project?.freelancer">
        <span class="bi bi-trash remove-icon" *ngIf="project?.tasks.length > 0 && isMyJob"
          (click)="deleteProjectFreelancer(index)"></span>
        <span class="bi bi-plus remove-icon" *ngIf="!project?.company && isMyJob"
          (click)="deleteProjectFreelancer(index)"></span>

        <div class="card-header">
          <strong>{{'project.freelancer' | translate}}</strong>
        </div>
        <div class="card-body">

          <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedSkillsOffer && isMyJob}"
            (mouseenter)="selectSkillsOffer()" (mouseleave)="removeClick()" (click)="selectSkillsOffer()">
            <div class="Text-Text-text">
              <strong>
                {{ "job.secondGroup.tasks" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="project?.skillsOffer" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedSkillsOffer && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop" (click)="editJobTasks({project:project, jobTasks: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.pcKnowledge"
            [ngClass]="{ 'isActive': selectedPCKnowledge && isMyJob}" (mouseenter)="selectPCKnowledge()"
            (mouseleave)="removeClick()" (click)="selectPCKnowledge()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.pcKnowledge" | translate}}
              </strong>
            </div>

            <div class="Text-Text-text-wrapper">
              <app-chips [chips]="project?.pcKnowledge" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedPCKnowledge && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop"
                    (click)="editPCKnowledge({project:project, jobPCKnowledge: true})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>

          </div>
          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.language?.length > 0"
            [ngClass]="{ 'isActive': selectedLanguage && isMyJob}" (mouseenter)="selectLanguage()"
            (mouseleave)="removeClick()" (click)="selectLanguage()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.language" | translate}}
              </strong>

              <div *ngFor="let language of project?.language; let iLang = index">
                <div class="Field-field-fieldBase">
                  <div class="Text-Text-wrapper">
                    <div class="Text-Text-text-wrapper">
                      <div class="progress">
                        <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                          style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                          [style.font-size.px]="10">
                          <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                            {{language.name | translate}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedLanguage && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop"
                    (click)="editJobLanguage({project:project, jobLanguage: true, jobDescription: false})"></li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobLanguage({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.driveLicense?.length > 0"
            [ngClass]="{ 'isActive': selectedDriveLicense && isMyJob}" (mouseenter)="selectDriveLicense()"
            (mouseleave)="removeClick()" (click)="selectDriveLicense()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.driveLicense" | translate}}
              </strong>

              <div class="Text-Text-text-wrapper">
                <app-chips [chips]="project?.driveLicense" stopPropagation></app-chips>
              </div>
            </div>
            <div *ngIf="selectedDriveLicense && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop"
                    (click)="editJobDriveLicense({project:project, jobDriveLicense: true})">
                  </li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.qualifications"
            [ngClass]="{ 'isActive': selectedQualifications && isMyJob}" (mouseenter)="selectQualifications()"
            (mouseleave)="removeClick()" (click)="selectQualifications()">
            <div class="Text-Text-text">
              <strong>
                {{ "skills.qualifications" | translate}}
              </strong>

              <div class="Text-Text-text-wrapper">
                <app-chips [chips]="project?.qualifications" stopPropagation></app-chips>
              </div>
            </div>
            <div *ngIf="selectedQualifications && isMyJob">
              <div clickOutside (clickOutside)="removeClick()">
                <ul>
                  <li class="bi bi-pencil addIconTop"
                    (click)="editQualifications({project:project, jobQualifications: true})">
                  </li>
                  <li class="bi bi-plus  addIconBottomCategory" (click)="editJobDescription({project:project})"></li>

                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card" [ngClass]="{ 'isActive': selectedSkills && isMyJob}" (mouseenter)="selectSkills()"
        (mouseleave)="removeClick()" (click)="selectSkills()">
        <div class="card-header">
          <strong *ngIf="!isProject">{{"job.description" | translate}}</strong>
          <strong *ngIf="isProject">{{"project.description" | translate}}</strong>
        </div>
        <div class="card-body" *ngIf="isProject">
          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
            <div class="Text-Text-text-wrapper" [innerHTML]="project?.description | noSanitize">
            </div>
          </div>
          <div *ngIf="selectedSkills && isMyJob">
            <div clickOutside (clickOutside)="removeClick()">
              <ul>
                <li class="bi bi-pencil addIconTop"
                  (click)="editJobDescription({project:proejct, jobDescription: true})"></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body" *ngIf="!isProject">
          <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
            <div class="Text-Text-text-wrapper" [innerHTML]="job?.description | noSanitize">
            </div>
          </div>
          <div *ngIf="selectedSkills && isMyJob">
            <div clickOutside (clickOutside)="removeClick()">
              <ul>
                <li class="bi bi-pencil addIconTop"
                  (click)="editJobDescription({project:proejct, jobDescription: true})"></li>
              </ul>
            </div>
          </div>
        </div>

      </div>

      <div class="card">
        <div class="card-header">
          <strong style="display: flex;" *ngIf="!isProject">{{"published" | translate}}: <span class="pl-3 ms-2" style="align-items: self-end;
                    display: flex;">{{job?.public?.startDate | date:"dd.MM.yyyy HH:mm"}}

            </span></strong>
          <strong style="display: flex;" *ngIf="isProject">{{"published" | translate}}: <span class="pl-3 ms-2" style="align-items: self-end;
                    display: flex;">{{project?.public?.startDate | date:"dd.MM.yyyy HH:mm"}}

            </span></strong>
        </div>

      </div>

    </div>
  </div>
</div>

<main *ngIf="router.url.endsWith('cancel')">
  <div class="container" >
    <div class="row">
      <div class="col-lg-6 col-6">
        <div>
          <button class="btn btn-light">{{'cancel' | translate}}</button>
          <div class="card">
            <div class="card-body">
              <div class="flex column col-md-12">
                <div class="flex">
                  <div class="flex  mb-2 col-md-2">
                    <span>{{'qualifications.name' | translate}}:</span>
                  </div>
                  <div class="row col-md-12">

                    <div class="col-md-10 px-0" *ngIf="router.url.endsWith('cancel')">
                      <input class="form-control col-md-12" [(ngModel)]="user?.firstName + ' ' + user.lastName"
                        disabled>
                    </div>
                    <div class="col-md-1 ms-2 mb-2">
                      <button class="btn btn-light" style="border: 1px solid;" (click)="goToCV()">{{'header.cv' |
                        translate}}</button>
                    </div>
                  </div>
                </div>
                <div class="flex mb-2">
                  <div class="col-md-2 me-2 px-0">
                    <span>{{'job.jobTitle' | translate}}:</span>
                  </div>
                  <div class="col-md-12" *ngIf="router.url.endsWith('cancel')">
                    <input class="form-control col-md-12" [ngModel]="userObject?.positionTitle" disabled>
                  </div>
                </div>
                <div class="flex mb-2">
                  <div class="col-md-2 me-2 px-0">
                    <span>{{'job.message' | translate}}:</span>
                  </div>
                  <div class="col-md-12">
                    <quill-editor class="content-editor" [modules]="editorOptions" [(ngModel)]="jIssue.description">
                    </quill-editor>
                  </div>
                </div>

              </div>
            </div>
            <div class="modal-footer" *ngIf="router.url.endsWith('cancel')">
              <button type="button" class="btn btn-outline-dark" (click)="sentBack()">{{'sent' | translate}}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</main>