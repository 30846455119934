<div>
  <div [ngClass]="{ isActive: selectedPersonalData === id }" (click)="setIndex(id)">
    <div class="Unit-unit Elegant-elegant-cv-personalData">
      <div class="Line-line-container ">
        <div class="Line-line-line">
          <div class="Field-field-fieldBase Elegant-elegant-fieldField">
            <div class="Text-Text-wrapper">
              <div class="Text-Text-text">
                {{ "data.title" | translate }}
              </div>
            </div>
          </div>
          <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
            <div class="Text-Text-wrapper">
              <div class="Text-Text-text-wrapper">
                <div class="float-left">
                  {{ getGender | translate }}
                </div>
                <div class="float-left" *ngIf="user.title > 0">
                  {{ getTitle | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="user.firstName.length > 0">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.firstName" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ personalData.firstName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Last Name -->
      <div *ngIf="user.lastName.length > 0">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.lastName" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ personalData.lastName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Birthday -->
      <div *ngIf="personalData.showBirthday">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.birthday" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ user?.birthday.date | date: "dd.MM.yyyy" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Status -->
      <div *ngIf="personalData.showStatus">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.status" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ getStatus.description | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Nationality -->
      <div *ngIf="personalData.showNationality">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.nationality" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    {{ personalData.nationality }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Telephone -->
      <div *ngIf="personalData.showTelephone">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.telephone" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{phoneNumber}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Email -->
      <div *ngIf="personalData.showEmail">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.email" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ personalData.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="personalData.showAddress">
        <div class="Line-line-container">
          <div class="Line-line-line">
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ "data.address" | translate }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldValue" *ngIf="user.address.data[0].street.length > 0">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{user.address.data[0].street }}
                </div>
              </div>
            </div>
          </div>
        </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase" *ngIf="postalCodeAndCity.length > 0">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ user.address.data[0].postalCode }} {{user.address.data[0].city }}
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase Elegant-elegant-fieldField">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                </div>
              </div>
            </div>
            <div class="Field-field-fieldBase ">
              <div class="Text-Text-text-wrapper">
                <div class="Text-Text-text-wrapper">
                  {{ user.address.data[0].state }} {{ countryJsonObject?.EN }}
                </div>
              </div>
            </div>
      </div>

      <div *ngIf="selectedPersonalData === id">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil editIcon" (click)="
                editPersonalData({
                  edit: true,
                  personalData: personalData,
                  model: model,
                  user: user,
                  index: index
                })
              "></li>
            <li class="bi bi-plus addIcon" (click)="
                addData({edit: false, model: model, personalData: true })
              "></li>
            <li class="bi bi-trash deleteIcon" (click)="deleteSubCategory(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngFor="let emptyObj of personalData.emptySubContents; let iEmpty = index">
  <app-empty-object [emptyObj]="emptyObj" [iEmpty]="iEmpty" [model]="model" [isFromPersonalData]="true">
  </app-empty-object>
</div>
