import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Model } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
  editShortcut = false;
  public model$: Observable<Model>;
  public model = new  Model();
  private subscriptions: Subscription;
  public currentUser: User;
  public image;
  public hasPhoto;
  public getFirstName;
  public getLastName;
  public userUrl;
public title;
  constructor(public authService: AuthService,
    public imageService: ImageService,
    public router: Router,
    private sendData?: SenderService,
    private route?:ActivatedRoute,
    private userService?: UserService) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
        if (res) {
          this.currentUser = res;
          if (this.currentUser.userUrl) {
             this.userUrl = this.currentUser.userUrl;
          } else {
            this.userUrl = this.currentUser._id;
          }
        }
      });
  }
  }

  navigateToUser() {
    const test = [this.currentUser, "outside"];
    console.log(test, "test")
       this.sendData.send(test);
       this.router.navigate([ '../' +this.currentUser.userUrl, "start" ],{relativeTo:this.route});
    }
  

  navigateToPayment() {
    localStorage.setItem("payment", "isUser");
    this.sendData.paymentDataVisibility(this.currentUser);
    this.router.navigateByUrl(this.currentUser?.userUrl + '/payments'.replace(this.title, 'isUser'));
  }

}
