<ng-container *ngIf="!isUser">
  <div *ngFor="let project of page?.ignoredProjects; let i = index">
    <app-job-item [pageObject]="page" [search]="'project.search'" [public]="'project.public'" [projectID]="project.projectId"  (unsaveJobUsers)="test(i)" [savedProject]="'savedProject'" [isIgnoredProject]="true" (removeFromIgnorePageProject)="removeIgnoredFromPageProject(i)" (unIgnoreProject)="removeIgnoredProject(i)" [isPage]="true"></app-job-item>
  </div>
</ng-container>
  

      <ng-container *ngIf="isUser">
        <div *ngFor="let project of user?.ignoredProjects; let i = index">
          <app-job-item [pageObject]="page" [search]="'project.search'" [public]="'project.public'" [projectID]="project.projectId"  (unsaveJobUsers)="test(i)" [savedProject]="'savedProject'" [isIgnoredProject]="true" (removeFromIgnorePageProject)="removeIgnoredFromPageProject(i)" (unIgnoreProject)="removeIgnoredProject(i)" [isPage]="true"></app-job-item>
        </div>
  
      </ng-container>
  