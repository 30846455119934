import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StopPropagationDirective } from './stop-propagation.directive';
import { Autosize } from './auto-resize.directive';
import { OutsideClickDivDirective } from './click-outside-div.directive';



@NgModule({
  declarations: [
    StopPropagationDirective,
    Autosize,
    OutsideClickDivDirective
  ],
  imports: [
  ],

  exports: [
    StopPropagationDirective,
    Autosize,
    OutsideClickDivDirective
  ]
})
export class DirectivesModule { }
