import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, Subscription } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { Model } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-second-template",
  templateUrl: "./second-template.component.html",
  styleUrls: ["./second-template.component.scss"]
})
export class SecondTemplateComponent implements OnInit {
  public model$: Observable<Model>;
  public model: Model;
  currentUser: User;
  private subscriptions: Subscription;
  public userID = this.authService.userID;




  constructor(public authService: AuthService,
              public dialog: MatDialog,
              public cd: ChangeDetectorRef,
              public http: HttpClient,
  ) { }

  ngOnInit(): void {
  }
}
