<div class="col-md-12" *ngIf="data.isEditLocation">

  <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()">

    <li class="line form-row">
      <div class="col-md-12">
        <input name="showEmail" type="checkbox" value="" [(ngModel)]="isStreetActive" />
        <label class="px-2">{{'pagesInfo.noStreet' | translate}}.</label>
      </div>
    </li>

    <li class="line form-row" *ngIf="!isStreetActive">
      <div class="col-md-12">
        <label>{{ "addressData.street" | translate }}</label>
        <input class="form-control col-md-12" autocomplete="off" [(ngModel)]="data.location.street"
          name="street" #street="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && street.invalid }" required />
          <div *ngIf="myForm.submitted && street.invalid" class="invalid-feedback">
            <div *ngIf="street.errors.required">
              {{ "field" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
      </div>
    </li>
    <li class="line form-row">


      <div class="col-md-6">
        <label>{{ "data.zip" | translate }}</label>
        <input class="form-control col-md-12" name="postalCode" [(ngModel)]="data.location.postalCode"
          autocomplete="postal-code"  #postalCode="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && postalCode.invalid }" required/>
          <div *ngIf="myForm.submitted && postalCode.invalid" class="invalid-feedback">
            <div *ngIf="postalCode.errors.required">
              {{ "field" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>

      </div>
      <div class="col-md-6">
        <label>{{ "data.city" | translate }}</label>
        <input class="form-control col-md-12" name="city" [(ngModel)]="data.location.city" #city="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && city.invalid }" required/>
        <div *ngIf="myForm.submitted && city.invalid" class="invalid-feedback">
          <div *ngIf="city.errors.required">
            {{ "field" | translate }}
            {{ "error.isRequired" | translate }}
          </div>
        </div>
      </div>
    </li>
    <li class="line form-row">

      <div class="col-md-6">
        <label>{{ "data.state" | translate }}</label>
        <input class="form-control col-md-12" name="state" [(ngModel)]="data.location.state" />

      </div>
      <div class="col-md-6">
        <label>{{ "data.country" | translate }}:*</label>
        <select class="col-md-12 form-control" name="selectedBranch" id="selectBranch"
          [ngModel]="data.location.country" [(ngModel)]="data.location.c" (ngModelChange)="onChange($event)" required>
          <option *ngFor="let country of countryJsonArray" [value]="country.ISO_2" [selected]="country"
            (change)="onChange(country)">
            {{ country.EN }} - ({{country.LokalName}})
          </option>
        </select>

      </div>
      <div class="col-md-6">
        <span class="checkbox-inline margin5px">
          <input [(ngModel)]="data.location.headquarters" [checked]="data.location.headquarters" name="showEmail"
            type="checkbox" value="" />
            {{'locations.makePrimary' | translate}}

        </span>
      </div>

    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
    </form>



  </div>

  <div class="col-md-12" style="width: 500px" *ngIf="data.isAddNewLocation">
    <h3>{{'pageSidebar.service_area' | translate}}</h3>
    <div class="search-widget">
        <ul class="ui-selection-list">
          <li class="line form-row">
            <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
            <div class="col-md-6" style="padding: .25rem;">
              <select class="col-md-12 form-control-sm" name="selectedBranch" id="selectBranch"
                [(ngModel)]="data.location.country" (ngModelChange)="onChange($event)" required >
                <option *ngFor="let country of countryJsonArray" [value]="country.ISO_2" [selected]="selectedCountry"
                  (change)="onChange(country)">
                  {{ country.EN }} - ({{country.LokalName}})
                </option>
              </select>

            </div>
             <mat-button-toggle-group style="margin-bottom: 0.75rem;" class="col-md-6 mat-group-toggle-buttons"
                [(value)]="data.location.region" #group="matButtonToggleGroup" multiple=true>
                <mat-button-toggle *ngFor="let item of toggleOptions;" (change)="selectionChanged($event)"
                  [value]="item">{{item}}</mat-button-toggle>
              </mat-button-toggle-group>
              <ng-container *ngIf="data?.location?.region?.length < 2">
                <label class="column">
                  <p>{{"data.zip" | translate }}-{{"area" | translate}}</p>
                <input type="text" class="form-control-sm col-md-8" style="margin-bottom: 0.75rem;" [(ngModel)]="data.location.postalCode" (ngModelChange)="selectIndexForButtonsPage(index)">
              </label>
            </ng-container>
            <div class="flex
            items-center" style="
            flex-direction: row;
            display: flex;
            border:none;
        " class="specialty col-md-12 px-0 mt-1">
            </div>


            </div>

          </li>

        </ul>
        <mat-dialog-actions align="end">
            <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
              {{ "cancel" | translate }}
            </button>
            <button type="submit" class="btn btn-success" (click)="saveData()" mat-button>
              {{ "save" | translate }}
            </button>
          </mat-dialog-actions>
      </div>

  </div>
