<div *ngIf="skillObj.languages.data.length > 0">
  <div [ngClass]="{ 'isActive': selectedLanguageFirst === id && authService.isLoggedIn}" (click)="setIndexLanguageFirst(id)">
    <div class="Unit-unit Elegant-elegant-cv-personalData">
    <div  class="Line-line-container ">
      <div class="Line-line-line">
        <div class="Field-field-fieldBase Elegant-elegant-fieldField Elegant-float-revert ">
          <div class="Text-Text-wrapper">
            <div class="Text-Text-text">
              <b>{{'skills.language' | translate}}</b>
            </div>
          </div>
        </div>
        <div *ngFor="let language of skillObj.languages; let iLang = index">
          <div pageContent class="Field-field-fieldBase Elegant-elegant-fieldValue">
            <div class="Text-Text-wrapper">
              <div class="Text-Text-text-wrapper">
                <div class="test">
                  {{getLanguageName(language.name)?.value}}</div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="selectedLanguageFirst === id && authService.isLoggedIn">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
                (click)="editLanguages({edit: true, language: skillObj.languages, model: model})"></li>
            <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({model: model, language: true, skills: skills, skillObj: skillObj})"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deleteLanguageFirst(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
      </div>
    </div>
  </div>
</div>
</div>
<div *ngFor="let language of skillObj.languages" class="Unit-unit-unitGroup ">
  <div class="Unit-unit Elegant-elegant-cv-personalData" [ngClass]="{ 'isActive': selectedRow && authService.isLoggedIn}" (clickOutsideInner)="removeClick()">
    <app-empty-row *ngFor="let empty of language.spacesBetween; let id = index"
                   [space]="empty"
                   [id]="id"
                   [model]="model"
                   (eventName)="doSomething($event)"
                   [selectedRow]="selectedRow"
                   [skillObj]="skillObj">
    </app-empty-row>
  </div>
</div>

<!-- Drive Licence -->
<div pageContent *ngIf="skillObj.driveLicenses.data.length > 0" class="Unit-unit-unitGroup Elegant-elegant-cv-personalData"
     [ngClass]="{ 'isActive': selectedDriveLicense === id && authService.isLoggedIn}" (click)="setIndexDriveLicense(id)">
  <div class="Line-line-container">
    <div class="Line-line-line">
      <div class="Field-field-fieldBase Elegant-elegant-fieldField Elegant-float-revert ">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text">
           <b> {{'skills.driveLicense' | translate}}</b>
          </div>
        </div>
      </div>
      <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="skillObj.driveLicenses" stopPropagation></app-chips>
          </div>
        </div>
      </div>

      <ng-container *ngIf="selectedDriveLicense === id && authService.isLoggedIn">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
                (click)="editDriveLicense({edit: true, driveLicense: skillObj.driveLicenses})"></li>
            <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deleteDriveLicense(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- PC Knowledge -->
<div *ngIf="skillObj.pcKnowledge.data.length > 0" class="Unit-unit-unitGroup Elegant-elegant-cv-personalData"
     [ngClass]="{ 'isActive': selectedPCKnowledge === id && authService.isLoggedIn}"
     (click)="setIndexForPcKnowledge(id)">
  <div class="Line-line-container">
    <div class="Line-line-line">
      <div class="Field-field-fieldBase Elegant-elegant-fieldField Elegant-float-revert ">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text">
           <b> {{'skills.pcKnowledge' | translate}}</b>
          </div>
        </div>
      </div>
      <div class="Field-field-fieldBase Elegant-elegant-fieldValue">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="skillObj.pcKnowledge" stopPropagation></app-chips>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedPCKnowledge === id && authService.isLoggedIn">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
                (click)="editPCKnowledge({edit: true, pcKnowledge: skillObj.pcKnowledge, model: model})"></li>
            <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deletePCKnowledge(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!-- Offer -->
<div *ngIf="skillObj.skillsOffer.data.length > 0" class="Unit-unit Elegant-elegant-cv-personalData" [ngClass]="{ 'isActive': selectedSkill === id && authService.isLoggedIn}"
     (click)="setIndexForSkill(id)">
  <div class="Line-line-container" pageContent>
    <div class="Line-line-line">
      <div class="Field-field-fieldBase Elegant-elegant-fieldField Elegant-float-revert ">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text">
            <b>{{'skills.offer' | translate}}</b>
          </div>
        </div>
      </div>
      <div class="Field-field-fieldBase Elegant-elegant-fieldField">
        <div class="Text-Text-wrapper">
          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="skillObj.skillsOffer" stopPropagation></app-chips>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedSkill === id && authService.isLoggedIn">
        <div clickOutside (clickOutside)="removeClick()">
          <ul>
            <li class="bi bi-pencil addIconTop"
                (click)="editOffer({edit: true, offer: skillObj.skillsOffer, model: model})"></li>
            <li class="bi bi-plus addIconBottom" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
            <li class="bi bi-trash deleteIconRight" (click)="deleteSkillOffer(id)"></li>
            <li class="bi bi-arrow-down moveIconDown"></li>
            <li class="bi bi-arrow-up moveIconTop"></li>
          </ul>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<!--Empty Object -->
<div pageContent class="Unit-unit">
  <ng-container *ngFor="let emptyObj of skillObj.emptySubContents; let iEmpty = index">
    <app-empty-object [emptyObj]="emptyObj" [iEmpty]="iEmpty" [model]="model"></app-empty-object>
  </ng-container>
</div>

<ng-container *ngIf="selectedIndex === id">
  <div clickOutside (clickOutside)="removeClick()">
    <ul>
      <li class="bi bi-plus addIconTop" (click)="openSubCatDialog({skillObj: skillObj, model: model})"></li>
      <li class="bi bi-arrow-down moveIconDown"></li>
      <li class="bi bi-arrow-up moveIconTop"></li>
      <li class="bi bi-trash deleteIconRight" (click)="deleteSubCategory(id)"></li>
    </ul>
  </div>
</ng-container>
