<div class="container">




  <div class="col-md-12 mt-4 mb-2">
    <div class="dropdown">
      <a href="#" class="text-secondary btn btn-secondary-soft tn-secondary-soft-hover py-1 px-2" id="cardShareAction2"
        data-bs-toggle="dropdown" aria-expanded="false">

        <i class="bi bi-images"></i>
        Add Media
      </a>
      <!-- Card share action dropdown menu -->
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
        <li><a class="dropdown-item" href="#"> <i class="bi bi-image fa-fw pe-2"></i>Add image</a></li>
        <li><a class="dropdown-item" href="#"> <i class="bi bi-play-btn-fill fa-fw pe-2"></i>Add video</a></li>

      </ul>
    </div>
  </div>

  <div class="col-md-12 col-sm-12 py-2">
    <label class="col-md-12">
      Headline
      <textarea maxlength="40" (keyup)="changeText($event)" id="headlineCard" class="form-control col-md-12"
        [(ngModel)]="imageCard.headline"></textarea>
      <div style="margin-top: 0.25rem; text-align: right" id="counter">40</div>
    </label>
  </div>

  <div class="col-md-12">
    <label class="col-md-12">
      Description
      <textarea (keyup)="changeAdsDetailsDescription($event)" maxLength="200" id="adsDetailsDescadsDetailsDescriptionCardription"
        class="form-control" [(ngModel)]="imageCard.description"
       ></textarea>
      <div style="margin-top: 0.25rem; text-align: right" id="counter1">200</div>

    </label>
  </div>

  <form name="ngForm" #adsDetailsForm="ngForm">

  <div class="col-md-12 py-2">

    <label class="col-md-8">
      Website URL
      <input class="form-control" [(ngModel)]="imageCard.url" type="url"
      #adsDetailsUrl="ngModel" name="adsDetailsUrl" placeholder="http://www.xy.expert" pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$" [required]="data.requiredUrl">
    </label>

    <div *ngIf="adsDetailsForm.controls['adsDetailsUrl']?.invalid" class="invalid-feedback">
      <span >{{ 'Web Url is required' }} </span>
    </div>
  </div>
  </form>

  <div class="col-md-12 py-2">
    <label class="col-md-12">
      Call to Action
      <select class="col-12 form-control-sm me-2" name="pcKnowledge" id="selectBranch"
        [(ngModel)]="imageCard.selectedButton" (ngModelChange)="changeDropButton($event)">
        <option *ngFor="let call of callAction" [value]="call.id" [selected]="call">
          {{ call.description }}
        </option>
      </select>
    </label>
  </div>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
      {{ "cancel" | translate }}
    </button>
    <button type="submit" class="btn btn-success" (click)="saveData()" mat-button>
      {{ "save" | translate }}
    </button>
  </mat-dialog-actions>
</div>
