<ng-container *ngIf="
            router.url === '/' + userData.user?.userUrl + '/publicated/projects'
          ">
  <div class="main-content right-aligned">
    <section class="search-result-container">
      <div>
        <div class="result-list" *ngFor="let userJobs of projects">
          <app-job-item [userJobs]="userJobs"></app-job-item>
        </div>
      </div>
    </section>
  </div>
</ng-container>

<ng-container *ngIf="router.url.includes(userData?.user?.userUrl + '/publicated/projects;')">
  <div class="main-content right-aligned">
    <section class="search-result-container">
      <ng-container *ngIf="!!project">
        <app-user-projects-published [project]="project" [isPublished]="true"></app-user-projects-published>
      </ng-container>

    </section>
  </div>
</ng-container>
