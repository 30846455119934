<div class="container" id="printPDF">
    <ng-container *ngIf="!data.isPayment">
        <ng-container *ngIf=data.isPage>
            <div class="row">
                <div class="span4" style="width: 100%;">
                    <div style="width: 100%;
        display: flex;
       align-items: center;">
                        <img src="../../../../assets/img/logo.png" class="img-rounded logo" width="40px">
                        <strong style="padding-left: 0.5rem;">{{data?.pageName}}</strong>
                    </div>
                    <hr>
                    <address>
                        <strong>{{data.page.name}}</strong><br>
                        {{page?.location?.street}}<br>
                        {{page?.location?.postalCode}}<br>
                        {{countryJsonObject?.EN}}
                    </address>
                </div>
                <div class="span4 well">
                    <table class="invoice-head">
                        <tbody>
                            <tr>
                                <td class="pull-right"><strong>Customer: </strong></td>
                                <td>{{page?._id}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Invoice: </strong></td>
                                <td>{{page?._id}}-{{data?.creditHistory?.date | date:'y'}}-{{data.creditHistory.invoiceID}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Date: </strong></td>
                                <td>{{data?.creditHistory?.date | date:'mediumDate'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8">
                    <h2>Invoice</h2>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style="text-align: center;">Credit</th>
                                <th style="text-align: end;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Service request</td>
                                <td style="text-align: center;">-450</td>
                                <td style="text-align: end;" >{{data.creditHistory.amount}} €</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: center;"><strong>Total</strong></td>
                                <td style="text-align: end;" ><strong>{{data.creditHistory.amount}} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-thank">
                    <h5 style="text-align:center;">Thank You!</h5>
                </div>
            </div>
            <div class="row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 23rem;">
                <div class="span3">
                    <span>{{data.pageDetails.name}}</span><br>
                    <span>{{primaryLocation.street}}</span><br>
                    <span>{{primaryLocation.postalCode}} {{primaryLocation.city}},
                        {{countryJsonObjectForUser?.EN}}</span>
                </div>

                <div class="span3">
                    <strong>Email:</strong> <a href="mailto:hello@5marks.co">hello@bootply.com</a>
                </div>
                <div class="span3">
                    <strong>Website:</strong> <a href="http://5marks.co">http://bootply.com</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="data.isUser">
            <div class="row">
                <div class="span4" style="width: 100%;">
                    <div style="width: 100%;
          display: flex;
         align-items: center;">
                        <img src="../../../../assets/img/logo.png" class="img-rounded logo" width="40px">
                        <strong style="padding-left: 0.5rem;">{{data?.pageName}}</strong>
                    </div>
                    <hr>
                    <address>
                        <strong>{{data?.user.firstName}} {{data?.user.lastName}}</strong><br>
                        {{user?.cv.data[0].personalData[0].street}}<br>
                        {{user?.cv.data[0].personalData[0].postalCode}} {{user?.cv.data[0].personalData[0].city}}<br>
                        {{countryJsonObject?.EN}}
                    </address>
                </div>
                <div class="span4 well">
                    <table class="invoice-head">
                        <tbody>
                            <tr>
                                <td class="pull-right"><strong>Customer: </strong></td>
                                <td>{{data?.user._id}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Invoice: </strong></td>
                                <td>{{user?._id}}-{{data.creditHistory.date | date:'y'}}-{{data.creditHistory.invoiceID}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Date: </strong></td>
                                <td>{{data.creditHistory.date | date:'mediumDate'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8">
                    <h2>Invoice</h2>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style="text-align: center;">Credit</th>
                                <th style="text-align: end;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Service request</td>
                                <td style="text-align: center;">-450</td>
                                <td style="text-align: end;" >{{data.creditHistory.amount}} €</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: center;"><strong>Total</strong></td>
                                <td style="text-align: end;" ><strong>{{data.creditHistory.amount}} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-thank">
                    <h5 style="text-align:center;">Thank You!</h5>
                </div>
            </div>
            <div class="row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 23rem;">
                <div class="span3">
                    <span>{{data.pageDetails.name}}</span><br>
                    <span>{{primaryLocation.street}}</span><br>
                    <span>{{primaryLocation.postalCode}} {{primaryLocation.city}},
                        {{countryJsonObjectForUser?.EN}}</span>
                </div>
                <div class="span3">
                    <strong>Email:</strong> <a href="mailto:hello@5marks.co">hello@bootply.com</a>
                </div>
                <div class="span3">
                    <strong>Website:</strong> <a href="http://5marks.co">http://bootply.com</a>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="data.isPayment">
        <ng-container *ngIf="data.isUser">
            <div class="row">
                <div class="span4" style="width: 100%;">
                    <div style="width: 100%;
              display: flex;
             align-items: center;">
                        <img src="../../../../assets/img/logo.png" class="img-rounded logo" width="40px">
                        <strong style="padding-left: 0.5rem;">{{data?.pageName?.name}}</strong>
                    </div>
                    <hr>
                    <address>
                        <strong>{{data?.user.firstName}} {{data?.user.lastName}}</strong><br>
                        {{user?.cv.data[0].personalData[0].street}}<br>
                        {{user?.cv.data[0].personalData[0].postalCode}} {{user?.cv.data[0].personalData[0].city}}<br>
                        {{countryJsonObject?.EN}}
                    </address>
                </div>
                <div class="span4 well">
                    <table class="invoice-head">
                        <tbody>
                            <tr>
                                <td class="pull-right"><strong>Customer: </strong></td>
                                <td>{{data?.user._id}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Invoice: </strong></td>
                                <td>{{user?._id}}-{{data.creditHistory.date | date:'y'}}-{{data.creditHistory.invoiceID}}</td>
                            </tr>
                            <tr>
                                <td class="pull-right"><strong>Date: </strong></td>
                                <td>{{data.creditHistory.date | date:'mediumDate'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8">
                    <h2>Invoice</h2>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-body">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th style="text-align: center;">Credit</th>
                                <th style="text-align: end;">Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Service request</td>
                                <td style="text-align: center;">{{data.creditHistory.amount}} €</td>
                                <td style="text-align: end;" >{{data.creditHistory.amount}} €</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="text-align: center;"><strong>Total</strong></td>
                                <td style="text-align: end;" ><strong>{{data.creditHistory.amount}} €</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="span8 invoice-thank">
                    <h5 style="text-align:center;">Thank You!</h5>
                </div>
            </div>
            <div class="row" style="display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 23rem;">
                <div class="span3">
                    <span>{{data.pageDetails.name}}</span><br>
                    <span>{{primaryLocation.street}}</span><br>
                    <span>{{primaryLocation.postalCode}} {{primaryLocation.city}},
                        {{countryJsonObjectForUser?.EN}}</span>
                </div>

                <div class="span3">
                    <strong>Email:</strong> <a href="mailto:hello@5marks.co">hello@bootply.com</a>
                </div>
                <div class="span3">
                    <strong>Website:</strong> <a href="http://5marks.co">http://bootply.com</a>
                </div>
            </div>
        </ng-container>

    </ng-container>
</div>
<mat-dialog-actions align="end">

    <a class="btn bg-white btn-light mx-1px text-95" (click)="printData()" data-title="PDF">
        <i class="me-1 bi bi-file-earmark-pdf text-danger-m1 text-120 w-2"></i>
        Print
    </a>
</mat-dialog-actions>
