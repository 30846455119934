import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-header-data-details',
  templateUrl: './header-data-details.component.html',
  styleUrls: ['./header-data-details.component.scss']
})
export class HeaderDataDetailsComponent {

  /**
   * Inputs
   */
  @Input() data: any;
  @Input() user: any;
  @Input() firstButtonText: string;
  @Input() thirdButtonText: string;
  @Input() secondButtonText: string;
  @Input() hasApplied: boolean;
  @Input() isSaved: boolean;
  @Input() showForApplied: boolean;


  /**
   * Outputs
   */
  @Output() openShareEmmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() openApplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkSaveEmmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkUnsaveEmmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyEmmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() messageEmmit: EventEmitter<any> = new EventEmitter<any>();






  public get showDateApplied() {
    return this.data?.manage_?.find((t) => t.userID === this.user._id)
      ?.createdAt;
  }



  openShare() {
    this.openShareEmmit.emit();
  }
  goToApplied() {
    this.openApplied.emit();
  }

  save() {
    this.checkSaveEmmit.emit();
  }

  unsave() {
    this.checkUnsaveEmmit.emit();
  }
  apply() {
    this.applyEmmit.emit();
  }

  message() {
    this.messageEmmit.emit();
  }


}
