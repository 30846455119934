import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PrivacyButtonComponent } from './privacy-button.component';



@NgModule({
  declarations: [
    PrivacyButtonComponent
  ],
  imports: [
    CommonModule,
    TooltipModule,
  ],
  exports: [
    PrivacyButtonComponent
  ]
})
export class PrivacyButtonModule { }
