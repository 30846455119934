import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { Model, Image, Contact, UserContact, Page } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { FriendService } from "src/app/shared/services/friend.service";
import { ImageService } from "src/app/shared/services/image.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserHelperService } from "src/app/shared/utils/user-helper.service";
import { UserProfileComponent } from "../user-profile/user-profile.component";
import { MessageHelperService } from "../../../shared/utils/message-helper.service";
import { Messages } from "../../../shared/interfaces/message";
import { UserService } from "src/app/shared/services/user.service";
import { shareReplay } from "rxjs/operators";
import { PagesService } from "src/app/shared/services/pages.service";
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-user-contacts",
  templateUrl: "./user-contacts.component.html",
  styleUrls: ["./user-contacts.component.scss"]
})
export class UserContactsComponent extends UserProfileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: Model;
  @Input() photoId;
  @Input() contact: UserContact;
  @Input() users: User;
  @Input() sidebar;
  @Input() privacy;
  @Input() removedFriend: User;
  @Input() buttonDisabled;
  @Input() contactExpect;
  @Input() contactSpecific;
  @Input() contactExpectAdded;
  @Input() isPage;
  @Output() onFriendRemove = new EventEmitter<Contact>();
  @Output() onDeleteFromList = new EventEmitter<Contact>();
  @Output() onContactExpect = new EventEmitter<Contact>();
  @Output() onSpecificContact = new EventEmitter<Contact>();
  @Output() onSelectEmit: EventEmitter<any> = new EventEmitter()
  public friendData: Model;
  public message = "";
  public messages: Messages[] = [];
  public selectedUser;
  public checkSpecificData: Model;

  noFriends: boolean;
  isLoading = true;
  unsubscribe = new Subject<void>();
  public subscriptions: Subscription;
  public image;
  public hasPhoto: boolean;
  public ISFriend = false;
  public userClicked = false;
  public checkId = false;
  public page: Page;


  constructor(
    public router: Router,
    public authService: AuthService,
    public imageService: ImageService,
    public dialog: MatDialog,
    public receiver?: SenderService,
    public userHelper?: UserHelperService,
    public friendService?: FriendService,
    public sendData?: SenderService,
    public cd?: ChangeDetectorRef,
    public userSer?: UserService,
    public userService?: UserService,
    public pageService?: PagesService,
    public msgHelperService?: MessageHelperService) {
    super(router, authService, imageService, dialog, receiver, userHelper, friendService, sendData, cd, userService);

  }

  ngOnInit(): void {
    console.log(this.contact, "contact");
    if (this.contact.contactId) {
      this.userService.getUserContactData(this.contact.contactId).pipe(shareReplay(1)).subscribe(res => {
        this.user = res;
      });
    }

    if (this.contact.pageId) {
      this.pageService.getPage(this.contact.pageId).pipe(shareReplay(1)).subscribe(res => {
        this.page = res;
      })
    }
    if (this.contact.userID) {
      this.userService.getUserContactData(this.contact.userID).pipe(shareReplay(1)).subscribe(res => {
        this.user = res;
      })
    }

  }


  ngOnChanges() {
  }

  ngOnDestroy(): void {
  }

  navigateToUser(data) {
    if(this.contact.contactId) {
    this.userService.getUserContactData(this.contact.contactId).pipe(shareReplay(1)).subscribe(res => {
      this.user = res;
      this.router.navigate([this.user?.userUrl, "start"]);
      this.ngOnInit();
    });
  } else {
    this.router.navigate([this.page.pageUrl]);
  }
    this.onSelectEmit.emit(data);
  }

  deleteFriend(friend) {
    console.log(this.contact);
    console.log(this.users);
    //this.userService.updateUser(this.authService.userID, this.fri);

    // this.userHelper.deleteFriend(friend._id).subscribe();
  }

  openUserChat(friend) {
    this.userService.getUserContactData(friend.contactId).pipe(shareReplay(1)).subscribe(res => {
      this.friendData = res;
      this.sendData.sendModel(this.friendData);

    });
    this.userClicked = true;
    this.selectedUser = friend.uniqueId;

  }

  closeChat() {
    this.userClicked = false;
    this.selectedUser = "";
  }

  removeFriend(friend) {
    this.onFriendRemove.emit(friend);
    this.cd.detectChanges();
  }
  deleteFromList(friend) {
    this.onDeleteFromList.emit(friend);
    this.cd.detectChanges();
  }
  removeContactExpect(contact) {
    this.onContactExpect.emit(contact);
    this.cd.detectChanges();
  }
  specificFriendDelete(friend) {
    this.onSpecificContact.emit(friend);
    this.cd.detectChanges();
  }
}
