import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/assets/environments/environment";
import { Contact } from "../interfaces/model";
import { HeaderService } from "./header.service";
import { NotificationService } from "./notification.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root"
})
export class FriendService {
  private apiBaseURL = environment.backend;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    })
  };

  constructor(private http: HttpClient,
     private toastService: ToastService,
     private header: HeaderService,
     private notificationService: NotificationService) { }

  createRequest(newRequest: Contact) {
    return this.http.post<Contact>(this.apiBaseURL + "/createRequest", newRequest, this.header.requestHeaders())
        .pipe(map(res => {
/*

            this.toastService.openSnackBar("Friend request sent Successfully", "", "success-snackbar");

*/
            return res;
        }));

}
getAllFriendRequests() {
  return this.http.get<any[]>(this.apiBaseURL + "/contacts");
}

findAllYourFriends(params): Observable<any> {
  return this.http.post(`${this.apiBaseURL}/findYourContacts`, JSON.stringify(params), this.httpOptions).pipe(
    map((response: Contact) => {
      return response;
    },
    (err) => {
      throw err;
    })
  )
}

getFriendById(params): Observable<any> {
  return this.http.post(`${this.apiBaseURL}/contact`, JSON.stringify(params), this.httpOptions).pipe(
    map((res) => {
      return res;
  },
  (err) => {
    throw err;
  }));
};

updateFriendRequest(updatedRequest) {
  return this.http.put(this.apiBaseURL + "/contacts/" + updatedRequest.id, updatedRequest).pipe(res => {
      return res;
  });
};

deleteFriend(id) {
  return this.http.delete(this.apiBaseURL + "/contact/" + id).pipe(res => {
    return res
  })
}

}
