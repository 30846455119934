import { Injectable } from "@angular/core";
import { SubCategory } from "../interfaces/model";

@Injectable({
  providedIn: "root"
})
export class CategoryService {
  public category = [];
  public categories: Category[];
  constructor() { }

  public getCategory() {
    /* this.category = [
       { checked: false, id: 0, name: "Advertising/Marketing", name: "Advertising/Marketing" },
       { checked: false, id: 1, name: "Agriculture", name: "Agriculture" },
       { checked: false, id: 2, name: "Arts & Entertainment", name: "Arts&Entertainment" },
       { checked: false, id: 3, name: "Automotive, Aircraft & Boat", name: "Automotive}, Aircraft & Boat" },
       { checked: false, id: 4, name: "Beauty, Cosmetic & Personal Care", name: "BeautyCosmetic&PersonalCare" },
       { checked: false, id: 5, name: "Commercial & Industrial", name: "Commercial&Industrial" },
       { checked: false, id: 6, name: "Education", name: "Education" },
       { checked: false, id: 7, name: "Finance", name: "Finance" },
       { checked: false, id: 8, name: "Food & Beverage", name: "Food&Beverage" },
       { checked: false, id: 9, name: "Hotel & Lodging", name: "Hotel&Lodging" },
       { checked: false, id: 10, name: "Legal", name: "Legal" },
       { checked: false, id: 11, name: "Legal Service", name: "LegalService" },
       { checked: false, id: 12, name: "Non-Governmental Organization (NGO) ", name: "Non-GovernmentalOrganization" },
       { checked: false, id: 13, name: "Nonprofit Organization", name: "NonprofitOrganization" },
       { checked: false, id: 14, name: "Science}, Technology & Engineering", name: "ScienceTechnology&Engineering" },
       { checked: false, id: 15, name: "Shopping & Retail ", name: "Shopping&Retail" },
       { checked: false, id: 16, name: "Sports & Recreation", name: "Sports&Recreation" },
       { checked: false, id: 17, name: "Travel & Transportation", name: "Travel&Transportation" },
       { checked: false, id: 18, name: "Community Organization", name: "Community_Organization" },
       { checked: false, id: 19, name: "ArmedForces", name: "Armed Forces" },
       { checked: false, id: 20, name: "Charity Organization", name: "CharityOrganization" },
       { checked: false, id: 21, name: "Community Service", name: "CommunityService" },
       { checked: false, id: 22, name: "Country Club / Clubhouse", name: "CountryClub/Clubhouse " },
       { checked: false, id: 23, name: "Environmental Conservation Organization", name: "EnvironmentalConservationOrganization" },
       { checked: false, id: 24, name: "Labor Union", name: "LaborUnion" },
       { checked: false, id: 25, name: "Private Members Club", name: "PrivateMembersClub" },
       { checked: false, id: 26, name: "Religious Organization", name: "ReligiousOrganization" },
       { checked: false, id: 27, name: "Social Club", name: "SocialClub" },
       { checked: false, id: 28, name: "Sorority & Fraternity", name: "Sorority&Fraternity" },
       { checked: false, id: 29, name: "Sports Club", name: "SportsClub" },
       { checked: false, id: 30, name: "Youth Organization", name: "YouthOrganization" },
       { checked: false, id: 31, name: "Interest", name: "Interest" },
       { checked: false, id: 32, name: "Literary Arts", name: "LiteraryArts" },
       { checked: false, id: 33, name: "Performing Arts", name: "PerformingArts" },
       { checked: false, id: 34, name: "Science", name: "Science" },
       { checked: false, id: 35, name: "Sports", name: "Sports" },
       { checked: false, id: 36, name: "Visual Arts", name: "VisualArts" },
       { checked: false, id: 37, name: "Media", name: "Media" },
       { checked: false, id: 38, name: "Art", name: "Art" },
       { checked: false, id: 39, name: "Books & Magazines", name: "Books&Magazines" },
       { checked: false, id: 40, name: "Concert Tour", name: "ConcertTour" },
       { checked: false, id: 41, name: "Media Restoration Service", name: "MediaRestorationService" },
       { checked: false, id: 42, name: "Music", name: "Music" },
       { checked: false, id: 43, name: "Show", name: "Show" },
       { checked: false, id: 44, name: "TV & Movies", name: " TV&Movies" },
       { checked: false, id: 45, name: "TheatricalPlay", name: "Theatrical Play" },
       { checked: false, id: 46, name: "TheatricalProductions", name: "Theatrical Productions" },
       { checked: false, id: 47, name: "Non-Business Places", name: "Non-Business Places" },
       { checked: false, id: 48, name: "AutomatedTellerMachine", name: "Automated Teller Machine (ATM)" },
       { checked: false, id: 49, name: "CampusBuilding", name: "Campus Building" },
       { checked: false, id: 50, name: "CityInfrastructure", name: "City Infrastructure" },
       { checked: false, id: 51, name: "Landmark&HistoricalPlace", name: "Landmark & Historical Place " },
       { checked: false, id: 52, name: "Locality", name: "Locality" },
       { checked: false, id: 53, name: "MeetingRoom", name: "Meeting Room" },
       { checked: false, id: 54, name: "OutdoorRecreation", name: "Outdoor Recreation" },
       { checked: false, id: 55, name: "PublicToilet", name: "Public Toilet " },
       { checked: false, id: 56, name: "ReligiousPlaceofWorship", name: " Religious Place of Worship" },
       { checked: false, id: 57, name: "Residence", name: "Residence " },
       { checked: false, id: 58, name: "Other", name: "Other" },
       { checked: false, id: 59, name: "Brand", name: "Brand" },
       { checked: false, id: 60, name: "Cause", name: "Cause" },
       { checked: false, id: 61, name: "Color", name: "Color" },
       { checked: false, id: 62, name: "Community", name: "Community " },
       { checked: false, id: 63, name: "Course", name: "Course" },
       { checked: false, id: 64, name: "Cuisine", name: "Cuisine" },
       { checked: false, id: 65, name: "Diseases", name: "Diseases" },
       { checked: false, id: 66, name: "Editorial/Opinion", name: "Editorial/Opinion" },
       { checked: false, id: 67, name: "Election", name: " Election" },
       { checked: false, id: 68, name: "Event", name: "Event" },
       { checked: false, id: 69, name: "ExchangeProgram", name: " Exchange Program" },
       { checked: false, id: 70, name: "FanPage", name: "Fan Page" },
       { checked: false, id: 71, name: "HarmonizedPage", name: "Harmonized Page" },
       { checked: false, id: 72, name: "JustForFun", name: "Just For Fun" },
       { checked: false, id: 73, name: "Language", name: "Language" },
       { checked: false, id: 74, name: "Mood", name: " Mood" },
       { checked: false, id: 75, name: "Nationality", name: " Nationality" },
       { checked: false, id: 76, name: "NotaBusiness", name: "Not a Business" },
       { checked: false, id: 77, name: "Profile", name: "Profile" },
       { checked: false, id: 78, name: "Satire/Parody", name: "Satire/Parody" },
       { checked: false, id: 79, name: "SportsSeason", name:"Sports Season"},
       { checked: false, id: 80, name: "Surgeries", name: "Surgeries" },
       { checked: false, id: 81, name: "TicketSales", name: "Ticket Sales" },
       { checked: false, id: 82, name: "Topic", name: "Topic" },
       { checked: false, id: 83, name: "University(NCES)", name: " University (NCES)" },
       { checked: false, id: 84, name: "UniversityStatus", name: " University Status " },
       { checked: false, id: 85, name: "WorkPosition", name: "Work Position" },
       { checked: false, id: 86, name: "WorkProject", name: " Work Project" },
       { checked: false, id: 87, name: "WorkStatus", name: " Work Status " },
       { checked: false, id: 88, name: "PublicFigure", name: "Public Figure" },
       { checked: false, id: 89, name: "Actor ", name: " Actor " },
       { checked: false, id: 90, name: "Artist", name: " Artist" },
       { checked: false, id: 91, name: "Athlete ", name: " Athlete " },
       { checked: false, id: 92, name: "Author", name: " Author" },
       { checked: false, id: 93, name: "Band", name: " Band" },
       { checked: false, id: 94, name: "Blogger ", name: " Blogger " },
       { checked: false, id: 95, name: "Chef", name: " Chef" },
       { checked: false, id: 96, name: "Comedian", name: " Comedian" },
       { checked: false, id: 97, name: "Dancer", name: " Dancer" },
       { checked: false, id: 98, name: "Designer", name: " Designer" },
       { checked: false, id: 99, name: "DigitalCreator", name: " Digital Creator " },
       { checked: false, id: 100, name: "Editor", name: " Editor" },
       { checked: false, id: 101, name: "Entrepreneur", name: " Entrepreneur" },
       { checked: false, id: 102, name: "FashionModel", name: " Fashion Model " },
       { checked: false, id: 103, name: "FilmDirector", name: " Film Director " },
       { checked: false, id: 104, name: "FitnessModel", name: " Fitness Model " },
       { checked: false, id: 105, name: "Gamer ", name: " Gamer " },
       { checked: false, id: 106, name: "Journalist", name: " Journalist" },
       { checked: false, id: 107, name: "MotivationalSpeaker ", name: " Motivational Speaker" },
       { checked: false, id: 108, name: "Musician", name: " Musician" },
       { checked: false, id: 109, name: "Musician/Band ", name: " Musician/Band " },
       { checked: false, id: 110, name: "NewsPersonality ", name: " News Personality" },
       { checked: false, id: 111, name: "Orchestra ", name: " Orchestra " },
       { checked: false, id: 112, name: "Producer", name: " Producer" },
       { checked: false, id: 113, name: "Scientist ", name: " Scientist " },
       { checked: false, id: 114, name: "SpiritualLeader ", name: " Spiritual Leader" },
       { checked: false, id: 115, name: "SportsPromoter", name: " Sports Promoter " },
       { checked: false, id: 116, name: "TalentAgent ", name: " Talent Agent" },
       { checked: false, id: 117, name: "VideoCreator", name: " Video Creator " },
       { checked: false, id: 118, name: "Writer", name: " Writer" },
       { checked: false, id: 119, name: "Politician", name: " Politician" },
       { checked: false, id: 120, name: "VIP ", name: " VIP " }
     ]; */
  }

  public getCategories() {
    this.categories = [
      {
        checked: false, id: Categories.Businesses, name: "categories.Businesses.name",
        subCategory: [
          { checked: false, id: BusinnesSubCategory.Advertising_Marketing, name: "categories.Businesses.Advertising_Marketing", tags: [] },
          { checked: false, id: BusinnesSubCategory.Agriculture, name: "categories.Businesses.Agriculture", tags: [] },
          { checked: false, id: BusinnesSubCategory.Arts_Entertainment, name: "categories.Businesses.Arts_Entertainment", tags: [] },
          { checked: false, id: BusinnesSubCategory.Automotive_Aircraft_Boat, name: "categories.Businesses.Automotive_Aircraft_Boat", tags: [] },
          { checked: false, id: BusinnesSubCategory.Beauty_Cosmetic_PersonalCare, name: "categories.Businesses.Beauty_Cosmetic_PersonalCare", tags: [] },
          { checked: false, id: BusinnesSubCategory.Construction, name: "categories.Businesses.Construction", tags: [] },
          { checked: false, id: BusinnesSubCategory.Commercial_Industrial, name: "categories.Businesses.Commercial_Industrial", tags: [] },
          { checked: false, id: BusinnesSubCategory.Education, name: "categories.Businesses.Education", tags: [] },
          { checked: false, id: BusinnesSubCategory.Finance, name: "categories.Businesses.Finance", tags: [] },
          { checked: false, id: BusinnesSubCategory.Food_Beverage, name: "categories.Businesses.Food_Beverage", tags: [] },
          { checked: false, id: BusinnesSubCategory.Hotel_Lodging, name: "categories.Businesses.Hotel_Lodging", tags: [] },
          { checked: false, id: BusinnesSubCategory.Legal, name: "categories.Businesses.Legal", tags: [] },
          { checked: false, id: BusinnesSubCategory.Local_Service, name: "categories.Businesses.Local_Service", tags: [] },
          { checked: false, id: BusinnesSubCategory.Media_News_Company, name: "categories.Businesses.Media_News_Company", tags: [] },
          { checked: false, id: BusinnesSubCategory.Medical_Health, name: "categories.Businesses.Medical_Health", tags: [] },
          { checked: false, id: BusinnesSubCategory.ngo, name: "categories.Businesses.ngo", tags: [] },
          { checked: false, id: BusinnesSubCategory.Non_profit_Organization, name: "categories.Businesses.Non_profit_Organization", tags: [] },
          { checked: false, id: BusinnesSubCategory.Real_Estate, name: "categories.Businesses.Real_Estate", tags: [] },
          { checked: false, id: BusinnesSubCategory.Science_Technology_Engineering, name: "categories.Businesses.Science_Technology_Engineering", tags: [] },
          { checked: false, id: BusinnesSubCategory.Shopping_Retail, name: "categories.Businesses.Shopping_Retail", tags: [] },
          { checked: false, id: BusinnesSubCategory.Sports_Recreation, name: "categories.Businesses.Sports_Recreation", tags: [] },
          { checked: false, id: BusinnesSubCategory.Travel_Transportation, name: "categories.Businesses.Travel_Transportation", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Community_Organization, name: "categories.Community_Organization.name",
        subCategory: [
          {
            checked: false, id: CommunityOrganizationSubCategory.Armed_Forces, name: "categories.Community_Organization.Armed_Forces",
            tags: []
          },
          { checked: false, id: CommunityOrganizationSubCategory.Charity_Organization, name: "categories.Community_Organization.Charity_Organization", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Community_Service, name: "categories.Community_Organization.Community_Service", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Country_Club_Clubhouse, name: "categories.Community_Organization.Country_Club_Clubhouse", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Environmental_Conservation_Organization, name: "categories.Community_Organization.Environmental_Conservation_Organization", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Labor_Union, name: "categories.Community_Organization.Labor_Union", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Private_Members_Club, name: "categories.Community_Organization.Private_Members_Club", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Religious_Organization, name: "categories.Community_Organization.Religious_Organization", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Social_Club, name: "categories.Community_Organization.Social_Club", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Sorority_Fraternity, name: "categories.Community_Organization.Sorority_Fraternity", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Sports_Club, name: "categories.Community_Organization.Sports_Club", tags: [] },
          { checked: false, id: CommunityOrganizationSubCategory.Youth_Organization, name: "categories.Community_Organization.Youth_Organization", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Interest, name: "categories.Interest.name",
        subCategory: [
          { checked: false, id: InterestSubCategory.Literary_Arts, name: "categories.Interest.Literary_Arts", tags: [] },
          { checked: false, id: InterestSubCategory.Performance_Art, name: "categories.Interest.Performance_Art", tags: [] },
          { checked: false, id: InterestSubCategory.Performing_Arts, name: "categories.Interest.Performing_Arts", tags: [] },
          { checked: false, id: InterestSubCategory.Science, name: "categories.Interest.Science", tags: [] },
          { checked: false, id: InterestSubCategory.Sports, name: "categories.Interest.Sports", tags: [] },
          { checked: false, id: InterestSubCategory.Visual_Arts, name: "categories.Interest.Visual_Arts", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Media, name: "categories.Media.name",
        subCategory: [
          { checked: false, id: MediaSubCategory.Art, name: "categories.Media.Art", tags: [] },
          { checked: false, id: MediaSubCategory.Books_Magazines, name: "categories.Media.Books_Magazines", tags: [] },
          { checked: false, id: MediaSubCategory.Concert_Tour, name: "categories.Media.Concert_Tour", tags: [] },
          { checked: false, id: MediaSubCategory.Media_Restoration_Service, name: "categories.Media.Media_Restoration_Service", tags: [] },
          { checked: false, id: MediaSubCategory.Music, name: "categories.Media.Music", tags: [] },
          { checked: false, id: MediaSubCategory.Show, name: "categories.Media.Show", tags: [] },
          { checked: false, id: MediaSubCategory.TV_Movies, name: "categories.Media.TV_Movies", tags: [] },
          { checked: false, id: MediaSubCategory.Theatrical_Play, name: "categories.Media.Theatrical_Play", tags: [] },
          { checked: false, id: MediaSubCategory.Theatrical_Productions, name: "categories.Media.Theatrical_Productions", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Non_BusinessPlaces, name: "categories.Non_BusinessPlaces.name",
        subCategory: [
          { checked: false, id: Non_BusinessPlacesSubCategory.A_T_M, name: "categories.Non_BusinessPlaces.A_T_M", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Campus_Building, name: "categories.Non_BusinessPlaces.Campus_Building", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.City_Infrastructure, name: "categories.Non_BusinessPlaces.City_Infrastructure", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Topic_Landmark, name: "categories.Non_BusinessPlaces.Topic_Landmark", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Locality, name: "categories.Non_BusinessPlaces.Locality", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Meeting_Room, name: "categories.Non_BusinessPlaces.Meeting_Room", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Outdoor_Places, name: "categories.Non_BusinessPlaces.Outdoor_Places", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.PublicToilet, name: "categories.Non_BusinessPlaces.PublicToilet", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Religious_Placeof_Worship, name: "categories.Non_BusinessPlaces.Religious_Placeof_Worship", tags: [] },
          { checked: false, id: Non_BusinessPlacesSubCategory.Residence, name: "categories.Non_BusinessPlaces.Residence", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Other, name: "categories.Other.name",
        subCategory: [
          { checked: false, id: OtherSubCategory.Brand, name: "categories.Other.Brand", tags: [] },
          { checked: false, id: OtherSubCategory.Cause, name: "categories.Other.Cause", tags: [] },
          { checked: false, id: OtherSubCategory.Color, name: "categories.Other.Color", tags: [] },
          { checked: false, id: OtherSubCategory.Community, name: "categories.Other.Community", tags: [] },
          { checked: false, id: OtherSubCategory.Course, name: "categories.Other.Course", tags: [] },
          { checked: false, id: OtherSubCategory.Cuisine, name: "categories.Other.Cuisine", tags: [] },
          { checked: false, id: OtherSubCategory.Diseases, name: "categories.Other.Diseases", tags: [] },
          { checked: false, id: OtherSubCategory.Editorial_Opinion, name: "categories.Other.Editorial_Opinion", tags: [] },
          { checked: false, id: OtherSubCategory.Election, name: "categories.Other.Election", tags: [] },
          { checked: false, id: OtherSubCategory.Event, name: "categories.Other.Event", tags: [] },
          { checked: false, id: OtherSubCategory.Exchange_Program, name: "categories.Other.Exchange_Program", tags: [] },
          { checked: false, id: OtherSubCategory.FanPage, name: "categories.Other.FanPage", tags: [] },
          { checked: false, id: OtherSubCategory.Harmonized_Page, name: "categories.Other.Harmonized_Page", tags: [] },
          { checked: false, id: OtherSubCategory.Just_For_Fun, name: "categories.Other.Just_For_Fun", tags: [] },
          { checked: false, id: OtherSubCategory.Language, name: "categories.Other.Language", tags: [] },
          { checked: false, id: OtherSubCategory.Mood, name: "categories.Other.Mood", tags: [] },
          { checked: false, id: OtherSubCategory.Nationality, name: "categories.Other.Nationality", tags: [] },
          { checked: false, id: OtherSubCategory.Not_a_Biz, name: "categories.Other.Not_a_Biz", tags: [] },
          { checked: false, id: OtherSubCategory.Profile, name: "categories.Other.Profile", tags: [] },
          { checked: false, id: OtherSubCategory.Satire_Parody, name: "categories.Other.Satire_Parody", tags: [] },
          { checked: false, id: OtherSubCategory.Sports_Season, name: "categories.Other.Sports_Season", tags: [] },
          { checked: false, id: OtherSubCategory.Surgeries, name: "categories.Other.Surgeries", tags: [] },
          { checked: false, id: OtherSubCategory.Ticket_Sales, name: "categories.Other.Ticket_Sales", tags: [] },
          { checked: false, id: OtherSubCategory.Topic, name: "categories.Other.Topic", tags: [] },
          { checked: false, id: OtherSubCategory.NCES_College_Generated, name: "categories.Other.NCES_College_Generated", tags: [] },
          { checked: false, id: OtherSubCategory.University_Status, name: "categories.Other.University_Status", tags: [] },
          { checked: false, id: OtherSubCategory.Work_Position, name: "categories.Other.Work_Position", tags: [] },
          { checked: false, id: OtherSubCategory.Work_Project, name: "categories.Other.Work_Project", tags: [] },
          { checked: false, id: OtherSubCategory.Work_Status, name: "categories.Other.Work_Status", tags: [] },
        ]
      },
      {
        checked: false, id: Categories.Public_Figure, name: "categories.Public_Figure.name",
        subCategory: [
          { checked: false, id: Public_FigureSubCategory.Actor, name: "categories.Public_Figure.Actor", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Artist, name: "categories.Public_Figure.Artist", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Athlete, name: "categories.Public_Figure.Athlete", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Author, name: "categories.Public_Figure.Author", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Band, name: "categories.Public_Figure.Band", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Blogger, name: "categories.Public_Figure.Blogger", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Chef, name: "categories.Public_Figure.Chef", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Comedian, name: "categories.Public_Figure.Comedian", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Dancer, name: "categories.Public_Figure.Dancer", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Designer, name: "categories.Public_Figure.Designer", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Digital_Creator, name: "categories.Public_Figure.Digital_Creator", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Editor, name: "categories.Public_Figure.Editor", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Entrepreneur, name: "categories.Public_Figure.Entrepreneur", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Fashion_Model, name: "categories.Public_Figure.Fashion_Model", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Film_Director, name: "categories.Public_Figure.Film_Director", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Fitness_Model, name: "categories.Public_Figure.Fitness_Model", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Gamer, name: "categories.Public_Figure.Gamer", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Journalist, name: "categories.Public_Figure.Journalist", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Motivational_Speaker, name: "categories.Public_Figure.Motivational_Speaker", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Musician, name: "categories.Public_Figure.Musician", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Musician_Band, name: "categories.Public_Figure.Musician_Band", tags: [] },
          { checked: false, id: Public_FigureSubCategory.News_Personality, name: "categories.Public_Figure.News_Personality", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Orchestra, name: "categories.Public_Figure.Orchestra", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Producer, name: "categories.Public_Figure.Producer", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Scientist, name: "categories.Public_Figure.Scientist", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Spiritual_Leader, name: "categories.Public_Figure.Spiritual_Leader", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Sports_Promoter, name: "categories.Public_Figure.Sports_Promoter", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Talent_Agent, name: "categories.Public_Figure.Talent_Agent", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Video_Creator, name: "categories.Public_Figure.Video_Creator", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Writer, name: "categories.Public_Figure.Writer", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Politician, name: "categories.Public_Figure.Politician", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Moderator, name: "categories.Public_Figure.Moderator", tags: [] },
          { checked: false, id: Public_FigureSubCategory.Manager, name: "categories.Public_Figure.Manager", tags: [] },

        ]
      },
    ]
  }
}

export enum Categories {
  All = "All",
  Businesses = "Businesses",
  Community_Organization = "Community_Organization",
  Interest = "Interest",
  Media = "Media",
  Non_BusinessPlaces = "Non_BusinessPlaces",
  Other = "Other",
  Public_Figure = "Public_Figure",
}

export enum BusinnesSubCategory {
  Advertising_Marketing = "Advertising_Marketing",
  Agriculture = "Agriculture",
  Arts_Entertainment = "Arts_Entertainment",
  Automotive_Aircraft_Boat = "Automotive_Aircraft_Boat",
  Beauty_Cosmetic_PersonalCare = "Beauty_Cosmetic_PersonalCare",
  Commercial_Industrial = "Commercial_Industrial",
  Construction = "Construction",
  Education = "Education",
  Finance = "Finance",
  Food_Beverage = "Food_Beverage",
  Hotel_Lodging = "Hotel_Lodging",
  Legal = "Legal ",
  Local_Service = "Local_Service",
  Media_News_Company = "Media_News_Company",
  Medical_Health = "Medical_Health",
  ngo = "ngo",
  Non_profit_Organization = "Non_profit_Organization",
  Real_Estate = "Real_Estate",
  Science_Technology_Engineering = "SScience_Technology_Engineering",
  Shopping_Retail = "Shopping_Retail",
  Sports_Recreation = "Sports_Recreation",
  Travel_Transportation = "Travel_Transportation"
}
export enum CommunityOrganizationSubCategory {
  Armed_Forces = "Armed_Forces",
  Charity_Organization = "Charity_Organization",
  Community_Service = "Community_Service",
  Country_Club_Clubhouse = "Country_Club_Clubhouse",
  Environmental_Conservation_Organization = "Environmental_Conservation_Organization",
  Labor_Union = "Labor_Union",
  Private_Members_Club = "Private_Members_Club",
  Religious_Organization = "Religious_Organization",
  Social_Club = "Social_Club",
  Sorority_Fraternity = "Sorority_Fraternity_",
  Sports_Club = "Sports_Club",
  Youth_Organization = "Youth_Organization"
}
export enum InterestSubCategory {
  Literary_Arts = "Literary_Arts",
  Performance_Art = "Performance_Art",
  Performing_Arts = "Performing_Arts",
  Science = "Science",
  Sports = "Sports",
  Visual_Arts = "Visual_Arts",
}
export enum MediaSubCategory {
  Art = "Art",
  Books_Magazines = "Books_Magazines",
  Concert_Tour = "Concert_Tour",
  Media_Restoration_Service = "Media_Restoration_Service",
  Music = "Music",
  Show = "Show",
  TV_Movies = "TV_Movies",
  Theatrical_Play = "Theatrical_Play",
  Theatrical_Productions = "Theatrical_Productions",
}
export enum Non_BusinessPlacesSubCategory {
  A_T_M = "A_T_M",
  Campus_Building = "Campus_Building",
  City_Infrastructure = "City_Infrastructure",
  Topic_Landmark = "Topic_Landmark",
  Locality = "Locality",
  Meeting_Room = "Meeting_Room",
  Outdoor_Places = "Outdoor_Places",
  PublicToilet = "PublicToilet",
  Religious_Placeof_Worship = "Religious_Placeof_Worship",
  Residence = "Residence",
}
export enum OtherSubCategory {
  Brand = "Brand",
  Cause = "Cause",
  Color = "Color",
  Community = "Community",
  Course = "Course",
  Cuisine = "Cuisine",
  Diseases = "Diseases",
  Editorial_Opinion = "Editorial_Opinion",
  Election = "Election",
  Event = "Event",
  Exchange_Program = "Exchange_Program",
  FanPage = "FanPage",
  Harmonized_Page = "Harmonized_Page",
  Just_For_Fun = "Just_For_Fun",
  Language = "Language",
  Mood = "Mood",
  Nationality = "Nationality",
  Not_a_Biz = "Not_a_Biz",
  Profile = "Profile",
  Satire_Parody = "Satire_Parody",
  Sports_Season = "Sports_Season",
  Surgeries = "Surgeries",
  Ticket_Sales = "Ticket_Sales",
  Topic = "Topic",
  NCES_College_Generated = "NCES_College_Generated",
  University_Status = "University_Status",
  Work_Position = "Work_Position",
  Work_Project = "Work_Project",
  Work_Status = "Work_Status",
}
export enum Public_FigureSubCategory {
  Actor = "AActor",
  Artist = "Artist",
  Athlete = "Athlete",
  Author = "Author",
  Band = "Band",
  Blogger = "Blogger",
  Chef = "Chef",
  Comedian = "Comedian",
  Dancer = "Dancer",
  Designer = "Designer",
  Digital_Creator = "Digital_Creator",
  Editor = "Editor",
  Entrepreneur = "Entrepreneur",
  Fashion_Model = "Fashion_Model",
  Film_Director = "Film_Director",
  Fitness_Model = "Fitness_Model",
  Gamer = "Gamer",
  Journalist = "Journalist",
  Motivational_Speaker = "Motivational_Speaker",
  Musician = "Musician",
  Musician_Band = "LMusician_Band",
  News_Personality = "News_Personality",
  Orchestra = "Orchestra",
  Producer = "Producer",
  Scientist = "Scientist",
  Spiritual_Leader = "Spiritual_Leader",
  Sports_Promoter = "Sports_Promoter",
  Talent_Agent = "Talent_Agent",
  Video_Creator = "Video_Creator",
  Writer = "Writer",
  Politician = "Politician",
  Moderator = "Moderator",
  Manager = "Manager",
}


export interface Category {
  checked: boolean;
   id: string;
    name: string;
  subCategory: CategorySub[ ]

}

export interface CategorySub {
    id: string,
    checked: boolean,
    name: string,
    tags: []
}



