import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryDataService {

  public model;

  countryData = new BehaviorSubject<[]>([]);

  public countryData$ = this.countryData.asObservable();

  constructor(private http: HttpClient) {
    
    this.http.get<any>("assets/country.json").subscribe(data => {
       this.model = data.Tabelle2;
       this.countryData.next(this.model)
    });
  }
}
