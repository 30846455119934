
<ng-container *ngIf="isPage">
  <div *ngFor="let message of appliedPageProjects"  [appliedProjects]="true">
    <app-job-item [projectID]="message?.projectID" [pageObject]="page" [appliedProjects]="true" [pageProjects]="true"></app-job-item>
  </div>

</ng-container>



<ng-container *ngIf="isUser">
  <div *ngFor="let job of appliedProjects" >
    <app-job-item [projectID]="job?.projectID" [appliedUserProjects]="true"></app-job-item>
  </div>

</ng-container>


