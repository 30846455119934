import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, delay, distinctUntilChanged, map, share, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/assets/environments/environment';
import { Page } from '../interfaces/model';
import { User } from '../interfaces/user.model';
import { NotificationHelperService } from '../utils/notification-helper.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  public baseUrl = environment.backend;
  public baseXYUrl = environment.backendXY;
  public data = new ReplaySubject<Array<Page>>();
  public userID = this.authService.userID;
  public editDataDetails: any = [];
  public subject = new Subject<any>();
  private messageSource = new BehaviorSubject(this.editDataDetails);
  getPageID = this.messageSource.asObservable();

  private appliedPageProjects = new ReplaySubject<any>(1);
  private savedPageProjects = new ReplaySubject<any>(1);
  private ignoredPageProjects = new ReplaySubject<any>(1);
  private applyPageProjects = new ReplaySubject<any>(1);

  private _loadPages: BehaviorSubject<any> = new BehaviorSubject({});
  loadPages: Observable<any> = this._loadPages.asObservable().pipe(distinctUntilChanged());




  public lastSetOfData: Array<Page> = [];
  public headers = new HttpHeaders({
    'userId': this.authService.userID
  })
  api = `${this.baseUrl}/page`;

  constructor(private http: HttpClient,
    private authService: AuthService,
    private notificationHelper: NotificationHelperService,
    private userService: UserService,
  ) { }

  public getPages() {
    const userID = this.authService.userID;
    return this.http.get(`${this.baseXYUrl}/userPages/${userID}`);
  }

  getMessagePageList(id: string) {
    if(!!id) {
      return this.http.get<any>(`${this.baseXYUrl}/messageListPage/${id}`)
    }

  }

  public updateMessagePageList(id, data) {
    const api = `${this.baseXYUrl}/messageListPage/${id}`
    this.http.put<any>(api, data).pipe(take(1)).subscribe();
  }

  public search(name: string): Observable<any> {
    return this.http.get<Page[]>(`${this.baseXYUrl}/findPages/${name}`).pipe(map(res => {
      this._loadPages.next(res);
    }))
  }

  getJobList(id): Observable<any> {
    if(!!id) {
      return this.http.get<any>(`${this.baseXYUrl}/jobListPage/${id}`)
    }
  }

  public updateJobList(id, data) {
    const api = `${this.baseXYUrl}/jobListPage/${id}`
    this.http.put<any>(api, data).pipe(take(1)).subscribe();
  }


  getProjectList(id): Observable<any> {
    if(!!id) {
      return this.http.get<any>(`${this.baseXYUrl}/projectListPage/${id}`)
    }
  }

  public updateProjectList(id, data) {
    const api = `${this.baseXYUrl}/projectListPage/${id}`
    this.http.put<any>(api, data).pipe(take(1)).subscribe();
  }

  getPagesCompany(name) {
    return this.http.get<any>(`${this.baseUrl}/findCompanies/${name}`).pipe(shareReplay(1));
  }
  findCompaniesAndUsers(name) {
    return this.http.get<any>(`${this.baseUrl}/findCompaniesAndUsers/${name}`);
  }

  public getAllRolePages() {
    let httpParams = new HttpParams();
    httpParams.set("userId", this.authService.userID);

    return this.http.get<any[]>(this.baseUrl + "/pages", {
      headers: this.headers,
      params: httpParams
    });

  }

  public showNotifications(pageID) {
    if (!!pageID) {
      return this.http.get<any>(`${this.baseUrl}/page/notifications/${pageID}`).pipe(shareReplay(1));
    }
  }




  public getAllPages() {
    return this.http.get<any>(`${this.baseUrl}/pages/${this.userID}`);
  }
  public get allPages(): Observable<Page[]> {
    return this.http.get<Page[]>(`${this.baseUrl}/pages`);
  }
  public pageModel(): Observable<Page[]> {
    return this.data.asObservable();
  }
  public getPage(id): Observable<any> {
    if (!!id) {
      return this.http.get(`${this.baseXYUrl}/page/${id}`);

    }
  }
  public getPageUser(id): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/page/user/${id}`);
  }
  public getAllPagesOfUser(id): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/page/addedUser/${id}`);
  }

  public getMyPages() {
    return this.http.get<any>(`${this.baseXYUrl}/loadPages/${this.userID}`).subscribe(res => {
      this.lastSetOfData = res;
      this.data.next(this.lastSetOfData);
    });
  }


  public setPage(page: Page): Observable<any> {

    return this.http.post<any>(this.baseXYUrl+'/page', page).pipe(
      catchError(this.handleError)
    );
  }
  public createPage(page: Page) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body'
    };
    return this.http.post<any>(this.api, page, httpOptions).pipe(map((response: HttpResponse<any>) => {
      this.userService.getUserData(this.authService.userID).pipe(shareReplay(1)).subscribe((res: User) => {
        const page = {
          pageId: response.body._id,
          timestamp: new Date(),
          status: 1
        };
        res.pages.unshift(page);
        this.userService.updateUser(res._id, res);
      });
      this.lastSetOfData = [...this.lastSetOfData, response.body]
       this.userService._pageListSource.next(this.lastSetOfData)
    }))
    /*subscribe(response => {
    this.userService.getUserData(this.authService.userID).pipe(shareReplay(1)).subscribe((res:User) => {
      console.log(response, "response");
      const page = {
        pageId: response._id,
        timestamp: new Date(),
        status: 1
      };

      res.pages.unshift(page);
      this.userService.updateUser(res._id, res);
  });
    this.lastSetOfData = [...this.lastSetOfData, response]
    this.data.next(this.lastSetOfData)
  }); */
  }
  public updatePage(id: string, page: Page) {

    const api = `${this.baseXYUrl}/page/${id}`;
    return this.http.put<any>(api, page).subscribe((res) => {
      const index: number = this.lastSetOfData.findIndex((_page: Page) => _page._id === page._id);
      this.lastSetOfData[index] = page;
      this.lastSetOfData = [...this.lastSetOfData];
      this.data.next(this.lastSetOfData);
      new Observable(observer => {
        const notification = {
          description: "wants to be your friend",
          userId: this.authService.userID,
          friendId: this.authService.userID,
          read: false,
          seen: false
        }
       /* this.notificationHelper.createNewNotificationRequest(notification).subscribe(() => {
          observer.next();
        }); */
      })
    });
  }

  public updatePageForAds(id: string, page: Page) {
    const api = `${this.baseUrl}/page/${id}`;
    return this.http.put<any>(api, page).subscribe(t => t);
  }

  updatePageAndRoles(page: any, roles: any) {
    const api = `${this.baseXYUrl}/pageAndRoles/${page._id}`;
    return this.http.put<any>(api, {page: page, roles: roles})
  }

  public pageAppliedProjects(): Observable<Page> {
    return this.appliedPageProjects.asObservable();
  }
  public pageApplyProjects(): Observable<Page> {
    return this.applyPageProjects.asObservable();
  }

  public getAppliedPageProjects() {
    this.http.get(`${this.baseUrl}/appliedPageProjects/${this.authService.userID}`).subscribe(res => this.appliedPageProjects.next(res));
  }

  public getApplyPageProjects(pageID) {
    this.http.get(`${this.baseUrl}/appliedPageProjects/${pageID}`).subscribe(res => this.applyPageProjects.next(res));

  }

  getMyPagesOfProject(): Observable<any> {
   return this.http.get(`${this.baseXYUrl}/getMyPagesOfProject/${this.authService.userID}`);
  }



  public updatePageObject(id, page) {
    const api = `${this.baseUrl}/page/${id}`;
    return this.http.put<any>(api, page).subscribe((res) => {
console.log(res)
  })
}

  public pageIgnoredProjects(): Observable<Page> {
    return this.ignoredPageProjects.asObservable();
  }

  public getIgnoredPageProjects() {
    this.http.get(`${this.baseUrl}/ignoredPageProjects/${this.authService.userID}`).subscribe(res => this.ignoredPageProjects.next(res));
  }

  public pageSavedProjects(): Observable<Page> {
    return this.savedPageProjects.asObservable();
  }

  public getSavedPageProjects() {
    this.http.get(`${this.baseUrl}/savedPageProjects/${this.authService.userID}`).subscribe(res => this.savedPageProjects.next(res));
  }


  public showFollowers(userId): Observable<User[]> {
    if (!!userId) {
      return this.http.get<User[]>(`${this.baseUrl}/pageFollowers/${userId}`).pipe(shareReplay(1));
    }
  }


  public showEmployers(pageID): Observable<User[]> {
    if (!!pageID) {
      return this.http.get<User[]>(`${this.baseUrl}/pageEmployers/${pageID}`).pipe(shareReplay(1));
    }
  }





  changeMessage(message: string) {
    this.messageSource.next(message)
  }
  handleError(error: HttpErrorResponse) {
    let msg = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }

}
