import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Qualifications } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';
import { EditQualifications, QualificationsDialogComponent } from '../qualifications-dialog/qualifications-dialog.component';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-qualifications-dialog-array',
  templateUrl: './qualifications-dialog-array.component.html',
  styleUrls: ['./qualifications-dialog-array.component.scss']
})
export class QualificationsDialogArrayComponent implements OnInit {
  public selectedQualifications: number = null;
  public user: User;
  public dataList$: Observable<any>;
  constructor(@Inject(MAT_DIALOG_DATA) public data: EditQualificationsArray,
  private dialogRef: MatDialogRef<QualificationsDialogArrayComponent>,
  public authService: AuthService,
  public dialog: MatDialog,
  public pageService: PagesService,
  public userService: UserService
  ) { }

  ngOnInit(): void {
    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe((res: any) => {
      this.user = res.user;
      this.data.qualifications = res.cv?.data[this.data.index]?.skills[0].qualifications.data;
    });

    ({ qualifications: this.data.qualifications } = this.data);


  }

  public setQualifications(index) {
    this.selectedQualifications = index;
  }
  public removeClick() {
    this.selectedQualifications = undefined;
  }

  editQualifications(data: EditQualifications) {
    this.dialog.open(QualificationsDialogComponent, {
      data,
    })
  }
  addNewQualifications(data: EditQualifications) {
    this.dialog.open(QualificationsDialogComponent, {
      data,
    }).afterClosed().subscribe(res => {
      if (res) {
        this.data.qualifications.unshift(res.qualifications);
      }
    })
  }
  saveData() {
    this.user.searchFilterSkills.searchQualifications.data =  [];
    this.user.searchFilterSkills.searchQualifications.data = this.data.qualifications;

   this.dialogRef.close(this.data);
  }

  deleteQualification(index) {
    this.data.qualifications.splice(index, 1);
  }


}

export interface EditQualificationsArray {
  qualifications: Qualifications[];
  user?: User;
  index?: number
}
