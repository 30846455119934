<aside class="pt-4 aside" style="left: 0">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3">
      <h2>{{'Search results'}}</h2>
      <hr>
      <h3>{{'Filters'}}</h3>
      <div class="card__list">
        <div class="d-flex row px-0 align-items-center card_list">
          <div style="border-radius: 50%;
          background: #1876f2;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;">
          <i class="bi bi-search" style="color: white"></i>
          </div>
          <span class="pr-2 pl-2">
            {{ 'All' }}
            <span> </span>
          </span>
        </div>
      </div>
      <div class="card__list">
        <div class="d-flex row px-0 align-items-center card_list">
          <div style="border-radius: 50%;
          background: #1876f2;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;">
          <i class="bi bi-search" style="color: white"></i>
          </div>
          <span class="pr-2 pl-2">
            {{ 'Pages' }}
            <span> </span>
          </span>
        </div>
      </div>
      <div class="card__list">
        <div class="d-flex row px-0 align-items-center card_list">
          <div style="border-radius: 50%;
          background: #1876f2;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;">
          <i class="bi bi-search" style="color: white"></i>
          </div>
          <span class="pr-2 pl-2">
            {{ 'Groups' }}
            <span> </span>
          </span>
        </div>
      </div>
      </div>
</section>
</aside>
