import { Output } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Group, Image } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { GroupDetailsComponent } from '../group-details/group-details.component';

@Component({
  selector: 'app-sidebar-groups-details',
  templateUrl: './sidebar-groups-details.component.html',
  styleUrls: ['./sidebar-groups-details.component.scss']
})
export class SidebarGroupsDetailsComponent extends GroupDetailsComponent implements OnInit {
@Input() group: Group;
public image;
@Output() changeEdit: EventEmitter<any> = new EventEmitter<any>();
public outsiderGroupId;

  constructor(public router: Router,
    public groupService: GroupService,
    public authService: AuthService,
    public cd: ChangeDetectorRef,
    public imageService: ImageService,
    public dialog: MatDialog) {
    super(router, groupService, authService, cd, imageService, dialog);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url = event.url.replace("/groups", "");
        event.url = event.url.replace("/", "");
        this.outsiderGroupId = event.url;
      }
  });
  }

  ngOnInit(): void {
    this.groupService.getGroups();
    this.groups$ = this.groupService.groupModel();
    this.groupService.getPageID.subscribe(data => {
      if(data.length > 0) {
      this.groupService.getGroup(data).subscribe(group => {
        this.group = group;
        if(this.group.profileImg) {
          this.imageService.getImageByID(this.group.profileImg).subscribe((res: Image) => {
            if(res.imageUrl) {
              this.profileImage = res.imageUrl;
              this.hasPhoto = true;
              this.cd.detectChanges();
            }
          });
        } else if(this.group.profileImg === undefined) {
          return this.hasPhoto = false;
        }
        if(this.group.url) {
          this.webUrl = this.group.url;
        } else {
          this.webUrl = this.group._id;
        }

      })
    } else {
      this.groupService.getGroupUser(this.outsiderGroupId).subscribe(group => {
        this.group = group[0];
        if(this.group.profileImg) {
          this.imageService.getImageByID(this.group.profileImg).subscribe((res: Image) => {
            if(res.imageUrl) {
              this.profileImage = res.imageUrl;
              this.hasPhoto = true;
              this.cd.detectChanges();
            }
          });
        } else if(this.group.profileImg === undefined) {
          return this.hasPhoto = false;
        }
      });
    }
    })
  }
  public edit(edit: any): void {
    this.changeEdit.emit(edit);
}

}
