import {ChangeDetectorRef, Component, Input, OnInit} from "@angular/core";
import {Category} from "../../shared/enums/enums-model";
import {DialogService} from "../../shared/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {SubCategoryDialogComponent, SubDialog} from "../modals/sub-category-dialog/sub-category-dialog.component";
import {EditLanguage, LanguageDialogComponent} from "../modals/editors/language-dialog/language-dialog.component";
import {EditOffer, OfferDialogComponent} from "../modals/editors/offer-dialog/offer-dialog.component";
import {
  EditPCKnowledge,
  PcKnowledgeDialogComponent
} from "../modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component";
import {
  DriveLicenseDialogComponent,
  EditDriveLicense
} from "../modals/editors/drive-license-dialog/drive-license-dialog.component";
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from "src/app/shared/services/user.service";
import { EditQualifications, QualificationsDialogComponent } from "../modals/editors/qualifications-dialog/qualifications-dialog.component";
import { QualificationsDialogArrayComponent } from "../modals/editors/qualifications-dialog-array/qualifications-dialog-array.component";
import { Skills, Model, SkillsSub } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.scss"]
})
export class SkillsComponent implements OnInit {
  @Input() skills: SkillsSub;
  @Input() model: Model;
  @Input() skillObj: SkillsSub;
  public category = Category;
  @Input() userCV: any;
  @Input() user: User;
  @Input() id: number;
  @Input() first: number;
  @Input() last: number;
  inputHtml = "";
  starCount = 5;
  languages = [];
  @Input() indexOfLang;

  @Input() indexOfSelected: number;

  public selectedIndex: number;
  public selectedSkill: number;
  public selectedLanguage: number;
  public selectedPCKnowledge: number;
  public selectedCategory: number = null;
  public selectedDriveLicense: number = null;
  public selectedLanguageFirst: number = null;
  public selectedQualifications: number = null;
  public selectedEmptyObj: number = null;
  public setRemovableDriveLicense: boolean;
  public setRemovablePcKnowledge: boolean;
  public setRemovableOffer: boolean;
  public selectedRow = false;

  constructor(public dialogService: DialogService,

              public authService: AuthService,
              public dialog: MatDialog,
              public userService?: UserService,
              public http?: HttpClient,
              public cd?: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.http.get<any>(`assets/languages/${this.model?.language?.primary}/language.json`).subscribe(data => {

      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

    })
  }
  public setIndex(id) {
    this.selectedIndex = id;
    this.selectedCategory = null;
  }
  public removeClick() {
    this.selectedIndex = undefined;
    this.selectedSkill = undefined;
    this.selectedCategory = undefined;
    this.selectedPCKnowledge = undefined;
    this.selectedLanguage = undefined;
    this.selectedDriveLicense = undefined;
    this.selectedLanguageFirst = undefined;
    this.selectedQualifications = undefined;
    this.setRemovableDriveLicense = false;
    this.setRemovablePcKnowledge = false;
    this.selectedRow = false;
  }
 public setIndexForSkill(index) {
    this.selectedIndex = undefined;
    this.selectedSkill = index;
    this.selectedCategory = undefined;
    this.selectedPCKnowledge = undefined;
    this.selectedLanguageFirst = undefined;
    this.setRemovableOffer = true;
    this.setRemovablePcKnowledge = false;
    this.setRemovableDriveLicense = false;
    this.selectedRow = false;

 }
 public setIndexForPcKnowledge(index) {
   this.selectedIndex = undefined;
   this.selectedSkill = undefined;
   this.selectedCategory = undefined;
   this.selectedPCKnowledge = index;
   this.selectedDriveLicense = undefined;
   this.selectedQualifications = undefined;
   this.selectedLanguage = undefined;
   this.setRemovablePcKnowledge = true;
   this.setRemovableOffer = false;
   this.setRemovableDriveLicense = false;
   this.selectedRow = false;

 }
 public setLanguageIndex(i) {
   this.selectedDriveLicense = undefined;
   this.selectedIndex = undefined;
   this.selectedSkill = undefined;
   this.selectedCategory = undefined;
   this.selectedPCKnowledge = undefined;
   this.selectedLanguageFirst = undefined;
   this.selectedQualifications = undefined;
   this.selectedLanguage = i;
   this.setRemovableDriveLicense = false;
   this.setRemovableOffer = false;
   this.setRemovablePcKnowledge = false;
   this.selectedRow = false;
 }
 public setIndexDriveLicense(index) {
    this.selectedDriveLicense = index;

    this.selectedIndex = undefined;
    this.selectedSkill = undefined;
    this.selectedCategory = undefined;
    this.selectedPCKnowledge = undefined;
    this.selectedLanguageFirst = undefined;
    this.selectedQualifications = undefined;
    this.selectedLanguage = undefined;
    this.setRemovableDriveLicense = true;
    this.setRemovablePcKnowledge = false;
    this.setRemovableOffer = false;
    this.selectedRow = false;
 }
 public setIndexLanguageFirst(index) {
   this.selectedDriveLicense = undefined;
   this.selectedIndex = undefined;
   this.selectedSkill = undefined;
   this.selectedCategory = undefined;
   this.selectedPCKnowledge = undefined;
   this.selectedLanguageFirst = index;
   this.selectedQualifications = undefined;
   this.selectedLanguage = undefined;
   this.setRemovableDriveLicense = false;
   this.setRemovablePcKnowledge = false;
   this.setRemovableOffer = false;
   this.selectedRow = false;
   this.selectedRow = false;

 }
 public setQualifications(index) {
   this.selectedDriveLicense = undefined;
   this.selectedIndex = undefined;
   this.selectedSkill = undefined;
   this.selectedCategory = undefined;
   this.selectedPCKnowledge = undefined;
   this.selectedLanguageFirst = undefined;
   this.selectedQualifications = index;
   this.selectedRow = false;

 }
 public setIndexForEmptyObj(index) {
   this.selectedEmptyObj = index;
 }
 doSomething() {
   this.selectedRow = true;
 }
  openSubCatDialog(data: SubDialog) {
    this.dialog.open(SubCategoryDialogComponent, {
     data,
   }).afterClosed().subscribe(result => {
     if (result === undefined) {
       return;
     } else {
       if (result.id === "Offer") {
         this.skills.skillsOffer.data.unshift(
           {name: "Java", rate: 0},
           {name: "Javascript", rate: 0});
        // this.dataService.updatePage(this.model._id, this.model);
       } else if (result.id === "Language") {
         this.skills.languages.data.unshift(
           {name: "en", rate: 100}
         );
         // this.dataService.updatePage(this.model._id, this.model);
       } else if (result.id === "DriveLicense") {
         this.skills.driveLicenses.data.unshift(
           {name: "B"}
         );
         // this.dataService.updatePage(this.model._id, this.model);
       } else if (result.id === "PCKnowledge") {
         this.skills.pcKnowledge.data.unshift(
           {name: "Word", rate: 0},
           {name: "Photoshop", rate: 0}
         );
         // this.dataService.updatePage(this.model._id, this.model);
       } else if (result.id === "Qualifications") {
         this.skills.qualifications.data = [];
         // this.dataService.updatePage(this.model._id, this.model);

       } else if (result.id === "space") {
          this.inputHtml = "<br>";
       } else {
         return null;
       }

       this.userCV.data[this.indexOfSelected].skills[0] = this.skills;
       this.userService.updateCV(this.userCV._id, this.userCV)




      // this.userService.updateCV(this.model._id, this.model)
     }
   });
  }
  public openDialog() {
    this.dialogService.openDialog().subscribe(result => {
     /* this.model.careers.unshift({subCategories: [{
          name: "test",
          description: "wdw1d",
          startDate: 12231,
          endDate: 1212,
          role: "Developer",
        }]});*/
    });
    // this.dataService.updatePage(this.model._id, this.model);
  }

  copyLanguage() {
    this.skills.languages.data.unshift({name: "English", rate: 4});
    // this.dataService.updatePage(this.model._id, this.model);
   //  this.dataService.getJSON();
  }


  deleteSubCategory(index) {
  }

  deleteLanguageFirst(index) {
    this.skills.languages.data = [];
    this.userCV.data[this.indexOfSelected].skills[0] = this.skills;
    this.userService.updateCV(this.userCV._id, this.userCV)

   // this.dataService.updatePage(this.model._id, this.model);
  }

  /** Delete Sub categories */
  deleteLanguage(index) {
    this.skills.languages.data.splice(index, 1);
   //  this.dataService.updatePage(this.model._id, this.model);
  }
  deleteQualifications(index) {
    this.skills.qualifications.data = [];
    // this.dataService.updatePage(this.model._id, this.model);
  }
  deleteSkillOffer(index) {
    this.skills.skillsOffer.data = [];
    // this.dataService.updatePage(this.model._id, this.model);
  }
  deletePCKnowledge(index) {
    this.skills.pcKnowledge.data = [];
    // this.dataService.updatePage(this.model._id, this.model);
  }
  deleteDriveLicense(index) {
    this.skills.driveLicenses.data = [];
    // this.dataService.updatePage(this.model._id, this.model);
  }

  editLanguages(data: EditLanguage) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {

        this.skillObj = res;
        this.user.cv.data[0].skills[0] = this.skillObj
        this.userService.updateUser(this.authService.userID, this.user)
        this.cd.detectChanges();
      }
    });
  }
  editOffer(data: EditOffer) {
    this.dialog.open(OfferDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        console.log(res, "resss")
        this.skillObj = res;
        this.cd.detectChanges();
      }
    });
  }
  editPCKnowledge(data: EditPCKnowledge) {
    this.dialog.open(PcKnowledgeDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.skillObj = res;
        this.cd.detectChanges();
      }
    });
  }
  editDriveLicense(data: EditDriveLicense) {
    this.dialog.open(DriveLicenseDialogComponent, {
      data
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.skillObj = res;
        this.cd.detectChanges();
      }
    });
  }
  editQualifications(data: EditQualifications) {
    this.dialog.open(QualificationsDialogArrayComponent, {
      data,
    }).afterClosed().subscribe(res => {
      if (!!res) {
       this.skillObj.qualifications = res.qualifications;
       this.user.cv.data[this.indexOfLang].skills[0].qualifications = this.skillObj.qualifications;
       this.userService.updateUser(this.user._id, this.user);
      }
    })
  }
  public get checkId() {
    return this.authService.userID === this.user?._id;
    }

    public swapArrays(arr, indexA, indexB) {
      let temp = arr[indexA];
      arr[indexA] = arr[indexB];
      arr[indexB] = temp;
    this.userService.updateUser(this.authService.userID, this.user)
    };
    swapArrayTop(arr, indexA, indexB) {
      this.swapArrays(arr, indexA, indexA - 1);
    }

    swapArray(arr, indexA, indexB) {
      this.swapArrays(arr, indexA, indexA + 1);
    }
    public getLanguageName(type) {
      return this.languages.find(t => t.type === type);
    }

}
