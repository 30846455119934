<div class="left-sidebar" id="main" *ngIf="router.url.endsWith('bookmarks')">
    <div class="imp-links">
        <button type="button" class="button-f button-sc" (click)="navigateToUser()">
            <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
              <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
               <span class="span-padding-left10">{{this.currentUser?.firstName}} {{this.currentUser?.lastName}}</span>
          </button>

        <button type="button" class="button-f button-sc" [routerLink]="'../' + userUrl + '/cv'">
            <div >
                <i class="bi bi-file-text width-40"></i>
              <span class="span-padding-left10">{{'header.cv' | translate}}</span>
                        </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/pages">
            <div>
                
             <span class="span-padding-left10">{{'header.pages' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/groups">
            <div>
                <i class="bi bi-people width-40"></i>
             <span class="span-padding-left10">{{'header.groups' | translate}}</span>
            </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/contacts">
            <div>
                <i class="bi bi-person width-40"></i>
              <span class="span-padding-left10">Contacts</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" [routerLink]="'../' +userUrl + '/jobs'">
            <div>
              <i class="bi bi-list-task width-40"></i>
              <span class="span-padding-left10">{{'sidebar.jobs' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" [routerLink]="'../'+userUrl + '/projects'">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-bar-chart-steps width-40" viewBox="0 0 16 16">
              <path
                d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z" />
            </svg>
              <span class="span-padding-left10">{{'sidebar.projects' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" (click)="navigateToPayment()">
            <div>
              <i class="bi bi-credit-card"></i>
              <span class="span-padding-left10">{{'Payment'}}</span>

            </div>
          </button>            <a href="#">See More</a>
    </div>
    <div class="shortcut-links">
     <p>Your Shortcuts</p>
     <a href="#"><img src="../../assets/img/shortcut-1.png"> Web Developers</a>
     <a href="#"><img src="../../assets/img/shortcut-2.png"> Web Design Course</a>
     <a href="#"><img src="../../assets/img/shortcut-3.png"> Full Stack Development</a>
     <a href="#"><img src="../../assets/img/shortcut-4.png"> Website Experts</a>
     
 </div>
</div>


<!-- Left sidebar without route -->
<div class="left-sidebar-route" id="main" *ngIf="!router.url.endsWith('bookmarks')">
    <div class="imp-links">
        <button type="button" class="button-f button-sc" (click)="navigateToUser()">
            <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
              <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32">
               <span class="span-padding-left10">{{this.currentUser?.firstName}} {{this.currentUser?.lastName}}</span>
          </button>

        <button type="button" class="button-f button-sc" [routerLink]="userUrl + '/cv'">
            <div >
                <i class="bi bi-file-text width-40"></i>
              <span class="span-padding-left10">{{'header.cv' | translate}}</span>
                        </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/pages">
            <div>
                <i class="bi bi-flag width-40"></i>
             <span class="span-padding-left10">{{'header.pages' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/groups">
            <div>
                <i class="bi bi-people width-40"></i>
             <span class="span-padding-left10">{{'header.groups' | translate}}</span>
            </div>
          </button>
        <button type="button" class="button-f button-sc" routerLink="/contacts">
            <div>
                <i class="bi bi-person width-40"></i>
              <span class="span-padding-left10">Contacts</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" [routerLink]="'../../' +userUrl + '/jobs'">
            <div>
              <i class="bi bi-list-task width-40"></i>
              <span class="span-padding-left10">{{'sidebar.jobs' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" [routerLink]="'../../' +userUrl + '/projects'">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-bar-chart-steps width-40" viewBox="0 0 16 16">
              <path
                d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0zM2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5v-1zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1z" />
            </svg>
              <span class="span-padding-left10">{{'sidebar.projects' | translate}}</span>

            </div>
          </button>
        <button type="button" class="button-f button-sc" (click)="navigateToPayment()">
            <div>
              <i class="bi bi-credit-card" ></i>
              <span class="span-padding-left10">{{'Payment'}}</span>

            </div>
          </button>            <a href="#">See More</a>
    </div>
    <div class="shortcut-links">
     <p>Your Shortcuts</p>
     <a href="#"><img src="../../assets/img/shortcut-1.png"> Web Developers</a>
     <a href="#"><img src="../../assets/img/shortcut-2.png"> Web Design Course</a>
     <a href="#"><img src="../../assets/img/shortcut-3.png"> Full Stack Development</a>
     <a href="#"><img src="../../assets/img/shortcut-4.png"> Website Experts</a>
     
 </div>
</div>