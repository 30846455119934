import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdsManager, ImageCards } from 'src/app/shared/interfaces/model';
import _ from "lodash"

@Component({
  selector: 'app-carousel-card-dialog',
  templateUrl: './carousel-card-dialog.component.html',
  styleUrls: ['./carousel-card-dialog.component.scss']
})
export class CarouselCardDialogComponent implements OnInit {

  public adsManager = new AdsManager();
  public callAction = [
    { id: 0, checked: false, description: "No button" },
    { id: 1, checked: false, description: "Call Now" },
    { id: 2, checked: false, description: "Listen now" },
    { id: 3, checked: false, description: "Contact us" },
    { id: 5, checked: false, description: "Request time" },
    { id: 6, checked: false, description: "Get quote" },
    { id: 7, checked: false, description: "Save" },
    { id: 8, checked: false, description: "See menu" },
    { id: 9, checked: false, description: "Order now" },
    { id: 10, checked: false, description: "Watch more" },
    { id: 11, checked: false, description: "Shop now" },
    { id: 12, checked: false, description: "Sign up" },
    { id: 13, checked: false, description: "Subscribe" },
    { id: 14, checked: false, description: "Send message" },
    { id: 15, checked: false, description: "Learn more" },
    { id: 16, checked: false, description: "Apply now" },
    { id: 17, checked: false, description: "Download" },
    { id: 18, checked: false, description: "Book now" }
  ]

  imageCard: ImageCards;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<CarouselCardDialogComponent>,) { }

  ngOnInit(): void {

    console.log(this.data, "ejjeje")
    this.imageCard = _.cloneDeep(this.data.adsManager.adsDetails.cards[this.data.i]);
  }


  changeAdsDetailsDescription(event) {
    const messageEle = document.getElementById('adsDetailsDescriptionCard');
    const counterEle = document.getElementById('counter1');
    const maxLength = messageEle.getAttribute('maxlength');
    counterEle.innerHTML = '0/' + maxLength;
    const target = event.target;
    const currentLength = target.value.length;
    counterEle.innerHTML = currentLength + '/' + maxLength;
  }

  adsDescription(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.description = event;
    }
    return this.adsManager.adsDetails.description = event;

  }

  changeText(event) {
    const messageEle = document.getElementById('headlineCard');
    const counterEle = document.getElementById('counter');
    const maxLength = messageEle.getAttribute('maxlength');
    counterEle.innerHTML = '0/' + maxLength;
    const target = event.target;
    const currentLength = target.value.length;
    counterEle.innerHTML = currentLength + '/' + maxLength;
  }

  changeDropButton(event) {
    const data = this.callAction.find(t => t.id.toString() === event);


    this.imageCard.selectedButton = data.id;

    return this.imageCard.buttonName = data.description;

  }
  adsUrl(event) {
    if (this.adsManager?.adsDetails == null) {
      this.adsManager.adsDetails = {};
      this.adsManager.adsDetails.url = event;
    }
    return this.adsManager.adsDetails.url = event;
  }


  saveData() {
    this.dialogRef.close({imageCard: this.imageCard, index: this.data.i});
  }
}
