import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { shareReplay, take } from "rxjs/operators";
import { CarrierSubCategory, EducationSubCategory, Page, SubCategory } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-education-name",
  templateUrl: "./education-name.component.html",
  styleUrls: ["./education-name.component.scss"]
})
export class EducationNameComponent implements OnInit {
  @Input() educationObj: EducationSubCategory;
  @Input() educationObject: boolean;
  @Input() careerObj: CarrierSubCategory;
  @Input() careerObject: boolean;
  @Input() companyURL: boolean;
  @Input() hometownPageID: string;
  @Input() hometown: boolean = false;
  @Input() educationCompanyURL: boolean;
  @Input() companyEducationURL: boolean;
  @Input() religion: boolean = false;
  @Input() religionPageID: string;
  @Input() relationshipUserID: string
  @Input() relationshipUser: boolean = false;
  @Input() isStatusReceive: boolean = false;
  @Input() userID: string;  
  @Input() familyMember: boolean = false;

  @Output() removeUserFromRelationship = new EventEmitter<boolean>();


  educationName = "";
  careerName = "";
  careerUrl = "";
  educationURL = "";
  careerPageUrl: Page;
  user: User;

  constructor(private pageService: PagesService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    if (this.educationObject) {
      if (!this.educationObj?.pageID) {
        this.educationName = this.educationObj.name;
      } else {
        this.pageService.getPage(this.educationObj?.pageID).pipe(shareReplay(1)).subscribe(res => {
          this.educationName = res.name;
          this.careerUrl = res.website;
          this.careerPageUrl = res;

        });
      }
    } else if (this.careerObject) {
      if (!this.careerObj?.pageID) {
        this.careerName = this.careerObj.name;
      } else {
        this.pageService.getPage(this.careerObj?.pageID).pipe(shareReplay(1)).subscribe(res => {
          this.careerName = res.name;
          this.careerUrl = res.website;
          this.careerPageUrl = res;
        });
      }
    } else if (this.educationCompanyURL) {
      this.pageService.getPage(this.educationObj?.pageID).pipe(shareReplay(1)).subscribe(res => {
        this.educationURL = res.website;
      });
    } else if (this.companyURL) {
      if (!!this.careerObj?.pageID) {
        this.pageService.getPage(this.careerObj?.pageID).pipe(shareReplay(1)).subscribe(res => {
          this.careerUrl = res.website;
        });
      }
      
    } else if (this.hometown) {
      if (!!this.hometownPageID) {
        this.pageService.getPage(this.hometownPageID).pipe(shareReplay(1)).subscribe(res => {
          this.careerName = res.name;
          this.careerUrl = res.website;
          this.careerPageUrl = res;
        });
      }
     
    }
    else if (this.religion) {
      if (!!this.religionPageID) {
        this.pageService.getPage(this.religionPageID).pipe(shareReplay(1)).subscribe(res => {
          this.careerName = res.name;
          this.careerUrl = res.website;
          this.careerPageUrl = res;
        });
      }
     
    } else if(this.relationshipUser) {
      if (!!this.relationshipUserID) {
        this.userService.getUserContactData(this.relationshipUserID).subscribe(res => {
          this.user = res;
        })
      }
    } else if (this.isStatusReceive) {
      if (!!this.userID) {
        this.userService.getUserContactData(this.userID).subscribe(res => {
          this.user = res;
        })
      }
    } else if (this.familyMember) {
      if (!!this.userID) {
        this.userService.getUserContactData(this.userID).subscribe(res => {
          this.user = res;
        })
      }
    }
  }


  removeUser() {
    this.removeUserFromRelationship.emit(true)
  }

  openPage() {
    localStorage.setItem("pageID", this.careerPageUrl._id)
    this.router.navigate(['', this.careerPageUrl.pageUrl]);
  }
  openNewTab(url) {
    if(url.startsWith("https://") || url.startsWith("http://")) {
       url;
    } else {
      url = "https://" + url;
    }

    window.open(url, "_blank");
  }
}
