<div class="container px-3 py-3">
  <div class="mat-form-field--inline" *ngIf="data.jobDescription">
    <label>{{ "career.description" | translate }} </label>
    <quill-editor name="editor" #editor="ngModel" [(ngModel)]="data.data.description" [modules]="modules">
    </quill-editor>
  </div>
  <div class="mat-form-field--inline" *ngIf="data.jobLanguage">
    <label>{{ "skills.language" | translate }} </label>
    <div class="line" *ngFor="let language of data.job.language; let i = index">
      <div class="row col-md-12">
        <div class="container d-flex justifty-content-center">
          <div class="row col-md-12">
            <div class="col-md-7">
              <div class="form-outline">
                <input [ngModel]="language.name | translate" class="form-control col-md-7" disabled>
              </div>
            </div>
            <div class="col-md-3">
              <app-star-rating stopPropagation [rating]="language.rate / 20" [starCount]="starCount"
              (ratingUpdated)="onRatingLanguageChanged(i, $event)" class="col-md-4">
            </app-star-rating>
            </div>
            <div class="col-md-1">
              <li class="fa fa-minus deleteIcon" (click)="deleteLanguage(i)"></li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr style=";">
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12 form-group">
          <select #selectedValue name="selectedValue" id="selectedValue" [(ngModel)]="empty"
            (ngModelChange)="addJobLanguage($event)" class="col-md-12 form-control" placeholder="d.ff">
            <option hidden value="" disabled selected>{{'chooseLanguage' | translate}}</option>
            <option *ngFor="let languages of languageService.languages" [ngValue]="languages"
              (change)="addJobLanguage(languages)" [selected]="languages">
              {{languages.description | translate}}
            </option>
          </select>
        </div>
      </div>
    </li>
  </div>
  <div class="container" *ngIf="data.jobQualifications">
    <div class="modal-header">
      <h3>{{'skills.qualifications' | translate}}</h3>
    </div>
    <div class="modal-body">
      <div class="line" *ngFor="let qualification of data.job?.qualifications; let i = index">
        <div class="row col-md-12">
          <div class="container d-flex justify-content-center">
            <div class="row col-md-12">
              <div class="col-md-7">
                <div class="form-outline">
                  <input [(ngModel)]="qualification.name" class="form-control  col-md-7">
                </div>
              </div>
              <div class="col-md-1">
                <li class="fa fa-minus deleteIcon" (click)="deleteJobQualifications(i)"></li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container d-flex justify-content-center col-md-12">
        <div class ="row col-md-12">
          <div class="col-md-9">
            <div class="form-outline">
              <input  autofocus [(ngModel)]="name" class="form-control form-control-sm col-md-10">
            </div>
          </div>
          <div class="col-md-1" style="right: 4.1rem;
          position: absolute;">
              <button class="addIcon" [disabled]="!name" (click)="addJobQualifications(event)"> <i class="bi bi-plus"></i></button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="data.jobDriveLicense">
    <div class="modal-header">
      <h3>{{'skills.driveLicense' | translate}}</h3>
    </div>
    <div class="modal-body">
      <div class="line row">
        <div class="col-md-12 specialty">
          <div class="card__body d-flex column">
            <div class="d-flex align-items-center card__body__list">
              <div *ngFor="let sp of data.job.driveLicense; let i=index" class="d-flex justify-content-between me-1">
                <div class="card__text">
                  {{sp.name}}
                  <span class="fa fa-remove" (click)="deleteJobDriveLicense(i)"></span>
                </div>
              </div>
              <input [(ngModel)]="driveLicense" class="form-control col-md-4">
              <button class="btn btn-light ms-1" [disabled]="!driveLicense" (click)="addJobDriveLicense()">
                <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                    class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></span></button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  *ngIf="data.jobPCKnowledge">
    <div class="modal-header">
      <h3>{{'skills.pcKnowledge' | translate}}</h3>
    </div>
    <div class="modal-body">
      <div class="line" *ngFor="let pcKnowledge of data.data.pcKnowledge; let i = index">
        <div class="row col-md-12">
          <div class="container d-flex justify-content-center">
            <div class="row col-md-12">
              <div class="col-md-7">
                <div class="form-outline">
                  <input [(ngModel)]="pcKnowledge.name" class="form-control col-md-7">
                </div>
              </div>
              <div class="col-md-3">
                <app-star-rating stopPropagation [rating]="pcKnowledge.rate" [starCount]="starCount"
                (ratingUpdated)="onRatingPCKnowledgeChanged(i, $event)" class="col-md-4">
              </app-star-rating>
              </div>
              <div class="col-md-1">
                <li class="fa fa-minus deleteIcon" (click)="deletePcKnowledge(i)"></li>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-flex justify-content-center col-md-12">
        <div class ="row col-md-12">
          <div class="col-md-9">
            <div class="form-outline">
              <input  autofocus [(ngModel)]="name" class="form-control form-control-sm col-md-10">
            </div>
          </div>
          <div class="col-md-1" style="right: 2.9rem;
          position: absolute;">
              <button class="addIcon" [disabled]="!name" (click)="addJobPCKnowledge()"> <i class="bi bi-plus"></i></button>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="data.jobSkillsOffer">
    <div class="modal-header">
      <h3>{{'job.secondGroup.tasks' | translate}}</h3>
    </div>
    <div class="modal-body">
      <div class="line" *ngFor="let task of data?.data?.skillsOffer; let i = index">
        <div class="row col-md-12">
          <div class="container d-flex justify-content center col-md-12">
            <div class="row col-md-12">
              <div class="col-md-8 px-0">
                <div class="form-outline">
                  <input [(ngModel)]="task.name" class="form-control col-md-7">
                </div>
              </div>
              <div class="col-md-3 px-0">
                <app-star-rating stopPropagation [rating]="task.rate" [starCount]="starCount"
                (ratingUpdated)="onRatingTasksChanged(i, $event)" class="col-md-4">
              </app-star-rating>
              </div>
              <div class="col-md-1 px-0">
                <li class="fa fa-minus deleteIcon" (click)="deleteJobTask(i)"></li>
              </div>
            </div>
          </div>

        </div>
      </div>
      <li class="line">
        <div class="row col-md-12">
          <div class="container d-flex justify-content-center px-0">
            <div class="row col-md-12">
              <div class="col-md-8 px-0">
                <div class="form-outline">
                  <input autofocus [(ngModel)]="name" class="form-control col-md-10">

                </div>
              </div>
              <div class="col-md-3">
                <button class="addIcon" [disabled]="!name" (click)="addSkillsOffer()"> <i class="bi bi-plus"></i></button>

              </div>
            </div>
          </div>

        </div>
      </li>
    </div>
  </div>
  <div class="container" *ngIf="data.jobTasks">
    <div class="modal-header">
      <h3>{{'job.secondGroup.tasks' | translate}}</h3>
    </div>
    <div class="modal-body">
      <div class="line" *ngFor="let task of data?.skillsOffer; let i = index">
        <div class="row col-md-12">
          <div class="container d-flex justify-content center col-md-12">
            <div class="row col-md-12">
              <div class="col-md-8 px-0">
                <div class="form-outline">
                  <input [(ngModel)]="task.name" class="form-control col-md-7">
                </div>
              </div>
              <div class="col-md-3 px-0">
                <app-star-rating stopPropagation [rating]="task.rate / 20" [starCount]="starCount"
                (ratingUpdated)="onRatingTasksChanged(i, $event)" class="col-md-4">
              </app-star-rating>
              </div>
              <div class="col-md-1 px-0">
                <li class="fa fa-minus deleteIcon" (click)="deleteJobTask(i)"></li>
              </div>
            </div>
          </div>

        </div>
      </div>
      <li class="line">
        <div class="row col-md-12">
          <div class="container d-flex justify-content center col-md-12 px-0">
            <div class="row col-md-12">
              <div class="col-md-10">
                <input autofocus [(ngModel)]="name" class="form-control col-md-10">
                </div>
                <div class="col-md-2">
                  <button class="addIcon" [disabled]="!name" (click)="addJobTasks()"> <i class="bi bi-plus"></i></button>

              </div>
            </div>
          </div>
        </div>
      </li>
    </div>
  </div>

  <div class="mat-form-field--inline" *ngIf="data.secondGroup">
    <div class="modal-body">
      <label>{{ "job.fourthPage.paymentForm" | translate }} </label>
      <select [(ngModel)]="data.job.incomes.s" class="form-control" required>
        <option *ngFor="let salary of salaryArray" [ngValue]="salary.id" [selected]="salary">
          {{ salary.description | translate }}
        </option>
      </select>

      <label> {{ "job.fourthPage.summe" | translate }} </label>
      <input name="salaryBudget" type="number" class="form-control" [(ngModel)]="data.job.incomes.budget" required />
      <br>
      <label>{{ "job.fourthPage.currency" | translate }} </label>
      <select [(ngModel)]="data.job.currency"  class="form-control" required (ngModelChange)="changeCurrency($event)">
        <option *ngFor="let country of model" [value]="country?.currency.Alphabeticcode" [selected]="selectedCountry?.ISO_2">
          {{ country?.currency.Symbol }} - ({{country?.currency.Alphabeticcode}} - {{ country?.currency.Name }}) - {{
          country?.LokalName }}
        </option>
      </select>
      <br>
      <label>{{ "job.fourthPage.duration.jobDuration" | translate }} </label>
      <div class="row flex">
        <mat-form-field appearance="fill"  style="width: 48%; padding-right: 1rem;">
        <mat-select id="jobDuration" [(ngModel)]="data.job.duration"
          required>
          <mat-option *ngFor="let duration of jobDurationArray" [value]="duration.id" [selected]="duration">
            {{ duration.description | translate }}
          </mat-option>
        </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"   *ngIf="data.job.duration === 'Unlimited'" style="width: 50%;">
          <mat-label>Start date</mat-label>
          <input matInput [(ngModel)]="data.job.startDate" [matDatepicker]="picker" [min]="minDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="fill"  style="width: 31%"  *ngIf="data.job.duration === 'Temporary'" style="width: 50%;">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input  [min]="minDate" [rangePicker]="picker">
          <input matStartDate  [(ngModel)]="startDateLimited" placeholder="Start date">
          <input matEndDate  [(ngModel)]="endDateLimited"  placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

    </div>
    </div>

  </div>
  <div class="mat-form-field--inline" *ngIf="data.firstGroup">
    <div class="modal-body">
      <mat-form-field appearance="fill" style="width: 98%;">

      <mat-label> {{ "newJobTitle" | translate }} </mat-label>
      <input matInput type="text" [(ngModel)]="data.job.positionTitle"
         />
      </mat-form-field>
      <br>
        <mat-form-field appearance="fill" style="width:48%">
          <mat-label>{{ "career.employmentType" | translate }} </mat-label>
          <mat-select id="jobDuration" [(ngModel)]="data.job.employmentType"
          required>
          <mat-option *ngFor="let duration of arraysService.employmentType" [value]="duration.id" [selected]="duration">
            {{ duration.description | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%; margin-left: 1rem">
          <mat-label>{{ "job.firstPage.workplace" | translate }} </mat-label>
          <mat-select  id="remote" [(ngModel)]="data.job.remote"
            required>
            <mat-option *ngFor="let remote of remoteArray" [value]="remote.id" [selected]="remote">
              {{ remote.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" style="width:98%">
          <mat-label> {{ "addressData.street" | translate }} </mat-label>
          <input matInput name="street" type="text" [(ngModel)]="data.job.street"  required />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%;">
          <mat-label> {{ "data.zip" | translate }} </mat-label>
          <input matInput name="zip" type="text" [(ngModel)]="data.job.postalCode"  required />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%; margin-left: 1rem;">
          <mat-label> {{ "data.city" | translate }} </mat-label>
          <input matInput name="city" type="text" [(ngModel)]="data.job.city"  required />
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%; ">
          <mat-label>{{ "data.country" | translate }} </mat-label>
          <mat-select [(ngModel)]="data.job.c" disabled required (ngModelChange)="changeCountry($event)">
            <mat-option *ngFor="let country of model" [value]="country.ISO_2" (change)="changeCountry($event)" [selected]="selectedCountry">
              {{ country.EN }} - ({{ country.LokalName }})
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%; margin-left: 1rem;">
          <mat-label> {{ "data.state" | translate }} </mat-label>
          <input matInput name="state" type="text" [(ngModel)]="data.job.state"  required />
        </mat-form-field>
      </div>

  </div>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-light" mat-button mat-dialog-close>
      {{ "cancel" | translate }}
    </button>
    <button type="submit" class="btn btn-success" (click)="saveData()" mat-button>
      {{ "save" | translate }}
    </button>
  </mat-dialog-actions>
</div>
