<div class="container">
  <form class="form-grid" name="#ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()">
    <h2>{{"categories.subCategories.name" | translate}}</h2>
    <li class="line form-row">
      <div class="col-md-12 pb-2">
        <select
        class="col-md-12 form-control"
        name="selectedPageSubCategory"
        #selectedPageSubCategory="ngModel"
        [(ngModel)]="data?.subCategory.name"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="addSubCategory($event)"
        [ngClass]="{ 'is-invalid': myForm.submitted && selectedPageSubCategory.invalid }"
        required=""
      >
      <option hidden value="" disabled selected>{{selectedSubCategory?.name | translate}}</option>
        <option
          *ngFor="let subCat of subCategories"
          [ngValue]="subCat.id"
        >
          {{ subCat.name | translate }}
        </option>
      </select>
      <div *ngIf="myForm.submitted && selectedPageSubCategory.invalid" class="invalid-feedback">
        <div *ngIf="selectedPageSubCategory.errors.required">{{'pagesInfo.dialogSubCategory.errorDropdownSubCategory' | translate}}</div>
      </div>
      </div>
    </li>
    <p></p>
    <li class="line form-row">
      <div class="col-md-12 pb-2 pt-2 specialty" id="sp">
        <label>{{"pagesInfo.keywords" | translate}}</label>
        <div class="card__body d-flex column">
          <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of data?.subCategory?.keywords; let i=index" class="d-flex justify-content-between pr-1">
                  <div class="card__text">
                    {{sp.name}}
                    <span class="fa fa-remove" (click)="removeKeyword(i)"></span>
                  </div>
                </div>
            <input
            class="form-control col-md-4"
            name="key"
            #key="ngModel"
            [(ngModel)]="keyword"
            [ngClass]="{ 'is-invalid': myForm.submitted && data?.subCategory?.keywords?.length < 1 }" [required]=" data?.subCategory?.keywords?.length < 1">
            <button type="button" class="btn btn-light ms-1" [disabled]="!keyword && data?.subCategory?.keywords?.length < 1  " (click)="addKeyword()">
              <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                  class="bi bi-plus" viewBox="0 0 16 16">
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg></span></button>
                <div class="invalid-feedback" *ngIf="myForm.submitted && data?.subCategory?.keywords?.length < 1">
                  <p *ngIf="data?.subCategory?.keywords?.length < 1"> {{'pagesInfo.dialogSubCategory.errorInputKeyword' | translate}}</p>
                </div>

          </div>
        </div>
      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary btn-small" mat-button mat-dialog-close [disabled]="disabledButton">
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success btn-small" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
