import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NotificationService } from "../services/notification.service";
import * as _ from "underscore";

@Injectable({
  providedIn: "root"
})
export class NotificationHelperService {

  constructor(
    private notificationService: NotificationService
  ) { }

  createNewNotificationRequest(notificationObject): Observable<Notification> {
    return new Observable(observer => {
      this.notificationService.createNotification(notificationObject).subscribe(() => {
        observer.next();
      })
    })
  }

  getAllNotification(userId):Observable<any> {
    return new Observable(observer => {
      this.notificationService.getAllNotification().subscribe(result => {
        const filterRequest = _.filter(result, (request) => {
          return (request.friendId === userId);
        })
        observer.next(filterRequest);
      })
    })

  }
  updateAllNotifications(id, t): Observable<any> {
    return new Observable(observer => {
      this.notificationService.updateAll(id, t).subscribe(result => {
        observer.next(result);
      })
    })
  }

}
