import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { environment } from 'src/assets/environments/environment';
import { Page, Project } from '../interfaces/model';
import { User } from '../interfaces/user.model';
import { AuthService } from './auth.service';
import { PagesService } from './pages.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  api = `${environment.backendXY}/project`;
  userID = this.authService.userID;
  loggedUser$: Observable<any>;
  loggedUser: any;

  private cachedProject$: Observable<any>;


  constructor(private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private pageService: PagesService) { }

  public createProject(project: Project, isUser: boolean, pageID?: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response' as 'body'
    };
    return this.http.post<any>(this.api, project, httpOptions).pipe(map((response: HttpResponse<any>) => {
      if (isUser) {
        this.loggedUser$ = this.userService.userList;
        this.loggedUser$.subscribe(res => {
          this.loggedUser = res;


          this.userService.getProjectList(res.user._id).subscribe(data => {

            const project = {
              projectID: response.body._id,
              timestamp: new Date(),
              c: response.body.c
            };
            data[0].projects.unshift(project);
            this.userService.updateProjectList(data[0]._id, data[0]);
          })
        })

      } else {
        this.pageService.getPage(pageID).pipe(shareReplay(1)).subscribe((res: any) => {

          this.pageService.getProjectList(res?.page._id).subscribe(data => {
            const job = {
              projectID: response.body._id,
              timestamp: new Date(),
              c: response.body.c
            };
            data[0].projects.unshift(job);
            this.pageService.updateProjectList(data[0]._id, data[0]);
          })

        })
      }

    }))
  }

  public getUserProjects(id): Observable<any> {
    return this.http.get(`${this.api}/userProjects/${id}`);
  }
  getProject(id): Observable<any> {
    return this.http.get(`${this.api}/${id}`);
  }

  getProjectTest(id): Observable<any> {
    if (!this.cachedProject$) {
      this.cachedProject$ = this.http.get<any>(`${this.api}/${id}`)
        .pipe(
          shareReplay(1)
        );
    }
    return this.cachedProject$;
  }


  public findProjects(id) {
    const api = `${environment.backendXY}/findProjects`;
    const params = new HttpParams().append('userID', id);
    return this.http.get(`${api}`, { params });
  }

  public findProjectsCompany(id) {
    const api = `${environment.backendXY}/findProjectsCompany`;
    const params = new HttpParams().append('pageID', id).append("userID", this.authService.userID);
    return this.http.get(`${api}`, { params });
  }

  public findProjectSearch(id) {
    const api = `${environment.backendXY}/findProjectSearch`;
    const params = new HttpParams().append('projectID', id);
    return this.http.get(`${api}`, { params });
  }

  public findProjectSearchPage(id) {
    const api = `${environment.backendXY}/findProjectSearchPage`;
    const params = new HttpParams().append('projectID', id);
    return this.http.get(`${api}`, { params });

  }

  public updateProject(id, project) {
    const updateProject = `${this.api}/${id}`
    this.http.put<any>(updateProject, project).pipe(take(1)).subscribe(t => t);

  }

  public  getProjectListPage(id): Observable<object> {
    return this.http.get(`${environment.backendXY}/projectListPage/${id}`);
  }

  public updateProjectList(jobListID, jobList) {
    const updateJobList = `${environment.backendXY}/projectListPage/${jobListID}`
    this.http.put<any>(updateJobList, jobList).pipe(take(1)).subscribe(t =>t);
  }

}
