import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataPrint, PrintDialogComponent } from 'src/app/components/modals/print-dialog/print-dialog.component';
import { Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { PagesService } from 'src/app/shared/services/pages.service';

@Component({
  selector: 'app-page-payments',
  templateUrl: './page-payments.component.html',
  styleUrls: ['./page-payments.component.scss']
})
export class PagePaymentsComponent implements OnInit {

  @Input() pageCompanyCode;
  @Input() pageName;
  @Input() pageDetails: Page;
  users: User[];
  pages: Page[];
  isPayment: boolean;
  isTransactions: boolean;

  constructor(public pageService: PagesService, private router: Router, public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.pageService.findCompaniesAndUsers(this.pageCompanyCode).subscribe(t => {
      this.users = t.users;
      this.pages = t.pages;
      this.isPayment = true;
      console.log(this.users, " " + "Users");
      console.log(this.pages, " " + "Pages")
    })
  }
  setPayment() {
    this.isPayment = true;
    this.isTransactions = false;
  }

  setTransaction() {
    this.isTransactions = true;
    this.isPayment = false;
  }
  goToPage(company) {
    this.pageService.changeMessage(company.id);
    this.router.navigateByUrl("/" + company.pageUrl);
  }
  goToUser(user) {
    this.router.navigateByUrl(user.userUrl + "/start");
  }

  goForPrint(data: DataPrint) {
    this.dialog.open(PrintDialogComponent, { data })
  }


}
