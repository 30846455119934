import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchPage"
})
export class SearchPagePipe implements PipeTransform {

  transform(value: any, searchValue): any {

    if (!searchValue) return value;

    // tslint:disable-next-line: max-line-length
    return value.filter((v) => v.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)

  }

}
