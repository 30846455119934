import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LanguagesService {
  public primaryLanguages = [];

  constructor() {

  }

  public getPrimaryLanguages(){
    this.primaryLanguages = [

          {version:"", id:"az", lang:"az", description: "Azərbaycan dili", region:"easternEurope"},
          {version:"", id:"hu", lang:"hu", description: "Magyar", region:"easternEurope"},
          {version:"", id:"pl", lang:"pl", description: "Polski", region:"easternEurope"},
          {version:"", id:"ro", lang:"ro", description: "Română", region:"easternEurope"},
          {version:"", id:"sk", lang:"sk", description: "Slovenčina", region:"easternEurope"},
          {version:"", id:"sl", lang:"sl", description: "Slovenščina", region:"easternEurope"},
          {version:"", id:"cs", lang:"cs", description: "Čeština", region:"easternEurope"},
          {version:"", id:"sz", lang:"sz", description: "ślōnskŏ gŏdka", region:"easternEurope"},
          {version:"", id:"be", lang:"be", description: "Беларуская", region:"easternEurope"},
          {version:"", id:"bg", lang:"bg", description: "Български", region:"easternEurope"},
          {version:"", id:"ru", lang:"ru", description: "Русский", region:"easternEurope"},
          {version:"", id:"tt", lang:"tt", description: "Татарча", region:"easternEurope"},
          {version:"", id:"uk", lang:"uk", description: "Українська", region:"easternEurope"},
          {version:"", id:"kk", lang:"kk", description: "Қазақша", region:"easternEurope"},
          {version:"", id:"hy", lang:"hy", description: "Հայերեն", region:"easternEurope"},
          {version:"", id:"my", lang:"my", description: "ქართული", region:"easternEurope"},
          {version:"", id:"bs", lang:"bs", description: "Bosanski", region:"westernEurope"},
          {version:"", id:"br", lang:"br", description: "Brezhoneg", region:"westernEurope"},
          {version:"", id:"ca", lang:"ca", description: "Català", region:"westernEurope"},
          {version:"", id:"co", lang:"co", description: "Corsu", region:"westernEurope"},
          {version:"", id:"cy", lang:"cy", description: "Cymraeg", region:"westernEurope"},
          {version:"", id:"da", lang:"da", description: "Dansk", region:"westernEurope"},
          {version:"0.0.2", id:"de", lang:"de", description: "Deutsch", region:"westernEurope"},
          {version:"", id:"et", lang:"et", description: "Eesti", region:"westernEurope"},
          {version:"0.0.2", id:"en", lang:"en", description: "English (UK)", region:"westernEurope"},
          {version:"", id:"es", lang:"es", description: "Español (España)", region:"westernEurope"},
          {version:"", id:"eu", lang:"eu", description: "Euskara", region:"westernEurope"},
          {version:"", id:"fr", lang:"fr", description: "Français (France)", region:"westernEurope"},
          {version:"", id:"fy", lang:"fy", description: "Frysk", region:"westernEurope"},
          {version:"", id:"fo", lang:"fo", description: "Føroyskt", region:"westernEurope"},
          {version:"", id:"ga", lang:"ga", description: "Gaeilge", region:"westernEurope"},
          {version:"", id:"gl", lang:"gl", description: "Galego", region:"westernEurope"},
          {version:"", id:"hr", lang:"hr", description: "Hrvatski", region:"westernEurope"},
          {version:"", id:"it", lang:"it", description: "Italiano", region:"westernEurope"},
          {version:"", id:"lv", lang:"lv", description: "Latviešu", region:"westernEurope"},
          {version:"", id:"lt", lang:"lt", description: "Lietuvių", region:"westernEurope"},
          {version:"", id:"mt", lang:"mt", description: "Malti", region:"westernEurope"},
          {version:"", id:"nl", lang:"nl", description: "Nederlands", region:"westernEurope"},
          {version:"", id:"nl", lang:"nl", description: "Nederlands (België)", region:"westernEurope"},
          {version:"", id:"nb", lang:"nb", description: "Norsk (bokmål)", region:"westernEurope"},
          {version:"", id:"nn", lang:"nn", description: "Norsk (nynorsk)", region:"westernEurope"},
          {version:"", id:"pt", lang:"pt", description: "Português (Portugal)", region:"westernEurope"},
          {version:"", id:"sc", lang:"sv", description: "Sardu", region:"westernEurope"},
          {version:"0.0.1", id:"al", lang:"sq", description: "Shqip", region:"westernEurope"},
          {version:"", id:"fi", lang:"fi", description: "Suomi", region:"westernEurope"},
          {version:"", id:"sv", lang:"sv", description: "Svenska", region:"westernEurope"},
          {version:"", id:"is", lang:"is", description: "Íslenska", region:"westernEurope"},
          {version:"", id:"el", lang:"el", description: "Ελληνικά", region:"westernEurope"},
          {version:"", id:"mk", lang:"mk", description: "Македонски", region:"westernEurope"},
          {version:"", id:"sr", lang:"sr", description: "Српски", region:"westernEurope"},
          {version:"", id:"so", lang:"so", description: "Af-Soomaali", region:"africa"},
          {version:"", id:"af", lang:"af", description: "Afrikaans", region:"africa"},
          {version:"", id:"ff", lang:"ff", description: "Fula", region:"africa"},
          {version:"", id:"ha", lang:"ha", description: "Hausa", region:"africa"},
          {version:"", id:"rw", lang:"rw", description: "Ikinyarwanda", region:"africa"},
          {version:"", id:"sw", lang:"sw", description: "Kiswahili", region:"africa"},
          {version:"", id:"ku", lang:"ku", description: "Kurdî (Kurmancî)", region:"africa"},
          {version:"", id:"mg", lang:"mg", description: "Malagasy", region:"africa"},
          {version:"", id:"sn", lang:"sn", description: "Shona", region:"africa"},
          {version:"", id:"tr", lang:"tr", description: "Türkçe", region:"africa"},
          {version:"", id:"zz", lang:"zz", description: "Zaza", region:"africa"},
          {version:"", id:"he", lang:"he", description: "עברית", region:"africa"},
          {version:"", id:"ar", lang:"ar", description: "العربية", region:"africa"},
          {version:"", id:"fa", lang:"fa", description: "فارسی", region:"africa"},
          {version:"", id:"ps", lang:"ps", description: "پښتو", region:"africa"},
          {version:"", id:"cb", lang:"cb", description: "کوردیی ناوەندی", region:"africa"},
          {version:"", id:"sy", lang:"sy", description: "ܣܘܪܝܝܐ", region:"africa"},
          {version:"", id:"am", lang:"am", description: "አማርኛ", region:"africa"},
          {version:"", id:"tz", lang:"tz", description: "ⵜⴰⵎⴰⵣⵉⵖⵜ", region:"africa"},
          {version:"", id:"en_us", lang:"en", description: "English (US)",region:"america"},
          {version:"", id:"es", lang:"es", description: "Español",region:"america"},
          {version:"", id:"fr", lang:"fr", description: "Français (Canada)",region:"america"},
          {version:"", id:"gn", lang:"gn", description: "Guarani",region:"america"},
          {version:"", id:"ik", lang:"ik", description: "Iñupiatun",region:"america"},
          {version:"", id:"ht", lang:"ht", description: "Kreyòl Ayisyen",region:"america"},
          {version:"", id:"pt", lang:"pt", description: "Português (Brasil)",region:"america"},
          {version:"", id:"id", lang:"id", description: "Bahasa Indonesia",region:"asia"},
          {version:"", id:"ms", lang:"ms", description: "Bahasa Melayu",region:"asia"},
          {version:"", id:"jv", lang:"jv", description: "Basa Jawa",region:"asia"},
          {version:"", id:"bi", lang:"ib", description: "Bisaya",region:"asia"},
          {version:"", id:"tl", lang:"tl", description: "Filipino",region:"asia"},
          {version:"", id:"uz", lang:"uz", description: "O'zbek",region:"asia"},
          {version:"", id:"vi", lang:"vi", description: "Tiếng Việt",region:"asia"},
          {version:"", id:"mn", lang:"mn", description: "Монгол",region:"asia"},
          {version:"", id:"tt", lang:"tt", description: "Татарча",region:"asia"},
          {version:"", id:"tg", lang:"tg", description: "Тоҷикӣ",region:"asia"},
          {version:"", id:"ky", lang:"ky", description: "кыргызча",region:"asia"},
          {version:"", id:"kk", lang:"kk", description: "Қазақша",region:"asia"},
          {version:"", id:"hy", lang:"hy", description: "Հայերեն",region:"asia"},
          {version:"", id:"hy", lang:"hy", description: "Հայերեն",region:"asia"},
          {version:"", id:"ur", lang:"ur", description: "اردو",region:"asia"},
          {version:"", id:"ps", lang:"ps", description: "پښتو",region:"asia"},
          {version:"", id:"ne", lang:"ne", description: "नेपाली",region:"asia"},
          {version:"", id:"mr", lang:"mr", description: "मराठी",region:"asia"},
          {version:"", id:"hi", lang:"hi", description: "हिन्दी",region:"asia"},
          {version:"", id:"as", lang:"as", description: "অসমীয়া",region:"asia"},
          {version:"", id:"bn", lang:"bn", description: "বাংলা",region:"asia"},
          {version:"", id:"pa", lang:"pa", description: "ਪੰਜਾਬੀ",region:"asia"},
          {version:"", id:"gu", lang:"gu", description: "ગુજરાતી",region:"asia"},
          {version:"", id:"or", lang:"or", description: "ଓଡ଼ିଆ",region:"asia"},
          {version:"", id:"ta", lang:"ta", description: "தமிழ்",region:"asia"},
          {version:"", id:"te", lang:"te", description: "తెలుగు",region:"asia"},
          {version:"", id:"kn", lang:"kn", description: "ಕನ್ನಡ",region:"asia"},
          {version:"", id:"ml", lang:"ml", description: "മലയാളം",region:"asia"},
          {version:"", id:"si", lang:"si", description: "සිංහල",region:"asia"},
          {version:"", id:"th", lang:"th", description: "ภาษาไทย",region:"asia"},
          {version:"", id:"lo", lang:"lo", description: "ພາສາລາວ",region:"asia"},
          {version:"", id:"my", lang:"my", description: "မြန်မာဘာသာ",region:"asia"},
          {version:"", id:"ka", lang:"ka", description: "ქართული",region:"asia"},
          {version:"", id:"km", lang:"km", description: "ქართული",region:"asia"},
          {version:"", id:"zh-Hant", lang:"zh-Hant", description: "中文(台灣)",region:"asia"},
          {version:"", id:"zh-Hans", lang:"zh-Hans", description: "中文(简体)",region:"asia"},
          {version:"", id:"zh-Hant2", lang:"zh-Hant", description: "中文(香港)",region:"asia"},
          {version:"", id:"ja", lang:"ja", description: "日本語",region:"asia"},
          {version:"", id:"ja-ks", lang:"ja", description: "日本語(関西)",tooltip: "Japanese (Kansai)", region:"asia"},
          {version:"", id:"ko", lang:"ko", description: "한국어",tooltip: "Korean", region:"asia"},

      ]
  }

}
