<div class="container">
  <div class="body-container">
    <h1>New SubCategory</h1>
    <div class="content">
      <div class="array" *ngFor="let data of personalData; let i = index">
        <ul>
          <button [disabled]="disableButton[i]" (click)="addPersonalData(data)"  type="button"><i class="fa-2x" [class]="data.icon"></i></button>
        </ul>
        <h3> {{data.description | translate}}</h3>
      </div>
    </div>
  </div>
</div>

