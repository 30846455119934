import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FriendService } from "../services/friend.service";
import * as _ from "underscore";
import { AuthService } from "../services/auth.service";
import { NotificationHelperService } from "./notification-helper.service";
import { Status } from "../enums/enums-model";
import { UserService } from "../services/user.service";
import { NavigationEnd, Router } from "@angular/router";
import { SenderService } from "../services/sender.service";
import { shareReplay, take } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class UserHelperService {
  public userUrl = "";

  constructor(
    private friendService: FriendService,
    public authService: AuthService,
    public notificationHelper: NotificationHelperService,
    public userService: UserService,
    public route: Router,
    public sendData: SenderService,
  ) {

    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        event.url = event.url.replace("/start", "");
        event.url = event.url.replace("/data/", "");
        event.url = event.url.replace("/contacts", "");
        event.url = event.url.replace("/followers", "");
        event.url = event.url.replace("/following", "");
        event.url = event.url.replace("/", "");
        event.url = event.url.replace("/settings/account", "");
        event.url = event.url.replace("/settings/notification", "");
        this.userUrl = event.url;
      }

  });
   }

  createNewFriendRequest(friendReqObject): Observable<any> {
    return new Observable(observer => {
      this.friendService.createRequest(friendReqObject).subscribe(() => {
        observer.next();
      });
      const notification = {
          description: "wants to be your friend",
          userId: friendReqObject.userId,
          contactId: friendReqObject.contactId,
          read: false,
          seen: false
      }
      this.notificationHelper.createNewNotificationRequest(notification).subscribe(() => {
        observer.next();
      });
    });
  }
  loadRequestingFriends(userId): Observable<any> {
    return new Observable(observer => {
      let friendsArray = [];
      this.friendService.getAllFriendRequests().subscribe(friendRequests => {
        let filteredRequests = _.filter(friendRequests, (request) => {
          return (request.userId === userId || request.contactId === userId)
        });

        filteredRequests.forEach(oneRequest => {
          if ((oneRequest.userId === userId || oneRequest.contactId === userId) && oneRequest.status === Status.Accepted) {
            oneRequest.uniqueId = oneRequest.userId === userId ? oneRequest.contactId : oneRequest.userId;
            oneRequest.isFriend = true;
            friendsArray.unshift(oneRequest);
          } else if (oneRequest.contactId === userId && oneRequest.status === Status.Pending) {
            oneRequest.uniqueId = oneRequest.userId;
            oneRequest.isRequest = true;
            friendsArray.unshift(oneRequest);
          }
        });

        if (friendsArray.length === 0) {
          observer.next(friendsArray);
          return;
        }
        this.prepareRequestingFriends(friendsArray).subscribe(result => {
          observer.next(result);
        });

      });
    });
  }

  showYourFriends(authId): Observable<any> {
    return new Observable<any>(observer => {
      this.friendService.findAllYourFriends({userId: authId}).subscribe(response => {
        observer.next(response);
      })
    })
  }
  showPendingFriends(authId): Observable<any> {
    return new Observable<any>(observer => {
      this.userService.getUserContact({friendId: authId}).subscribe(response => {
        observer.next(response);
      })
    })
  }

  prepareRequestingFriends(filteredRequests): Observable<any> {
    return new Observable(observer => {
      filteredRequests.forEach(element => {
        this.userService.getUserContactData(element.uniqueId).subscribe(friendDetails => {
          element.firstName = friendDetails.firstName;
          element.lastName = friendDetails.lastName;
          observer.next(filteredRequests);
        });
      });
    });
  }
  updateFriendRequest(friendReqObject): Observable<any> {
    return new Observable(observer => {
      this.friendService.updateFriendRequest(friendReqObject).subscribe(() => {
        observer.next();
      });
    });
  }

  getUserData(): Observable<any> {
    return new Observable(observer => {
      console.log("always here")
      this.userService.getUserProfile(this.userUrl).pipe(take(1)).subscribe(res => {
        observer.next(res);
        this.sendData.sendUserData(res)
      })
    })
  }
  getUserFollowing(): Observable<any> {
    return new Observable(observer => {
      this.userService.getUserFollowing(this.userUrl).pipe(shareReplay(1)).subscribe(res => {
        observer.next(res);
      })
    })
  }
  getUserFollowers(): Observable<any> {
    return new Observable(observer => {
      this.userService.getUserFollowers(this.userUrl).pipe(shareReplay(1)).subscribe(res => {
        observer.next(res);
      })
    })
  }

  getUserContacts(): Observable<any> {
    return new Observable(observer => {
      this.userService.getUserContacts(this.userUrl).pipe(shareReplay(1)).subscribe(res => {
        observer.next(res);
      })
    })
  }

  getUserCV():Observable<any> {
    return new Observable(observer => {
      this.userService.getUserCV(this.userUrl).subscribe(res => {
        observer.next(res);
      })
    })
  }


  deleteFriend(id): Observable<any> {
    return new Observable(observer => {
      this.friendService.deleteFriend(id).subscribe(() => {
        observer.next();
      })
    })
  }


}
