import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Job } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-ignored-jobs',
  templateUrl: './ignored-jobs.component.html',
  styleUrls: ['./ignored-jobs.component.scss']
})
export class IgnoredJobsComponent implements OnInit, OnDestroy {
  public user: User;
  public ignoredJobs = [];
  public subscription: Subscription;
  constructor( public userService: UserService, private authService: AuthService,  public router: Router) { }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.user = res;

    });
    /*  let test =  this.user.ignoredJobs.find(t => t.jobId);
      if (test) {
        this.jobService.getItem(test.jobId).subscribe((t: Job) => {
          if (Object.keys(t.public).length < 1) {
            const t2 = this.user.ignoredJobs.findIndex(t => t.jobId)
            this.user.ignoredJobs.splice(t2, 1);
            this.userService.updateUser(this.user._id, this.user);
          }
        })
      }
    }); */
  }

  test(i) {
    console.log(this.user, "users ")
    this.user.ignoredJobs.splice(i, 1);
    this.userService.updateUser(this.user._id, this.user);
  }

  createJob() {
    this.router.navigateByUrl(this.user?.userUrl+ "/jobs/create");
}
goToMyJobs() {
    this.router.navigateByUrl(this.user?.userUrl + "/jobs");
}

navigateToSaved() {
  this.router.navigateByUrl(this.user?.userUrl+ "/jobs/saved");

}

ignoreArray() {
  this.userService.getUserContactData(this.authService.userID).subscribe(res => {
    this.user = res;

  });

}
}
