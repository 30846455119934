  <div class="card">
    <!-- Card header START -->
    <div class="card-header border-0 pb-0">
      <h5 class="card-title">{{ "userProfile.about" | translate }}</h5>
      <div class="card mb-4 border-0">
        <div class="rounded border px-3 py-2 mb-3">
          <div class="d-flex align-items-center justify-content-between"></div>
          <p [innerHTML]="userData?.userUser?.about"></p>
        </div>
      </div>
    </div>
    <!-- Card header END -->
    <!-- Card body START -->

    <div class="row gx-0">
      <!-- Sidenav START -->
      <div class="col-lg-4">
        <!-- Advanced filter responsive toggler START -->
        <!-- Divider -->
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{
              "userProfile.about" | translate
              }}</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body px-1 py-1">
                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab">
                        <i class="bi bi-eye me-2"></i><span>{{ "userProfile.overview" | translate }}
                        </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-2"  data-bs-toggle="tab">
                        <i class="bi bi-journal-bookmark-fill me-2"></i><span>{{ "category.Career" | translate
                          }} &
                          {{ "category.Education" | translate }}
                        </span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-7" data-bs-toggle="tab">
                        <i class="bi bi-gear-wide-connected me-2"></i><span>{{ "category.Skills" | translate }}
                        </span></a>
                    </li>

                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab">
                        <i class="bi bi-pin-map me-2"></i><span>{{
                          "userProfile.placesLived" | translate
                          }}</span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-4" data-bs-toggle="tab">
                        <i class="bi bi-person-vcard me-2"></i><span>{{
                          "userProfile.contactAndBasic" | translate
                          }}
                        </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab">
                        <i class="bi bi-person-hearts me-2"></i><span>Family and relationships </span></a>
                    </li>
                  </ul>
                  <!-- Side Nav END -->
                </div>
                <!-- Card body END -->
                <!-- Card footer -->
              </div>
              <!-- Card END -->
            </div>
          </div>
        </nav>
      </div>
      <!-- Sidenav END -->

      <!-- Main content START -->
      <div class="col-lg-5 vstack gap-4">
        <!-- Setting Tab content START -->
        <div class="tab-content py-0 mb-0">
          <router-outlet></router-outlet>
          <!-- Account setting tab START -->
          <div class="tab-pane show active fade" id="nav-setting-tab-1">
            <!-- Account settings START -->
            <div class="card mb-4">
              <!-- Card body START -->
              <div class="card-body">
                <!-- Form settings START -->
                <form class="row g-3">
                  <!-- First name -->

                  <!-- Card header START -->
                  <!-- Card body START -->

                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center px-0 py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-buildings"></i>
                        <strong>
                          {{ branchTitle | translate }} -
                          {{ subCategoryTitle | translate }}
                        </strong>
                      </p>
                      <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                        <!-- Card share action menu -->
                        <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots"></i>
                        </a>
                        <!-- Card share action dropdown menu -->
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                          <li>
                            <a class="dropdown-item" [routerLink]="'../cv/branch'">
                              <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- Birthday END -->

                    <!-- Delete END -->
                  </div>

                  <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                    <!-- Workplace on START -->
                    <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                          addOffer({
                            edit: true,
                        offer: userData.cv.data[0]?.skills[0]
                                .skillsOffer.data,
                        model: model,
                        indexOfLang: 0
                          })
                        ">
                      <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                    <!-- Workplace on END -->
                  </div>
                  <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                    <p class="mb-0">
                      <i class="bi bi-tags"></i>
                    </p>
                    <div class="col-sm-9 d-flex flex-wrap ">
                      <ng-container *ngFor="
                          let keyword of userData?.user?.searchFilterSkills?.searchSkillsOffer?.data
                        ">
                        <p class="card__text me-1 mb-1">
                          {{ keyword?.name }}
                        </p>
                      </ng-container>
                    </div>
                    <div class="d-flex align-items-start ms-auto">
                      <app-privacy-button *ngIf="!!checkLoginID"
                        [privacy]="userData?.user?.searchFilterSkills?.searchSkillsOffer?.p"
                        (saveObject)="updatePlaces($event)"></app-privacy-button>



                      <div class="dropdown col-sm-1" *ngIf="!!checkLoginID">
                        <!-- Card share action menu -->
                        <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots"></i>
                        </a>
                        <!-- Card share action dropdown menu -->
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                          <li>
                            <a class="dropdown-item" (click)="
                               editOffer({
                              edit: true,
                              offer:
                                userData.cv.data[0]?.skills[0]
                                  ?.skillsOffer,
                              model:
                              userData.cv.data[0]?.skills[0]
                                  ?.skillsOffer,
                              indexOfLang: 0
                            })
                              ">
                              <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                          </li>
                          <li>
                            <a class="dropdown-item" (click)="
                               editOffer({
                              edit: true,
                              offer:
                              userData.cv.data[0]?.skills[0]
                                  ?.skillsOffer,
                              model:
                              userData.cv.data[0]?.skills[0]
                                  ?.skillsOffer,
                              indexOfLang: 0
                            })
                              ">
                              <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- Delete END -->


                  <div class="col-sm-12">
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <div class="mb-0 d-flex">
                        <i class="bi bi-briefcase"></i>
                        <div class="ms-1">
                          <strong>
                            {{getActualWorkingCompany()?.role}} <ng-container
                              *ngIf="getActualWorkingCompany()?.pageID">{{ "at" | translate }} </ng-container>
                            <app-education-name [careerObj]="getActualWorkingCompany()" [careerObject]="true">
                            </app-education-name>

                          </strong>
                          <br>
                          <div class="d-flex align-items-center">
                            <p class="mb-0 small me-2">
                              {{
                              getActualWorkingCompany()?.startDate | date : "MM.yyyy"
                              }}
                            </p>
                            -

                            <p class="mb-0 small ms-2" *ngIf="getActualWorkingCompany()?.today">
                              {{ "present" | translate }}
                            </p>
                            <p class="mb-0 small ms-2" *ngIf="!getActualWorkingCompany()?.today">
                              {{
                              getActualWorkingCompany()?.endDate | date : "MM.yyyy"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center ms-auto">
                        <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.cv.data[0]?.careers
                        ?.subCategories[0]?.p" (saveObject)="updateCareer($event)"></app-privacy-button>

                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" (click)="
                                  editCareer({
                                    edit: true,
                                    career: careerObj,
                                    model: userData.cv.data[0],
                                    index: i,
                                    indexOfLang: 0
                                  })
                                ">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a *ngIf="
                                  userData.cv.data[0].careers.subCategories
                                    .length > 1
                                " class="dropdown-item" (click)="deleteCareerSubCategory(i)">
                                <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12" *ngIf="!!userData.userEmea?.places?.current?.pageID">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <div class="mb-0 d-flex">
                        <i class="bi bi-house-door"></i>
                        <div class="ms-1">
                          <strong>
                            <app-education-name [hometown]="true" [hometownPageID]="
                              userData?.userEmea?.places?.current?.pageID
                            ">
                            </app-education-name>
                            <p *ngIf="userData?.userEmea?.places?.current?.name">
                              {{ userData?.userEmea.places?.current?.name }}
                            </p>
                          </strong>
                          <p class="small mb-0">
                            {{ "Current city" }}
                          </p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center ms-auto">
                        <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="userData?.userEmea.places?.current.p"
                          (saveObject)="updatePlaces($event)"></app-privacy-button>


                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="deletePageCurrent()">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <div class="col-sm-12" *ngIf="!!user?.places?.current?.name">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <div class="mb-0 d-flex">
                        <ul class="navbar-nav navbar-nav-scrol">
                          <li class="nav-item me-2">
                            <a class="nav-link icon-md btn btn-light p-0">
                              <i class="bi bi-geo-alt fs-6"></i>
                            </a>
                          </li>
                        </ul>
                        <div>
                          <strong>
                            <p>{{ user?.places?.current?.name }}</p>
                          </strong>
                          <p class="small mb-0">
                            {{ "Current city" }}
                          </p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center ms-auto">
                        <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="userData?.userEmea.places?.current.p"
                          (saveObject)="updatePlaces($event)"></app-privacy-button>
                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="deleteNameCurrent()">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.pageID">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <div class="mb-0 d-flex">
                        <i class="bi bi-geo-alt"></i>
                        <div>
                          <strong><app-education-name [hometown]="true" [hometownPageID]="
                              user?.places?.hometown?.pageID
                            "></app-education-name>
                          </strong>
                          <p class="small mb-0">{{ "Hometown" }}</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center ms-auto">


                        <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="userData?.userEmea.places?.hometown.p"
                          (saveObject)="updateHometown($event)"></app-privacy-button>


                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="deletePage()">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <div class="col-sm-12" *ngIf="!!user?.places?.hometown?.name">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <div class="mb-0 d-flex">
                        <ul class="navbar-nav navbar-nav-scrol">
                          <li class="nav-item me-2">
                            <a class="nav-link icon-md btn btn-light p-0">
                              <i class="bi bi-geo-alt fs-6"></i>
                            </a>
                          </li>
                        </ul>
                        <div>
                          <strong>{{
                            user?.places?.hometown.name
                            }}</strong>
                          <p class="small mb-0">{{ "Hometown" }}</p>
                        </div>
                      </div>
                      <div class="d-flex align-items-center ms-auto">


                        <app-privacy-button *ngIf="!!checkLoginID"
                          [privacy]="userData?.userEmea.places?.hometown.p"
                          (saveObject)="updateHometown($event)"></app-privacy-button>


                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" (click)="deleteNameOfHomeTown()">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <ng-container *ngIf="!!user?.relationship?.status">
                    <ng-container *ngIf="
                        isEditingRelationship;
                        else readOnlyRelationship
                      ">

                    </ng-container>
                    <ng-template #readOnlyRelationship>
                      <div class="col-sm-12">
                        <div class="d-flex align-items-center py-2" *ngIf="
                            !user?.relationship?.user?.status &&
                            !!user?.relationship?.status
                          ">
                          <!-- Date -->
                          <p class="mb-0">
                            <i class="bi bi-heart"></i>
                            <strong>
                              {{
                              relationshipStatus?.description
                              | translate
                              }}
                              <ng-container *ngIf="!!userData.user.relationship?.user?.id">
                                <app-education-name [isStatusReceive]="true" [userID]="
                                    userData.user.relationship?.user?.id
                                  "></app-education-name>
                              </ng-container>
                            </strong>
                          </p>
                          <div class="d-flex align-items-center ms-auto">
                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="userData.user?.relationship?.p"
                              (saveObject)="updateRelationship($event)"></app-privacy-button>


                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="editRelationship()">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteRelationship()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- Birthday START -->
                        <div class="d-flex align-items-center px-0 py-2" *ngIf="
                            user?.relationship?.user?.status === 2
                          ">
                          <!-- Date -->
                          <p class="mb-0">
                            <i class="bi bi-heart-fill"></i>
                            <strong>
                              {{
                              relationshipStatus?.description
                              | translate
                              }}
                              <app-education-name [isStatusReceive]="true"
                                [userID]="user.relationship?.user?.id"></app-education-name>
                            </strong>
                          </p>
                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.user?.relationship?.p"
                            (saveObject)="updateRelationship($event)"></app-privacy-button>

                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="editRelationship()">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="deleteRelationship()">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="d-flex align-items-center py-2" *ngIf="
                            user?.relationship?.user?.status === 1
                          ">
                          <!-- Date -->
                          <p class="mb-0">
                            <i class="bi bi-heart-fill"></i>
                            <strong>
                              {{
                              relationshipStatus?.description
                              | translate
                              }}

                              <app-education-name [isStatusReceive]="true"
                                [userID]="user.relationship?.user?.id"></app-education-name></strong>
                          </p>
                          <div class="d-flex align-items-center ms-auto">
                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="userData.user?.relationship?.p"
                              (saveObject)="updateRelationship($event)"></app-privacy-button>

                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="editRelationship()">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteRelationship()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>


                        </div>
                        <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative" *ngIf="
                            user?.relationship?.user?.status === 0
                          ">
                          <!-- Avatar -->
                          <div class="avatar text-center">
                            <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                          </div>
                          <!-- Info -->
                          <div class="mx-sm-3 my-2 my-sm-0">
                            <p class="small mb-2">
                              <b><app-education-name [isStatusReceive]="true" [userID]="
                                    user.relationship?.user?.id
                                  "></app-education-name></b>
                              added you to
                              {{
                              relationshipStatus?.description
                              | translate
                              }}
                            </p>
                            {{ "status 0" }}
                            <!-- Button -->
                            <div class="d-flex">
                              <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptRelationship()">
                                Accept
                              </button>
                              <button class="btn btn-sm py-1 btn-danger-soft">
                                Delete
                              </button>
                            </div>
                          </div>
                          <!-- Action -->
                          <div class="d-flex ms-auto">
                            <p class="small me-5 text-nowrap">
                              Just now
                            </p>

                            <div class="d-flex align-items-center ms-auto">
                              <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="userData.user?.relationship?.p"
                                (saveObject)="updateRelationship($event)"></app-privacy-button>

                              <!-- Notification action START -->
                              <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                                <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                                  id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <!-- Notification action END -->
                          </div>
                        </div>
                        <!-- Birthday END -->
                      </div>
                    </ng-template>
                  </ng-container>
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-telephone"></i> Phone:
                        <strong>
                          {{ userData.user?.phone[0]?.internationalNumber }}
                        </strong>
                      </p>
                      <div class="d-flex align-items-center ms-auto">
                        <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.user?.phone[0]?.p"
                          (saveObject)="updatePhone($event)"></app-privacy-button>
                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>

                    </div>
                    <!-- Birthday END -->
                  </div>
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-envelope"></i>
                        {{ "data.email" | translate }}
                        <strong>{{ userData.user?.email[0].name }} </strong>
                      </p>

                      <div class="ms-auto d-flex align-items-center">
                        <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.user?.email[0]?.p"
                          (saveObject)="updateEmail($event)"></app-privacy-button>
                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" [routerLink]="'../user_settings'">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>

                          </ul>
                        </div>
                      </div>

                    </div>
                    <!-- Birthday END -->
                  </div>
                </form>
                <!-- Settings END -->
              </div>
              <!-- Card body END -->
            </div>
            <!-- Account settings END -->

            <!-- Card END -->
          </div>
          <!-- Account setting tab END -->

          <!-- Notification tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-2">
            <!-- Notification START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "category.Career" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      addWork({
                        edit: false,
                        model: userData.cv.data[0],
                        career: false,
                        user: userData.user,
                        indexOfLang: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddCareer" | translate }}</a>
                  <!-- Workplace on END -->
                </div>

                <!-- Form settings START -->
                <form class="row g-3">
                  <!-- First name -->
                  <ng-container *ngFor="
                      let careerObj of userData.cv.data[0].careers.subCategories;
                      let i = index
                    ">

                    <div class="col-sm-12">
                      <div class="d-flex align-items-center py-2">
                        <!-- Date -->
                        <div class="mb-0 d-flex">
                          <div class="avatar avatar-xs me-2">
                            <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                class="avatar-img rounded-circle" /></a>
                          </div>
                          <div>
                            <strong>{{ careerObj?.role }}
                              {{ "at" | translate }}
                              <app-education-name [careerObj]="careerObj" [careerObject]="true">
                              </app-education-name>
                            </strong>
                            <br />
                            <div class="d-flex align-items-center">
                              <p class="mb-0 small me-2">
                                {{
                                careerObj.startDate | date : "MM.yyyy"
                                }}
                              </p>
                              -
                              <p class="mb-0 small me-2 ms-2" *ngIf="!careerObj.today">
                                {{
                                careerObj.endDate | date : "MM.yyyy"
                                }}
                              </p>
                              <p class="mb-0 small ms-2" *ngIf="careerObj.today">
                                {{ "present" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="careerObj.p"
                            (saveObject)="updateCareerObj($event, i)"></app-privacy-button>


                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="
                                  editCareer({
                                    edit: true,
                                    career: careerObj,
                                    model: userData.cv.data[0],
                                    index: i,
                                    indexOfLang: 0
                                  })
                                ">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a *ngIf="
                                  userData.cv.data[0].careers.subCategories
                                    .length > 1
                                " class="dropdown-item" (click)="deleteCareerSubCategory(i)">
                                  <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <!-- Birthday END -->
                    </div>
                  </ng-container>
                </form>
                <!-- Settings END -->
              </div>
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "category.Education" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      addEducation({
                        edit: false,
                        model: userData.cv.data[0],
                        languageIndex: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddEducation" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <!-- Form settings START -->
                <form class="row g-3">
                  <!-- First name -->
                  <ng-container *ngFor="
                      let educationObj of userData.cv.data[0].education
                        .subCategories;
                      let i = index
                    ">
                    <div class="col-sm-12">
                      <div class="d-flex align-items-center py-2">
                        <!-- Date -->
                        <div class="mb-0 d-flex">
                          <div class="avatar avatar-xs me-2">
                            <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                class="avatar-img rounded-circle" /></a>
                          </div>
                          <div>
                            <strong>{{ educationObj?.title }}
                              {{ "at" | translate }}
                              <app-education-name [educationObj]="educationObj" [educationObject]="true">
                              </app-education-name>
                            </strong>
                            <br />
                            <div class="d-flex align-items-center">
                              <p class="mb-0 small me-2">
                                {{
                                educationObj.startDate
                                | date : "MM.yyyy"
                                }}
                              </p>
                              -
                              <p class="mb-0 small me-2 ms-2" *ngIf="!educationObj.today">
                                {{
                                educationObj.endDate
                                | date : "MM.yyyy"
                                }}
                              </p>
                              <p class="mb-0 small ms-2" *ngIf="educationObj.today">
                                {{ "present" | translate }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex align-items-center ms-auto">


                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="educationObj.p"
                            (saveObject)="updateEducationObj($event, i)"></app-privacy-button>


                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="
                                  editEducation({
                                    edit: true,
                                    education: educationObj,
                                    model: userData.cv.data[0],
                                    languageIndex: 0
                                  })
                                ">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" *ngIf="
                                  userData.cv.data[0].education.subCategories
                                    .length > 1
                                " (click)="deleteEducationSubCategory(i)">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <!-- Birthday END -->
                    </div>
                  </ng-container>
                </form>
                <!-- Settings END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
            </div>
            <!-- Notification END -->
          </div>
          <!-- Notification tab END -->

          <!-- Privacy and safety tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-3">
            <!-- Privacy and safety START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">Places</h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Privacy START -->
                <ul class="list-group">
                  <!-- Privacy item -->
                  <div class="me-md-3">
                    <div class="mb-1 mt-1">
                      <div class="col-sm-12 position-relative">
                        <!-- Workplace on START -->
                        <a *ngIf="!cityAdd && !!checkLoginID"
                          class="btn btn-dashed rounded w-100 text-align-start" (click)="cityAdd = !cityAdd">
                          <i class="bi bi-plus-circle-dotted me-1"></i>Add City</a>
                        <!-- Workplace on END -->
                      </div>
                      <div class="tab-pane show active fade" id="addCity" *ngIf="cityAdd">
                        <div class="tab-pane show active fade" *ngIf="cityAdd">
                          <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                            search($event);
                              searchForPageTrue = true;
                              searchForPage = $event
                            " name="careerName" id="example-search-input5" autocomplete="off" />

                          <ng-container *ngIf="searchForPageTrue">
                            <div *ngFor="let page of pages$ | async" class="card__list">

                              <div class="hstack gap-2 mb-3" (click)="addNewCompany(page)">
                                <!-- Avatar -->
                                <div class="avatar-xs">
                                  <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg"
                                      alt=""></a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                  <a class="h6 mb-0"> {{ page.name }} </a>
                                </div>
                                <!-- Button -->
                              </div>

                            </div>
                          </ng-container>
                          <div class="col row col-sm-12 d-flex justify-content-start align-items-center">
                            <div class="col-md-10">
                              <label class="col-lg-3 me-2">{{ "Moved Date" }}
                              </label>

                              <input type="date" class="form-control col-lg-6" [ngModel]="
                                  cities.date | date : 'yyyy-MM-dd'
                                " (ngModelChange)="cities.date = $event" name="movedDate" required />
                            </div>
                          </div>
                          <div class="card-footer pt-0 text-end border-0 px-0">
                            <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                              (click)="cityAdd = !cityAdd">
                              {{ "cancel" | translate }}
                            </button>

                            <button type="submit" class="btn btn-sm btn-primary mb-0"
                              (click)="addCity(); cityAdd = !cityAdd">
                              {{ "save" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="!!userData.userUser?.places?.cities">
                      <ng-container *ngFor="let city of userData.userUser?.places?.cities">
                        <div class="col-sm-12" *ngIf="!!city?.name">
                          <!-- Birthday START -->
                          <div class="d-flex align-items-center py-2">
                            <!-- Date -->
                            <div class="mb-0"></div>
                            <div class="mb-0 d-flex">
                              <ul class="navbar-nav navbar-nav-scrol">
                                <li class="nav-item me-2">
                                  <a class="nav-link icon-md btn btn-light p-0">
                                    <i class="bi bi-geo-alt fs-6"></i>
                                  </a>
                                </li>
                              </ul>
                              <div>
                                <strong>
                                  <p>{{ city?.name }}</p>
                                </strong>
                                <p>
                                  Moved in
                                  {{ city?.date | date : "yyyy" }}
                                </p>
                              </div>
                            </div>
                            <div class="d-flex align-items-start ms-auto">

                              <app-privacy-button *ngIf="!!checkLoginID" [privacy]="city?.p"
                                (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>


                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>Edit</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="deleteNameCurrent()">
                                      <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- Birthday END -->
                        </div>
                        <div class="col-sm-12" *ngIf="!!city?.pageID">
                          <!-- Birthday START -->
                          <div class="d-flex align-items-center py-2">
                            <!-- Date -->
                            <div class="mb-0 d-flex">
                              <div class="avatar avatar-xs me-2">
                                <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                    class="avatar-img rounded-circle" /></a>
                              </div>
                              <div>
                                <strong><app-education-name [hometown]="true"
                                    [hometownPageID]="city?.pageID"></app-education-name>
                                </strong>
                                <p class="small mb-0">
                                  Moved in
                                  {{ city?.date | date : "yyyy" }}
                                </p>
                              </div>
                            </div>



                            <div class="d-flex align-items-start ms-auto">

                              <app-privacy-button *ngIf="!!checkLoginID" [privacy]="city?.p"
                                (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="deletePageCurrent()">
                                      <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- Birthday END -->
                        </div>
                      </ng-container>
                    </ng-container>

                    <div class="mb-1 mt-1" *ngIf="
                        !userData?.userUser?.places?.current?.pageID &&
                        !userData?.userUser?.places?.current?.name
                      ">
                      <div class="col-sm-12 position-relative">
                        <!-- Workplace on START -->
                        <a *ngIf="!addressAdd && !!checkLoginID"
                          class="btn btn-dashed rounded w-100 text-align-start"
                          (click)="addressAdd = !addressAdd">
                          <i class="bi bi-plus-circle-dotted me-1"></i>Add Current Addres</a>
                        <!-- Workplace on END -->
                      </div>
                      <div class="tab-pane show active fade" *ngIf="addressAdd">
                        <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                          search($event);
                            searchForPage = $event;
                            searchForPageTrue = true
                          " name="careerName" id="example-search-input5" autocomplete="off" />


                        <ng-container *ngIf="searchForPageTrue">
                          <div *ngFor="let page of pages$ | async" class="card__list">

                            <div class="hstack gap-2 mb-3" (click)="addCompanyAddress(page)">
                              <!-- Avatar -->
                              <div class="avatar-xs">
                                <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg"
                                    alt=""></a>
                              </div>
                              <!-- Title -->
                              <div class="overflow-hidden">
                                <a class="h6 mb-0"> {{ page.name }} </a>
                              </div>
                              <!-- Button -->
                            </div>

                          </div>
                        </ng-container>
                        <div class="col row col-sm-12 d-flex justify-content-start align-items-center">
                          <div class="col-md-10">
                            <label class="col-lg-3 me-2">{{ "Moved Date" }}
                            </label>

                            <input type="date" class="form-control col-lg-6" [ngModel]="
                                userData?.userUser?.places?.current?.date | date : 'yyyy-MM-dd'
                              " (ngModelChange)="userData.userUser.places.current.date = $event"
                              name="movedDate" required />
                          </div>
                        </div>
                        <div class="card-footer pt-0 text-end border-0 px-0">
                          <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                            (click)="addressAdd = !addressAdd">
                            {{ "cancel" | translate }}
                          </button>

                          <button type="submit" class="btn btn-sm btn-primary mb-0" (click)="
                              addAddres(); addressAdd = !addressAdd
                            ">
                            {{ "save" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12" *ngIf="!!userData?.userUser?.places?.current?.pageID">
                      <!-- Birthday START -->
                      <div class="d-flex align-items-center py-2">
                        <!-- Date -->
                        <div class="mb-0 d-flex">
                          <div class="avatar avatar-xs me-2">
                            <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                class="avatar-img rounded-circle" /></a>
                          </div>
                          <div>
                            <strong>
                              <app-education-name [hometown]="true" [hometownPageID]="
                                  userData?.userUser?.places?.current?.pageID
                                ">
                              </app-education-name>
                              <p *ngIf="userData.userUser?.places?.current?.name">
                                {{userData?.userUser?.places?.current?.name }}
                              </p>
                            </strong>
                            <p class="small mb-0">
                              {{ "Current city" }}
                            </p>
                          </div>
                        </div>

                        <div class="d-flex align-items-start ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID"
                            [privacy]="userData.userUser?.places?.current.p"
                            (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="deletePageCurrent()">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- Birthday END -->
                    </div>
                    <div class="col-sm-12" *ngIf="!!userData?.userUser?.places?.current?.name">
                      <!-- Birthday START -->
                      <div class="d-flex align-items-center py-2">
                        <div class="mb-0 d-flex">
                          <ul class="navbar-nav navbar-nav-scrol">
                            <li class="nav-item me-2">
                              <a class="nav-link icon-md btn btn-light p-0">
                                <i class="bi bi-geo-alt fs-6"></i>
                              </a>
                            </li>
                          </ul>
                          <div>
                            <strong>
                              <p>{{ userData?.userUser?.places?.current?.name }}</p>
                            </strong>
                            <p class="small mb-0">
                              {{ "Current city" }}
                            </p>
                          </div>
                        </div>
                        <div class="d-flex align-items-start ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID"
                            [privacy]="userData.user?.places?.current.p"
                            (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="deleteNameCurrent()">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- Birthday END -->
                    </div>
                    <ng-container>
                      <div class="mb-1 mt-1" *ngIf="
                          !userData.userUser?.places?.hometown?.pageID &&
                          !userData.userUser?.places?.hometown?.name
                        ">
                        <div class="col-sm-12 position-relative">
                          <!-- Workplace on START -->
                          <a *ngIf="!hometownAdd && !!checkLoginID"
                            class="btn btn-dashed rounded w-100 text-align-start"
                            (click)="hometownAdd = !hometownAdd">
                            <i class="bi bi-plus-circle-dotted me-1"></i>Add Hometown</a>
                          <!-- Workplace on END -->
                        </div>
                        <div class="tab-pane show active fade" id="addHometowm" *ngIf="hometownAdd">
                          <input class="form-control" type="text" [ngModel]="searchForPage" (ngModelChange)="
                            search($event);
                              searchForPage = $event;
                              searchForPageTrue = true
                            " name="careerName" id="example-search-input5" autocomplete="off" />

                          <ng-container *ngIf="searchForPageTrue">
                            <div *ngFor="let page of pages$ | async" class="card__list">

                              <div class="hstack gap-2 mb-3" (click)="addNewCompany(page)">
                                <!-- Avatar -->
                                <div class="avatar-xs">
                                  <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg"
                                      alt=""></a>
                                </div>
                                <!-- Title -->
                                <div class="overflow-hidden">
                                  <a class="h6 mb-0"> {{ page.name }} </a>
                                </div>
                                <!-- Button -->
                              </div>

                            </div>
                          </ng-container>

                          <div class="card-footer pt-0 text-end border-0 px-0">
                            <button type="submit" class="btn btn-sm btn-primary mb-0 me-3"
                              (click)="hometownAdd = !hometownAdd">
                              {{ "cancel" | translate }}
                            </button>

                            <button type="submit" class="btn btn-sm btn-primary mb-0" (click)="
                                addHomeTown();
                                hometownAdd = !hometownAdd
                              ">
                              {{ "save" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- First name -->
                      <div class="col-sm-12" *ngIf="!!userData?.userUser?.places?.hometown?.pageID">
                        <!-- Birthday START -->
                        <div class="d-flex align-items-center py-2">
                          <!-- Date -->
                          <div class="mb-0 d-flex">
                            <div class="avatar avatar-xs me-2">
                              <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                  class="avatar-img rounded-circle" /></a>
                            </div>
                            <div>
                              <strong><app-education-name [hometown]="true" [hometownPageID]="
                                    userData?.userUser?.places?.hometown?.pageID
                                  "></app-education-name>
                              </strong>
                              <p class="small mb-0">{{ "Hometown" }}</p>
                            </div>
                          </div>
                          <div class="d-flex align-items-start ms-auto">

                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="userData.user?.places?.current.p"
                              (saveObject)="updateCurrentPlacePrivacy($event)"></app-privacy-button>
                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deletePage()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- Birthday END -->
                      </div>
                      <div class="col-sm-12" *ngIf="!!userData?.userUser?.places?.hometown?.name">
                        <!-- Birthday START -->
                        <div class="d-flex align-items-center py-2">
                          <!-- Date -->
                          <div class="mb-0 d-flex">
                            <ul class="navbar-nav navbar-nav-scrol">
                              <li class="nav-item me-2">
                                <a class="nav-link icon-md btn btn-light p-0">
                                  <i class="bi bi-geo-alt fs-6"></i>
                                </a>
                              </li>
                            </ul>
                            <div>
                              <strong>{{
                                userData?.userUser?.places?.hometown?.name
                                }}</strong>
                              <p class="small mb-0">{{ "Hometown" }}</p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start ms-auto">

                            <app-privacy-button *ngIf="!!checkLoginID"
                              [privacy]="userData.user?.places?.hometown.p"
                              (saveObject)="updateCitiesPrivacy($event)"></app-privacy-button>

                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" href="#">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteNameOfHomeTown()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <!-- Birthday END -->
                      </div>
                    </ng-container>
                  </div>
                </ul>
                <!-- Privacy END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
            </div>
            <!-- Privacy and safety END -->
          </div>
          <!-- Privacy and safety tab END -->

          <!-- Communications tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-4">
            <!-- Communications START -->
            <div class="card">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "userProfile.contactInfo" | translate }}
                </h5>
              </div>
              <!-- Title START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Accordion START -->
                <form class="row g-3">
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <ng-container *ngFor="let phone of userData.user?.phone; let i = index">

                      <div class="d-flex px-0 py-2">
                        <p class="mb-0">
                          <i class="bi bi-telephone"></i> Phone:
                          <strong>
                            {{phone?.internationalNumber }}
                          </strong>
                        </p>
                        <!-- Date -->


                        <div class="d-flex align-items-start ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="phone.p"
                            (saveObject)="updatePhonePrivacy($event, i)"></app-privacy-button>

                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" [routerLink]="'../user_settings'">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>

                            </ul>
                          </div>

                        </div>

                      </div>
                    </ng-container>

                    <!-- Birthday END -->
                  </div>
                  <!-- First name -->
                  <div class="col-sm-12">
                    <ng-container *ngFor="let email of userData.user?.email.data; let i = index">

                      <!-- Birthday START -->
                      <div class="d-flex align-items-center px-0 py-2">
                        <!-- Date -->
                        <p class="mb-0">
                          <i class="bi bi-envelope"></i>
                          {{ "data.email" | translate }}
                          <strong>{{ email.name }} </strong>
                        </p>

                        <div class="d-flex align-items-start ms-auto">

                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="email?.p"
                            (saveObject)="updateEmailPrivacy($event, i)"></app-privacy-button>

                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" [routerLink]="'../user_settings'">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <!-- Birthday END -->
                  </div>
                </form>
                <!-- Accordion END -->
              </div>

              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "Websites and social links" }}
                </h5>
              </div>
              <div class="card-body">
                <div class="row g-3">
                  <div class="col-sm-12">
                    <div class="d-flex">
                      <div class="col-sm-12 position-relative">
                        <!-- Workplace on START -->

                        <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                          (click)="addWebsites()">
                          <i class="bi bi-plus-circle-dotted me-1"></i>{{ "Add a Website" }}</a>
                        <ng-container *ngIf="newWebsite">
                          <div class="col-sm-12 d-flex">
                            <div class="col-sm-12">
                              <input type="text" class="form-control" placeholder="www.smartits.de"
                                [(ngModel)]="addWebsiteUrl" />
                            </div>
                          </div>
                          <div class="flex items-center" style="
                              flex-direction: row;
                              display: flex;
                              border: none;
                            " class="specialty col-12 mt-1">
                            <button class="btn btn-light me-2" type="submit" (click)="saveWebsiteUrl()">
                              <i class="bi bi-check"></i>
                            </button>
                          </div>
                        </ng-container>
                        <ng-container *ngFor="let website of userData?.userUser?.websites; let i = index">
                          <div class="col-sm-12">
                            <ng-container *ngIf="isEditingWebsite === i; else readonlyWebsite">
                              <div class="col-sm-12 d-flex">
                                <div class="col-sm-12">
                                  <input type="text" class="form-control" placeholder="www.smartits.de"
                                    [(ngModel)]="website.url" />
                                </div>
                              </div>
                              <div class="flex items-center" style="
                                    flex-direction: row;
                                    display: flex;
                                    border: none;
                                  " class="specialty col-12 mt-1">
                                <button class="btn btn-light me-2" type="submit"
                                  (click)="updateWebsite(website, i)">
                                  <i class="bi bi-check"></i>
                                </button>
                              </div>
                            </ng-container>
                            <ng-template #readonlyWebsite>
                              <div class="d-flex align-items-center px-0 py-2">
                                <!-- Date -->
                                <div class="ms-2">
                                  <a class="btn-link" stopPropagation *ngIf="!!website.url"
                                    (click)="openLink(website.url)">
                                    {{ website.url }}
                                  </a>
                                </div>

                                <div class="d-flex align-items-start ms-auto">

                                  <app-privacy-button *ngIf="!!checkLoginID"
                                    [privacy]="userData?.userUser?.websites[i]?.p"
                                    (saveObject)="updateWebsitePrivacy($event, i)"></app-privacy-button>


                                  <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                    <!-- Card share action menu -->
                                    <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                      data-bs-toggle="dropdown" aria-expanded="false">
                                      <i class="bi bi-three-dots"></i>
                                    </a>
                                    <!-- Card share action dropdown menu -->
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                      <li>
                                        <a class="dropdown-item" (click)="editWebsite(i)">
                                          <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate
                                          }}</a>
                                      </li>
                                      <li>
                                        <a class="dropdown-item" (click)="deleteWebsite(i)">
                                          <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </ng-template>

                          </div>
                        </ng-container>

                        <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                          (click)="addSocialLinks()">
                          <i class="bi bi-plus-circle-dotted me-1"></i>{{ "Add Social Links" }}</a>
                        <!-- Workplace on END -->
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf="newSocialLink">
                    <div class="col-sm-12 d-flex">
                      <div class="col-sm-9">
                        <input type="text" class="form-control" placeholder="Add username"
                          [(ngModel)]="addSocialLinkUrl" />
                      </div>
                      <div class="col-sm-3">
                        <select name="selectedSocialLink" id="selectedSocialLink" [ngModel]="socialLinks?.id"
                          (ngModelChange)="changeSocialLink($event)" class="col-md-12 form-control mb-2">
                          <option class="dropdown-item" disabled selected value="undefined">Select</option>

                          <option *ngFor="
                              let socialLink of arrayService.socialLinks
                            " [ngValue]="socialLink.id" [selected]="socialLink">
                            {{ socialLink?.description }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="flex items-center" style="
                        flex-direction: row;
                        display: flex;
                        border: none;
                      " class="specialty col-12 mt-1">
                      <button class="btn btn-light me-2" type="submit" (click)="saveSocialLink()">
                        <i class="bi bi-check"></i>
                      </button>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="
                      let socialLink of userData?.userUser?.socialMedia?.links;
                      let i = index
                    ">
                    <div class="col-sm-12">
                      <ng-container *ngIf="isEditingSocialLink === i; else readOnlySocialLink">

                        <div class="col-sm-12 d-flex">
                          <div class="col-sm-9">
                            <input type="text" class="form-control" [(ngModel)]="socialLink.url" />
                          </div>
                          <div class="col-sm-3">
                            <select name="selectedSocialLink" id="selectedSocialLink"
                              [(ngModel)]="socialLink.id" class="col-md-12 form-control mb-2">
                              <option *ngFor="
                                    let socialLink of arrayService.socialLinks
                                  " [ngValue]="socialLink.id" [selected]="socialLink">
                                {{ socialLink.description }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="flex items-center" style="
                              flex-direction: row;
                              display: flex;
                              border: none;
                            " class="specialty col-12 mt-1">
                          <button class="btn btn-light me-2" type="submit"
                            (click)="updateSocialLink(socialLink, i)">
                            <i class="bi bi-check"></i>
                          </button>
                        </div>
                      </ng-container>
                      <ng-template #readOnlySocialLink>
                        <div class="d-flex align-items-center px-0 py-2">
                          <!-- Date -->
                          <div>
                            <i *ngIf="!!socialLink.id" [class]="
                                  getSocialLinkIcons(socialLink.id).icon +
                                  ' mr-1'
                                " style="font-size: 32px"></i>
                          </div>

                          <div class="ms-2">
                            <a target="_blank" *ngIf="
                                  !!socialLink.url &&
                                  getSocialLinkIcons(socialLink?.id)?.url
                                " [href]="
                                  getSocialLinkIcons(socialLink?.id)?.url +
                                  socialLink?.url
                                ">
                              {{ socialLink?.url }}
                            </a>
                            <span *ngIf="
                                  !!socialLink.url &&
                                  !getSocialLinkIcons(socialLink?.id)?.url
                                ">
                              {{ socialLink?.url }}
                            </span>

                            <br />
                            <div class="d-flex align-items-center">
                              <p class="mb-0 small me-2">
                                {{
                                getSocialLinkIcons(socialLink.id)?.description
                                }}
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start ms-auto">

                            <app-privacy-button *ngIf="!!checkLoginID" [privacy]="socialLink?.p"
                              (saveObject)="updateSocialLinksPrivacy($event, i)"></app-privacy-button>

                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="editSocialLink(i)">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteSocialLink(i)">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </ng-template>

                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "userProfile.basicInfo" | translate }}
                </h5>
              </div>
              <!-- Title START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Accordion START -->
                <form class="row g-3">
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center px-0 py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-person"></i>{{ "userProfile.gender" | translate }}
                        <strong> {{ getGender | translate }} </strong>
                      </p>




                      <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                        <!-- Card share action menu -->
                        <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="bi bi-three-dots"></i>
                        </a>
                        <!-- Card share action dropdown menu -->
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                          <li>
                            <a class="dropdown-item" [routerLink]="'../user_settings'">
                              <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <!-- First name -->
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center px-0 py-2">
                      <!-- Date -->
                      <p class="mb-0">
                        <i class="bi bi-calendar3"></i> Born:
                        <strong>{{userData.user?.birthday.date| date: "MMMM dd yyyy"}}
                        </strong>
                      </p>

                      <div class="d-flex align-items-start ms-auto">

                        <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.user?.birthday?.p"
                          (saveObject)="updateBirthdayPrivacy($event, i)"></app-privacy-button>
                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" [routerLink]="'../user_settings'">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                  <div class="col-sm-12">
                    <!-- Birthday START -->
                    <div class="d-flex align-items-center px-0 py-2">
                      <!-- Date -->
                      <p class="mb-0" *ngIf="!!userData.userUser?.religion?.pageID">
                        <i class="bi bi-hand-index"></i>
                        {{ "religionName" | translate }}:
                        <strong>{{ religion?.name | translate }} -
                          <app-education-name [religion]="true"
                            [religionPageID]="userData.userUser?.religion?.pageID">
                          </app-education-name>
                        </strong>
                      </p>
                      <p class="mb-0" *ngIf="!userData.userUser?.religion?.pageID">
                        <i class="bi bi-hand-index"></i>
                        {{ "religionName" | translate }}:
                        <strong>{{ religion?.name | translate }}
                        </strong>
                      </p>

                      <div class="d-flex align-items-start ms-auto">

                        <app-privacy-button *ngIf="!!checkLoginID" [privacy]="userData.userUser?.religion?.p"
                          (saveObject)="updateReligionPrivacy($event, i)"></app-privacy-button>

                        <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                          <!-- Card share action menu -->
                          <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                          </a>
                          <!-- Card share action dropdown menu -->
                          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                            <li>
                              <a class="dropdown-item" (click)="addReligion({ user: userData.user })">
                                <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                            </li>
                            <li>
                              <a class="dropdown-item">
                                <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Birthday END -->
                  </div>
                </form>
                <!-- Accordion END -->
              </div>
              <!-- Card body END -->
              <!-- Button save -->
            </div>
            <!-- Communications  END -->
          </div>
          <!-- Communications tab END -->

          <!-- Messaging tab START -->
          <div class="tab-pane fade" id="nav-setting-tab-5">
            <!-- Messaging privacy START -->
            <div class="card mb-4">
              <!-- Title START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "relationsship.name" | translate }}
                </h5>
              </div>
              <!-- Title START -->
              <div class="card-body pt-1">
                <ul class="list-group">
                  <div class="me-md-3">
                    <div class="col-sm-12 position-relative" *ngIf="!userData.userEmea?.relationship?.s">
                      <a class="btn btn-dashed rounded w-100 text-align-start" (click)="addRelationShip()">
                        <i class="bi bi-plus-circle-dotted me-1"></i>Add
                        Relationship</a>
                    </div>
                    <ng-container *ngIf="!!userData?.userEmea?.relationship?.status">
                      <ng-container *ngIf="
                          isEditingRelationship;
                          else readOnlyRelationship
                        ">
                        <div class="col-sm-12">
                          <!-- Birthday START -->
                          <div class="d-flex align-items-center px-0 py-2 col-lg-12">
                            <!-- Date -->
                            <form name="ngForm" #relationshipForm="ngForm" class="col-lg-12" (ngSubmit)="
                                saveUserRelationship(relationshipForm)
                              ">
                              <div class="col-lg-12">
                                <select name="relationshipStatus" id="selectedValue"
                                  [(ngModel)]="userData.userEmea.relationship.s"
                                  class="col-md-12 form-control mb-2" #relationshipStatus="ngModel"
                                  (ngModelChange)="
                                    changeRelationship($event)
                                  " [ngClass]="{
                                    'is-invalid':
                                      relationshipForm.submitted &&
                                      relationshipStatus.invalid
                                  }" required>
                                  <option *ngFor="
                                      let relationship of arrayService.relationsship
                                    " [ngValue]="relationship.id" [selected]="relationship">
                                    {{
                                    relationship?.description
                                    | translate
                                    }}
                                  </option>
                                </select>
                                <ng-container *ngIf="userData?.userEmea?.relationship?.s !== 1">
                                  <ng-container *ngIf="
                                      !!userData?.userEmea?.relationship?.user?.id
                                    ">
                                    <app-education-name [relationshipUser]="true" (removeUserFromRelationship)="
                                        removeUser()
                                      " [relationshipUserID]="
                                      userData?.userEmea?.relationship?.user?.id
                                      "></app-education-name>
                                  </ng-container>
                                  <ng-container *ngIf="
                                      !userData?.userEmea?.relationship?.user?.id
                                    ">
                                    <input class="form-control mb-2" type="text" name="searchForUser"
                                      [ngModel]="searchForUser" (ngModelChange)="
                                        searchForUser = $event;
                                        searchForUserTrue = true
                                      " autocomplete="off" />
                                  </ng-container>

                                  <ng-container *ngIf="searchForUserTrue">
                                    <div *ngFor="
                                        let userObject of usersArray
                                          | searchUser : searchForUser
                                      " class="card__list">
                                      <div class="d-flex row pr-2 pl-2 align-items-center" (click)="
                                          addSearchedUser(userObject)
                                        ">
                                        <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'"
                                          fallback="mp" size="35" />
                                        <span class="pr-2 pl-2">
                                          {{ userObject?.firstName }}
                                          {{ userObject?.lastName }}
                                          <span> </span>
                                        </span>
                                      </div>
                                    </div>
                                  </ng-container>
                                </ng-container>
                                <input *ngIf=" userData?.userEmea?.relationship.s !== 1" type="date"
                                  name="relationshipDate" #relationshipDate="ngModel"
                                  [ngModel]="userData?.userEmea?.relationship?.date" (ngModelChange)="
                                    (userData?.userEmea?.relationship?.date ===
                                      $event)
                                  " class="form-control col-sm-4" />

                                <div class="flex items-center" style="
                                    flex-direction: row;
                                    display: flex;
                                    border: none;
                                  " class="specialty col-12 px-0 mt-1">
                                  <button class="btn btn-light me-2" type="submit">
                                    <i class="bi bi-check"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          <!-- Birthday END -->
                        </div>
                      </ng-container>
                      <ng-template #readOnlyRelationship>
                        <div class="col-sm-12">
                          <div class="d-flex align-items-center px-0 py-2" *ngIf="
                              !user?.relationship?.user?.status &&
                              !!user?.relationship?.status
                            ">
                            <!-- Date -->
                            <p class="mb-0">
                              <i class="bi bi-heart-fill"></i>
                              <strong>
                                {{
                                relationshipStatus?.description
                                | translate
                                }}
                                <ng-container *ngIf="!!user.relationship?.user?.id">
                                  <app-education-name [isStatusReceive]="true" [userID]="
                                      user.relationship?.user?.id
                                    "></app-education-name>
                                </ng-container>
                              </strong>
                            </p>
                            <div class="d-flex align-items-center ms-auto">

                              <app-privacy-button *ngIf="!!checkLoginID"
                                [privacy]="userData.userEmea?.relationship?.p"
                                (saveObject)="updateRelationship($event)"></app-privacy-button>

                              <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                                <!-- Card share action menu -->
                                <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                  <li>
                                    <a class="dropdown-item" (click)="editRelationship()">
                                      <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" (click)="deleteRelationship()">
                                      <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <!-- Birthday START -->
                          <div class="d-flex align-items-center px-0 py-2" *ngIf="
                              user?.relationship?.user?.status === 2
                            ">
                            <!-- Date -->
                            <p class="mb-0">
                              <i class="bi bi-heart-fill"></i>
                              <strong>
                                {{
                                relationshipStatus?.description
                                | translate
                                }}
                                <app-education-name [isStatusReceive]="true"
                                  [userID]="user.relationship?.user?.id"></app-education-name>
                              </strong>
                            </p>
                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="editRelationship()">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteRelationship()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="d-flex align-items-center px-0 py-2" *ngIf="
                              user?.relationship?.user?.status === 1
                            ">
                            <!-- Date -->
                            <p class="mb-0">
                              <i class="bi bi-heart-fill"></i>
                              <strong>
                                {{
                                relationshipStatus?.description
                                | translate
                                }}

                                <app-education-name [isStatusReceive]="true"
                                  [userID]="user.relationship?.user?.id"></app-education-name></strong>
                            </p>
                            <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                              <!-- Card share action menu -->
                              <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-three-dots"></i>
                              </a>
                              <!-- Card share action dropdown menu -->
                              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                                <li>
                                  <a class="dropdown-item" (click)="editRelationship()">
                                    <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteRelationship()">
                                    <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative" *ngIf="
                              user?.relationship?.user?.status === 0
                            ">
                            <!-- Avatar -->
                            <div class="avatar text-center">
                              <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                            </div>
                            <!-- Info -->
                            <div class="mx-sm-3 my-2 my-sm-0">
                              <p class="small mb-2">
                                <b><app-education-name [isStatusReceive]="true" [userID]="
                                      user.relationship?.user?.id
                                    "></app-education-name></b>
                                added you to
                                {{
                                relationshipStatus?.description
                                | translate
                                }}
                              </p>
                              {{ "status 0" }}
                              <!-- Button -->
                              <div class="d-flex">
                                <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptRelationship()">
                                  Accept
                                </button>
                                <button class="btn btn-sm py-1 btn-danger-soft">
                                  Delete
                                </button>
                              </div>
                            </div>
                            <!-- Action -->
                            <div class="d-flex ms-auto">
                              <p class="small me-5 text-nowrap">
                                Just now
                              </p>
                              <!-- Notification action START -->
                              <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                                <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                                  id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="bi bi-three-dots"></i>
                                </a>
                                <!-- Card share action dropdown menu -->
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                    </a>
                                  </li>
                                  <li>
                                    <a class="dropdown-item" href="#">
                                      <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                    </a>
                                  </li>
                                </ul>
                              </div>
                              <!-- Notification action END -->
                            </div>
                          </div>
                          <!-- Birthday END -->
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>
                </ul>
                <!-- Settings style START -->

                <!-- Message END -->
              </div>
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "familyMembers.name" | translate }}
                </h5>
              </div>
              <div class="card-body pt-1">
                <div class="list group">
                  <div class="me-md-3">
                    <a *ngIf="!!checkLoginID" class="btn btn-dashed rounded w-100 text-align-start"
                      (click)="addFamilymember()"><i class="bi bi-plus-circle-dotted me-1"></i>Add
                      Family Member</a>
                  </div>
                  <ng-container *ngIf="newFamilyMember">
                    <div class="col-sm-12">
                      <div class="d-flex align-items-center px-0 py-2 col-lg-12">
                        <form name="ngForm" #familyMember="ngForm" class="col-lg-12"
                          (ngSubmit)="saveNewFamilyMember(familyMember)">
                          <div class="col-lg-12">
                            <input class="form-control mb-2" type="text" name="searchForUser"
                              [ngModel]="searchForUser" (ngModelChange)="
                                searchForUser = $event;
                                searchForUserTrue = true
                              " autocomplete="off" />
                            <ng-container *ngIf="searchForUserTrue">
                              <div *ngFor="
                                  let userObject of usersArray
                                    | searchUser : searchForUser
                                " class="card__list">
                                <div class="d-flex row pr-2 pl-2 align-items-center"
                                  (click)="addSearchedUser(userObject)">
                                  <img alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                                    size="35" />
                                  <span class="pr-2 pl-2">
                                    {{ userObject?.firstName }}
                                    {{ userObject?.lastName }}
                                    <span> </span>
                                  </span>
                                </div>
                              </div>
                            </ng-container>
                            <select name="familyMemberStatus" id="familyMemberStatus"
                              [(ngModel)]="user.relationship.status" class="col-md-12 form-control mb-2"
                              #familyMemberStatus="ngModel" (ngModelChange)="
                                changeRelationship($event)
                              " [ngClass]="{
                                'is-invalid':
                                  familyMember.submitted &&
                                  familyMemberStatus.invalid
                              }" required>
                              <option *ngFor="
                                  let relationship of arrayService.familyMembers
                                " [ngValue]="relationship.id" [selected]="relationship">
                                {{ relationship.description }}
                              </option>
                            </select>
                            <div class="flex items-center" style="
                                flex-direction: row;
                                display: flex;
                                border: none;
                              " class="specialty col-12 px-0 mt-1">
                              <button class="btn btn-light me-2" type="submit">
                                <i class="bi bi-check"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngFor="
                      let familyMember of user?.familyMembers;
                      let i = index
                    ">
                    <div class="col-sm-12">
                      <!-- Birthday START -->
                      <div class="d-flex align-items-center px-0 py-2" *ngIf="familyMember?.user?.status === 2">
                        <!-- Date -->
                        <div class="mb-0 d-flex">
                          <div class="avatar avatar-xs me-2">
                            <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                class="avatar-img rounded-circle" /></a>
                          </div>
                          <div>
                            <strong>
                              <app-education-name [familyMember]="true"
                                [userID]="familyMember?.user?.id"></app-education-name>
                            </strong>
                            <br />
                            <div class="d-flex align-items-center">
                              <p class="mb-0 small">
                                {{
                                getFamilyMemberStatus(
                                familyMember.status
                                ).description
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center px-0 py-2" *ngIf="familyMember?.user?.status === 1">
                        <!-- Date -->
                        <div class="mb-0 d-flex">
                          <div class="avatar avatar-xs me-2">
                            <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                                class="avatar-img rounded-circle" /></a>
                          </div>
                          <div>
                            <strong>
                              <app-education-name [familyMember]="true"
                                [userID]="familyMember?.user?.id"></app-education-name>
                            </strong>
                            <br />
                            <div class="d-flex align-items-center">
                              <p class="mb-0 small">
                                {{
                                getFamilyMemberStatus(
                                familyMember.status
                                ).description
                                }}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex align-items-center ms-auto">
                          <app-privacy-button *ngIf="!!checkLoginID" [privacy]="familyMember.p"
                            (saveObject)="updateFamilyMember($event, i)"></app-privacy-button>


                          <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                            <!-- Card share action menu -->
                            <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                              data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                              <li>
                                <a class="dropdown-item" (click)="editFamilyMember()">
                                  <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                              </li>
                              <li>
                                <a class="dropdown-item" (click)="deleteFamilyMember(i)">
                                  <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="rounded badge-unread d-sm-flex border-0 mb-1 p-3 position-relative"
                        *ngIf="familyMember?.user?.status === 0">
                        <!-- Avatar -->
                        <div class="avatar text-center">
                          <img class="avatar-img rounded-circle" src="assets/images/avatar/01.jpg" alt="" />
                        </div>
                        <!-- Info -->
                        <div class="mx-sm-3 my-2 my-sm-0">
                          <p class="small mb-2">
                            <b><app-education-name [familyMember]="true"
                                [userID]="familyMember?.user?.id"></app-education-name></b>
                            added you as
                            {{
                            getFamilyMemberStatus(familyMember.status)
                            .description
                            }}
                          </p>
                          <!-- Button -->
                          <div class="d-flex">
                            <button class="btn btn-sm py-1 btn-primary me-2" (click)="acceptFamilyMember(i)">
                              Accept
                            </button>
                            <button class="btn btn-sm py-1 btn-danger-soft">
                              Delete
                            </button>
                          </div>
                        </div>
                        <!-- Action -->
                        <div class="d-flex ms-auto">
                          <p class="small me-5 text-nowrap">Just now</p>
                          <!-- Notification action START -->
                          <div class="dropdown position-absolute end-0 top-0 mt-3 me-3">
                            <a href="#" class="z-index-1 text-secondary btn position-relative py-0 px-2"
                              id="cardNotiAction1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i class="bi bi-three-dots"></i>
                            </a>
                            <!-- Card share action dropdown menu -->
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction1">
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="bi bi-bell-slash fa-fw pe-2"></i>Turn off
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  <i class="bi bi-volume-mute fa-fw fs-5 pe-2"></i>Mute Judy Nguyen
                                </a>
                              </li>
                            </ul>
                          </div>
                          <!-- Notification action END -->
                        </div>
                      </div>
                      <!-- Birthday END -->
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Button save -->
            </div>

            <!-- Messaging experience END -->
          </div>
          <!-- Messaging tab END -->

          <!-- Close account tab START -->

          <div class="tab-pane fade" id="nav-setting-tab-7">
            <!-- Card START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "Branchen.name" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12">
                  <!-- Birthday START -->
                  <div class="d-flex align-items-center px-0 py-2">
                    <!-- Date -->
                    <p class="mb-0">
                      <i class="bi bi-buildings"></i>
                      <strong>
                        {{ branchTitle | translate }} -
                        {{ subCategoryTitle | translate }}
                      </strong>
                    </p>
                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>
                          <a class="dropdown-item" [routerLink]="'../cv/branch'">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Birthday END -->
                </div>

                <!-- Delete END -->
              </div>

              <div class="card-header border-0 pb-0">
                <h5 class="card-title">
                  {{ "skills.offer" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      addOffer({
                        edit: true,
                        offer: userData.cv.data[0]?.skills[0]
                                .skillsOffer.data,
                        model: model,
                        indexOfLang: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                  <div class="col-sm-9 d-flex flex-wrap ">
                    <ng-container *ngFor="
                    let keyword of userData.user.searchFilterSkills.searchSkillsOffer.data">
                      <p class="card__text me-1 mb-1">
                        {{ keyword?.name }}
                      </p>
                    </ng-container>
                  </div>

                  <div class="d-flex align-items-start ms-auto">



                    <app-privacy-button *ngIf="!!checkLoginID"
                      [privacy]="userData?.user?.searchFilterSkills?.searchSkillsOffer?.p"
                      (saveObject)="updateSearchSkillsOffer($event)"></app-privacy-button>

                    <div class="dropdown" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>

                          <a class="dropdown-item" (click)="
                            editOffer({
                              edit: true,
                              offer:
                                userData.cv.data[0]?.skills?.subCategories[0]
                                  ?.skillsOffer,
                              model:
                              userData.cv.data[0]?.skills[0]?.subCategories
                                  ?.skillsOffer,
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="
                            editOffer({
                              edit: true,
                              offer:
                                userData.cv.data[0]?.skills?.subCategories[0]
                                  ?.skillsOffer,
                              model:
                              userData.cv.data[0]?.skills[0]?.subCategories
                                  ?.skillsOffer,
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Delete END -->
              </div>

              <div class="card-header border-0 pb-0 py-1">
                <h5 class="card-title">
                  {{ "skills.pcKnowledge" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      editPCKnowledge({
                        edit: true,
                        pcKnowledge:
                          userData.cv.data[0]?.skills[0]
                            ?.pcKnowledge,
                        model: userData.cv.data[0],
                        indexOfLang: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                  <div class="col-sm-9 d-flex flex-wrap">
                    <ng-container *ngFor="
                    let keyword of userData.user.searchFilterSkills.searchPCKnowledge.data">
                      <p class="card__text me-1 mb-1">
                        {{ keyword?.name }}
                      </p>
                    </ng-container>
                  </div>


                  <div class="d-flex align-items-start ms-auto">

                    <app-privacy-button *ngIf="!!checkLoginID"
                      [privacy]="userData.user.searchFilterSkills.searchPCKnowledge.p"
                      (saveObject)="updatePcKnowledge($event)"></app-privacy-button>

                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>
                          <a class="dropdown-item" (click)="
                            editPCKnowledge({
                              edit: true,
                              pcKnowledge:
                              userData.cv.data[0]?.skills[0]
                            ?.pcKnowledge,
                              model: userData.cv.data[0],
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="
                            editPCKnowledge({
                              edit: true,
                              pcKnowledge:
                              userData.cv.data[0]?.skills[0]
                            ?.pcKnowledge,
                              model: userData.cv.data[0],
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Delete END -->
              </div>

              <div class="card-header border-0 pb-0 py-1">
                <h5 class="card-title">
                  {{ "skills.language" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      editLanguages({
                        edit: true,
                        language:
                        userData.cv.data[0]?.skills[0]
                            ?.language,
                        model: userData.cv.data[0],
                        indexOfLang: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                  <div class="col-sm-9 d-flex flex-wrap ">

                    <ng-container *ngFor="
                      let language of userData.user.searchFilterSkills.searchLanguages.data
                    ">
                      <p class="card__text me-1 mb-1">
                        {{ getLanguageName(language.name)?.value }}
                      </p>
                    </ng-container>
                  </div>

                  <div class="d-flex align-items-start ms-auto">


                    <app-privacy-button *ngIf="!!checkLoginID"
                      [privacy]="userData.user.searchFilterSkills.searchLanguages.p"
                      (saveObject)="updateSearchLanguages($event)"></app-privacy-button>

                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>
                          <a class="dropdown-item" (click)="
                            editLanguages({
                              edit: true,
                              language:
                                 userData.cv.data[0]?.skills[0]
                            ?.pcKnowledge,
                              model: user.cv.data[0]
                            })
                          ">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                        <li>
                          <a class="dropdown-item">
                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Delete END -->
              </div>
              <div class="card-header border-0 pb-0 py-1">
                <h5 class="card-title">
                  {{ "skills.driveLicense" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      editDriveLicense({
                        edit: true,
                        model: userData.cv.data[0],
                        driveLicense:
                        userData.cv.data[0]?.skills[0]
                            ?.driveLicenses,
                        indexOfLang: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                  <ng-container *ngFor="
                      let keyword of userData.user.searchFilterSkills.searchDriveLicenses.data">
                    <p class="card__text me-1 mb-1">
                      {{ keyword?.name | translate }}
                    </p>
                  </ng-container>

                  <div class="d-flex align-items-start ms-auto">


                    <app-privacy-button *ngIf="!!checkLoginID"
                      [privacy]="userData.user.searchFilterSkills.searchDriveLicenses.p"
                      (saveObject)="updateDriveLicense($event)"></app-privacy-button>


                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>
                          <a class="dropdown-item" (click)="
                            editDriveLicense({
                              edit: true,
                              model: user.cv.data[0],
                              driveLicense:
                              userData.cv.data[0]?.skills[0]
                            ?.driveLicenses,
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="
                            editDriveLicense({
                              edit: true,
                              model: user.cv.data[0],
                              driveLicense:
                              userData.cv.data[0]?.skills[0]
                            ?.driveLicenses,
                              indexOfLang: 0
                            })
                          ">
                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Delete END -->
              </div>

              <div class="card-header border-0 pb-0 py-1">
                <h5 class="card-title">
                  {{ "skills.qualifications" | translate }}
                </h5>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
                  <!-- Workplace on START -->
                  <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
                      editQualifications({
                        edit: true,
                        model: userData.cv.data[0],
                        qualifications:
                        userData.cv.data[0]?.skills[0]
                            ?.qualifications,
                        user: user,
                        index: 0
                      })
                    ">
                    <i class="bi bi-plus-circle-dotted me-1"></i>{{ "add" | translate }}</a>
                  <!-- Workplace on END -->
                </div>
                <div class="d-flex pr-1 pt-1 flex-wrap col-sm-12">
                  <div class="col-sm-9 d-flex flex-wrap ">

                    <ng-container *ngFor="
                      let keyword of  userData.user.searchFilterSkills.searchQualifications.data">
                      <p class="card__text me-1 mb-1">
                        {{ keyword?.name }}
                      </p>
                    </ng-container>
                  </div>
                  <div class="d-flex align-items-start ms-auto">

                    <app-privacy-button *ngIf="!!checkLoginID"
                      [privacy]="userData.user.searchFilterSkills.searchQualifications.p"
                      (saveObject)="updateSearchQualifications($event)"></app-privacy-button>


                    <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                      <!-- Card share action menu -->
                      <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </a>
                      <!-- Card share action dropdown menu -->
                      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                        <li>
                          <a class="dropdown-item" (click)="
                            editQualifications({
                              edit: true,
                        model: userData.cv.data[0],
                        qualifications:
                        userData.cv.data[0]?.skills[0]
                            ?.qualifications,
                        user: user,
                        index: 0
                            })
                          ">
                            <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="
                            editQualifications({
                              edit: true,
                        model: userData.cv.data[0],
                        qualifications:
                        userData.cv.data[0]?.skills[0]
                            ?.qualifications,
                        user: user,
                        index: 0
                            })
                          ">
                            <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Delete END -->
              </div>

              <!-- Card body END -->
            </div>
            <!-- Card END -->
          </div>
          <div class="tab-pane fade" id="nav-setting-tab-8">
            <!-- Card START -->
            <div class="card">
              <!-- Card header START -->
              <div class="card-header border-0 pb-0">
                <h5 class="card-title">I offer</h5>
                <p class="mb-0">
                  He moonlights difficult engrossed it, sportsmen.
                  Interested has all Devonshire difficulty gay
                  assistance joy. Unaffected at ye of compliment
                  alteration to.
                </p>
              </div>
              <!-- Card header START -->
              <!-- Card body START -->
              <div class="card-body">
                <!-- Delete START -->
                <h6>Before you go...</h6>
                <ul>
                  <li>
                    Take a backup of your data <a href="#">Here</a>
                  </li>
                  <li>
                    If you delete your account, you will lose your all
                    data.
                  </li>
                </ul>
                <div class="form-check form-check-md my-4">
                  <input class="form-check-input" type="checkbox" value="" id="deleteaccountCheck" />
                  <label class="form-check-label" for="deleteaccountCheck">Yes, I'd like to delete my
                    account</label>
                </div>
                <a href="#" class="btn btn-success-soft btn-sm mb-2 mb-sm-0">Keep my account</a>
                <a href="#" class="btn btn-danger btn-sm mb-0">Delete my account</a>
                <!-- Delete END -->
              </div>
              <!-- Card body END -->
            </div>
            <!-- Card END -->
          </div>
          <!-- Close account tab END -->
        </div>
        <!-- Setting Tab content END -->
      </div>
    </div>
    <!-- Row END -->
    <!-- Card body END -->
  </div>
