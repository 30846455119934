<aside class="pt-4 aside">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3" *ngIf="router.url === '/groups'">
      <div class="d-flex row justify-content-between">
        <h2>{{'groupSettings.name' | translate}}</h2>
        <i class="bi bi-gear-fill width-40 font-size1_25rem"></i>
      </div>
      <input type="text" class="form-control" placeholder="Search Groups">
      <div>
        <button type="button" class="button-f button-sc btn-light"  [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-gear-fill width-40"></i>
            <span class="span-padding-left10">{{'groupSettings.feed' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc btn-light" [routerLinkActive]="['active']">
          <div >
            <i class="bi bi-chat-text-fill"></i>
                          <span class="span-padding-left10">{{'groupSettings.discover' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc btn-light"  [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-pen-fill width-40"></i>
            <span class="span-padding-left10">{{'groupSettings.notifications' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc btn-light" routerLink="groups/create">
          <div>
            <i class="bi bi-plus width-40" aria-hidden="true"></i>
            <span class="span-padding-left10">Create new Group</span>
          </div>
        </button>
      </div>
      <h2>Groups you Manage</h2>
      <ng-container *ngFor="let group of groups">
        <app-group-list [group]="group" [sidebar]="'sidebar'"></app-group-list>
      </ng-container>
      <hr>
      <h2>Your Groups</h2>
    </div>

    <div *ngIf="sidebarDetails">
      test
    </div>

  </section>

</aside>

