import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-user-career-education',
  templateUrl: './user-career-education.component.html',
  styleUrls: ['./user-career-education.component.scss']
})
export class UserCareerEducationComponent implements OnInit {

  userData: any;

  constructor(public authService: AuthService) {

  }
  ngOnInit(): void {
    this.userData =  this.authService.userLoggedIn;
  }

  public get checkLoginID() {
    return this.userData.userId === this.authService.userID;
  }




}
