import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/assets/environments/environment';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent implements OnInit, OnDestroy {

  tags: string[];
  constructor(@Inject(MAT_DIALOG_DATA) public data: ShareData, private router: Router) { }
  ngOnDestroy(): void {
    this.tags = [];
  }
 public url;

  ngOnInit(): void {

    this.tags = [];
    console.log(this.data, "ejjeje")



    if (this.router.url.includes('jobs') || this.data.isJob) {

      if (this.data.isUserSearchFromJob && this.data.isUser) {
        this.url = document.location.origin + '/'+ this.data.url;
      }

      if (this.data.isPage) {
        this.url = document.location.origin + '/'+ this.data.url + '/published/jobs/' + this.data.data._id;

      }
      else if (this.data.isUser && !this.data.isUserSearchFromJob) {
        this.url = document.location.origin + '/'+ this.data.url + '/publicated/jobs/' + this.data.data._id;
      }

    } else {
      if (this.data.isPage) {
        this.url = document.location.origin + '/'+ this.data.url + '/published/projects/' + this.data.data._id;

      } else if (this.data.isUser) {
        this.url = document.location.origin + '/'+ this.data.url + '/publicated/projects/' + this.data.data._id;
      }
    }
  }




}


export interface ShareData {
  isUser?: boolean,
  isPage?: boolean,
  data: any;
  url: string;
  isJob?: boolean;
  isUserSearchFromJob?: boolean;
}
