import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrivacyDialogComponent } from '../privacy-dialog/privacy-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/shared/interfaces/user.model';

@Component({
  selector: 'app-privacy-button',
  templateUrl: './privacy-button.component.html',
  styleUrls: ['./privacy-button.component.scss']
})
export class PrivacyButtonComponent implements OnInit {
  @Input() privacy: number;
  public icon: number;
  public text: any;

  constructor(public arrayService: ArraysService, private dialog: MatDialog) { }
  @Output() saveObject: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.arrayService.getPrivacyArray();
    this.privacyText = this.privacy;
    this.privacyIcon = this.privacy;
  }

  openPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        console.log(t, "after TTT")
        this.privacyIcon = t;
        this.privacyText = t;
        this.saveObject.emit(t);

      }


    } )
  }

  public set privacyText(privacy) {
    this.text = this.arrayService.privacyArray.find(t => t.id === privacy).description;

  }
  public get privacyText() {
    return this.text;
  }

  public set privacyIcon(privacy) {
    this.icon = this.arrayService.privacyArray.find(t => t.id === privacy).icon;
  }

  public get privacyIcon() {
    return this.icon;
  }
}
