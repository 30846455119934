import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Page, Project } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { ChangeDetectionService } from '../shared/services/change-detection.service';
import { PagesService } from '../shared/services/pages.service';
import { ProjectService } from '../shared/services/project.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';
import { UserJobDialog, UserJobDialogComponent } from '../user-job-dialog/user-job-dialog.component';
import { DataObject, DialogDataDetailsComponent } from '../components/dialog-data-details/dialog-data-details.component';

@Component({
  selector: 'app-message-right-side',
  templateUrl: './message-right-side.component.html',
  styleUrls: ['./message-right-side.component.scss']
})
export class MessageRightSideComponent implements OnInit, OnChanges {
  public data: Project;
  public page: Page;
  deleteUser: User;
  pageContacted: Page;

  public project: Project;
  public user: User;
  public userHasWritten: User;
  public userApplied: User;
  public jobManage;
  @Input() selectedIndex;

  cancelProject;
  cancelMessage;
  editorConfig = {
    padding: "0",
  }
  public config = {
    toolbar: false
  };




  constructor(public receiver: SenderService,
    private userService: UserService,
     private pageService: PagesService,
     private authService: AuthService,
     private cd: ChangeDetectorRef,
     private dialog: MatDialog,
     private router: Router,
     private projectService: ProjectService,
     private changeDetector: ChangeDetectionService
    ) { }
  ngOnChanges(changes: SimpleChanges): void {
   this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.receiver.dataMessageForProject.pipe().subscribe((success: any) => {

        if (!!success.data) {

          this.data = success.data;

          this.jobManage = this.data?.manage_.filter(t => t.userID === this.authService.userID)[0];

          let id =  this.data?.manage_?.find(t => t.userID === this.authService.userID)?.message?.userID;
          if (id) {
           this.userService.getUserContactData(id).pipe(take(1)).subscribe(tt => {
             this.userApplied = tt;
           })
          }
          if (!!this.jobManage?.message?.pageID) {
            this.pageService.getPage(this.jobManage?.message?.pageID).subscribe(resPage => {
              this.pageContacted = resPage.page;
            })
          }

          this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(tt => {
             this.userHasWritten = tt;
           })

          if (this.data?.pageID) {
           this.pageService.getPage(this.data?.pageID).pipe(take(1)).subscribe(resPage => {
             this.page = resPage.page;
           })
         } else if (this.data?.userID) {
           this.userService.getUserContactData(this.data?.userID).pipe(take(1)).subscribe(t => {
             this.user = t;
           })
         }
        }



    });
  }
  apply(userJob: Project) {
   this.data.manage_.find(t => t.userID === this.authService.userID).cancel.status = 1;
   this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
    this.user = res;
   })

   this.user?.appliedProjects.unshift({projectId: this.data._id, timestamp: new Date()})
   this.projectService.updateProject(this.data._id, this.data);
  this.userService.updateUser(this.user?._id, this?.user)


  }
  cancel() {
    this.cancelProject = true;
  }

  cancelProjectButton() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      userID: this.authService.userID
    }

    this.jobManage.cancel = dataForCancel;
    this.projectService.updateProject(this.data._id, this.data);
    this.cancelProject = false;
  }

  openProject(data: DataObject) {
    this.dialog.open(DialogDataDetailsComponent, {
      data,
    });
  }
  deleteChat() {

    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
      this.deleteUser = res;
      this.deleteUser.messages.splice(this.selectedIndex, 1);
      this.userService.updateUser(this.deleteUser._id, this.deleteUser)
      this.changeDetector.emitChanges(true);


    })

  }
}
