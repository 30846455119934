export class NodeNotifications {
  friendRequest: {
    push: boolean;
    mail: boolean;
    sms: boolean;
  }
  reminders: {
    all?: boolean;
    push?: boolean;
    mail?: boolean;
    sms?: boolean;
  }
  user: string;
}
