import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-deactivate-delete-account',
  templateUrl: './deactivate-delete-account.component.html',
  styleUrls: ['./deactivate-delete-account.component.scss']
})
export class DeactivateDeleteAccountComponent implements OnInit {
  page: Page
  user: User;

  constructor(private router: Router,
    private pageService: PagesService,
    private userService: UserService,
    private authService: AuthService,
    private translate: TranslateService) {

    if (this.router.getCurrentNavigation().extras.state.page) {
      this.page = this.router.getCurrentNavigation().extras.state.page;
    } else if (this.router.getCurrentNavigation().extras.state.user) {
      this.user = this.router.getCurrentNavigation().extras.state.user;
    }
  }

  ngOnInit(): void {
  }

  continue() {
    if (this.page) {
      this.pageService.updatePage(this.page._id, this.page)
    } else if (this.user) {
     this.userService.updateUser(this.user._id, this.user);
     this.authService.logout();
     const browserLang = this.translate.getBrowserLang();
     this.translate.use(browserLang);

    }
    this.router.navigateByUrl('');
  }

  deactivate() {
    if (!!this.page) {
      this.page.account.s = 1;
      this.page.account.deleted = false;
      this.page.account.startDate = new Date();
    } else if (this.user) {
      this.user.account.status = 1;
      this.user.account.deleted = false;
      this.user.account.startDate = new Date();
    }
  }

  delete() {
    if (this.page) {
      this.page.account.deleted = true;
      this.page.account.s = 1;
      this.page.account.startDate = new Date();
    } else if (this.user) {
      this.user.account.status = 1;
      this.user.account.deleted = false;
      this.user.account.startDate = new Date();
    }

  }
  cancel() {
    this.router.navigateByUrl(this.page.pageUrl + '/settings/close_account');
  }
}
