import {Component, Input, OnInit} from "@angular/core";
import {MatChipInputEvent} from "@angular/material/chips";
import {DriveLicense, SkillOffer} from "src/app/shared/interfaces/model";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: "app-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.scss"]
})
export class ChipsComponent implements OnInit {
  public visible = true;
  public selectable = true;
  @Input() public removable = false;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() chips: object[];
  @Input() placeholder = "";
  @Input() show = false;

  constructor() { }

  ngOnInit(): void {
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add skill
    if ((value || "").trim()) {
        this.chips.unshift({name: value.trim(), rate: 0});
    }
    if (input) {
      input.value = "";
    }
  }
  remove(skill: SkillOffer): void {
    const index = this.chips.indexOf(skill);
    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }

}
