import { timestamp } from 'rxjs/operators';
import { CareerLevel } from "../enums/enums-model";
import { JIssue } from "./jira.model";
import { CreditsHistory, Transactions } from "./user.model";
import { OpenMessageList } from './messageList.model';

export class Model {
  _id = "";
  personalData: PersonalData[];
  skills?: SkillsSub[];
  education?: Education;
  careers?: Carrier;
  files?: Files[];
  user = "";
  jobTitle = "";
  hideName: boolean;
  photo = "";
  showCVPhoto: boolean;
  pdfPhoto = "";
  emptyCategory?: EmptyCategory[];
  showPageNumbers: boolean;
  description = "";
  language: {
    primary?: string,
    secondary?: string
  };
  theme = "";
  profilePicture: string;
  coverPicture: string;
  userUrl = "";
  employmentType: string;
  style: {
    theme: string,
    color: string,
  }
  p?: number;
}

export interface Carrier {
  subCategories: CarrierSubCategory[];
  emptySubContents?: EmptySub[];
}

export interface EmptyCategory {
  name: string;
  emptySubContents?: EmptySub[];
}

export interface Files {
  subCategories: SubFiles[];
}
export interface SubFiles {
  id?: string;
  name: string;
  link: string;
  file?: string;
  description?: string;
}


export interface CarrierSubCategory {
  name?: string;
  position?: string;
  employmentType?: number;
  startDate?: Date;
  endDate?: Date;
  role?: string;
  description?: string;
  companyUrl?: string;
  employmentSize?: number;
  organization?: number;
  level?: number;
  activityField?: number;
  branch?: number;
  area?: number;
  today?: boolean;
  spaceBetween?: Space[];
  showCompanyUrl?: boolean;
  pageID?: string;
  url?: string;
  privacy: number;
}


export interface EducationSubCategory {
  name?: string;
  pageID?: string;
  startDate: Date;
  endDate: Date;
  graduation?: string;
  title?: string;
  description: string;
  today?: boolean;
  url?: string
  privacy: number;
  showCompanyUrl?: boolean;
}

export interface Skills {
  subCategories: SkillsSub[];
}
export interface SkillsSub {
  _id?: string;
  name?: string;
  description?: string;
  languages?: {
    privacy: number,
    data: Language[];
  }
  pcKnowledge?: {
    privacy: number,
    data: PCKnowledge[];
  },
  driveLicenses?: {
    privacy: number,
    data: DriveLicense[]
  };
  qualifications?: {
    privacy: number,
    data:Qualifications[]
  };
  skillsOffer?: {
    privacy: number,
    data: SkillOffer[]
  };
  emptySubContents?: EmptySub[];
}
export interface EmptySub {
  _id?: string;
  name: string;
  description: string;
  spacesBetween?: Space[];
}
export interface Qualifications {
  name: string;
  company?: string;
  issueDate?: Date;
  issueUrl?: string;
  issueID?: string;
}

export interface Language {
  name: string;
  rate: number;
  spacesBetween?: Space[];
}
export interface Space {
  description: string;
}

export interface SkillOffer {
  _id?: string;
  name: string;
  rate?: number;
  privacy?: number;
}
export interface Tasks {
  _id?: string;
  name: string;
  privacy?: number;

}
export interface DriveLicense {
  name: string;
  rate?: number;

}
export interface PCKnowledge {
  name: string;
  rate?: number;
  privacy?: number;

}
export interface SearchQualifications {
  name: string;
  company?: string;
  issueDate?: Date;
  issueUrl?: string;
  issueID?: string;
}

export interface SearchLanguage {
  name: string;
  rate: number;
  spacesBetween?: Space[];
}
export interface Space {
  description: string;
}

export interface SearchSkillOffer {
  _id?: string;
  name: string;
  rate?: number;
  privacy?: number;
}
export interface SearchDriveLicense {
  name: string;
  privacy?: number;
}
export interface SearchPCKnowledge {
  name: string;
  rate?: number;
  privacy?: number;
}


export interface SubCategory {
  _id?: string;
  name: string;
  startDate: number;
  endDate: number;
  role?: string;
  title?: string;
  description: string;
  user?: string;
  checked?: boolean;
  id?: string;
  tags?: [];
}


export interface PersonalData {
  _id: string;
  email: string;
  telephone: string;
  status: number;
  showStatus: boolean;
  employmentType: string;
  user: string;
  job: string;
  c: string;
  postalCode: number;
  street: string;
  city: string;
  state: string;
  showAddress: boolean;
  showBirthday: boolean;
  showNationality: boolean;
  showTelephone: boolean;
  showEmail: boolean;
  visible: boolean;
  emptySubContents: EmptySub[];
  cv_enum: number;
  phone?: Phone;
}

export class StateAddress {
  _id?: string;
  name?: string;
  zipCode: number | undefined;
  street?: string | undefined;
  city?: string | undefined;
  user?: string;
  c?: string;
  postalCode?: number;
  state?: string
}
export interface Education {
  subCategories: EducationSubCategory[];
}

export class UserSearch {
  searchLocation: {
    remote: number;
    data: UserSearchLocation[];
  };
  cv: Model;
  searchSkillsFilter: SearchSkillOffer[]
}


export interface UserJobs {
  jobId?: string;
  projectId?: string;
  jobID?: string;
  projectID?: string;
  timestamp?: Date;
  public?: {
    startDate?: Date;
    endDate?: Date;
  };
  c?: string;

}

export interface PageJobs {
  jobId?: string;
  jobID?: string;
  userID?: string;
  projectID?: string;
  timestamp?: Date;
  public?: {
    startDate?: Date;
    endDate?: Date;
  };
  c?: string;
}

export interface PageProjects {
  projectID?: string;
  userID?: string;
  timestamp?: Date;
  public?: {
    startDate?: Date;
    endDate?: Date;
  };
  c?: string;

}
export interface UserProjects {
  projectID: string;
  timestamp?: Date;
  public?: {
    startDate?: Date;
    endDate?: Date;
  };
  c?: string;
}
export interface UserSearchLocation {
  c?: string,
  postalCode?: string
  region?: string[];
}

export class UserPage {
  pageId?: string;
  timestamp?: Date;
  status?: number;
  open?: OpenMessageList[];
  searchLocation?: {
    remote: number;
    data: SearchLocation[];
  };
  branches?: string;
  categories?: Categories;
}


export class UserContact {
  contactId: string;
  pageId?: string;
  userID?: string;
  status?: number;
  timestamp?: Date;
}

export interface Image {
  _id: string;
  imageUrl: string;
  imageTitle: string;
  imageDesc: string;
  uploaded: Date;
}


export class Page {
  _id?: string;
  userID? = "";
  name? = "";
  slogan? = "";
  about? = "";
  url? = "";
  privacy?: boolean;
  telephone?: number;
  pageUrl?: string;
  showPageUrl?: boolean;
  website?: string;
  founded?: number;
  organization?: number;
  email?: [
    {
      p?: number;
      name: string
    }
  ];
  coverImage?: string;
  profileImage?: string;
  branches?: string;
  language?: {
    primary?: string,
    secondary?: string
  };
  offer?: {
    timestamp: Date,
    status: number
  }
  categories?: Categories;
  adsM?: {
    privacy: number,
    data?: AdsManagerArray[];
  }
  locations?: Location[];
  phone?: Phone[];
  userRole?: string;
  roles?: Roles;
  jobs?: {
    privacy: number;
    data: PageJobs[];
  }
  projects?: {
    data: PageProjects[],
    privacy: number
  }
  messages?: [
    {
      _id?: string;
      userId?: string;
      messageID?: string;
      timestamp: Date;
      jobId?: string;
      projectId?: string;
    }
  ];
  followers?: {
    privacy: number,
    counter: number
  };
  notifications?: {
    followers?: {
      counter?: number,
      seen?: boolean
    }
  };
  education?: [{
    userID?: string;
    startDate?: Date,
    endDate?: Date,
    today?: boolean,
    timestamp?: Date
  }];
  employers?: {
    privacy: number;
    counter: number
  }
  searchLocation?: {
    remote: number;
    data: SearchLocation[];
  };
  location?: {
    c?: string;
    street?: string
    city?: string
    state?: string;
    postalCode?: string;
  };
  payments?: string;
  payment?: {
    counter: number,
    Job: number;
    Project: number;
    coins: number;
    history: CreditsHistory[];
    transactions: Transactions[];
  };
  savedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  appliedProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  ignoredProjects?: [
    {
      projectId: string;
      timestamp: Date;
    }
  ];
  account?: {
    s?: number,
    startDate?: Date,
    endDate?: Date,
    deleted?: boolean
  }
}

export interface Incomes {
  s: string;
  budget: number;
  hour: number;
  month: number;
  year: number;
}
export class Job {
  _id?: string;
  incomes: Incomes;
  apply: {
    timestamp: Date,
    s: Number
  };
  privacy?: {
    s: number;
    timestamp: Date
  }
  positionTitle?: string;
  employmentType: number;
  street?: string;
  postalCode?: number;
  city?: string;
  c?: string;
  state?: string;
  pcKnowledge: PCKnowledge[];
  skillsOffer: SkillOffer[];
  driveLicense?: DriveLicense[];
  language?: Language[];
  qualifications?: Qualifications[];
  description?: string;
  duration?: string;
  currency?: string;
  remote?: number;
  startDate?: Date;
  userID?: string;
  pageID?: string;
  createDate: Date;
  public: {
    startDate: Date;
    endDate: Date;
  };
  manage_?: JIssue[] = [];
  freelancer?: string;
  company?: string;
}
export class Project {
  _id: string;
  incomes: {
    s?: string;
    budget?: number;
    hour?: number;
    month?: number;
    year?: number;
  }
  apply: {
    timestamp: Date,
    s: Number
  };
  privacy?: {
    s: number;
    timestamp: Date
  }
  positionTitle?: string;
  employmentType: string;
  street?: string;
  postalCode?: number;
  city?: string;
  c?: string;
  state?: string;
  pcKnowledge: PCKnowledge[];
  skillsOffer: SkillOffer[];
  tasks: Tasks[];
  driveLicense?: DriveLicense[];
  language?: Language[];
  qualifications?: Qualifications[];
  description?: string;
  duration?: string;
  currency?: string;
  remote?: number;
  company?: boolean;
  freelancer?: boolean;
  startDate?: Date;
  manage_?: JIssue[] = [];
  userID?: string;
  pageID?: string;
  public: {
    startDate: Date;
    endDate: Date;
  };
  receivedApplicationBy?: {
    selectedApplication: number;
    externalLink?: string
  }

}


export interface SearchLocation {
  c?: string,
  searchActive?: boolean;
  postalCode?: string
  region?: string[];
  remote?: boolean;
  available?: Date;
}
export class Roles {
  Owner?: PageRoles[];
  Admin?: PageRoles[];
  Advertiser?: PageRoles[];
  Analyst?: PageRoles[];
  Editor?: PageRoles[];
  Moderator?: PageRoles[];
  User?: PageRoles[];
  Jobs?: PageRoles[];
  Projects?: PageRoles[];
  Messages?: PageRoles[];
  Notifications?: PageRoles[];
  Payments?: PageRoles[];
  Marketing?: PageRoles[];
}

export class PageRoles {
  userID?: string;
  timestamp?: Date;
}

export class Group {
  _id = "";
  name = "";
  privacy: number;
  url = "";
  description = "";
  coverImg: string;
  profileImg: string;
  color = "";
  badges = "";
  affiliation = "";
  categories: Categories[];
}
export class Phone {
  number = "";
  countryCode = "";
  dialCode = "";
  e164Number = "";
  internationalNumber = "";
  nationalNumber = "";
  privacy: number;
}


export interface Branches {
  description: string
}
export interface Categories {
  name?: string;
  subCategory?: CategoryChildren[];
}

export class CategoryChildren {
  name?: string;
  keywords?: Keywords[]
}

export class Keywords {
  name?: string;
}
export class Location {
  c? = "";
  street? = "";
  city? = "";
  state? = "";
  postalCode? = "";
  region?: string[];
  headquarters?: boolean;
}

export class Contact {
  id: string;
  userId?: string;
  friendId?: string;
  status?: string;
}

export class Notification {
  _id?: string;
  id?: string;
  createdDate?: Date;
  description: string;
  userId: string;
  contactId: string;
  read: boolean;
  seen: boolean;
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export class AdsManagerArray {
  adsId?: string;
  timestamp?: Date;
  c?: string;
}

export class AdsManager {
  _id!: string;
  name: string;
  status: number;
  objective?: {
    websiteVisit?: boolean,
    brand?: boolean
  }
  type: number;
  pageId: string;
  userId: string;
  url: string;
  c: string;
  createdDate: Date;
  userAge: {
    all?: boolean;
    startDate?: Date;
    endDate?: Date;
  }
  gender?: {
    all?: boolean;
    data?: number;
  };
  displayFormat: boolean;
  category?: {
    all?: boolean,
    data?: AdsCategory[]
  }
  employmentType?: {
    all?: boolean;
    data?: EmploymentTypeArray[];
  }
  branch?: {
    all?: boolean;
    data?: AdsBranch[];
  }
  audienceAll: boolean;
  skills: {
    all?: boolean;
    data?: SkillOffer[];
  }
  activityField?: {
    all?: boolean;
    data?: ActivityFieldArray[];
  }
  postalCode? = "";
  region?: {
    all?: boolean;
    data?: string[];
  }
  budgetPlan?: {
    form?: number;
    value?: number;
    startDate?: Date;
    endDate?: Date;
    budget?: number;
    active?: boolean
  }
  adsDetails: {
    name?: '';
    description?: '';
    single?: boolean,
    url?: '';
    title?: '';
    sharedDescription?: '';
    buttonName?: string;
    selectedButton?: number,
    text?: '',
    selectedCheckboxSingleImg?: boolean;
    cards?: ImageCards[];
  }
}


export interface ImageCards {
  headline: string;
  url: string;
  description: string;
  buttonName: string;
  selectedButton: number;
}

export interface City {
  name: string;
}

export interface AdsCategory {
  name?: string;
}

export interface AdsEmployment {
  name?: number
}

export interface AdsBranch {
  name?: string;
}
export interface ActivityFieldArray {
  name?: number;
}
export interface EmploymentTypeArray {
  name?: number
}
