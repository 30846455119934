<mat-card class="example-card me-4 ms-4">
  <mat-card-header>
    <mat-card-title>{{name}} Credits</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <button class="btn btn-primary">{{data}}</button>
      <br>
      <button (click)="buyCoins()" class="btn btn-secondary mt-3">Buy</button>
      <br>
    </div>
  </mat-card-content>
</mat-card>
