<div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2" *ngIf="followers">
  <!-- Date -->
  <a class="mb-0" (click)="goToObj(user)">
    <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
    {{user?.firstName}} {{user?.lastName}}
  </a>
  <button *ngIf="!checkUser && !hasFollow && !isPendingFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="followUser(user)"> <i class="bi bi-plus pe-1"></i>
    {{'follow' | translate}}
  </button>
  <button *ngIf="isPendingFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUser(user)"> <i class="bi bi-clock-history pe-1"></i>
    {{'request' | translate}}
  </button>
  <button *ngIf="!checkUser && hasFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUser(user)"> <i class="bi bi-check2 pe-1"></i>
    {{'following' | translate}}
  </button>


  <div class="dropdown ms-auto">
    <!-- Card share action menu -->
    <a class="nav nav-link text-secondary mb-0"  id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-three-dots"></i>
    </a>
    <!-- Card share action dropdown menu -->
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
      <li  (click)="removeUser(user)" ><a class="dropdown-item" > <i class="bi bi-pencil-square fa-fw pe-2"></i>Remove</a></li>
      <li (click)="blockUser(user)"><a class="dropdown-item" > <i class="bi bi-lock fa-fw pe-2"></i>Block</a></li>
      <li><a class="dropdown-item" > <i class="bi bi-chat-left-dots pe-2"></i>Report</a></li>

    </ul>
  </div>
</div>

<!-- Start of following-->


<div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2" *ngIf="following">

  <!-- Date -->
  <a class="mb-0" *ngIf="!!userFollowing?.userId"  (click)="goToUser(user)">
    <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
    {{user?.firstName}} {{user?.lastName}}
  </a>
  <a class="mb-0" *ngIf="!!userFollowing?.pageId"  (click)="goToUser(user)">
    <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
    {{page?.name}}
  </a>
  <button *ngIf="!checkUser && !hasFollow && !isPendingFollow && !!userFollowing?.pageId" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="followPage(page)"> <i class="bi bi-plus pe-1"></i>
    {{'follow' | translate}}
  </button>
  <button *ngIf="!checkUser && !hasFollow && !isPendingFollow && !!userFollowing?.userId" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="followUser(userFollowing)"> <i class="bi bi-plus pe-1"></i>
    {{'follow' | translate}}
  </button>
  <button *ngIf="isPendingFollow && !!userFollowing?.userId" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUser(userFollowing)"> <i class="bi bi-clock-history pe-1"></i>
    {{'request' | translate}}
  </button>
  <button *ngIf="isPendingFollow && !!userFollowing?.pageId " class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowPage(page)"> <i class="bi bi-clock-history pe-1"></i>
    {{'request' | translate}}
  </button>



  <button *ngIf="!checkUser && hasFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUser(userFollowing)"> <i class="bi bi-check2 pe-1"></i>
    {{'following' | translate}}
  </button>


  <!-- End of Following -->


  <div class="dropdown ms-auto">
    <!-- Card share action menu -->
    <a class="nav nav-link text-secondary mb-0"  id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-three-dots"></i>
    </a>
    <!-- Card share action dropdown menu -->
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
      <li (click)="blockUser(user)"><a class="dropdown-item" > <i class="bi bi-lock fa-fw pe-2"></i>Block</a></li>
      <li><a class="dropdown-item" >  <i class="bi bi-chat-left-dots pe-2"></i>Report</a></li>

    </ul>
  </div>
</div>

<div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2" *ngIf="contacts">
  <!-- Date -->
  <a class="mb-0"  (click)="goToUser(userContact)">
    <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
    {{userContact?.firstName}} {{userContact?.lastName}}
  </a>
  <a class="mb-0" *ngIf="!!userContact?.pageId"  (click)="goToUser(userContact)">
    <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
    {{page?.name}}
  </a>
  <button *ngIf="!checkUser && !hasFollow && !isPendingFollow && !!userContact?.pageId" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="followPage(page)"> <i class="bi bi-plus pe-1"></i>
    {{'follow' | translate}}
  </button>
  <button *ngIf="!checkUser && !hasFollow && !isPendingFollow && !!userContact?.userId" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="followUser(user)"> <i class="bi bi-plus pe-1"></i>
    {{'follow' | translate}}
  </button>
  <button *ngIf="isPendingFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUser(user)"> <i class="bi bi-clock-history pe-1"></i>
    {{'request' | translate}}
  </button>
  <button *ngIf="!checkUser &&  hasFollow" class="btn btn-primary-soft ms-2 btn-sm" type="button" (click)="unfollowUserContact(user)"> <i class="bi bi-check2 pe-1"></i>
    {{'following' | translate}}
  </button>


  <div class="dropdown ms-auto">
    <!-- Card share action menu -->
    <a class="nav nav-link text-secondary mb-0"  id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="bi bi-three-dots"></i>
    </a>
    <!-- Card share action dropdown menu -->
    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
      <li><a class="dropdown-item" (click)="removeUser(userFollowing)" > <i class="bi bi-trash fa-fw pe-2"></i>Remove</a></li>
      <li (click)="blockUser(userFollowing)"><a class="dropdown-item" > <i class="bi bi-lock fa-fw pe-2"></i>Block</a></li>
      <li><a class="dropdown-item" >  <i class="bi bi-chat-left-dots pe-2"></i>Report</a></li>

    </ul>
  </div>
</div>



