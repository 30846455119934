import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { AuthService } from '../shared/services/auth.service';
import { User } from '../shared/interfaces/user.model';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteUserBlockedGuard {
  private user: User;

  constructor(
    private userService: UserService,
    private authUser: AuthService,
    private router: Router) {
  }


  canActivate() {
    return this.userService.getUserContactData(this.authUser.userID).pipe(map(res => {
      this.user = res;
      if (this.user.account.status === 0) {
        this.router.navigateByUrl(this.user.userUrl + '/start')
        return false;
      } else {
        return true;
      }
    }));
  }
}
