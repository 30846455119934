import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Incomes, Links, Status, StatusPrivacy } from "src/app/shared/enums/enums-model";
import { Job, Page, Project } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { CountryDataService } from "src/app/shared/services/country-data.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import { ShareDialogComponent } from "../modals/share-dialog/share-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { SenderService } from "src/app/shared/services/sender.service";
import { IssueStatus, JIssue } from "src/app/shared/interfaces/jira.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { ProjectService } from "src/app/shared/services/project.service";
import { JobsService } from "src/app/shared/services/jobs.service";
import { EditDetailsData, EditJobsDialogComponent } from "../jobs/edit-jobs-dialog/edit-jobs-dialog.component";
import { Observable } from "rxjs";
import { PrivacyDialogComponent } from "../privacy-dialog/privacy-dialog.component";

@Component({
  selector: "app-data-item-details",
  templateUrl: "./data-item-details.component.html",
  styleUrls: ["./data-item-details.component.scss"],
})
export class DataItemDetailsComponent implements OnInit {
  public remote = "";
  public dataDuration: "";
  public countryJsonObject;
  public countryJsonArray;
  public paymentIDForIncomes;
  public salaryStatus;
  public currencyObject;
  public userCreatedObject: User;
  public page: Page;
  public selectedObject: boolean = false;
  public openMoreInfo: boolean = false;
  public contactPageForm: boolean = false;
  @Input() public languages = [];
  public userRemote;
  public countryJsonObjectForUser;
  applyForm: boolean = false;
  cancelObj: boolean = false;
  description = "";
  cancelDescription = "";
  writeMessage = "";
  contactMessage = "";
  messageSelected: boolean = false;
  showButtonsForSave: boolean = false;

  userEM: any | User;

  employmentTypeString = "";

  selectedUser;
  selectedPageID;
  hasAppliedToProject: boolean = false;


  public config = {
    toolbar: false,
  };
  editorConfig = {
    padding: "0",
  };

  public arrayOfApplication = [
    {id: Links.Internal, description:"pagesInfo.intern"},
    {id:  Links.External, description:"pagesInfo.externalLink"}
  ]

  selectedFirstGroup: boolean;
  selectedSecondGroup: boolean;
  selectedQualifications: boolean;
  selectedDriveLicense: boolean;
  selectedLanguage: boolean;
  selectedPCKnowledge: boolean;
  selectedTasks: boolean;
  selectedSkillsOffer: boolean;
  selectedDescription: boolean;

  @Input() userObj: any;
  @Input() data: any;
  @Input() user: User | any;
  @Input() employmentTypeInput: boolean;
  @Input() showSkills: boolean = false;
  @Input() isJob: boolean = false;
  @Input() showUser: boolean = false;
  @Input() published: boolean = false;
  @Input() isUserSearch: boolean = false;
  @Input() projectSearchUser: boolean = false;
  @Input() jobSearchUser: boolean = false;
  @Input() showForApply: boolean = false;
  @Input() hasApplied: boolean = false;
  @Input() isSaved: boolean = false;
  @Input() isIgnored: boolean = false;
  @Input() isApplied: boolean = false;
  @Input() myObject: boolean = false;
  @Input() isObjectSearch: boolean = false;
  @Input() showImage: boolean;
  @Input() advertisment: boolean = false;
  @Input() applyFormAdvertisment: boolean = false;
  @Input() advertismentHeader: boolean = false;
  @Input() applyFormAdvertismentHeader: boolean = false;
  @Input() showUserMessage: boolean = false;
  @Input() isProjectSearch: boolean = false;
  @Input() showForApplied: boolean = false;
  @Input() appliedProjectUser: boolean = false;
  @Input() isPrivacy: boolean = false;
  @Input() isUser: boolean = false;

  @Input() isApliedMessage: boolean = false;
  @Input() isPage: boolean = false;


  countryJson;
  userSearch: any;
  @Input() isProject: boolean = false;

  @Output() saveObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() unsaveObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() ignoreObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() unIgnoreObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchObjectEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToAppliedData: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelApply: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToMessages: EventEmitter<any> = new EventEmitter<any>();
  @Output() publicObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeObjFromPublic: EventEmitter<any> = new EventEmitter<any>();
  @Output() showOnlySelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendContact: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDataTasks: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDataPCKnowledge: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDataLanguage: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDriveLicense: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDataDescription: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitSkillsOffer: EventEmitter<any> = new EventEmitter<any>();
  @Output() emmitDeleteProjectFreelancer: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendMessageEmmit: EventEmitter<any> = new EventEmitter<any>();



public dataList$: Observable<any>;


  public userApplied: User;


  arrayPages;
  loggedUser$: Observable<any>;
  loggedUser: any;
  public icon;
  public text;
  public iconOffer;
  public textOffer;

  externalLink: string;
  showExternalApplicationInput: boolean = false;

  public privacyArray = [
    { id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone' },
    { id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts' },
    { id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text: 'Your Followers' },
    { id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '' }
  ]
  constructor(
    private arrSv: ArraysService,
    private countryDataService: CountryDataService,
    private userService: UserService,
    private pageService: PagesService,
    private http: HttpClient,
    private dialog: MatDialog,
    public router: Router,
    public sendData: SenderService,
    public userAuth: AuthService,
    public projectService: ProjectService,
    public jobService: JobsService,
    private titleService: ArraysService,
    public senderService: SenderService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJson = res);
    this.titleService.getPrivacyArray();

    if (this.data?.receivedApplicationBy?.externalLink) {
      this.externalLink = this.data.receivedApplicationBy.externalLink;
      this.showExternalApplicationInput = true;
    }


    this.titleService.getOfferArrayProject();

    if (!!this.user) {
      this.http
        .get<any>(
          `assets/languages/${this.user?.language.primary}/language.json`
        )
        .subscribe((response) => {
          this.languages = response;

          this.languages = Object.keys(this.languages).map((key) => ({
            type: key,
            value: this.languages[key],
          }));
        });
    }

    if (!!this.projectSearchUser) {
      this.userService.getUserCV(this.data._id).subscribe();
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((result: any) => {
        this.userSearch = result;
    })
    }

    if (!!this.jobSearchUser) {
      if (this.data) {
        this.userService.getUserCV(this.data?._id).subscribe();
        this.dataList$ = this.userService.dataList;
        this.dataList$.subscribe((result: any) => {
          this.userSearch = result;
        })

      }
    }

    this.arrSv.showRemote();
    this.arrSv.showSalary();
    this.arrSv.showJobDuration();
    this.arrSv.showEmploymentTypes();
    let id = this.data?.manage_?.find(t => t.message.userID)?.message.userID;
    if (id) {
      this.userService.getUserContactData(id).subscribe(tt => {
        this.userApplied = tt;
      })
    }


    this.countryDataService.countryData$
      .pipe()
      .subscribe((res) => (this.countryJsonArray = res));
    this.getRemoteID();
    this.getCurrencyID();
    this.getCountryID();
    this.getJoDurationID();
    this.getUserRemoteID();
    this.getCountryOfUser();

    if (this.isUserSearch) {
      this.data = this.userObj;
    }

    this.loggedUser$ = this.userService.userList;
    this.loggedUser$.subscribe(res => {
      this.user = res.user;
      this.getPaymentFormIDForIncomes(res.userUser?.incomes?.s);
      if (this.isJob) {
        this.incomes(res.userUser?.incomes.s, this.data)
      } else {
        this.incomes(this.data.incomes.s, this.data)
      }
      this.loggedUser = res;

      if (!this.isJob && !this.isUser) {
        this.privacyIcon = this.data;
        this.privacyText = this.data;
        this.privacyTextOffer = this.data;
        this.privacyIconOffer = this.data;
      }

    })


    this.userService.getUserEM(this.user._id).subscribe(res => {
      this.userEM = res;

      this.getPaymentFormIDForIncomes(this.userEM?.user);
      this.getCurrencyID(this.userEM?.user);

      if (!this.isJob) {
        if (this.userObj?.user?.incomes.s) {
          this.incomes(this.userObj?.user?.incomes?.s, this.data?.user);
        } else {
          this.incomes(this.userEM.user?.incomes?.s, this.data);
        }

      } else {
        this.incomes(this.userEM.user?.incomes?.s, this.userEM.user)

      }

    })




   /* if (this.isJob && this.userObj?.cv) {
      this.getEmploymentTypeID(this.userObj.cv.data[0].employmentType)
    } else if (this.isJob) {
      this.getEmploymentTypeID(this.data.employmentType)
    } */

    if (!!this.data?.pageID) {
      this.pageService.getPage(this.data?.pageID).subscribe((t) => {
        this.page = t.page;
      });
    } else if (!this.data?.pageID) {
      if (!!this.data?.userID) {
        this.userService
          .getUserContactData(this.data?.userID)
          .subscribe((t) => {
            this.userCreatedObject = t;
          });
      }
    }
    if (this.isUserSearch && this.userObj && this.isUserSearch) {
      this.getUserRemoteIDForUserSearch();
    }


    /* this.pageService.getMyPagesOfProject().subscribe(t => {
       this.arrayPages = t;
     }) */
  }

  selectFirstGroup() {
    this.selectedFirstGroup = true;
  }
  selectSecondGroup() {
    this.selectedSecondGroup = true;
  }
  selectTasks() {
    this.selectedTasks = true;
  }
  selectPCKnowledge() {
    this.selectedPCKnowledge = true;
  }
  selectSkillsOffer() {
    this.selectedSkillsOffer = true;
  }
  selectQualifications() {
    this.selectedQualifications = true;
  }
  selectDriveLicense() {
    this.selectedDriveLicense = true;
  }
  selectLanguage() {
    this.selectedLanguage = true;
  }
  selectDescription() {
    this.selectedDescription = true;
  }

  removeClick() {
    this.selectedQualifications = false;
    this.selectedDriveLicense = false;
    this.selectedLanguage = false;
    this.selectedPCKnowledge = false;
    this.selectedTasks = false;
    this.selectedSecondGroup = false;
    this.selectedFirstGroup = false;
    this.selectedSkillsOffer = false;
    this.selectedDescription = false;
  }


  editFirstGroup(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {

          this.data.positionTitle = res.positionTitle;
          this.data.employmentType = res.employmentType;
          this.data.remote = res.remote;
          this.data.street = res.street;
          this.data.postalCode = res.postalCode;
          this.data.city = res.city;
          this.data.country = res.c;
          this.data.state = res.state;
          this.getCountryID();
          this.jobService.updateJob(this.data._id, this.data)
        }
      });
  }

  editSecondGroup(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res: any) => {
        if (res) {
          this.data.incomes.budget = res.job.incomes.budget;
          this.data.incomes.status = res.job.incomes.s;
          this.data.incomes.budget = res.job.incomes.budget;
          this.data.incomes.s = res.job.incomes.s;
          this.data.currency = res.job.currency;

          this.data.currency = res.currency;
          // this.getCurrencyID(this.data.currency);
          this.jobService.updateJob(this.data._id, this.data);

          //this.dataService.updateJob(this.data._id, this.data);
        }
      });
  }


  getRemoteID() {

    if (this.data.remote) {
      this.remote = this.arrSv.remoteArray.find(
        (res) => res.id === this.data?.remote
      )?.description;
    } else {
      this.remote = this.arrSv.remoteArray.find(
        (res) => res.id === this.data.searchLocation?.remote
      )?.description;
    }

  }
  getCountryID() {
    if (!!this.data.c) {
      this.countryJsonObject = this.countryJsonArray.find(
        (t) => t.ISO_2 === this.data?.c
      );
    } else if (!!this.data.user) {
      this.countryJsonObject = this.countryJsonArray.find(
        (t) => t.ISO_2 === this.data?.user.address.data[0].c
      );
    } else {
      this.countryJsonObject = this.countryJsonArray.find(
        (t) => t.ISO_2 === this.data?.address.data[0].c
      );
    }

  }

  getPaymentFormIDForIncomes(user?: any) {
    if (this.isJob) {
      this.paymentIDForIncomes = this.arrSv.salary.find(
        (t) => t.id === this.data?.incomes?.s
      )?.description;

    } else {
      if (!!user) {
        this.paymentIDForIncomes = this.arrSv.salary.find(
          (t) => t.id === user?.incomes?.s
        )?.description;

      } else {
        if (this.userObj?.user?.incomes?.s) {
          this.paymentIDForIncomes = this.arrSv.salary.find(
            (t) => t.id === this.userObj?.user?.incomes?.s
          )?.description;
        } else {
          this.paymentIDForIncomes = this.arrSv.salary.find(
            (t) => t.id === this.user?.incomes?.s
          )?.description;
        }
      }
    }


  }

  getCurrencyID(userEM?: any) {

    if (!!userEM) {
      this.currencyObject = this.countryJsonArray.find(
        (t) => t.currency.Alphabeticcode === this.userEM?.incomes?.currency
      );
    } else {
      if (this.data.userUser) {
        this.currencyObject = this.countryJsonArray.find(
          (t) => t.currency.Alphabeticcode === this.data?.userUser.incomes.currency
        );
      } else {
        this.currencyObject = this.countryJsonArray.find(
          (t) => t.currency.Alphabeticcode === this.data?.incomes?.currency
        );
      }
    }



  }

  getJoDurationID() {
    this.dataDuration = this.arrSv.jobDurationArray.find(
      (t) => t.id === this.data?.duration
    )?.description;
  }

  getEmploymentTypeID(id) {
    this.employmentTypeString = this.arrSv.employmentType.find(t => t.id === id).description;
  }

  searchObject(id) {
    this.searchObjectEvent.emit(id);
  }

  apply(id) {
    this.applyObject.emit({ data: id, description: this.description });
    this.description = "";
    this.applyForm = false;
  }
  changeApplication(event: number) {
    if (!this.data.receivedApplicationBy) {
      this.data.receivedApplicationBy = {
        selectedApplication: event,
        externalLink: this.externalLink
      }
    } else {
      this.data.receivedApplicationBy.selectedApplication = event;
    }
    this.data.receivedApplicationBy.selectedApplication = event;
    this.cd.detectChanges();
  }

  applyForPageProject(id) {
    this.applyObject.emit(id);
    this.description = "";
    this.applyForm = false;
  }

  sendPageContactForm() {
    this.sendContact.emit({ data: this.data, contactMessage: this.contactMessage });
    this.contactMessage = "";
    this.contactPageForm = false;

  }

  saveObj(id) {
    this.saveObject.emit(id);
  }

  unsaveObj(id) {
    this.unsaveObject.emit(id);
  }

  cancel(id) {
    this.cancelObj = true;
  }
  cancelObjForm() {
    this.cancelObj = false;
    this.cancelDescription = "";
  }

  publicObj() {
    this.publicObject.emit();
  }

  removeFromPublic() {
    this.removeObjFromPublic.emit();
  }
  saveCancelObj(id) {
    this.cancelApply.emit({ data: id, description: this.cancelDescription });
    this.cancelObj = false;
    this.cancelDescription = "";
  }

  ignoreObj(id) {
    this.ignoreObject.emit(id);
  }

  unignoreObj(id) {
    this.unIgnoreObject.emit(id);
  }

  goToApplied() {
    this.goToAppliedData.emit();
  }

  message(job) {
    this.messageSelected = !this.messageSelected;
    //this.goToMessages.emit();
  }

  manageObject() {
    this.showOnlySelected.emit();
    this.selectedObject = true;
  }
  closeManageObject() {
    this.selectedObject = false;
  }
  moreInfo(jobID) {
    this.openMoreInfo = true;
  }
  lessInfo() {
    this.openMoreInfo = false;
  }
  public getLanguageName(type) {
    return this.languages.find((t) => t.type === type);
  }

  applyForForm() {
    this.applyFormAdvertisment = true;
  }


  contactPage() {
    this.contactPageForm = true;
  }
  test(event) {
    if (event?.isUser) {
      this.selectedUser = true;
      this.hasAppliedToProject = this.data.manage_.find(t => t.userId === this.user._id)?.userId === this.user._id;
      if (this.hasAppliedToProject) {
        // this.showDateApplied;
      }


    } else {
      this.selectedUser = false;
      this.selectedPageID = event;
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
        this.hasAppliedToProject = this.data.manage_.find(t => t.pageId === this.page._id)?.pageId === this.page._id;

        //  this.showDateApplied
      });
    }
  }


  cancelPageContact() {
    this.contactPageForm = false;
  }

  public get isUserJob() {
    return this.data?.userID === this.userAuth.userID;

  }

  public incomes(id, job) {
    if (id === Incomes.month) {
      this.salaryStatus = job?.incomes?.month.toFixed(0);
    } else if (id === Incomes.year) {
      this.salaryStatus = job?.incomes?.year.toFixed(0);
    } else if (id === Incomes.hour) {
      this.salaryStatus = Math.trunc(job?.incomes?.hour);
    }


  }
  getUserRemoteID() {
    this.userRemote = this.arrSv.remoteArray.find(
      (test) => test.id === this.data?.searchLocation?.remote
    )?.description;
  }
  getUserRemoteIDForUserSearch() {
    return this.userRemote = this.arrSv.remoteArray.find(
      (test) => test.id === this.userObj?.user?.searchLocation?.remote
    )?.description;

  }

  selectCheck() {
    this.showButtonsForSave = true;
  }



  getCountryOfUser() {
    this.countryJsonObjectForUser = this.countryJson.find(
      (t) => t.ISO_2 === this.userObj?.user?.address?.data[0]?.c
    );
  }


  openShare(data) {
    this.dialog.open(ShareDialogComponent, {
      data
    });
  }

  preventDefault(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

  }

  goToUser() {
    this.router.navigate([this.userCreatedObject.userUrl, "start"]);
    localStorage.setItem("userProfileID", this.userCreatedObject._id);
  }

  goToPage() {
    localStorage.setItem("pageID", this.page._id)
    this.pageService.changeMessage(this.page._id);
    this.sendData.send(this.page);
    this.router.navigate([this.page.pageUrl]);

  }

  applyForAdr() {
    this.applyForm = true;
  }

  applyForAdvertisment() {
    if (this.checkForApplyFreelancer) {
      this.applyFormAdvertisment = true;
    } else if (this.checkForApplyCompany) {
      this.applyFormAdvertisment = true;
    } else if (!this.checkForApplyCompany) {
      this.applyFormAdvertisment = false;
    } else if (!this.checkForApplyFreelancerNoPage) {
      this.applyFormAdvertisment = false;
    }
  }

  cancelApplyForm() {
    this.applyForm = false;
    this.applyFormAdvertisment = false;
  }

  public get checkForApplyFreelancer() {
    return this.data.freelancer;
  }

  public get checkForApplyCompany() {
    return !this.data.freelancer && this.user.pages.length > 0
  }
  public get checkForApplyFreelancerNoPage() {
    return !this.data.freelancer && this.user.pages.length === 0;
  }

  public get checkForFreelancerAndCompany() {
    return this.data.freelancer && this.data.company && this.user.pages.length === 0
  }

  applyForProject() {
    let jIssue: JIssue;
    if (this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.userAuth.userID,
          description: this.description,
        },
        userID: this.userAuth.userID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.data.manage_.unshift(jIssue);
      this.user.messages.unshift({ projectID: this.data._id, timestamp: new Date() });
      this.user.appliedProjects.unshift({ projectId: this.data._id, timestamp: new Date() });


      this.userService.updateUser(this.user._id, this.user);
      this.projectService.updateProject(this.data._id, this.data);

      //  this.router.navigateByUrl("/" + this.user.userUrl + "/projects")


    } else if (!this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.userAuth.userID,
          pageID: this.selectedPageID,
          description: this.description
        },
        userID: this.userAuth.userID,
        pageID: this.selectedPageID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.data.manage_.unshift(jIssue);
      this.pageService.getPage(this.selectedPageID).subscribe(res => {

        this.page = res;
        if (this.page.appliedProjects) {
          this.page.appliedProjects.unshift({ projectId: this.data._id, timestamp: new Date() })
        } else if (!this.page.appliedProjects) {
          this.page.appliedProjects = [] as any;
          this.page.appliedProjects.unshift({ projectId: this.data._id, timestamp: new Date() })
        }

        if (this.page.messages) {
          this.page.messages.unshift({ projectId: this.data._id, timestamp: new Date() });
        } else {
          this.page.messages = [] as any;
          this.page.messages.unshift({ projectId: this.data._id, timestamp: new Date() });

        }

        this.pageService.updatePage(this.page._id, this.page);
        this.projectService.updateProject(this.data._id, this.data);
        this.router.navigateByUrl("/" + this.page.pageUrl + "/projects/ourprojects")


      })
    }
    this.applyFormAdvertisment = false;
  }


  sendMessage(jobManage) {
    this.data.manage_[0].comments.unshift({
      createdAt: new Date().toISOString(),
      body: this.writeMessage,
      userId: this.userAuth.userID
    });

    if (this.appliedProjectUser) {
      this.userService.getProjectList(this.userAuth.userID).subscribe(res => {
        const projectData = res[0].projects.findIndex(r => r.projectID === this.data._id);

        if (res[0].projects.length > 1) {
          this.arraymove(res[0].projects, projectData, 0);
        }
        this.projectService.updateProjectList(res[0]._id, res[0]);
        this.projectService.updateProject(this.data._id, this.data);
      })
    } else {
      const findIndex = this.user?.messages?.findIndex(t => t?.jobID === this.data._id);
      this.arraymove(this.user.messages, findIndex, 0);
      this.userService.updateUser(this.user._id, this.user);
      this.jobService.updateJob(this.data._id, this.data)
    }
    this.writeMessage = "";
  }



  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  public set privacyIcon(project: any | Project | Job) {
    if (!!project.privacy) {
      this.icon = this.privacyArray.find(t => t.id === project.privacy.s).icon;
    }
  }

  public get privacyIcon() {
    return this.icon;
  }

  public set privacyText(project: any | Project | Job) {
    if (!!project.privacy) {
      this.text = this.privacyArray.find(t => t.id === project.privacy.s).description;

    }
  }

  public get privacyText() {
    return this.text;
  }

  public set privacyTextOffer(project: Project | Job | any) {
    if (!!project.apply) {
      this.textOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).text;
    }
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(project: Project | Job | any) {
    if (!!project.apply) {
      this.iconOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).icon;
    }
  }

  public get privacyIconOffer() {
    return this.iconOffer;
  }

  openPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.data._id) {
          this.data.privacy.s = t;
          this.privacyIcon = this.data;
          this.privacyText = this.data;
          this.data.privacy.timestamp = new Date();
          this.projectService.updateProject(this.data._id, this.data);

        }
      }
    })
  }

  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.data._id) {
          this.data.apply.s = t;
          this.privacyIconOffer = this.data;
          this.privacyTextOffer = this.data;
          this.data.apply.timestamp = new Date();
          this.projectService.updateProject(this.data._id, this.data);

        }
      }
    })
  }

  editTasks(data: EditDetailsData) {

    this.emmitDataTasks.next(data);
    /* if (res.jobSkillsOffer) {
        this.job.skillsOffer = res.data.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      } */
  }

  editPCKnowledge(data: EditDetailsData) {
    this.emmitDataPCKnowledge.next(data);
  }
  editLanguage(data) {
    this.emmitDataLanguage.next(data);
  }

  editDriveLicense(data) {
    this.emmitDriveLicense.next(data);

  }

  editDescription(data) {
    this.emmitDataDescription.next(data);
  }

  editSkillsOffer(data) {
    this.emmitSkillsOffer.next(data);
  }

  deleteProjectFreelancer(data) {
    this.emmitDeleteProjectFreelancer.next(data);

  }


  goToData(data: any) {
    if (!this.isPage) {
      this.senderService.dataManageJobID({ ID: this.data._id, isJob: true });
      this.router.navigateByUrl(this.router.url + "/" + data._id + "/manage");
    } else {
      this.senderService.dataManageJobID({ ID: this.data._id, isJob: true });
      const url = this.router.url.replace("ourprojects", "");
      this.router.navigateByUrl(url + "/" + data._id + "/manage");

    }
  }



}
