import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-smart-payments-table',
  templateUrl: './smart-payments-table.component.html',
  styleUrls: ['./smart-payments-table.component.scss']
})
export class SmartPaymentsTableComponent implements OnInit {


  @Input() firstTd!: string;
  @Input() secondTd!: string;
  @Input() thirdTd!: string;
  @Input() fourthTD!: string;
  @Input() fifthTD!: string;
  @Input() sixthTD!: string;


  @Input() data!: any;


  @Input() reference: boolean = false;
  @Input() status: boolean = false;
  @Output() print: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
    console.log(this.data)
  }

  goForPrint(data) {
    this.print.emit(data);
  }

}
