import {Component, Inject, OnInit} from "@angular/core";
import { Model, PersonalData, Location, UserSearchLocation} from "../../../shared/interfaces/model";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import {Groups, MaritualStatus, Status, StatusTitle, Title} from "../../../shared/enums/enums-model";
import { SearchCountryField, CountryISO } from "ngx-intl-tel-input";
import { NgForm } from "@angular/forms";
import { AuthService } from 'src/app/shared/services/auth.service';
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { PrivacyActivity } from "src/app/shared/services/privacy-activity.service";
import { EditPrivacyDialog, PrivacyDialogComponent } from "../../privacy-dialog/privacy-dialog.component";
import * as QuillNamespace from 'quill';
let Quill: any = QuillNamespace;
import ImageResize from 'quill-image-resize-module';
import { UserService } from "src/app/shared/services/user.service";
import { HttpClient } from "@angular/common/http";
import { User } from "src/app/shared/interfaces/user.model";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { CountryDataService } from "src/app/shared/services/country-data.service";
Quill.register('modules/imageResize', ImageResize);
@Component({
  selector: "app-personalized-data-dialog",
  templateUrl: "./personalized-data-dialog.component.html",
  styleUrls: ["./personalized-data-dialog.component.scss"]
})
export class PersonalizedDataDialogComponent implements OnInit {

  public genders = [
    { id: StatusTitle.Mr, description: "data.mr"},
    { id: StatusTitle.Mrs, description: "data.mrs"},
  ];
  public statusObject = [
    {id: 1, name: MaritualStatus.Single, description: "status.single"},
    {id: 2, name: MaritualStatus.Married, description: "status.married"},
    {id: 0, name: MaritualStatus.NotKnow, description: "status.nk"}
  ];
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public empty;
  public user: User;
  public model;
  public locations = new Location();
  selectedCountry: any;
  languages = [];


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditPersonalizedData,
              private dialogRef: MatDialogRef<EditPersonalizedData>,
              public authService: AuthService,
              public dialog: MatDialog,
              public privacyActivity: PrivacyActivity,
              private userService: UserService,
              public http: HttpClient,
              public titleService: ArraysService,
              private countryDataService: CountryDataService

              ) {

    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.titleService.showTitles();
    this.http.get<any>(`assets/languages/${this.data.model?.language?.primary}/country.json`).subscribe(data => {
      this.languages = data;
      this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

    });
    if (!this.data.edit) {
      this.data.personalData = {} as PersonalData;
    } else {
        this.user = this.data.user;
        console.log(this.data.user.cv, "this.data.user.cv")
    }
    this.privacyActivity.getPrivacyActivity();
    this.countryDataService.countryData$.pipe().subscribe(res => this.model = res);
  }

  saveData(myForm: NgForm) {
    this.data.user.firstName = myForm.value.firstName;
    this.data.user.lastName = myForm.value.lastName;
    this.data.user.address.data[0].postalCode = myForm.value.postalCode;
    this.data.user.address.data[0].street = myForm.value.street;
    this.data.user.address.data[0].state = myForm.value.state;
    this.data.user.address.data[0].c = myForm.value.country;
    this.data.user.gender = myForm.value.gender;
    this.data.personalData.showTelephone = myForm.value.showTelephone;
    this.data.user.nationality.data = myForm.value.nationality;
    this.data.personalData.showNationality = myForm.value.showNationality;
    this.data.user.birthday.date = new Date(myForm.value.birthday);
    this.data.user.email[0].name = myForm.value.email;
    const phone = myForm.controls[("phone")].value;
    this.data.user.status = myForm.value.status;
    this.data.user.phone[0].countryCode = phone.countryCode;
    this.data.user.phone[0].dialCode = phone.dialCode;
    this.data.user.phone[0].e164Number = phone.e164Number;
    this.data.user.phone[0].internationalNumber = phone.internationalNumber;
    this.data.user.phone[0].nationalNumber = phone.nationalNumber;
   // this.data.user.phone[0] = phone.nationalNumber.split(1, phone.number.number.length - 1)
   delete  this.data.user.phone[0].number;




   this.dialogRef.close({data: this.data, phone: phone});


   /* if (this.authService.isLoggedIn) {
      const phone = myForm.controls[("phone")].value;

      this.user.phone[0] = phone;
      if (!this.data.edit) {
        this.data.model.personalData.unshift(this.data.personalData);
      }
      let digit = this.user.address.data[0].postalCode.toString()[0];
      const plz = this.user.address.data[0].postalCode.toString().substring(0, 3)
      this.user.searchLocation.data = [];
      this.user.searchLocation.active.from = new Date();
      const searchFilterObj: UserSearchLocation = {
        c: this.user.address.data[0].c,
        region: [`${digit}`],
        postalCode: plz,
      }
      this.user.searchLocation.data.unshift(searchFilterObj);
      this.userService.updateUser(this.authService.userID, this.user);

    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });
      this.confirmationDialog.componentInstance.message = "If you want to save data you need to login or register"

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    } */


  }
  public addPrivacy(data:EditPrivacyDialog) {
    if(data.enum === Groups.Contacts_except || data.enum === Groups.Contacts_specific) {
      return this.dialog.open(PrivacyDialogComponent, {
        data,
        panelClass: 'my-class'
      })
  }
  console.log(data.enum);
  //this.data.personalData.cv_enum = data.enum;
}
onChange(value) {
  this.locations.c = value;
 this.data.personalData.c = value;
  }
}
export interface EditPersonalizedData {
  personalData?: PersonalData;
  edit?: boolean;
  model?: Model;
  user?: User;
  index?: number;
}

