import { Component, OnInit } from '@angular/core';
import { SenderService } from 'src/app/shared/services/sender.service';

@Component({
  selector: 'app-sidebar-page-settings',
  templateUrl: './sidebar-page-settings.component.html',
  styleUrls: ['./sidebar-page-settings.component.scss']
})
export class SidebarPageSettingsComponent implements OnInit {
  public page;
  public name;

  constructor( public recevier: SenderService) { }

  ngOnInit(): void {
    this.recevier.dataUpdate$.subscribe(success => {
      this.page = success;
    })
  }

}
