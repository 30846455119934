import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "src/app/shared/directives/directives.module";
import { UserDialogNameComponent } from "./user-dialog-name.component";



@NgModule({
  declarations: [UserDialogNameComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule

  ],
  exports: [
    UserDialogNameComponent
  ]
})
export class UserDialogModule { }
