import { select } from '@ngrx/store';
import {Component, Inject, OnInit} from "@angular/core";
import {EducationSubCategory, Group, Model, Page, Image} from "../../../shared/interfaces/model";
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material/dialog";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { GroupService } from "src/app/shared/services/group.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import * as QuillNamespace from "quill";
let Quill: any = QuillNamespace;
import ImageResize from "quill-image-resize-module";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/assets/environments/environment";
import { ImageService } from "src/app/shared/services/image.service";
import { ImageHandler, Options, VideoHandler } from "ngx-quill-upload";
import { debounceTime, distinctUntilChanged, timestamp } from "rxjs/operators";
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageHandler", ImageHandler);
Quill.register("modules/videoHandler", VideoHandler);
import _ from "lodash"
import { User } from "src/app/shared/interfaces/user.model";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-education-dialog",
  templateUrl: "./education-dialog.component.html",
  styleUrls: ["./education-dialog.component.scss"]
})
export class EducationDialogComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];
  public modules;
public user: User;
public website = "";
public searchForPage = "";
public selectedPageID = "";
public selectedPage: Page;
public page: Page;

public pageSelected = false;
pageActive = "";
searchForPageTrue = false;
public dataList$: Observable<any>;
selectedCV: any;
public uUser: User;
pages$: Observable<Page[]>;
private searchText$ = new Subject<string>();


  constructor(@Inject(MAT_DIALOG_DATA) public data: EditEducation,
              private dialogRef: MatDialogRef<EducationDialogComponent>,
              public auth: AuthService,
              public dialog: MatDialog,
              private userService: UserService,
              private pageService: PagesService,
              private groupService: GroupService,
              public http: HttpClient,
              public imageService: ImageService,
) {
  this.searchText$.pipe(
    debounceTime(400),
    distinctUntilChanged())
    .subscribe(value => {
    this.pageService.search(value).subscribe();
     this.pages$ = this.pageService.loadPages;
     this.searchForPageTrue = true
    });
}

  ngOnInit(): void {
    this.pages$ = this.pageService.loadPages;

    if (!this.data.edit) {
      this.data.education = {} as EducationSubCategory;
      this.pageSelected = true;
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        this.user = res.user;
        this.uUser = res.userUser;
        this.selectedCV = res.cv;
      });
    } else {
      this.dataList$ = this.userService.dataList;
      this.dataList$.subscribe((res: any) => {
        this.user = res;
        this.selectedCV = res.cv;
        this.selectedCV.data[this.data.languageIndex].education.subCategories[this.data.index] = this.data.education;
        this.data.education = _.cloneDeep(this.data.education);
        if (!!this.data.education.pageID) {
          this.pageService.getPage(this.data.education?.pageID).subscribe(res => {
            this.page = res;
            this.searchForPage = this.page?.name;
            this.pageActive = this.page?.name;
            this.pageSelected = true;
            this.website = this.page?.website;
            this.selectedPage = res;
            this.selectedPageID = this.page._id;
          });
        } else {
          this.searchForPage = this.data.education.name;
          this.pageSelected = true;
          this.website = this.data.education.url
        }
      });
      ({education: this.data.education} = this.data);
    }
    this.userService.userModel().subscribe(res => {
      this.users = res;
     /* this.pageService.getAllPages().subscribe(t => {
        this.pages = t;
      }) */
      this.pageService.allPages.subscribe(res => {
        this.pages = res;
      });

      this.groupService.getAllGroups().subscribe(t => {
        this.groups = t;
      })

      this.modules = {
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          linkTarget:"_blank",
          source: (searchTerm, renderList, mentionChar) => {

            let values = [{id: "", value: "", link: null }];

            if (mentionChar === "@") {
              this.users.forEach(t => {
                values.unshift({id: t._id, value: `${t.firstName} ${t.lastName}`, link:window.location.origin + "/" + t._id + "/cv"});

              })
              this.pages.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + "/" + t.pageUrl});
              });
              this.groups.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + "/groups/" + t.url});
              })

            } else if(mentionChar === "@") {


            }
            if (searchTerm.length === 0) {

              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                 // this.dataService.getUserModel(values[i].id).subscribe((data: Model) => {
                    //matches.unshift(values[i]);

                 // });
                  matches.unshift(values[i]);
                }


              renderList(matches, searchTerm);

              }
            }
          },
        },
        imageResize: true,
        imageDrop: true,
        imageHandler: {
          upload: (file) => {
            return new Promise((resolve, reject) => {

          if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg") { // File types supported for image
            if (file.size < 1000000) { // Customize file size as per requirement

            // Sample API Call
              const uploadData = new FormData();
              uploadData.append("file", file, file.name);

              return this.http.post(`${environment.backend}/image/${file}`, uploadData).toPromise()
              .then((result: Image) => {
                this.imageService.getImageByID(result._id).subscribe((t: Image) => {
                  resolve(t.imageUrl); // RETURN IMAGE URL from response
                 });
            })
                .catch(error => {
                  reject("Upload failed");
                  // Handle error control
                  console.error("Error:", error);
                });
            } else {
              reject("Size too large");
             // Handle Image size large logic
            }
          } else {
            reject("Unsupported type");
           // Handle Unsupported type logic
          }
        });
      },
          accepts: ["png", "jpg", "jpeg", "jfif"] // Extensions to allow for images (Optional) | Default - ['jpg', 'jpeg', 'png']
        } as Options,

    }
    });
  }
  public autogrow() {
    const textArea = document.getElementById("educationDescription");
    textArea.style.overflow = "hidden";
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }
  saveData() {
    if(this.auth.isLoggedIn) {

    if (!this.data.edit) {
      this.selectedCV.data[this.data.languageIndex].education.subCategories.unshift(this.data.education);
      this.userService.updateCV(this.selectedCV._id, this.selectedCV);


    if (!this.selectedPageID) {
      const input = document.getElementById("example-search-input4") as HTMLInputElement | null;
      this.data.education.name = input.value;
    }
    this.dialogRef.close();

  } else if (!!this.data.edit) {
    if (!this.selectedPageID) {
      const input = document.getElementById("example-search-input4") as HTMLInputElement | null;
      if (!!input) {
        this.data.education.name = input.value;
      }
      if (this.website) {
        this.data.education.url = this.website;
      }
      this.selectedCV.data[this.data.languageIndex].education.subCategories[this.data.index] = this.data.education;


  }

}
    // this.dataService.updatePage(this.data.model._id, this.data.model);
  }/* else {
    this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });
      this.confirmationDialog.componentInstance.message = "If you want to save data you need to login or register"

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
  }
  this.userService.updateUser(this.user._id, this.user); */
  this.dialogRef.close(this.data);


}
deletePage() {
   this.pageActive = "";
   this.searchForPage = "";
   if(this.selectedPageID) {
    const removeItem = "pageID";
    const { [removeItem]: remove, ...rest } = this.data.education;
    this.data.education = rest;
    this.user.cv[this.data.languageIndex].education[0].subCategories[this.data.index] =  this.data.education;
    // tslint:disable-next-line: max-line-length
    this.selectedPage?.education?.splice(_.indexOf(this.selectedPage.education, _.find(this.selectedPage.education, { userID: this.auth.userID })), 1);
    if (this.selectedPage) {
     this.pageService.updatePage(this.selectedPageID, this.selectedPage);
     this.selectedPageID = "";
     this.website = "";
    }
   }
 }
addCompany(page: Page) {
  console.log(page, "page")
  this.pageSelected = true;
  this.pageActive = page.name;


  if (page.name) {
    this.searchForPage = page.name;
  }
  if (page.website) {
    this.website = page.website;
  }

  console.log()
  this.data.education.pageID = page._id;
  if (this.pageSelected) {
    this.selectedPageID = page._id;
    this.selectedPage = page;
   this.data.education.pageID = page._id;
    this.data.education.name = "";
    const removeItem = "name";
   const { [removeItem]: remove, ...rest } = this.data.education;
    this.data.education = rest;
    this.selectedCV.cv[this.data.languageIndex].education.subCategories[this.data.index] = this.data.education;
  }

  this.searchForPageTrue = false;
}
addNewCompany(page: Page) {
  this.pageSelected = true;
  this.pageActive = page.name;
  if (page.name) {
    this.searchForPage = page.name;
  }
  if (page.website) {
    this.website = page.website;
  }
  this.data.education.pageID = page._id;
  if (this.pageSelected) {
    const data = {
      userID: this.auth.userID,
      startDate: this.data.education.startDate,
      endDate: this.data.education.endDate,
      today: this.data.education?.today
    }
    this.selectedPageID = page._id;
    this.selectedPage = page;
    this.data.education.pageID = page._id;
    this.data.education.name = "";
    const removeItem = "name";
    const { [removeItem]: remove, ...rest } = this.data.education;
    this.data.education = rest;

    if (!this.selectedPage.education.some((res) =>  res.userID === data.userID)) {
      this.selectedPage.education.unshift(data);
  }
}
this.searchForPageTrue = false;
}

todayChange(event) {
  if (this.selectedPage?.education.some((res) => res.userID === this.auth.userID && event)) {
    Object.assign(this.selectedPage.education.some((res) => res.userID === this.auth.userID), {today: true});
  }
}
search(packageName: string) {
  this.searchText$.next(packageName);
}
}
export interface EditEducation {
  education?: EducationSubCategory;
  edit?: boolean;
  model?: Model;
  index?: number;
  languageIndex?: number;
}
