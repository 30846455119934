import { Component, Input, OnInit } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { UserContact } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-page-contact',
  templateUrl: './page-contact.component.html',
  styleUrls: ['./page-contact.component.scss']
})
export class PageContactComponent implements OnInit {
  @Input() isPage;
  @Input() contact: UserContact;
  public user: User;
  public hasPhoto: boolean;


  constructor(
    public userService?: UserService,

  ) { }

  ngOnInit(): void {
    if (this.contact.userID) {
      this.userService.getUserContactData(this.contact.userID).pipe(shareReplay(1)).subscribe(res => {
        this.user = res;
      })
    }
  }

}
