import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserHelperService } from 'src/app/shared/utils/user-helper.service';
import {Notification} from '../../../shared/interfaces/model';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit {
  @Input() notification: Notification;
  public user: User

  constructor(
    private authService: AuthService,
    private userHelper: UserHelperService) { }

  ngOnInit(): void {

  /*  this.authService.getUserProfile(this.notification).subscribe(res => {
      this.user = res;
    }) */

  }
  onAcceptButtonClick(frinedClicked) {
    let friendRequestObject = {
      id: frinedClicked.id,
      status: "You are friend"
    }

    this.userHelper.updateFriendRequest(friendRequestObject).pipe().subscribe(() => {
      this.ngOnInit();
    });
  }

}
