<main>
  <div class="container">
    <div class="row justify-content-between gen-4">
      <div class="card-footer">
        <ul
          class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
          <li>
            <!-- Chat user tab item -->
            <a routerLink="/projects" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
              [routerLinkActive]="['active']" class="nav-link text-start" id="chat-1-tab" data-bs-toggle="pill"
              role="tab">
              <div class="d-flex">
                <div class="flex-shrink-0 avatar avatar-story me-2">
                  <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="" />
                </div>
                <div class="flex-grow-1 d-block">
                  <h6 class="mb-0 mt-1">
                    {{ loggedUser.user?.firstName }} {{ loggedUser.user?.lastName }}
                  </h6>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3">
        <div class="d-flex align-items-center mb-4 d-lg-none">
          <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
            <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{
              routerLinkUrl | translate
              }}</span>
          </button>
        </div>
        <!-- Advanced filter responsive toggler END -->

        <nav class="navbar navbar-light navbar-expand-lg mx-0">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
            <!-- Offcanvas header -->
            <div class="offcanvas-header">
              <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
            </div>

            <!-- Offcanvas body -->
            <div class="offcanvas-body p-0">
              <!-- Card START -->
              <div class="card w-100">
                <!-- Card body START -->
                <div class="card-body">
                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="
                          '/' + loggedUser.user?.userUrl + '/jobs'
                        " [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                        data-bs-toggle="tab">
                        <i class="bi bi-bar-chart-steps nav-icon"></i>
                        <span class="ms-2">{{ "job.myJobs" | translate }}
                        </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="
                          '/' + loggedUser.user?.userUrl + '/jobs/create'
                        " [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                        data-bs-toggle="tab">
                        <i class="bi bi-pencil-square nav-icon"></i>
                        <span class="ms-2">{{ "job.create" | translate }}
                        </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="
                          '/' +
                          loggedUser.user?.userUrl +
                          '/jobs/applied'
                        " [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                        data-bs-toggle="tab">
                        <i class="bi bi-box-arrow-up-right nav-icon"></i>
                        <span class="ms-2">{{ "project.applied" | translate }}
                        </span></a>
                    </li>
                    <li class="nav-item" data-bs-dismiss="offcanvas">
                      <a class="nav-link d-flex mb-0" [routerLink]="
                          '/' + loggedUser.user?.userUrl + '/jobs/saved'
                        " [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']"
                        data-bs-toggle="tab">
                        <i class="bi bi-bookmark-fill nav-icon"></i>
                        <span class="ms-2">{{ "job.savedJobs" | translate }}
                        </span></a>
                    </li>

                  </ul>
                  <!-- Side Nav END -->
                </div>
                <!-- Card body END -->
                <!-- Card footer -->
              </div>
              <!-- Card END -->
            </div>
          </div>
        </nav>
      </div>
      <div class="col-lg-6 vstack gap-4">
        <div class="py-p mb-0">
          <div class="result-list">
            <div class="container" *ngIf="router.url === '/' + loggedUser.user?.userUrl + '/jobs'">
              <ng-container *ngFor="let userJobs of jobObjs">
                <app-job-item [userJobs]="userJobs" [userJob]="true" [userJobsAd]="false" [userUrl]="loggedUser.user?.userUrl"
                  [isTrue]="'isTrue'"></app-job-item>
              </ng-container>

            </div>
            <div class="container" *ngIf="router.url === '/' + loggedUser.user?.userUrl + '/jobs/create'">
              <app-create-your-job [isPage]="false"></app-create-your-job>

            </div>
            <div class="container" *ngIf="router.url === '/' + loggedUser.user?.userUrl + '/jobs/applied'">
              <app-applied-jobs [isUser]="true"></app-applied-jobs>

            </div>
            <div class="container" *ngIf="router.url === '/' + loggedUser.user?.userUrl + '/jobs/saved'">
              <app-saved-jobs [isUser]="true"></app-saved-jobs>

            </div>
            <div class="container" *ngIf="router.url === '/' + loggedUser.user?.userUrl + '/jobs/ignored'">
              <app-ignored-jobs [isUser]="true"></app-ignored-jobs>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</main>
