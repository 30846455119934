<div class="friends" [ngClass]="{'friends-cvm': router.url.endsWith('/cvm') }">
  <div class="user d-flex row justify-content-between align-items-center col-md-12" (click)="showMessages = !showMessages">
    <img [src]="this.image" ngx-gravatar [email]="'example@mail.com'" class="px-0 col-md-1" fallback="mp" size="30">
    <p class="col-md-6">Messaging</p>
    <i class="bi bi-pen col-md-1"></i>
    <i class="bi bi-three-dots col-md-1"></i>
    <i *ngIf="showMessages" class="bi bi-caret-down-fill pl-3 pr-3 col-md-1"></i>
    <i *ngIf="!showMessages" class="bi bi-caret-up-fill pl-3 pr-3 col-md-1"></i>

  </div>
  <ng-container *ngIf="showMessages">
    <div class="showMessages">
    <ng-container *ngFor="let contact of contacts">
    <app-user-contacts [contact]="contact" [photoId]="this.user?.profilePicture" [sidebar]="'sidebar'"></app-user-contacts>
  </ng-container>
    </div>
  </ng-container>
</div>
