<main>
  <div class="container">
    <div class="row justify-content-between g-4" *ngIf="!router.url.endsWith('search')">
      <div class="card-footer">
       <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
        <li >
          <!-- Chat user tab item -->
          <a routerLink="/projects" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
            <div class="d-flex">
              <div class="flex-shrink-0 avatar avatar-story me-2">
                <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
              </div>
              <div class="flex-grow-1 d-block">
                <h6 class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
              </div>
            </div>
          </a>
        </li>
        <ng-container *ngFor="let userPage of pages$ | async; let i = index">
          <li>
            <app-pages-list class="d-flex me-1 ms-1" [pageProjects]="userPage" [projectPageID]="i" (selectedIndex)="selectIndexProject(i)" (selectedPage)="showSelectedPage($event)"></app-pages-list>

          </li>
        </ng-container>
       </ul>

        </div>
      <app-top-nav-buttons-jobs-projects
      *ngIf="router.url === '/projects'"
      [textButtonCreate]="'project.create'"
      [textButtonData]="'project.myProjects'"
      [textButtonApplied]="'project.applied'"
      [textButtonSaved]="'job.savedJobs'"
      [textButtonIgnored]="'job.ignoredJobs'"
      [routerLinkForApplied]="'../' +  user?.userUrl + '/projects/applied'"
      (buttonCreate)="createProject()"
      (buttonData)="goToMyProjects()"
      (buttonSaved)="navigateToSaved()"
    ></app-top-nav-buttons-jobs-projects>

    <app-top-nav-buttons-jobs-projects
    *ngIf="router.url === '/projects/' + page.page?.pageUrl"
      [textButtonCreate]="'project.create'"
      [textButtonData]="'project.ourProjects'"
      [textButtonApplied]="'project.applied'"
      [textButtonSaved]="'job.savedJobs'"
      [textButtonIgnored]="'job.ignoredJobs'"
      [routerLinkForApplied]="'../' +  page.page?.pageUrl + '/projects/applied_projects'"
      (buttonCreate)="createProject()"
      (buttonData)="goToMyProjects()"
      (buttonSaved)="navigateToSaved()"
    ></app-top-nav-buttons-jobs-projects>


    <div class="col-lg-3" *ngIf="router.url === '/projects'">

      <!-- Advanced filter responsive toggler START -->
      <!-- Divider -->
      <div class="d-flex align-items-center mb-4 d-lg-none">
        <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
          <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
          <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'searchDetails' | translate}}</span>
        </button>
      </div>
      <!-- Advanced filter responsive toggler END -->

      <nav class="navbar navbar-light navbar-expand-lg mx-0">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
          <!-- Offcanvas header -->
          <div class="offcanvas-header">
            <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>

          <!-- Offcanvas body -->
          <div class="offcanvas-body p-0">
            <!-- Card START -->
            <div class="card w-100">
              <!-- Card body START -->
              <div class="card-body px-0 py-0">

              <!-- Side Nav START -->
              <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                <li class="nav-item w-100">
                  <app-sidebar-search-jobs-projects class="w-100" [isPage]="false"></app-sidebar-search-jobs-projects>
                </li>
              </ul>
              <!-- Side Nav END -->

            </div>
            <!-- Card body END -->
            <!-- Card footer -->

            </div>
          <!-- Card END -->
          </div>


        </div>
      </nav>
    </div>
    <div class="col-lg-3" *ngIf="router.url === '/projects/' + page.page?.pageUrl">

      <!-- Advanced filter responsive toggler START -->
      <!-- Divider -->
      <div class="d-flex align-items-center mb-4 d-lg-none">
        <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
          <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
          <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'searchDetails' | translate}}</span>
        </button>
      </div>
      <!-- Advanced filter responsive toggler END -->

      <nav class="navbar navbar-light navbar-expand-lg mx-0">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
          <!-- Offcanvas header -->
          <div class="offcanvas-header">
            <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>

          <!-- Offcanvas body -->
          <div class="offcanvas-body p-0">
            <!-- Card START -->
            <div class="card w-100">
              <!-- Card body START -->
              <div class="card-body px-0 py-0">

              <!-- Side Nav START -->
              <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                <li class="nav-item w-100">

                  <app-sidebar-search-jobs-projects class="w-100" [isPage]="true" [pageID]="page.page._id" [(categoryTitle)]="categoryTitle" [pageIndex]="pageIndex"></app-sidebar-search-jobs-projects>
                </li>
              </ul>
              <!-- Side Nav END -->

            </div>
            <!-- Card body END -->
            <!-- Card footer -->

            </div>
          <!-- Card END -->
          </div>


        </div>
      </nav>
    </div>
<div class="col-lg-6 vstack gap-4">
    <!-- Setting Tab content START -->
    <div class="tab-content py-0 mb-0">

      <ng-container *ngIf="router.url === '/projects'">
      <ng-container *ngFor="let userJobSearchId of jobID; let i = index">
        <app-job-item [userJobSearchId]="userJobSearchId" [userUrl]="user?.userUrl"  (unsaveJobUsers)="onChangeSavedJobs(i)" [user]="user" [isProjectSearch]="'isProjectSearch'" [isProjectCompanySearch]="false"></app-job-item>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="router.url === '/projects/' + page.page?.pageUrl">
      <ng-container *ngFor="let pageID of pageID; let i = index">
        <app-job-item [pageID]="pageID" [userUrl]="user?.userUrl" [selectedPageID]="userPage.pageId" (addToIgnoreProjectPage)="ignoreProjectPage(pageID)" (unsaveJobUsers)="onChangeSavedJobs(i)" [user]="user" [isProjectCompanySearch]="true"></app-job-item>
      </ng-container>
    </ng-container>

    <div class="card">
      <div class="card-body d-flex column align-items-start" *ngIf="router.url === '/projects'">
        <h5>To see more projects, customise your CV:
          <br>
          <div class="d-flex flex-column align-items-start">
            <a class="me-1" [routerLink]="'../' +user?.userUrl + '/cv/'">{{'header.cv' | translate}}</a>
            <a   class="me-1 mr-1"[routerLink]="'../' +user?.userUrl + '/cv/search_locations'">{{'search_filter' | translate}}</a>
            <a  class="me-1" [routerLink]="'../' +user?.userUrl + '/cv/branch'">{{'Branchen.name' | translate}}</a>

          </div>



         </h5>
      </div>
      <div class="card-body d-flex column align-items-start" *ngIf="router.url === '/projects/' + page?.pageUrl">
        <h5>To see more projects, customise your site:
          <br>
          <div class="d-flex flex-column align-items-start">
            <a class="me-1" [routerLink]="'../' + page?.pageUrl+ '/settings'">{{'pagesSettings.settings' | translate}}</a>
            <a   class="me-1 mr-1" [routerLink]="'../' + page?.pageUrl+ '/settings/about'">{{'pageSidebar.info' | translate}}</a>
            <a  class="me-1" [routerLink]="'../' + page?.pageUrl+ '/settings/search_locations'">{{'pageSidebar.service_area' | translate}}</a>
            <br>
            <a  class="me-1" [routerLink]="'../' + page?.pageUrl+ '/about'">{{'pagesSettings.sidebar.pageInf' | translate}}</a>

          </div>
         </h5>
      </div>
    </div>
        </div>
        </div>

    </div>
    <div class="row justify-content-between g-4" *ngIf="router.url.endsWith('search')">
      <div class="card-footer">
        <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
         <li *ngIf="!projectSearch?.pageID">
           <!-- Chat user tab item -->
           <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
             <div class="d-flex">
               <div class="flex-shrink-0 avatar avatar-story me-2">
                 <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
               </div>
               <div class="flex-grow-1 d-block">
                 <h6 class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
               </div>
             </div>
           </a>
         </li>
         <li *ngIf="projectSearch?.pageID">
          <!-- Chat user tab item -->
          <a  [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="['active']" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
            <div class="d-flex">
              <div class="flex-shrink-0 avatar avatar-story me-2">
                <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
              </div>
              <div class="flex-grow-1 d-block">

                <h6 class="mb-0 mt-1"> {{page?.name}}</h6>
              </div>
            </div>
          </a>
        </li>
        </ul>

         </div>
         <app-top-nav-buttons-jobs-projects
           [textButtonCreate]="'project.create'"
           [textButtonData]="'project.ourProjects'"
           [textButtonApplied]="'project.applied'"
           [textButtonSaved]="'job.savedJobs'"
           [routerLinkForApplied]="'../' +  page?.pageUrl + '/projects/applied_projects'"
           (buttonCreate)="createProject()"
           (buttonData)="goToMyProjects()"
           (buttonSaved)="navigateToSaved()"
         ></app-top-nav-buttons-jobs-projects>
         <div class="col-lg-3">

          <!-- Advanced filter responsive toggler START -->
          <!-- Divider -->
          <div class="d-flex align-items-center mb-4 d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
              <span class="btn btn-primary"><i class="bi bi-bar-chart-steps nav-icon"></i></span>
              <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'searchDetails' | translate}}</span>
            </button>
          </div>
          <!-- Advanced filter responsive toggler END -->

          <nav class="navbar navbar-light navbar-expand-lg mx-0">
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
              <!-- Offcanvas header -->
              <div class="offcanvas-header">
                <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>

              <!-- Offcanvas body -->
              <div class="offcanvas-body p-0">
                <!-- Card START -->
                <div class="card w-100">
                  <!-- Card body START -->
                  <div class="card-body px-0 py-0">

                  <!-- Side Nav START -->
                  <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                    <li class="nav-item w-100">
                      <ng-container *ngIf="!!projectSearch">
                        <app-sidebar-search-jobs-projects class="w-100" [data]="projectSearch"  [isSearchSidebar]="true" [pageID]="page._id" [categoryTitle]="categoryTitle" [pageIndex]="pageIndex"></app-sidebar-search-jobs-projects>
                      </ng-container>
                    </li>
                  </ul>
                  <!-- Side Nav END -->

                </div>
                <!-- Card body END -->
                <!-- Card footer -->

                </div>
              <!-- Card END -->
              </div>


            </div>
          </nav>
        </div>
        <div class="col-lg-6 vstack gap-4">
          <!-- Setting Tab content START -->
          <div class="tab-content py-0 mb-0">
            <ng-container *ngIf="projectSearch?.freelancer">
              <ng-container *ngFor="let user of userSearch; let i = index">
                <app-job-item [userData]="user" [projectSearch]="projectSearch" [user]="user" [userUrl]="user?.userUrl" [isSearchUser]="true"></app-job-item>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="projectSearch?.company">
              <ng-container *ngFor="let page of pageSearch; let i = index">
                <app-job-item [pageData]="page" [projectSearch]="projectSearch" (selectedPage)="showSelectedPage($event)" [isSearchPage]="true"></app-job-item>
              </ng-container>
            </ng-container>
              </div>
              </div>
    </div>
  </div>
</main>


