<div class="search-widget">
    <ul class="ui-selection-list">
      <li class="line form-row">
        <div class="col-12 pb-2 pt-2 specialty" id="sp">
          <div class="card__body d-flex column">
            <div class="d-flex align-items-start card__body__list">
              <ng-container *ngIf="!!data">
                <div *ngFor="let language of data; let i=index"
                  class="d-flex justify-content-between pr-1 pt-1">
                  <div class="card__text">
                    {{getLanguageName(language?.name)}}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeLanguage(i)"><i class="fa fa-remove"></i></button>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="replace; else edit">
                <button class="btn btn-light ms-1" (click)="replaceLanguage()">
                  <i class="bi bi-arrow-repeat"></i></button>

              </ng-container>
              <ng-template #edit>
                <button class="btn btn-light ms-1" (click)="editLanguage({user:user, edit:true, jobLanguage:true, job: dataObj})">
                  <i class="bi bi-pencil"></i></button>
              </ng-template>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
