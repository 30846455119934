<table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">{{ firstTd }}</th>
      <th scope="col">{{secondTd}}</th>
      <th scope="col">{{thirdTd}}</th>
      <th scope="col">{{fourthTD}}</th>
      <th scope="col">{{fifthTD}}</th>
      <th scope="col">{{sixthTD}}</th>
      <th *ngIf="reference" scope="col">Reference</th>
      <th *ngIf="status" scope="col">Status</th>
    </tr>
  </thead>
  <tbody *ngFor="let creditHistory of data; let i = index">
    <tr>
      <td><a href="#">{{creditHistory.name}}</a></td>
      <td *ngIf="creditHistory.date">{{creditHistory.date | date:"mediumDate" }}</td>
      <td>{{creditHistory.invoiceID}}</td>
      <app-company-name [companyName]="creditHistory?.company"></app-company-name>
      <td>{{creditHistory.type_}}</td>
      <td>{{creditHistory.account_number}}</td>
      <td>{{creditHistory.amount}}</td>
      <td *ngIf="reference">{{creditHistory.reference}}</td>
      <td *ngIf="status">{{creditHistory.status}}</td>
      <td class="showLink">
        <i class="bi bi-box-arrow-up-right"
          (click)="goForPrint(creditHistory)"></i>
      </td>
    </tr>
  </tbody>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
  aria-label="Select page of periodic elements">
</mat-paginator>
