import { Component, OnInit } from '@angular/core';
import { BranchesService } from 'src/app/shared/services/branches.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss']
})
export class BranchesComponent implements OnInit {

  constructor(public branchesService: BranchesService) { }

  ngOnInit(): void {
    this.branchesService.getBranches();
  }

}
