import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { Role } from "src/app/shared/enums/role";
import { Page, Roles, Image, Model, PageRoles, UserPage } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import { NotificationHelperService } from "src/app/shared/utils/notification-helper.service";
import { Status } from "src/app/shared/utils/page-helper.service";
import { PageDetailsComponent } from "../page-details/page-details.component";
import _ from 'lodash'
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-pages-settings",
  templateUrl: "./pages-settings.component.html",
  styleUrls: ["./pages-settings.component.scss"]
})
export class PagesSettingsComponent extends PageDetailsComponent implements OnInit {
  public page: Page;
  public name;
  public users: User[] = [];
  public searchUsers: User[] = [];
  public searchUser = "";
  public role = new PageRoles();
  public pageRole = new Roles();
  public addedRoles: string[];
  public roles = "";
  public userID;
  public ownerRole = false;
  public adminRole = false;
  public moderatorRole = false;
  public editorRole = false;
  public advertiserRole = false;
  public analystRole = false;
  public userRole = false;
  public privacies = [
    { isChecked: false, id: Role.Owner, name: "role.owner", role: Role.Owner, show: false },
    { isChecked: false, id: Role.Admin, name: "role.admin", role: Role.Admin, show: false  },
    { isChecked: false, id: Role.Editor, name: "role.editor", role: Role.Editor, show: false  },
    { isChecked: false, id: Role.Moderator, name: "role.moderator", role: Role.Moderator, show: false  },
    { isChecked: false, id: Role.Advertiser, name: "role.advertiser", role: Role.Advertiser, show: false  },
    { isChecked: false, id: Role.Analyst, name: "role.analyst", role: Role.Analyst, show: false  },
    { isChecked: false, id: Role.User, name: "role.user", role: Role.User, show: false  },
    { isChecked: false, id: Role.Jobs, name: "role.jobs", role: Role.Jobs, show: false  },
    { isChecked: false, id: Role.Projects, name: "role.projects", role: Role.Projects, show: false  },
    { isChecked: false, id: Role.Messages, name: "role.messages", role: Role.Messages, show: false  },
    { isChecked: false, id: Role.Notifications, name: "role.notifications", role: Role.Notifications, show: false  },
    { isChecked: false, id: Role.Payments, name: "role.payments", role: Role.Payments, show: false  },
    { isChecked: false, id: Role.Marketing, name: "role.marketing", role: Role.Marketing, show: false  },
  ];
  public notifications: Notification[] = [];
  public pending = false;
  @ViewChild('rolesForm') rolesForm: NgForm;


  groupOwner;
  groupEditor;
  groupAdmin;
  groupModerator;
  groupAdvertiser;
  groupAnalyst;
  groupUser;
  constructor(public router: Router,
    public branchesService: BranchesService,
    public languageService: LanguagesService,
    public http: HttpClient,
    public authService: AuthService,
    public pageService: PagesService,
    public dialog: MatDialog,
    public imageService: ImageService,
    public shareData: SenderService,
    public receiver: SenderService,
    public cd: ChangeDetectorRef,
    public userService?: UserService,
    public notificationHelper?: NotificationHelperService) {
    super(router, branchesService, languageService, http, authService, pageService, dialog, imageService, shareData, receiver, cd);
  }

  ngOnInit(): void {

    this.receiver.dataUpdate$.subscribe((success: any) => {
      if (success) {
        this.page = success;
        if (this.page.pageUrl) {
          this.name = this.page.pageUrl;
        } else {
          this.name = this.page._id;
        }
        this.userService.getUsers();
        this.userService.userModel().subscribe(res => {
          this.users = res;
        });

        if (this.page.profileImage) {
          this.imageService.getImageByID(this.page.profileImage).subscribe((res: Image) => {
            if (res.imageUrl) {
              this.image = res.imageUrl;
              this.hasPhoto = true;
              this.cd.detectChanges();
            }
          });
        } else if (this.page.profileImage === undefined) {
          return;
        }

        if (this.page.coverImage) {

          this.imageService.getImageByID(this.page.coverImage).subscribe((res: Image) => {
            if (res.imageUrl) {
              this.coverImage = res.imageUrl;
              this.hasCoverPhoto = true;
              this.cd.detectChanges();

            }
          });
        } else if (this.page.coverImage === undefined) {
          return;
        }
        this.checkUserIsInRole()
      } else {
        const pageID = localStorage.getItem("pageID");
        this.pageService.getPage(pageID).subscribe(res => {
          this.page = res;
        })
      }

    });
  }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }
  addUser(user: User, event) {
    this.role.userID = user._id;
    this.searchUser = `${user.firstName} ${user.lastName}`;
    this.checkUserRoles();
    this.checkUserIsInRole()
  }
  changeInput(event) {
    if (event.target.value === '' || this.checkUserRoles) {
      this.privacies.forEach(res => res.isChecked = false);
    }

  }

  getPhoto(id) {
    console.log(id);
    /* this.modelDataService.getUserModel(id).subscribe((data:Model) => {
      if(data.profilePicture) {
      this.imageService.getImageByID(data.profilePicture).subscribe((res: Image) => {
        if(res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    }
    })
    */
  }

  addRole(event, id) {
    if (this.searchUser === "") {
      return;
    }

    const role = {
      userID: this.role.userID,
      timestamp: new Date()
    }
    switch (id.role) {
      case Role.Admin:
        if (id.isChecked) {
          return this.page.roles.Admin.unshift(role);
        } else {
          this.page.roles.Admin.splice(_.indexOf(this.page.roles.Admin, _.find(this.page.roles.Admin, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Owner:
        if (id.isChecked) {
          return this.page.roles.Owner.unshift(role);
        }
        this.page.roles.Owner.splice(_.indexOf(this.page.roles.Owner, _.find(this.page.roles.Owner, { userID: this.role.userID })), 1);
        break;
      case Role.Advertiser:
        if (id.isChecked) {
          this.page.roles.Advertiser.unshift(role);
        } else {
          this.page.roles.Advertiser.splice(_.indexOf(this.page.roles.Advertiser, _.find(this.page.roles.Advertiser, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Moderator:
        if (id.isChecked) {
          this.page.roles.Moderator.unshift(role);
        } else {
          this.page.roles.Moderator.splice(_.indexOf(this.page.roles.Moderator, _.find(this.page.roles.Moderator, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Editor:
        if (id.isChecked) {
          this.page.roles.Editor.unshift(role);
        } else {
          this.page.roles.Editor.splice(_.indexOf(this.page.roles.Editor, _.find(this.page.roles.Editor, { userID: this.role.userID })), 1);
        }
        break;
      case Role.User:
        if (id.isChecked) {
          this.page.roles.User.unshift(role);
        } else {
          this.page.roles.User.splice(_.indexOf(this.page.roles.User, _.find(this.page.roles.User, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Analyst:
        if (id.isChecked) {
          this.page.roles.Analyst.unshift(role);
        } else {
          this.page.roles.Analyst.splice(_.indexOf(this.page.roles.Analyst, _.find(this.page.roles.Analyst, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Projects:
        if (id.isChecked) {
          this.page.roles.Projects.unshift(role);
        } else {
          this.page.roles.Projects.splice(_.indexOf(this.page.roles.Projects, _.find(this.page.roles.Projects, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Jobs:
        if (id.isChecked) {
          this.page.roles.Jobs.unshift(role);
        } else {
          this.page.roles.Jobs.splice(_.indexOf(this.page.roles.Jobs, _.find(this.page.roles.Jobs, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Messages:
        if (id.isChecked) {
          this.page.roles.Messages.unshift(role);
        } else {
          this.page.roles.Messages.splice(_.indexOf(this.page.roles.Messages, _.find(this.page.roles.Messages, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Notifications:
        if (id.isChecked) {
          this.page.roles.Notifications.unshift(role);
        } else {
          this.page.roles.Notifications.splice(_.indexOf(this.page.roles.Notifications, _.find(this.page.roles.Notifications, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Payments:
        if (id.isChecked) {
          this.page.roles.Payments.unshift(role);
        } else {
          this.page.roles.Payments.splice(_.indexOf(this.page.roles.Payments, _.find(this.page.roles.Payments, { userID: this.role.userID })), 1);
        }
        break;
      case Role.Marketing:
        if (id.isChecked) {
          this.page.roles.Marketing.unshift(role);
        } else {
          this.page.roles.Marketing.splice(_.indexOf(this.page.roles.Marketing, _.find(this.page.roles.Marketing, { userID: this.role.userID })), 1);
        }
        break;
      default:
        break;
    }

  }

  get checkAdmin() {
    // tslint:disable-next-line: max-line-length
    return !!(this.page.roles.Admin.find(r => r.userID === this.role.userID) && this.privacies.find(rt => rt.id === Role.Admin).isChecked === true);
  }
  get checkOwner() {
    // tslint:disable-next-line: max-line-length
    return !!(this.page.roles.Owner.find(r => r.userID === this.role.userID) && this.privacies.find(rt => rt.id === Role.Owner).isChecked === true);
  }

  saveUserRole() {
    const roles: UserPage = {
      pageId: this.page._id,
      timestamp: new Date(),
      status: Status.Pending,
    }
    this.userService.getUserContactData(this.role.userID).subscribe((res: User) => {
      if (res.pages.find(r => r.pageId === this.page._id) && this.checkIdIfExistOnRole) {
        return;
      }
      let a = res.pages.some(o => o.pageId === this.page._id);
      if (!this.checkIfIDExist && a) {
        res.pages.splice(_.indexOf(res.pages, _.find(res.pages, { pageId: this.page._id })), 1);
      } else if (res.pages.find(r => r.pageId === this.page._id)) {
        return;
      }
      else if (this.checkIdIfExistOnRole && !a) {
        res.pages.unshift(roles);
        this.userService.updateUser(res._id, res);
      }
      this.userService.updateUser(res._id, res);
    });
    this.pageService.updatePage(this.page._id, this.page);
    this.rolesForm.reset();
    this.privacies.forEach(res => res.isChecked = false);
  }
  public get checkIfIDExist(): boolean {
    return this.page.roles.Owner.some(r => r.userID === this.role.userID) &&
      this.page.roles.Admin.some(r => r.userID === this.role.userID) &&
      this.page.roles.Advertiser.some(r => r.userID === this.role.userID) &&
      this.page.roles.Analyst.some(r => r.userID === this.role.userID) &&
      this.page.roles.Moderator.some(r => r.userID === this.role.userID) &&
      this.page.roles.Editor.some(r => r.userID === this.role.userID) &&
      this.page.roles.User.some(r => r.userID === this.role.userID) &&
      this.page.roles.Jobs.some(r => r.userID === this.role.userID) &&
      this.page.roles.Projects.some(r => r.userID === this.role.userID) &&
      this.page.roles.Messages.some(r => r.userID === this.role.userID) &&
      this.page.roles.Notifications.some(r => r.userID === this.role.userID) &&
      this.page.roles.Payments.some(r => r.userID === this.role.userID) &&
      this.page.roles.Marketing.some(r => r.userID === this.role.userID)
  }
  public get checkIdIfExistOnRole(): boolean {
    return this.page.roles.Owner.some(r => r.userID === this.role.userID) ||
      this.page.roles.Admin.some(r => r.userID === this.role.userID) ||
      this.page.roles.Advertiser.some(r => r.userID === this.role.userID) ||
      this.page.roles.Analyst.some(r => r.userID === this.role.userID) ||
      this.page.roles.Moderator.some(r => r.userID === this.role.userID) ||
      this.page.roles.User.some(r => r.userID === this.role.userID) ||
      this.page.roles.Editor.some(r => r.userID === this.role.userID) ||
      this.page.roles.Jobs.some(r => r.userID === this.role.userID) ||
      this.page.roles.Projects.some(r => r.userID === this.role.userID) ||
      this.page.roles.Messages.some(r => r.userID === this.role.userID) ||
      this.page.roles.Notifications.some(r => r.userID === this.role.userID) ||
      this.page.roles.Payments.some(r => r.userID === this.role.userID) ||
      this.page.roles.Marketing.some(r => r.userID === this.role.userID)

  }

  checkUserRoles() {

    if (this.page.roles.Owner.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Owner).isChecked = true;
    }
    if (this.page.roles.Admin.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Admin).isChecked = true;
    }
    if (this.page.roles.Advertiser.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Advertiser).isChecked = true;
    }
    if (this.page.roles.Moderator.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Moderator).isChecked = true;
    }
    if (this.page.roles.Editor.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Editor).isChecked = true;
    }
    if (this.page.roles.Analyst.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Analyst).isChecked = true;
    }
    if (this.page.roles.User.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.User).isChecked = true;
    }
    if (this.page.roles.Jobs.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Jobs).isChecked = true;
    }
    if (this.page.roles.Projects.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Projects).isChecked = true;
    }
    if (this.page.roles.Messages.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Messages).isChecked = true;
    }
    if (this.page.roles.Notifications.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Notifications).isChecked = true;
    }
    if (this.page.roles.Payments.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Payments).isChecked = true;
    }
    if (this.page.roles.Marketing.find(r => r.userID === this.role.userID)) {
      this.privacies.find(rt => rt.id === Role.Marketing).isChecked = true;
    }


  }

  checkUserIsInRole() {
    if (this.page.roles.Owner.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Owner).show = true;
      this.privacies.find(rt => rt.id === Role.Admin).show = true;
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
      this.privacies.find(rt => rt.id === Role.User).show = true;
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }
    if (this.page.roles.Admin.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Admin).show = true;
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
      this.privacies.find(rt => rt.id === Role.User).show = true;
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }
    if (this.page.roles.Advertiser.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Advertiser).show = true;
    }
    if (this.page.roles.Moderator.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Moderator).show = true;
    }
    if (this.page.roles.Editor.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Editor).show = true;
    }
    if (this.page.roles.Analyst.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Analyst).show = true;
    }
    if (this.page.roles.User.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.User).show = true;
    }
    if (this.page.roles.Jobs.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Jobs).show = true;
    }
    if (this.page.roles.Projects.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Projects).show = true;
    }
    if (this.page.roles.Messages.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Messages).show = true;
    }
    if (this.page.roles.Notifications.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Notifications).show = true;
    }
    if (this.page.roles.Payments.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Payments).show = true;
    }
    if (this.page.roles.Marketing.find(r => r.userID === this.authService.userID)) {
      this.privacies.find(rt => rt.id === Role.Marketing).show = true;
    }


  }

  public get isProjects() {
    return this.page?.roles?.Projects?.find(res => res.userID === this.authService.userID);
  }

  public get isMessaging() {
    return this.page?.roles?.Messages?.find(res => res.userID === this.authService.userID);
  }

  public get isJobs() {
    return this.page?.roles?.Jobs?.find(res => res.userID === this.authService.userID);
  }

  public get isNotifications() {
    return this.page?.roles?.Notifications?.find(res => res.userID === this.authService.userID);
  }
  public get isPayment() {
    return this.page?.roles?.Payments?.find(res => res.userID === this.authService.userID);
  }
  public get isOwner() {
    return this.page?.roles?.Owner?.find(res => res.userID === this.authService.userID);
  }
  public get isAdmin() {
    return this.page?.roles?.Admin?.find(res => res.userID === this.authService.userID);
  }
  public get isEditor() {
    return this.page?.roles?.Editor?.find(res => res.userID === this.authService.userID);
  }
  public get isModerator() {
    return this.page?.roles?.Moderator?.find(res => res.userID === this.authService.userID);
  }
  public get isAdvertiser() {
    return this.page?.roles?.Advertiser?.find(res => res.userID === this.authService.userID);
  }
  public get isAnalyst() {
    return this.page?.roles?.Analyst?.find(res => res.userID === this.authService.userID);
  }
  public get isUser() {
    return this.page?.roles?.User?.find(res => res.userID === this.authService.userID);
  }
  public get isMarketing() {
    return this.page?.roles?.Marketing?.find(res => res.userID === this.authService.userID);
  }




}
