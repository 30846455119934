import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { PagesService } from "../services/pages.service";
import { NotificationHelperService } from "./notification-helper.service";

import * as _ from "underscore";
import { Page } from "../interfaces/model";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})

export class PageHelperService {

    constructor(public authService: AuthService,
    public notificationHelper: NotificationHelperService,
    public pageService: PagesService
    ) {}

    loadRequestingPage(userId): Observable<any> {
        return new Observable(observer => {
            let pagesArray = [];
            this.pageService.allPages.subscribe(pageRequest => {
                let filteredRequests = _.filter(pageRequest, ((req: Page) => {

                }));
                filteredRequests.forEach(request => {
                    if((request.userId === userId) && request.status === Status.Added) {
                        pagesArray.unshift(request);
                    } else if (request.friendId === userId && request.status === Status.Pending) {
                        pagesArray.unshift(request);
                    }
                });
                if (pagesArray.length === 0) {
                    observer.next(pagesArray);
                    return;
                  }
            })
        })
    }

    getPages(): Observable<any> {
      return new Observable(observer => {
        this.pageService.getPages().pipe(shareReplay(1)).subscribe(res => {
          observer.next(res);
        })
      })
    }
}

export enum Status {
    Added = 1,
    Pending = 0
}
