import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { EditDetailsData, EditJobsDialogComponent } from "../components/jobs/edit-jobs-dialog/edit-jobs-dialog.component";
import { Duration } from "../shared/enums/enums-model";
import { Job, Page, Project } from "../shared/interfaces/model";
import { User } from "../shared/interfaces/user.model";
import { ArraysService } from "../shared/services/arrays-service/title-service";
import { AuthService } from "../shared/services/auth.service";
import { CountryDataService } from "../shared/services/country-data.service";
import { JobsService } from "../shared/services/jobs.service";
import { PagesService } from "../shared/services/pages.service";
import { ProjectService } from "../shared/services/project.service";
import { SenderService } from "../shared/services/sender.service";
import { ToastService } from "../shared/services/toast.service";
import { UserService } from "../shared/services/user.service";

@Component({
  selector: "app-project-details",
  templateUrl: "./project-details.component.html",
  styleUrls: ["./project-details.component.scss"],
})
export class ProjectDetailsComponent implements OnInit {
  public project = new Project();
  public job = new Job();
  public user: User;
  countryJsonArray;
  public remote;
  public employmentTypeString;
  public jobDurationString;
  public countryJsonObject;
  public currencyObject;
  public paymentID;
  userCreatedJob: User;


  selectedSkills: boolean;
  selectedQualifications: boolean;
  selectedDriveLicense: boolean;
  selectedLanguage: boolean;
  selectedPCKnowledge: boolean;
  selectedTasks: boolean;
  selectedSecondGroup: boolean;
  selectedFirstGroup: boolean;
  selectedSkillsOffer: boolean;
  page: Page;
  dataArr;
  dataArrJobs;


  public isProject: boolean = false;
  public isUser: boolean = false;
  public isJob: boolean = false;

  constructor(
    public router: Router,
    public receiver: SenderService,
    public jobService: JobsService,
    private http: HttpClient,
    private dialog: MatDialog,
    private userAuth: AuthService,
    private senderService: SenderService,
    public userService: UserService,
    private countryDataService: CountryDataService,
    private projectService: ProjectService,
    private arrayService: ArraysService,
    private pageService: PagesService,
  ) {}

  ngOnInit(): void {
    this.countryDataService.countryData$
      .pipe()
      .subscribe((res) => (this.countryJsonArray = res));
    this.arrayService.showJobDuration();
    this.arrayService.showEmploymentTypes();
    this.arrayService.showSalary();
    this.arrayService.showRemote();
    this.userService.user().subscribe((user) => {
      this.user = user;
    });
    if (localStorage.getItem("projectID")) {
      console.log("isProject");
      this.isProject = true;
      this.isUser = true;
      this.projectService
        .getProject(localStorage.getItem("projectID"))
        .subscribe((t) => {
          this.project = t;
          if (this.project.pageID) {
            this.pageService.getPage(this.project.pageID).subscribe(t => {
              this.page = t;
              this.dataArr = this.page.roles.Projects;
            })
            this.userService.getUserContactData(this.project.userID).subscribe(t => {
              this.userCreatedJob = t;
            })
          } else {
            this.userService.getUserContactData(this.project.userID).subscribe(t => {
              this.userCreatedJob = t;
            })
          }
          this.getRemoteID(this.project?.remote);
          this.getEmploymentTypeID(this.project?.employmentType);
          this.getJoDurationID(this.project?.duration);
          this.getCountryID(this.project?.c);
          this.getCurrencyID(this.project?.currency);
          this.getPaymentFormID(this.project?.incomes.s);
        });
    } else if (localStorage.getItem("jobID")) {
      console.log("isJob")
      this.jobService.getJob(localStorage.getItem("jobID")).subscribe((job) => {
        this.isProject = false;
        this.isUser = true;
        this.isJob = true;
        this.job = job;
        if (this.job.pageID) {
          this.pageService.getPage(this.job.pageID).subscribe(t => {
            this.page = t;
            this.dataArrJobs = this.page.roles.Jobs;
          })
          this.userService.getUserContactData(this.job.userID).subscribe(t => {
            this.userCreatedJob = t;
          })
        } else {
          this.userService.getUserContactData(this.job.userID).subscribe(t => {
            this.userCreatedJob = t;
          })
        }
        this.getRemoteID(this.job?.remote);
        this.getEmploymentTypeID(this.job?.employmentType);
        this.getJoDurationID(this.job?.duration);
        this.getCountryID(this.job?.c);
        this.getCurrencyID(this.job?.currency);
        this.getPaymentFormID(this.job?.incomes.s);
      });
    }
  }
  getRemoteID(id) {
    this.remote = this.arrayService.remoteArray.find(
      (test) => test.id === id
    )?.description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.arrayService.employmentType.find(
      (t) => t.id === id
    )?.description;
  }
  getJoDurationID(id) {
    this.jobDurationString = this.arrayService.jobDurationArray.find(
      (t) => t.id === id
    )?.description;
  }

  getCountryID(id) {
    this.countryJsonObject = this.countryJsonArray.find((t) => t.ISO_2 === id);
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(
      (t) => t.currency.Alphabeticcode === id
    );
  }

  getPaymentFormID(id) {
    this.paymentID = this.arrayService.salary.find(
      (t) => t.id === id
    )?.description;
  }

  removeClick() {
    this.selectedSkills = false;
    this.selectedQualifications = false;
    this.selectedDriveLicense = false;
    this.selectedLanguage = false;
    this.selectedPCKnowledge = false;
    this.selectedTasks = false;
    this.selectedSecondGroup = false;
    this.selectedFirstGroup = false;
    this.selectedSkillsOffer = false;
  }
  selectSecondGroup() {
    this.selectedSecondGroup = true;
  }
  selectFirstGroup() {
    this.selectedFirstGroup = true;
  }
  selectSkills() {
    this.selectedSkills = true;
  }
  selectQualifications() {
    this.selectedQualifications = true;
  }
  selectDriveLicense() {
    this.selectedDriveLicense = true;
  }
  selectLanguage() {
    this.selectedLanguage = true;
  }
  selectPCKnowledge() {
    this.selectedPCKnowledge = true;
  }
  selectTasks() {
    this.selectedTasks = true;
  }
  selectSkillsOffer() {
    this.selectedSkillsOffer = true;
  }

  editPCKnowledge(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.pcKnowledge = res.pcKnowledge;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }


  editJobDriveLicense(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.driveLicense = res.driveLicense;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobLanguage(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.language = res.language;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editJobTasks(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.skillsOffer = res.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editSecondGroup(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res: Job) => {
      if (res) {
        this.job.incomes.budget = res.incomes.budget;
        this.job.incomes.s = res.incomes.s;
        this.job.currency = res.currency;
        if (res.duration === Duration.unlimited) {
          this.job.duration = Duration.unlimited;
          this.job.startDate = res.startDate;
        } else if (res.duration === Duration.temporary) {
          this.job.duration = Duration.temporary;
          this.job.startDate = res.startDate;
        }
        this.job.currency = res.currency;
        this.getCurrencyID(this.job.currency);
        this.getJoDurationID(this.job.duration);
        this.getPaymentFormID(this.job.incomes.s);

       //this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editFirstGroup(data: EditDetailsData)
  {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.positionTitle = res.positionTitle;
        this.job.employmentType = res.employmentType;
        this.job.remote = res.remote;
        this.job.street = res.street;
        this.job.postalCode = res.postalCode;
        this.job.city = res.city;
        this.job.c = res.country;
        this.job.state = res.state;
        this.getCountryID(this.job.c);
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobDescription(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.job.description = res.description;
          this.jobService.updateJob(this.job._id, this.job);
        }
      });
  }

  editQualifications(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.job.qualifications = res.qualifications;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  publicJob() {
    this.router.navigateByUrl(this.router.url + "/public");
  }
  public get isMyJob() {
    return this.project?.userID === this.userAuth.userID ? this.project?.userID === this.userAuth.userID : this.job.userID === this.userAuth.userID;
  }

  changeUserOfProject(id) {
    this.project.userID = id;
    this.projectService.updateProject(this.project._id, this.project);
    this.userService.getUserContactData(id).subscribe(t => {
      this.userCreatedJob = t;
    })
  }

  changeUserOfJobs(id) {
    this.job.userID = id;
    this.jobService.updateJob(this.job._id, this.job);
    this.userService.getUserContactData(id).subscribe(t => {
      this.userCreatedJob = t;
    })

  }

  deleteProjectTasks() {
    this.project.tasks = [];
    this.project.company = false;
   // this.projectService.updateProject(this.project._id, this.project)
  }

  deleteProjectFreelancer() {
    this.project.skillsOffer = [];
    this.project.pcKnowledge = [];
    this.project.language = [];
    this.project.freelancer = false;
  //  this.projectService.updateProject(this.project._id, this.project)
  }



}
