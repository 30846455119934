import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/assets/environments/environment";
import { HeaderService } from "./header.service";
import { ToastService } from "./toast.service";
import { Notification} from "../interfaces/model"

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private apiBaseURL = environment.backend;

  constructor(private http: HttpClient,
    private toastService: ToastService,
    private header: HeaderService) { }

  createNotification(newRequest: Notification) {
    return this.http.post<Notification>(this.apiBaseURL + "/createNotification", newRequest, this.header.requestHeaders())
    .pipe(map(res => {
      return res;
    }))
  }

  getAllNotification() {
    return this.http.get<any[]>(this.apiBaseURL + "/notifications");
  }
  updateAll(id, t) {
    return this.http.put(`${this.apiBaseURL}/notifications/${id}`, t);
  }
}
