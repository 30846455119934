import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/modals/confirmation-dialog/confirmation-dialog.component';
import { Privacy, StatusFollow } from 'src/app/shared/enums/enums-model';
import { Page } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { PrivacyActivity } from 'src/app/shared/services/privacy-activity.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-connections',
  templateUrl: './user-connections.component.html',
  styleUrls: ['./user-connections.component.scss']
})
export class UserConnectionsComponent implements OnInit {

  @Input() user: User;
  @Input() loggedUser: any;
  hasFollow = false;
  isPending = false;
  isPendingFollow = false;
  public page: Page;
  public dataList$: Observable<any>;
  userData: any;


  @Input() following: boolean = false;
  @Input() followers: boolean = false;
  @Input() contacts: boolean = false;
  @Input() userFollowing: any;

  userContact: User;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;

  constructor(public authService: AuthService,
    public userService: UserService,
    private pageService: PagesService,
    private dialog: MatDialog,
    private router: Router,
    private sendPageData: SenderService,


  ) { }

  ngOnInit(): void {

    if (this.followers) {
      this.userService.getUserContactData(this.userFollowing).subscribe(t => {
        this.user = t;
        this.hasFollow = this.loggedUser.followings.data.some(s => s.userId === this.user._id && s.s === 1 || s.userId === this.user._id && s.s === 2);
        this.isPendingFollow = this.loggedUser.followings.data.some(s => s.userId === this.user._id && s.s === 0);
      })


    }
    if (this.following) {
      if (this.userFollowing.userId && this.userFollowing.s !== StatusFollow.Block) {
        this.userService.getUserContactData(this.userFollowing.userId).subscribe(t => {
          this.user = t;
          this.hasFollow = this.loggedUser.followings.data.some(s => s.userId === this.user._id && s.s === 1 || s.userId === this.user._id && s.s === 2);
          this.isPendingFollow = this.loggedUser.followings.data.some(s => s.userId === this.user._id && s.s === 0);
        })

      } else if (this.userFollowing.pageId) {
        this.pageService.getPage(this.userFollowing.pageId).subscribe(t => {
          this.page = t;
          this.hasFollow = this.loggedUser.followings.data.some(s => s.pageId === this.page._id && s.s === 1);
          this.isPendingFollow = this.loggedUser.followings.data.some(s => s.pageId === this.page._id && s.s === 0);


        })
      }
    } else if (this.contacts) {
      if (!!this.userFollowing.userId) {
        this.userService.getUserContactData(this.userFollowing.userId).subscribe(t => {
          this.userContact = t;
          this.hasFollow = this.loggedUser.following.data.some(s => s.userId === this.userContact._id && s.s === 1 || s.userId === this.userContact._id && s.s === 2);

        })
      }


    }

  }
  public get checkUser() {
    return this.authService.userID === this.user?._id;
  }


  unfollowUserContact() {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.componentInstance.message = "You will no longer receive notifications or see relevant updates in your feed";
    this.confirmationDialog.componentInstance.header = "Unfollow";
    this.confirmationDialog.componentInstance.confirmButtonText = "Unfollow";
    this.confirmationDialog.afterClosed().subscribe(result => {

      if (result) {

        this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {

          const userContact = user.following.data.findIndex(t => t.userId === this.userFollowing._id);
          user.following.data.splice(userContact, 1);

          const userForStatus = this.userFollowing.following.data.findIndex(t => t.userId === this.authService.userID);

          this.userFollowing.following.data[userForStatus].s = 1;
          this.userFollowing.following.data[userForStatus].timestamp = new Date();
          this.userService.updateUser(user._id, user);
          this.userService.updateUser(this.userFollowing._id, this.userFollowing);



        });


      }

    });

    this.ngOnInit();

  }


  unfollowPage() {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.componentInstance.message = "You will no longer receive notifications or see relevant updates in your feed";
    this.confirmationDialog.componentInstance.header = "Unfollow";
    this.confirmationDialog.componentInstance.confirmButtonText = "Unfollow";
    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {
          const userFollowing = user.following.data.findIndex(t => t.pageId === this.page._id);
          user.following.data.splice(userFollowing, 1);
          user.following.data.filter(t => {
            this.hasFollow = t.pageId === this.page._id
          });
          this.userService.updateUser(user._id, user);

        });
        this.page.followers.counter = this.page.followers.counter - 1;
        this.pageService.updatePage(this.page._id, this.page);


      }
    });

  }

  unfollowUser() {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.componentInstance.message = "You will no longer receive notifications or see relevant updates in your feed";
    this.confirmationDialog.componentInstance.header = "Unfollow";
    this.confirmationDialog.componentInstance.confirmButtonText = "Unfollow";
    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {


          const userFollowing = user.following.data.findIndex(t => t.userId === this.user._id);
          user.following.data.splice(userFollowing, 1);
          user.following.data.filter(t => {
            this.hasFollow = t.userId === this.user._id
          });
          this.userService.updateUser(user._id, user);
        });
        this.user.followers.counter = this.user.followers.counter - 1;
        this.userService.updateUser(this.user._id, this.user);

      }
    });
  }

  followUser(user: User | any) {
    const contact = {
      userId: user?._id,
      timestamp: new Date(),
      s: StatusFollow.Contact
    }

    const followingWithPrivacy = {
      userId: user?._id,
      timestamp: new Date(),
      s: StatusFollow.Private
    }

    this.userService.getUserFollowing(user._id).subscribe(res => {
      const userFollowing = res.data.find(t => t.userId === this.loggedUser.user._id);
      this.userService.getUserEM(this.authService.userID).subscribe(userEM => {
        if (user.pAccount) {
          this.loggedUser.followings.data.unshift(followingWithPrivacy);
          if (typeof user?.notifications?.followers?.counter === 'undefined') {
            const notifications = {
              followers: {
                counter: +1,
                seen: false
              }
            }
            user.notifications = notifications;
          }
          this.userService.updateUser(user._id, user);
          this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);
        } else {
          if (userFollowing.s === 1) {
            userFollowing.s = StatusFollow.Contact;
            this.loggedUser.followings.data.unshift(contact);
            this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);
            this.userService.updateFollowing(res._id, res);
            userEM.followers.counter = user.followers.counter + 1;
            this.userService.updateuUser(userEM._id, userEM);
          }
        }
      })



      console.log(res, "res")
    })

/*    this.userService.getUserEM(user._id).subscribe(res => {
     const  checkUser = this.loggedUser?.followings?.data.find(t => t.userId === this.loggedUser._id);
     const userUser = res.user;

      else {

        if (checkUser && checkUser.s === 1) {
          checkUser.s = StatusFollow.Contact;
          this.loggedUser.followings.data.unshift(contact);

          this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);
          this.userService.updateUser(user._id, user);

        } else {

          const following = {
            userId: user?._id,
            timestamp: new Date(),
            s: StatusFollow.Public
          }
          if (user.pAccount) {
            this.loggedUser.followings.data.unshift(followingWithPrivacy);
            this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);
          } else {
            userUser.followers.counter = userUser.followers.counter + 1;
            this.loggedUser.followings.data.unshift(following);
          }
          if (typeof userUser?.notifications?.followers?.counter === 'undefined') {
            const notifications = {
              followers: {
                counter: +1,
                seen: false
              }
            }
            userUser.notifications = notifications;
          } else {
            userUser.notifications.followers.counter = userUser.notifications.followers.counter + 1;
          }
          this.userService.updateUser(this.loggedUser.followings._id, this.loggedUser.followings);
          this.userService.updateuUser(userUser._id, userUser);
        }


      }
    }) */





  }

goToObj(user, isPage: boolean) {
  if (isPage) {
    return
  } else {
    this.router.navigate([user.userUrl, "start"]);
    localStorage.setItem("userProfileID", user._id);

  }
}

goToUser(user) {
  if(this.page) {
    const pageID = this.page._id
    localStorage.setItem("pageID", pageID)
    this.pageService.changeMessage(pageID);
    this.sendPageData.send(this.page);
    this.router.navigate(['', this.page.pageUrl]);

  } else  {
    console.log(user, "user")
    this.router.navigate([user.userUrl, "start"]);
    localStorage.setItem("userProfileID", user._id);

  }
}


  followPage(page: Page) {
    console.log(page, "user")
  }


  removeUser(user: User) {
    const findUserIndex = user.following.data.findIndex(t => t.userId === this.loggedUser._id);
    user.following.data.splice(findUserIndex, 1);

    this.loggedUser.followers.counter = this.loggedUser.followers.counter - 1;
      this.userService.updateUser(user._id, user);
     this.userService.updateUser(this.loggedUser._id, this.loggedUser);

  }



  blockUser(user: User) {
    this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent);
    this.confirmationDialog.componentInstance.message = `${user?.firstName}  will no longer be able to:\n
    See your posts on your timeline
    Tag you
    Invite you to events or groups
    Message you
    Add you as a friend
    If ${user?.firstName} moderates your live streams as a community manager, blocking him will remove him as a community manager.`
    this.confirmationDialog.componentInstance.header = `${'Block'} ${user?.firstName} ${user?.lastName}`;
    this.confirmationDialog.componentInstance.confirmButtonText = "Confirm";
    this.confirmationDialog.afterClosed().subscribe(result => {
      if (result) {
        this.userService.getUserCV(user._id).subscribe();
        this.dataList$ = this.userService.dataList;
        this.dataList$.subscribe((res: any) => {
          this.userData = res;

          const data =  this.loggedUser.followings.data.find(t => t.userId === user._id);

          if (data === -1) {

          } else {
            this.loggedUser.followings.data.splice(data, 1);

            const blockUser = {
              userId: this.authService.userID,
              timestamp: new Date(),
              s: StatusFollow.Block
            }
            const blockUser2 = {
              userId: this.userData.user._id,
              timestamp: new Date(),
              s: StatusFollow.Block
            }
            this.userData.followings.data.unshift(blockUser);
            this.loggedUser.followings.data.unshift(blockUser2);
           this.userService.updateFollowing(this.userData.followings._id, this.userData.followings);
          this.userService.updateFollowing(this.loggedUser.followings._id, this.loggedUser.followings);

          }

        });




    /*    this.userService.getUserContactData(this.authService.userID).subscribe((user: User) => {

          const blockedUser = user.following?.data?.findIndex(t => t.userId === this.user._id);

          if (blockedUser === -1) {
          } else {
            user.following.data.splice(blockedUser, 1);
          }

          const outBlockedUser = this.user.following?.data?.find(t => t.userId === user._id);

          if (outBlockedUser) {
            outBlockedUser.s = StatusFollow.Block;
          } else {
            const blockUser = {
              userId: this.authService.userID,
              timestamp: new Date(),
              s: StatusFollow.Block
            }

            this.user.following.data.unshift(blockUser);
          }

          this.userService.updateUser(this.user._id, this.user);
          this.userService.updateUser(user._id, user);

        }) */


      }
    });
  }

}
