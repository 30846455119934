
<main>

  <div  *ngIf="router.url.endsWith('apply') && router.url.includes('jobs') && !router.url.includes('projects')">
    <div class="card">
      <div class="card-body">
        <div class="flex column col-md-12">
          <div class="flex">
            <div class="flex  mb-2 col-md-2">
              <span>{{'qualifications.name' | translate}}:</span>
            </div>
            <div class="row col-md-12">
            <div class="col-md-10 px-0" *ngIf="contactedUser">
              <input class="form-control col-md-12" [(ngModel)]="contactedUser?.firstName + ' ' + contactedUser.lastName" disabled>
            </div>
            <div class="col-md-10 px-0"  *ngIf="!contactedUser">
              <input class="form-control col-md-12" [(ngModel)]="user?.firstName + ' ' + user.lastName" disabled>
            </div>
            <div class="col-md-1 ms-2 mb-2">
                <button class="btn btn-light" style="border: 1px solid;" (click)="goToCV()">{{'header.cv' | translate}}</button>
            </div>
          </div>
          </div>
          <div class="flex mb-2">
            <div class="col-md-2 me-2 px-0">
              <span>{{'job.jobTitle' | translate}}:</span>
            </div>
            <div class="col-md-12" *ngIf="contactedUser">
              <input class="form-control col-md-12" [ngModel]="userJob?.positionTitle" disabled>
            </div>
            <div class="col-md-12" *ngIf="!contactedUser">
              <input class="form-control col-md-12" [ngModel]="job?.positionTitle" disabled>
            </div>
          </div>
          <div class="flex mb-2">
            <div class="col-md-2 me-2 px-0">
              <span>{{'job.message' | translate}}:</span>
            </div>
            <div class="col-md-12">
              <quill-editor class="content-editor" [modules]="editorOptions" [(ngModel)]="jIssue.description">
              </quill-editor>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer" *ngIf="contactedUser">
        <button type="button" class="btn btn-outline-dark" (click)="contact()">{{'job.apply' | translate}}</button>
      </div>
      <div class="modal-footer" *ngIf="!contactedUser">
        <button type="button" class="btn btn-outline-dark" (click)="apply()">{{'job.apply' | translate}}</button>
      </div>
      </div>

  </div>
  <div  *ngIf="router.url.endsWith('cancel')">
    <button class="btn btn-light">{{'cancel' | translate}}</button>
    <div class="card">
      <div class="card-body">
        <div class="flex column col-md-12">
          <div class="flex">
            <div class="flex  mb-2 col-md-2">
              <span>{{'qualifications.name' | translate}}:</span>
            </div>
            <div class="row col-md-12">
            <div class="col-md-10 px-0" *ngIf="contactedUser && !router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [(ngModel)]="contactedUser?.firstName + ' ' + contactedUser.lastName" disabled>
            </div>
            <div class="col-md-10 px-0" *ngIf="router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [(ngModel)]="user?.firstName + ' ' + user.lastName" disabled>
            </div>
            <div class="col-md-10 px-0"  *ngIf="!contactedUser && !router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [(ngModel)]="user?.firstName + ' ' + user.lastName" disabled>
            </div>
            <div class="col-md-1 ms-2 mb-2">
                <button class="btn btn-light" style="border: 1px solid;" (click)="goToCV()">{{'header.cv' | translate}}</button>
            </div>
          </div>
          </div>
          <div class="flex mb-2">
            <div class="col-md-2 me-2 px-0">
              <span>{{'job.jobTitle' | translate}}:</span>
            </div>
            <div class="col-md-12" *ngIf="contactedUser && !router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [ngModel]="userJob?.positionTitle" disabled>
            </div>
            <div class="col-md-12" *ngIf="router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [ngModel]="userObject?.positionTitle" disabled>
            </div>
            <div class="col-md-12" *ngIf="!contactedUser && !router.url.endsWith('cancel')">
              <input class="form-control col-md-12" [ngModel]="job?.positionTitle" disabled>
            </div>
          </div>
          <div class="flex mb-2">
            <div class="col-md-2 me-2 px-0">
              <span>{{'job.message' | translate}}:</span>
            </div>
            <div class="col-md-12">
              <quill-editor class="content-editor" [modules]="editorOptions" [(ngModel)]="jIssue.description">
              </quill-editor>
            </div>
          </div>

        </div>
      </div>
      <div class="modal-footer" *ngIf="contactedUser && !router.url.endsWith('cancel')">
        <button type="button" class="btn btn-outline-dark" (click)="contact()">{{'job.apply' | translate}}</button>
      </div>
      <div class="modal-footer" *ngIf="router.url.endsWith('cancel')">
        <button type="button" class="btn btn-outline-dark" (click)="sentBack()">{{'sent' | translate}}</button>
      </div>
      <div class="modal-footer" *ngIf="!contactedUser && !router.url.endsWith('cancel')">
        <button type="button" class="btn btn-outline-dark" (click)="apply()">{{'job.apply' | translate}}</button>
      </div>
      </div>

  </div>
  <div  *ngIf="router.url.endsWith('apply') && router.url.includes('projects')">
      <!-- Container START -->
      <div class="container">
        <div class="row">

          <!-- Sidenav START -->
          <div class="col-lg-3">

            <!-- Advanced filter responsive toggler START -->
            <!-- Divider -->
            <div class="d-flex align-items-center mb-4 d-lg-none">
              <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                <span class="btn btn-primary"><i class="bi bi-sliders"></i></span>
                <span class="h6 mb-0 fw-bold d-lg-none ms-2">{{'projectText' | translate}}</span>
              </button>
            </div>
            <!-- Advanced filter responsive toggler END -->

            <nav class="navbar navbar-light navbar-expand-lg mx-0">
              <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
                <!-- Offcanvas header -->
                <div class="offcanvas-header">
                  <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <!-- Offcanvas body -->
                <div class="offcanvas-body p-0">
                  <!-- Card START -->
                  <div class="card w-100">
                    <!-- Card body START -->
                    <div class="card-body">

                    <!-- Side Nav START -->
                    <ul class="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                      <li class="nav-item" data-bs-dismiss="offcanvas">
                        <a class="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab"> <img class="me-2 h-20px fa-fw" src="assets/images/icon/person-outline-filled.svg" alt=""><span>{{'projectText' | translate}} </span></a>
                      </li>

                    </ul>
                    <!-- Side Nav END -->

                  </div>
                  <!-- Card body END -->
                  <!-- Card footer -->

                  </div>
                <!-- Card END -->
                </div>
                <!-- Offcanvas body -->

                <!-- Helper link START -->

              </div>
            </nav>
          </div>
          <!-- Sidenav END -->

            <!-- Main content START -->
            <div class="col-lg-6 vstack gap-4">
              <!-- Setting Tab content START -->
              <div class="tab-content py-0 mb-0">

                <!-- Account setting tab START -->
                <div class="tab-pane show active fade" id="nav-setting-tab-1">
                  <!-- Account settings START -->
                  <div class="card mb-4">

                    <!-- Title START -->
                    <div class="card-header border-0 pb-0">
                      <h1 class="h5 card-title">{{'projectText' | translate}}</h1>
                    </div>
                    <!-- Card header START -->
                    <!-- Card body START -->
                    <div class="card-body">
                      <!-- Form settings START -->
                      <form class="row g-3" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && applyForProject()">
                        <div class="col-sm-12">
                          <div class="col-sm-10">
                            <label class="form-label">{{'job.apply' | translate}} as</label>
                            <select name="selectedValue" id="selectedValue" [(ngModel)]="isSelected"
                            class="col-md-12 form-control" #selectedValue="ngModel"
                            [ngClass]="{ 'is-invalid': myForm.submitted && selectedValue.invalid }" required
                            required  (ngModelChange)="test($event)">
                            <option [ngValue]="{user: user, isUser: true}">{{user?.firstName}} {{user?.lastName}}</option>
                            <option *ngFor="let employment of arrayPages" [ngValue]="employment._id">
                              {{ employment?.name }}
                            </option>
                          </select>
                          <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
                            <div *ngIf="selectedValue.errors.required">
                              {{ "career.employmentType" | translate }}
                              {{ "error.isRequired" | translate }}
                            </div>
                          </div>
                          </div>
                          <div class="d-flex justify-content-start col-sm-2" *ngIf="selectedUser">
                            <button (click)="goToCV()" class="btn btn-light"><i class="bi bi-paperclip"></i>{{'header.cv' | translate}}</button>
                          </div>
                        </div>



                        <!-- First name -->

                        <!-- User name -->
                        <div class="col-sm-12">
                          <label class="form-label">{{'project.title' | translate}}</label>
                          <input [ngModel]="project?.positionTitle" type="text" name="positionTitle" #positionTitle="ngModel" class="form-control" placeholder="" disabled >
                        </div>

                        <div class="col-12">
                          <label class="form-label">{{'job.message' | translate}}</label>
                            <quill-editor name="message"
                            #message="ngModel" class="content-editor" [modules]="editorOptions" [(ngModel)]="description">
                            </quill-editor>
                          <small>Character limit: 300</small>
                        </div>
                        <!-- Button  -->
                        <div class="col-12 text-end">
                          <button *ngIf="!isApplied" [disabled]="!myForm.form.valid" type="submit" class="btn btn-sm btn-primary mb-0">{{'job.apply' | translate}}</button>
                          <button *ngIf="!isApplied" type="button" (click)="cancel()" class="btn btn-sm btn-primary mb-0">{{'cancel' | translate}}</button>

                          <button *ngIf="isApplied" type="button" (click)="goToMessages()" class="btn btn-sm btn-primary mb-0">{{'job.message' | translate}}</button>
                          <button *ngIf="isApplied" type="button" (click)="cancel()" class="btn btn-sm btn-primary mb-0">{{'cancel' | translate}}</button>

                        </div>
                      </form>
                      <!-- Settings END -->
                    </div>
                  <!-- Card body END -->
                  </div>
                  <!-- Account settings END -->

                </div>
                <!-- Account setting tab END -->

                <!-- Close account tab END -->

              </div>
              <!-- Setting Tab content END -->
            </div>

        </div> <!-- Row END -->
      </div>
      <!-- Container END -->


  </div>
</main>


