import { Injectable } from "@angular/core";
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { UserService } from "../shared/services/user.service";

@Injectable()
export class AuthGuard {
  constructor(private router: Router,
    private authService: AuthService,
  private userService: UserService) {
  }
  canActivate() {
    if (this.authService.isLoggedIn) {
      const authID = this.authService.userID;
 // this.userService.userDataLogin(this.authService.getUserData());

//  this.userService.getUserCV(authID).subscribe();

   return true;
    } else {
      this.router.navigate(['/login']);
      return false;

    }
  }

}
