import { Component, OnInit } from '@angular/core';
import { JIssue } from 'src/app/shared/interfaces/jira.model';
import { SenderService } from 'src/app/shared/services/sender.service';

@Component({
  selector: 'app-full-issue-details',
  templateUrl: './full-issue-details.component.html',
  styleUrls: ['./full-issue-details.component.scss']
})
export class FullIssueDetailsComponent implements OnInit {

  constructor(private senderService: SenderService) { }
  issue: JIssue;
  ngOnInit(): void {

    this.senderService.issueData$.subscribe((issue: any) => {
      this.issue = issue;
    });
  }

}
