<div style="width:1240px">

    <div class="col-md-12 px-0 pt-4 pb-2">
      <div class="col-md-4">
        <img src="../../../assets/avatar.png" height="40px" width="40px">
        <span class="pl-2 pr-2">{{pageName}}</span>
      </div>
  
    </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link"  [routerLinkActive]="['active']"  (click)="setPayment()">Billing Details <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['active']"  (click)="setTransaction()">Transactions</a>
          </li>
        </ul>
      </div>
    </nav>
  
    <div class="payments" *ngIf="isPayment">
      <div class="mat-elevation-z8 " id="htmlData">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">ID</th>
              <th scope="col">Company</th>
              <th scope="col">Type</th>
              <th scope="col">Account Number</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>

        <ng-container *ngFor="let user of users; let i = index">
          <tbody *ngFor="let creditHistory of user?.payment?.history; let i = index">
            <tr>
              <td>{{creditHistory.date | date:"mediumDate" }}</td>
              <td>{{creditHistory.invoiceID}}</td>
              <td class="showLink" (click)="goToUser(user)">{{user.firstName}} {{user.lastName}}</td>
              <td>{{creditHistory.type_}}</td>
              <td>{{creditHistory.account_number}}</td>
              <td >{{creditHistory.amount}}</td>
              <td  class="showLink">
                <i class="bi bi-box-arrow-up-right" (click)="goForPrint({user: user, creditHistory: creditHistory, isUser: true,  pageName: pageName, pageDetails: pageDetails})"></i>
              </td>
            </tr>
          </tbody>
        </ng-container>
        <ng-container *ngFor="let page of pages; let i = index">
          <tbody *ngFor="let creditHistory of page?.payment?.history; let i = index">
            <tr>
              <td>{{creditHistory.date | date:"mediumDate" }}</td>
              <td>{{creditHistory.invoiceID}}</td>
              <td class="showLink" (click)="goToPage(page)">{{page.name}}</td>
              <td>{{creditHistory.type_}}</td>
              <td>{{creditHistory.account_number}}</td>
              <td>{{creditHistory.amount}}</td>
              <td  class="showLink">
                <i class="bi bi-box-arrow-up-right" (click)="goForPrint({page: page, creditHistory: creditHistory, isPage: true, pageName: pageName, pageDetails: pageDetails})"></i>
              </td>
            </tr>
          </tbody>
        </ng-container>
        </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons 
                   aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
    </div>
  
    <div class="transactions" *ngIf="isTransactions">
      <div style="display: flex;
      flex-direction: row;
      justify-content: space-between;">
      <div style="display: flex;">
      <mat-form-field color="accent" appearance="fill" style="margin-right: 1rem;">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 color="primary"></mat-datepicker>
      </mat-form-field>
      
      <mat-form-field color="accent" appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="picker2">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary"></mat-datepicker>
      </mat-form-field>
    </div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Sort by</mat-label>
          <mat-select [(value)]="selected">
            <mat-option value="option1">Option 1</mat-option>
            <mat-option value="option2">Option 2</mat-option>
            <mat-option value="option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">ID</th>
            <th scope="col">Company</th>
            <th scope="col">Type</th>
            <th scope="col">Account Number</th>
            <th scope="col">Amount</th>
            <th scope="col">Reference</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <ng-container *ngFor="let user of users; let i = index">
        <tbody *ngFor="let creditHistory of user.payment?.transactions; let i = index">
          <tr>
            <td>{{creditHistory.date | date:"mediumDate" }}</td>
            <td>{{creditHistory.invoiceID}}</td>
            <td class="showLink" (click)="goToUser({user: user, creditHistory: creditHistory, isPage: true})">{{user.firstName}} {{user.lastName}}</td>
            <td>{{creditHistory.type_}}</td>
            <td>{{creditHistory.account_number}}</td>
            <td>{{creditHistory.amount}}</td>
            <td>{{creditHistory.reference}}</td>
            <td>{{creditHistory.status}}</td>
            <td  class="showLink">
              <i class="bi bi-box-arrow-up-right" (click)="goForPrint({user: user, creditHistory: creditHistory, isUser: true,  pageName: pageName, pageDetails: pageDetails})"></i>
            </td>
          </tr>
        </tbody>
        </ng-container>
        <ng-container *ngFor="let page of pages; let i = index">
          <tbody *ngFor="let creditHistory of page.payment?.transactions; let i = index">
            <tr>
              <td>{{creditHistory.date | date:"mediumDate" }}</td>
              <td>{{creditHistory.invoiceID}}</td>
              <td class="showLink" (click)="goToPage({page: page, creditHistory: creditHistory, isPage: true})">{{page.name}}</td>
              <td>{{creditHistory.type_}}</td>
              <td>{{creditHistory.account_number}}</td>
              <td>{{creditHistory.amount}}</td>
              <td>{{creditHistory.reference}}</td>
              <td>{{creditHistory.status}}</td>
              <td  class="showLink">
                <i class="bi bi-box-arrow-up-right" (click)="goForPrint({page: page, creditHistory: creditHistory, isPage: true, pageName: pageName, pageDetails: pageDetails})"></i>
              </td>
            </tr>
          </tbody>
          </ng-container>
      </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                 showFirstLastButtons 
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  </div>
    </div>
  </div>
