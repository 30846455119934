import { Injectable } from "@angular/core";
import {environment} from "../../../assets/environments/environment";
import {HttpClient} from "@angular/common/http";
import {NodeNotifications} from "../interfaces/node-notifications";
import {HeaderService} from "./header.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class NotificationNodeService {
  private apiBaseURL = environment.backend;

  constructor(private http:HttpClient,
              private header: HeaderService,) { }

  createRequest(newRequest: NodeNotifications, userID) {
    return this.http.post<NodeNotifications>(`${this.apiBaseURL}/nodenotification`, newRequest, {
      headers: {userID}
    }).pipe(map(res => {
        return res;
      }))
  }

  getAllNodeNotification(userID) {
    return this.http.get<any[]>(`${this.apiBaseURL}/nodenotifications/${userID}`);
  }
  update(updateRequest) {
    return this.http.put(`${this.apiBaseURL}/nodenotifications/${updateRequest.id}`, updateRequest).pipe(res => {
      return res;
    });
  }
}
