<div class="container px-3 py-3" *ngIf="!data.projectPCKnowledge && !data.jobProject">
  <div class="modal-header">
    <h3>{{'skills.pcKnowledge' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line" *ngFor="let pcKnowledge of data.pcKnowledge; let i = index">
      <div class="row col-md-12">
        <div class="container d-flex justify-content-center">
          <div class ="row col-md-12">
            <div class="col-md-7">
              <div class="form-outline">
                <input [(ngModel)]="pcKnowledge.name" class="form-control form-control-sm col-md-7">
              </div>
            </div>
            <div class="col-md-3">
              <app-star-rating stopPropagation [rating]="pcKnowledge.rate / 20" [starCount]="starCount"
              (ratingUpdated)="onRatingChanged(i, $event)" class="col-md-4">
</app-star-rating>            </div>
            <div class="col-md-1">
              <li class="bi bi-dash deleteIcon" (click)="deletePcKnowledge(i)"></li>

            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="container d-flex justify-content-center col-md-12">
      <div class ="row col-md-12">
        <div class="col-md-9">
          <div class="form-outline">
            <input  autofocus [(ngModel)]="name" class="form-control form-control-sm col-md-10">
          </div>
        </div>
        <div class="col-md-1" style="right: 4.1rem;
        position: absolute;">
            <button class="addIcon" [disabled]="!name" (click)="addPCKnowledge()"> <i class="bi bi-plus"></i></button>
          </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div class="container" *ngIf="data.projectPCKnowledge">
  <div class="modal-header">
    <h3>{{'skills.pcKnowledge' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line" *ngFor="let pcKnowledge of data.project?.pcKnowledge; let i = index">
      <div class="row col-md-12">
        <input [(ngModel)]="pcKnowledge.name" class="form-control form-control-sm col-md-7">
        <app-star-rating stopPropagation [rating]="pcKnowledge.rate / 20" [starCount]="starCount"
                         (ratingUpdated)="onRatingProjectChanged(i, $event)" class="col-md-4">
        </app-star-rating>
        <li class="fa fa-minus deleteIcon" (click)="deletePcKnowledge(i)"></li>
      </div>
    </div>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12 form-group">
          <input  autofocus [(ngModel)]="name" class="form-control form-control-sm col-md-10">
            <button class="addIcon" [disabled]="!name" (click)="addProjectPCKnowledge()"> <i class="bi bi-plus"></i></button>
        </div>
      </div>
    </li>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveProjectPCKnowledge()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div class="container" *ngIf="data.jobProject">
  <div class="modal-header">
    <h3>{{'skills.pcKnowledge' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line" *ngFor="let pcKnowledge of data.job?.pcKnowledge; let i = index">
      <div class="row col-md-12">
        <div class="container d-flex justify-content-center">
          <div class ="row col-md-12">
            <div class="col-md-7">
              <div class="form-outline">
                <input [(ngModel)]="pcKnowledge.name" class="form-control form-control-sm col-md-7">
              </div>
            </div>
            <div class="col-md-3">
              <app-star-rating stopPropagation [rating]="pcKnowledge.rate / 20" [starCount]="starCount"
              (ratingUpdated)="onRatingJobPCKnowledge(i, $event)" class="col-md-4">
</app-star-rating>
 </div>
            <div class="col-md-1">
              <li class="fa fa-minus deleteIcon" (click)="deletePcKnowledge(i)"></li>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-flex justify-content-center col-md-12">
      <div class ="row col-md-12">
        <div class="col-md-9">
          <div class="form-outline">
            <input  autofocus [(ngModel)]="name" class="form-control form-control-sm col-md-10">
          </div>
        </div>
        <div class="col-md-1" style="right: 4.1rem;
        position: absolute;">
            <button class="addIcon" [disabled]="!name" (click)="addJobPCKnowledge()"> <i class="bi bi-plus"></i></button>
          </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveJobPCKnowledge()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
