<div class="" (click)="selectProject()">
    <li data-bs-dismiss="offcanvas">
      <!-- Chat user tab item -->
      <a (click)="selectProject()" class="nav-link  text-start" id="chat-1-tab" data-bs-toggle="pill" role="tab">
        <div class="d-flex">
          <div class="flex-shrink-0 avatar avatar-story me-2 status-online">
            <img class="avatar-img rounded-circle" src="././assets/images/avatar/10.jpg" alt="">
          </div>
          <div class="flex-grow-1 d-block">
            <h6 *ngIf="!!project?.pageID" class="mb-0 mt-1">{{page?.name}}</h6>
            <h6 *ngIf="!project?.pageID" class="mb-0 mt-1"> {{user?.firstName}} {{user?.lastName}}</h6>
            <div class="small text-secondary">{{project?.positionTitle}}</div>
          </div>
        </div>
      </a>
    </li>
</div>
