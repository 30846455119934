<div class="container" *ngIf="!data.projectDriveLicense && !data.jobDriveLicense">
  <div class="modal-header">
    <h3>{{'skills.driveLicense' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line row">
      <div class="col-md-12">
        <app-chips [chips]="data.driveLicense" [removable]="true" [show]="true" [placeholder]="'skills.newDriveLicense' | translate"></app-chips>
        <a class="btn btn-empty text-align-start" (click)="addDriveLicense()"> <i class="bi bi-plus-circle-dotted me-1"></i>Add</a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div class="container" *ngIf="data.projectDriveLicense">
  <div class="modal-header">
    <h3>{{'skills.driveLicense' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line row">
      <div class="col-12 specialty">
        <div class="card__body d-flex column">
          <div class="d-flex align-items-center card__body__list">
            <div *ngFor="let sp of data.project.driveLicense; let i=index" class="d-flex justify-content-between pr-1">
              <div class="card__text">
                {{sp.name}}
                <span class="fa fa-remove" (click)="removeProjectDriveLicense(i)"></span>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center">
              <input [(ngModel)]="driveLicense" class="form-control-sm col-10">
              <button class="btn btn-light ms-1 col-1" [disabled]="!driveLicense" (click)="addProjectDriveLicense()">
                <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                    class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></span></button>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveDataProjectDriveLicense()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
<div class="container" *ngIf="data.jobDriveLicense">
  <div class="modal-header">
    <h3>{{'skills.driveLicense' | translate}}</h3>
  </div>
  <div class="modal-body">
    <div class="line row">
      <div class="col-12 specialty">
        <div class="card__body d-flex column">
          <div class="d-flex align-items-center card__body__list">
            <div *ngFor="let sp of data.job.driveLicense; let i=index" class="d-flex justify-content-between pr-1">
              <div class="card__text me-1 ms-1">
                {{sp.name}}
                <span class="fa fa-remove" (click)="delete(i)"></span>
              </div>
            </div>
            <div class="col-12 d-flex align-items-center">
              <input [(ngModel)]="driveLicense" class="form-control-sm col-10">
              <button class="btn btn-light ms-1 col-1" [disabled]="!driveLicense" (click)="addDriveLicense()">
                <span> <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                    class="bi bi-plus" viewBox="0 0 16 16">
                    <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg></span></button>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <mat-dialog-actions>
      <button type="button" class="btn btn-secondary" stopPropagation mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="button" class="btn btn-success" mat-button (click)="saveDataJobDriveLicense()">{{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</div>
