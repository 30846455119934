

<ng-container class="Unit-unit">
  <div (click)="setIndex(id)" [ngClass]="{ 'isActive': selectedIndex === id}">
    <div class="Line-line-container">
      <div class="Line-line-line">
        <div class="Field-field-fieldBase first-template-fieldField">
          <div class="Text-Text-wrapper">
            <div pageContent class="Text-Text-text">
              {{careerObj.startDate | date:'MM/yyyy'}}
              <div class="float-right" *ngIf="!careerObj.today">{{careerObj.endDate | date:'MM/yyyy'}}</div>
              <div class="float-right" *ngIf="careerObj.today">{{'present' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div pageContent class="Text-Text-text-wrapper">
              <b>{{careerObj.role}}</b>
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div pageContent class="Text-Text-text-wrapper">
              {{careerObj.name}}
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue">
          <div class="Text-Text-wrapper">
            <div pageContent class="Text-Text-text-wrapper" aria-multiline="true" [innerHTML]="careerObj.description">
            </div>
          </div>
        </div>
        <div class="Field-field-fieldBase first-template-fieldValue" *ngIf="careerObj.showCompanyUrl">
          <div class="Text-Text-wrapper">
            <div pageContent class="Text-Text-text-wrapper">
              {{careerObj | json}}
              <a target="_blank" [href]="careerObj.companyUrl">{{careerObj.companyUrl}}
              </a>
            </div>
          </div>
        </div>

        <ng-container pageContent *ngIf="selectedIndex === id">
          <div clickOutside (clickOutside)="removeClick()">
            <ul>
              <li class="bi bi-pencil addIconTop" (click)="editData({edit: true, career: careerObj, model: model})">
              </li>
              <li class="bi bi-plus addIconBottom" (click)="addData({edit: false, model: model, career: false})"></li>
              <button [disabled]="career.subCategories.length < 2" (click)="deleteSubCategory(id)" class="btn"><i
                  class="bi bi-trash deleteIconRight"></i></button>
              <li class="bi bi-arrow-down moveIconDown"></li>
              <li class="bi bi-arrow-up moveIconTop"></li>
            </ul>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
