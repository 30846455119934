<div class="container px-3 py-3">
  <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()">
    <h2>Career</h2>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "field" | translate }}:* </label>
          <input [(ngModel)]="data.career.role" name="position" #position="ngModel" class="form-control-sm col-md-12"
            [ngClass]="{ 'is-invalid': myForm.submitted && position.invalid }" required />
          <div *ngIf="myForm.submitted && position.invalid" class="invalid-feedback">
            <div *ngIf="position.errors.required">
              {{ "field" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label>{{ "career.employmentType" | translate }} </label>
          <select name="selectedValue" id="selectedValue" [(ngModel)]="data.career.employmentType"
            class="col-md-12 form-control-sm" #selectedValue="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && selectedValue.invalid }" required>
            <option *ngFor="let employment of employmentType" [ngValue]="employment.id" [selected]="employment">
              {{ employment.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
            <div *ngIf="selectedValue.errors.required">
              {{ "career.employmentType" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.fieldActivity" | translate }} </label>
          <select class="col-md-12 form-control-sm" [(ngModel)]="data.career.activityField" name="selectedActivity"
            #selectedActivity="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && selectedActivity.invalid }"
            required>
            <option *ngFor="let level of activityField" [ngValue]="level.id" [selected]="level">
              {{ level.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedActivity.invalid" class="invalid-feedback">
            <div *ngIf="selectedActivity.errors.required">
              {{ "career.fieldActivity" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label>{{ "career.level" | translate }} </label>
          <select class="col-md-12 form-control-sm" name="selectedLevel" [(ngModel)]="data.career.level"
            #selectedLevel="ngModel" [ngClass]="{ 'is-invalid': myForm.submitted && selectedLevel.invalid }" required>
            <option *ngFor="let level of careerLevel" [ngValue]="level.id" [selected]="level">
              {{ level.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedLevel.invalid" class="invalid-feedback">
            <div *ngIf="selectedLevel.errors.required">
              {{ "career.level" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <label>{{ "career.description" | translate }} </label>
          <quill-editor name="dialogDescription" [(ngModel)]="data.career.description" [modules]="modules">
          </quill-editor>
          <!--  style="resize:vertical" (keyup)="autogrow()" (click)="autogrow()" type="text" id="dialogDescription" [(ngModel)]="data.career.description" name="dialogDescription"
                    class="form-control-sm col-md-12">-->
        </div>
      </div>
    </li>
    <h4>{{ "career.company" | translate }}</h4>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{ "career.name" | translate }} </label>
          <ng-container *ngIf="!data.edit">

            <div class="row no-gutters" *ngIf="pageSelected">
              <div class="col input-group">
                <span class="input-group-prepend" *ngIf="pageActive">
                  <div class="input-group-text bg-transparent border-right-0">
                    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                      fallback="mp" size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                  </div>
                </span>
                <input class="form-control" type="text" [value]="pageActive" [ngModel]="searchForPage"
                  (keyup)="search($event)" name="careerName" #careerName="ngModel" id="example-search-input5"
                  [readonly]="pageActive" autocomplete="off">
              </div>
              <div class="col-auto">
                <button class="btn btn-outline-light" type="button" (click)="deletePage()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
            <ng-container *ngIf="searchForPageTrue">
              <div *ngFor="let page of pages$ | async" class="card__list">

                <div class="hstack gap-2 mb-3" (click)="addNewCompany(page)">
                  <!-- Avatar -->
                  <div class="avatar-xs">
                    <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                  </div>
                  <!-- Title -->
                  <div class="overflow-hidden">
                    <a class="h6 mb-0"> {{ page.name }} </a>
                  </div>
                  <!-- Button -->
                </div>

              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!!data.edit">

            <div class="row no-gutters" *ngIf="pageSelected">
              <div class="col input-group">
                <span class="input-group-prepend" *ngIf="pageActive">
                  <div class="input-group-text bg-transparent border-right-0">
                    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                      fallback="mp" size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                  </div>
                </span>
                <input class="form-control border-secondary border-right-0 rounded-0" type="text" [value]="pageActive"
                  [ngModel]="searchForPage" (ngModelChange)="search($event); searchForPageTrue = true" name="careerName"
                  #careerName="ngModel" id="example-search-input5" [readonly]="pageActive" autocomplete="off">
              </div>
              <div class="col-auto">
                <button class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right" type="button"
                  (click)="deletePage()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
            <ng-container *ngIf="searchForPageTrue">
              <div class="container">

              <ng-container  *ngFor="let page of pages$ | async">
                  <div class="card__list">
                    <div class="d-flex align-items-center btn btn-light mb-1 mt-1" (click)="addCompany(page)">
                      <div class="col-1">
                        <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                          fallback="mp" size="32" />
                        <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                          size="32" />
                      </div>
                      <div class="col-10 px-0 d-flex">
                        <span class="pr-2 pl-2">
                          {{ page.name }}
                          <span> </span>
                        </span>
                      </div>

                    </div>

                </div>

              </ng-container>
            </div>


            </ng-container>
          </ng-container>

        </div>
      </div>
    </li>
    <!-- <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.branch" | translate }} </label>
          <select
            class="col-md-12 form-control-sm"
            name="selectedBranch"
            id="selectBranch"
            [(ngModel)]="data.career.branch"
            (ngModelChange)="test($event)"
            #selectedBranch
            [ngClass]="{ 'is-invalid': myForm.submitted && selectedBranch.invalid }"
            required
          >
            <option
              *ngFor="let branch of bsService.branches"
              [ngValue]="branch.id"
              [selected]="branch"
            >
              {{ branch.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedBranch.invalid" class="invalid-feedback">
            <div *ngIf="selectedBranch.errors.required">
              {{ "career.branch" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="selectedArea.length > 0">
          <label>{{ "career.branch" | translate }} </label>
          <select
            class="col-md-12 form-control-sm"
            id="selectArea"
            name="selectedArea"
            [(ngModel)]="data.career.area"
                      >
            <option
              *ngFor="let area of selectedArea"
              [ngValue]="area.id"
              [selected]="area"
            >
              {{ area.description | translate }}
            </option>
          </select>
        </div>
      </div>
    </li> -->
    <!--  <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.organization" | translate }}:* </label>
          <select
            class="col-md-12 form-control-sm"
            name="selectedOrganization"
            id="selectedOrganization"
            [(ngModel)]="data.career.organization"
            #selectedOrganization="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && selectedOrganization.invalid }"
            required
          >
            <option
              *ngFor="let organization of organizations"
              [ngValue]="organization.id"
              [selected]="organization"
            >
              {{ organization.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedOrganization.invalid" class="invalid-feedback">
            <div *ngIf="selectedOrganization.errors.required">
              {{ "career.organization" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label>{{ "career.employSize" | translate }} </label>
          <select
            class="col-md-12 form-control-sm"
            name="selectedSize"
            id="careerEmploySize"
            [(ngModel)]="data.career.employmentSize"
            #selectedSize="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && selectedSize.invalid }"
            required
          >
            <option
              *ngFor="let employment of employmentSize"
              [ngValue]="employment.id"
              [selected]="employment"
            >
              {{ employment.description | translate }}
            </option>
          </select>
          <div *ngIf="myForm.submitted && selectedSize.invalid" class="invalid-feedback">
            <div *ngIf="selectedSize.errors.required">
              {{ "career.organization" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li> -->
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.companyUrl" | translate }}:* </label>
          <span class="checkbox-inline margin5px">
            <input [(ngModel)]="data.career.showCompanyUrl" [checked]="data.career.showCompanyUrl" name="showEmail"
              type="checkbox" value="" />
            {{ "data.show" | translate }}
          </span>
          <input name="companyUrl" *ngIf="selectedPageID" [(ngModel)]="website" class="form-control-sm col-md-12"
            #companyUrl="ngModel" [readonly]="website" />
          <input name="url" *ngIf="!selectedPageID" [(ngModel)]="data.career.url" class="form-control-sm col-md-12"
            #url="ngModel" />
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label>{{ "career.startDate" | translate }}:* </label>
          <input name="startdate" [ngModel]="data.career.startDate | date: 'yyyy-MM-dd'"
            (ngModelChange)="data.career.startDate = $event" type="date" #startdate="ngModel"
            class="form-control-sm col-md-12" [ngClass]="{ 'is-invalid': myForm.submitted && startdate.invalid }"
            required />
          <div *ngIf="myForm.submitted && startdate.invalid" class="invalid-feedback">
            <div *ngIf="startdate.errors.required">
              {{ "career.startDate" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="!data.career.today">
          <label>{{ "career.endDate" | translate }}:* </label>
          <input name="enddate" type="date" [ngModel]="data.career.endDate | date: 'yyyy-MM-dd'"
            (ngModelChange)="data.career.endDate = $event" class="form-control-sm col-md-12" #enddate="ngModel"
            [ngClass]="{ 'is-invalid': myForm.submitted && enddate.invalid }" required />
          <div *ngIf="myForm.submitted && enddate.invalid" class="invalid-feedback">
            <div *ngIf="enddate.errors.required">
              {{ "career.endDate" | translate }}
              {{ "error.isRequired" | translate }}
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-6">
          <label class="checkbox-inline">
            <input [(ngModel)]="data.career.today" name="tillToday" type="checkbox" value="" />
            <span class="margin5px">{{ "career.today" | translate }}</span>
          </label>
        </div>
      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
  </form>

</div>
