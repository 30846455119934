import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Group } from 'src/app/shared/interfaces/model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { GroupService } from 'src/app/shared/services/group.service';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss']
})
export class CreateGroupComponent implements OnInit {
  public privacy = [
    {id: 0, name:"Public"},
    {id: 1, name:"Private"}
  ];
  public group = new Group();
  public categories = [];
  searchCategory;

  constructor(public router: Router,
    public groupService: GroupService,
    public authService: AuthService,
    public categoryService: CategoryService) { }

  ngOnInit(): void {
    this.groupService.getGroups();
    this.categoryService.getCategory();
  }
  addPrivacy(event) {
    this.group.privacy = event.id;
  }
  createGroup() {
    this.group.categories = this.categories;
    this.group.url = `${this.group.name.replace(/\s/g, "")}${"-Page"}${Math.floor(Math.random() * 1000000000)}`;
   this.groupService.setGroup(this.group, this.authService.userID);

   this.router.navigate(["/groups"]);
  }
  addCategory(event) {
    this.categories.unshift(event);
    this.searchCategory = "";
  }
  removeCategory(index) {
    this.categories.splice(index, 1);
  }

}
