<div id="profile-item" *ngIf="!!project && !isPublished" stopPropagation
    class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" (click)="apply()">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>

              <li class="nav-item" >
                <a class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: project, isUser: true, url: user.userUrl })"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="applyForm">
            <form class="ms-2" name="ngForm" #myForm="ngForm">

            <div class="col-sm-6">
              <label class="form-label">{{'job.apply' | translate}} {{'as' | translate}}</label>
              <select name="selectedValue" id="selectedValue" [(ngModel)]="isSelected"
              class="col-md-12 form-control-sm"
              required  (ngModelChange)="test($event)">
             <ng-container *ngIf="project.freelancer"> <option [ngValue]="{user: user, isUser: true}">{{userData?.firstName}} {{userData?.lastName}}</option></ng-container>
             <ng-container *ngIf="project.company && !checkForFreelancerAndCompany">
              <option *ngFor="let employment of arrayPages" [ngValue]="employment._id">
                {{ employment?.name }}
              </option>
             </ng-container>

            </select>
            <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
              <div *ngIf="selectedValue.errors.required">
                {{ "career.employmentType" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
            </div>
            </form>
            <div class="ms-2 d-sm-flex align-items-end mt-1">
              <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config" [(ngModel)]="description" >
              </quill-editor>
              <button *ngIf="!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" (click)="applyForProject()" type="submit" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button *ngIf="!!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" type="submit" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i class="bi bi-x  fs-6"></i></button>

            </div>
          </ng-container>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{project?.positionTitle}}</h6>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                <p class="ms-1">{{project?.street}}, {{project?.postalCode}}, {{project?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>



              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{project?.incomes?.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{project?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div  *ngIf="project.company"  class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                  <div class="Text-Text-text pt-2">
                    <strong>
                      {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                    </strong>
                  </div>

                  <div class="Text-Text-text-wrapper">
                    <app-chips [chips]="project?.tasks" stopPropagation></app-chips>                  </div>

                </div>


                </div>

                <div *ngIf="project?.freelancer" class="remove-icon-border mt-4">


                  <div class="card-header px-0">
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="project?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreProjectInfo?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.language?.length > 0"
                    >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of project?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.driveLicense?.length > 0"
                     >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>



                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="project?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="project?.public?.startDate">Published: {{project?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{project?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}

            </span>
            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(data?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>
  <div id="profile-item" *ngIf="!!project && isPublished" stopPropagation
    class="summary-box profile-summary introjs-should-disabled">
    <div rel="group1" class="summary-link modal-link" data-bind="css: { 'ignored': isIgnored() }">
      <div class="card mb-2">
        <div class="flex col-12 job-buttons">

            <ul class="nav nav-pills nav-stack small fw-normal">
              <li class="nav-item" (click)="apply()">
                <a class="nav-link bg-light py-1 px-2 mb-0"> <i
                    class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
              </li>

              <li class="nav-item" >
                <a class="nav-link bg-light py-1 px-2 mb-0" (click)="openShare({data: project, isUser: true, url: user.userUrl })"> <i
                    class="bi me-1 bi-share text-warning pe-2"></i><span>{{'job.share' | translate}}</span></a>
              </li>
              <li class="nav-item dropdown ms-lg-auto">
                <a class="nav-link bg-light py-1 px-2 mb-0" href="#" id="feedActionShare" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots"></i>
                </a>
                <!-- Dropdown menu -->
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
                    </a></li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
                </ul>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="applyForm">
            <form class="ms-2" name="ngForm" #myForm="ngForm">

            <div class="col-sm-6">
              <label class="form-label">{{'job.apply' | translate}} {{'as' | translate}}</label>
              <select name="selectedValue" id="selectedValue" [(ngModel)]="isSelected"
              class="col-md-12 form-control-sm"
              required  (ngModelChange)="test($event)">
             <ng-container *ngIf="project.freelancer"> <option [ngValue]="{user: user, isUser: true}">{{user?.firstName}} {{user?.lastName}}</option></ng-container>
             <ng-container *ngIf="project.company && !checkForFreelancerAndCompany">
              <option *ngFor="let employment of arrayPages" [ngValue]="employment._id">
                {{ employment?.name }}
              </option>
             </ng-container>

            </select>
            <div *ngIf="myForm.submitted && selectedValue.invalid" class="invalid-feedback">
              <div *ngIf="selectedValue.errors.required">
                {{ "career.employmentType" | translate }}
                {{ "error.isRequired" | translate }}
              </div>
            </div>
            </div>
            </form>
            <div class="ms-2 d-sm-flex align-items-end mt-1">
              <quill-editor name="editor" [styles]="editorConfig" stopPropagation style="width:80%" [modules]="config" [(ngModel)]="description" >
              </quill-editor>
              <button *ngIf="!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" (click)="applyForProject()" type="submit" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button *ngIf="!!isProjectSearchCompany" stopPropagation [disabled]="myForm.form.invalid" type="submit" class="btn btn-sm btn-primary ms-2"><i class="bi bi-send fs-6"></i></button>
              <button (click)="cancelApplyForm()" stopPropagation class="btn btn-sm btn-primary ms-2"><i class="bi bi-x  fs-6"></i></button>

            </div>
          </ng-container>
        <div class="card-body">
          <div class="col-12 col-lg-12 d-block d-lg-flex mt-4 px-0">
            <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0">

              <h6>{{project?.positionTitle}}</h6>
              <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi me-1 bi-person-workspace" viewBox="0 0 16 16">
                  <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                  <path
                    d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                </svg> {{'project.firstPage.workplace' | translate}}: {{remote | translate}}</p>
              <div style="display: flex; align-items: center">
                <p> <i class="bi me-1 bi-geo-alt"></i> {{'job.address' | translate}}:</p>
                <p class="ms-1">{{project?.street}}, {{project?.postalCode}}, {{project?.city}},
                  {{countryJsonObject?.EN}}</p>
              </div>



              <p><i class="bi me-1 bi-cash"></i>
                {{"job.fourthPage.paymentForm" | translate }}: {{paymentID | translate}} {{project?.incomes?.budget}}+
                {{currencyObject?.currency.Symbol}} ({{currencyObject?.currency.Name}}) </p>
              <p><i class="bi me-1 bi-calendar-week"></i> {{'job.fourthPage.duration.jobDuration' | translate}}:
                {{jobDurationString | translate}} - Start date {{project?.startDate | date: "dd.MM.yyyy"}}</p>
              <div class="col-lg-12 mt-3  ml-lg-0 px-0" *ngIf="openMoreInfo">
                <div  *ngIf="project.company"  class="remove-icon-border">
                  <div class="card-header px-0">

                    <strong>{{ 'project.company' | translate }}</strong>
                  </div>

                  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
                  <div class="Text-Text-text pt-2">
                    <strong>
                      {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate }}
                    </strong>
                  </div>

                  <div class="Text-Text-text-wrapper">
                    <app-chips [chips]="project?.tasks" stopPropagation></app-chips>                  </div>

                </div>


                </div>

                <div *ngIf="project?.freelancer" class="remove-icon-border mt-4">


                  <div class="card-header px-0">
                    <strong>{{'project.freelancer' | translate}}</strong>
                  </div>
                  <div class="card-body px-0">

                    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "job.secondGroup.tasks" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="project?.skillsOffer" stopPropagation></app-chips>
                      </div>

                    </div>

                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.pcKnowledge" | translate}}
                        </strong>
                      </div>

                      <div class="Text-Text-text-wrapper">
                        <app-chips [chips]="moreProjectInfo?.pcKnowledge" stopPropagation></app-chips>
                      </div>


                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.language?.length > 0"
                    >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.language" | translate}}
                        </strong>

                        <div *ngFor="let language of project?.language; let iLang = index">
                          <div class="Field-field-fieldBase">
                            <div class="Text-Text-wrapper">
                              <div class="Text-Text-text-wrapper">
                                <div class="progress">
                                  <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                                    style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                                    [style.font-size.px]="10">
                                    <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                                      {{language.name | translate}}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="project?.driveLicense?.length > 0"
                     >
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.driveLicense" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.driveLicense" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>
                    <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
                      <div class="Text-Text-text">
                        <strong>
                          {{ "skills.qualifications" | translate}}
                        </strong>

                        <div class="Text-Text-text-wrapper">
                          <app-chips [chips]="project?.qualifications" stopPropagation></app-chips>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>



                <br>
                <div class="card-header px-0">
                  <strong>{{"description" | translate}}</strong>
                </div>
                <div class="card-body px-0">
                  <div class="col-12 col-lg-12 d-block d-lg-flex px-0">
                    <div class="col-lg-12 mt-lg-0 ml-lg-0 px-0">
                      <div class="Text-Text-text-wrapper" [innerHTML]="project?.description | noSanitize">
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="button-bottom">
            <span  style="align-items: self-end;
            display: flex;" *ngIf="project?.public?.startDate">Published: {{project?.public?.startDate | date:"dd.MM.yyyy HH:mm"}} <br> End:  {{project?.public?.endDate | date:"dd.MM.yyyy HH:mm"}}

            </span>
            <ng-container>
              <button type="button" class="btn btn-light" (click)="moreInfo(data?._id)" *ngIf="!openMoreInfo"
                stopPropagation><i class="bi me-1 bi-plus"></i> {{'header.more' | translate}}</button>
            </ng-container>
            <button type="button" class="btn btn-light" (click)="lessInfo()" *ngIf="openMoreInfo" stopPropagation><i
                class="bi me-1 bi-dash"></i> Less</button>

          </div>

        </div>
      </div>
    </div>
  </div>

