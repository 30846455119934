<div class="flex items-center mt-6" style="
display: flex;
margin-top: 1rem;
margin-bottom: 1rem;
">
    <div class="w-40 me-4">
        <input class="form-control" aria-label="search"
                 icon="search"                 
               >
    </div>
    <div class="flex flex-row me-3">
        <div 
             class="lift-avatar"
            >
    
        </div>
    </div>
    <button class="ms-3"
              class="btn btn-light"
              >
        Only My Issues
    </button>
    <button class="ms-3"
             
              class="btn btn-light">
        Ignore Resolved
    </button>
    <div class="ms-3 flex items-center"
       >
        <div class="me-3 w-px bg-backgroundMedium self-stretch"></div>
        <button class=" btn btn-secondary"
                 >Clear all
        </button>
    </div>
</div>