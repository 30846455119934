<div class="" *ngIf="!isProject">
<ng-container *ngIf="!!job">
  <div class="px-3 pb-4 pt-3 uppercase text-textMedium text-13 truncate">
    {{ IssueStatusDisplay[status] }}
    <span class="lowercase text-13">{{ issuesCount }}</span>
</div>

<div class="h-full pl-2"
     cdkDropList
     [cdkDropListData]="job?.manage_"
     [id]="status"
     style="
     background-color: white;"
     (cdkDropListDropped)="drop($event)">
    <app-smart-career-card *ngFor="let issue of job?.manage_; let i = index"
                [issue]="issue"
                [job]="job"
                cdkDrag
                [cdkDragData]="issue"
                >
</app-smart-career-card>
</div>

</ng-container>

</div>
<div class="" *ngIf="isProject">
    <div class="px-3 pb-4 pt-3 uppercase text-textMedium text-13 truncate">
        {{ IssueStatusDisplay[status] }}
        <span class="lowercase text-13">{{ issuesCount }}</span>
    </div>

    <div class="h-full pl-2"
         cdkDropList
         [cdkDropListData]="project?.manage_"
         [id]="status"
         style="
         background-color: white;"
         (cdkDropListDropped)="drop($event)">
        <app-smart-career-card *ngFor="let issue of project?.manage_; let i = index"
                    [issue]="issue"
                    [job]="project"
                    [isProject]="true"
                    cdkDrag
                    [cdkDragData]="issue"
                    >
</app-smart-career-card>
    </div>
</div>
