import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { OverviewComponent } from "./components/overview/overview.component";
import { LoginComponent } from "./components/login/login.component";
import { RegisterComponent } from "./components/register/register.component";
import { HomeComponent } from "./components/home/home.component";
import { JobsComponent } from "./components/jobs/jobs.component";
import { BookmarksComponent } from "./components/bookmarks/bookmarks.component";
import { BranchesComponent } from "./components/branches/branches.component";
import { GroupsComponent } from "./groups/groups/groups.component";
import { GroupDetailsComponent } from "./groups/group-details/group-details.component";
import { CreatePageComponent } from "./user-components/pages/create-page/create-page.component";
import { ContactsComponent } from "./friends/friends.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { ChatComponent } from "./components/chat/chat.component";
import { AuthGuard } from "./guards";
import { MainSearchComponent } from "./components/main-search/main-search.component";
import { ProjectsComponent } from "./components/projects/projects.component";
import { YourJobComponent } from "./components/jobs/your-job/your-job.component";
import { YourProjectsComponent } from "./your-projects/your-projects.component";
import { CreateYourJobComponent } from "./create-your-job/create-your-job.component";
import { CreateYourProjectComponent } from "./components/create-your-project/create-your-project.component";
import { JobDetailsComponent } from "./components/jobs/job-details/job-details.component";
import { PublicJobComponent } from "./components/jobs/public-job/public-job.component";
import { SavedJobsComponent } from "./components/jobs/saved-jobs/saved-jobs.component";
import { PaymentsComponent } from "./components/payments/payments.component";
import { IgnoredJobsComponent } from "./components/jobs/ignored-jobs/ignored-jobs.component";
import { AppliedJobsComponent } from "./components/jobs/applied-jobs/applied-jobs.component";
import { ProjectDetailsComponent } from "./project-details/project-details.component";
import { DeactivateDeleteAccountComponent } from "./components/deactivate-delete-account/deactivate-delete-account.component";
import { RouteUserBlockedGuard } from "./guards/route-user-blocked.guard";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent},
  { path: "cv", component: OverviewComponent, canActivate: [AuthGuard] },
  { path: "home", component: HomeComponent, canActivate: [AuthGuard] },
  { path: ":id/payments/billing", component: PaymentsComponent, canActivate: [AuthGuard] },
  { path: ":id/payments/transactions", component: PaymentsComponent, canActivate: [AuthGuard] },
  { path: ":id/payments/purchased", component: PaymentsComponent, canActivate: [AuthGuard] },
  {
    path: "jobs", component: JobsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard],
    children: [
      { path: ":id", component: JobsComponent, }
    ]
  },
  {
    path: "projects", component: ProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard],
    children: [
      { path: ":id", component: ProjectsComponent }
    ]
  },
  { path: "bookmarks", component: BookmarksComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: "branches", component: BranchesComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: ":id/cvm", component: OverviewComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: "search", component: MainSearchComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: ":id/jobs/:id/public", component: PublicJobComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: ":id/jobs/:id/search", component: JobsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: ":id/projects/:id/search", component: ProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },

{path: "jobs/public/:id", component: ProjectDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]},

  { path: ":id/projects/:id/public", component: PublicJobComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },

  {
    path: ":id/jobs", component: YourJobComponent,
    children: [
      { path: "create", component: CreateYourJobComponent },
      { path: "ignored", component: IgnoredJobsComponent },
      { path: "applied", component: AppliedJobsComponent },
      { path: "saved", component: SavedJobsComponent },

    ]
  },
  { path: "jobs/public/:id/cancel", component: JobDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: "projects/public/:id/cancel", component: ProjectDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },

  {
    path: "jobs/public/:id", component: JobDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard],
    children: [
      {
        path: "apply",
        component: JobDetailsComponent
      }
    ]
  },

  {
    path: ":id/projects/create", component: YourProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },
  {
    path: ":id/projects/applied", component: YourProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },
  {
    path: ":id/projects/saved_projects", component: YourProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },

  {
    path: ":id/projects/ignored_projects", component: YourProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },

  {
    path:":id/jobs/:id",  component: ProjectDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },
  // { path: ":id/projects/:id", component: JobDetailsComponent },
  {path: "projects/public/:id/apply", component: JobDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },

  {
    path: "projects/public/:id", component: ProjectDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },
  {
    path: ':id/projects/:id/manage', canActivate: [AuthGuard, RouteUserBlockedGuard],
    loadChildren: () => import('./components/jira-system/project/project.module').then((m) => m.ProjectModule)
  },



  {
    path: ":id/projects", component: YourProjectsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },
  {
    path: "groups", component: GroupsComponent, canActivate: [AuthGuard]
  },
  {
    path: "groups/:id", component: GroupDetailsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard],
    children: [
      { path: "about", component: GroupDetailsComponent },
      { path: "manage", component: GroupDetailsComponent },
      { path: "member-requests", component: GroupDetailsComponent },
      { path: "auto_approve_requests", component: GroupDetailsComponent },
      { path: "membership_questions", component: GroupDetailsComponent },
      { path: "pending_posts", component: GroupDetailsComponent },
      { path: "post_tags_list", component: GroupDetailsComponent },
      { path: "scheduled_posts", component: GroupDetailsComponent },
      { path: "admin_activities", component: GroupDetailsComponent },
      { path: "manage_rules", component: GroupDetailsComponent },
      { path: "member_reported_content", component: GroupDetailsComponent },
      { path: "alerted", component: GroupDetailsComponent },
      { path: "group_quality", component: GroupDetailsComponent },
      {
        path: "settings", component: GroupDetailsComponent,
        children: [
          { path: "about", component: GroupDetailsComponent },
          { path: "customize", component: GroupDetailsComponent },
          { path: "features", component: GroupDetailsComponent },
          { path: "membership", component: GroupDetailsComponent },
          { path: "discussion", component: GroupDetailsComponent },
          { path: "advanced", component: GroupDetailsComponent }

        ]
      }
    ]
  },


  { path: "notifications", component: NotificationsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  { path: "messages", component: ChatComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },


  { path: "pages/create", component: CreatePageComponent, canActivate: [AuthGuard, RouteUserBlockedGuard] },
  {
    path: "contacts", component: ContactsComponent, canActivate: [AuthGuard, RouteUserBlockedGuard]
  },

  {
    path: ':id/jobs/:id/manage', canActivate: [AuthGuard, RouteUserBlockedGuard],
    loadChildren: () => import('./components/jira-system/project/project.module').then((m) => m.ProjectModule)
  },
  {
    path: '', canActivate: [AuthGuard],
    loadChildren: () => import('./main-home/main-home.module').then((m) => m.MainHomeModule)
  },
  {
    path: ':id/cv', canActivate: [AuthGuard, RouteUserBlockedGuard],
    loadChildren: () => import('./components/overview/overview.module').then((m) => m.OverviewModule)
  },
 /* {
    path: ':id',
    loadChildren: () => import('./user-components/user/user.module').then((m) => m.UserModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./user-components/pages/page.module').then((m) => m.PageModule)

  }, */

  {
    path: ':id/settings/deactivate_delete_account',
    component: DeactivateDeleteAccountComponent
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
