import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Model, EmptySub } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from "src/app/shared/services/user.service";
import { SubCareerDialogComponent } from "../../career/sub-career-dialog/sub-career-dialog.component";
import { EmptyDataDialogComponent, EmptySubCategory } from "../../modals/empty-data-dialog/empty-data-dialog.component";
import { SubCategoryDialogComponent } from "../../modals/sub-category-dialog/sub-category-dialog.component";
import { PersonalDataSubDialogComponent } from "../../personal-data/personal-data-sub-dialog/personal-data-sub-dialog.component";

@Component({
  selector: "app-empty-object",
  templateUrl: "./empty-object.component.html",
  styleUrls: ["./empty-object.component.scss"]
})
export class EmptyObjectComponent implements OnInit {
  @Input() model: Model;
  @Input() emptyObj: EmptySub;
  @Input() iEmpty: number;
  @Input() isFromCareer: boolean;
  @Input() isFromPersonalData: boolean;
  public selectedItem: number = null;
  @Input() user: User;

  constructor(public dialog: MatDialog,
              public authService: AuthService,
              public userService: UserService) { }

  ngOnInit(): void {

  }
  public selectEmptyObj(index) {
    this.selectedItem = index;
  }

  public removeClick() {
    this.selectedItem = undefined;
  }
  editEmptyObj(data: EmptySubCategory) {
    this.dialog.open(EmptyDataDialogComponent, {
      data
    });
  }
  addEmptyObj() {
    if (this.isFromCareer) {
      this.dialog.open(SubCareerDialogComponent, {
        data: this.model
      });
    } else if (this.isFromPersonalData) {
      this.dialog.open(PersonalDataSubDialogComponent, {
        data: this.model
      });
    } else {
    this.dialog.open(SubCategoryDialogComponent, {
      data: this.model});
    }
  }
  deleteEmptyObj(index) {
    if (this.isFromCareer) {
      this.model.careers.emptySubContents.splice(index, 1);
    } else if (this.isFromPersonalData) {
      this.model.personalData.map(t => t.emptySubContents.splice(index, 1));
    } else {
    this.model.skills.map(test => test.emptySubContents.splice(index, 1));
    }
   // return this.dataService.updatePage(this.model._id, this.model);
  }
  public get checkId() {
    return this.authService.userID === this.user._id;
    }

}
