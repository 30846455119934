import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { MaterialModule } from "src/app/material.module";
import { ButtonCoinsComponent } from "./button-coins.component";

@NgModule({
  declarations: [ButtonCoinsComponent],
  imports: [
      BrowserModule,
      FormsModule,
      CommonModule,
      RouterModule,
      MaterialModule,
      ReactiveFormsModule,
      TranslateModule,
      QuillModule.forRoot(),

  ],
  exports: [
    ButtonCoinsComponent
  ]
})

export class ButtonCoinsModule {

}
