import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: "root"
})
export class SmartTranslateService {

  constructor(private translateService: TranslateService) {
  }

  public set(id: string) {
    this.translateService.get(id).subscribe(test => id = test);
  }
  public get(id: string) {
    this.set(id);
  }
}
