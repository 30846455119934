<div class="container">
  <form class="form-grid" name="#ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()">
    <h2>{{"pagesInfo.categories" | translate}}</h2>
    <li class="line form-row">
      <div class="col-md-12 pb-2">
        <label>{{ "pagesInfo.categories" | translate }} </label>
        <select
        class="col-md-12 form-control"
        name="selectedPageCategory"
        [(ngModel)]="page?.categories.name"
        (ngModelChange)="showTags($event)"
        #selectedPageCategory="ngModel"
        [ngClass]="{ 'is-invalid': myForm.submitted && selectedPageCategory.invalid }"
        required
      >
     <ng-container>
        <option
          *ngFor="let subCat of categoryService.categories"
          [ngValue]="subCat.id"
          [selected]="subCat"
          (change)="showTags($event)"
        >
          {{ subCat.name | translate }}
        </option>
      </ng-container>
      </select>
      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>
        {{ "cancel" | translate }}
      </button>
      <button type="submit" class="btn btn-success" mat-button>
        {{ "save" | translate }}
      </button>
    </mat-dialog-actions>
</form>
</div>
