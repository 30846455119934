import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import {Observable} from "rxjs";
import {Subscription} from "rxjs/internal/Subscription";
import { ActivatedRoute, Router } from "@angular/router";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { ImageService } from "src/app/shared/services/image.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { shareReplay, take } from "rxjs/operators";
import { Model } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, OnDestroy {
  faCoffee = faShare;

  editShortcut = false;
  public model$: Observable<Model>;
  public model = new  Model();
  private subscriptions: Subscription;
  public currentUser: User;
  public image;
  public hasPhoto;
  public getFirstName;
  public getLastName;
  public userUrl;
public title;



  constructor(public authService: AuthService,
              public imageService: ImageService,
              private router: Router,
              private sendData?: SenderService,
              private route?:ActivatedRoute,
              private userService?: UserService) { }

  ngOnInit(): void {
    const id = this.authService.userID;
    this.route.params.subscribe(params => {
      this.title = params['title'];
  });

    if (this.authService.isLoggedIn) {
      this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(res => {
        if (res) {
          this.currentUser = res;
          if (this.currentUser.userUrl) {
             this.userUrl = this.currentUser.userUrl;
          } else {
            this.userUrl = this.currentUser._id;
          }
        }
      });
  }
  }

  ngOnDestroy():void {
  }
  showShortcut() {
    this.editShortcut = true;
  }
  hideShortcut() {
    this.editShortcut = false;
  }

  goToUser() {
    if(this.model.userUrl) {
      this.router.navigate(['', this.currentUser.userUrl]);
    } else {
      this.router.navigate(['', this.currentUser._id]);

    }
  }
  navigateToUser() {
  const test = [this.currentUser, "outside"];
  console.log(test, "test")
     this.sendData.send(test);
     this.router.navigate([this.currentUser.userUrl, "start" ],{relativeTo:this.route});
  }

      navigateToPayment() {
        localStorage.setItem("payment", "isUser");
        this.sendData.paymentDataVisibility(this.currentUser);
        this.router.navigateByUrl(this.currentUser?.userUrl + '/payments/billing'.replace(this.title, 'isUser'));
      }

}
