import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Page } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { PagesService } from '../shared/services/pages.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-saved-projects',
  templateUrl: './saved-projects.component.html',
  styleUrls: ['./saved-projects.component.scss']
})
export class SavedProjectsComponent implements OnInit {
  public user: User;
  public savedProjects = [];
  public subscription: Subscription;
  public page: Page;
  @Input() isPage: boolean = false;
  @Input() selectedPage: string;
  @Input() isUser;

  constructor(public userService: UserService,
     public receiverService: SenderService,
     public router: Router,
     public authService: AuthService,
     private pageService: PagesService) { }

  ngOnInit(): void {
    this.userService.getSavedProjects();
    this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(t => {
      this.user = t;  
     })
     if (this.isUser) {
      console.log("user")
     }

     if (this.page) {
      console.log("user")

     }
     if (!this.page && !this.isUser) {
      console.log("no page no user")

     }
     if (!this.page && this.isUser) {
      console.log("no page  user")

     }
 
    this.receiverService.dataUpdate$.subscribe((res: Page) => {
      this.page = res;

    });


  }
  deleteSavedProject(i) {
    this.user.savedProjects.splice(i, 1);
    this.savedProjects = this.user.savedProjects;
    this.userService.updateUser(this.user._id, this.user)
  }

  deleteSavedProjectPage(i){
    this.page.savedProjects.splice(i, 1);
    this.pageService.updatePage(this.page._id, this.page)
  }
  createProject() {
    this.router.navigateByUrl(this.user.userUrl + "/projects/create");
}
goToMyProjects() {
    this.router.navigateByUrl(this.user?.userUrl + "/projects");
}

navigateToSaved() {
  this.router.navigateByUrl(this.user?.userUrl + "/projects/saved");
}

}
