import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Group, Image } from 'src/app/shared/interfaces/model';
import { GroupService } from 'src/app/shared/services/group.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {
  @Input() group: Group;
  @Input() sidebar;
  @Input() sidebarGroup;

  public hasPhoto: boolean;
  public image;

  constructor( public router: Router,
    public groupService: GroupService,
    public imageService: ImageService,) { }

  ngOnInit(): void {
    if(this.group.profileImg) {
      this.imageService.getImageByID(this.group.profileImg).subscribe((res: Image) => {
        if(res.imageUrl) {
          this.image = res.imageUrl;
          this.hasPhoto = true;
        }
      });
    } else if(this.group.profileImg === undefined) {
      return;
    }
  }
  goToWeb(id) {
    this.groupService.getGroup(id).subscribe(data => {
      this.group = data;
      if(this.group.url) {
        this.router.navigate(['groups', this.group.url]);
      } else {
        this.router.navigate(['groups', this.group._id]);
      }
    });
    this.groupService.changeMessage(id);
  }
}
