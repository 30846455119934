import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor( private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) { 
    this.matIconRegistry.addSvgIcon(
      `story`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/story.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `bug`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/bug.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `task`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svg/task.svg")
    );
  }

  ngOnInit(): void {
  }

}
