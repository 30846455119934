<aside class="pt-4 aside">
  <section class="sc-smart">
  <div class="widget-item p-0">
    <div class="">
      <div class="">
            <p class="sc-shortcut p">Sponsored
            </p>
              <div class="sc-lineSeparator" role="separator"></div>
        <div>
          <p class="sc-shortcut p">Your Pages
            <span><i class="fa fa-ellipsis-h"></i></span>
          </p>

          <button type="button" class="button-f button-sc">
            <div>
             <i class="bi bi-plus"></i>
              Pages
            </div>
          </button>

          <button type="button" class="button-f button-sc">
            <div>
             <i class="bi bi-plus"></i>
              Pages
            </div>
          </button>
          <div class="sc-lineSeparator" role="separator"></div>
        </div>
        <div class="sc-shortcut">
        <p class="sc-shortcut p">Birthdays
        </p>
        <div >
          <button type="button" class="button-f button-sc">
            <div>
             <i class="bi bi-plus"></i>
              Pages
            </div>
          </button>
          <div class="sc-lineSeparator" role="separator"></div>



          <p class="sc-shortcut p"></p>
            Contacts

        </div>
      </div>
    </div>
    </div>
</div>
</section>
 </aside>
