<mat-horizontal-stepper [linear]="true" #stepper style="margin-left: 20px">
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit()">
      <ng-template matStepLabel></ng-template>
      <li class="mat-form-field--inline">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label> {{ "project.title" | translate }} </mat-label>
          <input matInput name="JobTitle" type="text" [(ngModel)]="project.positionTitle" formControlName="projectTitle"
            required />
        </mat-form-field>
        <label style="width:100%">Ich suche :*</label>
        <br>
        <div class="column" style="margin-bottom: 1rem; width:100%">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="company" id="acceptTerms" class="form-check-input"
              [ngClass]="{ 'is-invalid': submitted && f.company.errors }" (change)="changeCompany()" />
            <label for="company" class="form-check-label">{{'project.company' | translate}}</label>
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">{{'project.company' | translate}}</div>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" formControlName="freelancer" id="freelancer" class="form-check-input"
              [ngClass]="{ 'is-invalid': submitted && f.freelancer.errors }" (change)="changeFreelancer()" />
            <label for="freelancer" class="form-check-label">{{'project.freelancer' | translate}}</label>
            <div *ngIf="submitted && f.freelancer.errors" class="invalid-feedback">{{'project.freelancer' | translate}}
            </div>
          </div>
        </div>

        <br>
        <mat-form-field appearance="fill">
          <mat-label>{{ "project.firstPage.workplace" | translate }} </mat-label>
          <mat-select id="remote" [(ngModel)]="project.remote" formControlName="remote" required>
            <mat-option *ngFor="let remote of remoteArray" [value]="remote.id" [selected]="remote">
              {{ remote.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="firstLabel" style="width: 100%; margin-bottom: .5rem;">Project {{ "data.address" | translate }}:*
        </span>
        <br>

        <mat-form-field appearance="fill" style="width:100%">
          <mat-label> {{ "addressData.street" | translate }} </mat-label>
          <input matInput name="street" type="text" [(ngModel)]="project.street" formControlName="street" required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.zip" | translate }} </mat-label>
          <input matInput name="zip" type="text" [(ngModel)]="project.postalCode" formControlName="postalCode"
            required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.city" | translate }} </mat-label>
          <input matInput name="city" type="text" [(ngModel)]="project.city" formControlName="city" required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "data.country" | translate }} </mat-label>
          <mat-select [(ngModel)]="project.c" formControlName="country" required
            (ngModelChange)="changeCountry($event)">
            <mat-option *ngFor="let country of countryJson" [value]="country?.ISO_2" (change)="changeCountry($event)"
              [selected]="selectedCountry">
              {{ country.EN }} - ({{ country.LokalName }})
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "data.state" | translate }} </mat-label>
          <input matInput name="state" type="text" [(ngModel)]="project.state" formControlName="state" />
        </mat-form-field>
      </li>
      <div>
        <button mat-button matStepperNext class="btn btn-light">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <form [formGroup]="secondFormGroup">
      <ng-container *ngIf="firstFormGroup.get('company').value">
        <label>{{'project.company' | translate}} :*</label>
        <li class="mat-form-field--inline">
          <mat-form-field appearance="fill" style="width:100%">
            <mat-label> {{'project.secondPage.tasks' | translate}} {{ "project.secondPage.keywords" | translate }}
            </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project?.tasks; let i = index" formArrayName="pcKnowledgeProject"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeTask(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="pcKnowledgeProject"
                  formControlName="pcKnowledgeProject" style="display: none !important;">
                <button class="btn btn-light ms-1" (click)="addTasks({createProject: true, createJob: false})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
        </li>
      </ng-container>
      <hr>
      <ng-container *ngIf="firstFormGroup.get('freelancer').value">

        <label>{{'project.freelancer' | translate}}</label>
        <li class="mat-form-field--inline">
          <mat-form-field appearance="fill">
            <mat-label> {{'project.secondPage.tasks' | translate}} :* </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project?.skillsOffer; let i = index" formArrayName="skillsOffer"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeSkills(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="skillsOffer" formControlName="skillsOffer"
                  style="display: none !important;">
                <button class="btn btn-light ms-1" (click)="addSkillsOffer({createProjectSkills: true})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> {{ "skills.pcKnowledge" | translate }} </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project?.pcKnowledge; let i = index" formArrayName="pcKnowledge"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeSP(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="pcKnowledge" formControlName="pcKnowledge"
                  style="display: none !important;">
                <button class="btn btn-light ms-1" (click)="addPcKnowledge({projectPCKnowledge: true})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> {{ "skills.driveLicense" | translate }} </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project?.driveLicense; let i = index" formArrayName="driveLicense"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeDriveLicense(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="driveLicense" formControlName="driveLicense"
                  style="display: none !important;">
                <button class="btn btn-light ms-1" (click)="addDriveLicense({projectDriveLicense: true})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> {{ "skills.language" | translate }} </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project.language; let i = index" formArrayName="language"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name | translate }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeLanguage(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="language" formControlName="language"
                  style="display: none !important;" />
                <button class="btn btn-light ms-1" (click)="addProjectLanguage({projectLanguage: true})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label> {{ "skills.qualifications" | translate }} </mat-label>
            <div class="card__body d-flex column">
              <div class="d-flex align-items-center card__body__list">
                <div *ngFor="let sp of project.qualifications; let i = index" formArrayName="qualifications"
                  class="d-flex justify-content-between pr-1">
                  <div class="card__text me-1">
                    {{ sp.name }}
                    <button class="btn btn-empty btn-sm py-0 px-0" type="button" (click)="removeQualifications(i)"><i
                        class="fa fa-remove"></i></button>
                  </div>
                </div>
                <input matInput name="zip" type="text" [(ngModel)]="qualifications" formControlName="qualifications"
                  style="display: none !important;" />
                <button class="btn btn-light ms-1"
                  (click)="addProjectQualifications({editProjectQualifications: true})">
                  <i class="bi bi-pencil"></i>
                </button>
              </div>
            </div>
          </mat-form-field>
        </li>
      </ng-container>
      <div>
        <button mat-button matStepperPrevious class="btn btn-light" (click)="backNavigate()">
          Back
        </button>
        <button mat-button matStepperNext class="btn btn-light">Next</button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="myForm.control">
    <form name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid">
      <li class="mat-form-field--inline">
        <mat-label>{{ "career.description" | translate }} </mat-label>
        <!-- <quill-editor name="editor" matInput [(ngModel)]="job.description" [modules]="modules" formControlName="editor">
            </quill-editor> -->
        <quill-editor name="editor" #editor="ngModel" [(ngModel)]="project.description"
          [ngClass]="{ 'is-invalid': myForm.submitted && editor.invalid }" required>
        </quill-editor>
        <div *ngIf="myForm.submitted && editor.invalid" class="invalid-feedback">
          <div *ngIf="editor.errors.required">
            {{ "field" | translate }}
            {{ "error.isRequired" | translate }}
          </div>
        </div>
      </li>

      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button type="submit" mat-button matStepperNext>Next</button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="fourthFormGroup">
    <form [formGroup]="fourthFormGroup">
      <h2>Planned</h2>
      <li class="mat-form-field--inline">
        <mat-form-field appearance="fill">
          <mat-label>{{ "project.fourthPage.paymentForm" | translate }} </mat-label>
          <mat-select [(ngModel)]="project.incomes.s" formControlName="salaryStatus" required>
            <mat-option *ngFor="let salary of salaryArray" [value]="salary.id" [selected]="salary">
              {{ salary.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label> {{ "project.fourthPage.summe" | translate }} </mat-label>
          <input matInput name="salaryBudget" type="number" [(ngModel)]="project.incomes.budget"
            formControlName="salaryBudget" required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "job.fourthPage.currency" | translate }} </mat-label>
          <mat-select [(ngModel)]="currency" formControlName="currency" (ngModelChange)="changeCurrency($event)"
            required>
            <mat-option *ngFor="let country of countryJson" [value]="country?.ISO_2">
              {{ country.currency.Symbol }} - ({{country.currency.Alphabeticcode}} - {{ country.currency.Name }}) - {{
              country.LokalName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "job.fourthPage.duration.jobDuration" | translate }} </mat-label>
          <mat-select id="projectDuration" [(ngModel)]="project.duration" formControlName="projectDuration" required>
            <mat-option *ngFor="let duration of projectDurationArray" [value]="duration.id" [selected]="duration">
              {{ duration.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill"
          *ngIf="fourthFormGroup.get('projectDuration').value === projectDuration.temporary">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker" [min]="minDate">
            <input matStartDate [(ngModel)]="startDateLimited" formControlName="start" placeholder="Start date">
            <input matEndDate [(ngModel)]="endDateLimited" formControlName="end" placeholder="End date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="fourthFormGroup.controls.start.hasError('matStartDateInvalid')">Invalid start
            date</mat-error>
          <mat-error *ngIf="fourthFormGroup.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill"
          *ngIf="fourthFormGroup.get('projectDuration').value === projectDuration.unlimited">
          <mat-label>Start date</mat-label>
          <input matInput [(ngModel)]="selectedDate" [matDatepicker]="picker" [min]="minDate"
            formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <span *ngIf="fourthFormGroup.get('projectDuration').value === projectDuration.temporary">
          {{getDays}} days
        </span>
      </li>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button *ngIf="!page" mat-button (click)="createProject()">Save</button>
        <button *ngIf="page" mat-button (click)="createPageProject()">Save</button>
      </div>
    </form>
  </mat-step>

</mat-horizontal-stepper>
