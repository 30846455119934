<div class="container">
    <form class="form-grid" name="ngForm" #myForm="ngForm" (ngSubmit)="myForm.form.valid && saveData()" novalidate>
      <h3>{{'skills.qualifications' | translate}}</h3>

    <li class="line">
      <div class="col-md-12">
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12">
              <label>{{'qualifications.name' | translate}}</label>
              <input [(ngModel)]="data.qualifications.name" name="qualificationsName" #qualificationsName="ngModel" class="form-control-sm col-md-12" [ngClass]="{'is-invalid': myForm.submitted && qualificationsName.invalid}" required>
              <div *ngIf="myForm.submitted && qualificationsName.invalid" class="invalid-feedback">
                <div *ngIf="qualificationsName.errors.required">
                  {{ "qualifications.name" | translate }}
                  {{ "error.isRequired" | translate }}
                </div>
              </div>
            </div>
          </div>
        </li>
        <br>
        <label>{{ "career.company" | translate }}</label>
        <li class="line form-row">
          <div class="col col-md-12">
            <div class="row no-gutters">
              <div class="col input-group">
                <span class="input-group-prepend" *ngIf="pageActive">
                  <div class="input-group-text bg-transparent border-right-0">
                    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar alt="photo" [email]="'example@mail.com'"
                      fallback="mp" size="24" />
                    <img *ngIf="!hasPhoto" alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp"
                      size="24" />
                  </div>
                </span>
                <input class="form-control" type="text" [value]="pageActive" [ngModel]="searchForPage"
                  (keyup)="search($event)" name="careerName" #careerName="ngModel" id="example-search-input5"
                  [readonly]="pageActive" autocomplete="off">
              </div>
              <div class="col-auto">
                <button class="btn btn-outline-light" type="button" (click)="deletePage()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
            </div>
            <ng-container *ngIf="searchForPageTrue">
              <div *ngFor="let page of pages$ | async" class="card__list">

                <div class="hstack gap-2 mb-3" (click)="addCompany(page)">
                  <!-- Avatar -->
                  <div class="avatar-xs">
                    <a><img class="avatar-img rounded-circle" src="assets/images/avatar/04.jpg" alt=""></a>
                  </div>
                  <!-- Title -->
                  <div class="overflow-hidden">
                    <a class="h6 mb-0"> {{ page.name }} </a>
                  </div>
                  <!-- Button -->
                </div>

              </div>
            </ng-container>

          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12">
              <label>{{'qualifications.date' | translate}}</label>
              <input type="date" [ngModel]="data.qualifications.issueDate | date: 'yyyy-MM-dd'" (ngModelChange)="data.qualifications.issueDate = $event" name="qualificationsDate"  class="form-control-sm col-md-12">
            </div>
          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12">
              <label>{{'qualifications.id' | translate}}</label>
              <input type="text" [(ngModel)]="data.qualifications.issueID" name="issueID" #issueID="ngModel" class="form-control-sm col-md-12">
            </div>
          </div>
        </li>
        <br>
        <li class="line form-row">
          <div class="col row col-md-12">
            <div class="col-md-12">
              <label>{{'qualifications.url' | translate}}</label>
              <input type="text" [(ngModel)]="data.qualifications.issueUrl" name="issueUrl" class="form-control-sm col-md-12">
            </div>
          </div>
        </li>

      </div>
    </li>
    <mat-dialog-actions align="end">
      <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
      <button type="submit" class="btn btn-success" mat-button>{{'save' | translate}}</button>
    </mat-dialog-actions>
  </form>

</div>
