<div class="container">
  <div class="body-container">
    <h1>New SubCategory</h1>
    <div class="content">
      <div class="array" *ngFor="let cat of careerSub; let i = index">
        <ul (click)="addCategory(cat, {model: data.model, personalData: true})">
          <button type="button"><i class="fa-2x" [class]="cat.icon"></i></button>
        </ul>
        <h3> {{cat.description | translate}}</h3>
      </div>
    </div>
  </div>
</div>

