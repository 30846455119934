import { HttpClient } from "@angular/common/http";
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormControl, NgForm } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import { map, startWith, take } from "rxjs/operators";
import { BlockedUserDialogComponent } from "src/app/components/modals/blocked-user-dialog/blocked-user-dialog.component";
import { ConfirmationDialogComponent } from "src/app/components/modals/confirmation-dialog/confirmation-dialog.component";
import { EditPrivacyDialog, PrivacyDialogComponent } from "src/app/components/privacy-dialog/privacy-dialog.component";
import { Groups, MaritualStatus, Pricing, Status, StatusTitle } from "src/app/shared/enums/enums-model";
import { Group, Model, Phone } from "src/app/shared/interfaces/model";
import { User, UserUser } from "src/app/shared/interfaces/user.model";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PdfService } from "src/app/shared/services/pdf.service";
import { PrivacyActivity } from "src/app/shared/services/privacy-activity.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"]
})
export class UserSettingsComponent implements OnInit, AfterViewInit {
  public model$: Observable<Model>;
  public model = new Model();
  public subscriptions: Subscription;
  public editUser = false;
  public userNameEdit = false;
  public userUrl = "";
  public userName = "";
  public editActivityPrivacyField = false;
  public editCVPersonalData = false;
  public editYourActivity = false;
  currentUser: User;
  filteredOptions: Observable<any[]>;
  filteredLanguages: Observable<any[]>
  myControl = new UntypedFormControl('');
  myControlSecond = new UntypedFormControl('');
  selectedLanguage;
  selectedValueLanguage;
  showSecondaryLanguage: boolean = false;
  selectedSecondaryLanguage;
  languageVersion = "";
  isNotTranslated: boolean = false;
  isNotSameVersion: boolean = false;
  secondLanguageArray;
  selectedCountry: any;
  address;
  anotherEmail: boolean = false;
  anotherNumber: boolean = false;
  newEmail = "";
  newNumber = new Phone();
  isSelectedPhoneIndex;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  Pricing = Pricing;



  public genders = [
    { id: StatusTitle.Mr, description: "data.mr"},
    { id: StatusTitle.Mrs, description: "data.mrs"},
  ];
  public statusObject = [
    {id: 1, name: MaritualStatus.Single, description: "status.single"},
    {id: 2, name: MaritualStatus.Married, description: "status.married"},
    {id: 0, name: MaritualStatus.NotKnow, description: "status.nk"}
  ];
  counter;
  config = {
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": false,

    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["code-block"],
        // [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        // [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction
        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ["code-block"], // code block
        [{align: []}],
        ["emoji"],
        ["clean"], // remove formatting button
        ["link", "image", "video"],
      ]
    },
  }
  languages = [];

  users: User[];

  isEditingMode: boolean = false;
  isEditingPhone: boolean = false;
  isSelectedIndex;
  public dataList$: Observable<any>;
  public uUser: any;
  public userUser: UserUser;

  selectedSecondLanguage;
  constructor(public router: Router,
    public authService: AuthService,
    public dialog: MatDialog,
    public cd: ChangeDetectorRef,
    public http: HttpClient,
    public pdfService: PdfService,
    public translate: TranslateService,
    public imageService: ImageService,
    public privacyActivity: PrivacyActivity,
    public userService?: UserService,
    public languageService?: LanguagesService,
    public titleService?: ArraysService,
    public toastService?: ToastService,
    public premiumService?: ArraysService
  ) {
  }
  ngAfterViewInit(): void {
    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe(result => {
      this.currentUser = result.user;
      this.uUser = result.userUser;
    });
  }

  ngOnInit(): void {
    this.privacyActivity.getPrivacyActivity();
    this.languageService.getPrimaryLanguages();
    this.titleService.showTitles();
    this.titleService.showReligions();
    this.titleService.showPricingDataPremium();
    this.titleService.showPricingDataVerified();

    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe(result => {
      this.currentUser = result.user;
      this.userUser = result.userUser;
      this.http.get<any>(`assets/languages/${this.currentUser?.language?.primary}/country.json`).subscribe(data => {
        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
        this.getAddress(this.currentUser.address.data[0].c);
      });
      this.translate.use(this.currentUser.language.primary);

      this.secondLanguageArray = this.languageService.primaryLanguages.filter(t => t.version === this.languageVersion );



      // this.model = res?.cv[0];
      if (this.currentUser?.userUrl) {
        this.userUrl = this.currentUser?.userUrl;
      } else {
        this.userUrl = this.currentUser?._id;

      }
      const version = this.languageService.primaryLanguages.find(t => t.id === this.currentUser?.language?.primary)?.version;
      this.selectedValueLanguage = this.languageService.primaryLanguages.find(t => t.id === this.currentUser?.language?.primary);
      if (!!this.currentUser.language.secondary) {
        this.selectedSecondLanguage = this.languageService.primaryLanguages.find(t => t.lang === this.currentUser.language.secondary )

      }

      if (!!this.languageVersion && this.languageVersion !== version) {
        this.isNotSameVersion = true;

      } else if (!version) {
        this.isNotTranslated = true;

      }

    });


    this.http.get<any>(`assets/i18n/en.json`).subscribe(res => {
      this.languageVersion = res.version;
      localStorage.setItem("languageVersion", res.version)
    });

  /*  this.userService.showBlocked(this.authService.userID).subscribe((users: User[]) => {
        this.users = users
    }) */
      // test







  }
  editUserName() {
    this.userNameEdit = true;
    this.userName = this.currentUser?.userName;
  }
  editUserUrl() {
    this.editUser = true;
    this.userUrl = this.currentUser?.userUrl;
  }
  cancel() {
    this.editUser = false;
    this.currentUser.userUrl = this.userUrl;
  }

  addNewUserName() {
    this.userService.updateUser(this.currentUser._id, this.currentUser);
    this.editUser = false;
  }
  addUserUrl() {
    this.userService.updateUser(this.currentUser._id, this.currentUser);
    this.editUser = false;
  }
  editActivityPrivacy() {
    this.editActivityPrivacyField = true;
  }
  editYourCVPersonalData() {
    this.editCVPersonalData = true;
  }
  addPrivacy(data:EditPrivacyDialog) {
    if(data.enum === Groups.Contacts_except || data.enum === Groups.Contacts_specific) {
     return this.dialog.open(PrivacyDialogComponent, {
       data,
      panelClass: 'my-class'
     })
    } else {
      this.model.p = data.enum;
    }
  }
  addYourCVPersonalDataPrivacy(data:EditPrivacyDialog) {
    if(data.enum === Groups.Contacts_except || data.enum === Groups.Contacts_specific) {
      return this.dialog.open(PrivacyDialogComponent, {
        data,
       panelClass: 'my-class'
      })
    } else {
      this.model.personalData[0].cv_enum = data.enum;
    }
  }
  addYourPrivacyActivity() {
    console.warn("Test");
  }

  getName() {
    if (this.model.p === Groups.Public) {
      return "Public"
    } else if (this.model.p === Groups.Contacts) {
      return "Contacts";
    } else if (this.model.p === Groups.OnlyMe) {
      return "Only me"
    } else if (this.model.p === Groups.Contacts_except) {
      return "Contacts except"
    } else if (this.model.p === Groups.Contacts_specific) {
      return "Specific Contacts"
    } else {
      return "Public"
    }
  }
  public get getPersonalDataName() {
    const personalDataCV = this.model.personalData[0].cv_enum;
    if (personalDataCV === Groups.Public) {
      return "Public"
    } else if (personalDataCV === Groups.Contacts) {
      return "Contacts";
    } else if (personalDataCV === Groups.OnlyMe) {
      return "Only me"
    } else if (personalDataCV === Groups.Contacts_except) {
      return "Contacts except"
    } else if (personalDataCV === Groups.Contacts_specific) {
      return "Specific Contacts"
    } else {
      return "Public"
    }
  }





  changeLanguage(event) {


    this.selectedLanguage = event;
    this.translate.use(this.selectedValueLanguage.lang);
if (event.version === this.languageVersion) {
  setTimeout(() => {
    this.currentUser.language = {};
    this.currentUser.language.primary = this.selectedLanguage.lang;
    this.selectedValueLanguage =  this.selectedLanguage;
    this.isNotSameVersion = false;
    this.isNotTranslated = false;
  }, 100)


} if (!!event.version && event.version !== this.languageVersion) {
  setTimeout(() => {
    this.isNotSameVersion = true;
    this.currentUser.language.primary = this.selectedLanguage.lang;
    this.selectedValueLanguage =  this.selectedLanguage;
    this.isNotTranslated = false;
  }, 100)

} else if(!event.version) {
  setTimeout(() => {
    this.isNotTranslated = true;
    this.showSecondaryLanguage = true;
    this.isNotSameVersion = false;
    this.currentUser.language.primary = this.selectedLanguage.lang;

    this.selectedValueLanguage =   this.selectedLanguage;
  }, 100)

}
  }

  changeLanguageSecond(event) {
    this.currentUser.language.secondary = event.lang;
  }

  saveLanguage(data: User) {
    this.userService.updateUser(data._id, data)
  }
  saveUserSettings(myForm: NgForm) {
    this.userService.updateuUser(this.userUser._id, this.userUser);
   this.userService.updateUser(this.currentUser._id, this.currentUser);

  this.toastService.openSnackBar("You have updated your data", "", "success-snackbar");
  }

  textChanged($event) {
    this.counter = $event.editor.getLength();
    if ($event.editor.getLength() > 300) {
      $event.editor.deleteText(299, $event.editor.getLength());
    }
  }
  onContentChanged(context) {
    console.log('Content changed: ');
    console.log(context.html.length - 1);
    if (context == null || context.html == null) {
      return;
    }
    if (context.html.length >= 300) {
      let oldDelta = context['oldDelta'];
      if (oldDelta == null) {
        return;
      }
      context.editor.setContents(oldDelta.ops);
    }
  }
  onChange(value) {
  let list = this.languages.filter(x => x.value === value)[0];
   this.currentUser.address.data[0].c = list.type;
    }

   public getAddress(id) {
    this.address =  this.languages.find(t => t.type === id);
    }

    saveNewEmail(form) {
      this.currentUser.email.unshift({name: this.newEmail, p: 2});
      this.anotherEmail = false;
      this.newEmail = "";
      const phone = form.controls[("phone")].value;

      this.currentUser.phone[0] = phone;
      this.userService.updateUser(this.currentUser._id, this.currentUser)

    }

    addNewEmail() {
      this.anotherEmail = true;
    }
    addNewPhoneNumber() {
      this.anotherNumber = true
    }

    editPhoneNumber(index) {
      this.isEditingPhone = true;
      this.isSelectedPhoneIndex = index;
    }
    cancelPhoneEdit() {
      this.isEditingPhone= false;
      this.isSelectedPhoneIndex = null;
    }

    removeEmail(i) {
      this.currentUser.email.splice(i, 1);
      this.userService.updateUser(this.currentUser._id, this.currentUser)
    }
    removePhone(i) {
      this.currentUser.phone.splice(i, 1);
      this.userService.updateUser(this.currentUser._id, this.currentUser)

    }
    editEmail(index) {
      this.isEditingMode = true;
      this.isSelectedIndex = index;
    }
    saveNewPhoneNumber(form) {
      const phone = form.controls[("addNewPhone")].value;
      this.currentUser.phone.unshift(phone);
      this.newNumber = {} as any;
      this.isEditingPhone = false;
      this.anotherNumber = false;
       this.userService.updateUser(this.currentUser._id, this.currentUser)
    }

    saveEditedPhone(form, index) {
      const phone = form.controls[("editedPhone")].value;
      this.currentUser.phone[index] = phone;

      console.log( this.currentUser.phone[index])
      this.isEditingPhone = false;
    }

    save(form, email, index) {
      this.currentUser.email[index] = email;
      this.isEditingMode = false;
      this.isSelectedIndex = null;
      const phone = form.controls[("phone")].value;

      this.currentUser.phone[0] = phone;
       this.userService.updateUser(this.currentUser._id, this.currentUser)
    }

    updateBuy() {

    }

    changePrivacy(event) {

      if (!event) {
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent);
        this.dialogRef.componentInstance.message = "If you switch to a public account, anyone can watch your profile. You won't need to approve followers and all pending follow requests will be automatically approved." ;
        this.dialogRef.componentInstance.header = "Switch to public account ?" ;
        this.dialogRef.componentInstance.confirmButtonText = "Confirm";
        this.dialogRef.afterClosed().subscribe(result => {
          const newElement = document.getElementById("comSwitchCheckChecked") as HTMLInputElement;
          if(result) {
               newElement.checked = false;
               this.currentUser.pAccount = false;
               this.userService.updateUserForPrivacy(this.currentUser._id, this.currentUser);
          } else {
            newElement.checked = true;
            this.currentUser.pAccount = true;
          }
          this.dialogRef = null;
        });
      }


    //  this.currentUser.privacy = event ? 0 : 1;
    }
    saveChanges() {
     this.userService.updateUser(this.currentUser._id, this.currentUser);
    }

    edit() {
      this.dialog.open(BlockedUserDialogComponent);

    }


    userDeactivate() {
      this.router.navigateByUrl(this.currentUser.userUrl + '/settings/deactivate_delete_account', { state: {user: this.currentUser}});

    }


}
