import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  Validators,
  UntypedFormBuilder,
  AbstractControl,
  UntypedFormGroup,
  UntypedFormControl,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  EditOffer,
  OfferDialogComponent,
} from "../components/modals/editors/offer-dialog/offer-dialog.component";
import {
  Duration,
  EmploymentType,
  Incomes,
  Remote,
} from "../shared/enums/enums-model";
import { AuthService } from "../shared/services/auth.service";
import { PagesService } from "../shared/services/pages.service";
import { SenderService } from "../shared/services/sender.service";
import { UserService } from "../shared/services/user.service";
import * as QuillNamespace from "quill";
let Quill: any = QuillNamespace;
import ImageResize from "quill-image-resize-module";
import { ImageHandler, Options, VideoHandler } from "ngx-quill-upload";
import { ContentChange, QuillEditorComponent } from "ngx-quill";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { JobsService } from "../shared/services/jobs.service";
import {
  EditPCKnowledge,
  PcKnowledgeDialogComponent,
} from "../components/modals/editors/pc-knowledge-dialog/pc-knowledge-dialog.component";
import {
  DriveLicenseDialogComponent,
  EditDriveLicense,
} from "../components/modals/editors/drive-license-dialog/drive-license-dialog.component";
import {
  EditLanguage,
  LanguageDialogComponent,
} from "../components/modals/editors/language-dialog/language-dialog.component";
import { Job, Page } from "../shared/interfaces/model";
import { User } from "../shared/interfaces/user.model";
import { CountryDataService } from "../shared/services/country-data.service";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modul3es/imageHandler", ImageHandler);
Quill.register("modules/videoHandler", VideoHandler);
import { Location } from '@angular/common';


@Component({
  selector: "app-create-your-job",
  templateUrl: "./create-your-job.component.html",
  styleUrls: ["./create-your-job.component.scss"],
})
export class CreateYourJobComponent implements OnInit {
  public job = new Job();
  languages = [];
  @Input() isPage: boolean = false;
  @Input() page: Page;

  firstFormGroup = this._formBuilder.group({
    positionTitle: ["", Validators.required],
    employmentType: ["", Validators.required],
    street: ["", Validators.required],
    postalCode: ["", Validators.required],
    city: ["", Validators.required],
    country: ["", Validators.required],
    state: [""],
    remote: ["", Validators.required],
    language: [""]
  });
  secondFormGroup: UntypedFormGroup = this._formBuilder.group({
    pcKnowledge: [""],
    skillsOffer: this._formBuilder.array([]),
    driveLicense: [""],
    language: [""],
    qualifications: [""],
  });

  thirdFormGroup = this._formBuilder.group({
    editor: ["", Validators.required],
  });
  fourthFormGroup = this._formBuilder.group({
    salaryBudget: ["", Validators.required],
    salaryStatus: ["", Validators.required],
    currency: ["", Validators.required],
    jobDuration: ["", Validators.required],
    startDate: [""],
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });


  currency;
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite" },
    { id: Remote.hybrid, description: "project.firstPage.hybrid" },
    { id: Remote.remote, description: "project.firstPage.remote" },
  ];

  @ViewChild("editor", {
    static: true,
  })
  editor: QuillEditorComponent;

  model: any;
  public user: User;
  isLinear = false;
  selectedUser = "";
  pcKnowledge = "";
  skillsOffer = "";
  driveLicense = "";
  language = "";
  qualifications = "";
  public modules;
  minDate = new Date();
  selectedDate = new Date();
  startDateLimited = new Date();
  currentYear = new Date().getFullYear();
  currentDate = new Date().getDate();
  currentMonth = new Date().getMonth();
  endDateLimited = new Date(
    this.currentYear,
    this.currentMonth,
    this.currentDate + 1
  );

  public employmentType = [
    {
      id: EmploymentType.EmployedFullTime,
      description: "employmentType.EmployedFullTime",
    },
    {
      id: EmploymentType.EmployedPartTime,
      description: "employmentType.EmployedPartTime",
    },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    {
      id: EmploymentType.BordMember,
      description: "employmentType.Boardmember",
    },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    {
      id: EmploymentType.SelfEmployed,
      description: "employmentType.SelfEmployed",
    },
    {
      id: EmploymentType.Shareholder,
      description: "employmentType.Shareholder",
    },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" },
  ];
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ];

  public jobDurationArray = [
    {
      id: Duration.unlimited,
      description: "job.fourthPage.duration.unlimited",
    },
    {
      id: Duration.temporary,
      description: "job.fourthPage.duration.temporary",
    },
  ];

  jobDuration: typeof Duration = Duration;
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public http: HttpClient,
    public userService: UserService,
    public authService: AuthService,
    public pageService: PagesService,
    public receiverService: SenderService,
    public cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public jobService: JobsService,
    public router: Router,
    private countryDataService: CountryDataService,
    private location: Location

  ) { }

  ngOnInit(): void {
    this.countryDataService.countryData$.pipe().subscribe(res => this.model = res);



    this.job.pcKnowledge = [];
    this.job.skillsOffer = [];
    this.job.driveLicense = [];
    this.job.language = [];
    this.job.qualifications = [];
    this.job.incomes = {
      budget: 0,
      month: 0,
      s: '',
      hour:0 ,
      year: 0

    };
    if (this.isPage) {
      const findPrimaryLocation = this.page.locations.find(t => t.headquarters === true);

        this.job.postalCode = findPrimaryLocation.postalCode as any;
        this.job.city = findPrimaryLocation.city;
        this.job.c =findPrimaryLocation.c;
        this.job.street = findPrimaryLocation.street;
        this.job.state = findPrimaryLocation.state;
        this.currency = findPrimaryLocation.c;
    } else {
      this.userService
        .getUserContactData(this.authService.userID)
        .subscribe((res) => {
          this.user = res;
          this.http.get<any>(`assets/languages/${this.user?.language?.primary}/language.json`).subscribe(data => {

            this.languages = data;
            this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));

          })
          this.job.postalCode = this.user.address.data[0].postalCode;
          this.job.city = this.user.address.data[0].city;
          this.job.c = this.user.address.data[0].c;
          this.job.street = this.user.address.data[0].street;
          this.job.state = this.user.address.data[0].state;
          this.currency = this.user.address.data[0].c;
          this.job.language
        });
    }
    this.thirdFormGroup.controls.editor.valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((data) => {
        // tslint:disable-next-line:no-console
        console.log("native fromControl value changes with debounce", data);
      });


    this.modules = {
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@", "#"],
        linkTarget: "_blank",
        source: (searchTerm, renderList, mentionChar) => {
          let values = [{ id: "", value: "", link: null }];

          if (mentionChar === "@") {
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++) {
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              ) {
                /*  this.dataService.getUserModel(values[i].id).subscribe((data: Model) => {                  });
                  matches.unshift(values[i]); */
              }

              renderList(matches, searchTerm);
            }
          }
        },
      },
      imageResize: true,
      imageDrop: true,
    };
  }
  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  selectUser() {
    console.log(this.firstFormGroup);
  }
  addPcKnowledge(data: EditPCKnowledge) {
    this.dialog
      .open(PcKnowledgeDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.job.pcKnowledge = res.pcKnowledge;
        }
      });
  }
  addSkillsOffer(data: EditOffer) {
    this.dialog
      .open(OfferDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.job.skillsOffer = res.skillsOffer;
        }
      });
  }
  addJobDriveLicense(data: EditDriveLicense) {
    this.dialog
      .open(DriveLicenseDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.job.driveLicense = res.driveLicense;
        }
      });
  }

  addJobLanguage(data: EditLanguage) {
    this.dialog
      .open(LanguageDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.job.language = res;

        }
      });
  }
  addLanguage() {
    this.job.language.unshift({ name: this.language, rate: 1 });
  }

  addQualifications(data: EditLanguage) {
    this.dialog
      .open(LanguageDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        } else {
          this.job.qualifications = res.qualifications;
        }
      });
  }

  removeSkills(index) {
    this.job.skillsOffer.splice(index, 1);
  }
  saveData() {
    if (
      this.fourthFormGroup.get("jobDuration").value ===
      this.jobDuration.unlimited
    ) {
      this.job.startDate = this.fourthFormGroup.get("startDate").value;
    }

    if (this.isPage) {
      console.log(this.page._id, "jejeje")
      this.job.pageID = this.page._id;
      this.job.userID = this.authService.userID;
      this.jobService
        .createJob(this.job, false, this.page._id)
        .subscribe((r) => { });
    } else {
      this.job.userID = this.authService.userID;
      this.jobService.createJob(this.job, true).subscribe((r) => { });
    }

    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
    this.fourthFormGroup.reset();

    if (this.isPage) {
      this.router.navigateByUrl(this.page.pageUrl + "/ourjobs");
    } else {
      this.router.navigateByUrl(this.user.userUrl + "/jobs");
    }
  }

  get getDays() {
    var date1 = new Date(this.secondFormGroup.get("start").value);
    var date2 = new Date(this.secondFormGroup.get("end").value);

    var Time = date2.getTime() - date1.getTime();
    var Days = Time / (1000 * 3600 * 24);
    return Days;
  }

  get selectedCountry() {
    return this.model.find(
      (r) => r.ISO_2 === this.firstFormGroup.get("country").value
    );
  }

  changeCountry(event) {
    this.currency = event;
    this.job.currency = this.currency;
  }
  changeCurrency(event) {
    this.currency = event;
    const object = this.model.find((r) => r.ISO_2 === event);
    this.job.currency = object?.currency.Alphabeticcode;
  }
  resetData() {
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.thirdFormGroup.reset();
    this.fourthFormGroup.reset();
    this.location.back();
  }

  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }

}
export const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) return null;

    return { MinLengthArray: true };
  };
};
