import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectComponent } from './project.component';
import { BoardComponent } from './pages/board/board.component';
import { ProjectRoutingModule } from './project.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BoardDndComponent } from './components/board/board-dnd/board-dnd.component';
import { BoardFilterComponent } from './components/board/board-filter/board-filter.component';
import { BoardDndListComponent } from './components/board/board-dnd-list/board-dnd-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from 'src/app/material.module';
import { SmartCareerCardComponent } from './components/smart-career-card/smart-career-card.component';
import { IssueModalComponent } from './components/issue-modal/issue-modal.component';
import { IssueDetailsComponent } from './components/issue-details/issue-details.component';
import { QuillModule } from "ngx-quill";
import { GravatarModule } from 'ngx-gravatar';
import { TranslateModule } from '@ngx-translate/core';
import { FullIssueDetailsComponent } from './components/full-issue-details/full-issue-details.component';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { SmartitsChipsModule } from 'src/app/smartits-ui/chips/smartits-chips.module';
import { UserNameModule } from 'src/app/user-name/user-name.module';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { DirectivesModule } from 'src/app/shared/directives/directives.module';
import { UserDialogModule } from 'src/app/project-details/user-dialog-name/user-dialog.module';


@NgModule({
  declarations: [
    ProjectComponent,
    BoardComponent,
    BoardDndComponent,
    BoardFilterComponent,
    BoardDndListComponent,
    SmartCareerCardComponent,
    IssueModalComponent,
    IssueDetailsComponent,
    FullIssueDetailsComponent,
    SvgIconComponent
  ],
  imports: [
    CommonModule,
    ProjectRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MaterialModule,
    DragDropModule,
    GravatarModule,
    TranslateModule,
    PipeModule,
    SmartitsChipsModule,
    UserNameModule,
    DirectivesModule,
    UserDialogModule,
  ],
  exports: [
    ProjectComponent,
    BoardComponent,
    BoardDndComponent,
    BoardFilterComponent,
    BoardDndListComponent,
    SmartCareerCardComponent,
    IssueModalComponent,
    IssueDetailsComponent,
    FullIssueDetailsComponent,
    SvgIconComponent,
  ]
})
export class ProjectModule { }
