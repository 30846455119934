import { Component, Input, OnInit } from "@angular/core";
import { Privacy, StatusFollow } from "src/app/shared/enums/enums-model";
import { Model, Notification } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import { NotificationHelperService } from "src/app/shared/utils/notification-helper.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit {
  public notifications: Notification[] = [];
  public modelData: Model;
  public usersNotification: User[] = [];
  public currentUser: User;
  @Input() date

  @Input() data: any;
  @Input() pageID: any;

  constructor(public notificationHelper: NotificationHelperService,
    private authService: AuthService,
    private userService: UserService,
    private pageService: PagesService) { }

  ngOnInit(): void {

    if (!this.data) {
      this.data = this.userService.showNotifications(this.authService.userID)
    } else if (!!this.data) {
      this.data = this.pageService.showNotifications(this.pageID)

    }
    this.userService.getUserContactData(this.authService.userID).subscribe(t => {
      this.currentUser = t;
    })

  }

  calculateData(data: Date): number {
    const newData = new Date();
    let Difference_In_Time = newData.getTime() - data.getTime();
    let days;

    // To calculate the no. of days between two dates
    return days = Difference_In_Time / (1000 * 3600 * 24);
  }

  acceptFollow(user: User) {

    console.log(user, "user")

    const findUser = user.following.data.find(t => t.userId === this.currentUser?._id && t.status === StatusFollow.Private);
    if (findUser) {

      this.currentUser.following.data.find(t => t.userId === user._id).status = 2;
      user.following.data.find(t => t.userId === this.currentUser._id).status = 2

      user.following.data.find(t => t.userId === this.currentUser._id).timestamp = new Date();
      this.currentUser.following.data.find(t => t.userId === user._id).timestamp = new Date();
      this.currentUser.followers.counter = this.currentUser.followers.counter + 1;
      this.userService.updateUser(this.currentUser._id, this.currentUser);
      this.userService.updateUser(user._id, user);

    } else {
      user.following.data.find(t => t.pageId === this.pageID).status = 1;
      user.following.data.find(t => t.pageId === this.pageID).timestamp = new Date();
      this.currentUser.followers.counter = this.currentUser.followers.counter + 1;

      this.currentUser.notifications.followers.counter = this.currentUser.notifications.followers.counter - 1;

      this.userService.updateUser(this.currentUser._id, this.currentUser);
      this.userService.updateUser(user._id, user);
    }

    this.ngOnInit();

  }

  deleteUserFromPage(user: User) {

    if (this.pageID) {
      const data = user.following.data.find(t => t.pageId === this.pageID).status = 0;
      user.following.data.splice(data, 1);

      this.userService.updateUser(user._id, user);

    } else {
      const data = user.following.data.find(t => t.userId === this.currentUser._id).status = 0;
      user.following.data.splice(data, 1);

      this.userService.updateUser(user._id, user);
    }

    this.ngOnInit();
  }


  getDate(user: User) {
    return user.following.data.find(t => t.userId === this.authService.userID).timestamp;
  }



}
