import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Group, Model, Page, SubFiles } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { GroupService } from "src/app/shared/services/group.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";

@Component({
  selector: "app-empty-file",
  templateUrl: "./empty-file.component.html",
  styleUrls: ["./empty-file.component.scss"]
})
export class EmptyFileComponent implements OnInit {
  public confirmationDialog: MatDialogRef<WarningDialogComponent>
  public users: User[] = [];
  public pages: Page[] = [];
  public groups: Group[] = [];
  public config;
  public user: User;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EmptySubFile,
    private dialogRef: MatDialogRef<EmptyFileComponent>,
    public dialog: MatDialog,
    public authService: AuthService,
    private userService: UserService,
    private pageService: PagesService,
    private groupService: GroupService) { }

  ngOnInit(): void {
    if (!this.data.edit) {
      this.data.file = {} as SubFiles;
    }
    else {
      this.userService.user().subscribe((res: User) => {
        this.user = res;
      this.data.file = res.cv.data[0].files[0].subCategories[this.data.index];
      });
    }
    this.userService.userModel().subscribe(res => {
      this.users = res;
      this.pageService.getAllPages().subscribe(t => {
        this.pages = t;
      })
      this.groupService.getAllGroups().subscribe(t => {
        this.groups = t;
      })

      this.config = {
        "emoji-toolbar": true,
        "emoji-textarea": true,
        "emoji-shortname": true,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ["@", "#"],
          linkTarget: "_blank",
          source: (searchTerm, renderList, mentionChar) => {

            let values = [{ id: "", value: "", link: null }];

            if (mentionChar === "@") {
              this.users.forEach(t => {
                values.unshift({ id: t._id, value: `${t.firstName} ${t.lastName}`, link:window.location.origin + '/' + t._id + "/cv" });

              })
              this.pages.forEach(t => {
                values.unshift({ id: t._id, value: `${t.name}`, link:window.location.origin + '/' + t.pageUrl });
              });
              this.groups.forEach(t => {
                values.unshift({id: t._id, value: `${t.name}`, link:window.location.origin + '/groups/' + t.url});
              })

            } else if (mentionChar === "@") {


            }
            if (searchTerm.length === 0) {

              renderList(values, searchTerm);
            } else {
              const matches = [];
              for (let i = 0; i < values.length; i++) {
                if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                 /* this.dataService.getUserModel(values[i].id).subscribe((data: Model) => {
                    values[i].link = data.userUrl;
                    matches.unshift(values[i]);

                  }); */
                  matches.unshift(values[i]);
                }


                renderList(matches, searchTerm);

              }
            }
          },
        },
      }
    });
  }
  saveData() {
    if(this.authService.isLoggedIn) {
      this.userService.updateUser(this.authService.userID, this.user);
   // this.dataService.updatePage(this.data.model._id, this.data.model);
    this.dialogRef.close(this.data);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }

  }
}
export interface EmptySubFile {
  edit?: boolean;
  model?: Model;
  file: SubFiles;
  index: number;
}
