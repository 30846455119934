import { Issue } from './../issue-modal/issue-modal.component';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IssuePriority, IssuePriorityDisplay, IssuePriorityIcon, JIssue } from 'src/app/shared/interfaces/jira.model';
import * as QuillNamespace from "quill";
let Quill: any = QuillNamespace;
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageHandler", ImageHandler);
Quill.register("modules/videoHandler", VideoHandler);
import { ImageHandler, Options, VideoHandler } from "ngx-quill-upload";
import ImageResize from "quill-image-resize-module";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { JComment } from 'src/app/shared/interfaces/comment';
import { JUser } from 'src/app/shared/interfaces/jira-user.model';
import { IssueUtil } from 'src/app/shared/utils/issue';
import { UserService } from 'src/app/shared/services/user.service';
import { User } from 'src/app/shared/interfaces/user.model';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SenderService } from 'src/app/shared/services/sender.service';
import { Subscription } from 'rxjs';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { Job, Page, Project } from 'src/app/shared/interfaces/model';
import _ from 'lodash'
import { ConfirmationDialogComponent } from 'src/app/components/modals/confirmation-dialog/confirmation-dialog.component';
import { PagesService } from 'src/app/shared/services/pages.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { DataObject, DialogDataDetailsComponent } from 'src/app/components/dialog-data-details/dialog-data-details.component';

@Component({
  selector: 'app-issue-details',
  templateUrl: './issue-details.component.html',
  styleUrls: ['./issue-details.component.scss']
})
export class IssueDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() issue: JIssue;
  @Input() isShowFullScreenButton: boolean;
  @Input() isShowCloseButton: boolean;
  @Input() isFullView: boolean;
  @Output() onClosed = new EventEmitter();
  @Output() onOpenIssue = new EventEmitter<string>();
  @Input() job: Job;
  @Input() isProject: boolean = false;
  @Input() project: Project;
  @Input() isNotPage: boolean;

  cancelProject;
  cancelMessage;


  public page: Page;
  public subscription: Subscription;
  isEditing: boolean;
  editorOptions;
  descriptionControl: UntypedFormControl;
  @Input() issueId: string;
  @Input() comment: JComment;
  isEditingDescription: boolean = false;
  isEditingNote: boolean = false;
  createMode: boolean;
  @ViewChild('commentBoxRef') commentBoxRef: ElementRef;
  commentControl: UntypedFormControl;
  noteControl: UntypedFormControl;
  user: User;
  appliedUser: User;
  cancelledUser: User;
  cancelledPage: Page;

  selectedPriority: IssuePriorityIcon;
  selectedPriorityOfName: IssuePriority;
  jobManage;
  IssuePriorityDisplay = IssuePriorityDisplay;
  priorities: IssuePriorityIcon[];
  prioritiesWithIcon: IssuePriorityIcon[] = [
    IssueUtil.getIssuePriorityIcon(IssuePriority.LOWEST),
    IssueUtil.getIssuePriorityIcon(IssuePriority.LOW),
    IssueUtil.getIssuePriorityIcon(IssuePriority.MEDIUM),
    IssueUtil.getIssuePriorityIcon(IssuePriority.HIGH),
    IssueUtil.getIssuePriorityIcon(IssuePriority.HIGHEST)
  ];

  public issueStatus = [
    { id: 'Applied', description: 'Applied' },
    { id: 'InProgress', description: 'In Progress' },
    { id: 'Done', description: 'Done' }
  ]


  public config = {
    toolbar: false
  };
  editorConfig = {
    padding: "0",
  }

  writeMessage;
  projectData: Project;

  checkIDOfUserPage;
  selectedPage: Page;

  ;

  noticeForm = new UntypedFormGroup({
    notice: new UntypedFormControl()
  });



  constructor(public userService: UserService,
    private router: Router,
    private matDialog: MatDialog,
    private senderService: SenderService,
    private jobService: JobsService,
    private pageService: PagesService,
    private authService: AuthService,
    private projectService: ProjectService,
    private changeDetectionservices: ChangeDetectionService) { }
  ngOnDestroy(): void {
    this.isFullView = false;
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!this.createMode || this.isEditing) {
      return;
    }
    if (event.key === 'M') {
      this.commentBoxRef.nativeElement.focus();
      this.isEditing = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    const issueChange = changes.issue;
    if (issueChange.currentValue !== issueChange.previousValue) {
      this.descriptionControl = new UntypedFormControl(this.issue?.message?.description);
    }
  }

  ngOnInit(): void {
    this.commentControl = new UntypedFormControl('');
    if (!this.isProject) {
      this.jobService.getJob(this.job._id).subscribe(tt => {
        this.projectData = tt;
        this.userService.getUserContactData(this.authService.userID).subscribe(res => {
          this.user = res;
        })

      })
    }
    this.senderService.manageProjectID.subscribe(t => {
      if (t.isProject) {
        this.projectService.getProject(t.ID).subscribe(tt => {
          this.projectData = tt;


          this.userService.getUserContactData(this.authService.userID).subscribe(res => {
            this.user = res;
          })
          if (localStorage.getItem("pageID")) {
            this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
              this.selectedPage = page.page;
              this.checkIDOfUserPage = this.projectData.manage_.find(t => t.userID === this.authService.userID) && this.isMessaging;
              const findId =  this.project.manage_.find(t => t?.pageID !== this.project.pageID)?.seen.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID;

              if (findId) {
                return
              } else {
                this.project.manage_.find(t => t.pageID !== this.project.pageID).seen.unshift({
                  userID: this.authService.userID,
                  pageID: this.project.pageID,
                  timestamp: new Date()
                })
                this.projectService.updateProject(this.projectData._id, this.project)

              }


              if (this.issue.cancel.pageID !== this.selectedPage._id && this.isMessaging) {
                if (!!this.issue.cancel.pageID) {
                  this.pageService.getPage(this.issue.cancel.pageID).subscribe(tt => {
                    this.cancelledPage = tt;
                  })
                }

              } else if (this.issue.cancel.pageID === this.selectedPage._id && this.isMessaging) {
                if (this.issue.cancel) {
                  if (this.issue.cancel.userID) {
                    this.userService.getUserContactData(this.issue.cancel.userID).subscribe(res => {
                      this.cancelledUser = res;
                    })
                  }

                }


              }
            });
          }
        })
      } else {
        this.jobService.getJob(t.ID).subscribe(tt => {
          this.projectData = tt;
          this.userService.getUserContactData(this.authService.userID).subscribe(res => {
            this.user = res;

          })

        })
      }
    });


    if (this.isProject) {
      this.jobManage = _.cloneDeep(this.project.manage_[0]);
      let id = this.jobManage?.userID;
      if (id) {
        this.userService.getUserContactData(id).subscribe(tt => {
          this.appliedUser = tt;
        })
      }
    } else {
      this.jobManage = _.cloneDeep(this.job.manage_[0]);
      let id = this.jobManage?.userID
      if (id) {
        this.userService.getUserContactData(id).subscribe(tt => {
          this.appliedUser = tt;
        })
      }
    }

    this.userService.user().subscribe((user) => {
      this.user = user;
    });
    if (this.isFullView) {
      this.senderService.issueData$.subscribe((res: any) => {
        this.issue = res;
      })
    }





    if (!!this.issue?.pageID) {
      this.pageService.getPage(this.issue?.pageID).subscribe(t => {
        this.page = t.page;
      })
    }




    this.editorOptions = {
      "emoji-toolbar": true,
      "emoji-textarea": true,
      "emoji-shortname": true,
      imageResize: true,
      imageDrop: true,


    }
  }

  reApply() {
    this.project.manage_.find(t => t.id === this.issue.id).cancel.status = 1;
    //  this.jobService.updateJob(this.userJob._id, this.userJob);

    this.projectService.updateProject(this.project._id, this.project);
  }
  setEditMode(mode: boolean) {
    this.isEditingDescription = mode;
  }
  setCommentEditMode(mode: boolean) {
    this.createMode = mode;
    this.isEditing = mode;
  }
  cancel() {
    this.descriptionControl.patchValue(this.issue.message.description);
    this.setEditMode(false);
  }
  cancelComment() {
    this.commentControl.patchValue("");
    this.setCommentEditMode(false);
  }
  setNoteEditMode(mode: boolean) {
    this.isEditingNote = mode;
  }
  saveNote() {
    this.issue.note = this.issue.note;

    if (this.isProject) {
      this.project.manage_.find(t => t = this.issue).note = this.issue.note;
      this.projectService.updateProject(this.project._id, this.project);
    } else {
      this.job.manage_.find(t => t = this.issue).note = this.issue.note;
      this.jobService.updateJob(this.job._id, this.job);
    }

    this.setNoteEditMode(false);
  }
  addNewNote(event) {
    this.issue.note = event.target.value;

  }
  cancelNodeEdit() {
    this.commentControl.patchValue(this.issue.note);
    this.setNoteEditMode(false);
  }



  deleteIssue() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Delete',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.job.manage_ = this.job.manage_.filter(t => t !== this.issue);
        this.jobService.updateJob(this.job._id, this.job);
        this.matDialog.closeAll();
      }
    });


  }



  addComment() {
    const now = new Date();
    this.issue.comments.unshift({
      id: `${now.getTime()}`,
      createdAt: now.toISOString(),
      body: this.writeMessage,
      userID: this.authService.userID,
    })

    this.projectService.updateProject(this.job._id, this.job);
    this.cancelAddComment();
  }

  deleteChat() {

    //  const index = this.projectData.manage_.find(t => t === this.issue);
    //  const index = this.projectData.manage_.map(i => i).indexOf(this.issue);
    const index = this.projectData.manage_.map(e => e.id).indexOf(this.issue.id);
    this.projectData.manage_.splice(index, 1);
    this.projectService.updateProject(this.projectData._id, this.projectData);
    this.changeDetectionservices.emitChanges(true);
    this.matDialog.closeAll();


  }
  goToPageDropdown() {
    localStorage.setItem("pageID", this.page._id)
    this.router.navigateByUrl("/" + this.page.pageUrl)

  }

  viewProfile() {
    localStorage.setItem("userProfileID", this.appliedUser._id)
    this.router.navigateByUrl("/" + this.appliedUser.userUrl + "/start");
    this.matDialog.closeAll();
  }

  addCommentProject() {
    const now = new Date();

    if (this.isProject && this.isNotPage) {
      const now = new Date();
      this.projectData.manage_.find(t => t.userID === this.issue.userID).comments.unshift({
        createdAt: now.toISOString(),
        body: this.writeMessage,
        userID: this.authService.userID,
      });
      this.projectData.manage_.find(t => t.userID === this.issue.userID).seen = [] as any;
      this.issue.seen = [] as any;
      this.issue.seen.unshift({
        timestamp: new Date(),
        userID: this.authService.userID,
      })
      this.projectData.manage_.find(t => t.userID === this.issue.userID).seen.unshift({
        timestamp: new Date(),
        userID: this.authService.userID,
      });
      this.issue.comments.unshift({
        createdAt: now.toISOString(),
        body: this.writeMessage,
        userID: this.authService.userID,
      })

       this.projectService.updateProject(this.projectData._id, this.projectData);
      this.cancelAddComment();
    } else {
      if (this.isProject && this.selectedPage._id) {
        const test = this.projectData.manage_.findIndex(t => t.id === this.issue.id);

        this.issue.comments.unshift({
          id: `${now.getTime()}`,
          createdAt: now.toISOString(),
          body: this.writeMessage,
          userID: this.authService.userID,
          pageID: this.selectedPage._id
        })

        this.projectData.manage_.find(t => t.pageID !== this.selectedPage._id).seen = [] as any;


        this.projectData.manage_.find(t => t.pageID !== this.selectedPage._id).seen.unshift({
          userID: this.authService.userID,
          pageID: this.selectedPage._id,
          timestamp: new Date()
        })

        this.projectData.manage_.find(t => t.id === this.issue.id).comments.unshift({
          id: `${now.getTime()}`,
          createdAt: now.toISOString(),
          body: this.writeMessage,
          userID: this.authService.userID,
          pageID: this.selectedPage._id
        })

        this.projectService.updateProject(this.projectData._id, this.projectData);
      } else {
        console.log(this.job, "job")
        this.job.manage_.find(t => t.id === this.issue.id).comments.unshift({
          id: `${now.getTime()}`,
          createdAt: now.toISOString(),
          body: this.writeMessage,
          userID: this.authService.userID,
        })

        this.jobService.updateJob(this.job._id, this.job);

      }

    }
  }


  cancelAddComment() {
    this.commentControl.patchValue('');
    this.setCommentEditMode(false);
    this.writeMessage = false;
  }

  openIssuePage(issueId) {
    this.matDialog.closeAll();
    this.senderService.issueDataVis(this.issue);
    this.router.navigateByUrl(this.router.url.replace('/board', `${'/issue'}/${issueId}`));

  }

  goToCV(data: DataObject) {
    this.matDialog.open(DialogDataDetailsComponent, {
      data
    });
  }


  cancelProjectButton() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      userId: this.authService.userID,
      pageId: this.project.pageID
    }

    this.jobManage.cancel = dataForCancel;

    this.project.manage_.find(t => t.id === this.issue.id).cancel = dataForCancel;

    this.projectService.updateProject(this.project._id, this.project);
    this.cancelProject = false;
  }

  cancelProjectData() {
    this.cancelProject = true;
  }


  goToPage() {
    localStorage.setItem("pageID", this.page._id)
    window.open(this.page.pageUrl + '/about', '_blank');

  }
  remoteCheck(event) {



    this.issue.status = event;

    if(this.isProject) {
      this.projectData.manage_.find(t => t.id === this.issue.id).status = event;

      //  this.projectService.updateProject(this.project._id, this.project);



      // this.job.manage_ = [];
      // this.project.manage_ = [];
      // this.project.manage_ = this.jobManage;
      //this.job.manage_ = this.jobManage;
      this.projectService.updateProject(this.projectData._id, this.projectData);
      this.changeDetectionservices.emitChanges(true);
    } else {
      this.job.manage_.find(t => t.id === this.issue.id).status = event;
      this.jobService.updateJob(this.job._id, this.job);
      this.changeDetectionservices.emitChanges(true);

    }



    //this.jobService.updateJob(this.job._id, this.job);
  }



  reactivate(event: JIssue) {
    this.issue.cancel.status = 1;
    this.issue.cancel.timestamp = new Date();
    const index = this.jobManage.findIndex(t => t.id === this.issue.id);
    this.jobManage[index] = this.issue;
    this.job.manage_ = [];
    this.job.manage_ = this.jobManage;
    this.jobService.updateJob(this.job._id, this.job)
  }
  public get isMessaging() {

    if (!!this.selectedPage) {
      return this.selectedPage?.roles?.Messages?.find(res => res.userID === this.authService.userID);

    }
  }

  checkId(comment) {
    return this.issue.userID !== comment.userID;
  }

}
