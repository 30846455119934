<ng-container>
<aside class="pt-4 aside" style="left: 0">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3">
        <h2>{{'groupSettings.manage' | translate}}</h2>
        <li class="nav-item dropdown">
          <button class="btn btn-default dropdown-toggle px-0 nav-item" type="button" id="menu2" data-toggle="dropdown">
            <img *ngIf="hasPhoto" [round]="false" [src]="profileImage" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
              <img *ngIf="!hasPhoto" [round]="false" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="35">
              <span>{{group.name}}</span>


             <span class="caret"></span>
          </button>
          <div class="test">
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" style="    position: absolute;
          will-change: transform;
          top: 0px;
          left: 0px;
          width: 100%;
          transform: translate3d(0px, 45px, 0px);
          max-height: 20rem;
          overflow: auto;">
            <ng-container *ngFor="let group of groups$ | async">
            <app-group-list [group]="group" [sidebarGroup]="'sidebar-group'"></app-group-list>
          </ng-container>
          </div>
        </div>
        </li>

        <hr>
        <div>
          <button type="button" class="button-f button-sc btn-light" [routerLink]="'/groups/' + this.webUrl"  [routerLinkActive]="['active']">
            <div >
              <i class="bi bi-house-door-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.home' | translate}}</span>
                        </div>
          </button>
          <hr>
          <h2>{{'groupSettings.admin' | translate}}</h2>
          <button type="button" class="button-f button-sc btn-light" routerLink="member-requests" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-person-plus-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.member' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="auto_approve_requests" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-person-check-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.automaticMember' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="membership_questions"  [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-person-lines-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.membershipQuestions' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light"  routerLink="pending_posts" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-newspaper width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.pendingPosts' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="post_tags_list"  [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-card-heading width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.postTopic' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="scheduled_posts" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-table width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.scheduledPost' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="admin_activities"   [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-clock-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.activityLog' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="manage_rules" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-people-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.rules' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="member_reported_content" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-exclamation-square-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.memberReported' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light"  routerLink="alerted" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-chat-square-dots-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.moderation' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="group_quality" [routerLinkActive]="['active']">
            <div >
             <i class="bi bi-award-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.quality' | translate}}</span>
                        </div>
          </button>
          <button type="button" class="button-f button-sc btn-light" routerLink="settings/about" [routerLinkActive]="['active']" (click)="edit($event)">
            <div >
             <i class="bi bi-gear-fill width-40"></i>
              <span class="span-padding-left10">{{'groupSettings.sidebar.settings' | translate}}</span>
                        </div>
          </button>
      </div>

    </div>
  </section>
  <footer class="footer footer-sidebar"></footer>
</aside>
</ng-container>
