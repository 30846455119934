<mat-dialog-content>
  <div class="modal-header">
    <h4 class="modal-title">{{ header }}</h4>
    </div>
    <div class="modal-body" [innerHTML]="message">
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="btn btn-light me-2" (click)="onConfirmClick()" >{{confirmButtonText}}</button>
	<button class="btn btn-light" mat-dialog-close >{{cancelButtonText}}</button>
</mat-dialog-actions>
