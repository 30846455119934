import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserContact } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ContactsComponent } from '../friends.component';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss']
})
export class ContactItemComponent implements OnInit {
  @Input() contact: UserContact;
  @Input() image: boolean;
  @Output() outside = new EventEmitter<UserContact>();
  public user: User;
  public imageProfile;

  constructor(     public authService: AuthService,
    public userService: UserService,
    public imageService: ImageService,
    public cd: ChangeDetectorRef,
    public router: Router,
    public sendData: SenderService,
    ) { }

  ngOnInit(): void {

    this.userService.getUserContactData(this.contact.contactId).subscribe(res => {
     this.user = res;
    });
  }

  onAcceptButtonClick(friend) {
    this.outside.emit(friend);
    this.cd.detectChanges();
  }

  navigateToUser(id) {

  }


}
