<ng-container *ngIf="!isUserSearch">
  <div *ngIf="!!data.company" class="remove-icon-border">
    <div class="card-header px-0 py-1">

      <span stopPropagation class="bi bi-trash3-fill remove-icon" *ngIf="data?.freelancer && myObject"
        (click)="deleteProjectTasks(index)"></span>
      <span stopPropagation class="bi bi-plus-circle-fill remove-icon" *ngIf="!data?.freelancer && myObject"
        (click)="addFreelancer(index)"></span>

      <strong>{{ 'project.company' | translate }}</strong>
    </div>

    <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" [ngClass]="{ 'isActive': selectedTasks && myObject}"
      (mouseenter)="selectTasks()" (mouseleave)="removeClick()" (click)="selectTasks()">
      <div class="Text-Text-text pt-2">
        <strong>
          {{ "project.secondPage.tasks" | translate}} {{ "project.secondPage.keywords" | translate
          }}
        </strong>
      </div>

      <div class="Text-Text-text-wrapper">
        <app-chips [chips]="data?.tasks" stopPropagation></app-chips>
      </div>
      <div *ngIf="selectedTasks && myObject">
        <div clickOutside (clickOutside)="removeClick()">
          <span stopPropagation class="bi bi-pencil-fill edit-icon"
            (click)="editJobTasks({job:data, jobTasks: true})"></span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!!data.freelancer" class="remove-icon-border mt-4">

    <div class="card-header px-0 py-1">
      <span stopPropagation class="bi bi-trash3-fill remove-icon" *ngIf="data?.company && myObject"
        (click)="deleteProjectFreelancer(index)"></span>
      <span stopPropagation class="bi bi-plus-circle-fill remove-icon" (click)="addCompany()"
        *ngIf="!data?.company && myObject"></span>
      <strong>{{'project.freelancer' | translate}}</strong>
    </div>
    <div class="card-body px-0 py-1">

      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
        <div [ngClass]="{ 'isActive': selectedSkillsOffer && myObject}" (mouseenter)="selectSkillsOffer()"
          (mouseleave)="removeClick()" (click)="selectSkillsOffer()">

          <div class="Text-Text-text">
            <strong>
              {{ "job.secondGroup.tasks" | translate}}
            </strong>
          </div>

          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="data?.skillsOffer" stopPropagation></app-chips>
          </div>
          <div *ngIf="selectedSkillsOffer && myObject">
            <div clickOutside (clickOutside)="removeClick()">
              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                (click)="editSkillsOffer({job:data, jobSkillsOffer: true})"></span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remove-icon-border">

    <div class="card-body px-0 py-1">

      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" *ngIf="!data.freelancer">
        <div [ngClass]="{ 'isActive': selectedTasks && myObject}" (mouseenter)="selectTasks()"
          (mouseleave)="removeClick()" (click)="selectTasks()">
          <div class="Text-Text-text">
            <strong>
              {{ "job.secondGroup.tasks" | translate}}
            </strong>
          </div>
          <div class="Text-Text-text-wrapper" *ngIf="data?.skillsOffer">
            <app-chips [chips]="data?.skillsOffer" stopPropagation></app-chips>
          </div>
          <div class="Text-Text-text-wrapper" *ngIf="data?.searchFilterSkills?.searchSkillsOffer?.data">
            <app-chips [chips]="data?.searchFilterSkills?.searchSkillsOffer?.data" stopPropagation></app-chips>
          </div>

          <div *ngIf="selectedTasks && myObject">
            <div clickOutside (clickOutside)="removeClick()">
              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                (click)="editTasks({data:data, jobTasks: true})"></span>

            </div>

          </div>
        </div>

      </div>

      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
        <div [ngClass]="{ 'isActive': selectedPCKnowledge && myObject}" (mouseenter)="selectPCKnowledge()"
          (mouseleave)="removeClick()" (click)="selectPCKnowledge()">
          <div class="Text-Text-text">
            <strong>
              {{ "skills.pcKnowledge" | translate}}
            </strong>
          </div>

          <div class="Text-Text-text-wrapper" *ngIf="data?.pcKnowledge">
            <app-chips [chips]="data?.pcKnowledge" stopPropagation></app-chips>
          </div>
          <div class="Text-Text-text-wrapper" *ngIf="data?.searchFilterSkills?.searchPCKnowledge?.data">
            <app-chips [chips]="data?.searchFilterSkills?.searchPCKnowledge?.data" stopPropagation></app-chips>
          </div>
          <div *ngIf="selectedPCKnowledge && myObject">
            <div clickOutside (clickOutside)="removeClick()">
              <span stopPropagation class="bi bi-pencil-fill edit-icon"
                (click)="editPCKnowledge({job:data, secondGroup: true})"></span>

            </div>

          </div>
        </div>


      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
        <div [ngClass]="{ 'isActive': selectedLanguage && myObject}" (mouseenter)="selectLanguage()"
          (mouseleave)="removeClick()" (click)="selectLanguage()">
          <div class="Text-Text-text">
            <strong>
              {{ "skills.language" | translate}}
            </strong>

            <div *ngFor="let language of data?.language ? data?.searchFilterSkills?.searchLanguages?.data : ''; let iLang = index">
              <div class="Field-field-fieldBase">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <div class="progress">

                      <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate * 20 +'%'"
                        style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                        [style.font-size.px]="10">
                        <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                          {{getLanguageName(language.name)?.value}} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedLanguage && myObject">
              <div clickOutside (clickOutside)="removeClick()">
                <span stopPropagation class="bi bi-pencil-fill edit-icon"
                  (click)="editLanguage({job:data, secondGroup: true})"></span>

              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
        <div [ngClass]="{ 'isActive': selectedDriveLicense && myObject}" (mouseenter)="selectDriveLicense()"
          (mouseleave)="removeClick()" (click)="selectDriveLicense()">
          <div class="Text-Text-text">
            <strong>
              {{ "skills.driveLicense" | translate}}
            </strong>


            <div class="Text-Text-text-wrapper" *ngIf="data?.driveLicense">
              <app-chips [chips]="data?.driveLicense" stopPropagation></app-chips>
            </div>
            <div class="Text-Text-text-wrapper" *ngIf="!!data.searchFilterSkills?.searchDriveLicenses?.data">
              <app-chips [chips]="data?.searchFilterSkills?.searchDriveLicenses?.data" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedDriveLicense && myObject">
              <div clickOutside (clickOutside)="removeClick()">
                <span stopPropagation class="bi bi-pencil-fill edit-icon"
                  (click)="editDriveLicense({job:data, secondGroup: true})"></span>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
        <div [ngClass]="{ 'isActive': selectedQualifications && myObject}" (mouseenter)="selectQualifications()"
          (mouseleave)="removeClick()" (click)="selectQualifications()">
          <div class="Text-Text-text">
            <strong>
              {{ "skills.qualifications" | translate}}
            </strong>

            <div class="Text-Text-text-wrapper" *ngIf="!!data.qualifications">
              <app-chips [chips]="data?.qualifications" stopPropagation></app-chips>
            </div>
            <div class="Text-Text-text-wrapper" *ngIf="!!data.searchFilterSkills?.searchQualifications?.data">
              <app-chips [chips]="data.searchFilterSkills.searchQualifications.data" stopPropagation></app-chips>
            </div>
            <div *ngIf="selectedQualifications && myObject">
              <div clickOutside (clickOutside)="removeClick()">
                <span stopPropagation class="bi bi-pencil-fill edit-icon"
                  (click)="editTasks({job:data, secondGroup: true})"></span>

              </div>
            </div>

          </div>
        </div>

      </div>



      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2" *ngIf="projectSearchUser || jobSearchUser">
        <div class="card-header px-0 py-1">
          <strong>{{ 'category.Education' | translate }}</strong>
        </div>
        <div class="Text-Text-text-wrapper">

          <div *ngFor="let educationObj of userSearch?.cv?.data[0]?.education?.subCategories; let iLang = index">
            <div pageContent class="Line-line-container">
              <div class="Line-line-line">
                <div class="Field-field-fieldBase first-template-fieldField">
                  <div class="Text-Text-wrapper">
                    <div class="Text-Text-text" style="display: flex;
                                align-items: center;
                                justify-content: space-around;">
                      {{educationObj?.startDate | date:'MM.yyyy'}}
                      <div class="float-right" *ngIf="!educationObj?.today">
                        {{educationObj?.endDate |
                        date:'MM.yyyy'}}
                      </div>
                      <div class="float-right" *ngIf="educationObj?.today">
                        {{'present' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <b>{{educationObj?.title}}</b>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper">
                    <app-education-name [educationObj]="educationObj" [educationObject]="true">
                    </app-education-name>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div class="Text-Text-text-wrapper" aria-multiline="true"
                    [innerHTML]="educationObj?.description | noSanitize">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 px-0 pt-2" *ngIf="projectSearchUser || jobSearchUser">
        <div class="card-header px-0 py-1">
          <strong>{{ 'category.Career' | translate }}</strong>
        </div>

        <div class="Text-Text-text-wrapper">
          <div *ngFor="let careerObj of userSearch?.cv?.data[0]?.careers?.subCategories; let iLang = index">
            <div class="Line-line-container">
              <div class="Line-line-line">
                <div class="Field-field-fieldBase first-template-fieldField">
                  <div class="Text-Text-wrapper">
                    <div pageContent class="Text-Text-text" style="display: flex;
                                align-items: center;
                                justify-content: space-around;">
                      {{careerObj?.startDate | date:'MM.yyyy'}}
                      <div class="float-right" *ngIf="!careerObj?.today">
                        {{careerObj?.endDate | date:'MM.yyyy'}}
                      </div>
                      <div class="float-right" *ngIf="careerObj?.today">
                        {{'present' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div pageContent class="Text-Text-text-wrapper">
                    <b>{{careerObj?.role}}</b>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div pageContent class="Text-Text-text-wrapper">
                    <app-education-name [careerObj]="careerObj" [careerObject]="true"></app-education-name>
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue">
                <div class="Text-Text-wrapper">
                  <div pageContent class="Text-Text-text-wrapper" aria-multiline="true"
                    [innerHTML]="careerObj?.description | noSanitize">
                  </div>
                </div>
              </div>
              <div class="Field-field-fieldBase first-template-fieldValue" *ngIf="careerObj?.showCompanyUrl">
                <div class="Text-Text-wrapper">
                  <div pageContent class="Text-Text-text-wrapper">
                    <app-education-name [careerObj]="careerObj" [companyURL]="true"></app-education-name>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2" *ngIf="!projectSearchUser">
    <div [ngClass]="{ 'isActive': selectedDescription && myObject}" (mouseenter)="selectDescription()"
      (mouseleave)="removeClick()" (click)="selectDescription()">
      <div class="Text-Text-text">
        <strong>
          {{ "description" | translate}}
        </strong>
      </div>

      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2">
        <div class="Text-Text-text-wrapper" [innerHTML]="data?.description | noSanitize">
        </div>
        <div *ngIf="selectedDescription && myObject">
          <div clickOutside (clickOutside)="removeClick()">
            <span stopPropagation class="bi bi-pencil-fill edit-icon"
              (click)="editDescription({job:data, secondGroup: true})"></span>

          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
<!-- <ng-container *ngIf="isUserSearch">
  <div>
    <div class="card-body px-0">

      <div class="col-lg-12 mt-0-5 mt-lg-0 ms-lg-12 pb-2">
        <div class="Text-Text-text">
          <strong>
            {{ "job.secondGroup.tasks" | translate}}
          </strong>
        </div>
        <div class="Text-Text-text-wrapper">
          <app-chips [chips]="data.cv.data[0]?.skills[0]?.skillsOffer?.data" stopPropagation></app-chips>
        </div>
      </div>

      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data.cv.data[0]?.skills[0]?.pcKnowledge?.data?.length > 0">
        <div class="Text-Text-text">
          <strong>
            {{ "skills.pcKnowledge" | translate}}
          </strong>
        </div>

        <div class="Text-Text-text-wrapper">
          <app-chips [chips]="data.cv.data[0]?.skills[0]?.pcKnowledge.data" stopPropagation></app-chips>
        </div>


      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data.cv.data[0]?.skills[0]?.languages?.data?.length > 0">
        <div class="Text-Text-text">
          <strong>
            {{ "skills.language" | translate}}
          </strong>

          <div *ngFor="let language of data.cv.data[0]?.skills[0]?.languages?.data; let iLang = index">
            <div class="Field-field-fieldBase">
              <div class="Text-Text-wrapper">
                <div class="Text-Text-text-wrapper">
                  <div class="progress">
                    <div id="rate" [style.background]="'#d9d9d9'" [style.width]="language.rate + '20%'"
                      style="display: flex; white-space: pre-wrap;" [style.line-height.px]="13"
                      [style.font-size.px]="10">
                      <div style="display: flex; white-space: pre-wrap; padding-left: .5rem;" id="language">
                        {{getLanguageName(language.name)?.value}} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data.cv.data[0]?.skills[0]?.driveLicenses?.data?.length > 0">
        <div class="Text-Text-text">
          <strong>
            {{ "skills.driveLicense" | translate}}
          </strong>

          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="data.cv.data[0]?.skills[0]?.data?.driveLicenses" stopPropagation></app-chips>
          </div>
        </div>

      </div>
      <div class="col-lg-12 mt-0-5 mt-lg-0 pb-2" *ngIf="data.cv.data[0]?.skills[0]?.qualifications?.data?.length > 0">
        <div class="Text-Text-text">
          <strong>
            {{ "skills.qualifications" | translate}}
          </strong>

          <div class="Text-Text-text-wrapper">
            <app-chips [chips]="data.cv.data[0]?.skills[0]?.data?.qualifications" stopPropagation></app-chips>
          </div>
        </div>
      </div>


    </div>
  </div>

</ng-container> -->
