import {Component, Input, OnInit} from "@angular/core";
import {Education, EducationSubCategory, Model} from "../../shared/interfaces/model";
import {Category} from "../../shared/enums/enums-model";
import {DialogService} from "../../shared/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {EditEducation, EducationDialogComponent} from "../modals/education-dialog/education-dialog.component";
import { PagesService } from "src/app/shared/services/pages.service";

@Component({
  selector: "app-education",
  templateUrl: "./education.component.html",
  styleUrls: ["./education.component.scss"]
})
export class EducationComponent implements OnInit {
  @Input() education: Education;
  @Input() id: number;
  @Input() model: Model;
  public category = Category;
  @Input() educationObj: EducationSubCategory;
  educationObjName = "";

  public selectedIndex: number;
  public selectedCategory: number = null;

  constructor(
              public dialogService: DialogService,
              public dialog: MatDialog,
              private pageService: PagesService) { }

  ngOnInit() {
    if (this.educationObj?.pageID) {
      this.pageService.getPage(this.educationObj?.pageID).subscribe(res => {
        this.educationObjName = res.name;
      })
    } else {
      this.educationObjName = this.educationObj.name
    }
  }
  public setIndex(id) {
    this.selectedIndex = id;
    this.selectedCategory = null;
  }
  public setCategory(index: Category) {
    this.selectedCategory = index;
    this.selectedIndex = undefined;
  }
  public removeClick() {
    this.selectedIndex = undefined;
    this.selectedCategory = undefined;
  }
  deleteCategory(index) {
    this.model.education.subCategories.splice(index, 1);
   //  this.dataService.updatePage(this.model._id, this.model);
  }

  deleteSubCategory(index) {
    this.education.subCategories.splice(index, 1);
    // this.dataService.updatePage(this.model._id, this.model);
  }
  public editEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, {data});
  }
  public addEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, {
      data,
    });
  }
  public autogrow() {
    const textArea = document.getElementById("description");
    textArea.style.overflow = "hidden";
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }
}

