import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PersonalData, Skills} from "../../../../shared/interfaces/model";

@Component({
  selector: "app-personal-data-dialog",
  templateUrl: "./personal-data-dialog.component.html",
  styleUrls: ["./personal-data-dialog.component.scss"]
})
export class PersonalDataDialogComponent implements OnInit {
  public disableButton = [false, false];
  public personalData = [
    {id: "title", description: "data.title", icon: "fa fa-user"},
    {id: "firstName", description: "data.firstName", icon: "fa fa-user"},
    {id: "lastName", description: "data.lastName", icon: "fa fa-user"},
    {id: "address", description: "data.address", icon: "fa fa-address-book-o"},
    {id: "telephone", description: "data.telephone", icon: "fa fa-phone"},
    {id: "email", description: "data.email", icon: "fa fa-envelope-o"},
    {id: "birthday", description: "data.birthday", icon: "fa fa-birthday-cake"},
    {id: "status", description: "data.status"}
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: PersonalData,
              private dialogRef: MatDialogRef<PersonalDataDialogComponent>) {
  }

  ngOnInit(): void {
  //  this.disableButton[1] = this.data.firstName.length > 0;
   // this.disableButton[2] = this.data.lastName.length > 0;
    this.disableButton[3] = this.data.c.length > 0;
    this.disableButton[4] = this.data.telephone.toString().length > 0;
    this.disableButton[5] = this.data.email.length > 0;
    this.disableButton[7] = this.data.status.toString().length > 0;
  }

  public addPersonalData(data) {
    this.dialogRef.close(data);
  }

}
