import {Component, Input, OnInit} from "@angular/core";
import {PersonalDataComponent} from "../personal-data.component";
import {Model} from "../../../shared/interfaces/model";
import {AuthService} from "../../../shared/services/auth.service";
import {DialogService} from "../../../shared/services/dialog.service";
import {MatDialog} from "@angular/material/dialog";
import {MaritualStatus, Status, StatusTitle, Title} from "../../../shared/enums/enums-model";
import { ArraysService } from "src/app/shared/services/arrays-service/title-service";

@Component({
  selector: "app-personal-data-sec",
  templateUrl: "./personal-data-sec.component.html",
  styleUrls: ["./personal-data-sec.component.scss"]
})
export class PersonalDataSecComponent extends PersonalDataComponent implements OnInit {
  @Input() model: Model;
  @Input() index: number;
  public housingTypeOptions = [
    { id: StatusTitle.Mr, description: "data.mr"},
    { id: StatusTitle.Mrs, description: "data.mrs"},
  ];
  public statusObject = [
    {id: 0, name: MaritualStatus.Single, description: "status.single"},
    {id: 1, name: MaritualStatus.Married, description: "status.married"},
    {id: 2, name: MaritualStatus.NotKnow, description: "status.nk"}
  ];

  constructor(public authService: AuthService,
              public dialogService: DialogService,
              public dialog: MatDialog,
              public titleService?: ArraysService) {
    super(authService,
    dialogService,
     dialog);
  }

  ngOnInit(): void {
    this.stateAndCountry = `${this.personalData.state} ${this.personalData.c}`;
    this.postalCodeAndCity = `${this.personalData.postalCode} ${this.personalData.city}`;
    this.getIdOfGender(this.user.gender);
    this.getIdOfTitle(this.user.title);
    this.getIdOfStatus(this.user.pAccount);
    if (this.user.phone[0].internationalNumber) {
      this.phoneNumber = this.user.phone[0].internationalNumber;
      }
  }
  public getIdOfGender(id) {
      this.getGender = this.housingTypeOptions.find(test => test.id === id).description;
    }
    getIdOfTitle(id) {
      this.getTitle = this.titleService.titles.find(test => test.id === id).description;
    }
    getIdOfStatus(id) {
      this.getStatus = this.statusObject.find(t => t.id === id).description;
    }
}
