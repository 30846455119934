import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { environment } from "src/assets/environments/environment";
import { Image } from "../interfaces/model"

@Injectable({
  providedIn: "root"
})
export class ImageService {
   apiUrl = environment.backend;

  constructor(private http: HttpClient) { }


  addImage(file: File | Blob, name): Observable<any> {
    const formData = new FormData();
    formData.append("file", file, name);
    const header = new HttpHeaders();
    const params = new HttpParams();

    const options = {
      params,
      reportProgress: true,
      headers: header
    };
    const req = new HttpRequest("POST", `${this.apiUrl}/images/${file}`, formData, options);
    return this.http.request(req);
  }
  getImageByID(id: string): Observable<any> {
    const url = `${this.apiUrl}/image/${id}`;
    return this.http.get<Image>(url).pipe(
    catchError(this.handleError)
    );
    }
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {

    }
    return throwError(
      'Something bad happened; please try again later.');
  }

}
