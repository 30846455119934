import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MessagesComponent } from "src/app/messages/messages.component";
import { PageDetailsComponent } from "./page-details/page-details.component";
import { PagesSettingsComponent } from "./pages-settings/pages-settings.component";
import { PagesComponent } from "./pages.component";
import { AdsManagerComponent } from "./ads-manager/ads-manager.component";
import { AuthGuard } from "src/app/guards";


const routes: Routes = [
  {
    path: "pages", component: PagesComponent, canActivate: [AuthGuard],

    children: [
      { path: "info", component: PagesComponent },
      { path: "details", component: PagesComponent },
    ]
  },
  { path: ":page/messages", component: MessagesComponent, canActivate: [AuthGuard]  },
  {
    path: ":page", component: PageDetailsComponent, canActivate: [AuthGuard],
    children: [
      { path: "info", component: PageDetailsComponent},
      { path: "details", component: PageDetailsComponent },
      { path: "language", component: PageDetailsComponent },
      { path: "creation", component: PageDetailsComponent },
      { path: "language", component: PageDetailsComponent },
      { path: "about", component: PageDetailsComponent },
      { path: "feed", component: PageDetailsComponent },
      { path: "manage_shop", component: PageDetailsComponent },
      {path: "ads_manager", component: AdsManagerComponent },
      {path: "ads_manager/create", component: AdsManagerComponent },
      {path: "ads_manager/edit", component: AdsManagerComponent },
      { path: "employers", component: PageDetailsComponent},
      {path:"connections", component: PageDetailsComponent, children: [
        {path:"followers_page", component: PageDetailsComponent},
        {path:"employers", component: PageDetailsComponent},

      ]},
      { path: "payment", component: PageDetailsComponent,
        children: [
          { path: "billing", component: PageDetailsComponent},
          { path: "transactions", component: PageDetailsComponent},
          { path: "purchased", component: PageDetailsComponent}
        ]
      },
      { path: "payments_management", component: PageDetailsComponent},
      { path: "inbox", component: PageDetailsComponent },
      { path: "app_store", component: PageDetailsComponent },
      { path: "tools", component: PageDetailsComponent },
      { path: "ourjobs", component: PageDetailsComponent },
      { path: "projects/ourprojects", component: PageDetailsComponent },
      { path: "published/jobs", component: PageDetailsComponent },
      { path: "published/projects", component: PageDetailsComponent },
      { path: "published/projects/:id", component: PageDetailsComponent },
      { path: "published/jobs/:id", component: PageDetailsComponent },
      { path: "notifications", component: PageDetailsComponent },
      { path: "insights", component: PageDetailsComponent },
      { path: "publishing_tools", component: PageDetailsComponent },
      { path: "ad_center", component: PageDetailsComponent },
      { path: "page_quality", component: PageDetailsComponent },
      {path: "projects/applied_projects", component: PageDetailsComponent},
      {path: "projects/create_project", component: PageDetailsComponent},
      {path: "create_job", component: PageDetailsComponent},
      {path: "projects/saved", component: PageDetailsComponent},
      {path: "projects/ignored", component: PageDetailsComponent},
      {path: "settings", component: PageDetailsComponent,
      children: [
          { path: "locations", component: PageDetailsComponent },
          { path: "page_info", component: PagesSettingsComponent },
          { path: "tabs", component: PagesSettingsComponent },
          { path: "notifications", component: PagesSettingsComponent },
          { path: "page_roles", component: PagesSettingsComponent },
          { path: "people_and_tags", component: PagesSettingsComponent },
          { path: "preferred_audience", component: PagesSettingsComponent },
          { path: "payments", component: PagesSettingsComponent },
          { path: "page_management_history", component: PagesSettingsComponent },
          { path: "allactivity", component: PagesSettingsComponent },
          {path: "search_locations",  component: PageDetailsComponent},
          {path: "language",  component: PageDetailsComponent},
          {path: "premium",  component: PageDetailsComponent},
          {path: "verified",  component: PageDetailsComponent},
          {path: "about", component: PageDetailsComponent},
          {path: "close_account", component: PageDetailsComponent},
      ]
    }

    ]
  },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PagesRoutingModule{}
