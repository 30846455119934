import { Injectable } from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    const isLogedin = this.authService.isLoggedIn;
    if (isLogedin) {
      req = req.clone({
        setHeaders: {
          Authorization: authToken
        }
      });
    }
    return next.handle(req);
  }
}
