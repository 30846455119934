<div class="tab-pane fade" id="nav-setting-tab-2">
  <!-- Notification START -->
  <div class="card">
    <!-- Card header START -->
    <div class="card-header border-0 pb-0">
      <h5 class="card-title">
        {{ "category.Career" | translate }}
      </h5>
    </div>
    <!-- Card header START -->
    <!-- Card body START -->
    <div class="card-body">
      <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
        <!-- Workplace on START -->
        <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
            addWork({
              edit: false,
              model: userData.cv.data[0],
              career: false,
              user: userData.user,
              indexOfLang: 0
            })
          ">
          <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddCareer" | translate }}</a>
        <!-- Workplace on END -->
      </div>

      <!-- Form settings START -->
      <form class="row g-3">
        <!-- First name -->
        <ng-container *ngFor="
            let careerObj of userData.cv.data[0].careers.subCategories;
            let i = index
          ">

          <div class="col-sm-12">
            <div class="d-flex align-items-center py-2">
              <!-- Date -->
              <div class="mb-0 d-flex">
                <div class="avatar avatar-xs me-2">
                  <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                      class="avatar-img rounded-circle" /></a>
                </div>
                <div>
                  <strong>{{ careerObj?.role }}
                    {{ "at" | translate }}
                    <app-education-name [careerObj]="careerObj" [careerObject]="true">
                    </app-education-name>
                  </strong>
                  <br />
                  <div class="d-flex align-items-center">
                    <p class="mb-0 small me-2">
                      {{
                      careerObj.startDate | date : "MM.yyyy"
                      }}
                    </p>
                    -
                    <p class="mb-0 small me-2 ms-2" *ngIf="!careerObj.today">
                      {{
                      careerObj.endDate | date : "MM.yyyy"
                      }}
                    </p>
                    <p class="mb-0 small ms-2" *ngIf="careerObj.today">
                      {{ "present" | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center ms-auto">

                <app-privacy-button *ngIf="!!checkLoginID" [privacy]="careerObj.p"
                  (saveObject)="updateCareerObj($event, i)"></app-privacy-button>


                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                  <!-- Card share action menu -->
                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                    <li>
                      <a class="dropdown-item" (click)="
                        editCareer({
                          edit: true,
                          career: careerObj,
                          model: userData.cv.data[0],
                          index: i,
                          indexOfLang: 0
                        })
                      ">
                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                    </li>
                    <li>
                      <a *ngIf="
                        userData.cv.data[0].careers.subCategories
                          .length > 1
                      " class="dropdown-item" (click)="deleteCareerSubCategory(i)">
                        <i class="bi bi-trash fa-fw pe-2"></i>Delete</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Birthday END -->
          </div>
        </ng-container>
      </form>
      <!-- Settings END -->
    </div>
    <div class="card-header border-0 pb-0">
      <h5 class="card-title">
        {{ "category.Education" | translate }}
      </h5>
    </div>
    <!-- Card header START -->
    <!-- Card body START -->
    <div class="card-body">
      <div class="col-sm-12 position-relative" *ngIf="!!checkLoginID">
        <!-- Workplace on START -->
        <a class="btn btn-dashed rounded w-100 text-align-start" (click)="
            addEducation({
              edit: false,
              model: userData.cv.data[0],
              languageIndex: 0
            })
          ">
          <i class="bi bi-plus-circle-dotted me-1"></i>{{ "category.AddEducation" | translate }}</a>
        <!-- Workplace on END -->
      </div>
      <!-- Form settings START -->
      <form class="row g-3">
        <!-- First name -->
        <ng-container *ngFor="
            let educationObj of userData.cv.data[0].education
              .subCategories;
            let i = index
          ">
          <div class="col-sm-12">
            <div class="d-flex align-items-center py-2">
              <!-- Date -->
              <div class="mb-0 d-flex">
                <div class="avatar avatar-xs me-2">
                  <a href="#"><img src="assets/images/avatar/03.jpg" alt=""
                      class="avatar-img rounded-circle" /></a>
                </div>
                <div>
                  <strong>{{ educationObj?.title }}
                    {{ "at" | translate }}
                    <app-education-name [educationObj]="educationObj" [educationObject]="true">
                    </app-education-name>
                  </strong>
                  <br />
                  <div class="d-flex align-items-center">
                    <p class="mb-0 small me-2">
                      {{
                      educationObj.startDate
                      | date : "MM.yyyy"
                      }}
                    </p>
                    -
                    <p class="mb-0 small me-2 ms-2" *ngIf="!educationObj.today">
                      {{
                      educationObj.endDate
                      | date : "MM.yyyy"
                      }}
                    </p>
                    <p class="mb-0 small ms-2" *ngIf="educationObj.today">
                      {{ "present" | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center ms-auto">


                <app-privacy-button *ngIf="!!checkLoginID" [privacy]="educationObj.p"
                  (saveObject)="updateEducationObj($event, i)"></app-privacy-button>


                <div class="dropdown ms-auto" *ngIf="!!checkLoginID">
                  <!-- Card share action menu -->
                  <a class="nav nav-link text-secondary mb-0" href="#" id="aboutAction2"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots"></i>
                  </a>
                  <!-- Card share action dropdown menu -->
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
                    <li>
                      <a class="dropdown-item" (click)="
                        editEducation({
                          edit: true,
                          education: educationObj,
                          model: userData.cv.data[0],
                          languageIndex: 0
                        })
                      ">
                        <i class="bi bi-pencil-square fa-fw pe-2"></i>{{ "edit" | translate }}</a>
                    </li>
                    <li>
                      <a class="dropdown-item" *ngIf="
                        userData.cv.data[0].education.subCategories
                          .length > 1
                      " (click)="deleteEducationSubCategory(i)">
                        <i class="bi bi-trash fa-fw pe-2"></i>{{ "delete" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Birthday END -->
          </div>
        </ng-container>
      </form>
      <!-- Settings END -->
    </div>
    <!-- Card body END -->
    <!-- Button save -->
  </div>
  <!-- Notification END -->
</div>
