import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/shared/interfaces/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-saved-jobs',
  templateUrl: './saved-jobs.component.html',
  styleUrls: ['./saved-jobs.component.scss']
})
export class SavedJobsComponent implements OnInit, OnChanges {
  public user: User;
  public savedJobs = [];
  public subscription: Subscription;

  constructor( public userService: UserService, jobService: JobsService,  public router: Router, private authService: AuthService) { }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.savedJobs = res.savedJobs
    })

  }
  onChangeSavedJobs() {
  console.log(this.user);
  }
  test(i) {
    this.user.savedJobs.splice(i, 1);
    this.userService.updateUser(this.user._id, this.user);
  }

  createJob() {
    this.router.navigateByUrl(this.user?.userUrl+ "/jobs/create");
}
goToMyJobs() {
    this.router.navigateByUrl(this.user?.userUrl + "/jobs");

}


}
