
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { shareReplay } from "rxjs/operators";
import { Image, Page, Roles } from "src/app/shared/interfaces/model";
import { AuthService } from "src/app/shared/services/auth.service";
import { BranchesService } from "src/app/shared/services/branches.service";
import { ImageService } from "src/app/shared/services/image.service";
import { LanguagesService } from "src/app/shared/services/languages.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { UserService } from "src/app/shared/services/user.service";
import { PageHelperService } from "src/app/shared/utils/page-helper.service";
@Component({
  selector: "app-sidebar-page-details",
  templateUrl: "./sidebar-page-details.component.html",
  styleUrls: ["./sidebar-page-details.component.scss"]
})
export class SidebarPageDetailsComponent implements OnInit, OnChanges {
  public page: Page;
  @Input() pageData: Page
  @Input() isPageData:boolean = false;
  public name;
  public image;
  public hasPhoto = false;;

  public outsiderPageId;
  public pages$: Observable<Page[]>;
  public pages: Observable<Page[]>;
  public pageRoles = new Roles();

  constructor(public router: Router,
    public branchesService: BranchesService,
    public languageService: LanguagesService,
    public http: HttpClient,
    public authService: AuthService,
      public pageService: PagesService,
      public imageService: ImageService,
      public shareData: SenderService,
      public recevier: SenderService,
      private pageHelper: PageHelperService,
      public cd: ChangeDetectorRef,
      public userService?: UserService,
    ) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            event.url = event.url.replace("/", "");
            this.outsiderPageId = event.url;
          }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    this.pageService.getPages();

    if (this.isPageData) {
      this.pageHelper.getPages().pipe(shareReplay(1)).subscribe(res => {
        this.pages = res.pages;
      })
      this.pageService.getMyPages();
      this.pages$ = this.pageService.pageModel();
    }

    this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
      this.page = page.page;
      console.log(this.page, "page")
      this.pageRoles = page.roles;

    });
    this.pages$ = this.pageService.pageModel();
    this.recevier.dataUpdate$.subscribe((success:Page) => {
      if (success) {
        if(Object.keys(success).length == 0) {
          this.pageService.getPage(this.outsiderPageId).subscribe(t => {
            this.page = t;
            this.cd.detectChanges();
            this.imageService.getImageByID(this.page?.profileImage).subscribe((res: Image) => {
              if(res.imageUrl) {
                this.image = res.imageUrl;
                this.hasPhoto = true;
                this.cd.detectChanges();
              }
            });
          });
          this.pageService.getPageUser(this.outsiderPageId).subscribe(t => {
            this.page = t[0];
            this.cd.detectChanges();
            this.imageService.getImageByID(this.page?.profileImage).subscribe((res: Image) => {
              if(res.imageUrl) {
                this.image = res.imageUrl;
                this.hasPhoto = true;
                this.cd.detectChanges();
              }
            });
          });
        } else {
          this.page = success;
          }
        this.page = success;
      }

      if(this.page.profileImage) {
        this.imageService.getImageByID(this.page?.profileImage).subscribe((res: Image) => {
          if(res.imageUrl) {
            this.image = res.imageUrl;
            this.hasPhoto = true;
            this.cd.detectChanges();
          }
        });
      }
    });
  }
  sendPageUrl() {
    this.recevier.paymentDataVisibility(this.page);
    this.router.navigate([this.page?.pageUrl + '/payments', { title: 'isPage', id: this.page._id } ]);
    localStorage.setItem("payment", "isPage");
  }

  public get isUserJobs() {
    return this.pageRoles?.Jobs?.find(res => res.userID === this.authService.userID);
  }

  public get isUserProjects() {
    return this.pageRoles.Projects?.find(res => res.userID === this.authService.userID);
  }
  public get isOwnerOrAdmin() {
    return (this.pageRoles.Owner?.find(res => res.userID === this.authService.userID) || this.pageRoles.Admin?.find(res => res.userID === this.authService.userID) )
  }

  public get isPayment() {
    return this.pageRoles?.Payments?.find(res => res.userID === this.authService.userID);
  }

  public get isMessaging() {
    return this.pageRoles?.Messages?.find(res => res.userID === this.authService.userID);
  }

  public get isNotifications() {
    return this.pageRoles?.Notifications?.find(res => res.userID === this.authService.userID);

  }

  sendData() {
    this.pages$ = this.userService.pageList;
    this.pages$.subscribe(t => {
      console.log(t, "ejeje")
    })

    this.recevier.pageData$(this.page);
  }

  showSidebar() {
   let item =  document.getElementById("hoverSidebar");
   let item1 = document.getElementById("isSidebar");
   item.setAttribute('style', 'display:flex !important; width: 280px;');
   item1.setAttribute('style', "display: flex !important")

  }

  removeClick() {

    console.log("testt3")
    let item =  document.getElementById("hoverSidebar");
    let item1 = document.getElementById("isSidebar");
    item.setAttribute('style', 'display:none !important');
    item1.setAttribute('style', "display: none !important")

  }


  sidebarClick(event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    const elementToShow = document.
    querySelector('.nav-sidenav');
    elementToShow.classList.toggle('show');

  }

    outsideClick(hasClickedOutside) {

      const elementToShow = document.
      querySelector('.nav-sidenav');
      if (elementToShow.classList.contains('show')) {
        elementToShow.classList.toggle('show');
      }

    }

  public get hasUserAccesOnSidebar() {
    return this.isUserJobs &&
    this.isUserProjects &&
    this.isOwnerOrAdmin &&
    this.isPayment &&
    this.isMessaging &&
    this.isNotifications
  }


}
