<div class="body">
  <div class="container">
    <div class="create-post">
        <div class="row">
            <div class="col-md-8">
                <div class="row">
            		<div class="col-md-7 col-sm-7">
                      <div class="form-group">
                        <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" class="img-thumbnail profile-photo-md" />
                        <textarea name="texts" id="exampleTextarea" cols="30" rows="1" class="form-control" placeholder="Write what you wish"></textarea>
                      </div>
                    </div>
                    <div class="col-md-5 col-sm-5">
                      <div class="tools">
                        <ul class="publishing-tools list-inline">
                          <li><a href="#"><i class="bi bi-pencil-square-o"></i></a></li>
                          <li><a href="#"><i class="fa fa-file-image-o"></i></a></li>
                          <li><a href="#"><i class="fa fa-video-camera"></i></a></li>
                          <li><a href="#"><i class="fa fa-map"></i></a></li>
                        </ul>
                        <button class="btn btn-primary pull-right">Publish</button>
                      </div>
                    </div>
            	</div>
        	</div>
    	</div>
    </div>

    <div class="friend-list">
        <div class="row">
            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/6495ED" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/008B8B" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar6.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/9932CC" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/228B22" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/20B2AA" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-6">
              <div class="friend-card">
                  <img src="https://via.placeholder.com/400x100/FF4500" alt="profile-cover" class="img-responsive cover">
                  <div class="card-info">
                  <img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="user" class="profile-photo-lg">
                  <div class="friend-info">
                    <a href="#" class="pull-right text-green">My Friend</a>
                  	<h5><a href="timeline.html" class="profile-link">Sophia Lee</a></h5>
                  	<p>Student at Harvard</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
</div>
