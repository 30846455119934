import {Injectable} from "@angular/core";
import {Branches} from "../enums/enums-model";

@Injectable({
  providedIn: "root"
})
export class BranchesService {
  public branches = [];
  constructor() { }

  public getBranches() {
    this.branches = [
      {checked: false, id: Branches.Automotive_Industry, description: "Branchen.Automotive_Industry" },
      {checked: false, id: Branches.Construction_RealEstate, description: "Branchen.Construction_RealEstate"},
      {checked: false, id: Branches.Education, description: "Branchen.Education"},
      {checked: false, id: Branches.Service, description: "Branchen.Service"},
      {checked: false, id: Branches.Energy_Industry, description: "Branchen.Energy_Industry"},
      {checked: false, id: Branches.Finance_Marketing_Insurance, description: "Branchen.Finance_Marketing_Insurance"},
      {checked: false, id: Branches.Trade, description: "Branchen.Trade"},
      {checked: false, id: Branches.Hotels_Restaurants, description: "Branchen.Hotels_Restaurants"},
      {checked: false, id: Branches.Industry, description: "Branchen.Industry"},
      {checked: false, id: Branches.IT_Telecommunications, description: "Branchen.IT_Telecommunications"},
      {checked: false, id: Branches.Agriculture, description: "Branchen.Agriculture"},
      {checked: false, id: Branches.Media_Internet, description: "Branchen.Media_Internet"},
      {checked: false, id: Branches.Medicine_Health, description: "Branchen.Medicine_Health"},
      {checked: false, id: Branches.Fashion_Beauty, description: "Branchen.Fashion_Beauty"},
      {checked: false, id: Branches.Food_Beverage, description: "Branchen.Food_Beverage"},
      {checked: false, id: Branches.Public_Service, description: "Branchen.Public_Service"},
      {checked: false, id: Branches.Organisation, description: "Branchen.Organisation"},
      {checked: false, id: Branches.Legal, description: "Branchen.Legal"},
      {checked: false, id: Branches.Other, description: "Branchen.Other"},
      {checked: false, id: Branches.Sports_Rekreation, description: "Branchen.Sports_Rekreation"},
      {checked: false, id: Branches.Transport_Traffic_Logistik, description: "Branchen.Transport_Traffic_Logistik"},
      {checked: false, id: Branches.Science_Research, description: "Branchen.Science_Research"},
    ];
  }
}
