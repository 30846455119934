import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filter"
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchValue): any {

    if (!searchValue) return value;
    // tslint:disable-next-line: max-line-length
    return value.filter((v) => v.description.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)

  }

}
