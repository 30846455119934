<aside class="pt-4 aside">
  <section class="sc-smart">
    <div class="widget-item p-0 px-3">
      <h2>{{'settings.name' | translate}}</h2>
      <div>
        <button type="button" class="button-f button-sc btn-light" routerLink="settings/account" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-gear width-40"></i>
            <span class="span-padding-left10">{{'settings.general' | translate}}</span>
                      </div>
        </button>

        <button type="button" class="button-f button-sc" >
          <div >
           <i class="bi bi-shield width-40"></i>
            <span class="span-padding-left10">{{'settings.securityAndLogin' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn btn-light button-f button-sc" routerLink="settings/information" [routerLinkActive]="['active']" >
          <div >
           <i class="bi bi-columns-gap width-40"></i>
            <span class="span-padding-left10">{{'settings.information' | translate}}</span>
                      </div>
        </button>
        <hr>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/privacy" [routerLinkActive]="['active']" >
          <div >
           <i class="bi bi-lock width-40"></i>
            <span class="span-padding-left10">{{'settings.privacy' | translate}} </span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/facerec" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-person-bounding-box width-40"></i>
            <span class="span-padding-left10">{{'settings.recognition' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/profile" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-person-lines-fill width-40"></i>
            <span class="span-padding-left10">{{'settings.profileAndTag' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light  button-f button-sc" routerLink="settings/followers" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-globe2 width-40"></i>
            <span class="span-padding-left10">{{'settings.followers' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/blocking" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-person-x width-40"></i>
            <span class="span-padding-left10">{{'settings.blocking' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/location" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-geo-alt width-40"></i>
            <span class="span-padding-left10">{{'settings.location' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/languages" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-type width-40"></i>
            <span class="span-padding-left10">{{'settings.language' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="btn-light button-f button-sc" routerLink="settings/stories" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-book-half width-40"></i>
            <span class="span-padding-left10">{{'settings.story' | translate}}</span>
                      </div>
        </button>
        <hr>
        <button type="button" class="button-f button-sc" routerLink="settings/notification" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-bell width-40"></i>
            <span class="span-padding-left10">{{'settings.notification' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/mobile" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-phone width-40"></i>
            <span class="span-padding-left10">{{'settings.mobile' | translate}}</span>
                      </div>
        </button>
        <hr>
        <button type="button" class="button-f button-sc" routerLink="settings/apps" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-intersect width-40"></i>
            <span class="span-padding-left10">{{'settings.appsAndWeb' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/games" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-controller width-40"></i>
            <span class="span-padding-left10">{{'settings.games' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/business_integration" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-window width-40"></i>
            <span class="span-padding-left10">{{'settings.businessIntegration' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/ads" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-credit-card-2-front width-40"></i>
            <span class="span-padding-left10">{{'settings.ads' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/ads_payment" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-credit-card width-40"></i>
            <span class="span-padding-left10">{{'settings.adsPayment' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="settings/support_inbox" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-envelope width-40"></i>
            <span class="span-padding-left10">{{'settings.supportInbox' | translate}}</span>
                      </div>
        </button>
        <button type="button" class="button-f button-sc" routerLink="/videos" [routerLinkActive]="['active']">
          <div >
           <i class="bi bi-camera-reels width-40"></i>
            <span class="span-padding-left10">{{'settings.videos' | translate}}</span>
                      </div>
        </button>


      </div>

    </div>

  </section>

</aside>
