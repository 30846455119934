import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {CategoryDialogComponent} from "../../components/modals/category-dialog/category-dialog.component";
import {SubCategoryDialogComponent} from "../../components/modals/sub-category-dialog/sub-category-dialog.component";


@Injectable()

export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  public openDialog() {
    return this.dialog.open(CategoryDialogComponent).afterClosed();
  }

  public openSubDialog() {
    return this.dialog.open(SubCategoryDialogComponent).afterClosed();
  }
}
