export enum Role {
  Owner = 0,
  Admin,
  Editor,
  Moderator,
  Advertiser,
  Analyst,
  User,
  Jobs,
  Projects,
  Messages,
  Notifications,
  Payments,
  Marketing
}
