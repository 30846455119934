import { MessageList } from './../shared/interfaces/messageList.model';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { write } from 'fs';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Page, Project } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { PagesService } from '../shared/services/pages.service';
import { ProjectService } from '../shared/services/project.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';
import { UserJobDialog, UserJobDialogComponent } from '../user-job-dialog/user-job-dialog.component';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input() name: string;
  @Input() isPage: boolean = false;
  public project: Project;
  public projectSelected = "";
  public selectedProject;
  public user: User;
  public page: Page;
  public projectPage;
  public jobManage;
  cancelledUser: User;
  cancelledPage: Page;
  userPage: User;
  isEditing: boolean;
  createMode: boolean;
  authUser: User;
  public appliedPage: any;
  writeUser: User;
  isOpen = false;
  public isProject: boolean = false;
  writeMessage;

  cancelProject;
  cancelMessage;
  appliedPageRoles: any;


  isSidebarTrue;

  commentControl: UntypedFormControl;
  pageIDEqual;
  public pages$: Observable<Page[]>;


  public config = {
    toolbar: false
  };
  editorConfig = {
    padding: "0",
  }
  checkIDOfUserPage;
  pageMessages = [];
  pageID;
  messageList: MessageList;
  public loggedUser: Observable<any>;
  public currentUser: User;

  selectedPage: Page;
  constructor(public receiver: SenderService,
    private projectService: ProjectService,
    private pageService: PagesService,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.commentControl = new UntypedFormControl('');
    const pageID = localStorage.getItem("pageID");
    this.pageService.getMessagePageList(pageID).subscribe((res: MessageList) => {
    this.pageID = res[0].xPageID;
    this.messageList = res[0];
     this.pageMessages = res[0].messages;
    })

    this.loggedUser = this.userService.userList;
    this.loggedUser.subscribe(res => {
      this.currentUser = res.userUser;
      const page = this.currentUser.pages.find(t => t.pageId === this.pageID);
      if (page.open[0].jobID) {
        this.selectProject(page.open[0].jobID)
      } else {
        this.selectProject(page.open[0].projectID)
      }
    })




  }

  setTrueSidebar() {
    this.isSidebarTrue = true;
  }
  setFalseSidebar() {
    this.isSidebarTrue = false;
  }

  selectProject(event) {
    this.projectSelected = event;
    const projectID = event;

  this.currentUser.pages.find(t => t.pageId === this.pageID).open = [];

  this.currentUser.pages.find(t => t.pageId === this.pageID).open.unshift({projectID: event.projectID});


    this.projectService.getProject(event).subscribe((t: Project) => {
      this.project = t;

      this.isProject = true;

      let pageId = this.project.manage_.find(t => t.pageID === this.pageID)?.pageID;
      this.checkIDOfUserPage = this.project.pageID;

      if (localStorage.getItem("pageID")) {
        this.pageService.getPage(localStorage.getItem("pageID")).subscribe(page => {
          this.selectedPage = page;
        });
      }

      if (this.project.pageID) {
        this.pageService.getPage(this.project.pageID).subscribe(t => {
          this.appliedPage = t.page;
          this.appliedPageRoles = t.roles
        })
      } else {
        this.userService.getUserContactData(this.project.userID).subscribe(res => {
          this.userPage = res;
        })
      }
   /*   const findId =   this.project.manage_.find(t => t.pageID !== this.project.pageID).seen.find(t => t.userID === this.authService.userID)?.userID === this.authService.userID;

      if (findId) {
        return
      } else {
        this.project.manage_.find(t => t.pageID !== this.project.pageID).seen.unshift({
          userID: this.authService.userID,
          pageID: this.pageID,
          timestamp: new Date()
        })
        this.projectService.updateProject(this.project._id, this.project)

      } */



      if (pageId === this.pageID) {
        this.pageIDEqual = true;
        let id = this.project.manage_.find(t => t.pageID === this.pageID).userID;
        this.userService.getUserContactData(id).subscribe(res => {
          this.user = res;
        })
      }
      this.checkIDOfUserPage = this.project.manage_.find(t => t.message.pageID === this.project.pageID)?.message?.pageID;

      console.log(this.project.manage_, "this.project.manage_.filter(t => t.pageID === this.pageID)[0]")
      this.jobManage = this.project.manage_.filter(t => t.pageID === this.pageID)[0];


      let cancelId = this.project.manage_.find(t => t.cancel.userID === this.authService.userID)?.cancel?.userID;
      let cancelIdPage = this.project.manage_.find(t => t.cancel.pageID === this.appliedPage?._id)?.cancel?.pageID;

      let cancelIDOutPage = this.project.manage_.find(t => t.cancel.pageID === this.project.pageID)?.cancel?.pageID;




      if (!!cancelIDOutPage) {
        this.pageService.getPage(cancelIDOutPage).subscribe(tt => {

          this.cancelledPage = tt;
        })
      } else {

        if (!!cancelIdPage) {
          this.pageService.getPage(cancelIdPage).subscribe(tt => {

            this.cancelledPage = tt;
          })
        } else if (cancelIdPage && this.isMessaging) {
          this.userService.getUserContactData(cancelId).subscribe(res => {
            console.log(res, "res")
            this.cancelledUser = res;
          })
        }

      }



      this.userService.getUserContactData(this.authService.userID).subscribe(t => {
        this.authUser = t;
      })
    });
    this.messageList.open = [];

   this.messageList.open.unshift({projectID: this.project?._id});
  this.pageService.updateMessagePageList(this.messageList._id, this.messageList);


    this.selectedProject = true;
  }
  setCommentEditMode(mode: boolean) {
    this.createMode = mode;
    this.isEditing = mode;
  }

  addComment() {
    const now = new Date();


    this.jobManage.comments.unshift({
      createdAt: now.toISOString(),
      updatedAt: now.toISOString(),
      body: this.writeMessage,
      userID: this.authService.userID,
      pageID: this.appliedPage._id
    });
    this.createMode = false;
    this.isEditing = false;
    this.writeMessage = "";
    const findIndex = this.appliedPage?.messages?.findIndex(t => t?.projectID === this.project._id);

   if (!!findIndex) {
      this.arraymove(this.page.messages, findIndex, 0);
      this.pageService.updatePage(this.page._id, this.page);
    }

    const pageIndex = this.project.manage_.findIndex(t => t.pageID === this.pageID);
      this.project.manage_[pageIndex].seen = [] as any;

      this.project.manage_.find(t => t.pageID === this.pageID).seen.unshift({
        userID: this.authService.userID,
        pageID: this.pageID,
        timestamp: new Date()
      })


    this.projectService.updateProject(this.project._id, this.project);
    this.commentControl.reset()
  }


  openProject(data: UserJobDialog) {
    this.dialog.open(UserJobDialogComponent, {
      data,
    });
  }
  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }
  public get isOwnerOrAdmin() {
    return (this.page?.roles?.Owner?.find(res => res.userID === this.authService.userID) || this.page?.roles?.Admin?.find(res => res.userID === this.authService.userID))
  }
  public get isPayment() {
    return this.page?.roles?.Payments?.find(res => res.userID === this.authService.userID);
  }

  public get isMessaging() {
    return this.page?.roles?.Messages?.find(res => res.userID === this.authService.userID);
  }

  public get isNotifications() {
    return this.page?.roles?.Notifications?.find(res => res.userID === this.authService.userID);
  }
  public get isUserProjects() {
    return this.page?.roles?.Projects?.find(res => res.userID === this.authService.userID);
  }
  public get isUserJobs() {
    return this.page?.roles?.Jobs?.find(res => res.userID === this.authService.userID);
  }

  goToPage() {
    localStorage.setItem("pageID", this.appliedPage._id)
    this.router.navigateByUrl("/" + this.appliedPage.pageUrl)

  }

  viewProfile() {
    localStorage.setItem("userProfileID", this.userPage._id)
    this.router.navigateByUrl("/" + this.userPage.userUrl + "/start")
  }

  deleteUserChat() {
    const index = this.page.messages.findIndex(t => t.projectId === this.project._id);
    this.page.messages.splice(index, 1);
    this.projectSelected = "";
    this.pageService.updatePage(this.page._id, this.page);
  }
  deletePageChat() {

    const index = this.page.messages.findIndex(t => t.projectId === this.project._id);
    this.page.messages.splice(index, 1);
    this.projectSelected = ""

    this.pageService.updatePage(this.page._id, this.page);

  }

  getNameOfCommentID(id) {
    this.userService.getUserContactData(id).pipe(take(1)).subscribe(t => {
      this.writeUser = t;
    })
  }




  cancel() {
    this.cancelProject = true;
  }

  cancelProjectButton() {
    const dataForCancel = {
      status: 2,
      timestamp: new Date(),
      description: this.cancelMessage,
      userId: this.authService.userID,
      pageId: this.page._id
    }

    this.jobManage.cancel = dataForCancel;
    this.projectService.updateProject(this.project._id, this.project);
    this.cancelProject = false;
    let index = this.page.appliedProjects.findIndex(t => t.projectId === this.project._id);
    this.page.appliedProjects.splice(index, 1);
    this.pageService.updatePage(this.page._id, this.page);
  }

  apply() {
    this.jobManage.cancel.status = 1;
    this.projectService.updateProject(this.project._id, this.project);
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }







  public get hasUserAccesOnSidebar() {
    return this.isUserJobs &&
      this.isUserProjects &&
      this.isOwnerOrAdmin &&
      this.isPayment &&
      this.isMessaging &&
      this.isNotifications
  }
}
