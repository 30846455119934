<div class="card">
  <!-- Card header START -->
  <div class="card-header border-0 pb-0">
    <h5 class="card-title">Language {{languageVersion}}</h5>
  </div>
  <!-- Card header START -->
  <!-- Card body START -->
  <div class="card-body">
    <div class="d-flex col-lg-12 align-items-center row">
      <mat-form-field class="example-full-width col-lg-4" appearance="fill">
        <mat-label>Select Language</mat-label>
        <input type="text" placeholder="Pick one" aria-label="Language" matInput
          [ngModel]="selectedValueLanguage?.description" [formControl]="myControl" [matAutocomplete]="auto">


        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
          (optionSelected)="data.language.primary">

          <mat-option *ngFor="let option of languageService.primaryLanguages"
            (onSelectionChange)="changeLanguage(option, $event)" [value]="option.description">
            {{option.description}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="!!isNotTranslated" class="col-lg-8 d-flex justify-content-between">
        <span class="">
          has not been translated yet <br>
          Let it Translate for
        </span>
        <app-button-coins [updatePrice]="updatePrice"></app-button-coins>


      </div>
      <div *ngIf="!!isNotSameVersion" class="col-lg-8 d-flex justify-content-between">
        <span class="">
          update available <br>
          Let it Translate for
        </span>

        <button class="btn btn-primary" (click)="updateBuy()"> {{updatePrice}} Buy</button>
      </div>
    </div>
    <br>
    <hr>
    <span *ngIf="!!isNotTranslated || !!isNotSameVersion"> {{'languageText' | translate}}
    </span>
    <br>
    <mat-form-field class="example-full-width col-lg-4" appearance="fill"
      *ngIf="!!isNotTranslated || !!isNotSameVersion">
      <mat-label>Select Language</mat-label>
      <input type="text" placeholder="Pick one" aria-label="Language" matInput
        [formControl]="myControlSecond" [ngModel]="selectedSecondLanguage?.description"
        [matAutocomplete]="auto">


      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
        (optionSelected)="data?.language?.secondary">

        <mat-option *ngFor="let option of secondLanguageArray"
          (onSelectionChange)="changeLanguageSecond(option, $event)" [value]="option.description">
          {{option.description}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <br>
    <!-- Delete START -->
    <mat-dialog-actions>
      <button type="button" class="btn btn-success" mat-button (click)="saveLanguage()">{{'save' |
        translate}}
      </button>
    </mat-dialog-actions> <!-- Delete END -->
  </div>
  <!-- Card body END -->
</div>
