import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Page } from '../shared/interfaces/model';
import { User } from '../shared/interfaces/user.model';
import { AuthService } from '../shared/services/auth.service';
import { SenderService } from '../shared/services/sender.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-ignored-projects',
  templateUrl: './ignored-projects.component.html',
  styleUrls: ['./ignored-projects.component.scss']
})
export class IgnoredProjectsComponent implements OnInit {
  public user: User;
  public ignoredProjects = [];
  public subscription: Subscription;
  @Input() isUser;

  public page: Page;

  constructor(public userService: UserService,
        public authService: AuthService,
       public router: Router,   public receiverService: SenderService,) { }

  ngOnInit(): void {
    this.userService.getIgnoredProjects();
   this.userService.getUserContactData(this.authService.userID).pipe(take(1)).subscribe(t => {
    this.user = t;
    console.log(this.user, "user")

   })
    this.receiverService.dataUpdate$.subscribe((res: Page) => {
      this.page = res;

    });

  }



  removeIgnoredFromPageProject(i) {
    this.page.ignoredProjects.splice(i, 1);

  }

  createProject() {
    this.router.navigateByUrl(this.user.userUrl + "/projects/create");
}
goToMyProjects() {
    this.router.navigateByUrl(this.user?.userUrl + "/projects");

}

navigateToSaved() {

  this.router.navigateByUrl(this.user?.userUrl + "/projects/saved");

}

}
