import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EditDetailsData, EditJobsDialogComponent } from 'src/app/components/jobs/edit-jobs-dialog/edit-jobs-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/modals/confirmation-dialog/confirmation-dialog.component';
import { ShareDialogComponent } from 'src/app/components/modals/share-dialog/share-dialog.component';
import { PrivacyDialogComponent } from 'src/app/components/privacy-dialog/privacy-dialog.component';
import { Duration, EmploymentType, Remote, Incomes, Links, Status, StatusPrivacy } from 'src/app/shared/enums/enums-model';
import { JIssue, IssueStatus, IssueType } from 'src/app/shared/interfaces/jira.model';
import { Job, Page, PageProjects, Project, UserJobs, UserProjects } from 'src/app/shared/interfaces/model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CountryDataService } from 'src/app/shared/services/country-data.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { PagesService } from 'src/app/shared/services/pages.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { SenderService } from 'src/app/shared/services/sender.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-page-jobs-published',
  templateUrl: './page-jobs-published.component.html',
  styleUrls: ['./page-jobs-published.component.scss']
})
export class PageJobsPublishedComponent implements OnInit {
  @Input() public job: Job;
  public moreJobInfo: Job;
  public moreProjectInfo: Project;
  public unpublishedJobs: Job;
  public jobDurationString;
  public employmentTypeString;
  public currencyObject;
  public countryJsonArray;
  public paymentID;
  countryJsonObject;
  selectedFirstGroup: boolean;
  selectedSecondGroup: boolean;
  selectedQualifications: boolean;
  selectedDriveLicense: boolean;
  selectedLanguage: boolean;
  selectedPCKnowledge: boolean;
  selectedTasks: boolean;
  selectedSkillsOffer: boolean;
  selectedDescription: boolean;
  externalLink: string;
  showButtonsForSave: boolean = false;
  selectedProject: boolean = false;
  selectedObject: boolean = false;
  public icon;
  public text;
  public iconOffer;

public textOffer;

  applyForm: boolean = false;

  public arrayOfApplication = [
    {id: Links.Internal, description:"pagesInfo.intern"},
    {id:  Links.External, description:"pagesInfo.externalLink"}
  ]
  public privacyArray = [
    { id: StatusPrivacy.Public, description: 'Public', icon: 'bi bi-globe-europe-africa', text: 'Anyone' },
    { id: StatusPrivacy.Contacts, description: 'Contacts', icon: 'bi bi-people-fill', text: 'Your Contacts' },
    { id: StatusPrivacy.Followers, description: 'Followers', icon: 'bi bi-person-lines-fill', text: 'Your Followers' },
    { id: StatusPrivacy.Only_me, description: 'Only me', icon: 'bi bi-lock-fill', text: '' }
  ]

  public config = {
    toolbar: false
  };
  editorConfig = {
    padding: "0",
  }


  @Input() isProjectOutside: boolean = false;
  @Input() projectOut: Project;


  @Input() isJobOutside: boolean = false;

  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;


  dataArrProjects;
  dataArrJobs;
  userCreatedProject: User;
  showDateApplied;
  public remote;
  public openMoreInfo = false;
  public subscription: Subscription;
  @Input() pageJobs: UserJobs;
  @Input() pageProjects: PageProjects;
  @Input() isPublished: boolean = false;
  @Input() page: Page | any;
  @Input() isProject: boolean = false;
  @Input() isPageProject: boolean = false;
  @Output() showOnlySelected: EventEmitter<any> = new EventEmitter<any>();
  user: User;

  public project = new Project();
  public jobDurationArray = [
    { id: Duration.unlimited, description: "job.fourthPage.duration.unlimited" },
    { id: Duration.temporary, description: "job.fourthPage.duration.temporary" },
  ]
  public employmentType = [
    { id: EmploymentType.EmployedFullTime, description: "employmentType.EmployedFullTime" },
    { id: EmploymentType.EmployedPartTime, description: "employmentType.EmployedPartTime" },
    { id: EmploymentType.Internship, description: "employmentType.Internship" },
    { id: EmploymentType.Owner, description: "employmentType.Owner" },
    { id: EmploymentType.BordMember, description: "employmentType.Boardmember" },
    { id: EmploymentType.Volunteer, description: "employmentType.Volunteer" },
    { id: EmploymentType.SelfEmployed, description: "employmentType.SelfEmployed" },
    { id: EmploymentType.Shareholder, description: "employmentType.Shareholder" },
    { id: EmploymentType.Official, description: "employmentType.Official" },
    { id: EmploymentType.Recruiter, description: "employmentType.Recruiter" },
    { id: EmploymentType.Freelancer, description: "employmentType.Freelancer" },
    { id: EmploymentType.Partner, description: "employmentType.Partner" }
  ];
  public salaryArray = [
    { id: Incomes.hour, description: "incomes.hour" },
    { id: Incomes.month, description: "incomes.month" },
    { id: Incomes.year, description: "incomes.year" },
    { id: Incomes.total, description: "incomes.total" },
  ]
  public remoteArray = [
    { id: Remote.onSite, description: "project.firstPage.onSite", percent: '0%' },
    { id: Remote.hybrid, description: "project.firstPage.hybrid", percent: '50%' },
    { id: Remote.remote, description: "project.firstPage.remote", percent: '100%' },
  ];
  arrayPages;
  selectedUser;
  selectedPageID;
description;
hasAppliedToProject: boolean = false;

userForPage: any;


  constructor(public jobService: JobsService,
    public http: HttpClient,
    public router: Router,
    private countryDataService: CountryDataService,
    private projectService: ProjectService,
    private senderService: SenderService,
    private pageService: PagesService,
    private userService: UserService,
    private userAuth: AuthService,
    public dialog?: MatDialog,
    private titleService?: ArraysService

    ) { }

  ngOnInit(): void {
    this.titleService.getOfferArrayProject();
    this.titleService.getPrivacyArray();


    this.userService.getUserEM(this.userAuth.userID).subscribe(t => {
      this.userForPage = t.user;
    });
    this.userService.getUserContactData(this.userAuth.userID).subscribe(t => {
      this.user = t;
    })
    this.pageService.getMyPagesOfProject().subscribe(t => {
      this.arrayPages = t;
    })
    this.countryDataService.countryData$.pipe().subscribe(res => this.countryJsonArray = res);


    console.log(this.pageJobs, "pageJobs")
    if (this.isProjectOutside) {
      this.getJoDurationID(this.projectOut.duration);
      this.getRemoteID(this.projectOut.remote);
      this.getCurrencyID(this.projectOut.currency);
      this.getPaymentFormID(this.projectOut.incomes.s);
      this.getCountryID(this.projectOut.c)

    }

    if (!!this.isJobOutside && !!this.job) {
      this.getJoDurationID(this.job.duration);
      this.getRemoteID(this.job.remote);
      this.getCurrencyID(this.job.currency);
      this.getPaymentFormID(this.job.incomes.s);
      this.getCountryID(this.job.c)
      this.getEmploymentTypeID(this.job.employmentType)

    }

    if (this.isPageProject && this.isPublished) {
      this.projectService.getProject(this.pageProjects.projectID).subscribe(res => {
        this.project = res;
        if (this.project.receivedApplicationBy?.externalLink) {
          this.externalLink = this.project.receivedApplicationBy.externalLink;
        }
        this.getJoDurationID(this.project.duration);
        this.getRemoteID(this.project.remote);
        this.getCurrencyID(this.project.currency);
        this.getPaymentFormID(this.project.incomes.s);
        this.getCountryID(this.project.c)
      })
    }
    if (this.isProject) {
      this.projectService.getProject(this.pageJobs.projectID).subscribe(res => {
        this.project = res;
        this.privacyIcon = this.project;
        this.privacyText = this.project;
        this.privacyTextOffer = this.project;
        this.privacyIconOffer = this.project;

        if (this.project?.receivedApplicationBy?.externalLink) {
          this.externalLink = this.project.receivedApplicationBy.externalLink;
        }
        this.getJoDurationID(this.project.duration);
        this.getRemoteID(this.project.remote);
        this.getCurrencyID(this.project.currency);
        this.getPaymentFormID(this.project.incomes.s);
        this.getCountryID(this.project.c)
        if (this.project.pageID) {
          console.log(this.project, "project")
          this.pageService.getPage(this.project.pageID).subscribe(t => {
            this.page = t.page;
            this.dataArrProjects = t.roles.Projects;
          })
          this.userService.getUserContactData(this.project.userID).subscribe(t => {
            this.userCreatedProject = t;
          })
        }
      })
    } else if (!!this.pageJobs?.jobID) {
      this.jobService.getJob(this.pageJobs.jobID).subscribe((job: Job) => {
        this.job = job;
        this.privacyIcon = this.job;
        this.privacyText = this.job;
        this.privacyTextOffer = this.job;
        this.privacyIconOffer = this.job;


        if (this.job.pageID) {
          this.pageService.getPage(this.job.pageID).subscribe(t => {
            this.page = t.page;
            this.dataArrJobs = t.roles.Jobs;
          })
          this.userService.getUserContactData(this.job.userID).subscribe(t => {
            this.userCreatedProject = t;
          })
        }
        this.getJoDurationID(this.job.duration);
        this.getRemoteID(this.job.remote);
        this.getCurrencyID(this.job.currency);
        this.getPaymentFormID(this.job.incomes.s);
        this.getCountryID(this.job.c)
        this.getEmploymentTypeID(this.job.employmentType)
  });
    }

  }
  getJoDurationID(id) {
    this.jobDurationString = this.jobDurationArray.find(t => t.id === id).description;
  }
  getEmploymentTypeID(id) {
    this.employmentTypeString = this.employmentType.find(t => t.id === id).description;
  }
  getCurrencyID(id) {
    this.currencyObject = this.countryJsonArray.find(t => t.currency.Alphabeticcode === id);
  }
  getPaymentFormID(id) {
    this.paymentID = this.salaryArray.find(t => t.id === id).description;
  }
  getCountryID(id) {
   this.countryJsonObject = this.countryJsonArray.find(t => t.ISO_2 === id);
  }
  getRemoteID(id) {
    this.remote = this.remoteArray.find(test => test.id === id).description;
  }
  changeUserOfProject(id) {
    this.project.userID = id;
    this.projectService.updateProject(this.project._id, this.project);
    this.userService.getUserContactData(id).subscribe(t => {
      this.userCreatedProject = t;
    })

  }

  searchJob(id) {
    localStorage.setItem("searchJobId", this.job._id);
    localStorage.setItem("jobUrl", this.router.url + `/${id}`);
    localStorage.setItem("isPageSearchJob", this.page._id);
    this.router.navigateByUrl(this.page.pageUrl+ `/jobs/${id}` + "/search");
  }

  emitID(id) {
    localStorage.setItem('jobID', id);
    localStorage.removeItem('projectID');
    this.router.navigateByUrl("jobs/public/" + id);

  }

  publicJob() {
    localStorage.setItem('jobID', this.job._id);
    localStorage.removeItem('projectID');
    this.senderService.send({data: this.page, id: "page"})
    this.router.navigateByUrl(this.page.pageUrl + '/jobs/' + this.job._id + "/public");
  }

  removeFromPublic(job) {

    if (this.page) {
      const projecT =  this.page.jobs.data.findIndex(t => t.jobId === job._id);
      this.page.jobs.data[projecT].public = {};
      this.pageService.updatePage(this.page._id, this.page);
    } else if (this.user) {
      const projecT =  this.user.jobs.data.findIndex(t => t.jobId === job._id);
      this.user.jobs.data[projecT].public = {};
      this.userService.updateUser(this.user._id, this.user);

    }
    job.public = {} as any
    this.jobService.updateJob(job._id, job)
  }

  removeProjectFromPublic(project) {

    if (this.page) {
      this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.componentInstance.message = `Are u sure to cancel publication for ${ project?.positionTitle} ?`;
      this.confirmationDialog.afterClosed().subscribe(result => {
        if (result) {
          project.public = {};
          this.projectService.getProjectListPage(this.page._id).subscribe((res) => {
            const job = res[0].projects.find(r => r.projectID === this.project._id);
            job.public = this.project.public;
            this.projectService.updateProjectList(res[0]._id, res[0]);
          });
          this.projectService.updateProject(project._id, project)
        }
        });
    } else if (this.user) {
      const projecT =  this.user.projects.data.findIndex(t => t.projectID === project._id);
      this.user.projects.data[projecT].public = {};
      this.userService.updateUser(this.user._id, this.user);
    }


  }
  manageProject() {
    this.senderService.dataManageProjectID({ID: this.project._id, isProject: true});
    this.showOnlySelected.emit();
    this.selectedProject = true;

   // this.router.navigateByUrl("../projects/" + this.project._id + "/manage");
  }

  manageJob() {
   this.selectedObject = true;
    this.senderService.dataManageJobID({ID: this.job._id, isJob: true});
  }

  closeManageProject() {
    this.selectedProject = false;

  }
  selectSkillsOffer() {
    this.selectedSkillsOffer = true;
  }

  editFirstGroup(data: EditDetailsData) {

    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.project.positionTitle = res.positionTitle;
        this.project.employmentType = res.employmentType;
        this.project.remote = res.remote;
        this.project.street = res.street;
        this.project.postalCode = res.postalCode;
        this.project.city = res.city;
        this.project.c = res.country;
        this.project.state = res.state;
        this.getCountryID(this.project.c);
        this.projectService.updateProject(this.project._id, this.project)
      }
    });
  }

  editSkillsOffer(data: EditDetailsData) {

    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res.jobSkillsOffer) {
        this.job.skillsOffer = res.data.skillsOffer;
        this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }



  editSecondGroup(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res: Job) => {
      if (res) {
        this.project.incomes.budget = res.incomes.budget;
        this.project.incomes.s = res.incomes.s;
        this.project.currency = res.currency;
        if (res.duration === Duration.unlimited) {
          this.project.duration = Duration.unlimited;
          this.project.startDate = res.startDate;
        } else if (res.duration === Duration.temporary) {
          this.project.duration = Duration.temporary;
          this.project.startDate = res.startDate;
        }
        this.project.currency = res.currency;
        this.getCurrencyID(this.project.currency);
        this.getJoDurationID(this.project.duration);
        this.getPaymentFormID(this.project.incomes.s);
        this.projectService.updateProject(this.project._id, this.project);

       //this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobDescription(data: EditDetailsData) {
    this.dialog
      .open(EditJobsDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe((res) => {
        if (res.jobDescription) {
          this.job.description = res.data.description;
          this.jobService.updateJob(this.job._id, this.job);
        }
      });
  }
  publicProject() {
    localStorage.setItem('projectID', this.project._id);
    localStorage.removeItem('jobID');
    this.senderService.send({data: this.page, id: "page"})
    this.router.navigateByUrl('../projects/' + this.project._id + "/public");
  }
  searchProject(id) {
    localStorage.setItem("searchProjectID", id);
    localStorage.setItem("searchAsPage", "isPage");
    localStorage.setItem("jobUrl", this.router.url + `/${id}`);
    this.router.navigateByUrl(this.page.pageUrl + "/projects/" + id + "/search");
  }

  emmitProject(id) {
    localStorage.setItem('projectID', id);
    this.router.navigate([this.page?.pageUrl + "/projects/", id]);
  }
  emmitIDTrue(id) {
    localStorage.setItem('jobID', id);
    localStorage.removeItem('projectID');
    this.router.navigate([this.page?.pageUrl + "/jobs/", id]);
  }
  moreInfo(jobID) {
    this.openMoreInfo = true;
   this.subscription =  this.jobService.getJobMoreInfo(jobID).subscribe((job: Job) => {
        this.moreJobInfo = job;
    });
  }
  lessInfo() {
    this.openMoreInfo = false;
   // this.subscription.unsubscribe();
  }

  projectInfo(projectId) {
    this.openMoreInfo = true;
    this.subscription =  this.projectService.getProject(projectId).subscribe((project: Project) => {
         this.moreProjectInfo = project;
     });

  }
  jobInfo() {
    this.openMoreInfo = true;

  }


  apply() {
    if (this.checkForApplyFreelancer) {
      this.applyForm = true;
    } else if (this.checkForApplyCompany) {
      this.applyForm = true;
    } else if (!this.checkForApplyCompany) {
      this.applyForm = false;
    } else if (!this.checkForApplyFreelancerNoPage) {
      this.applyForm = false;
    }
  }

  cancelApplyForm() {
    this.applyForm = false;

  }

  selectFirstGroup() {
    this.selectedFirstGroup = true;
  }
  selectSecondGroup() {
    this.selectedSecondGroup = true;
  }
  removeClick() {
    this.selectedQualifications = false;
    this.selectedDriveLicense = false;
    this.selectedLanguage = false;
    this.selectedPCKnowledge = false;
    this.selectedTasks = false;
    this.selectedSecondGroup = false;
    this.selectedFirstGroup = false;
    this.selectedSkillsOffer = false;
    this.selectedDescription = false;
  }
  selectTasks() {
    this.selectedTasks = true;
  }
  selectPCKnowledge() {
    this.selectedPCKnowledge = true;
  }
  selectQualifications() {
    this.selectedQualifications = true;
  }
  selectDriveLicense() {
    this.selectedDriveLicense = true;
  }
  selectLanguage() {
    this.selectedLanguage = true;
  }
  selectDescription() {
    this.selectedDescription = true;
  }

  public get isMyProject() {
    return this.project?.userID === this.userAuth?.userID ? this.project?.userID === this.userAuth.userID : this.job?.userID === this.userAuth.userID;
  }


  deleteProjectFreelancer() {
    this.project.freelancer = false;
    this.projectService.updateProject(this.project._id, this.project)
  }

  deleteProjectTasks() {
    this.project.company = false;
    this.projectService.updateProject(this.project._id, this.project)
  }

  addFreelancer() {
    this.project.freelancer = true;
    this.projectService.updateProject(this.project._id, this.project)
  }

  addCompany() {
    this.project.company = true;
    this.projectService.updateProject(this.project._id, this.project)
  }
  selectCheck() {
    this.showButtonsForSave = true;
  }

  saveProjectChanges() {
    this.project.receivedApplicationBy.externalLink = this.externalLink;
    this.projectService.updateProject(this.project._id, this.project);
    this.showButtonsForSave = false;
  }

  cancelChanges() {
    this.showButtonsForSave = false;

  }

  closeManageObject() {
    this.selectedObject = false;
  }

  changeApplication(event: number) {
    if (!this.project.receivedApplicationBy) {
      this.project.receivedApplicationBy = {
        selectedApplication: event,
        externalLink: this.externalLink
      }
    } else {
      this.project.receivedApplicationBy.selectedApplication = event;
    }
  }

  editJobDriveLicense(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.moreProjectInfo.driveLicense = res.driveLicense;
        this.projectService.updateProject(this.moreProjectInfo._id, this.moreProjectInfo);
     //   this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editJobLanguage(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.moreProjectInfo.language = res.language;
        this.projectService.updateProject(this.moreProjectInfo._id, this.moreProjectInfo);
       // this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }
  editJobTasks(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        console.log(res.tasks, "")
        this.moreProjectInfo.tasks = res.tasks;
        this.projectService.updateProject(this.moreProjectInfo._id, this.moreProjectInfo);

      //  this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editPCKnowledge(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.moreProjectInfo.pcKnowledge = res.pcKnowledge;
        this.projectService.updateProject(this.moreProjectInfo._id, this.moreProjectInfo);
       // this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  editQualifications(data: EditDetailsData) {
    this.dialog
    .open(EditJobsDialogComponent, {
      data,
    })
    .afterClosed()
    .subscribe((res) => {
      if (res) {
        this.moreProjectInfo.qualifications = res.qualifications;
        this.projectService.updateProject(this.moreProjectInfo._id, this.moreProjectInfo);

     //   this.jobService.updateJob(this.job._id, this.job);
      }
    });
  }

  public get checkForApplyFreelancer() {
    return this.project.freelancer;
  }

  public get checkForApplyCompany() {
    return !this.project.freelancer && this.userForPage.pages.length > 0
  }
  public get checkForApplyFreelancerNoPage() {
    return !this.project.freelancer && this.user.pages.length === 0;
  }

  public get checkForFreelancerAndCompany() {
  return this.project.freelancer && this.project.company && this.userForPage.pages.length === 0
  }
  test(event) {
    if (event?.isUser) {
     this.selectedUser = true;
     this.hasAppliedToProject = this.project.manage_.find(t => t.userID === this.user._id)?.userID === this.user._id;
     if (this.hasAppliedToProject) {
      this.showDateApplied = this.project.manage_.find(t => t.userID === this.user._id).createdAt;
     }
     console.log(this.hasAppliedToProject, "hasAppliedToProject")


    } else {
      this.selectedUser = false;
      this.selectedPageID = event;
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
        this.hasAppliedToProject = this.project.manage_.find(t => t.pageID === this.page._id)?.pageID === this.page._id;
        this.showDateApplied = this.project.manage_.find(t => t.pageID === this.page._id)?.createdAt;

        console.log(this.hasAppliedToProject, "hasAppliedToProject")

      });
    }
  }
  applyForProject() {
    let jIssue: JIssue;
    if (this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.userAuth.userID,
          description: this.description,
        },
        userID: this.userAuth.userID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.project.manage_.unshift(jIssue);
      this.user.messages.unshift({projectID: this.project._id, timestamp: new Date()});
      this.user.appliedProjects.unshift({ projectId: this.project._id, timestamp: new Date() });


       this.userService.updateUser(this.user._id, this.user);
      this.projectService.updateProject(this.project._id, this.project);

    //  this.router.navigateByUrl("/" + this.user.userUrl + "/projects")


    }  else if (!this.selectedUser) {
      jIssue = {
        status: IssueStatus.Applied,
        message: {
          userID: this.userAuth.userID,
          pageID: this.selectedPageID,
          description: this.description
        },
        userID: this.userAuth.userID,
        pageID: this.selectedPageID,
        createdAt: new Date(),
        id: Math.floor(Math.random() * 9).toString() + 1,
        cancel: {
          status: Status.Accepted,
          timestamp: new Date()
        },
      }
      this.project.manage_.unshift(jIssue);
      this.pageService.getPage(this.selectedPageID).subscribe(res => {
        this.page = res;
        if (this.page.appliedProjects) {
          this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
        } else if (!this.page.appliedProjects) {
          this.page.appliedProjects = [] as any;
          this.page.appliedProjects.unshift({projectId: this.project._id, timestamp: new Date()})
        }

        if (this.page.messages) {
          this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});
        } else {
            this.page.messages = [] as any;
            this.page.messages.unshift({projectId: this.project._id, timestamp: new Date()});

        }

        this.pageService.updatePage(this.page._id, this.page);
        this.projectService.updateProject(this.project._id, this.project);
        this.router.navigateByUrl("/" + this.page.pageUrl + "/projects/ourprojects")


      })
    }
  }

  openShare(data) {
    this.dialog.open(ShareDialogComponent, {
      data
    });
  }





  public set privacyIcon(project: any | Project | Job) {
    if (project) {
      this.icon = this.privacyArray.find(t => t.id === project.privacy.s).icon;
    }
  }

  public get privacyIcon() {
    return this.icon;
  }

  public set privacyText(project: any | Project | Job) {
    this.text = this.privacyArray.find(t => t.id === project.privacy.s).description;
  }

  public get privacyText() {
    return this.text;
  }

  public set privacyTextOffer(project: Project | Job | any) {
    this.textOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).text;
  }
  public get privacyTextOffer() {
    return this.textOffer;
  }

  public set privacyIconOffer(project: Project | Job | any) {
    this.iconOffer = this.titleService.offerArrayProject.find(t => t.id === project.apply.s).icon;
  }

  public get privacyIconOffer() {
    return this.iconOffer;
  }

  openPrivacy(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.project._id) {
          this.project.privacy.s = t;
          this.privacyIcon = this.project;
          this.privacyText = this.project;
          this.project.privacy.timestamp = new Date();
          this.projectService.updateProject(this.project._id, this.project);

        } else {
          this.job.privacy.s = t;
          this.privacyIcon = this.job;
          this.privacyText = this.job;
          this.job.privacy.timestamp = new Date();
          this.jobService.updateJob(this.job._id, this.job);

        }
      }
    })
  }

  openPrivacyOffer(data) {
    this.dialog.open(PrivacyDialogComponent, {
      data: data
    }).afterClosed().subscribe(t => {
      if (t !== undefined) {
        if (this.project._id) {
          this.project.apply.s = t;
          this.privacyIconOffer = this.project;
          this.privacyTextOffer = this.project;
          this.project.apply.timestamp = new Date();
          this.projectService.updateProject(this.project._id, this.project);

        } else {
          this.job.apply.s = t;
          this.privacyIconOffer = this.job;
          this.privacyTextOffer = this.job;
          this.job.apply.timestamp = new Date();
          this.jobService.updateJob(this.job._id, this.job);

        }
      }
    })
  }



}
