<!-- <div class="container">
  <ng-container   *ngFor="let user of users">


    <div>
      <div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2">
        <p class="mb-0">
          <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
          {{user?.firstName}} {{user?.lastName}}
        </p>


        <div class="dropdown ms-auto">
          <a class="nav nav-link text-secondary mb-0"  id="aboutAction2" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="aboutAction2">
            <li><a class="dropdown-item" > <i class="bi bi-pencil-square fa-fw pe-2"></i>Remove</a></li>
            <li (click)="blockUser(user)"><a class="dropdown-item" > <i class="bi bi-lock fa-fw pe-2"></i>Block</a></li>
            <li><a class="dropdown-item" > <i class="bi bi-chat-left-dots pe-2"></i>Report</a></li>

          </ul>
        </div>
      </div>


    </div>

</ng-container>


</div>
-->

<div class="modal fade" id="feedActionPhoto" tabindex="-1" aria-labelledby="feedActionPhotoLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<!-- Modal feed header START -->
			<div class="modal-header">
				<h5 class="modal-title" id="feedActionPhotoLabel">Blocked Users</h5>
				<button type="button" mat-dialog-close="true"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<!-- Modal feed header END -->

				<!-- Modal feed body START -->

        <div class="modal-body">
          <div class="d-flex justify-content-between">
            <div class="d-flex w-100" >
              <div class="nav mt-3 mt-lg-0 flex-nowrap align-items-center px-4 px-lg-0">
                <div class="nav-item w-100">
                  <form [formGroup]="searchForm" class="rounded position-relative">
                    <input class="form-control ps-5 bg-light" id="userSearch" name="userSearch" formControlName="userSearch" type="search" [(ngModel)]="searchUser" (keyup)="searchForUser(searchUser)"  autocomplete="off" placeholder="Search..." aria-label="Search">
                    <button class="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y" type="submit"><i class="bi bi-search fs-5"> </i></button>
                  </form>
                  <div class="col-md-4" style="padding: 20px;"  *ngIf="selectUser && searchUser">
                    <button (click)="blockUser(userSelected)" class="btn btn-primary col-md-12 btn-sm" type="submit">Block</button>
                  </div>
                </div>
              </div>


      </div>

        </div>

          <div *ngIf="searchUser" class="card__search">
            <div class="card_list">
              <div *ngFor="let user of allUsers">
                <div class="row">
                  <div class="d-flex row pr-2 pl-2 align-items-center card_list" (click)="addNewUser(user)">
                    <p class="mb-0">
                      <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
                      {{user?.firstName}} {{user?.lastName}}
                      <button *ngIf="!getUser(user)" (click)="blockUser(user)" class="btn btn-primary-soft">Block</button>
                      <button *ngIf="!!getUser(user)" (click)="unblockUser(user)" class="btn btn-primary-soft">Unblock</button>

                    </p>
                  </div>
                </div>
              </div>
          </div>
        </div>


        </div>
				<div class="modal-body">
				<!-- Add Feed -->


				<!-- Dropzone photo START -->
				<div>
          <ng-container  *ngFor="let user of users">


            <div>
              <div class="d-flex align-items-center rounded border px-3 py-2 mt-2 mb-2 justify-content-between">
                <p class="mb-0">
                  <img  alt="no-photo" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="32" />
                  {{user?.firstName}} {{user?.lastName}}
                </p>

               <button class="btn btn-primary-soft" (click)="unblockUser(user)"><i class="bi bi-lock fa-fw pe-2"></i>Unblock
                </button>

              </div>


            </div>

        </ng-container>
				</div>
				<!-- Dropzone photo END -->

				</div>
				<!-- Modal feed body END -->

				<!-- Modal feed footer -->

				<!-- Modal feed footer -->
		</div>
	</div>
</div>
