<footer class="footer fixed-bottom">
  <div class="fb-body-footer">
	<div id="fb-body-footer-base" class="fb-body-footer justify-content-center">
    <ul>
      <li *ngFor="let lang of languages">
        <div
          (click)="setLanguage(lang.id)">
        <a [ngClass]="{'active': translateService.currentLang === lang.id}">{{lang.description}}</a>

      </div>
    </li>
      <li (click)="openLanguages()">
        <a><i class="bi bi-plus-square"></i></a>
      </li>
    </ul><br><hr>
</div>
</div>
</footer>
