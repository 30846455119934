import { Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";
import { AuthService } from "../shared/services/auth.service";
import { UserHelperService } from "../shared/utils/user-helper.service";
import { io } from 'socket.io-client';
import { Socket } from "socket.io-client";
import { ToastService } from "../shared/services/toast.service";
import { environment } from "src/assets/environments/environment";
import { ImageService } from "../shared/services/image.service";
import {NodeNotificationHelperService} from "../shared/utils/node-notification-helper.service";
import { UserService } from "../shared/services/user.service";
import { Status, StatusFollow } from "../shared/enums/enums-model";
import { UserContact } from "../shared/interfaces/model";
import { User } from "../shared/interfaces/user.model";
@Component({
  selector: "app-friends",
  templateUrl: "./friends.component.html",
  styleUrls: ["./friends.component.scss"]
})
export class ContactsComponent implements OnInit {
  contacts: UserContact[] = [];
  requestContacts: UserContact[] = [];
  user: User;
  noFriends: boolean;
  isLoading = true;
  unsubscribe = new Subject<void>();
  private socket: Socket;
  public image;
  allContacts = false;
  requests = true;
  userContacts$: Observable<User[]>;

  loggedUser: User;
  filterContacts: any

  constructor(private userHelper: UserHelperService,
     public authService: AuthService,
     private toastService: ToastService,
     public imageService: ImageService,
     public userService: UserService,
              public nodeNotification: NodeNotificationHelperService) {
   // this.socket = io(environment.backend);
  }

  ngOnInit(): void {
    this.userService.getUserData(this.authService.userID);
    this.userContacts$ = this.userService.showContacts(this.authService.userID);
    this.userService.getUserContactData(this.authService.userID).subscribe(res => {
      this.loggedUser = res;
      this.filterContacts = this.loggedUser.following.data.filter(t => t.status === StatusFollow.Contact)

    });

    this.userService.user().pipe(shareReplay(1)).subscribe(res => {
     this.user = res;


     this.contacts = this.user?.contacts.data.filter(res => res.status === Status.Accepted);
     this.requestContacts = this.user?.contacts.data.filter(res => res.status === Status.Pending);
    });

  }
  onAcceptButtonClick(friendClicked: UserContact) {

    let item = this.user.contacts.data.find(item => item.contactId === friendClicked.contactId);
    if (item) {
      item.contactId = item.contactId;
      item.timestamp = new Date();
      item.status = Status.Accepted;
    }
    const follower = {
      contactId: this.authService?.userID,
      timestamp: new Date()
    }
    const following = {
      contactId: item.contactId,
      timestamp: new Date()
    }


    this.userService.getUserContactData(friendClicked.contactId).pipe(shareReplay(1)).subscribe((res:User) => {
      let item = res.contacts.data.find(item => item.contactId === this.authService.userID);
      if (item) {
        item.timestamp = new Date();
        item.contactId = item.contactId;
        item.status = Status.Accepted;
      }
      this.userService.updateUser(res?._id, res);
    })
    this.user.following.data.unshift(following);

  //  this.user.friends.unshift(friendClicked.id);
   this.userService.updateUser(this.user._id, this.user);
  }
  onRejectButtonClick(friendClicked: UserContact, index) {
    this.user.contacts.data.slice(index, 1);

    this.userService.getUserContactData(friendClicked.contactId).subscribe((res:User) => {
        res.contacts.data.slice(index, 1);
        this.userService.updateUser(friendClicked.contactId, res);
    })
    this.userService.updateUser(this.authService.userID, this.user);
  }


  showAllContacts() {
    this.requests = false;
    return this.allContacts = true;
  }

  showRequests() {
    this.requests = true;
    return this.allContacts = false;

  }


  /* public LoadRequestingFriends() {
    this.userHelper.loadRequestingFriends(this.authService.userID).subscribe(finalRequesters => {
      this.isLoading = false;
      this.noFriends = finalRequesters.length === 0 ? true : false;
      this.contacts = finalRequesters;

      this.contacts.forEach(t => {
      this.modelDataService.getUserModel(t.contactId).subscribe((res:Model) => {
        this.imageService.getImageByID(res.profilePicture).forEach((img: Image) => {
          if(img.imageUrl) {
            this.image = img.imageUrl
          }
        })
      })
      })
    });
  } */
}
