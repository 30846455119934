import { Component, OnInit } from "@angular/core";
import {Image, Model, UserContact} from "../../shared/interfaces/model";
import {UserHelperService} from "../../shared/utils/user-helper.service";
import {AuthService} from "../../shared/services/auth.service";
import {ImageService} from "../../shared/services/image.service";
import { SenderService } from "src/app/shared/services/sender.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/shared/services/user.service";
import { Status } from "src/app/shared/enums/enums-model";
import { User } from "src/app/shared/interfaces/user.model";

@Component({
  selector: "app-friend-messages",
  templateUrl: "./friend-messages.component.html",
  styleUrls: ["./friend-messages.component.scss"]
})
export class FriendMessagesComponent implements OnInit {
  friends: User[] = [];
  public image;
  public data: Model;
  user: User;
  showMessages = false;
  contacts: UserContact[] = [];


  constructor(private userHelper: UserHelperService,
              public authService: AuthService,
              public imageService: ImageService,
              public friendList: SenderService,
              public userService: UserService,
              public router: Router) { }

  ngOnInit(): void {
    this.userService.getUserData(this.authService.userID).subscribe(res => {
     this.user = res;
     this.contacts = this.user?.contacts.data.filter(res => res.status === Status.Accepted);
     console.log(this.contacts);
      this.friendList.sendFriends(this.contacts);
    });
  }




}
