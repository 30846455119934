<ng-container *ngIf="sidebar">
  <div class="d-flex row pb-3 pt-3 pl-2 sidebar-details" (click)="goToWeb(group._id)">
    <img *ngIf="hasPhoto" [round]="false" [src]="this.image" ngx-gravatar [round]="false" [email]="'example@mail.com'" fallback="mp" size="30">
    <img *ngIf="!hasPhoto" [round]="false" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
   <div class="d-flex column pl-3">
     {{group.name}}
   </div>


  </div>
 </ng-container>
 <ng-container *ngIf="sidebarGroup">
  <div class="d-flex row pb-3 pt-3 pl-2 sidebar-details" (click)="goToWeb(group._id)">
    <img *ngIf="hasPhoto" [src]="this.image" ngx-gravatar [round]="false" [email]="'example@mail.com'" fallback="mp" size="30">
    <img *ngIf="!hasPhoto" ngx-gravatar [email]="'example@mail.com'" fallback="mp" size="30">
    <div class="d-flex column pl-3">
      {{group.name}}
    </div>


   </div>
  </ng-container>
