<div class="card" style="width: 15rem; margin-right: 1rem;" (click)="navigateToUser(user?._id)">
  <img class="card-img-top" [src]="this.imageProfile">
  <div class="card-body">
    <h3 class="card-title">
     <p>{{user?.firstName}} {{user?.lastName}}</p>
    </h3>
    <div *ngIf="contact.status === 0" style="
    flex-direction: column;
    display: flex;
">
      <button class="btn btn-primary mt-1 mb-1 " (click)="onAcceptButtonClick(contact)">Confirm</button>
      <button class="btn btn-light mt-1 mb-1"
      (click)="onRejectButtonClick(contact, i)">Remove</button>
  </div>
  </div>
</div>
