import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditLanguage, LanguageDialogComponent } from 'src/app/components/modals/editors/language-dialog/language-dialog.component';
import { User } from 'src/app/shared/interfaces/user.model';
import { ChangeDetectionService } from 'src/app/shared/services/change-detection.service';
import { JobsService } from 'src/app/shared/services/jobs.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-sidebar-language',
  templateUrl: './sidebar-language.component.html',
  styleUrls: ['./sidebar-language.component.scss']
})
export class SidebarLanguageComponent implements OnInit, AfterViewInit {
  @Input() data: any;
  @Input() isUser: boolean;
  languages = [];
  @Input() user: User;
  @Input() userCV: any;
  showLanguage;
  @Input() dataObj;

  @Input() replace: boolean;


  constructor(private userService: UserService,
    private changeDetectionService: ChangeDetectionService,
    public http: HttpClient,
    public dialog: MatDialog,
    public jobservice: JobsService,
    public cd?: ChangeDetectorRef) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    console.log(this.dataObj, "datsobj")

    if (!!this.user) {
      this.http.get<any>(`assets/languages/${this.user?.language?.primary}/language.json`).subscribe(data => {
        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({type: key, value: this.languages[key]}));
      })
    }
  }

  replaceLanguage() {
    const data = this.userCV.data.map(t => t.skills[0].languages.data);
    let  newArr = Array.prototype.concat(...data);

    newArr =  newArr.filter((el, i, a) => i === a.findIndex((findTest) => findTest.name === el.name));
    this.user.searchFilterSkills.searchLanguages.data = [];
    this.user.searchFilterSkills.searchLanguages.data = newArr;

    this.userService.updateUser(this.user._id, this.user);
      this.changeDetectionService.emitChanges(true);
  }

  editLanguage(data: EditLanguage) {
    this.dialog.open(LanguageDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        if (this.dataObj) {
          this.dataObj.language = res;
        } else {
          console.log(this.data, "datas")
        }
        this.data = res;
        console.log(res, "res")
        this.jobservice.updateJob(this.dataObj._id, this.dataObj)



        this.cd.detectChanges();
      }
    });
  }
  removeLanguage(index) {
    this.user.searchFilterSkills.searchLanguages.data.splice(index, 1);
    this.userService.updateUser(this.user._id, this.user);
    this.changeDetectionService.emitChanges(true);

  }

  public getLanguageName(type) {
   return this.languages.find(t => t.type === type)?.value;
  }



}
