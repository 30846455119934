import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CropperComponent } from "angular-cropperjs";
import { Model, Page, Group, Image } from "src/app/shared/interfaces/model";
import { User } from "src/app/shared/interfaces/user.model";
import { AuthService } from "src/app/shared/services/auth.service";
import { GroupService } from "src/app/shared/services/group.service";
import { ImageService } from "src/app/shared/services/image.service";
import { PagesService } from "src/app/shared/services/pages.service";
import { UserService } from "src/app/shared/services/user.service";
import { WarningDialogComponent } from "src/app/sharedComponents/warning-dialog/warning-dialog.component";

@Component({
  selector: "app-user-dialog-picture",
  templateUrl: "./user-dialog-picture.component.html",
  styleUrls: ["./user-dialog-picture.component.scss"]
})
export class UserDialogPictureComponent implements OnInit {
  imgUrl;
  image;
  imageURL;
  imageCrop;
  @ViewChild("imageProfileCropper", {static: false}) public imageProfileCropper: CropperComponent;
public imagePhoto;
public confirmationDialog: MatDialogRef<WarningDialogComponent>
public user: User;

public imgConfig = {
  aspectRatio : 1/1,
  dragMode : "move",
  background : true,
  movable: true,
  rotatable : true,
  scalable: true,
  zoomable: true,
  viewMode: 0,
  checkImageOrigin : false,
  checkCrossOrigin: false,
  width: 300,
  height: 400,
  rounded: true,

  crop: () => {

this.image = this.imageProfileCropper.cropper.getCroppedCanvas().toDataURL("image/jpeg", 90);
  }
};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EditUserDialog,
    public imageService: ImageService,
    public authService: AuthService,
    public pageService: PagesService,
    public groupService: GroupService,
    public dialog: MatDialog,
    public userService: UserService,
    public dialogRef: MatDialogRef<UserDialogPictureComponent>
    ) { }

  ngOnInit(): void {
    this.userService.getUserData(this.authService.userID);
    this.userService.user().subscribe(res => {
      this.user = res;
    })

    if (this.data.edit) {
      this.data.model = this.data.model;
      if (this.data.model.profilePicture !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.model.profilePicture).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
        });
      }
    } else if(this.data.editPage) {
      this.data.page = this.data.page;
      if (this.data.page.profileImage !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.page.profileImage).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
        });
      }
    } else if(this.data.editGroup) {
      this.data.group = this.data.group;
      if (this.data.group.profileImg !== undefined && this.authService.isLoggedIn) {
        this.imageService.getImageByID(this.data.group.profileImg).subscribe((res: Image) => {
          this.imagePhoto = res.imageUrl;
          console.log("image");
        });
      }
    }
  }
  fileChangeEvent(event: any): void {
    this.imgUrl = URL.createObjectURL(event.target.files[0]);
    this.imageCrop = event.target.files[0];
    }

    saveImage() {
      if(this.data.edit) {
      const name = `${this.user?.firstName}.${this.user?.lastName}`

      this.imageProfileCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

         this.imageService.addImage(blob, name).subscribe((res: any) => {
           if (res.body) {
             this.data.model.profilePicture = res.body._id;
             this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
              this.imageURL = t.imageUrl;
              this.user.profilePicture = t.imageUrl
              this.userService.updateUser(this.authService.userID, this.user);
             });
            }
          }, (err: any) => {
            console.log(err);
         })
       }, "image/jpeg", 100);
      } else if(this.data.editPage) {
        const name = `${this.data.page._id}`

        this.imageProfileCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

           this.imageService.addImage(blob, name).subscribe((res: any) => {
             if (res.body) {
               this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
                this.imageURL = t.imageUrl;
                this.data.page.profileImage = t.imageUrl;
               });
               this.pageService.updatePage(this.data.page._id, this.data.page);
              }
            }, (err: any) => {
              console.log(err);
           })
         }, "image/jpeg", 100);
      }  else if(this.data.editGroup) {
        const name = `${this.data.group._id}`

        this.imageProfileCropper.cropper.getCroppedCanvas({width: 1024, height: 1024}).toBlob((blob) =>  {

           this.imageService.addImage(blob, name).subscribe((res: any) => {
             if (res.body) {
               this.data.group.profileImg = res.body._id;
               this.imageService.getImageByID(res.body._id).subscribe((t: Image) => {
                 this.data.group.profileImg = t.imageUrl;
               });
               this.groupService.update(this.data.group._id, this.data.group);
              }
            }, (err: any) => {
              console.log(err);
           })
         }, "image/jpeg", 100);
      }
}
flipHorizontal() {
  this.imageProfileCropper.cropper.scaleX(-1);
  }
  saveData() {
    if(this.authService.isLoggedIn) {
      if(this.data.edit) {
        this.dialogRef.close(this.data);

      } else if(this.data.editPage) {
        this.pageService.updatePage(this.data.page._id, this.data.page);
      }
      else if(this.data.editGroup) {
        this.groupService.update(this.data.group._id, this.data.group);
      }
     this.dialogRef.close(this.data);
    } else {
      this.dialogRef.close();
      this.confirmationDialog = this.dialog.open(WarningDialogComponent, {
        disableClose: false
      });

      this.confirmationDialog.afterClosed().subscribe(result => {
        if(result) {
          // do confirmation actions
        }
        this.confirmationDialog = null;
      });
    }
  }

  flipVertical() {
    this.imageProfileCropper.cropper.scaleY(-1)
  }

rotateLeft45() {
  this.imageProfileCropper.cropper.rotate(-45);
}
rotateRight45() {
  this.imageProfileCropper.cropper.rotate(45);
}
cropperZoom(number) {
  this.imageProfileCropper.cropper.zoom(number);
}
cancel(): void {
  this.dialogRef.close();
}
}

export class EditUserDialog {
  edit?: boolean;
  editPage?: boolean;
  model?: Model;
  page?: Page;
  editGroup?: boolean;
  group?: Group;
}
