import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {NotificationNodeService} from "../../../../shared/services/notification-node.service";
import {NodeNotificationHelperService} from "../../../../shared/utils/node-notification-helper.service";
import {NodeNotifications} from "../../../../shared/interfaces/node-notifications";
import {AuthService} from "../../../../shared/services/auth.service";

@Component({
  selector: "app-settings-notification",
  templateUrl: "./settings-notification.component.html",
  styleUrls: ["./settings-notification.component.scss"]
})
export class SettingsNotificationComponent implements OnInit {
  public node = new NodeNotifications();
  isCollapsed = false;
  isCollapsedTags = false;
  isCollapsedReminders = false;
  isCollapsedActivity = false;
  isCollapsedUpdates = false;
  isCollapsedFriends = false;
  isCollapsedPeople = false;
  isCollapsedBirthday = false;
  isCollapsedGroup = false;
  isCollapsedVideo = false;
  isCollapsedEvents = false;
  isCollapsedPages = false;
  isCollapsedPagesFollow = false;
  isCollapsedPagesMarketPlace = false;
  isCollapsedFundraisers = false;
  isCollapsedNotifications = false;
  public friendRequestNotificationPush = "";
  friendRequestNotificationEmail = "Off";
  public friendRequestNotificationReminders = "";

  constructor(public router: Router,
              private notificationNodeService: NotificationNodeService,
              private notificationNodeHelper:NodeNotificationHelperService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.notificationNodeHelper.loadNodeNotifications(this.authService.userID).subscribe((result: NodeNotifications) => {
      this.node = result;
      if(this.node.friendRequest.push) {this.friendRequestNotificationPush = this.node.friendRequest.push ? "On" : "Off"; }
      if(this.node.reminders.all) { this.friendRequestNotificationReminders = this.node.reminders.all ? "On" : "Off"; }

    })
  }

  clickReminder(event) {
   this.node = {} as NodeNotifications;
    const node = {
      reminders: {
        all: event.target.checked,
      },
      user: this.authService.userID
    }
    if(event.target.checked) {
      this.friendRequestNotificationReminders = "On";
      this.isCollapsedReminders = true;
    } else {
      this.friendRequestNotificationReminders = "Off";
      this.isCollapsedReminders = false;

    }
    if(Object.keys(this.node).length === 0) {
      this.notificationNodeHelper.createNewNode(node, this.authService.userID).subscribe();
    } else {
      this.node.reminders.all = node.reminders.all;
      this.notificationNodeHelper.updateNodeNotification(this.node).subscribe();
    }
  }

  clicked(event) {
    const nodeObject = {
      friendRequest: {
        push: event.target.checked
      },
      user: this.authService.userID
    }
    if(event.target.checked) {
      this.friendRequestNotificationPush = "On"
      this.node.friendRequest.push = event.target.checked;
    } else {
      this.friendRequestNotificationPush = "Off"
      this.node.friendRequest.push = event.target.checked;
    }
    if(Object.keys(this.node).length === 0) {
      this.notificationNodeHelper.createNewNode(nodeObject, this.authService.userID).subscribe();
    } else {
      this.notificationNodeHelper.updateNodeNotification(this.node).subscribe();
    }
  }
  clickedEmail(event){
    if(event.target.checked) {
      this.friendRequestNotificationEmail = "On";
    } else {
      this.friendRequestNotificationEmail = "Off"
    }
  }


}
