<div class="container">
  <form class="form-grid">
    <li class="line form-row">
      <div class="col row col-md-12">
        <div class="col-md-12">
          <label>{{'file.name' | translate}}</label>
          <input name="school" autocomplete="off" [(ngModel)]="data.file.name"  class="form-control-sm col-md-12" >
        </div>
      </div>
    </li>
    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <label> {{'file.link' | translate}}
          </label>
          <input class="form-control-sm col-md-12" [(ngModel)]="data.file.link" name="link" placeholder="https://www.smartits.de">
        </div>
      </div>
    </li>
    <br>
    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <input class="form-control-sm col-md-12"  type="file" [(ngModel)]="data.file.file" name="file">
        </div>
      </div>
    </li>
    <br>
    <li class="line form-row">
      <div class="col row">
        <div class="col-md-12">
          <label> {{'file.description' | translate}}
          </label>
          <quill-editor [(ngModel)]="data.file.description"
           name="emptyFileDescription"
           [modules]="config"></quill-editor>
        </div>
      </div>
    </li>
  </form>
  <mat-dialog-actions align="end">
    <button type="button" class="btn btn-secondary" mat-button mat-dialog-close>{{'cancel' | translate}}</button>
    <button type="button" class="btn btn-success" mat-button (click)="saveData()">{{'save' | translate}}
    </button>
  </mat-dialog-actions>
</div>
