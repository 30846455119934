import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog-blocked',
  templateUrl: './confirmation-dialog-blocked.component.html',
  styleUrls: ['./confirmation-dialog-blocked.component.scss']
})
export class ConfirmationDialogBlockedComponent implements OnInit {
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  header = "";
  unblock: string;

  isUnblock: boolean = false;
  isBlock: boolean = false;
  firstName: string;
  lastName: string;
  message: string;
  constructor(@Inject(MAT_DIALOG_DATA) private data: any,
  private dialogRef: MatDialogRef<ConfirmationDialogBlockedComponent>) { }

  ngOnInit(): void {
  }

  onConfirmClick() {
    this.dialogRef.close(true);
  }

}
