<div class="flex col-12 job-buttons" *ngIf="!showForApplied">
  <ul class="nav nav-pills nav-stack small fw-normal">
    <span *ngIf="hasApplied"> {{showDateApplied | date: "dd.MM.yyyy HH:mm" }}</span>
    <li class="nav-item" stopPropagation *ngIf="hasApplied">
      <a  (click)="goToApplied()" class="nav-link bg-light py-1 px-2 mb-0">
        <i class="bi me-1 bi-box-arrow-up-right text-info pe-2">
        </i>
        {{firstButtonText | translate}}
      </a>
    </li>
    <li class="nav-item" stopPropagation *ngIf="!hasApplied">
      <a (click)="apply()" class="nav-link bg-light py-1 px-2 mb-0"> <i
          class="bi me-1 bi-box-arrow-up-right text-success pe-2"></i>{{'job.apply' | translate}}</a>
    </li>

    <li *ngIf="!isSaved" class="nav-item" stopPropagation>
      <a class="nav-link bg-light py-1 px-2 mb-0" (click)="saveObj()"> <i
          class="bi me-1 bi-bookmark text-info pe-2"></i><span>{{'save' | translate}}</span></a>
    </li>
    <li *ngIf="isSaved" class="nav-item" stopPropagation>
      <a class="nav-link bg-light py-1 px-2 mb-0" (click)="unsaveObj()"> <i
          class="bi me-1 bi-bookmark-fill text-info pe-2"></i>{{'job.unsave' | translate}}</a>
    </li>

    <li class="nav-item">
      <a  (click)="openShare()" class="nav-link bg-light py-1 px-2 mb-0">
        <i class="bi me-1 bi-share text-warning pe-2">
        </i>
        {{thirdButtonText | translate}}
      </a>
    </li>
    <li class="nav-item dropdown ms-lg-auto">
      <a class="nav-link bg-light py-1 px-2 mb-0" stopPropagation id="feedActionShare" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="bi bi-three-dots"></i>
      </a>
      <!-- Dropdown menu -->
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="feedActionShare">
        <li><a class="dropdown-item" href="#"> <i class="bi bi-envelope fa-fw pe-2"></i>Create a poll</a></li>
        <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark-check fa-fw pe-2"></i>Ask a question
          </a></li>
        <li><a stopPropagation class="dropdown-item"><i class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>
        <li>
          <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" href="#"> <i class="bi bi-pencil-square fa-fw pe-2"></i>Help</a></li>
      </ul>
    </li>
  </ul>

</div>

<div class="flex col-12 job-buttons" *ngIf="showForApplied">
  <button type="button" class="btn btn-primary-soft btn-sm col-lg-2" stopPropagation (click)="message(data)"><i
      class="bi me-1 bi-chat-dots"></i> {{'job.message' | translate}}</button>
  <button  (click)="openShare()" type="button" class="btn btn-success-soft d-block btn-sm col-lg-2"><i class="bi me-1 bi-share"></i>
    {{'job.share' | translate}}</button>
  <div class="dropdown">
    <button stopPropagation type="button" class="icon-md rounded-circle btn btn-primary-soft me-2 px-2"
      id="chatcoversationDropdown2" role="button" data-bs-toggle="dropdown" data-bs-auto-close="outside"
      aria-expanded="false"><i class="bi me-1 bi-three-dots"></i></button>

    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chatcoversationDropdown2">
      <li><a class="dropdown-item" href="#"><i class="bi bi-check-lg me-2 fw-icon"></i>Mark as read</a></li>
      <li><a class="dropdown-item" href="#"><i class="bi bi-mic-mute me-2 fw-icon"></i>Mute conversation</a>
      </li>
      <li *ngIf="page" (click)="goToPage()"><a class="dropdown-item"><i
            class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
      <li *ngIf="!page" (click)="viewProfile()"><a class="dropdown-item"><i
            class="bi bi-person-check me-2 fw-icon"></i>View profile</a></li>
      <li><a stopPropagation class="dropdown-item" (click)="deleteProject()"><i
            class="bi bi-trash me-2 fw-icon"></i>Delete</a></li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" href="#"><i class="bi bi-archive me-2 fw-icon"></i>Archive chat</a></li>
    </ul>
  </div>
</div>
