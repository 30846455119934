import { Component, Input, OnInit } from '@angular/core';
import { DataItemDetailsComponent } from '../data-item-details.component';
import { EditDetailsData } from '../../jobs/edit-jobs-dialog/edit-jobs-dialog.component';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent extends DataItemDetailsComponent implements OnInit  {

  @Input() data: any;
  @Input() isJob: boolean;
  @Input() languages: any;
  @Input() myObject: boolean;
  @Input() projectSearchUser: boolean = false;
  @Input() userSearch: any;
  @Input() jobSearchUser: boolean = false;



ngOnInit(): void {
    console.log(this.userSearch, "ddhdhhdhdhdh")
}

  editTasks(data: EditDetailsData) {

    this.emmitDataTasks.next(data);
  /* if (res.jobSkillsOffer) {
      this.job.skillsOffer = res.data.skillsOffer;
      this.jobService.updateJob(this.job._id, this.job);
    } */
}

editPCKnowledge(data: EditDetailsData) {
  this.emmitDataPCKnowledge.next(data);
}

editDriveLicense(data) {
  this.emmitDriveLicense.next(data);

}

editDescription(data) {
  this.emmitDataDescription.next(data);
}
editLanguage(data) {
  this.emmitDataLanguage.next(data);
}

editSkillsOffer(data: any): void {
  console.log(data, "")
    this.emmitSkillsOffer.next(data);
}

deleteProjectFreelancer(data) {
  this.emmitDeleteProjectFreelancer.next(data);
}

public getLanguageName(type) {
  return this.languages.find((t) => t.type === type);
}






}
