import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobItemDetailsComponent } from './job-item-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { DirectivesModule } from '../shared/directives/directives.module';
import { GravatarModule } from 'ngx-gravatar';
import { PipeModule } from '../shared/pipes/pipe.module';
import { SmartitsChipsModule } from "src/app/smartits-ui/chips/smartits-chips.module";
import { QuillModule } from 'ngx-quill';
import { ProjectModule } from '../components/jira-system/project/project.module';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


@NgModule({
  declarations: [
    JobItemDetailsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    DirectivesModule,
    GravatarModule,
    PipeModule,
    SmartitsChipsModule,
    QuillModule,
    TooltipModule,
    ProjectModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareButtonsPopupModule,
  ],

  exports: [
    JobItemDetailsComponent
  ]
})
export class JobItemDetailsModule { }
