import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EditCareer, DataDialogComponent } from 'src/app/components/modals/data-dialog/data-dialog.component';
import { EditOffer, OfferDialogComponent } from 'src/app/components/modals/editors/offer-dialog/offer-dialog.component';
import { EditEducation, EducationDialogComponent } from 'src/app/components/modals/education-dialog/education-dialog.component';
import { StatusTitle } from 'src/app/shared/enums/enums-model';
import { User } from 'src/app/shared/interfaces/user.model';
import { ArraysService } from 'src/app/shared/services/arrays-service/title-service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BranchesService } from 'src/app/shared/services/branches.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-user-about',
  templateUrl: './user-about.component.html',
  styleUrls: ['./user-about.component.scss']
})
export class UserAboutComponent implements OnInit {

  userData: any;
  public dataList$: Observable<any>;
  public user: User;
  branchTitle;
  categoryTitle
  subCategoryTitle
  public emailIcon;
  public getGender;
  public emailText;
  religion;
  newFamilyMember;
  newSocialLink;
  newWebsite;

  cityAdd: false;
  hometownAdd: false;
  addressAdd: false;
  hometown: string;
  languages = [];
  searchForPage;
  selectedPageId;




  public housingTypeOptions = [
    { id: StatusTitle.Mr, description: "data.mr" },
    { id: StatusTitle.Mrs, description: "data.mrs" },
  ];





  constructor(public userService: UserService,
    public authService: AuthService,
    private categoryService: CategoryService,
    private branchService: BranchesService,
    public arrayService: ArraysService,
    private http: HttpClient,
    private translateService: TranslateService,
    private dialog: MatDialog,

  ) {

  }


  ngOnInit(): void {

    this.dataList$ = this.userService.dataList;
    this.dataList$.subscribe((res: any) => {
      //   this.privacyIcon = this.loggedUser;
      //  this.privacyText = this.loggedUser;


      this.userData = res;

      this.userService.checkUserPrivacy(this.userData.user._id).subscribe(res => {
        console.log(res, "ress")
      })


      this.getBranch(this.userData.user?.branches);

      this.getCategory(this.userData.user?.categories);
      this.privacyIcon = this.userData.user;
      this.privacyText = this.userData.user;
      this.getIdOfGender(this.userData?.user?.gender);
      this.getReligion(this.userData?.userUser?.religion?.name);

      this.getSubCategoryTitle(this.userData.user?.categories.name);

      this.http.get<any>(`assets/languages/${this.userData.user.language.primary}/language.json`).subscribe(data => {
        this.translateService.use(this.userData.user.language.primary);
        this.languages = data;
        this.languages = Object.keys(this.languages).map(key => ({ type: key, value: this.languages[key] }));
      })





    });


  }

  public get checkLoginID() {
    return this.userData.user._id === this.authService.userID;
  }

  getActualWorkingCompany() {
    return this.userData.cv.data[0].careers.subCategories[0];
  }

  getBranch(id) {
    this.branchTitle = this.branchService.branches.find(t => t.id === id)?.description;
  }
  getCategory(id) {
    if (!!id) {
      return this.categoryTitle = this.categoryService.categories.find(t => t.id === id)?.name;
    }
  }
  getSubCategoryTitle(id) {
    const t = this.categoryService.categories.find(t => t.id === id);
    const tt = this.userData.user.categories.subCategory.find(t => t.name);
    this.subCategoryTitle = t.subCategory.find(rtt => rtt.id === tt.name).name;
  }

  public getIdOfGender(id) {
    this.getGender = this.housingTypeOptions.find(test => test.id === id)?.description;
  }
  getReligion(id) {
    this.religion = this.arrayService.religions.find(t => t.id === id);
  }

  public getLanguageName(type) {
    return this.languages.find(t => t.type === type);
  }

  public set privacyIcon(user: User) {
    this.emailIcon = this.arrayService.privacyArray.find(t => t.id === user.email[0].p).icon;
  }

  public get privacyIcon() {
    return this.emailIcon;
  }

  public set privacyText(user: User) {
    this.emailText = this.arrayService.privacyArray.find(t => t.id === user.email[0].p)?.description;

  }
  public get privacyText() {
    return this.emailText;
  }

  getSocialLinkIcons(id) {
    return this.arrayService.socialLinks.find(t => t.id === id);
  }

  editOffer(data: EditOffer) {
    this.dialog.open(OfferDialogComponent, {
      data,
    }).afterClosed().subscribe((res) => {
      if (!res) {
        return;
      } else {
        this.user.cv[0].skills[0].subCategories[0] = res
        this.userService.updateUser(this.authService.userID, this.user)
      }
    });
  }

  public editCareer(data: EditCareer) {
    this.dialog.open(DataDialogComponent, {
      data,
    }).afterClosed().subscribe((res: EditCareer) => {
      if (!res) {
        return;
      } else {
        if (res) {
          this.userData.cv.data[0].careers.subCategories[data.index].name = res.career.name;
          this.userData.cv.data[0].careers.subCategories[data.index].position = res.career?.position;
          this.userData.cv.data[0].careers.subCategories[data.index].employmentType = res.career?.employmentType;
          this.userData.cv.data[0].careers.subCategories[data.index].endDate = res.career?.endDate;
          this.userData.cv.data[0].careers.subCategories[data.index].startDate = res.career?.startDate;
          this.userData.cv.data[0].careers.subCategories[data.index].role = res.career?.role;
          this.userData.cv.data[0].careers.subCategories[data.index].organization = res.career?.organization;
          this.userData.cv.data[0].careers.subCategories[data.index].description = res.career?.description;
          this.userData.cv.data[0].careers.subCategories[data.index].today = res.career?.today;
          this.userData.cv.data[0].careers.subCategories[data.index].pageID = res.career?.pageID;
          this.userData.cv.data[0].careers.subCategories[data.index].url = res.career?.url;
        }
        this.userService.updateCV(this.userData.cv._id, this.userData.cv);
      }
    });;
  }

  public editEducation(data: EditEducation) {
    this.dialog.open(EducationDialogComponent, { data })
      .afterClosed().subscribe((res: EditEducation) => {
        if (!res) {
          return;
        } else {
          this.user.cv[0].education.some(r => {
            if (res) {
              r.subCategories[data.languageIndex].title = res.education?.title;
              r.subCategories[data.languageIndex].description = res.education?.description;
              r.subCategories[data.languageIndex].today = res.education?.today;
              r.subCategories[data.languageIndex].endDate = res.education?.endDate;
              r.subCategories[data.languageIndex].startDate = res.education?.startDate;
              r.subCategories[data.languageIndex].graduation = res.education?.graduation;
              r.subCategories[data.languageIndex].pageID = res.education?.pageID;
              r.subCategories[data.languageIndex].name = res.education?.name;
            }
          });
          this.userService.updateUser(this.user._id, this.user);
        }
      });
  }
  deleteEducationSubCategory(i) {
    this.user?.cv[0].education.map(t => t.subCategories.splice(i, 1));
    this.userService.updateUser(this.user._id, this.user);
  }
  deleteCareerSubCategory(index) {
    this.user?.cv[0].careers.map(t => t.subCategories.splice(index, 1));
    this.userService.updateUser(this.user._id, this.user);
  }

  deletePageCurrent() {
    this.searchForPage = "";
    const { pageID: remove, ...rest } = this.user.places.current;
    this.user.places.current = rest;

    this.addressAdd = false;
    this.selectedPageId = false;
    this.userService.updateUser(this.user._id, this.user);
  }

  deleteNameCurrent() {
    this.searchForPage = "";
    const { name: remove, ...rest } = this.user.places.current;
    this.user.places.current = rest;

    this.addressAdd = false;
    this.selectedPageId = false;
    this.userService.updateUser(this.user._id, this.user);
  }

  deletePage() {
    this.searchForPage = "";
    const { pageID: remove, ...rest } = this.user.places.hometown;
    this.user.places.hometown = rest;

    this.hometownAdd = false;
    this.selectedPageId = false;
    this.userService.updateUser(this.user._id, this.user);
  }








}
