import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'src/app/shared/interfaces/model';
import { CreditsHistory } from 'src/app/shared/interfaces/user.model';
import { PagesService } from 'src/app/shared/services/pages.service';
import { SenderService } from 'src/app/shared/services/sender.service';

@Component({
  selector: 'app-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss']
})
export class CompanyNameComponent implements OnInit {

  @Input() companyName;
  company: Page;
  constructor( public pageService: PagesService,  private router: Router,     private sendPageData: SenderService,
    ) { }

  ngOnInit(): void {

    this.pageService.getPagesCompany(this.companyName).subscribe(t => {
      this.company = t.page[0]?.page;
     })
  }
  goToPage() {
    localStorage.setItem("pageID", this.company._id)
    this.pageService.changeMessage(this.company._id);
    this.sendPageData.send(this.company);
    this.router.navigate([this.company.pageUrl]);
  }

}
