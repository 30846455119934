import {Injectable} from "@angular/core";
import {NotificationNodeService} from "../services/notification-node.service";
import {NodeNotifications} from "../interfaces/node-notifications";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root"
})
export class NodeNotificationHelperService{
  constructor(
    private nodeNotificationService: NotificationNodeService
  ) {
  }
  createNewNode(node, userID): Observable<NodeNotifications> {
    return new Observable(observer => {
      this.nodeNotificationService.createRequest(node, userID).subscribe(() => {
        observer.next();
      })
    })
  }

  loadNodeNotifications(userID): Observable<any> {
    return new Observable<any>(observer => {
      this.nodeNotificationService.getAllNodeNotification(userID).subscribe(result => {
        observer.next(result)
      })
    })
  }

  updateNodeNotification(nodeObject): Observable<any> {
    return new Observable(observer => {
      this.nodeNotificationService.update(nodeObject).subscribe(() => {
        observer.next();
      })
    })
  }
}
