<div class="card mb-3">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <!-- Avatar -->
            <div class="avatar me-2">
                <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/12.svg" alt=""> </a>
            </div>
            <!-- Info -->
            <div>
                <h6 *ngIf="!page" class="card-title mb-0"><a href="#!">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</a></h6>
                <h6 *ngIf="!!page" class="card-title mb-0"><a href="#!">{{page?.name}}</a></h6>

                <a *ngIf="job" href="#!" class="mb-0 text-body">Job  </a>
                <a *ngIf="!job" href="#!" class="mb-0 text-body">Project </a>

            </div>
        </div>
        <!-- Card share action START -->
        <div class="dropdown">
            <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots"></i>
            </a>
            <!-- Card share action dropdown menu -->
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
            </ul>
        </div>
        <!-- Card share action START -->
    </div>
</div>
<ng-container *ngIf="!!job && !!user">

<app-data-item-details advertisment="true" isProjectSearchCompany="false" [applyFormAdvertisment]="false" [employmentTypeInput]="true"  [showImage]="false"  [published]="true"  [isJob]="true" [data]="job" [user]="user" [showSkills]="true" [showUser]="true"></app-data-item-details>

</ng-container>

</div>
<div class="card">
  <div class="card-header">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <!-- Avatar -->
            <div class="avatar me-2">
                <a href="#!"> <img class="avatar-img rounded-circle" src="assets/images/logo/12.svg" alt=""> </a>
            </div>
            <!-- Info -->
            <div>
                <h6 *ngIf="!page" class="card-title mb-0"><a href="#!">{{userCreatedJob?.firstName}} {{userCreatedJob?.lastName}}</a></h6>
                <h6 *ngIf="!!page" class="card-title mb-0"><a href="#!">{{page?.name}}</a></h6>

                <a *ngIf="project" href="#!" class="mb-0 text-body">Project </a>

            </div>
        </div>
        <!-- Card share action START -->
        <div class="dropdown">
            <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardShareAction2" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-three-dots"></i>
            </a>
            <!-- Card share action dropdown menu -->
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardShareAction2">
                <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Save post</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-person-x fa-fw pe-2"></i>Unfollow lori ferguson </a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-x-circle fa-fw pe-2"></i>Hide post</a></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-slash-circle fa-fw pe-2"></i>Block</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#"> <i class="bi bi-flag fa-fw pe-2"></i>Report post</a></li>
            </ul>
        </div>
        <!-- Card share action START -->
    </div>
</div>
<ng-container *ngIf="!!project && !!user">

<app-data-item-details [isProject]="true" advertisment="true" [isProjectSearchCompany]="true" [applyFormAdvertisment]="false" [showImage]="false"  [published]="true"  [isJob]="false" [data]="project" [user]="user" [showUser]="false" [showSkills]="true" [showUser]="true"></app-data-item-details>

</ng-container>

</div>
